import React from "react";
import axios from "axios";
import { Navigate, useSearchParams, useNavigate } from "react-router-dom";
import LoginImage from "../assets/man-593333.jpg";
import { useForm } from "react-hook-form";
import UtilsFunctions from "../utils/UtilsFunctions";
import { toast } from "react-hot-toast";

const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const rutValidations = {
    required: { value: true, message: "Debes ingresar un rut" },
    pattern: {
      value: /^(\d{1,2}(?:[\.]?\d{3}){2}-[\dkK])$/,
      message: "El formato del RUT es incorrecto",
    },
    validate: {
      rutValido: (v) =>
        UtilsFunctions.validateRut(v) || "El RUT ingresado no es válido",
    },
  };

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;

  const [searchParams] = useSearchParams();
  const tokenVal = searchParams.get("token");
  if (!tokenVal) {
    return <Navigate to="/login" />;
    }

  const handleReset = (e) => {
    e.preventDefault();
    const tokenVal = searchParams.get("token");

    if (tokenVal && tokenVal.endsWith("/")) {
      let tokenNoVal = searchParams.get("token");
      searchParams.set("token", tokenNoVal.slice(0, -1));
    }

    let tokenValido = searchParams.get("token");
    console.log(tokenValido);
    let tokenNoVal = searchParams.get("token");

    if (tokenNoVal && tokenNoVal.endsWith("/")) {
      searchParams.set("token", tokenNoVal.slice(0, -1));
    }

    tokenValido = searchParams.get("token");

    const tokenRut = localStorage.getItem("token-rut");
    const tokenGen = tokenValido;
    const tokenUser = localStorage.getItem("token-user");

    try {
      const data = {
        // email: email,
        // nuevaPassword: password,
        passwordResetToken: tokenGen.toString(),
      };
      console.log("DATA", data);
      const info = axios.put(
        `${api_service}/api/v1/76494210/auth/resetpassword`,
        data
      );
    } catch (err) {
      console.log("error", err);
    }
  };

  const onSubmit = (data) => {
    toast.loading("Restableciendo contraseña...")

    if (!tokenVal) {
        toast.dismiss();
        toast.error("No se ha podido restablecer la contraseña");
        return;
    }

    const payload = {
        passwordResetToken: tokenVal.slice(0, -1),
        nuevaPassword: data.nuevaPassword
    }
    axios.put(
        `${api_service}/api/v1/auth/resetpassword`,
        payload,
        {
          headers: {
            ClientId: UtilsFunctions.rutFormatter(data.rutHolding, {dots: false}).slice(0,8)
          }
        }
      ).then((res) => {
        toast.dismiss();
        toast.success("Contraseña restablecida con éxito");
        navigate("/login");
        reset();
      }).catch((err) => {
        toast.dismiss();
        toast.error("No se ha podido restablecer la contraseña");
        console.log("error", err);
      });
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 h-screen w-full">
      <div className="hidden sm:block">
        <img className="w-full h-full object-cover" src={LoginImage} alt="" />
      </div>

      <div className="sm:bg-bluegray-200 flex flex-col justify-center sm:px-3">
        <img
          className="absolute sm:hidden w-full h-full object-cover mix-blend-overlay"
          src={LoginImage}
          alt=""
        />

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="relative max-w-[500px] w-full mx-auto bg-white py-4 px-6 rounded-lg"
        >
          <h2 className="text-4xl font-bold text-center py-4">
            Restablecer Contraseña
          </h2>
          <div className="flex flex-col my-2">
            <div className="flex flex-col mb-3">
            <label className="mb-1">Rut Holding</label>
            <input
              {...register("rutHolding", rutValidations)}
              type="text"
              className={`border p-2`}
            />
            {errors.rutHolding && <p className="text-red-600 text-xs">{errors.rutHolding.message}</p>}
            </div>
            <div className="flex flex-col mb-3">
            <label className="mb-1">Nueva Contraseña</label>
            <input
              {...register("nuevaPassword", {
                required: { value: true, message: "Debes ingresar una contraseña" },
                minLength: {
                    value: 8,
                    message: "La contraseña debe tener al menos 8 caracteres",
                },
              })}
              type="password"
              className={`border p-2`}
            />
            {errors.nuevaPassword && <p className="text-red-600 text-xs">{errors.nuevaPassword.message}</p>}
            </div>
            <div className="flex flex-col">
            <label className="mb-1">Repetir Contraseña</label>
            <input
              {...register("confirmarPassword", {
                required: { value: true, message: "Debes ingresar una contraseña" },
                minLength: {
                    value: 8,
                    message: "La contraseña debe tener al menos 8 caracteres",
                },
                validate: (value) => {
                    return value === getValues("nuevaPassword") || "Las contraseñas no coinciden";
                },   
              })}
              type="password"
              className={`border p-2`}
            />
            {errors.confirmarPassword && <p className="text-red-600 text-xs">{errors.confirmarPassword.message}</p>}
            </div>
          </div>

          <button className="border rounded-md relative w-full my-5 py-2 bg-blue-ribbon-700 hover:bg-blue-ribbon-800 text-white font-bold">
            Restablecer Contraseña
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
