import { useLocation, Navigate, Outlet } from "react-router-dom";

const moduloId = localStorage.getItem("moduloId");

// console.log("moduloId", moduloId);

function checkIfUserCanAccessMenu(menuPath, userMenu) {
  let currentMenu = userMenu;

  for (const title of menuPath) {
    
    const menu = currentMenu.find((menu) => menu.title === title);

    if (!menu) {
    if(moduloId === 1){
        return <Navigate to="remuneraciones/pagina-404" />;
    }
    if(moduloId === 2){
        return <Navigate to="vacaciones/pagina-404" />;
    }
    return false;
    }

    currentMenu = menu.childrens;
  }

  return true;
}

const Auth = ({ menuPath }) => {
  const userAuthString = localStorage.getItem("userAuth");
  const userMenuString = localStorage.getItem("userMenu");

  const userAuth = JSON.parse(userAuthString);
  const userMenu = JSON.parse(userMenuString);

  if (!userAuth || !userMenu) {
          if(moduloId === 1){
          return <Navigate to="remuneraciones/pagina-404" />;
      }
      if(moduloId === 2){
          return <Navigate to="vacaciones/pagina-404" />;
      }
  }

  if (!userAuth.token || !userAuth.refreshToken) {
    if(moduloId === 1){
        return <Navigate to="remuneraciones/pagina-404" />;
    }
    if(moduloId === 2){
        return <Navigate to="vacaciones/pagina-404" />;
    }
}

  return checkIfUserCanAccessMenu(menuPath, userMenu) ? (
    <Outlet />
  ) : (
    <Navigate to={`${moduloId == 1 ? "/remuneraciones/pagina-404" : "/vacaciones/pagina-404"}`} />
  );

};

export default Auth;
