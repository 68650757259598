import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import UtilsFunctions from "../../../utils/UtilsFunctions";
import { useDispatch, useSelector } from "react-redux";
import { useStateContext } from "../../../contexts/ContextProvider";
import {
  getCcaf,
  getCiudades,
  getComunas,
  getGratificacionesLegales,
  getMutual,
  getPaises,
  getRegiones,
  getRepLegalesActivos,
} from "../../../Redux/Actions";
import { axiosPrivate } from "../../../common/axiosPrivate";
import { toast } from "react-hot-toast";

export default function CrearNuevaEmpresa({ isVisible, onClose }) {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      paisId: "",
      regionId: "",
      comunaId: "",
      ciudadId: "",
      gratificacionId: "",
    },
  });

  const REACT_APP_API_HERCULES_SERVICE =
    process.env.REACT_APP_API_HERCULES_SERVICE;

  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const periodoId = localStorage.getItem("periodoId");

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const { refreshTable, setRefreshTable, screenSize } = useStateContext();

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPaises());
    dispatch(getRepLegalesActivos());
    dispatch(getCcaf());
    dispatch(getMutual());
    dispatch(getGratificacionesLegales())
  }, [refreshTable, dispatch]);

  useEffect(() => {
    if (watch("paisId")) {
      dispatch(getRegiones(watch("paisId")));
    }
  }, [watch("paisId")]);

  useEffect(() => {
    if (watch("regionId")) {
      dispatch(getComunas(watch("regionId")));
    }
  }, [watch("regionId")]);

  useEffect(() => {
    if (watch("comunaId")) {
      dispatch(getCiudades(watch("comunaId")));
    }
  }, [watch("comunaId")]);

  const [botonGuardarHabilitado, setBotonGuardarHabilitado] = useState(true);
  const representantes = useSelector((state) => state.repLegalesActivos);
  const paises = useSelector((state) => state.paises);
  const mutuales = useSelector((state) => state.mutuales);
  const regionesData = useSelector((state) => state.regiones);
  const comunasData = useSelector((state) => state.comunas);
  const ciudadesData = useSelector((state) => state.ciudades);
  const gratificacionesData = useSelector((state) => state.getGratificaciones);

  const validacionTextInput = {
    required: { value: true, },
  };

  const rutValidations = {
    required: { value: true, message: "Obligatorio" },
    pattern: {
      value: /^(\d{1,2}(?:[\.]?\d{3}){2}-[\dkK])$/,
      message:
        "El formato del RUT es incorrecto (ej. 18.405.136-K o 18405136-K)",
    },
    validate: {
      rutValido: (v) =>
        UtilsFunctions.validateRut(v) || "El RUT ingresado no es válido",
    },
  };


  async function onSubmit(data) {
    setBotonGuardarHabilitado(false);
    toast.loading("Creando Empresa", estilosAlerta);
    try {
      const requestBody = {
        periodoId: periodoId,
        nombreFantasia: data.nombreFantasia,
        rut: UtilsFunctions.rutFormatter(data.rut, { dots: false }),
        razonSocial: data.razonSocial,
        giro: data.giro,
        ciudadId: data.ciudadId,
        calle: data.calle,
        numero: data.numero,
        depto: data.depto,
        actividadEconomica: data.actividadEconomica,
        telefono: data.telefono,
        representanteId: data.representanteLegalId,
        gratificacionId: data.gratificacionId
      };
      const response = await axiosPrivate.post(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/empresas/nuevaempresa`,
        requestBody,
        Authorization
      );
      if (response.status === 200) {
        reset();
        toast.dismiss();
        toast.success("Empresa creada con éxito", estilosAlerta);
        onClose();
        setRefreshTable(!refreshTable);
      }
      setBotonGuardarHabilitado(true);
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al crear Empresa",
        estilosAlerta
      );
      setBotonGuardarHabilitado(true);
    }
  }


  if (!isVisible) {
    return null;
  }

  return (
    <>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="primerDivModal">
          <div className="segundoDivModal max-w-6xl">
            {/*content*/}
            <div className="tercerDivModal shadow-lg  max-h-[85vh] ">
              {/*header*/}
              <div className="dark:bg-hercules-dark-bg-100 flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <h3 className="titulo-Modal">Nueva Empresa</h3>
              </div>
              {/*body*/}
              <div className="relative py-6 px-3 flex-auto overflow-auto dark:bg-hercules-dark-bg-200">

                {/* Información basica de la empresa */}
                <div className="flex flex-wrap">
                  <div className="input-EditarEmpresa md:w-4/12">
                    <label
                      className="block dark:text-white text-hercules-light-font-secondary  uppercase tracking-wide text-xs font-bold mb-2"
                      htmlFor="grid-nombre-fantasia"
                    >
                      Nombre de fantasía
                    </label>
                    <input
                      {...register("nombreFantasia", validacionTextInput)}
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                    />
                    <span
                      className={`dark:text-red-400 text-red-600 italic text-xs ${
                        !errors.nombreFantasia && "opacity-0 invisible"
                      }`}
                    >
                      {errors.nombreFantasia ? "Obligatorio" : "hidden"}
                    </span>
                  </div>
                  <div className="input-EditarEmpresa md:w-2/12">
                    <label
                      className="block dark:text-white uppercase tracking-wide text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-first-name"
                    >
                      RUT Empresa
                    </label>
                    <input
                      {...register("rut", {
                        ...rutValidations,
                        onChange: () => {
                          if (getValues("rut") !== "-") {
                            setValue(
                              "rut",
                              UtilsFunctions.rutFormatter(getValues("rut"), {
                                dots: true,
                              })
                            );
                          }
                          if (getValues("rut") === "-") {
                            setValue("rut", "");
                          }
                        },
                      })}
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                    />
                    <span
                      className={`dark:text-red-400 text-red-600 italic text-xs ${
                        !errors.rut && "opacity-0 invisible"
                      }`}
                    >
                      {errors.rut ? "Obligatorio" : "hidden"}
                    </span>

                  </div>
                  <div className="input-EditarEmpresa md:w-3/12">
                    <label className="dark:text-white block uppercase tracking-wide text-hercules-light-font-secondary text-xs font-bold mb-2">
                      Razón Social
                    </label>
                    <input
                      {...register("razonSocial", validacionTextInput)}
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                    />
                    <span
                      className={`dark:text-red-400 text-red-600 italic text-xs ${
                        !errors.razonSocial && "opacity-0 invisible"
                      }`}
                    >
                      {errors.razonSocial ? "Obligatorio" : "hidden"}
                    </span>
                  </div>
                  <div className="input-EditarEmpresa md:w-3/12">
                    <label
                      className="dark:text-white block uppercase tracking-wide text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      Giro
                    </label>
                    <input
                      {...register("giro", validacionTextInput)}
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                    />
                    <span
                      className={`dark:text-red-400 text-red-600 italic text-xs ${
                        !errors.giro && "opacity-0 invisible"
                      }`}
                    >
                      {errors.giro ? "Obligatorio" : "hidden"}
                    </span>
                  </div>
                </div>

                {/* Rep Legal */}
                <div className="flex flex-wrap">
                  <div className="input-EditarEmpresa md:w-4/12">
                    <label
                      className="dark:text-white block uppercase tracking-wide text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      Representante Legal
                    </label>
                    <select
                      {...register("representanteLegalId", {
                        required: "Obligatorio",
                        onChange: (e) => {
                          console.log("e.target.selectedIndex - 1", e.target.selectedIndex - 1)
                          const representante =
                            representantes[e.target.selectedIndex - 1];
                          setValue("representante", representante);
                          setValue(
                            "representanteRut",
                            UtilsFunctions.rutFormatter(representante.rut, {
                              dots: true,
                            })
                          );
                        },
                      })}
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      <option hidden value="">
                        Seleccionar Representante Legal
                      </option>
                      {representantes.map((representante, i) => (
                        <option value={representante.id} key={i}>
                          {representante.nombres +
                            " " +
                            representante.apellidoPaterno +
                            " " +
                            representante.apellidoMaterno}
                        </option>
                      ))}
                    </select>
                    <span
                      className={`dark:text-red-400 text-red-600 italic text-xs ${
                        !errors.representanteLegalId && "opacity-0 invisible"
                      }`}
                    >
                      {errors.representanteLegalId ? "Obligatorio" : "hidden"}
                    </span>

                  </div>
                  <div className="input-EditarEmpresa md:w-3/12 pb-6">
                    <label
                      className="dark:text-white block uppercase tracking-wide text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      RUT Rep. Legal
                    </label>
                    <input
                      disabled
                      {...register("representanteRut")}
                      className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                      id="grid-last-name"
                      type="text"
                    />
                  </div>
                </div>

                {/* Ubicacion */}
                <div className="flex flex-wrap">
                  <div className="input-EditarEmpresa md:w-2/12">
                    <label
                      className="dark:text-white block uppercase tracking-wide text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      País
                    </label>
                    <select
                      {...register("paisId", {
                        required: "Debe seleccionar un país",
                        onChange: () => {
                          setValue("regionId", "");
                          setValue("comunaId", "");
                          setValue("ciudadId", "");
                        },
                      })}
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-state"
                    >
                      <option hidden value="">
                        Seleccionar País
                      </option>
                      {paises.map((pais) => (
                        <option value={pais.id} key={pais.id}>
                          {pais.nombre}
                        </option>
                      ))}
                    </select>
                    <span
                      className={`dark:text-red-400 text-red-600 italic text-xs ${
                        !errors.paisId && "opacity-0 invisible"
                      }`}
                    >
                      {errors.paisId ? errors.paisId.message : "hidden"}
                    </span>
                  </div>

                  <div className="input-EditarEmpresa md:w-4/12">
                    <label
                      className="dark:text-white block uppercase tracking-wide text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      Región
                    </label>
                    <select
                      disabled={watch("paisId") === "" ? true : false}
                      {...register("regionId", {
                        required: "Debe seleccionar una región",
                        onChange: (e) => {
                          setValue("comunaId", "");
                          setValue("ciudadId", "");
                        },
                      })}
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      <option hidden value="">
                        {watch("paisId") === ""
                          ? "Seleccionar Pais"
                          : "Seleccionar Región"}
                      </option>
                      {regionesData.map((region) => (
                        <option value={region.id} key={region.id}>
                          {region.nombre}
                        </option>
                      ))}
                    </select>
                    <span
                      className={`dark:text-red-400 text-red-600 italic text-xs ${
                        !errors.regionId && "opacity-0 invisible"
                      }`}
                    >
                      {errors.regionId ? errors.regionId.message : "hidden"}
                    </span>
                  </div>

                  <div className="input-EditarEmpresa md:w-3/12">
                    <label
                      className="block dark:text-white uppercase tracking-wide text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      Comuna
                    </label>
                    <select
                      disabled={watch("regionId") === "" ? true : false}
                      {...register("comunaId", {
                        required: "Debe seleccionar una comuna",
                        onChange: (e) => {
                          setValue("ciudadId", "");
                        },
                      })}
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      <option hidden value="">
                        {watch("regionId") === ""
                          ? "Seleccionar Región"
                          : "Seleccionar Comuna"}
                      </option>
                      {comunasData.map((comuna) => (
                        <option value={comuna.id} key={comuna.id}>
                          {comuna.nombre}
                        </option>
                      ))}
                    </select>
                    <span
                      className={`dark:text-red-400 text-red-600 italic text-xs ${
                        !errors.comunaId && "opacity-0 invisible"
                      }`}
                    >
                      {errors.comunaId ? errors.comunaId.message : "hidden"}
                    </span>
                  </div>

                  <div className="input-EditarEmpresa md:w-3/12">
                    <label
                      className="dark:text-white block uppercase tracking-wide text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      Ciudad
                    </label>
                    <select
                      {...register("ciudadId", {
                        required: "Debe seleccionar una ciudad",
                      })}
                      disabled={watch("comunaId") === "" ? true : false}
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-state"
                    >
                      <option hidden value="">
                        {watch("comunaId") === ""
                          ? "Seleccionar Comuna"
                          : "Seleccionar Ciudad"}
                      </option>
                      {ciudadesData.map((ciudad) => (
                        <option value={ciudad.id} key={ciudad.id}>
                          {ciudad.nombre}
                        </option>
                      ))}
                    </select>
                    <span
                      className={`dark:text-red-400 text-red-600 italic text-xs ${
                        !errors.ciudadId && "opacity-0 invisible"
                      }`}
                    >
                      {errors.ciudadId ? errors.ciudadId.message : "hidden"}
                    </span>
                  </div>
                </div>

                {/* Direccion */}
                <div className="flex flex-wrap">
                  <div className="input-EditarEmpresa md:w-4/12">
                    <label
                      className="dark:text-white block uppercase tracking-wide text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      Calle
                    </label>
                    <input
                      {...register("calle", validacionTextInput)}
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    />
                    <span
                      className={`dark:text-red-400 text-red-600 italic text-xs ${
                        !errors.calle && "opacity-0 invisible"
                      }`}
                    >
                      {errors.calle ? "Obligatorio" : "hidden"}
                    </span>
                  </div>

                  <div className="input-EditarEmpresa md:w-2/12">
                    <label
                      className="dark:text-white block uppercase tracking-wide text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      Número
                    </label>
                    <input
                      {...register("numero", validacionTextInput)}
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                    />
                    <span
                      className={`dark:text-red-400 text-red-600 italic text-xs ${
                        !errors.numero && "opacity-0 invisible"
                      }`}
                    >
                      {errors.numero ? "Obligatorio" : "hidden"}
                    </span>
                  </div>

                  <div className="input-EditarEmpresa md:w-2/12 pb-6">
                    <label
                      className="dark:text-white block uppercase tracking-wide text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      Depto.
                    </label>
                    <input
                      {...register("depto")}
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-last-name"
                      type="text"
                    />
                  </div>
                </div>

                {/* Gratificación */}
                <div className="flex flex-wrap">
                  <div className="input-EditarEmpresa md:w-3/12">
                    <label className="dark:text-white block uppercase tracking-wide text-hercules-light-font-secondary text-xs font-bold mb-2">
                      Gratificación
                    </label>
                    <select
                      {...register("gratificacionId", {
                        required: "Obligatorio",
                        onChange: (e) => {
                          const gratificacion =
                          gratificacionesData[e.target.selectedIndex - 1];
                          setValue("gratificacion", gratificacion);
                          setValue("gratificacionTexto", gratificacion.observacion);
                        },
                      })}
                      name="gratificacionId"
                      className="appearance-none block w-full md:w-64 bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                    >
                      <option hidden value="">
                        Seleccionar Gratificación
                      </option>
                      {gratificacionesData.map((gratificacion, i) => (
                        <>
                          <option key={i} value={gratificacion.id}>
                            {gratificacion.nombre}
                          </option>
                        </>
                      ))}
                    </select>
                    <span
                      className={`dark:text-red-400 text-red-600 italic text-xs ${
                        !errors.gratificacionId && "opacity-0 invisible"
                      }`}
                    >
                      {errors.gratificacionId ? "Obligatorio" : "hidden"}
                    </span>
                  </div>

                  <div className="input-EditarEmpresa md:w-8/12 pb-6">
                    <label
                      className="dark:text-white block uppercase tracking-wide text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      Observación
                    </label>
                    <input
                      disabled
                      {...register("gratificacionTexto")}
                      className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                      id="grid-last-name"
                      type="text"
                    />
                  </div>
                </div>

                {/* Act Economica y Telefono */}
                <div className="flex flex-wrap">
                  <div className="input-EditarEmpresa md:w-3/12">
                    <label className="dark:text-white block uppercase tracking-wide text-hercules-light-font-secondary text-xs font-bold mb-2">
                      Actividad Económica
                    </label>
                    <input
                      {...register("actividadEconomica", validacionTextInput)}
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-last-name"
                      type="text"
                    />
                    <span
                      className={`dark:text-red-400 text-red-600 italic text-xs ${
                        !errors.actividadEconomica && "opacity-0 invisible"
                      }`}
                    >
                      {errors.actividadEconomica ? "Obligatorio" : "hidden"}
                    </span>

                  </div>

                  <div className="input-EditarEmpresa md:w-3/12">
                    <label
                      className="dark:text-white block uppercase tracking-wide text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      Teléfono
                    </label>
                    <input
                      {...register("telefono", validacionTextInput)}
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-last-name"
                      type="text"
                    />
                    <span
                      className={`dark:text-red-400 text-red-600 italic text-xs ${
                        !errors.telefono && "opacity-0 invisible"
                      }`}
                    >
                      {errors.telefono ? "Obligatorio" : "hidden"}
                    </span>
                  </div>
                </div>


                {/* Final Filas */}
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className=" dark:text-white text-hercules-light-font-secondary hover:scale-105 font-bold uppercase text-base px-6 py-3 outline-none focus:outline-none mr-3 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    onClose();
                    reset();
                  }}
                >
                  Cerrar
                </button>
                <button
                  disabled={!botonGuardarHabilitado}
                  className={` ${
                    !botonGuardarHabilitado
                      ? "bg-gray-500"
                      : "bg-hercules-light-primary-button hover:shadow-lg hover:scale-105"
                  }  text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400`}
                  type="submit"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </form>
    </>
  );
}
