import { axiosPrivate } from "../common/axiosPrivate";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import LoginImage from "../assets/man-593333.jpg";
import UtilsFunctions from "../utils/UtilsFunctions";
import { useStateContext } from "../contexts/ContextProvider";
import axios from "axios";
import { DarkModeContext } from "../contexts/DarkModeProvider";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const Login = () => {
  const {
    register,
    getValues,
    watch,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm();

  const [showPassword, setShowPassword] = useState(false);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { setDarkMode } = useContext(DarkModeContext);
  let navigate = useNavigate();

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const api_central = process.env.REACT_APP_API_HERCULES_CENTRAL;

  const campoObligatorio = {
    required: { value: true, message: "Obligatorio" },
  };

  function presionaTecla(event) {
    if (event.keyCode == 13) {
      handleLogin();
    }
  }

  async function handleGetNotifications() {
    try {
      const tokenRut = localStorage.getItem("token-rut");
      const token = tokenRut.slice(0, 8);
      const tokenUser = localStorage.getItem("token-user");

      const Authorization = {
        headers: {
          Authorization: `Bearer ${tokenUser}`,
          ClientId: `${token}`,
        },
      };
      const response = await axiosPrivate(
        `${api_central}/api/v1/central/holdings/1/mensaje/5`,
        Authorization
      );

      if (response.status === 200) {
        localStorage.setItem(
          "notificationsUser",
          JSON.stringify(response.data)
        );
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
    }
  }

  async function handleLogin() {
    const value = getValues();

    const passFormValidation = await trigger();
    if (!passFormValidation) {
      return null;
    }

    setLoading(true);
    try {
      const token = UtilsFunctions.rutFormatter(value.rutHolding, {
        dots: false,
      });

      const dataRUT = await axios.post(
        `${api_central}/api/v1/central/validar/holding`,
        { rut: token },
        {
          headers: {
            ClientId: `${token.slice(0, 8)}`,
          },
        }
      );

      if (dataRUT.status === 200) {
        try {
          const dataUser = {
            username: value.username,
            password: value.password,
          };
          localStorage.setItem("token-rut", token);
          localStorage.setItem("token-rut-slice", token.slice(0, 8));
          const data = await axios.post(
            `${api_service}/api/v1/auth/signin`,
            dataUser,
            {
              headers: {
                ClientId: `${token.slice(0, 8)}`,
              },
            }
          );
          if (data.status === 200) {
            localStorage.setItem("token-rol-id", data.data.rolId);
            localStorage.setItem("email-user", value.username);
            localStorage.setItem("token-user", data.data.token);
            localStorage.setItem("rol", data.data.roles);
            localStorage.setItem("refresh-token", data.data.refreshToken);
            localStorage.setItem("userId", data.data.id);
            localStorage.setItem(
              "userAuth",
              JSON.stringify({
                token: data.data.token,
                refreshToken: data.data.refreshToken,
                rolId: data.data.rolId,
                roles: data.data.roles,
                userId: data.data.id,
                emailUser: value.username,
              })
            );
            if (localStorage.getItem("userTheme") === null) {
              localStorage.setItem("userTheme", "light");
            }
            setDarkMode(
              localStorage.getItem("userTheme") === "dark" ? true : false
            );
            await handleGetNotifications();
            setLoading(false);
            navigate("/seleccionar-modulo");
          }
        } catch (error) {
          setLoading(false);
          if (error.response.status === 401) {
            setErrorMessage(
              error.response.data.message === "Bad credentials"
                ? "Datos incorrectos"
                : error.response.data.message
            );
          } else {
            setErrorMessage(
              error.response.data.message
                ? error.response.data.message
                : "Error al iniciar sesión"
            );
          }
        }
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage(
        error.response.data ? error.response.data : "Error al iniciar sesión"
      );
    }
  }

  return (
    <div
      className="grid grid-cols-1 sm:grid-cols-2 h-screen w-full"
      onKeyDown={(event) => presionaTecla(event)}
    >
      <div className="hidden sm:block">
        <img className="w-full h-full object-cover" src={LoginImage} alt="" />
      </div>

      <div className="sm:bg-bluegray-200 bgLP00542248879581black/40 flex flex-col justify-center sm:px-3">
        <img
          className="absolute sm:hidden w-full h-full object-cover mix-blend-overlay"
          src={LoginImage}
          alt=""
        />

        <form
          className="relative max-w-[500px] w-full mx-auto bg-white p-4 rounded-lg"
          onSubmit={handleSubmit(handleLogin)}
        >
          <h2 className="text-4xl font-bold text-center py-6 ">
            Ingresar a Hércules
          </h2>
          <div className="flex flex-col py-2">
            <label htmlFor="email">RUT Holding</label>
            <input
              {...register("rutHolding", {
                onChange: () => {
                  if (getValues("rutHolding") !== "-") {
                    setValue(
                      "rutHolding",
                      UtilsFunctions.rutFormatter(getValues("rutHolding"), {
                        dots: true,
                      })
                    );
                  }
                  if (getValues("rutHolding") === "-") {
                    setValue("rutHolding", "");
                  }
                },
                ...campoObligatorio,
              })}
              className={`border p-2 ${
                errorMessage === "El RUT ingresado no es válido"
                  ? "border-red-500"
                  : ""
              }`}
              type="text"
              name="rutHolding"
              id="rutHolding"
            />
            <span
              className={`text-red-500 text-xs italic ${
                !errors.rutHolding && "opacity-0 invisible"
              }`}
            >
              {errors.rutHolding ? errors.rutHolding.message : "hidden"}
            </span>
          </div>
          <div className="flex flex-col my-2">
            <label htmlFor="email">Email</label>
            <input
              {...register("username", campoObligatorio)}
              className={`border p-2 ${
                errorMessage === "Datos incorrectos" ? "border-red-500" : ""
              }`}
              placeholder="ejemplo@email.com"
              type="username"
              name="username"
              id="username"
            />
            <span
              className={`text-red-500 text-xs italic ${
                !errors.username && "opacity-0 invisible"
              }`}
            >
              {errors.username ? errors.username.message : "hidden"}
            </span>
          </div>

          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label htmlFor="password">Contraseña</label>

              <div className="flex items-center relative">
                <input
                  {...register("password", campoObligatorio)}
                  className={`border p-2 ${
                    errorMessage === "Datos incorrectos" ? "border-red-500" : "border-gray-200 focus:border-gray-500"
                  } appearance-none pr-12 block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white  `}
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                />
                <button
                  type="button"
                  className="absolute right-2 cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                          <AiFillEyeInvisible className="text-2xl text-gray-500" />
                        ) : (
                          <AiFillEye className="text-2xl text-gray-500" />
                        )}
                </button>
              </div>

              <span
                className={`text-red-500 text-xs italic ${
                  !errors.password && "opacity-0 invisible"
                }`}
              >
                {errors.password ? errors.password.message : "hidden"}
              </span>
            </div>
          </div>

          <button
            className="hover:scale-105 w-full my-5 py-2 bg-hercules-dark-primary-button text-white  font-semibold text-base px-8 rounded-xl shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-2 ease-in-out transition-all duration-400"
            type="submit"
          >
            Iniciar Sesión
          </button>
          <div className="flex">
            {errorMessage === "" ? null : (
              <p className="hidden lg:block text-red-500 text-sm">
                {errorMessage}
              </p>
            )}
            <Link className="ml-auto" to="/recuperar-contraseña">
              <p className="mt-2 md:mt-0 text-md hover:cursor-pointer font-semibold">
                Olvidé mi contraseña
              </p>
            </Link>
          </div>
        </form>
      </div>
      {loading && (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
          <div className="border-t-blue-ribbon-500 animate-spin ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
          <h2 className="text-center text-white text-xl font-semibold">
            Cargando...
          </h2>
          <p className="w-1/3 text-center text-white">
            Esto puede tardar unos segundos, por favor no cierres esta página.
          </p>
        </div>
      )}
    </div>
  );
};

export default Login;
