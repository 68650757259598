import { axiosPrivate } from "../../../common/axiosPrivate";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useStateContext } from "../../../contexts/ContextProvider";
import {
  limpiarInfoMenuEdicion,
  listaTramosCFamiliar,
} from "../../../Redux/Actions";
import UtilsFunctions from "../../../utils/UtilsFunctions";
import { useForm } from "react-hook-form";
import SimpleTable from "../../SimpleTable";
import ModalSpinner from "../../ModalSpinner";

export default function CargasFamiliaresTrabajador({
  isVisibleEditorCargas,
  onCloseEditorCargas,
  modalData,
  updateTrabajadorCargaFamiliar,
}) {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      numeroCargas: "0",
      // numeroCargasInvalidez: "0",
      periodoAnyo: "",
    },
  });

  const [botonGuardarHabilitado, setBotonGuardarHabilitado] = useState(true)
  const [habilitadoCargaMaternal, setHabilitadoCargaMaternal] = useState(false);
  const [habilitadoCargaInvalidez, setHabilitadoCargaInvalidez] =
    useState(false);
    const [mesesData, setMesesData] = useState([]);
    const [infoPeriodos, setInfoPeriodos] = useState([]);
    const [listaCFamiliares, setListaCFamiliares] = useState([]);
  const { refreshTable, setRefreshTable, screenSize } = useStateContext();
  const anyosData = UtilsFunctions.obtenerAnyosHastaActual();
  const trabajadorCfamiliarData = useSelector(
    (state) => state.datosTrabajadorCarga
  );
  const tramosCFamiliares = useSelector((state) => state.tramosCargaFamiliar);

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;

  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const dispatch = useDispatch();

  useEffect(() => {
    getPeriodosCargados()
    dispatch(listaTramosCFamiliar());
    const rutTrabajador = UtilsFunctions.rutFormatter(modalData.rutTrabajador, {
      dots: true,
    });
    setValue(
      "nombres",
      modalData.nombres +
        " " +
        modalData.apellidoPaterno +
        " " +
        modalData.apellidoMaterno
    );
    setValue("rut", rutTrabajador);
    setValue("trabajadorId", modalData.trabajadorId);
  }, []);

  useEffect(() => {
    if (trabajadorCfamiliarData.length > 0) {
      setListaCFamiliares(trabajadorCfamiliarData);
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("tramosCargaFamiliar"));
      reset({});
    };
  }, []);

  async function getPeriodosCargados(){
    const infoMeses = await axiosPrivate(`${api_service}/api/v1/periodos/cargados`, Authorization)
    setInfoPeriodos(infoMeses.data)
  }

  const handleYearChange = (e) => {
    const year = parseInt(e);
    const yearData = infoPeriodos.find((item) => item.anyo === year);
    if (yearData) {
      const filteredMonths = yearData.periodos.filter(
        (mes) => mes.datosPrevisionalesCargados === true
      );
      const transformedMonths = filteredMonths.map((month) => (
        UtilsFunctions.integerToMonth(month.mes)
      ));
      setMesesData(transformedMonths);

    } else {
      // Si no se encontró ningún año con el valor seleccionado, establecer mesesData como un arreglo vacío.
      setMesesData([]);
    }
  };

  async function onSubmit(data) {
    setBotonGuardarHabilitado(false)
    toast.loading("Editando Cargas Familiares", estilosAlerta);
    const copiaListaCFamiliares = listaCFamiliares;

    try {
      const info = await axiosPrivate.get(
        `${api_service}/api/v1/periodos/mes/${data.numeroMes}/anyo/${data.periodoAnyo}`,
        Authorization
      );
      setValue("periodoId", info.data.id);

      const payload = {
        trabajadorId: data.trabajadorId,
        numeroCargas: data.numeroCargas,
        tramoAsignacionFamiliarId: data.tramoId,
        periodoId: info.data.id,
        numeroCargasInvalidez: habilitadoCargaInvalidez ? 1 : 0,
        cargaFamiliarMaternal: habilitadoCargaMaternal,
      };
      const response = await axiosPrivate.put(
        `${api_service}/api/v1/trabajadores/actualizarcargatrabajador`,
        payload,
        Authorization
      );
      if (response.status === 200) {
        const elemento = copiaListaCFamiliares.filter(
          (e) =>
            e.periodo.mes === parseInt(getValues("numeroMes")) &&
            e.periodo.anyo === parseInt(getValues("periodoAnyo"))
        );
        const index = copiaListaCFamiliares.indexOf(elemento[0]);
        const CFamiliarEditar = copiaListaCFamiliares.splice(index, 1)[0];
        CFamiliarEditar["numeroCargas"] = data.numeroCargas;
        CFamiliarEditar.tramoAsignacionFamiliar["codigo"] = data.tramo.codigo;
        CFamiliarEditar["numeroCargasInvalidez"] = habilitadoCargaInvalidez ? 1 : 0 ;
        CFamiliarEditar["cargaFamiliarMaternal"] = habilitadoCargaMaternal;

        copiaListaCFamiliares.splice(index, 0, CFamiliarEditar);
        const nuevoArray = [...copiaListaCFamiliares];
        setListaCFamiliares(nuevoArray);
        updateTrabajadorCargaFamiliar(listaCFamiliares[0]?.numeroCargas);

        toast.dismiss();
        toast.success("Cargas Familiares editadas con éxito", estilosAlerta);
        setValue("numeroCargas", "0");
        setValue("numeroCargasInvalidez", "0");
        setValue("periodoAnyo", "");
        setValue("periodoMes", "");
        setValue("tramoId", "");
        setHabilitadoCargaMaternal(false);
        setHabilitadoCargaInvalidez(false);
      }
      setBotonGuardarHabilitado(true)
    } catch (error) {
      console.log(error);
      if (
        error.response &&
        error.response.status === 404 &&
        error.response.data.message ===
          "No existe registro de cargas familiares para el periodo seleccionado"
      ) {
        try {
          // Lógica adicional para actualizar la tabla cuando la API devuelve un estado 400
          const payload = {
            periodoId: getValues("periodoId"),
            trabajadorId: modalData?.trabajadorId,
            numeroCargas: data.numeroCargas,
            tramoAsignacionFamiliarId: data.tramoId,
            numeroCargasInvalidez: habilitadoCargaInvalidez ? 1 : 0,
            cargaFamiliarMaternal: habilitadoCargaMaternal,
          };
          const response = await axiosPrivate.post(
            `${api_service}/api/v1/trabajadores/agregarcargatrabajador`,
            payload,
            Authorization
          );
          copiaListaCFamiliares.push(response.data);
          copiaListaCFamiliares.sort((a, b) => b.periodo.id - a.periodo.id);
          const nuevoArray = [...copiaListaCFamiliares];
          console.log("nuevoArray", nuevoArray)
          setListaCFamiliares(nuevoArray);

          setRefreshTable(!refreshTable);
          toast.dismiss();
          toast(
            "No existía registro de Cargas Familiares para el trabajador en el periodo seleccionado.\nSe agregó un nuevo registro de Cargas Familiares en ese periodo.",
            estilosAlerta
          );
          setValue("numeroCargas", "0");
          setValue("numeroCargasInvalidez", "0");
          setValue("periodoAnyo", "");
          setValue("periodoMes", "");
          setValue("tramoId", "");
          setHabilitadoCargaMaternal(false);
          setHabilitadoCargaInvalidez(false);
          setBotonGuardarHabilitado(true)
        } catch (innerError) {
          console.log("error", innerError);
          toast.dismiss();
          toast.error(
            innerError.response.data.message
              ? innerError.response.data.message
              : "Error al editar Cargas Familiares",
            estilosAlerta
          );
          setBotonGuardarHabilitado(true)
        }
      } else {
        console.log(error);
        toast.dismiss();
        toast.error(
          error.response.data.message
            ? error.response.data.message
            : "Error al editar Cargas Familiares",
          estilosAlerta
        );
        setBotonGuardarHabilitado(true)
      }
    }
  }

  const COLUMNS = [
    {
      Header: "Periodo",
      accessor: (d) =>
        UtilsFunctions.periodoToText(d.periodo.mes, d.periodo.anyo),
    },
    {
      Header: "N° Cargas",
      accessor: (d) => d.numeroCargas,
    },
    {
      Header: "N° Cargas Invalidez",
      accessor: (d) => d.numeroCargasInvalidez,
    },
    {
      Header: "Carga Maternal",
      accessor: (d) => (d.cargaFamiliarMaternal ? "Si" : "No"),
    },
    {
      Header: "Tramo",
      accessor: (d) => d.tramoAsignacionFamiliar.codigo,
    },
  ];

  if (!isVisibleEditorCargas) {
    return null;
  }

  console.log("listaCFamiliares", listaCFamiliares)

  const loadingModal =
    trabajadorCfamiliarData.length > 0 && tramosCFamiliares.length > 0;

  return (
    <>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="segundoDivModal max-w-3xl">
            {/*content*/}
            <div className="tercerDivModal shadow-lg  max-h-[85vh]">
              {/*header*/}
              <div className="divHeaderModal border-slate-200">
                <h3 className="titulo-Modal">Cargas Familiares Trabajadores</h3>
              </div>
              {/*body*/}
              <div
                className={`relative p-6 flex-auto dark:bg-hercules-dark-bg-200 ${
                  !loadingModal ? "overflow-hidden" : "overflow-auto"
                }`}
              >
                {!loadingModal ? (
                  <div className="h-fix">
                    <ModalSpinner />
                  </div>
                ) : (
                  <>
                    <div className="md:flex md:flex-wrap mb-4">
                      <div className="mb-9 md:mb-6 md:w-1/2 md:pr-3">
                        <label
                          className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Nombre
                        </label>
                        <input
                          disabled
                          {...register("nombres")}
                          className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                          id="nombre"
                          type="text"
                        />
                      </div>

                      <div className="mb-9 md:mb-6 md:w-1/2 md:pl-3">
                        <label
                          className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          RUT
                        </label>
                        <input
                          disabled
                          {...register("rut")}
                          className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                          id="nombre"
                          type="text"
                        />
                      </div>
                    </div>

                    <div className="flex flex-wrap mb-3 md:mb-0">
                      {/* N° de Cargas */}
                      <div className="div-Inputs-EditarHaber md:w-2/12 md:pr-3">
                        <label
                          className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Cargas
                        </label>
                        <input
                          {...register("numeroCargas", {
                            required: {
                              value: true,
                              message: "Obligatorio",
                            },
                            onChange: (e) => {
                              if (parseInt(e.target.value) === 0) {
                                setValue("numeroCargasInvalidez", 0);
                                setHabilitadoCargaMaternal(false);
                                setHabilitadoCargaInvalidez(false);
                              }
                            },
                          })}
                          className=" appearance-none block w-20 md:w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="number"
                          min="0"
                        />
                        <span
                          className={`text-red-500 text-xs italic ${
                            !errors.numeroCargas && "opacity-0 invisible"
                          }`}
                        >
                          {errors.numeroCargas
                            ? errors.numeroCargas.message
                            : "hidden"}
                        </span>
                      </div>

                      <div className="relative md:w-2/12 w-4/12 md:pl-3 md:pr-2 pr-3">
                        <label
                          className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Periodo
                        </label>
                        <select
                          {...register("periodoAnyo", {
                            onChange: (e) => {
                              setValue("periodoMes", "");
                              handleYearChange(e.target.value)
                            },
                            required: {
                              value: true,
                              message: "Obligatorio",
                            },
                          })}
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        >
                          <option hidden value="">
                            Año
                          </option>
                          {anyosData.map((anyo, i) => (
                            <option value={anyo} key={i}>
                              {anyo}
                            </option>
                          ))}
                        </select>
                        <span
                          className={`text-red-500 text-xs italic ${
                            !errors.periodoAnyo && "opacity-0 invisible"
                          }`}
                        >
                          {errors.periodoAnyo
                            ? errors.periodoAnyo.message
                            : "hidden"}
                        </span>
                      </div>

                      <div className="relative md:w-3/12 w-6/12 pt-1">
                        <select
                          {...register("periodoMes", {
                            onChange: (e) => {
                              // setBotonAgregarVisible(false);
                              setValue("numeroMes", e.target.selectedIndex);
                            },
                            required: {
                              value: true,
                              message: "Seleccione un Mes",
                            },
                          })}
                          disabled={watch("periodoAnyo") === "" ? true : false}
                          className="block w-full mt-6 appearance-none bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        >
                          <option hidden value="">
                            {watch("periodoAnyo") === "" ? "Mes" : "Mes"}
                          </option>
                          {mesesData.map((mes, i) => (
                            <option value={mes} key={i}>
                              {mes}
                            </option>
                          ))}
                        </select>
                        <span
                          className={`text-red-500 text-xs italic ${
                            !errors.periodoMes && "opacity-0 invisible"
                          }`}
                        >
                          {errors.periodoMes
                            ? errors.periodoMes.message
                            : "hidden"}
                        </span>
                      </div>
                    </div>

                    <div className="w-full md:w-6/12 mb-7 flex items-center">
                      <div className="flex flex-wrap">
                        <span className="tracking-wide dark:text-white text-hercules-light-font-secondary text-14 font-bold my-auto mr-5 md:mr-3">
                          Una de la Cargas es de Invalidez
                        </span>
                        <label className="inline-flex relative items-center cursor-pointer">
                          <input
                            type="checkbox"
                            className="sr-only peer"
                            disabled={
                              parseInt(watch("numeroCargas")) === 0 ||
                              watch("numeroCargas") === ""
                            }
                            checked={habilitadoCargaInvalidez}
                            readOnly
                          />
                          <div
                            onClick={() => {
                              setHabilitadoCargaInvalidez(
                                !habilitadoCargaInvalidez
                              );
                            }}
                            className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"
                          ></div>
                        </label>
                        {/* <input
                          {...register("numeroCargasInvalidez", {
                            required: {
                              value: true,
                              message: "Obligatorio",
                            },
                          })}
                          className=" appearance-none block w-28 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="number"
                          min="0"
                          disabled={parseInt(watch("numeroCargas")) === 0 || watch("numeroCargas") === ""}
                          max={1}
                        />
                        <span
                          className={`text-red-500 text-xs italic ${
                            !errors.numeroCargasInvalidez &&
                            "opacity-0 invisible"
                          }`}
                        >
                          {errors.numeroCargasInvalidez
                            ? errors.numeroCargasInvalidez.message
                            : "hidden"}
                        </span> */}
                      </div>
                    </div>

                    <div className="w-full md:w-6/12 mb-7 flex items-center">
                      <div className="flex flex-wrap">
                        <span className="tracking-wide dark:text-white text-hercules-light-font-secondary text-14 font-bold my-auto mr-5 md:mr-3">
                        Una de las Cargas es Maternal
                        </span>
                        <label className="inline-flex relative items-center cursor-pointer">
                          <input
                            type="checkbox"
                            className="sr-only peer"
                            disabled={
                              parseInt(watch("numeroCargas")) === 0 ||
                              watch("numeroCargas") === ""
                            }
                            checked={habilitadoCargaMaternal}
                            readOnly
                          />
                          <div
                            onClick={() => {
                              setHabilitadoCargaMaternal(
                                !habilitadoCargaMaternal
                              );
                            }}
                            className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"
                          ></div>
                        </label>
                      </div>
                    </div>

                    <div className="md:flex md:flex-wrap">
                      <div className="relative w-full md:w-4/12 mb-1 md:mb-6 ">
                        <label
                          className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Tramo
                        </label>
                        <select
                          {...register("tramoId", {
                            required: {
                              value: true,
                              message: "Obligatorio",
                            },
                            onChange: (e) => {
                              const tramo =
                                tramosCFamiliares[e.target.selectedIndex - 1];
                              setValue("tramo", tramo);
                            },
                          })}
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        >
                          <option hidden value="">
                            Elegir tramo
                          </option>
                          {tramosCFamiliares.map((tramo, i) => (
                            <option value={tramo.id} key={i}>
                              {tramo.codigo + " - " + tramo.glosa}
                            </option>
                          ))}
                        </select>
                        <span
                          className={`text-red-500 text-xs italic ${
                            !errors.tramoId && "opacity-0 invisible"
                          }`}
                        >
                          {errors.tramoId ? errors.tramoId.message : "hidden"}
                        </span>
                      </div>

                      <div className="w-full md:pl-3 md:w-3/12 mb-6 mt-3 md:mt-7">
                        <button
                  disabled={!botonGuardarHabilitado}
                  className={` ${!botonGuardarHabilitado? "bg-gray-500" : "bg-hercules-light-primary-button hover:shadow-lg hover:scale-105"}  text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400`}
                          type="submit"
                        >
                          Agregar
                        </button>
                      </div>
                    </div>

                    <SimpleTable
                      col={COLUMNS}
                      tableData={listaCFamiliares}
                      searchBar={false}
                      showFilters={false}
                      tableHeight="h-72"
                    />
                  </>
                )}
              </div>
              {/*footer*/}
              <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                  type="button"
                  onClick={() => {
                    onCloseEditorCargas();
                    reset();
                    setHabilitadoCargaMaternal(false);
                    setHabilitadoCargaInvalidez(false);
                  }}
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </form>
    </>
  );
}
