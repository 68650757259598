import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSecciones, hideLoader, limpiarInfoMenuEdicion, showLoader } from "../../Redux/Actions";
import { useStateContext } from "../../contexts/ContextProvider";
import { ColumnFilter, EditarSeccion, NuevaSeccion } from "../../components";
import UtilsFunctions from "../../utils/UtilsFunctions";
import SimpleTable from "../../components/SimpleTable";
import ColumnFilterOptions from "../../components/ColumnFilterOptions";

const SeccionArea = () => {
  const { refreshTable, activeMenu } = useStateContext();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loader);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader());
      await Promise.all([
        dispatch(getSecciones()),
      ]);
      dispatch(hideLoader());
    };
    fetchData().catch((error) => {
      console.log(error.response.data.message);
      dispatch(hideLoader());
    });
  }, [refreshTable, dispatch]);

  useEffect(() =>{
    return () => {
      dispatch(limpiarInfoMenuEdicion("getSecciones"))
    }
  },[])

  const secciones = useSelector((state) => state.getSecciones);

  const [showModalNuevaSeccionArea, setShowModalNuevaSeccionArea] =
    useState(false);

  const [showModalEditarSeccionArea, setshowModalEditarSeccionArea] =
    useState(false);
  const [seccionAreaData, setSeccionAreaData] = useState({});

  const COLUMNS = [
    {
      Header: "Nombre",
      accessor: "nombre",
      Filter: ColumnFilter,
    },
    {
      Header: "Descripcion",
      accessor: "descripcion",
      Filter: ColumnFilter,
    },
    {
      Header: "Empresa",
      accessor:
        "seccionesHasCentrosCostos[0].centroCosto.empresa.nombreFantasia",
      Filter: ColumnFilter,
    },
    {
      Header: "Centro de costo",
      accessor: (d) => {
        return d.seccionesHasCentrosCostos.map((e, index) =>
          index === 0 ? e.centroCosto.nombre : ", " + e.centroCosto.nombre
        );
      },
      Filter: ColumnFilter,
    },
    {
      Header: "Activo",
      accessor: "activo",
      Filter: ColumnFilterOptions,
      Cell: ({ value }) => {
        if (value === true) {
          return "Si";
        } else {
          return "No";
        }
      },
    },
  ];

  const headersCsv = [
    {
      key: "nombre",
      name: "Nombre",
    },
    {
      key: "descripcion",
      name: "Descripcion",
    },
    {
      key: "empresa",
      name: "Empresa",
    },
    {
      key: "centroCosto",
      name: "Centro de costo",
    },
    {
      key: "activo",
      name: "Activo",
    },
  ];

  return (
    <div
    className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
      activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">
        Edición
      </div>
      <div className="titulo-Pagina">
        Sección/Área
      </div>
      <div className="h-full md:mr-12 rounded-lg pt-5">
          <SimpleTable
            col={COLUMNS}
            tableData={secciones || []}
            primaryButton={{
              type: "editar",
              onClickFunc: (data) => {
                setshowModalEditarSeccionArea(true);
                setSeccionAreaData(data);
              },
              active: true,
            }}
            exportCsv={() =>
              UtilsFunctions.jsonToCSV3(secciones, headersCsv, "Seccion/Area")
            }  
          />

        <button
          onClick={() => setShowModalNuevaSeccionArea(true)}
          disabled={isLoading}
          className={`mt-3 ${isLoading? "bg-gray-500" : "bg-hercules-light-primary-button hover:shadow-lg"} text-white dark:text-dark-primary font-medium uppercase text-normal px-4 py-3 rounded shadow  outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}  
      >
          Nueva Sección/Área
        </button>
      </div>
      {showModalNuevaSeccionArea &&
      <NuevaSeccion
        isVisible={showModalNuevaSeccionArea}
        onClose={() => setShowModalNuevaSeccionArea(false)}
      />
    }
    {showModalEditarSeccionArea &&
      <EditarSeccion
        isVisible={showModalEditarSeccionArea}
        onClose={() => setshowModalEditarSeccionArea(false)}
        modalData={seccionAreaData}
      />
    }
    </div>
  );
};

export default SeccionArea;
