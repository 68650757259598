import React, { useMemo, useState } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useFilters,
  usePagination,
  useRowSelect,
} from "react-table";
import {
  TbArrowsSort,
  TbSortAscending,
  TbSortDescending,
} from "react-icons/tb";
import GlobalFilter from "../../GlobalFilter";
import { BsDownload } from "react-icons/bs";
import { BiSearch } from "react-icons/bi";
import {
  MdArrowBackIos,
  MdArrowForwardIos,
  MdLastPage,
  MdFirstPage,
  MdOutlineVisibility,
} from "react-icons/md";
import { VscNewFile } from "react-icons/vsc";
import { useStateContext } from "../../../contexts/ContextProvider";
import { axiosPrivate } from "../../../common/axiosPrivate";
import { useSelector } from "react-redux";
import Skeleton from "../../Skeleton";
export default function TablaContratosAnexos({
  col,
  tableData,
  botonCrearAnexo,
  botonVer,
  abrirModalNuevoAnexo,
  abrirModalVerContratoAnexo,
  exportCsv,
}) {
  // eslint-disable-next-line
  const columns = useMemo(() => col, []);
  const data = useMemo(() => [...tableData], [tableData]);
  const { setVerAnexoContrato, setContratoId } = useStateContext();
  const { setRowDataAnexo } = useStateContext();
  const isLoading = useSelector((state) => state.loader);

  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    canPreviousPage,
    canNextPage,
    gotoPage,
    pageCount,
    setPageSize,
    pageOptions,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      // dataAnexo,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const { pageIndex, pageSize } = state;

  const { globalFilter } = state;

  async function handlePostCrearAnexo(fila) {
    try {
      const info = await axiosPrivate(
        `${api_service}/api/v1/contratos/trabajador/${fila.trabajadorId}/nuevo`,
        Authorization
      );
      setRowDataAnexo(info.data);
      abrirModalNuevoAnexo();
    } catch (error) {
      console.log(error);
    }
  }

  async function handlePostVerContratoAnexo(fila) {
    try {
      const info = await axiosPrivate(
        `${api_service}/api/v1/contratos/${fila.contratoId}/version/${fila.version}`,
        Authorization
      );
      console.log("info", info);
      setRowDataAnexo(info.data);
      setVerAnexoContrato(true);
      abrirModalVerContratoAnexo();
      setContratoId(fila.contratoId)
    } catch (error) {
      console.log(error);
    }
  }

  if (isLoading) {
    return (
      <Skeleton
        width={"full"}
        height="h-fix"
        loaderMessage="Cargando datos..."
        searchBar={true}
        pagination={true}
      />
    );
  }

  return (
    <>
      <div className="bg-white dark:bg-hercules-dark-bg-100 border-t  border-l border-r border-gray-300 dark:border-dark-bg-200 h-12 flex rounded-t-md p-3">
        <>
          <span className="ml-auto ">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          </span>
          <span className="mx-3 pt-1 text-xl dark:text-dark-text-100 text-black hover:cursor-pointer">
            <BiSearch />
          </span>
        </>

        <span className={`border-1 mt-1`}></span>
        <span className="ml-3 pt-0.5 text-black dark:text-dark-text-100 text-xl hover:cursor-pointer">
          <BsDownload onClick={() => exportCsv()} />
        </span>
      </div>

      {/* <div className="h-fix overflow-auto border-x-1 border-t-1 "> */}
      <div className="h-fix overflow-auto border-x-1  bg-white dark:bg-hercules-dark-bg-200  dark:border-dark-bg-200 border-gray-300 border-t-1">
        <table {...getTableProps()} className="w-full  rounded-lg">
          {/* <thead className="text-left sticky top-0 bg-gray-200"> */}
          <thead className="text-left sticky top-0 border-b-1 border-b-hercules-light-secondary dark:border-b-hercules-dark-secondary-button bg-hercules-light-table-header dark:bg-hercules-dark-bg-200">
            {headerGroups.map((headerGroup) => {
              const { key, ...restHeaderGroup } =
                headerGroup.getHeaderGroupProps();
              return (
                <React.Fragment key={key}>
                  <tr {...restHeaderGroup} className="h-10 flex-auto">
                    {headerGroup.headers.map((column) => {
                      const { key, ...restColumn } = column.getHeaderProps(
                        column.getSortByToggleProps()
                      );
                      return (
                        <React.Fragment key={key}>
                          <th
                            {...restColumn}
                            className="py-2 px-4 text-hercules-light-font-secondary font-medium dark:text-white"
                          >
                            <div className="flex">
                              <span className="">
                                {column.render("Header")}
                              </span>
                              <span className="ml-1 mt-auto mb-auto">
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <TbSortDescending />
                                  ) : (
                                    <TbSortAscending />
                                  )
                                ) : (
                                  <TbArrowsSort />
                                )}
                              </span>
                            </div>
                          </th>
                        </React.Fragment>
                      );
                    })}
                    {botonCrearAnexo && (
                      <th className="py-2 px-4 text-hercules-light-font-secondary font-medium dark:text-white">
                        Crear Anexo
                      </th>
                    )}
                    {botonVer && (
                      <th className="py-2 px-4 text-hercules-light-font-secondary font-medium dark:text-white">
                        Ver Contrato/ Anexo
                      </th>
                    )}
                  </tr>
                  <tr {...headerGroup.getHeaderGroupProps()} className="h-10">
                    {headerGroup.headers.map((column) => {
                      const { key, ...restColumn } = column.getHeaderProps();
                      return (
                        <th key={key} {...restColumn} className="pl-4 pb-4">
                          <div className="w-fit pr-4">
                            {column.canFilter ? column.render("Filter") : null}
                          </div>
                        </th>
                      );
                    })}
                    {botonCrearAnexo && <th></th>}
                    {botonVer && <th></th>}
                  </tr>
                </React.Fragment>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()} className="h-full">
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  key={i}
                  {...row.getRowProps()}
                  className="group bg-white dark:bg-hercules-dark-bg-200   dark:text-dark-text-100 dark:border-b-hercules-dark-secondary-button border-b border-hercules-light-secondary text-black h-12 dark:hover:bg-white dark:hover:bg-opacity-10 transition duration-100 ease-in-out hover:bg-light-accent hover:bg-opacity-20"
                >
                  {row.cells.map((cell, i) => {
                    return (
                      <td
                        key={i}
                        {...cell.getCellProps}
                        className="py-2 px-4 text-base font-normal"
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                  {botonCrearAnexo && row.original.estado !== 3 ? (
                    <td className="mx-6">
                      <VscNewFile
                        className="mr-3 text-2xl group-hover:cursor-pointer text-green-500"
                        onClick={() => {
                          handlePostCrearAnexo(row.original);
                        }}
                      />
                    </td>
                  ) : (
                    <td className="mx-6"></td>
                  )}
                  {botonVer && (
                    <td className="mx-6">
                      <MdOutlineVisibility
                        className="mr-3 text-2xl group-hover:cursor-pointer text-blue-500"
                        onClick={() => {
                          handlePostVerContratoAnexo(row.original);
                        }}
                      />
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className=" border-1 dark:border-t-hercules-dark-secondary-button border-t-hercules-light-secondary border-gray-300 dark:border-dark-bg-200 dark:bg-hercules-dark-bg-200   rounded-b-md h-14 flex bg-white">
        <div className="ml-auto py-3 flex">
          <p className="hidden sm:block pt-1 text-black dark:text-white">
            Filas por página
          </p>
          <select
            name=""
            id=""
            className="ml-2 h-8 text-sm bg-white border  rounded-sm"
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block p-4 text-black dark:text-white">
          Página {pageIndex + 1} de {pageOptions.length}
        </div>

        <div className="flex mr-4 pl-4">
          <button
            className="bg-white text-black dark:text-white dark:bg-opacity-0 rounded-full hover:bg-gray-200 hover:text-black my-2 w-8 mr-6"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <MdFirstPage className="pl-1 text-2xl" />
          </button>
          <button
            className="bg-white text-black rounded-full dark:text-white dark:bg-opacity-0  hover:bg-gray-200 hover:text-black my-2 mr-6 w-8"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <MdArrowBackIos className="ml-2" />
          </button>
          <button
            className="bg-white text-black rounded-full dark:text-white dark:bg-opacity-0  hover:bg-gray-200 hover:text-black my-2 mr-6 w-8"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <MdArrowForwardIos className="ml-2" />
          </button>
          <button
            className="bg-white text-black rounded-full dark:text-white dark:bg-opacity-0  hover:bg-gray-200 hover:text-black my-2 w-8"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <MdLastPage className="pl-1 text-2xl" />
          </button>
        </div>
      </div>
    </>
  );
}
