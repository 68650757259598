import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  hideLoader,
  limpiarInfoMenuEdicion,
  obtenerHorasExtrasCanjeadasEmpresa,
  showLoader,
} from "../../Redux/Actions";
import { ColumnFilter } from "../../components";
import { useStateContext } from "../../contexts/ContextProvider";
import UtilsFunctions from "../../utils/UtilsFunctions";
import SimpleTable from "../../components/SimpleTable";
import RutColumnFilter from "../../components/RutColumnFilter";
import ModalGenerarComprobante from "../../components/Movimientos/GenerarComprobanteVacaciones/ModalGenerarComprobante";

export default function GenerarComprobanteVacaciones() {
  const [modalDataAdministrar, setModalDataAdministrar] = useState(null);
  const [showModalComprobante, setShowModalComprobante] = useState(false);
  const { refreshTable, activeMenu } = useStateContext();
  const dispatch = useDispatch();

  const empresaId = localStorage.getItem("empresaId");

  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader());
      await Promise.all([
        dispatch(obtenerHorasExtrasCanjeadasEmpresa(empresaId)),
      ]);
      dispatch(hideLoader());
    };

    fetchData().catch((error) => {
      console.log(error.response.data.message);
      dispatch(hideLoader());
    });
  }, [refreshTable, dispatch]);

  const infoHorasCanjeadasEmpresa = useSelector(
    (state) => state.infoHorasExtrasCanjeadasEmpresa
  );

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("infoHorasExtrasCanjeadasEmpresa"));
    };
  }, []);

  const COLUMNS = [
    {
      Header: "RUT",
      accessor: "rut",
      Cell: ({ value }) => UtilsFunctions.rutFormatter(value, { dots: true }),
      Filter: RutColumnFilter,
    },
    {
      Header: "Trabajador",
      accessor: (d) =>
        d.apellidoPaterno + " " + d.apellidoMaterno + " " + d.nombres,
      Filter: ColumnFilter,
    },
    {
        Header: "Total Días Canjeados",
        accessor: "totalDiasCanjeados",
        Filter: ColumnFilter,
        Cell: ({ value }) => {
          if (value === null) {
            return "0 días";
          } else if (value === 1) {
            return "1 día";
          } else {
            return value + " días";
          }
        },
      }, 
    {
      Header: "Total Días Canjeados Disponibles",
      accessor: "totalDiasCanjeadosDisponibles",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value === null) {
          return "0 días";
        } else if (value === 1) {
          return "1 día";
        } else {
          return value + " días";
        }
      },
    },   
  ];

  const headersCsv = [
    {
      key: "rut",
      name: "RUT",
    },
    {
      key: "nombres",
      name: "Nombres",
    },
    {
      key: "apellidoPaterno",
      name: "Apellido Paterno",
    },
    {
      key: "apellidoMaterno",
      name: "Apellido Materno",
    },
    {
      key: "Horas Extra",
      name: "horasExtra",
    },
  ];

  return (
    <>
      <div
        className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
          activeMenu && "md:w-[calc(100vw_-_23rem)]"
        }`}
      >
        <div className="titulo-Menu">Movimientos</div>
        <div className="titulo-Pagina">Generar Comprobante Vacaciones</div>
        <div className="h-full md:mr-12 rounded-lg pt-5">
          <SimpleTable
            col={COLUMNS}
            tableData={infoHorasCanjeadasEmpresa || []}
            secondaryButton={{
              type: "comprobante",
              onClickFunc: (data) => {
                setShowModalComprobante(true);
                setModalDataAdministrar(data);
              },
              active: true,
            }}
            exportCsv={() =>
              UtilsFunctions.jsonToCSV3(infoHorasCanjeadasEmpresa, headersCsv, "Haberes")
            }
            hasPagination={true}
          />
        </div>
      </div>

      {showModalComprobante && (
        <ModalGenerarComprobante
          isVisible={showModalComprobante}
          onClose={() => setShowModalComprobante(false)}
          modalData={modalDataAdministrar}
        />
      )}

    </>
  );
}
