import { axiosPrivate } from "../../../../common/axiosPrivate";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStateContext } from "../../../../contexts/ContextProvider";
import {
  getEmpresas,
  getRolesActivos,
} from "../../../../Redux/Actions";
import UtilsFunctions from "../../../../utils/UtilsFunctions";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";

export default function EditarUsuarios({ isVisible, onClose, modalData }) {
  const [habilitado, setHabilitado] = useState(true);
  const { refreshTable, setRefreshTable, screenSize } = useStateContext();
  const inforoles = useSelector((state) => state.rolesActivos);
  const empresas = useSelector((state) => state.empresas);
  const [botonGuardarHabilitado, setBotonGuardarHabilitado] = useState(true)

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;

  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");

  const dispatch = useDispatch();

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      empresasUsuario: [], //solo se agrego este campo como default porque teniamos que inicializarlo como array para que funcionara el checkbox en la linea 193
    },
  });

  useEffect(() => {
    dispatch(getRolesActivos());
    dispatch(getEmpresas());
    if (modalData) {
      reset({
        nombre: modalData.nombre,
        user: modalData.user,
        rolId: modalData.roles[0].id,
        empresasUsuario: modalData.usuariosHasEmpresas.map((item) =>
          item.empresaId.toString()
        ),
      });
      setHabilitado(modalData.activo);
    }
  }, [modalData, isVisible, dispatch, reset]);

  const onSubmit = async (data) => {
    setBotonGuardarHabilitado(false)
    try {
      toast.loading("Guardando cambios", estilosAlerta);
      const payload = {
        idUsuario: modalData.id,
        usuario: data.user,
        nombre: data.nombre,
        rolId: data.rolId,
        activo: habilitado,
        empresas: data.empresasUsuario,
      };
      console.log("payload", payload);
      const response = await axiosPrivate.put(
        `${api_service}/api/v1/usuarios/editarusuario`,
        payload,
        Authorization
      );
      if (response.status === 200) {
        toast.dismiss();
        toast.success("Usuario editado correctamente", estilosAlerta);
        setRefreshTable(!refreshTable);
        onClose();
        reset();
        setHabilitado(true);
      }
      setBotonGuardarHabilitado(true)
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error(error.response.data.message ? error.response.data.message : "Error al editar Usuario", estilosAlerta);
      setBotonGuardarHabilitado(true)
    }
  };

  const emailValidation = {
    required: {
      value: true,
      message: "Obligatorio",
    },
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: "Email no es válido",
    },
  };

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="primerDivModal">
          <div className="segundoDivModal max-w-lg">
            {/*content*/}
            <div className="tercerDivModal shadow-lg  max-h-[85vh] ">
              {/*header*/}
              <div className="divHeaderModal border-slate-200">
                <h3 className="titulo-Modal">Editar Usuario</h3>
              </div>
              <div className="relative p-6 flex-auto overflow-auto dark:bg-hercules-dark-bg-200">
                <div className="div-Inputs-EditarHaber">
                  <label
                    className="block uppercase dark:text-white tracking-wide text-hercules-light-font-secondary text-xs font-bold mb-2"
                    htmlFor="grid-first-name"
                  >
                    Email
                  </label>
                  <input
                    {...register("user", emailValidation)}
                    className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:border-gray-500 bg-gray-200 border-gray-200"
                    type="text"
                    placeholder="ejemplo@ejemplo.com"
                  />
                  <span
                    className={`text-red-500 dark:text-red-400 text-xs italic ${
                      !errors.user && "opacity-0 invisible"
                    }`}
                  >
                    {errors.user ? errors.user.message : "hidden"}
                  </span>
                </div>

                <div className="div-Inputs-EditarHaber">
                  <label
                    className="block uppercase dark:text-white tracking-wide text-hercules-light-font-secondary text-xs font-bold mb-2"
                    htmlFor="grid-first-name"
                  >
                    Nombre Completo
                  </label>
                  <input
                    {...register("nombre", {
                      required: {
                        value: true,
                        message: "Obligatorio",
                      },
                      minLength: {
                        value: 3,
                        message: "Al menos 3 caracteres",
                      },
                      maxLength: {
                        value: 50,
                        message: "Máximo 50 caracteres",
                      },
                    })}
                    className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:border-gray-500 bg-gray-200 border-gray-200"
                    type="text"
                  />
                  <span
                    className={`text-red-500 dark:text-red-400 text-xs italic ${
                      !errors.nombre && "opacity-0 invisible"
                    }`}
                  >
                    {errors.nombre ? errors.nombre.message : "hidden"}
                  </span>
                </div>

                <div className="div-Inputs-EditarHaber">
                  <label className="block uppercase dark:text-white tracking-wide text-hercules-light-font-secondary text-xs font-bold mb-2">
                    Seleccionar Rol
                  </label>
                  <select
                    {...register("rolId", {
                      required: { value: true, message: "Obligatorio" },
                    })}
                    className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:border-gray-500 bg-gray-200 border-gray-200"
                  >
                    {inforoles.map((rol) => (
                      <option key={rol.id} value={rol.id}>
                        {UtilsFunctions.rolFormatter(rol.nombre)}
                      </option>
                    ))}
                  </select>
                  <span
                    className={`text-red-500 dark:text-red-400 text-xs italic ${
                      !errors.rolId && "opacity-0 invisible"
                    }`}
                  >
                    {errors.rolId ? errors.rolId.message : "hidden"}
                  </span>
                </div>

                <div className="div-Inputs-EditarHaber">
                  <label
                    className="block uppercase dark:text-white tracking-wide text-hercules-light-font-secondary text-xs font-bold mb-2"
                    htmlFor="grid-last-name"
                  >
                    Seleccionar Empresas a las cuales el usuario tendrá acceso:
                  </label>
                  <div className="border-l-2 border-t-2 border-r-2 border-b-2 h-auto h-max-48 overflow-y-auto px-3">
                    {empresas.map((empresa) => (
                      <div key={empresa.id} className="my-1 border-b-2">
                        <input
                          type="checkbox"
                          {...register("empresasUsuario", {
                            validate: (value) =>
                              value.length > 0 ||
                              "Debe seleccionar al menos una empresa",
                          })}
                          value={empresa.id}
                        />
                        <label className="pl-3 dark:text-white text-hercules-light-font-secondary">
                          {empresa.nombreFantasia}
                        </label>
                      </div>
                    ))}
                  </div>
                  <span
                    className={`text-red-500 dark:text-red-400 text-xs italic ${
                      !errors.empresasUsuario && "opacity-0 invisible"
                    }`}
                  >
                    {errors.empresasUsuario
                      ? errors.empresasUsuario.message
                      : "hidden"}
                  </span>
                </div>

                <div className="flex flex-wrap mt-6">
                  <span className="uppercase dark:text-white tracking-wide text-hercules-light-font-secondary text-xs font-bold my-auto mr-10 mb-1 md:mb-0 ">
                    Habilitado
                  </span>
                  <label className="inline-flex relative items-center hover:cursor-pointer">
                    <input
                      type="checkbox"
                      className="sr-only peer"
                      checked={habilitado}
                      readOnly
                    />
                    <div
                      onClick={() => {
                        setHabilitado(!habilitado);
                      }}
                      name="activo"
                      className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"
                    ></div>
                  </label>
                </div>
              </div>
              <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                  type="button"
                  onClick={() => {
                    reset();
                    onClose();
                    setHabilitado(true);
                  }}
                >
                  Cerrar
                </button>
                <button
              disabled={!botonGuardarHabilitado}
              className={` ${!botonGuardarHabilitado? "bg-gray-500" : "bg-hercules-light-primary-button hover:shadow-lg hover:scale-105"}  text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400`}
                  type="submit"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </form>
    </>
  );
}
