import React, { useMemo } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";
import {
  TbArrowsSort,
  TbSortAscending,
  TbSortDescending,
} from "react-icons/tb";
import { BiSearch } from "react-icons/bi";
import {
  MdArrowBackIos,
  MdArrowForwardIos,
  MdLastPage,
  MdFirstPage,
} from "react-icons/md";
import { useStateContext } from "../../../../contexts/ContextProvider";
import { axiosPrivate } from "../../../../common/axiosPrivate";
import GlobalFilter from "../../../GlobalFilter";
import { toast } from "react-hot-toast";
import Skeleton from "../../../Skeleton";
import { useSelector } from "react-redux";

export default function RolTabla ({ col, tableData }) {
  // eslint-disable-next-line
  const columns = useMemo(() => col, []);
  const data = useMemo(() => [...tableData], [tableData]);
  const { refreshTable, setRefreshTable, setRowData, screenSize } = useStateContext();

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const REACT_APP_API_HERCULES_SERVICE =
    process.env.REACT_APP_API_HERCULES_SERVICE;

  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
    },
  };

  const handleActivarDesactivar = async (row) => {
    try {
      if (row.original.activo) {
        toast.loading("Desactivando Rol", estilosAlerta);
      } else {
        toast.loading("Activando Rol", estilosAlerta);
      }
      const data = {
        activo: !row.original.activo,
        rolId: row.original.id,
      };
      const response = await axiosPrivate.patch(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/rol/activardesactivarrol`,
        data,
        Authorization
      );
      if (response.status === 200) {
        setRefreshTable(!refreshTable);
      }
      toast.dismiss();
      if (row.original.activo) {
        toast.success("Rol desactivado correctamente", estilosAlerta);
      } else {
        toast.success("Rol activado correctamente", estilosAlerta);
      }
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error(error.response.data.message ? error.response.data.message : "Error al editar Rol", estilosAlerta);
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    canPreviousPage,
    canNextPage,
    gotoPage,
    pageCount,
    setPageSize,
    pageOptions,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const { pageIndex, pageSize } = state;

  const { globalFilter } = state;

  const isLoading = useSelector((state) => state.loader);

  if (isLoading) {
    return (
      <Skeleton
            width={"full"}
            height="h-fix"
            loaderMessage="Cargando datos..."
            searchBar={true}
            pagination={true}
          />
    )
  }

  return (
    <>
      <div className="bg-white dark:bg-hercules-dark-bg-100 border-t  border-l border-r border-gray-300 dark:border-dark-bg-200 h-12 flex rounded-t-md p-3">
        <span className="ml-auto">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        </span>
        <span className="mx-3 pt-1 text-xl dark:text-dark-text-100 text-black hover:cursor-pointer">
          <BiSearch />
        </span>
      </div>
      <div className="h-fix overflow-auto border-x-1 border-t-1 bg-white dark:bg-hercules-dark-bg-200  dark:border-dark-bg-200 border-gray-300 ">
        <table {...getTableProps()} className="w-full rounded-lg">
          <thead className="text-left sticky top-0 border-b-1 border-b-hercules-light-secondary dark:border-b-hercules-dark-secondary-button bg-hercules-light-table-header dark:bg-hercules-dark-bg-100">
            {headerGroups.map((headerGroup) => {
              const { key, ...restHeaderGroup } =
                headerGroup.getHeaderGroupProps();
              return (
                <React.Fragment key={key}>
                  <tr {...restHeaderGroup} className="flex-auto">
                    {headerGroup.headers.map((column) => {
                      const { key, ...restColumn } = column.getHeaderProps(
                        column.getSortByToggleProps()
                      );
                      return (
                        <React.Fragment key={key}>
                          <th {...restColumn} className="py-2 px-4 text-hercules-light-font-secondary font-medium dark:text-white">
                            <div className="flex">
                              <span className="">
                                {column.render("Header")}
                              </span>
                              <span className="ml-1 mt-auto mb-auto">
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <TbSortDescending />
                                  ) : (
                                    <TbSortAscending />
                                  )
                                ) : (
                                  <TbArrowsSort />
                                )}
                              </span>
                            </div>
                          </th>
                        </React.Fragment>
                      );
                    })}
                    <th className="px-3 text-hercules-light-font-secondary font-medium dark:text-white">Activar/Desactivar</th>
                  </tr>
                </React.Fragment>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()} className="h-full">
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  key={i}
                  {...row.getRowProps()}
                  onClick={() => {
                    setRowData(row.original);
                  }}
                  className="group bg-white dark:bg-hercules-dark-bg-200   dark:text-dark-text-100 dark:border-b-hercules-dark-secondary-button border-b border-hercules-light-secondary text-black h-12 dark:hover:bg-white dark:hover:bg-opacity-10 transition duration-100 ease-in-out hover:bg-light-accent hover:bg-opacity-20"
                >
                  {row.cells.map((cell, i) => {
                    return (
                      <td
                        key={i}
                        {...cell.getCellProps}
                        className="py-2 px-4 text-14 font-normal"
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                  {row.original.activo === true ? (
                    <td>
                      <button
                        className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => handleActivarDesactivar(row)}
                      >
                        Desactivar
                      </button>
                    </td>
                  ) : (
                    <td>
                      <button
                        className="bg-emerald-500  text-white active:bg-red-600 font-bold uppercase text-xs px-7 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => handleActivarDesactivar(row)}
                      >
                        Activar
                      </button>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className=" border-1 dark:border-t-hercules-dark-secondary-button border-t-hercules-light-secondary border-gray-300 dark:border-dark-bg-200 dark:bg-hercules-dark-bg-200   rounded-b-md h-14 flex bg-white">
        <div className="ml-auto py-4 flex">
          <p className="hidden sm:block pt-1 text-black dark:text-white">Filas por página</p>
          <select
            name=""
            id=""
            className="ml-2 text-sm h-8 bg-white border rounded-sm"
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block p-4 text-black dark:text-white">
          Página {pageIndex + 1} de {pageOptions.length}
        </div>
        <div className="flex mr-4 pl-4">
          <button
            className="bg-white text-black dark:text-white dark:bg-opacity-0 rounded-full hover:bg-gray-200 hover:text-black my-2 w-8 mr-6"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <MdFirstPage className="pl-1 text-2xl" />
          </button>
          <button
            className="bg-white text-black dark:text-white dark:bg-opacity-0 rounded-full hover:bg-gray-200 hover:text-black my-2 w-8 mr-6"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <MdArrowBackIos className="ml-2" />
          </button>
          <button
            className="bg-white text-black dark:text-white dark:bg-opacity-0 rounded-full hover:bg-gray-200 hover:text-black my-2 w-8 mr-6"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <MdArrowForwardIos className="ml-2" />
          </button>
          <button
            className="bg-white text-black dark:text-white dark:bg-opacity-0 rounded-full hover:bg-gray-200 hover:text-black my-2 w-8 mr-6"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <MdLastPage className="pl-1 text-2xl" />
          </button>
        </div>
      </div>
    </>
  );
};
