import React, { useState } from "react";
import { useAsyncDebounce } from "react-table";

const filterOptionsMap = {
  trueOrFalse: [
    { value: "", label: "Todos" },
    { value: "true", label: "Si" },
    { value: "false", label: "No" },
  ],
  liquidacionCalculada: [
    { value: "", label: "Todos" },
    { value: "true", label: "Calculada" },
    { value: "false", label: "Pendiente" },
  ],
};

const accessorMap = {
    activo: 'trueOrFalse',
    habilitado: 'trueOrFalse',
    semanaCorrida: 'trueOrFalse',
    configurada: 'trueOrFalse',
    liquidacionCalculada: 'liquidacionCalculada',
  };
  
const ColumnFilterOptions = ({ column }) => {
  const { filterValue, setFilter, id } = column;
  const [value, setValue] = useState(filterValue);

  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined);
  }, 400);

  const optionKey = accessorMap[id];
  const options = filterOptionsMap[optionKey] || [];

  return (
    <>
      <select
        className="w-[100px] font-normal text-sm h-[34px] border-0 bg-white rounded"
        value={value || ""}
        placeholder=" Filtrar..."
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </>
  );
};

export default ColumnFilterOptions;
