import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useStateContext } from "../../../contexts/ContextProvider";
import { useDispatch, useSelector } from "react-redux";
import {
  getCargos,
  getCentrosCostosByEmpresaId,
  getEmpresas,
  getHaberes,
  getJornadas,
  getSecciones,
  limpiarInfoMenuEdicion,
} from "../../../Redux/Actions";
import ModalSpinner from "../../ModalSpinner";
import UtilsFunctions from "../../../utils/UtilsFunctions";
import AgregarHaberForm from "./AgregarHaberForm";
import toast from "react-hot-toast";
import TablaHaberes from "./TablaHaberes";
import ColumnFilter from "../../ColumnFilter";
import ModalAdvertenciaGratificacionLegal from "./ModalAdvertenciaGratificacion";
import functionsContratos from "./functionsContratos";
import { axiosPrivate } from "../../../common/axiosPrivate";

export default function NuevoCrearAnexoContrato({
  isVisible,
  onClose,
  modalData,
}) {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      gratificacionId: "",
    },
  });
  
  const validacionCampoObligatorio = {
    required: { value: true, message: "Obligatorio" },
  };

  const { refreshTable, setRefreshTable, setRowDataAnexo, screenSize } =
    useStateContext();
  const [botonGuardarHabilitado, setBotonGuardarHabilitado] = useState(true);
  const [tablaHaberesEditada, setTablaHaberesEditada] = useState(false);
  const [haberesAnexo, setHaberesAnexo] = useState([]);
  const [tieneSemanaCorrida, setTieneSemanaCorrida] = useState(false);
  const [tieneGratificaciones, setTieneGratificaciones] = useState(false);
  const [showModalGratificacionLegal, setShowModalGratificacionLegal] =
    useState(false);
  const [payloadSinGratificacion, setPayloadSinGratificacion] = useState({});

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const dispatch = useDispatch();

  const nombreEmpresa = localStorage.getItem("empresa");
  // const empresaId = localStorage.getItem("empresaId");

  useEffect(() => {
    dispatch(getEmpresas());
    dispatch(getCargos());
    dispatch(getJornadas());
    dispatch(getSecciones());
    dispatch(getHaberes());
    // }, [dispatch, refreshTable]);
  }, [dispatch]);

  useEffect(() =>{
    dispatch(getCentrosCostosByEmpresaId(modalData.empresaId));
  }, [dispatch, modalData])

  useEffect(() => {
    if (modalData) {
      const estado =
        modalData.estado === 1
          ? "Vigente"
          : modalData.estado === 2
          ? "Vencido"
          : modalData.estado === 3
          ? "Finiquitado"
          : null;

      const nombreTrabajador =
        modalData.nombres +
        " " +
        modalData.apellidoPaterno +
        " " +
        modalData.apellidoMaterno;

      const rutTrabajador = UtilsFunctions.rutFormatter(
        modalData.rutTrabajador,
        { dots: true }
      );

      const fechaAnexoASugerirParaContratos = new Date(modalData.fechaTerminoContratoBase ? modalData.fechaTerminoContratoBase : modalData.fechaIngresoLegal );
      fechaAnexoASugerirParaContratos.setDate(fechaAnexoASugerirParaContratos.getDate() + 1);

      const fechaAnexoASugerirParaAnexos = new Date(modalData.fechaAnexoAnterior);
      fechaAnexoASugerirParaAnexos.setDate(fechaAnexoASugerirParaAnexos.getDate() + 1);

      const fechaAnexoSugerida =
        modalData.version > 1
          ? fechaAnexoASugerirParaAnexos.toISOString().slice(0, 10)
          : fechaAnexoASugerirParaContratos.toISOString().slice(0, 10);

      const fechaMinimaAnexoSegunContrato = new Date(
        modalData.fechaIngresoLegal
      );
      fechaMinimaAnexoSegunContrato.setDate(
        fechaMinimaAnexoSegunContrato.getDate() + 1
      );

      const fechaMinimaAnexoSegunAnexo = new Date(modalData.fechaAnexoAnterior);
      fechaMinimaAnexoSegunAnexo.setDate(
        fechaMinimaAnexoSegunAnexo.getDate() + 1
      );

      const fechaMinimaDelAnexo =
        modalData.version > 1
          ? fechaMinimaAnexoSegunAnexo.toISOString().slice(0, 10)
          : fechaMinimaAnexoSegunContrato.toISOString().slice(0, 10);

      const trabajoPesado =
        modalData.trabajoPesado == 0
          ? "No"
          : modalData.trabajoPesado == 1
          ? "Trabajo menos pesado 2%"
          : modalData.trabajoPesado == 2
          ? "Trabajo pesado 4%"
          : null;

      if (!tablaHaberesEditada) {
        if (modalData.version > 1) {
          // Es un Anexo
          const copiaHaberesAnexoOriginal = [...modalData.anexosHaberes];
          setHaberesAnexo(copiaHaberesAnexoOriginal);
          const encontrarSemanaCorrida = modalData.anexosHaberes.find(
            (e) => e.haber.haberesDt.id == "4"
          );
          if (encontrarSemanaCorrida && encontrarSemanaCorrida.haber) {
            setTieneSemanaCorrida(true);
          } else {
            setTieneSemanaCorrida(false);
          }
          const encontrarGratificacion6 = modalData.anexosHaberes.find(
            (e) => e.haber.haberesDt.id == 6
          );
          const encontrarGratificacion22 = modalData.anexosHaberes.find(
            (e) => e.haber.haberesDt.id == 22
          );
          if (encontrarGratificacion22 || encontrarGratificacion6) {
            setTieneGratificaciones(true);
          } else {
            setTieneGratificaciones(false);
          }
        } else if (modalData.version === 1) {
          // Es un Contrato
          const copiaHaberesAnexoOriginal = [...modalData.contratosHaberes];
          setHaberesAnexo(copiaHaberesAnexoOriginal);

          const encontrarSemanaCorrida = modalData.contratosHaberes.find(
            (e) => e.haber.haberesDt.id == "4"
          );
          if (encontrarSemanaCorrida && encontrarSemanaCorrida.haber) {
            setTieneSemanaCorrida(true);
          } else {
            setTieneSemanaCorrida(false);
          }
          const encontrarGratificacion6 = modalData.contratosHaberes.find(
            (e) => e.haber.haberesDt.id == 6
          );
          const encontrarGratificacion22 = modalData.contratosHaberes.find(
            (e) => e.haber.haberesDt.id == 22
          );
          if (encontrarGratificacion22 || encontrarGratificacion6) {
            setTieneGratificaciones(true);
          } else {
            setTieneGratificaciones(false);
          }
        }
      } else {
        setTieneSemanaCorrida(modalData.semanaCorrida);
      }

      reset({
        version: modalData.version + 1,
        estado: estado,
        nombreTrabajador: nombreTrabajador,
        rut: rutTrabajador,
        direccion: modalData.direccion,
        comuna: modalData.comuna,
        ciudad: modalData.ciudad,
        plazoContratoId: modalData.plazoContratoId,
        fechaIngreso: modalData.fechaIngreso,
        fechaIngresoLegal: modalData.fechaIngresoLegal,

        fechaTerminoContratoAnterior:
          modalData.version === 1
            ? modalData.fechaTerminoContratoBase
            : modalData.fechaTerminoContrato,
        fechaAnexoAnterior: modalData.fechaAnexoAnterior,
        fechaAnexo: fechaAnexoSugerida,
        fechaMinimaDelAnexo: fechaMinimaDelAnexo,
        // fechaTerminoAnexo: "",
        // // fechaMinimaTerminoAnexo: "",

        empresaId: modalData.empresaId,
        empresaRazonSocial: modalData.empresa,

        articulo22: modalData.articulo22,
        jornadaId: modalData.jornadaId,
        centroCostoId: modalData.centroCostoId,
        seccionId: modalData.seccionId,
        seccionNombre: modalData.seccionNombre,
        cargoId: modalData.cargoId,
        cargoNombre: modalData.cargoNombre,
        trabajoPesadoId: modalData.trabajoPesado,
        trabajoPesadoNombre: trabajoPesado,
        gratificacionId: modalData.gratificacionId,
        gratificacionNombre: modalData.gratificacionNombre,
        gratificacionTexto: modalData.gratificacionObservacion,
      });
    }
  }, [modalData]);

  const empresas = useSelector((state) => state.empresas);
  const cargos = useSelector((state) => state.getCargos);
  const jornadas = useSelector((state) => state.getJornadas);
  const secciones = useSelector((state) => state.getSecciones);
  const haberesLista = useSelector((state) => state.getHaberes);
  const listaCCosto = useSelector((state) => state.centrosCostoEmpresa);
  const validacionFecha = {
    required: { value: true, message: "Obligatorio" },
  };

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("getCargos"));
      dispatch(limpiarInfoMenuEdicion("getJornadas"));
      dispatch(limpiarInfoMenuEdicion("centrosCostoEmpresa"));
      dispatch(limpiarInfoMenuEdicion("getSecciones"));
      dispatch(limpiarInfoMenuEdicion("getHaberes"));
      reset({});
      setRowDataAnexo(false);
    };
  }, []);

    const loadingModal = modalData && 
    listaCCosto.length > 0 &&
    haberesLista.length > 0 &&
    cargos.length > 0 &&
    jornadas.length > 0 &&
    secciones.length > 0 
    
  // function agregarHaber(haberes){
  //   functionsContratos.agregarHaber(haberes, setTablaHaberesEditada, haberesAnexo, setHaberesAnexo, setTieneSemanaCorrida, setTieneGratificaciones, estilosAlerta )
  // }

  function agregarHaber(haberes) {
    if (!haberes) {
      return null;
    }

    if (/^ *$/.test(haberes.valor) && !haberes.valorVariable) {
      console.log("Valor vacio");
      return null;
    }

    setTablaHaberesEditada(true);
    if (haberes.valorVariable) {
      haberes.valor = -1;
    }
    toast.loading("Agregando Haber", estilosAlerta);
    try {
      const nuevoArray = [...haberesAnexo, haberes];
      setHaberesAnexo(nuevoArray);
      const encontrarSemanaCorrida = nuevoArray.find(
        (e) => e.haber.haberesDt.id == "4"
      );
      if (encontrarSemanaCorrida?.haber) {
        setTieneSemanaCorrida(true);
      } else {
        setTieneSemanaCorrida(false);
      }
      const encontrarGratificacion6 = nuevoArray.find(
        (e) => e.haber.haberesDt.id === 6
      );
      const encontrarGratificacion22 = nuevoArray.find(
        (e) => e.haber.haberesDt.id === 22
      );
      if (encontrarGratificacion22 || encontrarGratificacion6) {
        setTieneGratificaciones(true);
        setValue("gratificacionId", modalData.gratificacionId);
        setValue("gratificacionNombre", modalData.gratificacionNombre);
        setValue("gratificacionTexto", modalData.gratificacionObservacion);
      } else {
        setTieneGratificaciones(false);
      }
      toast.dismiss();
      toast.success("Haber agregado con éxito", estilosAlerta);
    } catch (error) {
      console.log("Error", error);
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al agregar Haber",
        estilosAlerta
      );
    }
  }

  async function onSubmit(data) {
    try {
      if (!haberesAnexo.find((e) => e.haber.haberesDt.codigo === 2101)) {
        toast.dismiss();
        toast.error("El contrato debe tener un Haber de Sueldo Base");
        return null;
      }

      const anyoAnexo = data.fechaAnexo && data.fechaAnexo.split("-")[0];
      const mesAnexo = data.fechaAnexo && data.fechaAnexo.split("-")[1];
      const diaAnexo = data.fechaAnexo && data.fechaAnexo.split("-")[2];
      const fechaAnexo =
        data.fechaAnexo && `${diaAnexo}-${mesAnexo}-${anyoAnexo}`;

      const anyoTerminoContrato = data.fechaTerminoAnexo
        ? data.fechaTerminoAnexo.split("-")[0]
        : null;
      const mesTerminoContrato = data.fechaTerminoAnexo
        ? data.fechaTerminoAnexo.split("-")[1]
        : null;
      const diaTerminoContrato = data.fechaTerminoAnexo
        ? data.fechaTerminoAnexo.split("-")[2]
        : null;
      const fechaTerminoContrato = `${diaTerminoContrato}-${mesTerminoContrato}-${anyoTerminoContrato}`;

      const payload = {
        contratoId: modalData.id,
        estado: 1,
        trabajadorId: modalData.trabajadorId,
        fechaAnexo: fechaAnexo,
        fechaTerminoContrato: data.fechaTerminoAnexo
          ? fechaTerminoContrato
          : null,
        articulo22: data.articulo22,
        cargoId: data.cargoId,
        trabajoPesado: data.trabajoPesadoId,
        gratificacionId: data.gratificacionId,
        plazoContratoId: data.plazoContratoId,
        jornadaId: data.articulo22 == true ? 0 : data.jornadaId,
        seccionId: data.seccionId,
        centroCostoId: data.centroCostoId,
        semanaCorrida: tieneSemanaCorrida,
        haberes: UtilsFunctions.formatearHaberes(haberesAnexo),
      };
      setPayloadSinGratificacion(payload);
      console.log("payload", payload);
      setBotonGuardarHabilitado(false);
      if (
        !haberesAnexo.find(
          (e) =>
            e.haber.haberesDt.codigo === 2106 ||
            e.haber.haberesDt.codigo === 2122
        )
      ) {
        toast.dismiss();
        setShowModalGratificacionLegal(true);
        setBotonGuardarHabilitado(true);
        return null;
      }
      toast.loading("Creando Anexo", estilosAlerta);
      await axiosPrivate.post(
        `${api_service}/api/v1/trabajadores/nuevoanexocontrato`,
        payload,
        Authorization
      );
      toast.dismiss();
      toast.success("Anexo creado con éxito", estilosAlerta);
      setRefreshTable(!refreshTable);
      setBotonGuardarHabilitado(true);
      reset();
      onClose();
    } catch (error) {
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al crear Anexo",
        estilosAlerta
      );
      console.log("error", error);
      setBotonGuardarHabilitado(true);
    }
  }

  const TABLA_HABERES_TRABAJADOR = [
    {
      Header: "Nombre",
      accessor: "haber.nombre",
      Filter: ColumnFilter,
    },
    {
      Header: "Categoría L.R.E.",
      accessor: "haber.haberesDt.glosa",
      Filter: ColumnFilter,
    },
    {
      Header: "Imponible",
      accessor: "haber.haberesDt.imponible",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value === true || value === "true") {
          return "Si";
        } else {
          return "No";
        }
      },
    },
    {
      Header: "Tributable",
      accessor: "haber.haberesDt.tributable",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value === true || value === "true") {
          return "Si";
        } else {
          return "No";
        }
      },
    },
    {
      Header: "Constituye Remuneración",
      accessor: "haber.haberesDt.constRemuneracion",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value == "true") {
          return "Si";
        } else {
          return "No";
        }
      },
    },
    {
      Header: "Fijo o Variable",
      accessor: "valorFijo",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value === true) {
          return "Fijo";
        } else {
          return "Variable";
        }
      },
    },
    {
      Header: "Monto",
      accessor: "valor",
      Filter: ColumnFilter,
      Cell: ({ value, row }) => {
        if (parseInt(value) === -1 || row.original.valorVariable === true) {
          return "N/A";
        } else {
          return "$" + UtilsFunctions.integerFormatter(value);
        }
      },
    },
    {
      Header: "Cond. asistencia total",
      accessor: "haber.condicionalAsistenciaTotal",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value === true || value === "true") {
          return "Si";
        } else {
          return "No";
        }
      },
    },
    {
      Header: "Proporcional a dias trabajados",
      accessor: "haber.pagoProporcionalDiasTrabajados",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value === true || value === "true") {
          return "Si";
        } else {
          return "No";
        }
      },
    },
  ];

  return (
    <>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-screen my-6 mx-auto max-w-6xl">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col max-h-[90vh] overflow-y-auto w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t dark:bg-hercules-dark-bg-100 ">
                <h3 className="text-3xl text-hercules-light-font-secondary font-semibold leading-normal dark:text-white">
                  Nuevo Anexo
                </h3>
              </div>

              {/*body*/}
              <div
                className={`relative p-6 ${
                  !loadingModal ? "overflow-hidden" : "overflow-auto"
                } flex-auto dark:bg-hercules-dark-bg-200`}
              >
                {!loadingModal ? (
                  <div className="h-fix">
                    <ModalSpinner />
                  </div>
                ) : (
                  <>
                    <div className="flex flex-wrap -mx-3 mb-6">
                      <div className="w-full md:w-1/12 px-3 mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Versión
                        </label>
                        <input
                          {...register("version")}
                          disabled
                          className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                          type="text"
                        />
                      </div>

                      <div className="w-full md:w-3/12 px-3 mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Empresa
                        </label>
                        <input
                          value={nombreEmpresa}
                          disabled
                          className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                          type="text"
                        />
                      </div>

                      <div className="w-full md:w-3/12 px-3 mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Estado
                        </label>
                        <input
                          {...register("estado")}
                          disabled
                          className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                          type="text"
                        />
                      </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-6">
                      <div className="w-full md:w-4/12 px-3 mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Nombre
                        </label>
                        <input
                          {...register("nombreTrabajador")}
                          disabled
                          className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                          type="text"
                        />
                      </div>

                      <div className="w-full md:w-4/12 px-3 mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          RUT
                        </label>
                        <input
                          {...register("rut")}
                          disabled
                          className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                          type="text"
                        />
                      </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-6">
                      <div className="w-full md:w-4/12 px-3 mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="direccion"
                        >
                          Dirección
                        </label>
                        <input
                          {...register("direccion")}
                          disabled
                          className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                          type="text"
                          id="direccion"
                        />
                      </div>

                      <div className="w-full md:w-4/12 px-3 mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="comuna"
                        >
                          Comuna
                        </label>
                        <input
                          {...register("comuna")}
                          disabled
                          className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                          type="text"
                          id="comuna"
                        />
                      </div>

                      <div className="w-full md:w-4/12 px-3 mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="ciudad"
                        >
                          Ciudad
                        </label>
                        <input
                          {...register("ciudad")}
                          disabled
                          className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                          type="text"
                          id="ciudad"
                        />
                      </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-6">
                      <div className="w-full relative md:w-3/12 px-3 mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="duracionContrato"
                        >
                          Duración del Anexo
                        </label>
                        {watch("plazoContratoId") === 2 ? (
                          <input
                            disabled
                            className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                            type="text"
                            value="Indefinido"
                          />
                        ) : (
                          <>
                            <select
                              {...register("plazoContratoId", {
                                onChange: (e) => {
                                  if (e.target.value === 2) {
                                    setValue("fechaTerminoAnexo", null);
                                  }
                                },
                              })}
                              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            >
                              <option value="1" key="1">
                                Plazo Fijo
                              </option>
                              <option value="2" key="2">
                                Indefinido
                              </option>
                            </select>
                          </>
                        )}
                        {errors.plazoContratoId && (
                          <p className="text-red-600 text-xs">
                            {errors.plazoContratoId.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-6">
                      <div className="w-full md:w-3/12 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                          Fecha de Ingreso
                        </label>
                        <input
                          disabled
                          {...register("fechaIngreso", validacionFecha)}
                          className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                          type="date"
                        />
                        {errors.fechaIngreso && (
                          <p className="text-red-600 text-xs">
                            {errors.fechaIngreso.message}
                          </p>
                        )}
                      </div>

                      <div className="w-full md:w-3/12 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                          Fecha de Ingreso Legal
                        </label>
                        <input
                          {...register("fechaIngresoLegal", validacionFecha)}
                          disabled
                          className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                          type="date"
                        />
                        {errors.fechaIngresoLegal && (
                          <p className="text-red-600 text-xs">
                            {errors.fechaIngresoLegal.message}
                          </p>
                        )}
                      </div>

                      <div className="w-full md:w-3/12 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                          Fecha de término del contrato base
                        </label>
                        {watch("plazoContratoId") !== 2 &&
                        watch("fechaTerminoContratoAnterior") ? (
                          <>
                            <input
                              {...register("fechaTerminoContratoAnterior")}
                              disabled
                              className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                              type="date"
                            />
                          </>
                        ) : (
                          watch("plazoContratoId") === 2 && (
                            <input
                              className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                              type="text"
                              disabled
                              value={"No aplica"}
                            />
                          )
                        )}
                      </div>
                    </div>

                    {/* Fecha Anexo Anterior / Fecha Anexo / Fecha Termino del Anexo  */}
                    <div className="flex flex-wrap -mx-3 mb-6">
                      {watch("fechaAnexoAnterior") && (
                        <div className="w-full md:w-3/12 px-3 mb-6 md:mb-0">
                          <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                            Fecha del Anexo Anterior
                          </label>
                          <input
                            {...register("fechaAnexoAnterior")}
                            disabled
                            className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                            type="date"
                          />
                        </div>
                      )}

                      <div className="w-full md:w-3/12 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                          Fecha del Anexo
                        </label>
                        <input
                          {...register("fechaAnexo", validacionFecha)}
                          min={watch("fechaMinimaDelAnexo")}
                          className="hover:cursor-pointer appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="date"
                        />
                        {errors.fechaAnexo && (
                          <p className="text-red-600 text-xs">
                            {errors.fechaAnexo.message}
                          </p>
                        )}
                      </div>

                      <div className="w-full md:w-3/12 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                          Fecha de término del Anexo
                        </label>
                        {watch("plazoContratoId") === 1 &&
                        //   watch("fechaTerminoContratoOriginal") !== "" &&
                        watch("fechaTerminoContratoAnterior") !== null &&
                        watch("fechaTerminoContratoAnterior") !== "" ? (
                          <>
                            <input
                              {...register(
                                "fechaTerminoAnexo",
                                validacionFecha
                              )}
                              min={watch("fechaAnexo")}
                              className="hover:cursor-pointer appearance-none block w-full bg-gray-200 text-gray-700 border mb-1 border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              type="date"
                            />
                            {errors.fechaTerminoAnexo && (
                              <p className="text-red-600 text-xs">
                                {errors.fechaTerminoAnexo.message}
                              </p>
                            )}
                          </>
                        ) : (
                          <input
                            className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                            type="text"
                            disabled
                            value={"No aplica"}
                          />
                        )}
                      </div>
                    </div>

                    {/* Empresa y Centro de costo */}
                    <div className="flex flex-wrap -mx-3 mb-6">
                      <div className="w-full relative md:w-6/12 px-3 mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="duracionContrato"
                        >
                          Empresa
                        </label>
                        <select
                          {...register("empresaId", {
                            onChange: (e) => {
                              setValue("centroCostoId", "");
                              // setValue("centroCostoNombre", "");
                              dispatch(
                                getCentrosCostosByEmpresaId(e.target.value)
                              );
                            },
                          })}
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        >
                          {empresas?.map((empresa, i) => (
                            <>
                              <option key={i} value={empresa.id}>
                                {empresa.razonSocial}
                              </option>
                            </>
                          ))}
                        </select>
                        {errors.empresaId && (
                          <p className="text-red-600 text-xs">
                            {errors.empresaId.message}
                          </p>
                        )}
                      </div>

                      <div className="w-full relative md:w-4/12 px-3 mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="duracionContrato"
                        >
                          Centro de Costo
                        </label>
                        <select
                          {...register("centroCostoId", validacionCampoObligatorio)}
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        >
                          {watch("centroCostoId" === "") && (
                            <option hidden value="">
                              Seleccionar Centro Costo
                            </option>
                          )}
                          {listaCCosto?.map((cCosto, i) => (
                            <>
                              <option key={i} value={cCosto.id}>
                                {cCosto.nombre}
                              </option>
                            </>
                          ))}
                        </select>
                        {errors.centroCostoId && (
                          <p className="text-red-600 text-xs">
                            {errors.centroCostoId.message}
                          </p>
                        )}
                      </div>
                    </div>

                    {/* Art22 y Jornada Laboral */}
                    <div className="flex flex-wrap -mx-3 mb-6">
                      <div className="w-full relative md:w-4/12 px-3 mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="duracionContrato"
                        >
                          Trabajador Artículo 22
                        </label>
                        <select
                          {...register("articulo22", {
                            onChange: (e) => {
                              const value =
                                e.target.value === "true" ? true : false;
                              setValue("articulo22", value);
                            },
                          })}
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        >
                          {functionsContratos.optionArt22.map((el) => (
                            <>
                              <option value={el.id} key={el.id}>
                                {el.nombre}
                              </option>
                            </>
                          ))}
                        </select>
                        {errors.articulo22 && (
                          <p className="text-red-600 text-xs">
                            {errors.articulo22.message}
                          </p>
                        )}
                      </div>

                      <div className="w-full relative md:w-4/12 px-3 mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="duracionContrato"
                        >
                          Jornada Laboral
                        </label>
                        {watch("articulo22") === true || watch("articulo22") === "true" ? (
                          <input
                            className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                            type="text"
                            disabled
                            value={"No aplica"}
                          />
                        ) : (
                          <>
                            <select
                              {...register("jornadaId", {
                                //   onChange: (e) => {
                                //     const jornada = jornadas.find(
                                //       (jornada) => jornada.id == e.target.value
                                //     );
                                //     setSueldoMinimoProporcional(
                                //       jornada.sueldoMinimoProporcional
                                //     );
                                //   },
                              })}
                              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            >
                              {/* <option value="" hidden key={watch("jornadaId")}>
                                {watch("jornadaNombre")}
                              </option> */}
                              {jornadas?.map((el) => (
                                <>
                                  <option value={el.id} key={el.id}>
                                    {el.nombre}
                                  </option>
                                </>
                              ))}
                            </select>
                            {errors.jornadaId && (
                              <p className="text-red-600 text-xs">
                                {errors.jornadaId.message}
                              </p>
                            )}
                          </>
                        )}
                      </div>
                    </div>

                    {/* Seccion/Area y Cargo */}
                    <div className="flex flex-wrap -mx-3 mb-6">
                      <div className="w-full relative md:w-4/12 px-3 mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="duracionContrato"
                        >
                          Sección/Área
                        </label>
                        <select
                          {...register("seccionId")}
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        >
                          {secciones?.map((el) => (
                            <>
                              <option value={el.id} key={el.id}>
                                {el.nombre}
                              </option>
                            </>
                          ))}
                        </select>
                        {errors.seccionId && (
                          <p className="text-red-600 text-xs">
                            {errors.seccionId.message}
                          </p>
                        )}
                      </div>

                      <div className="w-full relative md:w-4/12 px-3 mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="duracionContrato"
                        >
                          Cargo
                        </label>
                        <select
                          {...register("cargoId")}
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        >
                          {cargos?.map((el) => (
                            <>
                              <option value={el.id} key={el.id}>
                                {el.nombre}
                              </option>
                            </>
                          ))}
                        </select>
                        {errors.cargoId && (
                          <p className="text-red-600 text-xs">
                            {errors.cargoId.message}
                          </p>
                        )}
                      </div>
                    </div>

                    {/* Trabajo Pesado */}
                    <div className="flex flex-wrap -mx-3 mb-6">
                      <div className="w-full relative md:w-4/12 px-3 mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="duracionContrato"
                        >
                          Trabajo Pesado
                        </label>
                        <select
                          {...register("trabajoPesadoId")}
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        >
                          <option
                            value=""
                            hidden
                            key={watch("trabajoPesadoId")}
                          >
                            {watch("trabajoPesadoNombre")}
                          </option>
                          {functionsContratos.trabajoPesado.map((el) => (
                            <>
                              <option value={el.id} key={el.id}>
                                {el.nombre}
                              </option>
                            </>
                          ))}
                        </select>
                        {errors.trabajoPesadoId && (
                          <p className="text-red-600 text-xs">
                            {errors.trabajoPesadoId.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <AgregarHaberForm
                      haberesSelect={haberesLista}
                      sendFormData={(haberes) => agregarHaber(haberes)}
                    />

                    <div className="rounded-lg">
                      <TablaHaberes
                        col={TABLA_HABERES_TRABAJADOR}
                        tableData={haberesAnexo}
                        botonEliminar={true}
                        borrarHaber={(index) => {
                          toast.loading("Eliminando Haber", estilosAlerta);
                          try {
                            haberesAnexo.splice(index, 1);
                            const arrayEditado = [...haberesAnexo];
                            setHaberesAnexo(arrayEditado);
                            const encontrarSemanaCorrida = arrayEditado.find(
                              (e) => e.haber.haberesDt.id === 4
                            );

                            if (encontrarSemanaCorrida) {
                              setTieneSemanaCorrida(true);
                            } else {
                              setTieneSemanaCorrida(false);
                            }
                            const encontrarGratificacion6 = arrayEditado.find(
                              (e) => e.haber.haberesDt.id === 6
                            );
                            const encontrarGratificacion22 = arrayEditado.find(
                              (e) => e.haber.haberesDt.id === 22
                            );
                            if (
                              encontrarGratificacion6 ||
                              encontrarGratificacion22
                            ) {
                              setTieneGratificaciones(true);
                            } else {
                              setValue("gratificacionId", 3);
                              setValue("gratificacionNombre", "");
                              setValue("gratificacionTexto", "");
                              setTieneGratificaciones(false);
                            }
                            toast.dismiss();
                            toast.success(
                              "Haber eliminado con éxito",
                              estilosAlerta
                            );
                          } catch (error) {
                            console.log("Error", error);
                            toast.dismiss();
                            toast.error(
                              "Error al eliminar Haber",
                              estilosAlerta
                            );
                          }
                        }}
                      />
                    </div>

                    {tieneGratificaciones && (
                      <div className="relative mt-5 flex-auto ">
                        <div className="w-full md:w-min mb-6 md:mb-0 ">
                          <label className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
                            Gratificación
                          </label>
                        </div>

                        <div className="flex flex-wrap">
                          {watch("gratificacionId") !== "" && (
                            <div className="w-full md:w-3/12 mr-3 mb-6 md:mb-0 ">
                              <input
                                disabled
                                {...register("gratificacionNombre")}
                                className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                id="grid-rut"
                                type="text"
                              />
                            </div>
                          )}

                          {watch("gratificacionId") !== "" && (
                            <div className="w-full md:w-8/12 mx-3 mb-6 md:mb-0 ">
                              <input
                                disabled
                                {...register("gratificacionTexto")}
                                className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                                id="grid-rut"
                                type="text"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>

              {/*footer*/}
              <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                  type="button"
                  onClick={() => {
                    onClose();
                    reset();
                  }}
                  disabled={!botonGuardarHabilitado}
                >
                  Cerrar
                </button>
                <button
                  type="submit"
                  disabled={!botonGuardarHabilitado}
                  className={` ${
                    !botonGuardarHabilitado
                      ? "bg-gray-500"
                      : "bg-hercules-light-primary-button hover:shadow-lg hover:scale-105"
                  }  text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400`}
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

      {showModalGratificacionLegal && (
        <ModalAdvertenciaGratificacionLegal
          onCloseAlert={() => {
            setShowModalGratificacionLegal(false);
          }}
          onCloseAndReset={() => {
            setShowModalGratificacionLegal(false);
            onClose();
            reset();
          }}
          confirmarCambiosPayload={payloadSinGratificacion}
          tipoContratoAnexo="Anexo"
        />
      )}

<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
