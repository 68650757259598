import { axiosPrivate } from "../../../common/axiosPrivate";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { limpiarInfoMenuEdicion, listaSalud } from "../../../Redux/Actions";
import UtilsFunctions from "../../../utils/UtilsFunctions";
import { useForm } from "react-hook-form";
import SimpleTable from "../../SimpleTable";
import ModalSpinner from "../../ModalSpinner";
import { useStateContext } from "../../../contexts/ContextProvider";

export default function NuevoSaludTrabajador({
  isVisibleEditorSalud,
  onCloseEditorSalud,
  modalData,
  updateTrabajadorSalud,
}) {
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      tipoDePago: "",
      institucionSalud: "",
      saludId: "",
      codigoFun: "",
      periodoAnyo: "",
    },
  });

  const [botonGuardarHabilitado, setBotonGuardarHabilitado] = useState(true)
  const anyosData = UtilsFunctions.obtenerAnyosHastaActual();
  const [mesesData, setMesesData] = useState([]);
  const [infoPeriodos, setInfoPeriodos] = useState([]);
const tablaSaludData = useSelector((state) => state.tablaSalud);
  const institucionesSalud = useSelector((state) => state.listaSalud);
  const [listaSaludData, setListaSaludData] = useState([]);
  const [botonAgregarVisible, setBotonAgregarVisible] = useState(false);
  const { screenSize } = useStateContext();

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const formatter = new Intl.NumberFormat("es-CL", {
    styles: "currency",
    currency: "CLP",
    minimumFractionDigits: 0,
  });

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const dispatch = useDispatch();

  useEffect(() => {
    getPeriodosCargados()
    dispatch(listaSalud());
    const rutTrabajador = UtilsFunctions.rutFormatter(modalData.rutTrabajador, {
      dots: true,
    });
    setValue(
      "nombres",
      modalData.nombres +
        " " +
        modalData.apellidoPaterno +
        " " +
        modalData.apellidoMaterno
    );
    setValue("rut", rutTrabajador);
    setValue("trabajadorId", modalData.trabajadorId);
  }, []);

  useEffect(() => {
    if (tablaSaludData.length > 0) {
      setListaSaludData(tablaSaludData);
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("listaSalud"));
      reset({});
    };
  }, []);

  const validacionSelect = {
    required: { value: true, message: "Obligatorio" },
  };

  async function getPeriodosCargados(){
    const infoMeses = await axiosPrivate(`${api_service}/api/v1/periodos/cargados`, Authorization)
    setInfoPeriodos(infoMeses.data)
  }

  const handleYearChange = (e) => {
    const year = parseInt(e);
    const yearData = infoPeriodos.find((item) => item.anyo === year);
    if (yearData) {
      const filteredMonths = yearData.periodos.filter(
        (mes) => mes.datosPrevisionalesCargados === true
      );
      const transformedMonths = filteredMonths.map((month) => (
        UtilsFunctions.integerToMonth(month.mes)
      ));
      setMesesData(transformedMonths);

    } else {
      // Si no se encontró ningún año con el valor seleccionado, establecer mesesData como un arreglo vacío.
      setMesesData([]);
    }
  };

  async function onSubmitTasaSalud(data) {
    try {
      toast.loading("Calculando", estilosAlerta);
      const infoPeriodo = await axiosPrivate.get(
        `${api_service}/api/v1/periodos/mes/${data.numeroMes}/anyo/${data.periodoAnyo}`,
        Authorization
      );
      setValue("periodoId", infoPeriodo.data.id);
      const payload = {
        saludId: data.saludId,
        periodoId: infoPeriodo.data.id,
      };
      const response = await axiosPrivate.post(
        `${api_service}/api/v1/trabajadores/tasaperiodosalud`,
        payload,
        Authorization
      );
      if (response.status === 200) {
        if (watch("saludId") !== "7") {
          setValue("tipoDePago", "");
        }
        setValue("montoValor", "");
        setValue("montoUF", "");
        setValue("codigoFun", "");
        setValue("montoPorcentaje", `${response.data.tasa}%`);
        setBotonAgregarVisible(true);
        toast.dismiss();
        toast.success("Calculado con éxito", estilosAlerta);
      }
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al calcular", estilosAlerta
      );
    }
  }

  async function onSubmitAgregarSalud() {
    setBotonGuardarHabilitado(false)
    const data = getValues();
    toast.loading("Editando Salud", estilosAlerta);
    const copiaListaSaludData = listaSaludData;
    try {
      const payload = {
        trabajadorId: data.trabajadorId,
        institucionDeSaludId: data.saludId,
        pactadoPesos:
          data.tipoDePago === "2"
            ? data.montoValor?.replace(/\./g, "").replace("$", "")
            : 0,
        pactadoUf:
          data.tipoDePago === "3" ? data.montoUF?.replace(",", ".") : 0,
        codigoFun: data.codigoFun,
        periodoId: data.periodoId,
      };
      console.log("payload", payload);
      const response = await axiosPrivate.put(
        `${api_service}/api/v1/trabajadores/actualizarsaludtrabajador`,
        payload,
        Authorization
      );
      if (response.status === 200) {
        const elemento = copiaListaSaludData.filter(
          (e) =>
            e.periodo.mes === parseInt(getValues("numeroMes")) &&
            e.periodo.anyo === parseInt(getValues("periodoAnyo"))
        );
        const index = copiaListaSaludData.indexOf(elemento[0]);
        const saludEditar = copiaListaSaludData.splice(index, 1)[0];
        saludEditar["institucionDeSaludNombre"] = data.institucionSalud;
        saludEditar["tasa"] =
          data.tipoDePago === "1"
            ? data.montoPorcentaje?.replace(/\./g, "").replace("%", "")
            : 0;
        saludEditar["pactado"] =
          data.tipoDePago === "2"
            ? data.montoValor?.replace(/\./g, "").replace("$", "")
            : 0;
        saludEditar["pactadoUF"] =
          data.tipoDePago === "3" ? data.montoUF?.replace(",", ".") : 0;
        saludEditar["fun"] = data.codigoFun;
        copiaListaSaludData.splice(index, 0, saludEditar);
        const nuevoArray = [...copiaListaSaludData];
        setListaSaludData(nuevoArray);
        updateTrabajadorSalud(listaSaludData[0]?.institucionDeSaludNombre);
        setValue("saludId", "");
        setValue("institucionSalud", "");
        setValue("tipoDePago", "");
        setValue("montoPorcentaje", "");
        setValue("montoValor", "");
        setValue("montoUF", "");
        setValue("codigoFun", "");
        setValue("numeroMes", "");
        setValue("periodoAnyo", "");
        setValue("periodoMes", "");
        setValue("periodoId", "");
        toast.dismiss();
        toast.success("Salud editada con éxito", estilosAlerta);
        setBotonAgregarVisible(false);
      }
      setBotonGuardarHabilitado(true)
    } catch (error) {
      //   console.log(error);
      if (
        error.response &&
        error.response.status === 404 &&
        error.response.data.message ===
          "No existe registro de salud para el periodo seleccionado"
      ) {
        try {
          // Lógica adicional para actualizar la tabla cuando la API devuelve un estado 404
          const payload = {
            trabajadorId: data.trabajadorId,
            institucionDeSaludId: data.saludId,
            periodoId: getValues("periodoId"),
            pactadoPesos:
              data.tipoDePago === "2"
                ? data.montoValor.replace(/\./g, "").replace("$", "")
                : 0,
            pactadoUf:
              data.tipoDePago === "3" ? data.montoUF?.replace(",", ".") : 0,
            codigoFun: data.codigoFun,
          };
          const response = await axiosPrivate.post(
            `${api_service}/api/v1/trabajadores/agregarsaludtrabajador`,
            payload,
            Authorization
          );
          console.log("response.data", response.data);
          copiaListaSaludData.push(response.data);
          copiaListaSaludData.sort((a, b) => b.periodo.id - a.periodo.id);
          const nuevoArray = [...copiaListaSaludData];
          setListaSaludData(nuevoArray);
          // setRefreshTable(!refreshTable);
          toast.dismiss();
          toast(
            "No existía registro de Salud para el trabajador en el periodo seleccionado.\nSe agregó un nuevo registro de Salud en ese periodo.",
            estilosAlerta
          );
          setValue("saludId", "");
          setValue("institucionSalud", "");
          setValue("tipoDePago", "");
          setValue("montoPorcentaje", "");
          setValue("montoValor", "");
          setValue("codigoFun", "");
          setValue("periodoAnyo", "");
          setValue("periodoMes", "");
          setValue("montoUF", "");
          setValue("numeroMes", "");
          setValue("periodoId", "");
          setBotonAgregarVisible(false);
        } catch (innerError) {
          toast.dismiss();
          toast.error(error.response.data.message ? error.response.data.message : "Error al actualizar Salud", estilosAlerta);
        }
      } else {
        toast.dismiss();
        toast.error(error.response.data.message ? error.response.data.message :"Error al actualizar Salud", estilosAlerta);
      }
      setBotonGuardarHabilitado(true)
    }
  }

  const COLUMNS = [
    {
      Header: "Institución de salud",
      accessor: "institucionDeSaludNombre",
    },
    {
      Header: "Periodo",
      accessor: (d) =>
        UtilsFunctions.periodoToText(d.periodo.mes, d.periodo.anyo),
    },
    {
      Header: "Tasa",
      accessor: (d) =>
        d.pactado === 0 && d.pactadoUF === 0
          ? d.tasa
            ? d.tasa + "%"
            : "0%"
          : "0%",
    },
    {
      Header: "Pactado",
      accessor: (d) => (d.pactado ? "$" + formatter.format(d.pactado) : "$0"),
    },
    {
      Header: "Pactado UF",
      accessor: (d) =>
        d.pactadoUF ? formatter.format(d.pactadoUF) + " UF" : "0",
    },
    {
      Header: "FUN",
      accessor: (d) => (d.fun ? d.fun : "Sin FUN"),
    },
  ];

  const loadingModal =
    tablaSaludData.length > 0 && institucionesSalud.length > 0;

  if (!isVisibleEditorSalud) {
    return null;
  }

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="segundoDivModal max-w-3xl">
          {/*content*/}
          <div className="tercerDivModal shadow-lg  max-h-[85vh]">
            {/*header*/}
            <div className="divHeaderModal border-slate-200">
              <h3 className="titulo-Modal">Salud Trabajador</h3>
            </div>
            {/*body*/}
            <div
              className={`relative p-6 flex-auto dark:bg-hercules-dark-bg-200 ${
                !loadingModal ? "overflow-hidden" : "overflow-auto"
              }`}
            >
              {!loadingModal ? (
                <div className="h-fix">
                  <ModalSpinner />
                </div>
              ) : (
                <>
                  <div className="md:flex md:flex-wrap mb-4">
                    <div className="mb-9 md:mb-6 md:w-1/2 md:pr-3">
                      <label
                        className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Nombre
                      </label>
                      <input
                        disabled
                        {...register("nombres")}
                        className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        id="nombre"
                        type="text"
                      />
                    </div>

                    <div className="mb-9 md:mb-6 md:w-1/2 md:pl-3">
                      <label
                        className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        RUT
                      </label>
                      <input
                        disabled
                        {...register("rut")}
                        className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        id="nombre"
                        type="text"
                      />
                    </div>
                  </div>

                  <form onSubmit={handleSubmit(onSubmitTasaSalud)}>
                    <div className="flex flex-wrap">
                      <div className="relative w-full md:w-4/12 md:pr-3 mb-2">
                        <label
                          className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Institución de Salud
                        </label>
                        <select
                          {...register("saludId", {
                            onChange: (e) => {
                              console.log(
                                "e.target.selectedIndex",
                                e.target.selectedIndex - 1
                              );
                              const institucionSalud =
                                institucionesSalud[e.target.selectedIndex - 1]
                                  .glosa;
                              setValue("institucionSalud", institucionSalud);
                              if (botonAgregarVisible) {
                                setBotonAgregarVisible(false);
                              }
                              if (
                                institucionesSalud[e.target.selectedIndex - 1]
                                  .glosa === "Fonasa"
                              ) {
                                console.log("Entra al if");
                                setValue("tipoDePago", "1");
                              }
                            },
                            ...validacionSelect,
                          })}
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        >
                          <option hidden value="">
                            Seleccionar
                          </option>
                          {institucionesSalud.map((salud, i) => (
                            <option value={salud.id} key={i}>
                              {salud.glosa}
                            </option>
                          ))}
                        </select>
                        <span
                          className={`text-red-500 text-xs italic ${
                            !errors.saludId && "opacity-0 invisible"
                          }`}
                        >
                          {errors.saludId ? errors.saludId.message : "hidden"}
                        </span>
                      </div>

                      <div className="relative md:w-2/12 w-4/12 md:pl-3 md:pr-2 pr-3">
                        <label
                          className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Periodo
                        </label>
                        <select
                          {...register("periodoAnyo", {
                            onChange: (e) => {
                              setValue("periodoMes", "");
                              handleYearChange(e.target.value)
                              if (botonAgregarVisible) {
                                setBotonAgregarVisible(false);
                              }
                            },
                            ...validacionSelect,
                          })}
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        >
                          <option hidden value="">
                            Año
                          </option>
                          {anyosData.map((anyo, i) => (
                            <option value={anyo} key={i}>
                              {anyo}
                            </option>
                          ))}
                        </select>
                        <span
                          className={`text-red-500 text-xs italic ${
                            !errors.periodoAnyo && "opacity-0 invisible"
                          }`}
                        >
                          {errors.periodoAnyo
                            ? errors.periodoAnyo.message
                            : "hidden"}
                        </span>
                      </div>

                      <div className="relative md:w-3/12 w-6/12 md:pr-3 pt-1">
                        <select
                          {...register("periodoMes", {
                            onChange: (e) => {
                              setValue("numeroMes", e.target.selectedIndex);
                              if (botonAgregarVisible) {
                                setBotonAgregarVisible(false);
                              }
                            },
                            ...validacionSelect,
                          })}
                          disabled={watch("periodoAnyo") === "" ? true : false}
                          className="block w-full mt-6 appearance-none bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        >
                          <option hidden value="">
                            Mes
                          </option>
                          {mesesData.map((mes, i) => (
                            <option value={mes} key={i}>
                              {mes}
                            </option>
                          ))}
                        </select>
                        <span
                          className={`text-red-500 text-xs italic ${
                            !errors.periodoMes && "opacity-0 invisible"
                          }`}
                        >
                          {errors.periodoMes
                            ? errors.periodoMes.message
                            : "hidden"}
                        </span>
                      </div>

                      <div className="w-full md:pl-3 md:w-3/12 mb-7 md:mt-7 mt-3">
                        <button
                          className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 ease-in-out transition-all duration-400"
                          type="submit"
                        >
                          Ver
                        </button>
                      </div>
                    </div>
                  </form>

                  {botonAgregarVisible && (
                    <form onSubmit={handleSubmit(onSubmitAgregarSalud)}>
                      <div className="flex flex-wrap">
                        <div className="relative md:w-3/12 w-full md:pr-3 mb-3 md:mb-0">
                          <label
                            className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                            htmlFor="grid-last-name"
                          >
                            Tipo de pago
                          </label>

                          {watch("saludId") === "1" ? (
                            <input
                              disabled
                              value="N/A"
                              name="tipoDePago"
                              className="appearance-none block w-full bg-gray-400 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                              id="tipoDePago"
                              type="text"
                            />
                          ) : (
                            <>
                              <select
                                {...register("tipoDePago", {
                                  onChange: (e) => {
                                    setValue("montoValor", "$0");
                                  },
                                  ...validacionSelect,
                                })}
                                disabled={watch("saludId") === "7"}
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              >
                                <option hidden value="">
                                  Seleccionar
                                </option>
                                <option value="1">Tasa</option>
                                <option value="2">Pactado en $</option>
                                <option value="3">Pactado en UF</option>
                              </select>
                              <span
                                className={`text-red-500 text-xs italic ${
                                  !errors.tipoDePago && "opacity-0 invisible"
                                }`}
                              >
                                {errors.tipoDePago
                                  ? errors.tipoDePago.message
                                  : "hidden"}
                              </span>
                            </>
                          )}
                        </div>

                        <div className="md:w-3/12 w-5/12 md:px-3 pr-3">
                          <label
                            className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                            htmlFor="grid-last-name"
                          >
                            Monto
                          </label>
                          <>
                            {(watch("tipoDePago") === "" ||
                              watch("saludId") === "1") && (
                              <input
                                disabled
                                value={watch("saludId") === "1" ? "N/A" : ""}
                                className="appearance-none block w-full bg-gray-400 border-gray-400 text-gray-800 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                type="text"
                              />
                            )}

                            {watch("tipoDePago") === "1" && (
                              <input
                                {...register("montoPorcentaje")}
                                disabled
                                className="appearance-none block w-full bg-gray-400 border-gray-400 text-gray-800 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                type="text"
                              />
                            )}

                            {watch("tipoDePago") === "2" && (
                              <>
                                <input
                                  {...register("montoValor", {
                                    validate: (value) => {
                                      if (
                                        value === "" ||
                                        value === "$" ||
                                        value === "$0"
                                      ) {
                                        return "Monto mayor a $0";
                                      }
                                      return true;
                                    },
                                    onChange: () => {
                                      setValue(
                                        "montoValor",
                                        "$" +
                                          formatter.format(
                                            getValues("montoValor")?.replace(
                                              /\D/g,
                                              ""
                                            )
                                          )
                                      );
                                    },
                                  })}
                                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                  type="text"
                                />
                                <span
                                  className={`text-red-500 text-xs italic ${
                                    !errors.montoValor && "opacity-0 invisible"
                                  }`}
                                >
                                  {errors.montoValor
                                    ? errors.montoValor.message
                                    : "hidden"}
                                </span>
                              </>
                            )}

                            {watch("tipoDePago") === "3" && (
                              <>
                                <input
                                  {...register("montoUF", {
                                    validate: (value) => {
                                      if (
                                        value === "" ||
                                        value === "0" ||
                                        parseFloat(value.replace(",", ".")) <= 0
                                      ) {
                                        return "Monto mayor a 0";
                                      }
                                      return true;
                                    },
                                  })}
                                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                  type="text"
                                />

                                <span
                                  className={`text-red-500 text-xs italic ${
                                    !errors.montoUF && "opacity-0 invisible"
                                  }`}
                                >
                                  {errors.montoUF
                                    ? errors.montoUF.message
                                    : "hidden"}
                                </span>
                              </>
                            )}
                          </>
                        </div>

                        <div className="w-6/12 md:w-3/12 md:px-3">
                          <label
                            className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                            htmlFor="grid-last-name"
                          >
                            Código FUN
                          </label>
                          {watch("saludId") === "1" ||
                          watch("saludId") === "7" ||
                          watch("tipoDePago") === "" ? (
                            <input
                              value={
                                watch("saludId") === "1" ||
                                watch("saludId") === "7"
                                  ? "N/A"
                                  : ""
                              }
                              className="appearance-none block w-full  bg-gray-400 border-gray-400 text-gray-900 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                              id="sis"
                              type="text"
                              disabled
                            />
                          ) : (
                            <input
                              {...register("codigoFun", validacionSelect)}
                              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              type="text"
                            />
                          )}
                          <span
                            className={`text-red-500 text-xs italic ${
                              !errors.codigoFun && "opacity-0 invisible"
                            }`}
                          >
                            {errors.codigoFun
                              ? errors.codigoFun.message
                              : "hidden"}
                          </span>
                        </div>

                        <div className="w-full md:pl-3 md:w-2/12 mb-6 mt-3 md:mt-7">
                          <button
                  disabled={!botonGuardarHabilitado}
                  className={` ${!botonGuardarHabilitado? "bg-gray-500" : "bg-hercules-light-primary-button hover:shadow-lg hover:scale-105"}  text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400`}
                            type="submit"
                          >
                            Agregar
                          </button>
                        </div>
                      </div>
                    </form>
                  )}

                  <SimpleTable
                    col={COLUMNS}
                    tableData={listaSaludData}
                    searchBar={false}
                    showFilters={false}
                    tableHeight="h-96"
                  />
                </>
              )}
            </div>

            {/*footer*/}
            <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                type="button"
                onClick={() => {
                  onCloseEditorSalud();
                  reset();
                }}
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
