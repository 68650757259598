import React, { useEffect, useState } from "react";
import { useStateContext } from "../../../contexts/ContextProvider";
import { useDispatch, useSelector } from "react-redux";
import {
  getSolicitudVacacionesCCosto,
  hideLoader,
  limpiarInfoMenuEdicion,
  listaPeriodosHastaActual,
  showLoader,
} from "../../../Redux/Actions";
import { ColumnFilter } from "../../../components";
import TableGestionarVacaciones from "../components/GestionarVacaciones/TableGestionarVacaciones";
import ModalAprobarVacaciones from "../components/GestionarVacaciones/ModalAprobarVacaciones";
import ModalRechazarVacaciones from "../components/GestionarVacaciones/ModalRechazarVacaciones";
import ModalAnularVacaciones from "../components/GestionarVacaciones/ModalAnularVacaciones";
import UtilsFunctions from "../../../utils/UtilsFunctions";
import RutColumnFilter from "../../../components/RutColumnFilter";

export default function GestionarVacaciones() {
  const dispatch = useDispatch();
  const { refreshTable, activeMenu } = useStateContext();

  const [showModalAprobar, setShowModalAprobar] = useState(false);
  const [showModalRechazar, setShowModalRechazar] = useState(false);
  const [completoLlamada, setCompletoLlamada] = useState(false);

  const { rowDataGestionarVacaciones, setRowDataGestionarVacaciones } =
    useStateContext();

  const centroCostoId = localStorage.getItem("centroCostoId");

  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader());
      setCompletoLlamada(false);
      await Promise.all([
        dispatch(getSolicitudVacacionesCCosto(centroCostoId)),
      ]);
      dispatch(hideLoader());
      setCompletoLlamada(true);
    };

    fetchData().catch((error) => {
      console.log(error.response.data.message);
      dispatch(hideLoader());
      setCompletoLlamada(true);
    });
  }, [refreshTable, dispatch]);

  const infoRegistrosVacaciones = useSelector(
    (state) => state.infoSolicitudesVacacionesCCosto
  );

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("infoSolicitudesVacacionesCCosto"));
    };
  }, []);

  useEffect(() => {
    try {
      dispatch(listaPeriodosHastaActual());
    } catch (error) {
      console.log(error);
    }
  }, [refreshTable, dispatch]);

  const COLUMNAS_VACACIONES_SOLICITADAS = [
    {
      Header: "RUT",
      accessor: "rut",
      Cell: ({ value }) => UtilsFunctions.rutFormatter(value, { dots: true }),
      Filter: RutColumnFilter,
    },
    {
      Header: "Trabajador",
      accessor: (d) =>
        d.nombres + " " + d.apellidoPaterno + " " + d.apellidoMaterno,
      Filter: ColumnFilter,
    },
    {
      Header: "Días Hábiles Tomados",
      accessor: "diasHabiles",
      accessor: (d) => d.diasHabilesTotales + " días",
      Filter: ColumnFilter,
    },
    {
      Header: "Fecha de Solicitud",
      accessor: (d) => d.createdAt.slice(0, 10),
      Filter: ColumnFilter,
    },
    {
      Header: "Fecha de Inicio",
      accessor: "fechaInicio",
      Filter: ColumnFilter,
    },
    {
      Header: "Fecha de Término",
      accessor: "fechaTermino",
      Filter: ColumnFilter,
    },
    {
      Header: "Estado",
      accessor: "estado",
      Cell: ({ value }) => {
        if (value === "APROBADO") {
          return <p className="text-green-500">APROBADO</p>;
        }
        if (value === "ANULADO") {
          return <p className="text-yellow-500">ANULADO</p>;
        }
        if (value === "RECHAZADO") {
          return <p className="text-red-500">RECHAZADO</p>;
        }
        if (value === "PENDIENTE") {
          return <p>PENDIENTE</p>;
        }
      },
      Filter: ColumnFilter,
    },
  ];

  return (
    <>
      <div
        className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
          activeMenu && "md:w-[calc(100vw_-_23rem)]"
        }`}
      >
        <div className="titulo-Menu">Vacaciones</div>
        <div className="titulo-Pagina">Gestionar Vacaciones</div>
        {!infoRegistrosVacaciones.length && completoLlamada === true ? (
          <h3 className="text-xl md:pt-5 font-normal leading-normal dark:text-white text-hercules-light-font-secondary">
            No hay solicitudes de vacaciones por gestionar
          </h3>
        ) : (
          <div className="h-full md:mr-12 md:mt-3 rounded-lg">
            <TableGestionarVacaciones
              col={COLUMNAS_VACACIONES_SOLICITADAS}
              tableData={infoRegistrosVacaciones}
              botonLiquidaciones={true}
              exportCsvButton={false}
              checkButton={{
                // type: "check",
                onClickFunc: (data) => {
                  setShowModalAprobar(true);
                  setRowDataGestionarVacaciones(data);
                },
                active: true,
              }}
              rechazarButton={{
                // type: "check",
                onClickFunc: (data) => {
                  setShowModalRechazar(true);
                  setRowDataGestionarVacaciones(data);
                },
                active: true,
              }}
            />
          </div>
        )}
      </div>

      {showModalAprobar && (
        <ModalAprobarVacaciones
          isVisible={showModalAprobar}
          onClose={() => setShowModalAprobar(false)}
          modalData={rowDataGestionarVacaciones}
        />
      )}

      {showModalRechazar && (
        <ModalRechazarVacaciones
          isVisible={showModalRechazar}
          onClose={() => setShowModalRechazar(false)}
          modalData={rowDataGestionarVacaciones}
        />
      )}

    </>
  );
}
