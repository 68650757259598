import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { obtenerCausalesFiniquito } from "../../../../Redux/Actions";
import AgregarConceptoFiniquito from "./AgregarConceptoFiniquitoForm";
import ConceptosFiniquitosTabla from "./ConceptosFiniquitoTabla";
import ColumnFilter from "../../../ColumnFilter";
import UtilsFunctions from "../../../../utils/UtilsFunctions";
import { axiosPrivate } from "../../../../common/axiosPrivate";
import { toast } from "react-hot-toast";
import { useStateContext } from "../../../../contexts/ContextProvider";

export default function ModalFiniquito({ isVisible, onClose, modalData }) {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  const userId = localStorage.getItem("userId");

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const periodoId = localStorage.getItem("periodoId");
  const periodoMes = localStorage.getItem("periodoMes");
  const periodoAnyo = localStorage.getItem("periodoAnyo");
  const { refreshTable, setRefreshTable, screenSize } = useStateContext();
  const [botonGuardarHabilitado, setBotonGuardarHabilitado] = useState(true)

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const validacionTextInput = {
    required: { value: true, message: "Obligatorio" },
  };
  const listaCausalesFiniquito = useSelector(
    (state) => state.causalesFiniquito
  );
  const [conceptosFiniquito, setConceptosFiniquito] = useState([]);
  const [montoTotalFiniquito, setMontoTotalFiniquito] = useState(0);

  useEffect(() => {
    dispatch(obtenerCausalesFiniquito());
    if (modalData) {
      reset({
        nombreTrabajador:
          modalData.trabajador.nombres +
          " " +
          modalData.trabajador.apellidoPaterno +
          " " +
          modalData.trabajador.apellidoMaterno,
        rutTrabajador: UtilsFunctions.rutFormatter(modalData.trabajador.rut, {dots: true}),
        nombreEmpresa: modalData.centroCosto.empresa.nombreFantasia,
        fechaInicio: `${modalData.fechaIngreso.split("-")[2]}-${
          modalData.fechaIngreso.split("-")[1]
        }-${modalData.fechaIngreso.split("-")[0]}`,
        labor: modalData.cargo.nombre,
      });
    }
  }, [dispatch, modalData]);

  function agregarHaber(concepto) {
    if (!concepto) {
      return null;
    }
    const conceptoNuevo = {
      tipoConcepto: concepto.tipoConcepto.toUpperCase(),
      descripcion: concepto.descripcion,
      valor: concepto.valor,
    };
    const nuevoArray = [...conceptosFiniquito, conceptoNuevo];
    setConceptosFiniquito(nuevoArray);
    nuevoArray.map((e) => {
      if (e.tipoConcepto === "HABER") {
        setMontoTotalFiniquito(
          parseInt(montoTotalFiniquito) + parseInt(e.valor)
        );
      }
      if (e.tipoConcepto === "DESCUENTO") {
        setMontoTotalFiniquito(
          parseInt(montoTotalFiniquito) - parseInt(e.valor)
        );
      }
    });
  }

  async function onSubmit(data) {
    setBotonGuardarHabilitado(false)
    toast.loading("Finiquitando Trabajador")
    try {
      const payload = {
        usuarioId: userId,
        periodoId: periodoId,
        fechaFiniquito: data.fechaTermino,
        causalId: data.causalTerminoContratoId,
        total: montoTotalFiniquito,
        contratoId: modalData.id,
        conceptos: conceptosFiniquito,
      };
      const response = await axiosPrivate.post(
        `${api_service}/api/v1/finiquitos`,
        payload,
        Authorization
      );
      setRefreshTable(!refreshTable);
      toast.dismiss();
      toast.success("Trabajador finiquitado con éxito", estilosAlerta);
      reset();
      onClose();
      setBotonGuardarHabilitado(true)
    } catch (error) {
      toast.dismiss()
      toast.error(error.response.data.message)
      console.log("error", error)
      setBotonGuardarHabilitado(true)
    }
  }

  const TABLA_CONCEPTOS_FINIQUITO = [
    {
      Header: "Tipo",
      accessor: "tipoConcepto",
      accessor: (d) =>
        d.tipoConcepto[0] + d.tipoConcepto.slice(1).toLowerCase(),
      Filter: ColumnFilter,
    },
    {
      Header: "Descripción",
      accessor: "descripcion",
      Filter: ColumnFilter,
    },
    {
      Header: "Valor",
      accessor: (d) => "$" + UtilsFunctions.integerFormatter(d.valor),
      Filter: ColumnFilter,
    },
  ];

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="primerDivModal">
          <div className="segundoDivModal max-w-4xl">
            {/*content*/}
            <div className="tercerDivModal shadow-lg  max-h-[85vh] ">
              {/*header*/}
              <div className="divHeaderModal border-slate-200">
                <h3 className="titulo-Modal">
                  Crear Nuevo Finiquito</h3>
              </div>
              {/*body*/}
              <div className="relative p-6 flex-auto overflow-auto dark:bg-hercules-dark-bg-200">
                {/* Primera fila */}
                <div className="md:flex md:flex-wrap">
                  <div className="w-full md:w-5/12 md:pr-3 md:mb-11 mb-9">
                    <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                      Nombre Trabajador
                    </label>
                    <input
                      {...register("nombreTrabajador")}
                      disabled
                      className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                      type="text"
                    />
                  </div>

                  <div className="w-full md:w-3/12 md:px-3 md:mb-11 mb-9">
                    <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                      Rut Trabajador
                    </label>
                    <input
                      {...register("rutTrabajador")}
                      disabled
                      className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                      type="text"
                    />
                  </div>

                  <div className="w-full md:w-4/12 md:pl-3 md:mb-11 mb-9">
                    <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                      Empresa
                    </label>
                    <input
                      {...register("nombreEmpresa")}
                      disabled
                      className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                      type="text"
                    />
                  </div>
                </div>

                <h3 className="text-2xl font-normal leading-normal mb-5 md:mb-3 dark:text-white text-hercules-light-font-secondary">
                  Datos del Finiquito
                </h3>

                <div className="flex flex-wrap">
                  <div className="div-Inputs-EditarHaber md:w-3/12 md:pr-3">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      Fecha inicio
                    </label>
                    <input
                      {...register("fechaInicio")}
                      name="fechaInicio"
                      disabled
                      className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      id="grid-last-name"
                      type="date"
                    />
                    <span
                      className={`text-red-500 dark:text-red-400 text-xs italic ${
                        !errors.fechaInicio && "opacity-0 invisible"
                      }`}
                    >
                      {errors.fechaInicio
                        ? errors.fechaInicio.message
                        : "hidden"}
                    </span>
                  </div>

                  <div className="div-Inputs-EditarHaber md:w-3/12 md:px-3">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      Fecha Término
                    </label>
                    <input
                      {...register("fechaTermino", validacionTextInput)}
                      name="fechaTermino"
                      // min={watch("fechaInicio")}
                      min={`${periodoAnyo}-${
                        periodoMes > 9 ? periodoMes : 0 + periodoMes
                      }-01`}
                      max={`${periodoAnyo}-${
                        periodoMes > 9 ? periodoMes : 0 + periodoMes
                      }-31`}        
                      className="hover:cursor-pointer appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-last-name"
                      type="date"
                    />
                    <span
                      className={`text-red-500 dark:text-red-400 text-xs italic ${
                        !errors.fechaTermino && "opacity-0 invisible"
                      }`}
                    >
                      {errors.fechaTermino
                        ? errors.fechaTermino.message
                        : "hidden"}
                    </span>
                  </div>
                </div>

                <div className="flex flex-wrap">
                  <div className="w-full md:w-6/12 md:pr-3 md:mb-11 mb-9">
                    <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                      Labor
                    </label>
                    <input
                      {...register("labor")}
                      disabled
                      className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                      type="text"
                    />
                  </div>

                  <div className="div-Inputs-EditarHaber md:w-6/12 md:pl-3">
                    <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                      Causal término de contrato
                    </label>
                    <select
                      {...register("causalTerminoContratoId", {
                        required: {
                          value: true,
                          message: "Obligatorio",
                        },
                        validate: (value) => value !== "",
                      })}
                      name="causalTerminoContratoId"
                      className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                    >
                      <option value="" hidden>
                        Seleccionar Causal
                      </option>
                      {listaCausalesFiniquito.map((causal, i) => (
                        <>
                          <option key={i} value={causal.id}>
                            {causal.glosa}
                          </option>
                        </>
                      ))}
                    </select>
                    <span
                      className={`text-red-500 dark:text-red-400 text-xs italic ${
                        !errors.causalTerminoContratoId && "opacity-0 invisible"
                      }`}
                    >
                      {errors.causalTerminoContratoId
                        ? errors.causalTerminoContratoId.message
                        : "hidden"}
                    </span>
                  </div>
                </div>

                <hr className="mb-3 md:mb-6" />
                <h3 className="text-2xl font-normal leading-normal mb-5 md:mb-3 dark:text-white text-hercules-light-font-secondary">
                  Conceptos del finiquito
                </h3>

                <AgregarConceptoFiniquito
                  sendFormData={(concepto) => agregarHaber(concepto)}
                />

                {/* Tabla de Conceptos */}
                <div className="h-full rounded-lg md:mt-0 mt-6">
                  <ConceptosFiniquitosTabla
                    col={TABLA_CONCEPTOS_FINIQUITO}
                    tableData={conceptosFiniquito}
                    botonEliminar={true}
                    borrarConcepto={(index) => {
                      const arrayEliminado = conceptosFiniquito.splice(
                        index,
                        1
                      );
                      const arrayEditado = [...conceptosFiniquito];
                      setConceptosFiniquito(arrayEditado);
                      arrayEliminado.map((e) => {
                        if (e.tipoConcepto === "HABER") {
                          console.log("valor a borrar", e.valor);
                          setMontoTotalFiniquito(
                            parseInt(montoTotalFiniquito) - parseInt(e.valor)
                          );
                        }
                        if (e.tipoConcepto === "DESCUENTO") {
                          setMontoTotalFiniquito(
                            parseInt(montoTotalFiniquito) + parseInt(e.valor)
                          );
                        }
                      });
                    }}
                  />
                </div>

                <div className="w-5/12 md:w-2/12 mt-3 mb-3">
                  <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                    Total
                  </label>
                  <input
                    value={
                      "$" + UtilsFunctions.integerFormatter(montoTotalFiniquito)
                    }
                    disabled
                    className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                    type="text"
                  />
                </div>
              </div>

              <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                  type="button"
                  onClick={() => {
                    onClose();
                    reset();
                  }}
                >
                  Cerrar
                </button>
                <button
                  type="submit"
                  disabled={!botonGuardarHabilitado}
                  className={` ${!botonGuardarHabilitado? "bg-gray-500" : "bg-hercules-light-primary-button hover:shadow-lg hover:scale-105"}  text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400`}
                  >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </form>
    </>
  );
}
