import React, { useEffect, useState } from "react";
import { useStateContext } from "../../../contexts/ContextProvider";
import { getFeriadosCentroCosto, hideLoader, limpiarInfoMenuEdicion, showLoader } from "../../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import SimpleTable from "../../../components/SimpleTable";
import { ColumnFilter } from "../../../components";
import ModalEliminarFeriado from "../components/Feriados/EliminarFeriado";
import ModalAgregarFeriado from "../components/Feriados/AgregarFeriado";

export default function AdministrarFeriados(){

    const dispatch = useDispatch();

    const { refreshTable, setRefreshTable, activeMenu, screenSize } = useStateContext();

    const [modalDataEliminarFeriado, setModalDataEliminarFeriado] = useState([]);
    const [showModalEliminarFeriado, setShowModalEliminarFeriado] = useState(false);
    const [showModalAgregarFeriado, setShowModalAgregarFeriado] = useState(false);

    const centroCostoId = localStorage.getItem("centroCostoId");
    
    useEffect(() => {
      const fetchData = async () => {
        dispatch(showLoader());
        await Promise.all([
          dispatch(getFeriadosCentroCosto(centroCostoId))
        ]);
        dispatch(hideLoader());
      };
  
      fetchData().catch((error) => {
        console.log(error.response.data.message);
        dispatch(hideLoader());
      });
    }, [refreshTable, dispatch]);
  
    const infoFeriadosCentroCosto = useSelector(
      (state) => state.feriadosCentroCosto
    );
  
    useEffect(() =>{
      return () => {
        dispatch(limpiarInfoMenuEdicion("feriadosCentroCosto"))
      }
    },[])  

    async function eliminarFeriado(data){
        setModalDataEliminarFeriado(data)
        setShowModalEliminarFeriado(true)
    }

    const COLUMNS = [
        {
          Header: "Fecha",
          accessor: "fecha",
          Filter: ColumnFilter,
        },
        {
          Header: "Descripción",
          accessor: "descripcion",
          Filter: ColumnFilter,
        },
        {
          Header: "Activo",
          accessor: (d) => d.activo === true ? "Si" : "No",
          Filter: ColumnFilter,
        },
      ];
    

    return(
        <>
    <div
      className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
        activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">Vacaciones</div>
      <div className="titulo-Pagina">Administrar Feriados</div>

        <div className=" relative h-full rounded-lg md:mr-12 md:mt-3">
          <SimpleTable
            col={COLUMNS}
            tableData={infoFeriadosCentroCosto}
            secondaryButton={{
              type: "eliminar",
              onClickFunc: (data) => {
                eliminarFeriado(data);
              },
              active: true,
            }}
            exportCsvButton={false}
          />
        </div>

        <button
          onClick={() => setShowModalAgregarFeriado(true)}
          className={`mt-3 bg-hercules-light-primary-button hover:shadow-lg text-white dark:text-dark-primary font-medium uppercase text-normal px-4 py-3 rounded shadow  outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}  
      >
          Agregar Feriado
        </button>

    </div>
    {showModalEliminarFeriado &&
      <ModalEliminarFeriado
        isVisible={showModalEliminarFeriado}
        onClose={() => setShowModalEliminarFeriado(false)}
        modalData={modalDataEliminarFeriado}
      />
    }
        {showModalAgregarFeriado &&
      <ModalAgregarFeriado
        isVisible={showModalAgregarFeriado}
        onClose={() => setShowModalAgregarFeriado(false)}
      />
    }
        </>
    )
}