import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useStateContext } from "../../../../contexts/ContextProvider";

export default function AgregarAPVsForm({
  institucionesApv,
  sendFormData,
  datosTrabajadorEditar,
}) {
  const {
    register,
    reset,
    watch,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      apvId: "",
      tipoContrato: "",
      tipoApv: "",
      numeroContrato: "",
      tipoMonto: "",
      monto: "",
    },
  });

  const { screenSize } = useStateContext();

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  }

  const periodoId = localStorage.getItem("periodoId");

  const validacionTextInput = {
    required: { value: true, message: "Obligatorio" },
  };

  const formatter = new Intl.NumberFormat("es-CL", {
    styles: "currency",
    currency: "CLP",
    minimumFractionDigits: 0,
  });

  async function agregarAPV() {
    const passFormValidation = await trigger();

    if (!passFormValidation) {
      return null;
    }
    toast.loading("Agregando APV", estilosAlerta);
    const data = getValues();
    try {
      const payload = {
        trabajadorId: datosTrabajadorEditar.id,
        apvId: data.apvId,
        tipoContrato: data.tipoContrato,
        tipoApv: data.tipoApv,
        numeroContrato: data.numeroContrato,
        monto:
          data.tipoMonto === "0"
            ? data.montoPesos
                ?.replace(/\./g, "")
                .replace("$", "")
                .replace(" ", "")
            : data.tipoMonto === "1"
            ? data.montoUF?.replace(",", ".").replace(" ", "").replace("$", "")
            : data.tipoMonto === "2" &&
              data.montoPorcentaje?.replace(" %", "").replace(",", "."),
        tipoPago: data.tipoMonto,
        montoAporteEmpleador:
          data.tipoContrato === "Individual"
            ? 0
            : data.tipoMonto === "0"
            ? data.montoPesosAporteEmpleador
                ?.replace(/\./g, "")
                .replace("$", "")
                .replace(" ", "")
            : data.tipoMonto === "1"
            ? data.montoUFAporteEmpleador
                ?.replace(",", ".")
                .replace(" ", "")
                .replace("$", "")
            : data.tipoMonto === "2" &&
              data.montoPorcentajeAporteEmpleador
                ?.replace(" %", "")
                .replace(",", "."),
        periodoId: periodoId,
      };
      reset();
      return payload;
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al agregar APV",
          estilosAlerta
      );
    }
  }

  return (
    <>
      <h3 className="text-2xl font-normal leading-normal mb-3 dark:text-white text-hercules-light-font-secondary">
        APVs trabajador
      </h3>

      <div className="flex flex-wrap">

        <div className="div-Inputs-EditarHaber md:w-3/12 md:pr-3">
          <label
            className="block  tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
            htmlFor="grid-last-name"
          >
            Tipo de Contrato
          </label>
          <select
            {...register("tipoContrato", {
              ...validacionTextInput,
              onChange: () => {
                setValue("tipoMonto", "");
                setValue("montoPesosAporteEmpleador", "$ 0");
                setValue("montoUFAporteEmpleador", "0");
                setValue("montoPorcentajeAporteEmpleador", "0 %");
              },
            })}
            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          >
            <option hidden value="">
              Seleccionar tipo contrato
            </option>
            <option value={"Individual"}>Individual</option>
            <option value={"Colectivo"}>Colectivo</option>
          </select>
          <span
            className={`text-red-500 text-xs italic ${
              !errors.tipoContrato && "opacity-0 invisible"
            }`}
          >
            {errors.tipoContrato ? errors.tipoContrato.message : "hidden"}
          </span>
        </div>

        <div className="div-Inputs-EditarHaber md:w-3/12 md:px-3">
          <label
            className="block  tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
            htmlFor="grid-last-name"
          >
            Institución
          </label>
          <select
            {...register("apvId", validacionTextInput)}
            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          >
            <option hidden value="">
              Seleccionar Institución
            </option>
            {institucionesApv?.map((apv) => {
              return (
                <option value={apv.id} key={apv.id}>
                  {apv.glosa}
                </option>
              );
            })}
          </select>
          <span
            className={`text-red-500 text-xs italic ${
              !errors.apvId && "opacity-0 invisible"
            }`}
          >
            {errors.apvId ? errors.apvId.message : "hidden"}
          </span>
        </div>

        <div className="div-Inputs-EditarHaber md:w-3/12 md:px-3">
          <label
            className="block  tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
            htmlFor="grid-last-name"
          >
            Tipo de Régimen Tributario
          </label>
          <select
            {...register("tipoApv", validacionTextInput)}
            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          >
            <option hidden value="">
              Seleccionar cuenta
            </option>
            <option value={"A"}>A</option>
            <option value={"B"}>B</option>
          </select>
          <span
            className={`text-red-500 text-xs italic ${
              !errors.tipoApv && "opacity-0 invisible"
            }`}
          >
            {errors.tipoApv ? errors.tipoApv.message : "hidden"}
          </span>
        </div>

      </div>

      <div className="div-Inputs-EditarHaber md:w-3/12 md:pr-3">
          <label
            className="block  tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
            htmlFor="grid-last-name"
          >
            Tipo de Monto
          </label>
          <select
            {...register("tipoMonto", {
              ...validacionTextInput,
              onChange: () => {
                setValue("montoPesos", "$ 0");
                setValue("montoUF", "0");
                setValue("montoPorcentaje", "0 %");
              },
            })}
            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          >
            <option hidden value="">
              Seleccionar tipo monto
            </option>
            <option value={"0"}>Pesos</option>
            <option value={"1"}>UF</option>
            <option value={"2"}>% Imponible</option>
          </select>
          <span
            className={`text-red-500 text-xs italic ${
              !errors.tipoMonto && "opacity-0 invisible"
            }`}
          >
            {errors.tipoMonto ? errors.tipoMonto.message : "hidden"}
          </span>
        </div>

      <div className="flex flex-wrap">
        <div className="div-Inputs-EditarHaber md:w-3/12 md:pr-3">
          <label
            className="block  tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
            htmlFor="grid-last-name"
          >
            Monto
          </label>

          {watch("tipoMonto") === "" && (
            <input
              disabled
              value="Seleccionar Tipo Monto"
              className="mb-6 appearance-none block w-full bg-gray-400 border-gray-400 text-gray-800 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="text"
            />
          )}

          {watch("tipoMonto") === "0" && (
            <>
              <input
                {...register("montoPesos", {
                  ...validacionTextInput,
                  onChange: () => {
                    setValue(
                      "montoPesos",
                      "$ " +
                        formatter.format(
                          getValues("montoPesos")?.replace(/\D/g, "")
                        )
                    );
                  },
                })}
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="text"
              />
              <span
                className={`text-red-500 text-xs italic ${
                  !errors.montoPesos && "opacity-0 invisible"
                }`}
              >
                {errors.montoPesos ? errors.montoPesos.message : "hidden"}
              </span>
            </>
          )}
          {watch("tipoMonto") === "1" && (
            <>
              <input
                {...register("montoUF", {
                  validate: (value) => {
                    if (
                      value === "" ||
                      value === "0" ||
                      parseFloat(value.replace(",", ".")) <= 0
                    ) {
                      return "Monto mayor a 0";
                    }
                    return true;
                  },
                  onChange: () => {
                    setValue(
                      "montoUF",
                      getValues("montoUF")?.replace(/[^\d.,]/g, "")
                    );
                  },
                })}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="text"
              />

              <span
                className={`text-red-500 text-xs italic ${
                  !errors.montoUF && "opacity-0 invisible"
                }`}
              >
                {errors.montoUF ? errors.montoUF.message : "hidden"}
              </span>
            </>
          )}
          {watch("tipoMonto") === "2" && (
            <>
              <input
                {...register("montoPorcentaje", {
                  validate: (value) => {
                    if (value === "" || value === "%" || parseInt(value) < 0) {
                      return "Mayor a 0%";
                    }
                    return true;
                  },
                  onChange: () => {
                    setValue(
                      "montoPorcentaje",
                      getValues("montoPorcentaje")?.replace(/[^\d.,]/g, "") +
                        " %"
                    );
                  },
                })}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="text"
              />
              <span
                className={`text-red-500 text-xs italic ${
                  !errors.montoPorcentaje && "opacity-0 invisible"
                }`}
              >
                {errors.montoPorcentaje
                  ? errors.montoPorcentaje.message
                  : "hidden"}
              </span>
            </>
          )}
        </div>

        {watch("tipoContrato") === "Colectivo" && (
        <div className="div-Inputs-EditarHaber md:w-3/12 md:px-3">
            <label
              className="block  tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
              htmlFor="grid-last-name"
            >
              Monto - Aporte Empleador
            </label>

            {watch("tipoMonto") === "" && (
              <input
                disabled
                value="Seleccionar Tipo Monto"
                className="mb-6 appearance-none block w-full bg-gray-400 border-gray-400 text-gray-800 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="text"
              />
            )}

            {watch("tipoMonto") === "0" && (
              <>
                <input
                  {...register("montoPesosAporteEmpleador", {
                    ...validacionTextInput,
                    onChange: () => {
                      setValue(
                        "montoPesosAporteEmpleador",
                        "$ " +
                          formatter.format(
                            getValues("montoPesosAporteEmpleador")?.replace(
                              /\D/g,
                              ""
                            )
                          )
                      );
                    },
                  })}
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                />
                <span
                  className={`text-red-500 text-xs italic ${
                    !errors.montoPesosAporteEmpleador && "opacity-0 invisible"
                  }`}
                >
                  {errors.montoPesosAporteEmpleador
                    ? errors.montoPesosAporteEmpleador.message
                    : "hidden"}
                </span>
              </>
            )}
            {watch("tipoMonto") === "1" && (
              <>
                <input
                  {...register("montoUFAporteEmpleador", {
                    validate: (value) => {
                      if (
                        value === "" ||
                        value === "0" ||
                        parseFloat(value.replace(",", ".")) <= 0
                      ) {
                        return "Monto mayor a 0";
                      }
                      return true;
                    },
                    onChange: () => {
                      setValue(
                        "montoUFAporteEmpleador",
                        getValues("montoUFAporteEmpleador")?.replace(
                          /[^\d.,]/g,
                          ""
                        )
                      );
                    },
                  })}
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                />

                <span
                  className={`text-red-500 text-xs italic ${
                    !errors.montoUFAporteEmpleador && "opacity-0 invisible"
                  }`}
                >
                  {errors.montoUFAporteEmpleador
                    ? errors.montoUFAporteEmpleador.message
                    : "hidden"}
                </span>
              </>
            )}
            {watch("tipoMonto") === "2" && (
              <>
                <input
                  {...register("montoPorcentajeAporteEmpleador", {
                    validate: (value) => {
                      if (
                        value === "" ||
                        value === "%" ||
                        parseInt(value) < 0
                      ) {
                        return "Mayor a 0%";
                      }
                      return true;
                    },
                    onChange: () => {
                      setValue(
                        "montoPorcentajeAporteEmpleador",
                        getValues("montoPorcentajeAporteEmpleador")?.replace(
                          /[^\d.,]/g,
                          ""
                        ) + " %"
                      );
                    },
                  })}
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                />
                <span
                  className={`text-red-500 text-xs italic ${
                    !errors.montoPorcentajeAporteEmpleador &&
                    "opacity-0 invisible"
                  }`}
                >
                  {errors.montoPorcentajeAporteEmpleador
                    ? errors.montoPorcentajeAporteEmpleador.message
                    : "hidden"}
                </span>
              </>
            )}
          </div>
      )}

        <div className="div-Inputs-EditarHaber md:w-3/12 md:px-3">
          <label
            className="block  tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
            htmlFor="grid-last-name"
          >
            Número de Contrato
          </label>
          <input
            {...register("numeroContrato", validacionTextInput)}
            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="text"
          />
          <span
            className={`text-red-500 text-xs italic ${
              !errors.numeroContrato && "opacity-0 invisible"
            }`}
          >
            {errors.numeroContrato ? errors.numeroContrato.message : "hidden"}
          </span>
        </div>

        <div className="w-full md:pl-3 md:w-3/12 mb-7 md:mt-7">
        <button
          className="hover:scale-105 bg-hercules-dark-primary-button text-white font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 ease-in-out transition-all duration-400"
          type="button"
          onClick={async () => sendFormData(await agregarAPV())}
        >
          Agregar APV
        </button>
        </div>

      </div>
    </>
  );
}
