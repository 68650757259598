import React, { useEffect, useState } from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  getCentrosCostosByEmpresaId,
  getDescuentos,
  getHaberes,
  getJornadas,
} from "../../Redux/Actions";
import ActualizarHaberesDescuentosTable from "../../components/Herramientas/ActualizarHaberesDescuentos/ActualizarHaberesDescuentosTable";
import UtilsFunctions from "../../utils/UtilsFunctions";
import { ColumnFilter } from "../../components";
import { toast } from "react-hot-toast";
import { axiosPrivate } from "../../common/axiosPrivate";

export default function ActualizarHaberesDescuentos() {
  const { register, getValues, setValue, handleSubmit, formState } = useForm({
    defaultValues: {
      categoriaLRE: "Seleccione un Nombre",
      nombreHaberDescuento: "",
    },
  });

  const { errors } = formState;
  const dispatch = useDispatch();
  const { activeMenu, refreshTable, setRefreshTable, screenSize } =
    useStateContext();
  const listaHaberes = useSelector((state) => state.getHaberes);
  const infoCCosto = useSelector((state) => state.centrosCostoEmpresa);
  const jornadas = useSelector((state) => state.getJornadas);
  const [infoTabla, setInfoTabla] = useState([]);
  const [filasSeleccionadasObjVacio, setFilasSeleccionadasObjVacio] = useState(
    {}
  );

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const empresaId = localStorage.getItem("empresaId");

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const validacionTextInput = {
    required: { value: true, message: "Obligatorio" },
  };

  useEffect(() => {
    dispatch(getHaberes());
    dispatch(getCentrosCostosByEmpresaId(empresaId));
    dispatch(getJornadas());
  }, [refreshTable]);

  async function onSubmit(data) {
    toast.loading("Cargando tabla", estilosAlerta);
    try {
      const response = await axiosPrivate(
        `${api_service}/api/v1/contratos/contratos-anexos-haberes/empresa/${empresaId}/centro-costo/${data.centroCostoId}/jornada/${data.jornadaId}/haber/${data.nombreHaberDescuento}`,
        Authorization
      );
      if (!response.data.length > 0) {
        toast.dismiss();
        toast.error("No existen registros de este Haber", estilosAlerta);
        setInfoTabla([]);
      } else {
        setInfoTabla(response.data);
        toast.dismiss();
        toast.success("Tabla cargada con éxito", estilosAlerta);
      }
    } catch (error) {
      console.log("error", error);
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al cargar tabla",
        estilosAlerta
      );
    }
  }

  const COLUMNS = [
    {
      Header: "RUT",
      accessor: (d) =>
        UtilsFunctions.rutFormatter(d.rutTrabajador, { dots: true }),
      Filter: ColumnFilter,
    },
    {
      Header: "Apellido paterno",
      accessor: "apellidoPaternoTrabajador",
      Filter: ColumnFilter,
    },
    {
      Header: "Apellido materno",
      accessor: "apellidoMaternoTrabajador",
      Filter: ColumnFilter,
    },
    {
      Header: "Nombre",
      accessor: "nombresTrabajador",
      Filter: ColumnFilter,
    },
    {
      Header: "Valor Actual",
      accessor: (d) => "$" + UtilsFunctions.integerFormatter(d.valor),
      Filter: ColumnFilter,
    },
    {
      Header: "Nuevo Valor",
      accessor: (d) => {
        if (d.nuevoValor) {
          return "$" + UtilsFunctions.integerFormatter(d?.nuevoValor);
        }
        return "N/A";
      },
      Filter: ColumnFilter,
    },
  ];

  async function actualizarTabla() {
    try {
      const data = getValues();
      const responseHaberes = await axiosPrivate(
        `${api_service}/api/v1/contratos/contratos-anexos-haberes/empresa/${empresaId}/centro-costo/${data.centroCostoId}/jornada/${data.jornadaId}/haber/${data.nombreHaberDescuento}`,
        Authorization
      );
      setRefreshTable(!refreshTable);
      setFilasSeleccionadasObjVacio({});
      setInfoTabla(responseHaberes.data);
      toast.dismiss();
      toast.success("Tabla actualizada con éxito", estilosAlerta);
    } catch (error) {
      toast.dismiss();
      toast.error("Error al actualizar la tabla", estilosAlerta);
    }
  }

  return (
    <div
      className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
        activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">Herramientas</div>
      <div className="titulo-Pagina">Actualizar haberes</div>

      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="pt-3 flex flex-wrap">
          <div className="w-full md:w-3/12 md:mr-3 mb-3">
            <label className="dark:text-white text-hercules-light-font-secondary">
              Nombre
            </label>
            <select
              {...register("nombreHaberDescuento", {
                ...validacionTextInput,
                onChange: (e) => {
                  const nombre = listaHaberes.find(
                    (haber) => haber.id == e.target.value
                  );
                  setValue("categoriaLRE", nombre.haberesDt.glosa);
                  setInfoTabla([]);
                  setFilasSeleccionadasObjVacio({});
                },
              })}
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option hidden value="">
                Seleccionar
              </option>
              {listaHaberes.map((haber, i) => (
                <option value={haber.id} key={i}>
                  {haber.nombre}
                </option>
              ))}
            </select>
            <span
              className={`text-red-500 dark:text-red-400 text-xs italic ${
                !errors.nombreHaberDescuento && "opacity-0 invisible"
              }`}
            >
              {errors.nombreHaberDescuento
                ? errors.nombreHaberDescuento.message
                : "hidden"}
            </span>
          </div>

          <div className="w-full md:w-6/12 md:ml-3 mb-9">
            <label className="dark:text-white text-hercules-light-font-secondary">
              Categoría L.R.E.
            </label>
            <input
              disabled
              {...register("categoriaLRE")}
              className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
              id="grid-rut"
              type="text"
            />
          </div>
        </div>

        <div className="flex flex-wrap">
          <div className="w-full md:w-2/12 md:pr-3 md:mb-11 mb-9">
            <label className="dark:text-white text-hercules-light-font-secondary">
              Centro de Costo
            </label>
            <select
              {...register("centroCostoId")}
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option value={"0"}>Todos</option>
              {infoCCosto?.map((ccosto, i) => (
                <option value={ccosto.id} key={i}>
                  {ccosto.nombre}
                </option>
              ))}
            </select>
          </div>

          <div className="w-full md:w-3/12 md:px-3 md:mb-11 mb-9">
            <label className="dark:text-white text-hercules-light-font-secondary">
              Jornada
            </label>
            <select
              {...register("jornadaId")}
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option value={"0"}>Todas</option>
              {jornadas?.map((jornada, i) => (
                <option value={jornada.id} key={i}>
                  {jornada.nombre}
                </option>
              ))}
            </select>
          </div>

          <div className="w-full md:w-3/12 md:mt-6 mb-6 md:mb-0">
            <button
              type="submit"
              className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
            >
              Buscar
            </button>
          </div>
        </div>
      </form>

      {infoTabla.length > 0 && (
        <div className="h-full md:mr-12 rounded-lg mt-5 md:mt-0">
          <ActualizarHaberesDescuentosTable
            col={COLUMNS}
            tableData={infoTabla}
            infoRecibidaTableData={(data, objeto) => {
              setInfoTabla(data);
              setFilasSeleccionadasObjVacio(objeto);
            }}
            filasSeleccionadas={filasSeleccionadasObjVacio}
            actualizarTablaFront={(nuevoBody) => {
              actualizarTabla(nuevoBody);
            }}
          />
        </div>
      )}
    </div>
  );
}
