import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCentroCostos, listaPeriodosHastaActual } from "../../Redux/Actions";
import { useStateContext } from "../../contexts/ContextProvider";
import { ColumnFilter } from "../../components";
import UtilsFunctions from "../../utils/UtilsFunctions";
import { useForm } from "react-hook-form";
import { axiosPrivate } from "../../common/axiosPrivate";
import EliminarLiquidacionCalculadaTable from "../../components/Herramientas/EliminarLiquidacionCalculada/EliminarLiquidacionCalculadaTable";
import { toast } from "react-hot-toast";

export default function EliminarLiquidacionCalculada() {
  const {
    register,
    getValues,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      centroCostoId: 0,
      periodoAnyo: "",
    },
  });

  const { refreshTable, setRefreshTable, activeMenu, screenSize } = useStateContext();

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const dispatch = useDispatch();
  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const empresaId = localStorage.getItem("empresaId");
  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  useEffect(() => {
    try {
      getPeriodosCargados()
      dispatch(listaPeriodosHastaActual());
      dispatch(getCentroCostos());
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  const anyosData = UtilsFunctions.obtenerAnyosHastaActual();
  const [mesesData, setMesesData] = useState([]);
  const [infoPeriodos, setInfoPeriodos] = useState([]);
  const [infoLiquidaciones, setInfoLiquidaciones] = useState([]);
  const [periodoId, setPeriodoId] = useState("");

  const periodoMes = localStorage.getItem("periodoMes");

  async function getPeriodosCargados(){
    const infoMeses = await axiosPrivate(`${api_service}/api/v1/periodos/cargados`, Authorization)
    setInfoPeriodos(infoMeses.data)
  }

  const handleYearChange = (e) => {
    const year = parseInt(e);
    const yearData = infoPeriodos.find((item) => item.anyo === year);
    if (yearData) {
      const filteredMonths = yearData.periodos.filter(
        (mes) => mes.datosPrevisionalesCargados === true
      );
      const transformedMonths = filteredMonths.map((month) => (
        UtilsFunctions.integerToMonth(month.mes)
      ));
      setMesesData(transformedMonths);

    } else {
      setMesesData([]);
    }
  };

  async function buscarLiquidacionesCalculadas() {
    const data = getValues();

    toast.loading("Cargando", estilosAlerta);
    try {
      const info = await axiosPrivate.get(
        `${api_service}/api/v1/periodos/mes/${data.numeroMes}/anyo/${data.periodoAnyo}`,
        Authorization
      );
      setPeriodoId(info.data.id);
      const response = await axiosPrivate.get(
        `${api_service}/api/v1/liquidaciones/periodo/${info.data.id}/empresa/${empresaId}/`,
        Authorization
      );
      if (!response.data.length > 0) {
        toast.dismiss();
        toast.error("Sin Liquidaciones en este periodo", estilosAlerta);
        setInfoLiquidaciones([]);
      } else {
        setInfoLiquidaciones(response.data);
        setRefreshTable(!refreshTable);
        toast.dismiss();
        toast.success("Liquidaciones obtenidas con éxito", estilosAlerta);
      }
    } catch (error) {
      console.log("error", error);
      toast.dismiss();
      toast.error(error.response.data.message? error.response.data.message : "Error al obtener Liquidaciones calculadas", estilosAlerta);
    }
  }

  async function eliminarLiquidacionesCalculadas() {
    const data = getValues();
    try {
      const response = await axiosPrivate.get(
        `${api_service}/api/v1/liquidaciones/periodo/${periodoId}/empresa/${empresaId}`,
        Authorization
      );
      if (!response.data.length > 0) {
        toast.dismiss();
        toast.error("Ya no quedan Liquidaciones en este periodo", estilosAlerta);
        setInfoLiquidaciones([]);
      } else {
        setInfoLiquidaciones(response.data);
        setRefreshTable(!refreshTable);
        toast.dismiss();
        toast.success("Liquidaciones eliminadas con éxito", estilosAlerta);
      }
    } catch (error) {
      console.log("error", error);
      toast.dismiss();
      toast.error(error.response.data.message? error.response.data.message : "Error al actualizar la información", estilosAlerta);
    }
  }

  const COLUMNS = [
    {
      Header: "RUT",
      accessor: (d) =>
        UtilsFunctions.rutFormatter(d.rutTrabajador, { dots: true }),
      Filter: ColumnFilter,
    },
    {
      Header: "Apellido paterno",
      accessor: "apellidoPaternoTrabajador",
      Filter: ColumnFilter,
    },
    {
      Header: "Apellido materno",
      accessor: "apellidoMaternoTrabajador",
      Filter: ColumnFilter,
    },
    {
      Header: "Nombre",
      accessor: "nombresTrabajador",
      Filter: ColumnFilter,
    },
    {
      Header: "Centro de Costo",
      accessor: "centroCosto",
      Filter: ColumnFilter,
    },
    {
      Header: "D. Trabajados",
      accessor: "diasTrabajados",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value === null) {
          return 30 + " días";
        }
        if (periodoMes === "2" && value === 28) {
          return 30 + " días";
        }
        if (value === 31) {
          return 30 + " días";
        }
        return value + " días";
      },
    },
    {
      Header: "H. extra",
      accessor: "horasExtras",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value === null) {
          return "0 hrs.";
        } else {
          return value + " hrs.";
        }
      },
    },
    {
      Header: "H. descuento",
      accessor: "horasDescuento",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value === null) {
          return "0 hrs.";
        } else {
          return value + " hrs.";
        }
      },
    },
  ];
  
  return (
    <div
      className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
        activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">Herramientas</div>
      <div className="titulo-Pagina">Eliminar liquidación calculada</div>
      <form className="w-full" onSubmit={handleSubmit(buscarLiquidacionesCalculadas)}>
        
        <div className="pt-3 flex flex-wrap">

        <div className="flex flex-wrap w-full md:min-w-[160px] md:max-w-[245px]">
            <div className="w-4/12 md:w-4/12 md:min-w-[100px] md:max-w-[200px] mr-3 md:mr-0 md:pr-3 mb-3">
              <label
                htmlFor="periodoId"
                className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold"
              >
                Periodo
              </label>
              <select
                {...register("periodoAnyo", {
                  onChange: (e) => {
                    setValue("periodoMes", "");
                    handleYearChange(e.target.value)
                    setInfoLiquidaciones([]);
                  },
                  required: { value: true, message: "Obligatorio" },
                })}
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option hidden value="">
                  Año
                </option>
                {anyosData.map((anyo, i) => (
                  <option value={anyo} key={i}>
                    {anyo}
                  </option>
                ))}
              </select>
              <span
                className={`dark:text-red-400 text-red-600 text-xs ${
                  !errors.periodoAnyo && "opacity-0 invisible"
                }`}
              >
                {errors.periodoAnyo ? errors.periodoAnyo.message : "hidden"}
              </span>
            </div>

            <div className="w-5/12 md:w-6/12 md:min-w-[130px] md:max-w-[200px] mt-6 md:mr-3 mb-3">
              <select
                {...register("periodoMes", {
                  onChange: (e) => {
                    setValue("numeroMes", e.target.selectedIndex);
                    setInfoLiquidaciones([]);
                  },
                  required: { value: true, message: "Obligatorio" },
                })}
                disabled={watch("periodoAnyo") === "" ? true : false}
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option hidden value="">
                  Mes
                </option>
                {mesesData.map((mes, i) => (
                  <option value={mes} key={i}>
                    {mes}
                  </option>
                ))}
              </select>
              <span
                className={`dark:text-red-400 text-red-600 text-xs ${
                  !errors.periodoMes && "opacity-0 invisible"
                }`}
              >
                {errors.periodoMes ? errors.periodoMes.message : "hidden"}
              </span>
            </div>
          </div>

        <div className="w-4/12 md:w-[110px] md:mt-6 md:ml-3 mb-6 md:mb-0">
            <button
              type="submit"
              className="w-full hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-in-out transition-all duration-400"
              >
              Buscar
            </button>
          </div>
        </div>
      </form>

      {infoLiquidaciones.length > 0 && (
        <div className="h-full md:mr-12 rounded-lg">
        <EliminarLiquidacionCalculadaTable
            col={COLUMNS}
            tableData={infoLiquidaciones}
            periodo={periodoId}
            actualizarTabla={() => eliminarLiquidacionesCalculadas()}
          />
        </div>
      )}
    </div>
  );
}
