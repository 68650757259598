import { axiosPrivate } from "../../../../common/axiosPrivate";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStateContext } from "../../../../contexts/ContextProvider";
import {
  getEmpresas,
  getRoles,
  getRolesActivos,
} from "../../../../Redux/Actions";
import UtilsFunctions from "../../../../utils/UtilsFunctions";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

export default function EditarUsuarios({ isVisible, onClose }) {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues:{
      user: "",
      nombre: "",
      rolId: "",
      claveUsuario: "",
      confirmarClaveUsuario: "",
    }
  });


  const [habilitado, setHabilitado] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const inforoles = useSelector((state) => state.rolesActivos);
  const empresas = useSelector((state) => state.empresas);
  const { refreshTable, setRefreshTable, screenSize } = useStateContext();
  const [botonGuardarHabilitado, setBotonGuardarHabilitado] = useState(true)

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRolesActivos());
    dispatch(getEmpresas());
  }, [dispatch]);

  const onSubmit = async (data) => {
    setBotonGuardarHabilitado(false)
    try {
      toast.loading("Creando Usuario", estilosAlerta);
      const payload = {
        user: data.user,
        nombre: data.nombre,
        roleId: data.rolId,
        activo: habilitado,
        empresasUsuario: Array.isArray(data.empresasUsuario)
          ? data.empresasUsuario
          : [data.empresasUsuario],
          password: data.claveUsuario,
      };
      console.log("payload", payload);
      const response = await axiosPrivate.post(
        `${api_service}/api/v1/usuarios/nuevousuario`,
        payload,
        Authorization
      );
      if (response.status === 200) {
        toast.dismiss();
        toast.success("Usuario creado con éxito", estilosAlerta);
        setRefreshTable(!refreshTable);
        dispatch(getRoles());
        reset();
        onClose();
        setHabilitado(true);
      }
      setBotonGuardarHabilitado(true)
    } catch (error) {
      toast.dismiss();
      toast.error(error.response.data.message ? error.response.data.message : "Error al crear usario", estilosAlerta);
      console.log(error);
      setBotonGuardarHabilitado(true)
    }
  };

  const emailValidation = {
    required: {
      value: true,
      message: "Obligatorio",
    },
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: "Email no es válido",
    },
  };

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <div className="primerDivModal">
          <div className="segundoDivModal max-w-lg">
            {/*content*/}
            <div className="tercerDivModal shadow-lg  max-h-[85vh] ">
              {/*header*/}
              <div className="divHeaderModal border-slate-200">
              <h3 className="titulo-Modal">
                  Crear Usuario
                </h3>
              </div>

              <div className="relative p-6 flex-auto overflow-auto dark:bg-hercules-dark-bg-200">

              <div className="div-Inputs-EditarHaber">
                  <label
                    className="block uppercase dark:text-white tracking-wide text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-first-name"
                    >
                      Email
                    </label>
                    <input
                      {...register("user", emailValidation)}
                      className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:border-gray-500 bg-gray-200 border-gray-200"
                      type="text"
                      placeholder="ejemplo@ejemplo.com"
                    />
                  <span
                    className={`text-red-500 dark:text-red-400 text-xs italic ${
                      !errors.user && "opacity-0 invisible"
                    }`}
                  >
                    {errors.user ? errors.user.message : "hidden"}
                  </span>
              </div>

              <div className="div-Inputs-EditarHaber">
                  <label
                    className="block uppercase dark:text-white tracking-wide text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-first-name"
                    >
                      Nombre Completo
                    </label>
                    <input
                      {...register("nombre", {
                        required: {
                          value: true,
                          message: "Obligatorio",
                        },
                        minLength: {
                          value: 3,
                          message: "Al menos 3 caracteres",
                        },
                        maxLength: {
                          value: 50,
                          message: "Máximo 50 caracteres",
                        },
                      })}
                      className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:border-gray-500 bg-gray-200 border-gray-200"
                      type="text"
                    />
                        <span
                        className={`text-red-500 dark:text-red-400 text-xs italic ${
                          !errors.nombre && "opacity-0 invisible"
                          }`}
                        >
                          {errors.nombre ? errors.nombre.message : "hidden"}
                        </span>
                </div>

                <div className="div-Inputs-EditarHaber">
                  <label className="block uppercase dark:text-white tracking-wide text-hercules-light-font-secondary text-xs font-bold mb-2">
                      Seleccionar Rol
                    </label>
                    <select
                      {...register("rolId", {
                        required: { value: true, message: "Obligatorio" },
                      })}
                      className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:border-gray-500 bg-gray-200 border-gray-200"
                      >
                      <option hidden value="">
                        Seleccionar Rol
                      </option>
                      {inforoles.map((rol) => (
                        <option key={rol.id} value={rol.id}>
                          {UtilsFunctions.rolFormatter(rol.nombre)}
                        </option>
                      ))}
                    </select>
                    <span
                    className={`text-red-500 dark:text-red-400 text-xs italic ${
                      !errors.rolId && "opacity-0 invisible"
                    }`}
                  >
                    {errors.rolId ? errors.rolId.message : "hidden"}
                  </span>
                </div>

                <div className="div-Inputs-EditarHaber">
                <label className="block uppercase dark:text-white tracking-wide text-hercules-light-font-secondary text-xs font-bold mb-2">
                      Clave
                    </label>

                    <div className="flex items-center relative">
                      <input
                        {...register("claveUsuario", {
                          required: { value: true, message: "Obligatorio" },
                          minLength: {
                            value: 6,
                            message: "Al menos 6 caracteres",
                          },
                          maxLength: {
                            value: 50,
                            message: "Máximo 50 caracteres",
                          },
                          pattern: {
                            value: /^\S*$/,
                            message: "No puede tener espacios en blanco",
                          },
                        })}
                        className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:border-gray-500 bg-gray-200 border-gray-200"
                        type={showPassword ? "text" : "password"}
                      />
                      <button
                        type="button"
                        className="absolute right-2 cursor-pointer"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <AiFillEyeInvisible className="text-2xl text-gray-500" />
                        ) : (
                          <AiFillEye className="text-2xl text-gray-500"/>
                        )}
                      </button>
                    </div>
                        <span
                        className={`text-red-500 dark:text-red-400 text-xs italic ${
                          !errors.claveUsuario && "opacity-0 invisible"
                          }`}
                        >
                          {errors.claveUsuario ? errors.claveUsuario.message : "hidden"}
                        </span>
                  </div>

                  <div className="div-Inputs-EditarHaber">
                <label className="block uppercase dark:text-white tracking-wide text-hercules-light-font-secondary text-xs font-bold mb-2">
                      Confirmar Clave
                    </label>
                      <input
                        {...register("confirmarClaveUsuario", {
                          validate: (value) =>
                            value === watch("claveUsuario") || "Las contraseñas no coinciden",
                        })}
                        className="appearance-none block w-full text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:border-gray-500 bg-gray-200 border-gray-200"
                        type="password"
                      />
                        <span
                        className={`text-red-500 dark:text-red-400 text-xs italic ${
                          !errors.confirmarClaveUsuario && "opacity-0 invisible"
                          }`}
                        >
                          {errors.confirmarClaveUsuario ? errors.confirmarClaveUsuario.message : "hidden"}
                        </span>
                  </div>

                <div className="relative flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3">
                    <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                      Seleccionar Empresas a las cuales el usuario tendrá
                      acceso:
                    </label>
                    <div className="border-l-2 border-t-2 border-r-2 border-b-2 h-auto h-max-48 overflow-y-auto px-3">
                      {empresas.map((empresa) => (
                        <div key={empresa.id} className="my-1 border-b-2">
                          <input
                            type="checkbox"
                            {...register("empresasUsuario", {
                              validate: (value) =>
                                value.length > 0 ||
                                "Debe seleccionar al menos una empresa",
                            })}
                            value={empresa.id}
                          />
                          <label className="pl-3 dark:text-white text-hercules-light-font-secondary">
                            {empresa.nombreFantasia}
                          </label>
                        </div>
                      ))}
                    </div>
                  <span
                    className={`text-red-500 dark:text-red-400 text-xs italic ${
                      !errors.empresasUsuario && "opacity-0 invisible"
                    }`}
                  >
                    {errors.empresasUsuario ? errors.empresasUsuario.message : "hidden"}
                  </span>
                  </div>
                </div>

                <div className="flex flex-wrap mt-6">
                      <span className="uppercase dark:text-white tracking-wide text-hercules-light-font-secondary text-xs font-bold my-auto mr-10 mb-1 md:mb-0 ">
                        Habilitado
                      </span>
                      <label className="inline-flex relative items-center hover:cursor-pointer">
                        <input
                          {...register("activo")}
                          type="checkbox"
                          className="sr-only peer"
                          checked={habilitado}
                          readOnly
                        />
                        <div
                          onClick={() => {
                            setHabilitado(!habilitado);
                          }}
                          name="activo"
                          className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"
                          ></div>
                      </label>
                </div>
              </div>

              {/*footer*/}
              <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                  type="button"
                  onClick={() => {
                    reset();
                    setHabilitado(true);
                    onClose();
                  }}
                >
                  Cerrar
                </button>
                <button
              disabled={!botonGuardarHabilitado}
              className={` ${!botonGuardarHabilitado? "bg-gray-500" : "bg-hercules-light-primary-button hover:shadow-lg hover:scale-105"}  text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400`}
                  type="submit"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </form>
    </>
  );
}
