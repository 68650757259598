import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRepLegales, hideLoader, limpiarInfoMenuEdicion, showLoader } from "../../Redux/Actions";
import {
  NuevoRepresentante,
  ColumnFilter,
  Button,
  EditarRepresentante,
} from "../../components";
import { useStateContext } from "../../contexts/ContextProvider";
import UtilsFunctions from "../../utils/UtilsFunctions";
import SimpleTable from "../../components/SimpleTable";
import RutColumnFilter from "../../components/RutColumnFilter";
import ColumnFilterOptions from "../../components/ColumnFilterOptions";

export default function RepLegales() {

  const dispatch = useDispatch();

  const [showModalNuevoRepresentante, setShowModalNuevoRepresentante] =
    useState(false);
  const [showModalEditarRepresentante, setShowModalEditarRepresentante] =
    useState(false);
  const [representanteData, setRepresentanteData] = useState(null);
  const { refreshTable, activeMenu } = useStateContext();


  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader());
      await Promise.all([
        dispatch(getRepLegales()),
      ]);
      // dispatch(limpiarRepLegales())
      dispatch(hideLoader());
    };

    fetchData().catch((error) => {
      console.log(error.response.data.message);
      dispatch(hideLoader());
    });
  }, [refreshTable, dispatch]);

  useEffect(() =>{
    return () => {
      dispatch(limpiarInfoMenuEdicion("repLegales"))
    }
  },[])

  const repLegales = useSelector((state) => state.repLegales);

  const COLUMNS = [
    {
      Header: "RUT",
      accessor: "rut",
      Cell: ({ value }) => UtilsFunctions.rutFormatter(value, { dots: true }),
      Filter: RutColumnFilter,
    },
    {
      Header: "Nombre",
      accessor: (d) => d.apellidoPaterno + " " + d.apellidoMaterno  + " " + d.nombres,
      Filter: ColumnFilter,
    },
    {
      Header: "Habilitado",
      accessor: "activo",
      Filter: ColumnFilterOptions,
      Cell: ({ value }) => {
        if (value === true) {
          return "Si";
        } else {
          return "No";
        }
      },
    }
  ];

  const headersCsv = [
    { name: "RUT", key: "rut" },
    { name: "Nombre", key: "nombres" },
    { name: "Apellido Paterno", key: "apellidoPaterno" },
    { name: "Apellido Materno", key: "apellidoMaterno" },
    { name: "Habilitado", key: "activo" },
  ];

  return (
    <div
      className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
        activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">
        Edición
      </div>
      <div className="titulo-Pagina">
        Representantes Legales
      </div>
      <div className="h-full md:mr-12 rounded-lg pt-5">
          <SimpleTable
            col={COLUMNS}
            tableData={repLegales || []}
            primaryButton={{
              type: "editar",
              onClickFunc: (data) => {
                setShowModalEditarRepresentante(true);
                setRepresentanteData(data);
              },
              active: true,
            }}
            exportCsv={() =>
              UtilsFunctions.jsonToCSV3(
                repLegales,
                headersCsv,
                "Representantes Legales"
              )
            }
          />
      </div>
      <div>
        <Button
          bgColor={"hercules-light-primary-button"}
          text={"Nuevo Representante Legal"}
          colorText={"white"}
          onClickFunc={() => setShowModalNuevoRepresentante(true)}
          type={"button"}
        />
        {showModalNuevoRepresentante &&
        <NuevoRepresentante
          isVisible={showModalNuevoRepresentante}
          onClose={() => setShowModalNuevoRepresentante(false)}
        /> 
      }
        {showModalEditarRepresentante &&
        <EditarRepresentante
          isVisible={showModalEditarRepresentante}
          onClose={() => setShowModalEditarRepresentante(false)}
          modalData={representanteData}
        />
      }
      </div>
    </div>
  );
}
