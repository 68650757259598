import React from "react";
import UtilsFunctions from "../../../utils/UtilsFunctions";
import ColumnFilter from "../../ColumnFilter";
import SimpleTable from "../../SimpleTable";

export default function ModalMensajeCreditosCAFFyCargasFamiliares({
  nombrePlantilla,
  erroresRecibidos,
  isVisible,
  onClose,
}) {
  if (!isVisible) {
    return null;
  }

  const COLUMNS = [
    {
      Header: "RUT",
      accessor: (d) => nombrePlantilla === "Créditos CCAF" ? UtilsFunctions.rutFormatter(d.rut, { dots: true }) : nombrePlantilla === "Cargas Familiares" && UtilsFunctions.rutFormatter(d.rut, { dots: true }) ,
      Filter: ColumnFilter,
    },
    {
      Header: "Error",
      accessor: "error",
      Filter: ColumnFilter,
    },
  ];

  const headersCsv = [
    {
      key: "rut",
      name: "RUT",
    },
    {
      key: "error",
      name: "Error",
    },
  ];

  return (
    <>
      <div className="primerDivModal">
        <div className="segundoDivModal max-w-6xl">
          <div className="tercerDivModal shadow-lg  max-h-[85vh] ">
            {/*header*/}
            <div className="divHeaderModal border-slate-200">
              <h3 className="titulo-Modal">
                Error en Ingreso de {nombrePlantilla}
              </h3>
            </div>
            {/*body*/}
            <div className="relative p-6 overflow-auto flex-auto dark:bg-hercules-dark-bg-200">
              <p className="text-lg font-normal leading-normal mb-4 dark:text-white text-hercules-light-font-secondary">
                Los siguientes trabajadores presentan errores al importar{" "}
                {nombrePlantilla}, por favor corrijalos e intente nuevamente.
                <br /> No se importaron {nombrePlantilla} para estos trabajadores:
              </p>

              <div className="w-full md:pr-3 mb-9">
                <SimpleTable
                  col={COLUMNS}
                  tableData={erroresRecibidos}
                  exportCsv={() =>
                    UtilsFunctions.jsonToCSV3(
                      erroresRecibidos,
                      headersCsv,
                      `Trabajadores_error_importar_${nombrePlantilla}`
                    )
                  }
                  tableHeight="h-72"
                />
              </div>
            </div>

            {/*footer*/}
            <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                type="button"
                onClick={() => {
                  onClose();
                }}
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
