import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoginImage from "../assets/man-593333.jpg";
import { useForm } from "react-hook-form";
import UtilsFunctions from "../utils/UtilsFunctions";
import { axiosPrivate } from "../common/axiosPrivate";
import { toast } from "react-hot-toast";

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const rutValidations = {
    required: {value: true, message: "Debes ingresar un rut"},
    pattern: {value: /^(\d{1,2}(?:[\.]?\d{3}){2}-[\dkK])$/, message: "El formato del RUT es incorrecto"},
    validate: {
      rutValido: v => UtilsFunctions.validateRut(v) || "El RUT ingresado no es válido"
    }
  }

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;

  const [correoEnviado, setCorreoEnviado] = useState(false);

  useEffect(() => {
        reset();
  }, [reset]);

    const onSubmit = (data) => {
        toast.loading("Enviando correo para restablecer contraseña...");
        const payload = {
            email: data.email,
        };
        axiosPrivate.post(`${api_service}/api/v1/auth/solucitudresetpassword`, payload, {
            headers: {
              ClientId: UtilsFunctions.rutFormatter(data.rutHolding, {dots: false}).slice(0,8)
            }
        })
            .then(() => {
                toast.dismiss();
                toast.success("Correo enviado con éxito");
                setCorreoEnviado(true);
            })
            .catch((error) => {
                toast.dismiss();
                toast.error("Error al enviar correo");
                console.log(error);
            });
    };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 h-screen w-full">
      <div className="hidden sm:block">
        <img className="w-full h-full object-cover" src={LoginImage} alt="" />
      </div>

      <div className="sm:bg-bluegray-200  flex flex-col justify-center sm:px-3">
        <img
          className="absolute sm:hidden w-full h-full object-cover mix-blend-overlay"
          src={LoginImage}
          alt=""
        />

        {!correoEnviado ? (<form
          onSubmit={handleSubmit(onSubmit)}
          className="relative max-w-[500px] w-full mx-auto bg-white py-4 px-6 rounded-lg"
        >
          <h2 className="text-3xl font-bold text-center py-4">
            ¿Olvidaste tu contraseña?
          </h2>
            <p className=" font-semibold text-justify">
                Ingresa el rut del Holding donde estás registrado y tu email para recibir un correo con instrucciones para restablecer tu contraseña.
            </p>
          <div className="flex flex-col my-3">
            <label>Rut Holding</label>
            <input
                {...register("rutHolding", rutValidations)}
              className={`border p-2`}
              type="text"
              name="rutHolding"
              id="rutHolding"
            />
          {errors.rutHolding && <p className="text-red-600 text-xs">{errors.rutHolding.message}</p>}

          </div>

          <div className="flex flex-col my-3">
            <label>Email</label>
            <input
                {...register("email", {required: {value: true, message: "Debes ingresar un email"}})}
              className={`border p-2`}
            />
            {errors.email && <p className="text-red-600 text-xs">{errors.email.message}</p>}
          </div>

          <button
            className="border relative w-full mt-5 py-2 rounded-md bg-blue-600 hover:bg-blue-700 transition ease-in-out duration-500 text-white font-bold"
            type="submit"
          >
            Enviar correo para restablecer contraseña
          </button>
          <Link to="/">
            <button
              className=" border relative w-full my-5 py-2 rounded-md bg-gray-200 hover:bg-gray-300 transition ease-in-out duration-500 text-black font-bold"
              type="button"
            >
              Volver al inicio
            </button>
          </Link>
        </form>) : (
            <div
            className="relative max-w-[500px] w-full mx-auto bg-white py-4 px-6 rounded-lg"
          >
            <h2 className="text-3xl font-bold text-center py-4">
              Correo enviado con éxito
            </h2>
              <p className=" font-semibold text-justify">
                    Revisa tu correo para restablecer tu contraseña. Si no lo encuentras, revisa tu bandeja de spam.
              </p>
            <Link to="/">
              <button
                className=" border relative w-full my-5 py-2 rounded-md bg-blue-600 hover:bg-blue-700 transition ease-in-out duration-500 text-white font-bold"
                type="button"
              >
                Volver al inicio
              </button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
