import React, { useState, useEffect } from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, listarUsuarios, showLoader } from "../../Redux/Actions";
import {
  EditarUsuarios,
  ColumnFilter,
  ColumnFilterBoolean,
  CrearUsuario,
} from "../../components";
import SimpleTable from "../../components/SimpleTable";
import UtilsFunctions from "../../utils/UtilsFunctions";
import ModificarCentroCosto from "../../components/Parametros/Configuracion/Usuarios/ModificarCentroCosto";

export default function Usuarios2() {
  const dispatch = useDispatch();
  const { refreshTable, activeMenu } = useStateContext();

  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader());
      await Promise.all([
        dispatch(listarUsuarios()),
      ]);
      dispatch(hideLoader());
    };

    fetchData().catch((error) => {
      console.log(error.response.data.message);
      dispatch(hideLoader());
    });
  }, [refreshTable, dispatch]);

  const usuarios = useSelector((state) => state.listaUsuarios);
  const [showModalEditarUsuarios, setShowModalEditarUsuarios] = useState(false);
  const [showModalEditarUsuariosCCosto, setShowModalEditarUsuariosCCosto] = useState(false);
  const [showModalCrearUsuario, setShowModalCrearUsuario] = useState(false);
  const { rowDataUsuarios, setRowDataUsuarios } = useStateContext();

  const COLUMNS = [
    {
      Header: "Usuario",
      accessor: "user",
      Filter: ColumnFilter,
    },
    {
      Header: "Nombre",
      accessor: "nombre",
      Filter: ColumnFilter,
    },
    {
      Header: "Rol",
      accessor: "roles[0].nombre",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        return (
          value
            .slice(5)
            .replaceAll("_", " ")
            .toLowerCase()
            .slice(0, 1)
            .toUpperCase() +
          value.slice(5).replaceAll("_", " ").toLowerCase().slice(1)
        );
      },
    },
    {
      Header: "Activo",
      accessor: "activo",
      Filter: ColumnFilterBoolean,
      Cell: ({ value }) => {
        if (value === true) {
          return "Si";
        } else {
          return "No";
        }
      },
    },
  ];

  const headersCsv = [
    {
      key: "user",
      name: "Usuario",
    },
    {
      key: "nombre",
      name: "Nombre",
    },
    {
      key: "roles",
      name: "Rol",
    },
    {
      key: "activo",
      name: "Activo",
    },
  ];

  return (
    <div
      className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${activeMenu && "md:w-[calc(100vw_-_23rem)]"
        }`}
    >
      <div className="titulo-Menu">Usuarios</div>
      <div className="titulo-Pagina">
        Mantenedor de Usuarios
      </div>
      <div className="h-full md:mr-12 rounded-lg pt-5">
        <SimpleTable
          col={COLUMNS}
          tableData={usuarios}
          primaryButton={{
            type: "editar",
            onClickFunc: (data) => {
              setShowModalEditarUsuarios(true);
              setRowDataUsuarios(data);
            },
            active: true,
          }}
          secondaryButton={{
            type: "modificarCCosto",
            onClickFunc: (data) => {
              setShowModalEditarUsuariosCCosto(true);
              setRowDataUsuarios(data);
            },
            active: true,
          }}
          exportCsv={() =>
            UtilsFunctions.jsonToCSV3(usuarios, headersCsv, "Usuarios")
          }
        />
        <button
          onClick={() => setShowModalCrearUsuario(true)}
          className="mt-3 bg-hercules-light-primary-button text-white dark:text-dark-primary font-medium uppercase text-normal px-4 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        >
          Crear Usuario
        </button>
      </div>
      {showModalEditarUsuarios ?
        <EditarUsuarios
          isVisible={showModalEditarUsuarios}
          onClose={() => setShowModalEditarUsuarios(false)}
          modalData={rowDataUsuarios}
        />
        : null}
      {showModalCrearUsuario ?
        <CrearUsuario
          isVisible={showModalCrearUsuario}
          onClose={() => setShowModalCrearUsuario(false)}
        />
        : null}

      {showModalEditarUsuariosCCosto ?
        <ModificarCentroCosto
          isVisible={showModalEditarUsuariosCCosto}
          onClose={() => setShowModalEditarUsuariosCCosto(false)}
          modalData={rowDataUsuarios}
        />
        : null}

    </div>
  );
}
