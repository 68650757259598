import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useStateContext } from "../../contexts/ContextProvider";
import ActualizarHaberesDescuentosTable from "../../components/Herramientas/ActualizarHaberesDescuentos/ActualizarHaberesDescuentosTable";
import { ColumnFilter } from "../../components";
import UtilsFunctions from "../../utils/UtilsFunctions";
import TablaEditarJornadaContrato from "../../components/Herramientas/ActualizarJornadaContrato/TablaEditarJornadaContrato";
import {
  hideLoader,
  limpiarInfoMenuEdicion,
  listaTrabajadoresParaCambioJornada,
  showLoader,
} from "../../Redux/Actions";

export default function ActualizarJornadaContratos({}) {

  const dispatch = useDispatch();
  const periodoId = localStorage.getItem("periodoId");
  const empresaId = localStorage.getItem("empresaId");

  const { activeMenu, refreshTable, setRefreshTable, screenSize } =
    useStateContext();

  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader());
      await Promise.all([
        dispatch(listaTrabajadoresParaCambioJornada(periodoId, empresaId)),
      ]);
      dispatch(hideLoader());
    };

    fetchData().catch((error) => {
      console.log(error.response.data.message);
      dispatch(hideLoader());
    });
  }, [refreshTable, dispatch]);

  const listaMovimientosTrabPeriodo = useSelector(
    (state) => state.trabajadoresCambioJornada
  );

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("trabajadoresCambioJornada"));
    };
  }, []);

  const COLUMNS = [
    {
      Header: "RUT",
      accessor: (d) => UtilsFunctions.rutFormatter(d.rutTrabajador, { dots: true }),
      Filter: ColumnFilter,
    },
    {
      Header: "Trabajador",
      accessor: (d) =>
        d.apellidoPaterno + " " + d.apellidoMaterno + " " + d.nombres,
      Filter: ColumnFilter,
    },
    {
      Header: "Centro Costo",
      accessor: "centroCostoNombre",
      Filter: ColumnFilter,
    },
    {
      Header: "Cargo",
      accessor: "cargoNombre",
      Filter: ColumnFilter,
    },
    {
      Header: "Sección",
      accessor: "seccionNombre",
      Filter: ColumnFilter,
    },
  ];

  return (
    <>
      <div
        className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
          activeMenu && "md:w-[calc(100vw_-_23rem)]"
        }`}
      >
        <div className="titulo-Menu">Herramientas</div>
        <div className="titulo-Pagina">Actualizar Jornada</div>

        {/* {listaMovimientosTrabPeriodo.length > 0 && ( */}
        <div className="h-full md:mr-12 rounded-lg pt-5">
          <TablaEditarJornadaContrato col={COLUMNS} tableData={listaMovimientosTrabPeriodo} />
        </div>
        {/* )} */}
      </div>
    </>
  );
}
