import React from "react";

export default function GuiaImportacionPlantillas({
  nombrePlantilla,
  body,
  imagen,
  onClose,
}) {
  // function abrirImagen() {}

  return (
    <>
      <div className="overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none justify-center items-center">
        <div className="relative w-auto my-20 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col max-h-[85vh] overflow-y-auto w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t dark:bg-hercules-dark-bg-100 ">
              <h3 className="text-3xl text-hercules-light-font-secondary font-semibold leading-normal dark:text-white">
                Importación de {nombrePlantilla}
              </h3>
            </div>
            <div className="relative p-6 flex-auto overflow-auto dark:bg-hercules-dark-bg-200 dark:text-white text-hercules-light-font-secondary">
              {/* body */}
              {body}
              {/* <a href={"#" + imagen}>
                <img className="pt-2" onClick={abrirImagen()} src={imagen} />
              </a> */}
            </div>

            {/*footer*/}
            <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                type="button"
                onClick={() => {
                  onClose();
                }}
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <article
        id={imagen}
        className="z-50 fixed w-full mt-0 ml-0 flex justify-center items-center scale-0 target:scale-1"
      >
        <img src={imagen} className="w-10/12 h-3/4" />
      </article> */}
      <div className="opacity-25 fixed inset-0 z-30 bg-black"></div>
    </>
  );
}
