import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import ColumnFilter from "../../../ColumnFilter";
import UtilsFunctions from "../../../../utils/UtilsFunctions";
import SimpleTable from "../../../SimpleTable";

export default function ModalVerFiniquito({ isVisible, onClose, modalData }) {
  const {
    register,
    reset,
    formState: { errors },
  } = useForm();


  useEffect(() => {
    if (modalData) {
      reset({
        nombreTrabajador:
          modalData.nombres +
          " " +
          modalData.apellidoPaterno +
          " " +
          modalData.apellidoMaterno,
        rutTrabajador: UtilsFunctions.rutFormatter(modalData.rut, {
          dots: true,
        }),
        nombreEmpresa: modalData.empresa,
        fechaInicio: modalData.fechaIngreso,
        fechaFiniquito: modalData.fechaFiniquito,
        labor: modalData.cargo,
        causal: modalData.causal,
      });
    }
  }, [modalData]);

  const TABLA_CONCEPTOS_FINIQUITO = [
    {
      Header: "Tipo",
      accessor: (d) =>
        d.tipoConcepto[0].toUpperCase() +
        d.tipoConcepto.substring(1).toLowerCase(),
      Filter: ColumnFilter,
    },
    {
      Header: "Descripción",
      accessor: "descripcion",
      Filter: ColumnFilter,
    },
    {
      Header: "Valor",
      accessor: (d) => "$" + UtilsFunctions.integerFormatter(d.valor),
      Filter: ColumnFilter,
    },
  ];


  if (!isVisible) {
    return null;
  }

  return (
    <>
        <div className="primerDivModal">
          <div className="segundoDivModal max-w-4xl">
            {/*content*/}
            <div className="tercerDivModal shadow-lg  max-h-[85vh] ">
              {/*header*/}
              <div className="divHeaderModal border-slate-200">
              <h3 className="titulo-Modal">
                Ver Finiquito
              </h3>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto overflow-auto dark:bg-hercules-dark-bg-200">

              <div className="md:flex md:flex-wrap">
              <div className="w-full md:w-5/12 md:pr-3 md:mb-11 mb-9">
                    <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                    Nombre Trabajador
                  </label>
                  <input
                    {...register("nombreTrabajador")}
                    disabled
                    className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                    type="text"
                  />
                </div>

                <div className="w-full md:w-3/12 md:px-3 md:mb-11 mb-9">
                    <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                    Rut Trabajador
                  </label>
                  <input
                    {...register("rutTrabajador")}
                    disabled
                    className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                    type="text"
                  />
                </div>

                <div className="w-full md:w-4/12 md:pl-3 md:mb-11 mb-9">
                    <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                    Empresa
                  </label>
                  <input
                    {...register("nombreEmpresa")}
                    disabled
                    className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                    type="text"
                  />
                </div>
              </div>

              <h3 className="text-2xl font-normal leading-normal mb-5 md:mb-3 dark:text-white text-hercules-light-font-secondary">
                Datos del Finiquito
              </h3>

              <div className="flex flex-wrap">
                  <div className="w-full md:w-3/12 md:pr-3 md:mb-11 mb-9">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                    htmlFor="grid-last-name"
                  >
                    Fecha inicio
                  </label>
                  <input
                    {...register("fechaInicio")}
                    name="fechaInicio"
                    disabled
                    className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                    id="grid-last-name"
                    type="date"
                  />
                </div>

                <div className="w-full md:w-3/12 md:px-3 md:mb-11 mb-9">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                    htmlFor="grid-last-name"
                  >
                    Fecha Término
                  </label>
                  <input
                    {...register("fechaFiniquito")}
                    name="fechaFiniquito"
                    disabled
                    className="hover:cursor-pointer appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="grid-last-name"
                    type="date"
                  />
                </div>
              </div>

              <div className="flex flex-wrap">
                <div className="w-full md:w-6/12 md:pr-3 md:mb-11 mb-9">
                    <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                    Labor
                  </label>
                  <input
                    {...register("labor")}
                    disabled
                    className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                    type="text"
                  />
                </div>

                <div className="w-full md:w-6/12 md:pl-3 md:mb-11 mb-9">
                    <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                    Causal término de contrato
                  </label>
                  <input
                    {...register("causal")}
                    disabled
                    className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                    type="text"
                  />
                </div>
              </div>

              {modalData.conceptosFiniquito.length ? 
              <div>
              <hr className="mb-3 md:mb-6" />
                <h3 className="text-2xl font-normal leading-normal mb-5 md:mb-3 dark:text-white text-hercules-light-font-secondary">
                Conceptos del finiquito
              </h3>

              {/* Tabla de Conceptos */}
              <div className="h-full rounded-lg md:mt-0 mt-6">
                <SimpleTable
                  col={TABLA_CONCEPTOS_FINIQUITO}
                  tableData={
                    modalData.conceptosFiniquito.length
                      ? modalData.conceptosFiniquito
                      : []
                  }
                  searchBar={false}
                  showFilters={false}
                  hasPagination={false}
                  tableHeight="h-auto"
                />
              </div>
              </div>
              : null}
            </div>

            <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                type="button"
                onClick={() => {
                  onClose();
                  reset();
                }}
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
