import React, { useEffect, useMemo, useState } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useFilters,
  usePagination,
  useRowSelect,
} from "react-table";
import {
  TbArrowsSort,
  TbSortAscending,
  TbSortDescending,
} from "react-icons/tb";
import GlobalFilter from "../../GlobalFilter";
import { BiSearch } from "react-icons/bi";
import {
  MdArrowBackIos,
  MdArrowForwardIos,
  MdLastPage,
  MdFirstPage,
} from "react-icons/md";
import { useStateContext } from "../../../contexts/ContextProvider";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "../../Skeleton";
import UtilsFunctions from "../../../utils/UtilsFunctions";
import { useForm } from "react-hook-form";
import { parse } from "papaparse";
import {
  getJornadas,
  hideLoader,
  limpiarInfoMenuEdicion,
  showLoader,
} from "../../../Redux/Actions";
import { axiosPrivate } from "../../../common/axiosPrivate";
import ModalErrorActualizarJornadaContrato from "./ModalErroresActualizarJornada";

export default function TablaEditarJornadaContrato({ col, tableData }) {
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      nuevaJornada: "",
      horasSemana: "",
      porcentajeRecargo: "",
    },
  });

  // eslint-disable-next-line
  const isLoading = useSelector((state) => state.loader);
  const columns = useMemo(() => col, []);
  const data = useMemo(() => [...tableData], [tableData]);
  const { screenSize, refreshTable, setRefreshTable } = useStateContext();
  const jornadas = useSelector((state) => state.getJornadas);
  const dispatch = useDispatch();

  const [errorRecibido, setErrorRecibido] = useState([]);
  const [showModalError, setShowModalError] = useState(false);

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const validacion = {
    required: { value: true, message: "Obligatorio" },
  };

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;

  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    canPreviousPage,
    canNextPage,
    gotoPage,
    pageCount,
    setPageSize,
    pageOptions,
    state,
    rows,
    selectedFlatRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
          disableGlobalFilter: true,
        },
        ...columns,
      ]);
    }
  );

  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader());
      await Promise.all([dispatch(getJornadas())]);
      dispatch(hideLoader());
    };
    fetchData().catch((error) => {
      console.log(error.response.data.message);
      dispatch(hideLoader());
    });
  }, [refreshTable, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("getJornadas"));
    };
  }, []);

  const { pageIndex, pageSize } = state;

  const { globalFilter } = state;

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      );
    }
  );

  useEffect(() => {}, [selectedFlatRows]);

  if (isLoading) {
    return (
      <Skeleton
        width={"w-full"}
        height={"h-fix"}
        searchBar={true}
        pagination={true}
      />
    );
  }

  async function modificarJornadaContrato(data){
    if (selectedFlatRows.length === 0) {
      toast.dismiss();
      toast.error("Debe seleccionar por lo menos un Contrato", estilosAlerta);
      return null;
    }
    toast.dismiss();
    toast.loading("Editando Jornadas", estilosAlerta);
    try {
      const mapeoContratos = selectedFlatRows.map((e) => {
        return {
          contratoId: e.original.contratoId,
        };
      });
      const payload = {
        contratoAnexoIds: mapeoContratos,
        nuevaJornadaId: data.nuevaJornada,
        fechaNuevoAnexo: data.fechaAnexo
      }

      const info = await axiosPrivate.put(
        `${api_service}/api/v1/contratos/actualizar-jornadas`,
        payload,
        Authorization
      );
      console.log("info recibida", info);
      
      if (info.data.length > 0) {
        setErrorRecibido(info.data);
        setShowModalError(true);
        toast.dismiss();
        toast.error(
          "Hay " + info.data.length + (info.data.length > 1 ? " contratos" : " contrato") + " con error", estilosAlerta
        );
      } else {
        setRefreshTable(!refreshTable);
        toast.dismiss();
        toast.success("Jornadas editadas con éxito", estilosAlerta);
        // actualizarTablaFront(nuevoBody)
        // setNuevoBody([])
      }

    } catch (error) {
      console.log("error", error)
      toast.error(error.response.data.message? error.response.data.message : "Error al actualizar Jornada")
    }

  }

  return (
    <>
      <div className="bg-white dark:bg-hercules-dark-bg-100 border-t  border-l border-r border-gray-300 dark:border-dark-bg-200 h-12 flex rounded-t-md p-3">
        <>
          <span className="ml-auto ">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          </span>
          <span className="mx-3 pt-1 text-xl dark:text-dark-text-100 text-black hover:cursor-pointer">
            <BiSearch />
          </span>
        </>
      </div>
      <div className="h-fix overflow-auto border-x-1  bg-white dark:bg-hercules-dark-bg-200  dark:border-dark-bg-200 border-gray-300 border-t-1">
        <table {...getTableProps()} className="w-full rounded-lg">
          <thead className="text-left sticky top-0 border-b-1 border-b-hercules-light-secondary dark:border-b-hercules-dark-secondary-button bg-hercules-light-table-header dark:bg-hercules-dark-bg-200">
            {headerGroups.map((headerGroup) => {
              const { key, ...restHeaderGroup } =
                headerGroup.getHeaderGroupProps();
              return (
                <React.Fragment key={key}>
                  <tr {...restHeaderGroup} className="h-10 flex-auto">
                    {headerGroup.headers.map((column) => {
                      const { key, ...restColumn } = column.getHeaderProps(
                        column.getSortByToggleProps()
                      );
                      return (
                        <React.Fragment key={key}>
                          <th
                            {...restColumn}
                            className="py-2 px-4 text-hercules-light-font-secondary font-medium dark:text-white"
                          >
                            <div className="flex">
                              <span className="">
                                {column.render("Header")}
                              </span>
                              <span className="ml-1 mt-auto mb-auto">
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <TbSortDescending />
                                  ) : (
                                    <TbSortAscending />
                                  )
                                ) : (
                                  <TbArrowsSort />
                                )}
                              </span>
                            </div>
                          </th>
                        </React.Fragment>
                      );
                    })}
                  </tr>
                  <tr {...headerGroup.getHeaderGroupProps()} className="h-10">
                    {headerGroup.headers.map((column) => {
                      const { key, ...restColumn } = column.getHeaderProps();
                      return (
                        <th key={key} {...restColumn} className="pl-4 pb-4">
                          <div className="w-fit pr-4">
                            {column.canFilter ? column.render("Filter") : null}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                </React.Fragment>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()} className="h-full">
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <React.Fragment key={i}>
                  <tr
                    {...row.getRowProps()}
                    className="group bg-white dark:bg-hercules-dark-bg-200   dark:text-dark-text-100 dark:border-b-hercules-dark-secondary-button border-b border-hercules-light-secondary text-black h-12 dark:hover:bg-white dark:hover:bg-opacity-10 transition duration-100 ease-in-out hover:bg-light-accent hover:bg-opacity-20"
                  >
                    {row.cells.map((cell, i) => {
                      return (
                        <td
                          key={i}
                          {...cell.getCellProps()}
                          className="py-2 px-4 text-base font-normal"
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className=" border-1 dark:border-t-hercules-dark-secondary-button border-t-hercules-light-secondary border-gray-300 dark:border-dark-bg-200 dark:bg-hercules-dark-bg-200   rounded-b-md h-14 flex bg-white">
        <div className="ml-auto py-3 flex">
          <p className="hidden sm:block pt-1 text-black dark:text-white">
            Filas por página
          </p>
          <select
            name=""
            id=""
            className="ml-2 h-8 text-sm bg-white border  rounded-sm"
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block p-4 text-black dark:text-white">
          Página {pageIndex + 1} de {pageOptions.length}
        </div>
        <div className="flex mr-4 pl-4">
          <button
            className="bg-white text-black dark:text-white dark:bg-opacity-0 rounded-full hover:bg-gray-200 hover:text-black my-2 w-8 mr-6"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <MdFirstPage className="pl-1 text-2xl" />
          </button>
          <button
            className="bg-white text-black rounded-full dark:text-white dark:bg-opacity-0  hover:bg-gray-200 hover:text-black my-2 mr-6 w-8"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <MdArrowBackIos className="ml-2" />
          </button>
          <button
            className="bg-white text-black rounded-full dark:text-white dark:bg-opacity-0  hover:bg-gray-200 hover:text-black my-2 mr-6 w-8"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <MdArrowForwardIos className="ml-2" />
          </button>
          <button
            className="bg-white text-black rounded-full dark:text-white dark:bg-opacity-0  hover:bg-gray-200 hover:text-black my-2 w-8"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <MdLastPage className="pl-1 text-2xl" />
          </button>
        </div>
      </div>

      <form
        onSubmit={handleSubmit(modificarJornadaContrato)}
      >
        <div className="md:flex md:flex-wrap md:pt-5">
          <div className="w-full pt-5 md:pt-0 md:pr-3 md:w-3/12 ">
            <label className=" dark:text-white text-hercules-light-font-secondary">
              Jornada
            </label>

            <select
              {...register("nuevaJornada", {
                required: "Obligatorio",
                onChange: (e) => {
                  const horasSemana =
                    jornadas[e.target.selectedIndex - 1].horasSemana;
                  setValue("horasSemana", horasSemana + " horas");
                  const recargo =
                    jornadas[e.target.selectedIndex - 1].tipoHorasExtra.recargo;
                  const porcentajeRecargo = (
                    Math.round((recargo - 1) * 100) + "%"
                  ).replace(".", ",");
                  setValue("porcentajeRecargo", porcentajeRecargo );
                },
              })}
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 pt-3 pb-4 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option value={""} hidden>
                Seleccionar Jornada
              </option>
              {jornadas.filter((jornada) => jornada.activo == true).map((jornada, i) => (
                <option key={jornada.id} value={jornada.id}>
                  {jornada.nombre}
                </option>
              ))}
            </select>
            <span
              className={`text-red-500 text-xs italic ${
                !errors.nuevaJornada && "opacity-0 invisible"
              }`}
            >
              {errors.nuevaJornada ? errors.nuevaJornada.message : "hidden"}
            </span>
          </div>

          {watch("horasSemana") !== "" ? 
          <div className="w-full pt-5 md:pt-0 md:px-3 md:w-2/12 ">
            <label className=" dark:text-white text-hercules-light-font-secondary">
              Horas semanales
            </label>
            <input
              disabled
              {...register("horasSemana")}
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 pt-3 pb-4 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-rut"
              type="text"
            />
          </div>
          : null }

          {watch("porcentajeRecargo") !== "" ? 
          <div className="w-full pt-5 md:pt-0 md:px-3 md:w-2/12 ">
            <label className=" dark:text-white text-hercules-light-font-secondary">
              Recargo hora extra
            </label>
            <input
              disabled
              {...register("porcentajeRecargo")}
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 pt-3 pb-4 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-rut"
              type="text"
            />
          </div>
          : null }

          <div className="w-full md:w-2/12 px-3 mb-6 md:mb-0">
            <label className=" dark:text-white text-hercules-light-font-secondary">
              Fecha de Anexo
            </label>
            <input
              {...register("fechaAnexo", validacion)}
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 pt-3 pb-4 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              type="date"
            />
            <span
              className={`text-red-500 text-xs italic ${
                !errors.fechaAnexo && "opacity-0 invisible"
              }`}
            >
              {errors.fechaAnexo ? errors.fechaAnexo.message : "hidden"}
            </span>
          </div>

          <div className="w-full pt-1 md:pt-6 md:w-2/12 md:px-3 mb-6 md:mb-0">
            <button
              type="submit"
              className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
            >
              Guardar
            </button>
          </div>
        </div>
      </form>

      {showModalError && (
        <ModalErrorActualizarJornadaContrato
          erroresRecibidos={errorRecibido}
          isVisible={showModalError}
          onClose={() => setShowModalError(false)}
        />
      )}

    </>
  );
}
