import React, { useEffect, useState } from "react";
import ColumnFilter from "../../../ColumnFilter";
import HaberesDescuentosTable from "../HaberesDescuentosTable";
import { axiosPrivate } from "../../../../common/axiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import UtilsFunctions from "../../../../utils/UtilsFunctions";
import { toast } from "react-hot-toast";
import { getDescuentos, limpiarInfoMenuEdicion } from "../../../../Redux/Actions";
import SpinnerRevisarHabDesc from "../../../SpinnerEspecificoRevHabDesc";
import { useStateContext } from "../../../../contexts/ContextProvider";

export default function DescuentosAdicionalesTabla({
  modalDataContrato,
}) {

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const periodoId = localStorage.getItem("periodoId");
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true)
  const [descuentoAdicionalSeleccionado, setDescuentoAdicionalSeleccionado] =
    useState(null);
    const { screenSize } = useStateContext();

    const isMobile = screenSize <= 768;
    const estilosAlerta = {
      style: {
        marginTop: isMobile ? "80px" : "",
      },
    };
  
  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const formatter = new Intl.NumberFormat("es-CL", {
    styles: "currency",
    currency: "CLP",
    minimumFractionDigits: 0,
  });

  const {
    register,
    reset,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();

  const [descuentosAdicionalesTabla, setDescuentosAdicionalesTabla] = useState(
    []
  );

  async function obtenerDescuentosAdicionales(modalDataContrato) {
    try {
      const data = {
        contratoId: modalDataContrato.contratoId,
        anexoContratoId: modalDataContrato.anexoContratoId
          ? modalDataContrato.anexoContratoId
          : null,
        periodoId: periodoId,
        trabajadorId: modalDataContrato.trabajadorId,
      };
      const infoDescuentosAdicionales = await axiosPrivate.post(
        `${api_service}/api/v1/contratos/obtenerDescuentosAdicionales`,
        data,
        Authorization
      );
      if (infoDescuentosAdicionales.status === 200) {
        setDescuentosAdicionalesTabla(infoDescuentosAdicionales.data);
      }
    } catch (error) {
      console.log("Error", error);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await Promise.all([
        dispatch(getDescuentos()),
        obtenerDescuentosAdicionales(modalDataContrato),
        ]);
      setIsLoading(false);
    };
    fetchData().catch((error) => {
      console.log(error.response.data.message);
      setIsLoading(false);
    });
  }, [dispatch, modalDataContrato]);

  useEffect(() =>{
    return () => {
      dispatch(limpiarInfoMenuEdicion("getDescuentos"))
    }
  },[])

  const listaDescuentos = useSelector((state) => state.getDescuentos);

  async function onSubmit(data) {
    toast.loading("Agregando Descuento", estilosAlerta);
    try {
      const payload = {
        contratoId: modalDataContrato.contratoId,
        anexoContratoId:
          modalDataContrato.version !== 1
            ? modalDataContrato.anexoContratoId
            : null,
        periodoId: periodoId,
        descuentoId: data.descuentoId,
        monto: descuentoAdicionalSeleccionado.esValor
          ? data.montoPesos?.replace(/\./g, "").replace("$ ", "") || 0
          : 0,
        porcentaje: descuentoAdicionalSeleccionado.esPorcentaje
          ? data.montoPorcentaje?.replace(" %", "").replace(",", ".") || 0
          : 0,
      };
      // console.log("Payload a enviar", payload);
      const info = await axiosPrivate.post(
        `${api_service}/api/v1/contratos/agregarDescuentoAdicional`,
        payload,
        Authorization
      );
      const nuevaInfo = info.data;
      const nuevoArray = [...descuentosAdicionalesTabla, nuevaInfo];
      setDescuentosAdicionalesTabla(nuevoArray);
      reset();
      toast.dismiss();
      toast.success("Descuento agregado con éxito", estilosAlerta);
    } catch (error) {
      console.log("Error", error);
      toast.dismiss();
      toast.error(error.response.data.message? error.response.data.message : "Error al agregar Descuento", estilosAlerta);
    }
  }

  async function eliminarDescuento(descuentoPeriodoId, index) {
    toast.loading("Eliminando Descuento", estilosAlerta);
    try {
      const info = await axiosPrivate.delete(
        `${api_service}/api/v1/contratos/eliminarDescuentoAdicional/${descuentoPeriodoId}`,
        Authorization
      );
      console.log("info actualizada con exito", info);
      descuentosAdicionalesTabla.splice(index, 1);
      const arrayEditado = [...descuentosAdicionalesTabla];
      setDescuentosAdicionalesTabla(arrayEditado);
      toast.dismiss();
      toast.success("Descuento eliminado con éxito", estilosAlerta);
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error(error.response.data.message? error.response.data.message :"Error al eliminar Descuento", estilosAlerta);
    }
  }

  const COLUMNAS_DESCUENTOS_ADICIONALES = [
    {
      Header: "Nombre",
      accessor: "nombreDescuento",
      Filter: ColumnFilter,
    },
    {
      Header: "Monto",
      accessor: (d) => d.esValor && d.monto === -1? "Variable" : d.esValor ? "$" + UtilsFunctions.integerFormatter(d.monto) : d.porcentaje * 100 + "%" ,
      Filter: ColumnFilter,
    },

  ];

  return (
    <>
      <div>
        <h3 className="mb-2 font-semibold dark:text-white text-hercules-light-font-secondary">
          Descuentos Adicionales
        </h3>

        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full md:mb-0">
          <div className="flex flex-wrap">

              <div className="w-6/12 md:w-5/12 mb-3 md:mb-0 md:pr-3">
                <select
                  {...register("descuentoId", {
                    required: {
                      value: true,
                      message: "Obligatorio",
                    },
                    validate: (value) => {
                      if (value === "Seleccionar Descuento") {
                        return "Obligatorio";
                      }
                      return true;
                    },
                    onChange: (e) => {
                      console.log("selecciono", e.target.selectedIndex);
                      setDescuentoAdicionalSeleccionado(
                        (prev) => listaDescuentos[e.target.selectedIndex - 1]
                      );
                      console.log(
                        "descuentoAdicionalSeleccionado",
                        listaDescuentos[e.target.selectedIndex - 1]
                      );
                      setValue("monto", "$0");

                      setValue("montoPesos", "$ 0");
                      setValue("montoPorcentaje", "0 %");
                    },
                  })}
                  name="descuentoId"
                  className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                >
                  <option hidden value="Seleccionar Descuento">
                    Seleccionar
                  </option>
                  {listaDescuentos?.map((descuento, i) => (
                    <option key={i} value={descuento.id}>
                      {descuento.nombre}
                    </option>
                  ))}
                </select>
                {errors.descuentoId && (
            <span className="text-red-500 dark:text-red-400 text-xs italic">
            {errors.descuentoId.message}
                  </span>
                )}
              </div>

              <div className="w-6/12 md:w-3/12 mb-3 md:mb-0 pl-3 md:pl-0 md:px-3">

                    {!descuentoAdicionalSeleccionado && (
                      <input
                        disabled
                        className="appearance-none block w-full bg-gray-400 border-gray-400 text-gray-800 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                      />
                    )}

                    {descuentoAdicionalSeleccionado?.esValor && (
                      <>
                        <input
                          placeholder="Monto"
                          {...register("montoPesos", {
                            validate: (value) => {
                              if (
                                value === "" ||
                                value === "$" ||
                                value === "$ 0"
                              ) {
                                return "Mayor a $0";
                              }
                              return true;
                            },
                            onChange: () => {
                              if (descuentoAdicionalSeleccionado !== null) {
                                if (descuentoAdicionalSeleccionado.esValor) {
                                  setValue(
                                    "montoPesos",
                                    "$ " +
                                      formatter.format(
                                        getValues("montoPesos")?.replace(
                                          /\D/g,
                                          ""
                                        )
                                      )
                                  );
                                } else {
                                  setValue(
                                    "montoPesos",
                                    getValues("montoPesos")
                                  );
                                }
                              }
                            },
                          })}
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                        />
                        {/* <span
                          className={`text-red-500 text-xs italic ${!errors.montoPesos && "opacity-0 invisible"}`}
                        >
                          {errors.montoPesos ? errors.montoPesos.message : "hidden"}
                        </span> */}
                      </>
                    )}

                    {descuentoAdicionalSeleccionado?.esPorcentaje && (
                      <>
                        <input
                          {...register("montoPorcentaje", {
                            validate: (value) => {
                              if (
                                value === "" ||
                                value === "%" ||
                                parseInt(value) < 0 ||
                                parseInt(value) > 100
                              ) {
                                return "Entre 0 y 100%";
                              }
                              return true;
                            },
                            onChange: () => {
                              setValue(
                                "montoPorcentaje",
                                getValues("montoPorcentaje")?.replace(
                                  /[^\d.,]/g,
                                  ""
                                ) + " %"
                              );
                            },
                          })}
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                        />
                        {/* <span
                className={`text-red-500 text-xs italic ${
                  !errors.montoPorcentaje && "opacity-0 invisible"
                }`}
              >
                {errors.montoPorcentaje
                  ? errors.montoPorcentaje.message
                  : "hidden"}
              </span> */}
                      </>
                    )}
              </div>

              <div className="w-6/12 md:w-3/12 mb-3 md:mb-0 md:pl-3">
                <button
                  className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-sm px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
                  type="submit"
                >
                  Agregar
                </button>
              </div>
            </div>
          </div>
        </form>

        {errors.monto && (
          <span className="text-red-500 text-xs italic">
            {errors.monto.message}
          </span>
        )}
          {isLoading ? (
            <div className="pt-6">
              <SpinnerRevisarHabDesc />
            </div>
          ) : (
        <HaberesDescuentosTable
          col={COLUMNAS_DESCUENTOS_ADICIONALES}
          tableData={descuentosAdicionalesTabla}
          botonEliminarDescuento={true}
          borrarHaberDescuento={(descuentoPeriodoId, index) => {
            eliminarDescuento(descuentoPeriodoId, index);
          }}
        />
        )}
      </div>
    </>
  );
}
