import React, { useEffect, useState } from "react";
import { useStateContext } from "../../../contexts/ContextProvider";
import {
  Card,
  Grid,
  Metric,
  Text,
  Flex,
} from "@tremor/react";
import { useDispatch, useSelector } from "react-redux";
import {
  dashboardVacaciones,
} from "../../../Redux/Actions"

const InicioVacaciones = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(dashboardVacaciones());
  }, [dispatch]);

  const infoDashboard = useSelector((state) => state.infoDashboardVacaciones);

  const { activeMenu } = useStateContext();

  return (
    <div
      className={`dark:shadow-none md:mt-12 mt-28 md:mx-14 px-6 pb-6 ${
        activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="text-4xl mb-6 pt-1 font-semibold text-hercules-light-font-secondary dark:text-dark-primary">
        Bienvenido a Hércules - Vacaciones
      </div>
      <div className="h-full">
        <Grid numItemsLg={3} className="gap-6 mb-6">

        <Card>
        <div className="mb-3">
              <Text>Días de vacaciones pendientes</Text>
              <Flex
                justifyContent="start"
                alignItems="baseline"
                className="truncate space-x-3 mt-1"
              >
                <Metric>{infoDashboard.diasVacacionesPendientes}</Metric>
              </Flex>
            </div>
          </Card>

          <Card className="flex items-center">
            <div className="mb-3">
              <Text>Número de solicitudes pendientes</Text>
              <Flex
                justifyContent="start"
                alignItems="baseline"
                className="truncate space-x-3 mt-1"
              >
                <Metric>{infoDashboard.numeroSolicitudesPendientes}</Metric>
              </Flex>
            </div>
          </Card>

          <Card className="flex items-center">
            <div className="mb-3">
              <Text>Trabajadores en este Centro de Costo</Text>
              <Flex
                justifyContent="start"
                alignItems="baseline"
                className="truncate space-x-3 mt-1"
              >
                <Metric>{infoDashboard.numeroTrabajadores}</Metric>
              </Flex>
            </div>
          </Card>

        </Grid>

      </div>
    </div>
  );
};

export default InicioVacaciones;
