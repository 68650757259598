import { axiosPrivate } from "../../../common/axiosPrivate";
import React, { useEffect, useRef, useState } from "react";
import { useStateContext } from "../../../contexts/ContextProvider";
import UtilsFunctions from "../../../utils/UtilsFunctions";
import { toast } from "react-hot-toast";
import ModalSpinner from "../../ModalSpinner";
import { useForm } from "react-hook-form";

export default function ModalCanjearHorasExtra({
  isVisible,
  onClose,
  modalData,
}) {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const periodoId = localStorage.getItem("periodoId");
  const empresaId = localStorage.getItem("empresaId");

  const navbarData = UtilsFunctions.getEmpresaPeriodoInfo();

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };
  const { refreshTable, setRefreshTable, screenSize } = useStateContext();
  const [botonGuardarHabilitado, setBotonGuardarHabilitado] = useState(true);

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const inputRef = useRef(null);

  const handleChangeDias = (e) => {
    let value = e.target.value;

    value = value.replace(/[^0-9]/g, "");

    const formattedValue = value ? `${value} días` : "";

    setValue("diasVacaciones", formattedValue);

    setTimeout(() => {
      const input = inputRef.current;
      if (input) {
        input.setSelectionRange(value.length, value.length);
      }
    }, 0);
  };

  useEffect(() => {
    const input = inputRef.current;
    const value = watch("diasVacaciones") || "";
    if (input) {
      const numericValue = value.replace(/[^0-9]/g, "");
      input.setSelectionRange(numericValue.length, numericValue.length);
    }
  }, [watch("diasVacaciones")]);

  useEffect(() => {
    reset({
      //   id: modalDataHs.horasExtraId,
      rut: UtilsFunctions.rutFormatter(modalData.rut, { dots: true }),
      nombreTrabajador:
        modalData.nombres +
        " " +
        modalData.apellidoPaterno +
        " " +
        modalData.apellidoMaterno,
      periodo: navbarData.periodo,
      periodoId: periodoId,
      horasExtra: modalData.horasExtra,
      horasExtraInput: modalData.horasExtra + " horas",
    });
  }, [modalData, refreshTable]);

  const onSubmit = async (data) => {
    setBotonGuardarHabilitado(false);
    try {
      toast.loading("Canjeando Horas Extra", estilosAlerta);
      const payload = {
        trabajadorId: modalData.trabajadorId,
        periodoId: parseInt(periodoId),
        diasACanjear: parseInt(data.diasVacaciones.slice(0, -5)),
        empresaId: parseInt(empresaId),
      };
      await axiosPrivate.post(
        `${api_service}/api/v1/movimientos-trabajador/canjear-horas-extras`,
        payload,
        Authorization
      );
      toast.dismiss();
      toast.success("Horas Extra canjeadas con éxito", estilosAlerta);
      setRefreshTable(!refreshTable);
      onClose();
      setBotonGuardarHabilitado(true);
    } catch (error) {
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al canjear Horas Extra",
        estilosAlerta
      );
      console.log(error);
      setBotonGuardarHabilitado(true);
    }
  };

  const loadingModal = modalData;

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="primerDivModal">
          <div className="segundoDivModal max-w-2xl">
            {/*content*/}
            <div className="tercerDivModal shadow-lg  max-h-[85vh] ">
              {/*header*/}
              <div className="divHeaderModal border-slate-200">
                <h3 className="titulo-Modal">Canjear Horas Extra</h3>
              </div>
              {/*body*/}

              <div
                className={`relative p-6 ${
                  !loadingModal ? "overflow-hidden" : "overflow-auto"
                } flex-auto dark:bg-hercules-dark-bg-200`}
              >
                {!loadingModal ? (
                  <div className="h-fix">
                    <ModalSpinner />
                  </div>
                ) : (
                  <>
                    {/* RUT y Nombre */}
                    <div className="md:flex md:flex-wrap">
                      <div className="w-full md:w-4/12 md:pr-3 md:mb-11 mb-9">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          RUT Trabajador
                        </label>
                        <input
                          {...register("rut")}
                          disabled
                          name="rut"
                          id="rut"
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                        />
                      </div>

                      <div className="w-full md:w-8/12 md:pl-3 md:mb-11 mb-9">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Nombre Trabajador
                        </label>
                        <input
                          {...register("nombreTrabajador")}
                          disabled
                          name="nombre"
                          id="nombre"
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                        />
                      </div>
                    </div>

                    {/* Horas Extra */}
                    <div className="flex">
                      <div className="w-full md:w-4/12 md:pr-3 md:mb-11 mb-9">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Periodo
                        </label>
                        <input
                          {...register("periodo")}
                          disabled
                          name="periodo"
                          id="periodo"
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                        />
                      </div>

                      <div className="w-full md:w-4/12 md:px-3 md:mb-11 mb-9">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Horas Extra
                        </label>
                        <input
                          {...register("horasExtraInput")}
                          disabled
                          name="horasExtraInput"
                          id="horasExtraInput"
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                        />
                      </div>

                      <div className="w-full md:w-4/12 md:pl-3 md:mb-11 mb-9">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Días Vacaciones
                        </label>
                        <input
                          {...register("diasVacaciones", {
                            required: { value: true, message: "Obligatorio" },
                            onChange: (e) => handleChangeDias(e),
                          })}
                          name="diasVacaciones"
                          id="diasVacaciones"
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                        />
                        <span
                          className={`text-red-500 dark:text-red-400 text-xs italic ${
                            !errors.diasVacaciones && "opacity-0 invisible"
                          }`}
                        >
                          {errors.diasVacaciones
                            ? errors.diasVacaciones.message
                            : "hidden"}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>

              {/*footer*/}
              <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                  type="button"
                  onClick={() => {
                    onClose();
                    reset({});
                  }}
                >
                  Cerrar
                </button>
                <button
                  disabled={!botonGuardarHabilitado}
                  className={` ${
                    !botonGuardarHabilitado
                      ? "bg-gray-500"
                      : "bg-hercules-light-primary-button hover:shadow-lg hover:scale-105"
                  }  text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400`}
                  type="submit"
                >
                  Canjear
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </form>
    </>
  );
}
