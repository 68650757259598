import React from "react";
import toast from "react-hot-toast";
import { axiosPrivate } from "../../../common/axiosPrivate";
import { useStateContext } from "../../../contexts/ContextProvider";

export default function ModalAdvertenciaGratificacionLegal({
  onCloseAlert,
  onCloseAndReset,
  confirmarCambiosPayload,
  tipoContratoAnexo,
}) {
  const { refreshTable, setRefreshTable, screenSize } = useStateContext();

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  async function guardarCambiosSinGratificacion() {
    try {
      if (tipoContratoAnexo === "Contrato") {
        await axiosPrivate.post(
          `${api_service}/api/v1/trabajadores/nuevocontrato`,
          confirmarCambiosPayload,
          Authorization
        );
        toast.dismiss();
        toast.success("Contrato creado con éxito", estilosAlerta);
      } else if (tipoContratoAnexo === "Anexo") {
        await axiosPrivate.post(
          `${api_service}/api/v1/trabajadores/nuevoanexocontrato`,
          confirmarCambiosPayload,
          Authorization
        );
        toast.dismiss();
        toast.success("Anexo creado con éxito", estilosAlerta);
      }
      setRefreshTable(!refreshTable);
      onCloseAndReset();
    } catch (error) {
      toast.dismiss();
      if (
        error.response.data.message !==
        "gratificacionId: Debe seleccionar un tipo de gratificación o sin gratificación"
      ) {
        toast.error(
          error.response.data.message
            ? error.response.data.message
            : "Error al crear Contrato",
          estilosAlerta
        );
      }
      console.log(error);
    }
  }

  return (
    <>
      <div
        className="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center"
        id="modal-id"
      >
        <div className="relative w-auto my-36 mx-auto max-w-md">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col max-h-[85vh] overflow-y-auto w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t dark:bg-hercules-dark-bg-100 ">
              <h3 className="text-3xl text-hercules-light-font-secondary font-semibold leading-normal dark:text-white">
                Advertencia
              </h3>
            </div>
            <div className="relative p-6 flex-auto overflow-auto dark:bg-hercules-dark-bg-200">
              <p className="mb-4 text-slate-500 text-lg leading-relaxed dark:text-white text-hercules-light-font-secondary">
                En este contrato no se ha incluido Gratificación Legal.
              </p>
              <p className="mt-4 text-slate-500 text-lg leading-relaxed dark:text-white text-hercules-light-font-secondary">
                ¿Desea guardar los cambios?
              </p>
            </div>
            <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                onClick={() => {
                  onCloseAlert();
                }}
                type="button"
                // onclick="toggleModal('modal-id')"
              >
                Cerrar
              </button>
              <button
                onClick={() => {
                  guardarCambiosSinGratificacion();
                }}
                className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-sm px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
                type="button"
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className=" opacity-25 fixed inset-0 z-40 bg-black"
        id="modal-id-backdrop"
      ></div>
    </>
  );
}
