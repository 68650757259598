import React, { useEffect, useState } from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import {
  getCentroCostos,
  getCentrosCostosByEmpresaId,
  listaPeriodosHastaActual,
} from "../../Redux/Actions";
import UtilsFunctions from "../../utils/UtilsFunctions";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { axiosPrivate } from "../../common/axiosPrivate";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import { ColumnFilter } from "../../components";
import SimpleTable from "../../components/SimpleTable";

export default function NuevoLibroRemuneracionesMensual() {
  const {
    register,
    getValues,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      centroCostoId: -1,
      periodoAnyo: "",
    },
  });

  const { activeMenu, screenSize } = useStateContext();
  const anyosData = UtilsFunctions.obtenerAnyosHastaActual();
  const [mesesData, setMesesData] = useState([]);
  const [infoPeriodos, setInfoPeriodos] = useState([]);
  const [infoRemuneraciones, setInfoRemuneraciones] = useState([]);
  const infoCCosto = useSelector((state) => state.centrosCostoEmpresa);

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const empresaId = localStorage.getItem("empresaId");
  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const dispatch = useDispatch();

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const campoObligatorio = {
    required: { value: true, message: "Obligatorio" },
  };

  useEffect(() => {
    try {
      getPeriodosCargados();
      dispatch(listaPeriodosHastaActual());
      dispatch(getCentroCostos());
      dispatch(getCentrosCostosByEmpresaId(empresaId));
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  async function getPeriodosCargados() {
    const infoMeses = await axiosPrivate(
      `${api_service}/api/v1/periodos/cargados`,
      Authorization
    );
    setInfoPeriodos(infoMeses.data);
  }

  const handleYearChange = (e) => {
    const year = parseInt(e);
    const yearData = infoPeriodos.find((item) => item.anyo === year);
    if (yearData) {
      const filteredMonths = yearData.periodos.filter(
        (mes) => mes.datosPrevisionalesCargados === true
      );
      const transformedMonths = filteredMonths.map((month) =>
        UtilsFunctions.integerToMonth(month.mes)
      );
      setMesesData(transformedMonths);
    } else {
      setMesesData([]);
    }
  };

  async function submitData() {
    const data = getValues();

    const info = await axiosPrivate.get(
      `${api_service}/api/v1/periodos/mes/${data.numeroMes}/anyo/${data.periodoAnyo}`,
      Authorization
    );
    setValue("periodoId", info.data.id);

    const response = await axiosPrivate(
      `${api_service}/api/v1/trabajadores/liquidaciones/libro-remuneraciones/mensual/periodo/${info.data.id}/empresa/${empresaId}/centroCosto/${data.centroCostoId}`,
      Authorization
    );

    console.log("response", response);
    if (!response.data.trabajadores.length > 0) {
      setInfoRemuneraciones([]);
      toast.dismiss();
      toast.error(
        "Sin información en este Periodo y Centro de costo",
        estilosAlerta
      );
    } else {
      setInfoRemuneraciones(response.data.trabajadores);
      toast.dismiss();
      toast.success("Información cargada con éxito", estilosAlerta);
    }
  }

  const COLUMNS = [
    // {
    //   Header: "#",
    //   accessor: "numeroFila",
    //   Filter: ColumnFilter,
    // },
    {
      Header: "RUT",
      accessor: (d) => UtilsFunctions.rutFormatter(d.rut, { dots: true }),
      Filter: ColumnFilter,
    },
    {
      Header: "Nombre",
      accessor: (d) =>
        d.nombres + " " + d.apellidoPaterno + " " + d.apellidoMaterno,
      Filter: ColumnFilter,
    },
    {
      Header: "D.T.",
      accessor: "diasTrabajados",
      Filter: ColumnFilter,
    },
    {
      Header: "S. Base",
      accessor: (d) => "$" + UtilsFunctions.integerFormatter(d.sueldoBase),
      Filter: ColumnFilter,
    },
    {
      Header: "Cant. H. Extra",
      accessor: "cantidadHorasExtras",
      Filter: ColumnFilter,
    },
    {
      Header: "Total H. Extra",
      accessor: (d) => "$" + UtilsFunctions.integerFormatter(d.horasExtras),
      Filter: ColumnFilter,
    },
    {
      Header: "Cant. H. Desc.",
      accessor: "cantidadHorasDescuento",
      Filter: ColumnFilter,
    },
    {
      Header: "Total H. Desc.",
      accessor: (d) => "$" + UtilsFunctions.integerFormatter(d.horasDescuento),
      Filter: ColumnFilter,
    },
    {
      Header: "Gratif.",
      accessor: (d) => "$" + UtilsFunctions.integerFormatter(d.gratificacion),
      Filter: ColumnFilter,
    },
    {
      Header: "O. Imp.",
      accessor: (d) =>
        d.otrosImponibles < 0
          ? "-$" + UtilsFunctions.integerFormatter(d.otrosImponibles * -1)
          : "$" + UtilsFunctions.integerFormatter(d.otrosImponibles),
      Filter: ColumnFilter,
    },
    {
      Header: "R. Imp",
      accessor: (d) =>
        d.rentaImponible < 0
          ? "-$" + UtilsFunctions.integerFormatter(d.rentaImponible * -1)
          : "$" + UtilsFunctions.integerFormatter(d.rentaImponible),
      Filter: ColumnFilter,
    },
    {
      Header: "Cargas",
      accessor: "cargasFamiliares",
      Filter: ColumnFilter,
    },
    {
      Header: "V. Cargas",
      accessor: (d) => "$" + UtilsFunctions.integerFormatter(d.valorCargas),
      Filter: ColumnFilter,
    },
    {
      Header: "Mov.",
      accessor: "movilizacion",
      Filter: ColumnFilter,
    },
    {
      Header: "Colac.",
      accessor: (d) => "$" + UtilsFunctions.integerFormatter(d.colacion),
      Filter: ColumnFilter,
    },
    {
      Header: "O. No Imp.",
      accessor: (d) =>
        d.otrosNoImponibles < 0
          ? "-$" + UtilsFunctions.integerFormatter(d.otrosNoImponibles * -1)
          : "$" + UtilsFunctions.integerFormatter(d.otrosNoImponibles),
      Filter: ColumnFilter,
    },
    {
      Header: "T. hab.",
      accessor: (d) =>
        d.totalHaberes < 0
          ? "-$" + UtilsFunctions.integerFormatter(d.totalHaberes * -1)
          : "$" + UtilsFunctions.integerFormatter(d.totalHaberes),
      Filter: ColumnFilter,
    },
    {
      Header: "C. Prev.",
      accessor: (d) =>
        d.cotizacionPrevisional < 0
          ? "-$" + UtilsFunctions.integerFormatter(d.cotizacionPrevisional * -1)
          : "$" + UtilsFunctions.integerFormatter(d.cotizacionPrevisional),
      Filter: ColumnFilter,
    },
    {
      Header: "C. Salud.",
      accessor: (d) =>
        d.cotizacionSalud < 0
          ? "-$" + UtilsFunctions.integerFormatter(d.cotizacionSalud * -1)
          : "$" + UtilsFunctions.integerFormatter(d.cotizacionSalud),
      Filter: ColumnFilter,
    },
    {
      Header: "I. Único.",
      accessor: (d) =>
        d.impuestoUnicoSegundaCategoria < 0
          ? "-$" +
            UtilsFunctions.integerFormatter(
              d.impuestoUnicoSegundaCategoria * -1
            )
          : "$" +
            UtilsFunctions.integerFormatter(d.impuestoUnicoSegundaCategoria),
      Filter: ColumnFilter,
    },
    {
      Header: "O. Desc.",
      accessor: (d) =>
        d.otrosDescuentos < 0
          ? "-$" + UtilsFunctions.integerFormatter(d.otrosDescuentos * -1)
          : "$" + UtilsFunctions.integerFormatter(d.otrosDescuentos),
      Filter: ColumnFilter,
    },
    {
      Header: "T. Desc.",
      accessor: (d) =>
        d.totalDescuentos < 0
          ? "-$" + UtilsFunctions.integerFormatter(d.totalDescuentos * -1)
          : "$" + UtilsFunctions.integerFormatter(d.totalDescuentos),
      Filter: ColumnFilter,
    },
    {
      Header: "Antic.",
      accessor: (d) =>
        d.anticipos < 0
          ? "-$" + UtilsFunctions.integerFormatter(d.anticipos * -1)
          : "$" + UtilsFunctions.integerFormatter(d.anticipos),
      Filter: ColumnFilter,
    },
    {
      Header: "Desc. Cuota.",
      accessor: (d) =>
        "$" + UtilsFunctions.integerFormatter(d.descuentosCuotas),
      Filter: ColumnFilter,
    },
    {
      Header: "S. Negativo.",
      accessor: (d) => "$" + UtilsFunctions.integerFormatter(d.saldoNegativo),
      Filter: ColumnFilter,
    },
    {
      Header: "Líquido",
      accessor: (d) =>
        d.liquidoAPagar < 0
          ? "-$" + UtilsFunctions.integerFormatter(d.liquidoAPagar * -1)
          : "$" + UtilsFunctions.integerFormatter(d.liquidoAPagar),
      Filter: ColumnFilter,
    },
    {
      Header: "Jornada",
      accessor: "jornada",
      Filter: ColumnFilter,
    },
    {
      Header: "Cargo",
      accessor: "cargo",
      Filter: ColumnFilter,
    },
    {
      Header: "CeCo",
      accessor: "centroCosto",
      Filter: ColumnFilter,
    },
  ];

  const headersCsv = [
    { name: "RUT", key: "rut" },
    { name: "Apellido paterno", key: "apellidoPaterno" },
    { name: "Apellido materno", key: "apellidoMaterno" },
    { name: "Nombre", key: "nombres" },
    { name: "Dias Trabajados", key: "diasTrabajados" },
    { name: "Sueldo Base", key: "sueldoBase" },
    { name: "Cantidad Horas Extra", key: "cantidadHorasExtras" },
    { name: "Total Horas Extra", key: "horasExtras" },
    { name: "Cantidad Horas Descuento", key: "cantidadHorasDescuento" },
    { name: "Total Horas Descuento", key: "horasDescuento" },
    { name: "Gratificacion", key: "gratificacion" },
    { name: "Otros Imponibles", key: "otrosImponibles" },
    { name: "Renta Imponible", key: "rentaImponible" },
    { name: "Cargas Familiares", key: "cargasFamiliares" },
    { name: "Valor Cargas", key: "valorCargas" },
    { name: "Movilizacion", key: "movilizacion" },
    { name: "Colacion", key: "colacion" },
    { name: "Otros No Imponibles", key: "otrosNoImponibles" },
    { name: "Total Haberes", key: "totalHaberes" },
    { name: "Cotizacion Previsional", key: "cotizacionPrevisional" },
    { name: "Cotizacion de Salud", key: "cotizacionSalud" },
    {
      name: "Impuesto Unico de Segunda Categoria",
      key: "impuestoUnicoSegundaCategoria",
    },
    { name: "Otros descuentos", key: "otrosDescuentos" },
    { name: "Total descuentos", key: "totalDescuentos" },
    { name: "Anticipos", key: "anticipos" },
    { name: "Descuentos en Cuotas", key: "descuentosCuotas" },
    { name: "Saldo Negativo", key: "saldoNegativo" },
    { name: "Liquido a Pagar", key: "liquidoAPagar" },
    { name: "Jornada", key: "jornada" },
    { name: "Cargo", key: "cargo" },
    { name: "Centro de Costo", key: "centroCosto" },
  ];

  return (
    <div
      className={`dark:shadow-none md:mt-12 md:ml-20 md:pr-12 mt-28 mx-7 pb-12 rounded-xl ${
        activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">Herramientas</div>
      <div className="titulo-Pagina">Libro de Remuneraciones</div>

      <div
        className={`${
          infoRemuneraciones.length > 0 ? "" : "md:divide-y-[3px]"
        } `}
      >
        <form
          onSubmit={handleSubmit(submitData)}
          className="pt-3 flex flex-wrap"
        >
          <div className="flex flex-wrap w-full md:w-fit">
            <div className="w-4/12 md:w-4/12 md:min-w-[100px] md:max-w-[200px] pr-3 mb-3">
              <label
                htmlFor="periodoId"
                className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold"
              >
                Periodo
              </label>
              <select
                {...register("periodoAnyo", {
                  onChange: (e) => {
                    setValue("periodoMes", "");
                    handleYearChange(e.target.value);
                    setInfoRemuneraciones([]);
                  },
                  ...campoObligatorio,
                })}
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option hidden value="">
                  Año
                </option>
                {anyosData.map((anyo, i) => (
                  <option value={anyo} key={i}>
                    {anyo}
                  </option>
                ))}
              </select>
              <span
                className={`dark:text-red-400 text-red-600 text-xs ${
                  !errors.periodoAnyo && "opacity-0 invisible"
                }`}
              >
                {errors.periodoAnyo ? errors.periodoAnyo.message : "hidden"}
              </span>
            </div>

            <div className="w-5/12 md:w-6/12 md:min-w-[130px] md:max-w-[200px] mt-6 md:mr-3 mb-3">
              <select
                {...register("periodoMes", {
                  onChange: (e) => {
                    setValue("numeroMes", e.target.selectedIndex);
                    setInfoRemuneraciones([]);
                  },
                  ...campoObligatorio,
                })}
                disabled={watch("periodoAnyo") === "" ? true : false}
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option hidden value="">
                  Mes
                </option>
                {mesesData.map((mes, i) => (
                  <option value={mes} key={i}>
                    {mes}
                  </option>
                ))}
              </select>
              <span
                className={`dark:text-red-400 text-red-600 text-xs ${
                  !errors.periodoMes && "opacity-0 invisible"
                }`}
              >
                {errors.periodoMes ? errors.periodoMes.message : "hidden"}
              </span>
            </div>
          </div>

          <div className="w-full md:w-72 md:px-3 mb-6 md:mb-0">
            <label className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
              Centro de Costo
            </label>
            <select
              {...register("centroCostoId", {
                onChange: (e) => {
                  const centroCosto = infoCCosto[e.target.selectedIndex - 1];
                  setValue("centroCosto", centroCosto);
                  setInfoRemuneraciones([]);
                },
              })}
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option hidden value="">
                Seleccionar
              </option>
              <option value={"-1"}>Todos</option>
              {infoCCosto.map((ccosto, i) => (
                <option value={ccosto.id} key={i}>
                  {ccosto.nombre}
                </option>
              ))}
            </select>
          </div>

          <div className="w-full md:w-48 md:px-3 md:mt-3 mb-3 md:mb-0">
            <button
              type="submit"
              className="mt-3 hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
            >
              Buscar
            </button>
          </div>
        </form>

        {infoRemuneraciones.length > 0 && (
          <div className=" relative h-full rounded-lg">
            <SimpleTable
              col={COLUMNS}
              tableData={infoRemuneraciones}
              exportCsv={() =>
                UtilsFunctions.jsonToCSV(
                  infoRemuneraciones,
                  headersCsv,
                  "Remuneraciones Mensual"
                )
              }
            />
          </div>
        )}

        <div className="pt-7 mb-3">
          <Link to="/herramientas/libro-remuneraciones">
            <button className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400">
              Ver Libro anual
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
