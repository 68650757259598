import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBancos, getCentroCostos, getEmpresas, hideLoader, limpiarInfoMenuEdicion, showLoader } from "../../Redux/Actions";
import { useStateContext } from "../../contexts/ContextProvider";
import { ColumnFilter } from "../../components";
import UtilsFunctions from "../../utils/UtilsFunctions";
import SimpleTable from "../../components/SimpleTable";
import MantenedorNuevoCentroCosto from "../../components/Edicion/MantenedorCentrosDeCosto/MantenedorNuevoCentroCosto";
import MantenedorEditarCentroCosto from "../../components/Edicion/MantenedorCentrosDeCosto/MantenedorEditarCentroCosto";
import ColumnFilterOptions from "../../components/ColumnFilterOptions";

export default function MantenedorCentrosDeCosto() {
  const { rowDataCentroCosto, setRowDataCentroCosto } = useStateContext();
  const [showModalNuevoCentroCosto, setShowModalNuevoCentroCosto] =
    useState(false);
  const [showModalEditarCentroCosto, setshowModalEditarCentroCosto] =
    useState(false);
    const isLoading = useSelector((state) => state.loader);

    const centrosCosto = useSelector((state) => state.getCentroCostos);
    const listaEmpresas = useSelector((state) => state.empresas);
    const listaBancos = useSelector((state) => state.getBancos);
  
  const { refreshTable, activeMenu } = useStateContext();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader());
      await Promise.all([
        dispatch(getCentroCostos()),
        dispatch(getEmpresas()),
        dispatch(getBancos()),
        ]);
      dispatch(hideLoader());
    };
    fetchData().catch((error) => {
      console.log(error.response.data.message);
      dispatch(hideLoader());
    });
  }, [refreshTable, dispatch]);

  useEffect(() =>{
    return () => {
      dispatch(limpiarInfoMenuEdicion("getCentroCostos"))
      dispatch(limpiarInfoMenuEdicion("empresas"))
      dispatch(limpiarInfoMenuEdicion("getBancos"))
    }
  },[])

  const COLUMNS = [
    {
      Header: "Nombre",
      accessor: "nombre",
      Filter: ColumnFilter,
    },
    {
      Header: "Descripcion",
      accessor: "descripcion",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value === null) {
          return "Sin descripción";
        } else {
          return value;
        }
      },
    },
    {
      Header: "Código Previred",
      accessor: (d) => d.codigoPrevired.length > 0 ? d.codigoPrevired : "N/A",
      Filter: ColumnFilter,
    },
    {
      Header: "Empresa",
      accessor: "empresa.nombreFantasia",
      Filter: ColumnFilter,
    },
    {
      Header: "Activo",
      accessor: "activo",
      Filter: ColumnFilterOptions,
      Cell: ({ value }) => {
        if (value === true) {
          return "Si";
        } else {
          return "No";
        }
      },
    },
  ];
  
  const headersCsv = [
    {
      key: "nombre",
      name: "Nombre",
    },
    {
      key: "descripcion",
      name: "Descripcion",
    },
    {
      key: "codigoPrevired",
      name: "Codigo Previred",
    },
    {
      key: "empresa.nombreFantasia",
      name: "Empresa",
    },
    {
      key: "activo",
      name: "Habilitado",
    },
  ];

  return (
    <div
    className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
      activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">
        Edición
      </div>
      <div className="titulo-Pagina">
        Centros de costo
      </div>
      <div className="h-full md:mr-12 rounded-lg pt-5">
          <SimpleTable
            col={COLUMNS}
            tableData={centrosCosto || []}
            primaryButton={{
              type: "editar",
              onClickFunc: (data) => {
                setshowModalEditarCentroCosto(true);
                setRowDataCentroCosto(data);
              },
              active: true,
            }}
            exportCsv={() =>
              UtilsFunctions.jsonToCSV3(centrosCosto, headersCsv, "Centros de costo")
            }  
          />

        <button
          onClick={() => setShowModalNuevoCentroCosto(true)}
          disabled={isLoading}
          className={`mt-3 ${isLoading? "bg-gray-500" : "bg-hercules-light-primary-button hover:shadow-lg"} text-white dark:text-dark-primary font-medium uppercase text-normal px-4 py-3 rounded shadow  outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}  
      >
          Nuevo Centro de Costo
        </button>
      </div>
      {showModalNuevoCentroCosto &&
      <MantenedorNuevoCentroCosto
        isVisible={showModalNuevoCentroCosto}
        onClose={() => setShowModalNuevoCentroCosto(false)}
        listaEmpresas={listaEmpresas}
        listaBancos={listaBancos}
      />
    }
    {showModalEditarCentroCosto &&
      <MantenedorEditarCentroCosto
        isVisible={showModalEditarCentroCosto}
        onClose={() => setshowModalEditarCentroCosto(false)}
        modalData={rowDataCentroCosto}
        listaEmpresas={listaEmpresas}
        listaBancos={listaBancos}
      />
    }
    </div>
  );
}
