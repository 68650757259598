import React, { useEffect } from "react";
import UtilsFunctions from "../../../../utils/UtilsFunctions";
import ColumnFilter from "../../../ColumnFilter";
import SimpleTable from "../../../SimpleTable";
import { axiosPrivate } from "../../../../common/axiosPrivate";
import toast from "react-hot-toast";
import { useStateContext } from "../../../../contexts/ContextProvider";

export default function ModalVerDescuentoEnCuotas({
  modalData,
  isVisible,
  onClose,
  infoTabla,
}) {

  const { screenSize } = useStateContext();

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;

  async function generarInformeDescCuotas(){
    toast.loading("Generando informe", estilosAlerta)
    try {

      axiosPrivate({
        url: `${api_service}/api/v1/descuentos/programado/${modalData.id}/informe/pdf`,
        method: "GET",
        // data: data,
        maxBodyLength: Infinity,
        headers: { Authorization: `Bearer ${tokenUser}`, ClientId: `${token}` },
        responseType: "blob", // importante
        onDownloadProgress: (progressEvent) => {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Informe descuento en cuotas -${modalData.trabajadorRut}.pdf`
          );
          document.body.appendChild(link);
          link.click();
          toast.dismiss();
          toast.success("Informe generado con éxito", estilosAlerta);
        })
        .catch((error) => {
          console.log(error);
          toast.dismiss();
          toast.error("Error al generar informe", estilosAlerta);
        });

      toast.dismiss()
      toast.success("Informe generado con éxito", estilosAlerta)  
    } catch (error) {
      toast.dismiss()
      console.log(error)
      toast.error(error.response.data.message ? error.response.data.message : "Error al generar Informe", estilosAlerta)
    }
  }

  const COLUMNAS = [
    {
      Header: "Cuota",
      accessor: "numeroCuota",
      Filter: ColumnFilter,
    },
    {
      Header: "Periodo",
      accessor: (data) =>
        UtilsFunctions.periodoToText(data.mes, data.anio),
      Filter: ColumnFilter,
    },
    {
      Header: "Monto",
      accessor: (d) => "$" + UtilsFunctions.integerFormatter(d.monto),
      Filter: ColumnFilter,
    },
    {
      Header: "Estado",
      accessor: (d) =>
        d.cancelado === true
          ? "Cancelada"
          : d.prorrogada === true
          ? "Prorrogada"
          : d.pagado === true
          ? "Pagada"
          : d.prorrogada === false && d.pagado === false && "Pendiente",
    },
  ];

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-screen my-6 mx-auto max-w-6xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col max-h-[85vh] overflow-y-auto w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t dark:bg-hercules-dark-bg-100 ">
              <h3 className="text-3xl text-hercules-light-font-secondary font-semibold leading-normal dark:text-white">
                Ver descuento en cuotas
              </h3>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto overflow-auto dark:bg-hercules-dark-bg-200">
              <form className="w-full">
                {/* Primera fila modal */}
                <div className="flex flex-wrap -mx-3 mb-3">
                  <div className="w-full md:w-3/12 px-3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-first-name"
                    >
                      RUT
                    </label>
                    <input
                      disabled
                      value={UtilsFunctions.rutFormatter(modalData.trabajadorRut, {dots: true})}
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="rut"
                      type="text"
                    />
                  </div>
                  <div className="w-full md:w-3/12 px-3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-first-name"
                    >
                      Nombres
                    </label>
                    <input
                      disabled
                      value={modalData.trabajadorNombre}
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                    />
                  </div>
                  <div className="w-full md:w-3/12 px-3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-first-name"
                    >
                      Apellido Paterno
                    </label>
                    <input
                      disabled
                      value={modalData.trabajadorApellidoPaterno}
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                    />
                  </div>
                  <div className="w-full md:w-3/12 px-3 mb-6 md:mb-0">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-first-name"
                    >
                      Apellido Materno
                    </label>
                    <input
                      disabled
                      value={modalData.trabajadorApellidoMaterno}
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                    />
                  </div>
                </div>
                <br />

                <div className="flex">
                  <div className="w-full md:w-6/12 px-3 mb-6 md:mb-0">
                    <h6 className="text-3xl font-semibold pb-8 dark:text-white text-hercules-light-font-secondary">
                      Datos del descuento
                    </h6>

                    <div className="flex pb-2">
                      <div className="w-full md:w-4/12 px-3 mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide mt-2 dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-first-name"
                        >
                          Cuota Inicial
                        </label>
                      </div>
                      <div className="w-full md:w-4/12 px-3 md:mb-0">
                        <input
                          disabled
                          value={`${modalData.periodoMes}${"-"}${
                            modalData.periodoAnio
                          }`}
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                        />
                      </div>
                    </div>

                    <div className="flex pb-2">
                      <div className="w-full md:w-4/12 px-3 mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide mt-2 dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-first-name"
                        >
                          Monto Cuota
                        </label>
                      </div>
                      <div className="w-full md:w-4/12 px-3 md:mb-0">
                        <input
                          disabled
                          value={`${"$"} ${UtilsFunctions.integerFormatter(
                            modalData.monto
                          )}`}
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                        />
                      </div>
                    </div>

                    <div className="flex pb-2">
                      <div className="w-full md:w-4/12 px-3 mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide mt-2 dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-first-name"
                        >
                          Cantidad Cuotas
                        </label>
                      </div>
                      <div className="w-full md:w-4/12 px-3 md:mb-0">
                        <input
                          disabled
                          value={modalData.numeroCuotas}
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="flex pb-2">
                      <div className="w-full md:w-4/12 px-3 mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide mt-2 dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-first-name"
                        >
                          Monto Total
                        </label>
                      </div>
                      <div className="w-full md:w-4/12 px-3 md:mb-0">
                        <input
                          disabled
                          value={`${"$"} ${UtilsFunctions.integerFormatter(
                            modalData.monto * modalData.numeroCuotas
                          )}`}
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                        />
                      </div>
                    </div>

                    <div className="flex pb-2">
                      <div className="w-full md:w-4/12 px-3 mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide mt-2 dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-first-name"
                        >
                          Nombre Descuento
                        </label>
                      </div>
                      <div className="w-full md:w-7/12 px-3 md:mb-0">
                        <input
                          disabled
                          value={modalData.nombreDescuentoProgramado}
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                        />
                      </div>
                    </div>

                    <div className="flex pb-2">
                      <div className="w-full md:w-4/12 px-3 mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide mt-2 dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-first-name"
                        >
                          Observación
                        </label>
                      </div>
                      <div className="w-full md:w-7/12 px-3 md:mb-0">
                        <textarea
                          rows="3"
                          disabled
                          value={modalData.observacion}
                          className=" resize-none appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="w-full md:w-6/12 px-3 mb-6 md:mb-0">
                    <SimpleTable
                      col={COLUMNAS}
                      tableData={infoTabla}
                      searchBar={false}
                      showFilters={false}
                      hasPagination={false}
                      tableHeight="h-96"
                    />
                  </div>

                  <div></div>
                </div>
              </form>
            </div>

            <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                type="button"
                onClick={() => {
                  onClose();
                }}
              >
                Cerrar
              </button>
              <button
                  className="bg-hercules-light-primary-button text-white hover:scale-105 font-bold uppercase text-base px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  onClick={() => generarInformeDescCuotas()}
                >
                  Generar Informe
                </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
