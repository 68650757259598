import { axiosPrivate } from "../../../common/axiosPrivate";
import React, { useState } from "react";
import { useStateContext } from "../../../contexts/ContextProvider";
import UtilsFunctions from "../../../utils/UtilsFunctions";
import ColumnFilter from "../../ColumnFilter";
import HaberesDescuentosTable from "./HaberesDescuentosTable";
import { toast } from "react-hot-toast";
import HaberesVariablesTabla from "./TablasHaberesDescuentos.jsx/HaberesVariablesTabla";
import HaberesAdicionalesTabla from "./TablasHaberesDescuentos.jsx/HaberesAdicionalesTabla";
import DescuentosAdicionalesTabla from "./TablasHaberesDescuentos.jsx/DescuentosAdicionalesTabla";
import { useNavigate } from "react-router-dom";
import ModalSpinner from "../../ModalSpinner";
import SimpleTable from "../../SimpleTable";

export default function RevisarHabDesc({
  isVisible,
  onClose,
  modalData,
  modalDataContrato,
}) {
  // const history = useHistory();
  const navigate = useNavigate();
  const periodoLogin = UtilsFunctions.getEmpresaPeriodoInfo().periodo;

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const periodoId = localStorage.getItem("periodoId");

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const {
    setShowModalCalculoLiquidacion,
    setRowDataLiquidaciones,
    screenSize,
  } = useStateContext();

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const [editarMontoHaberVariable, setEditarMontoHaberVariable] = useState({
    estado: false,
    monto: "",
    contratoHaberId: "",
    anexoHaberId: "",
    indice: "",
  });

  const [descuentosProgramadosTabla, setDescuentosProgramadosTabla] = useState(
    []
  );

  const COLUMNAS_HABERES_FIJOS = [
    {
      Header: "Nombre",
      accessor: "haber.nombre",
      Filter: ColumnFilter,
    },
    {
      Header: "Monto",
      accessor: (d) => "$" + UtilsFunctions.integerFormatter(d.valor),
      Filter: ColumnFilter,
    },
  ];

  const COLUMNAS_DESCUENTOS_OBLIGATORIOS = [
    {
      Header: "Nombre",
      accessor: "descuentoNombre",
      Filter: ColumnFilter,
    },
    {
      Header: "Monto",
      accessor: (d) => d.valor.toFixed(2) + "%",
      Filter: ColumnFilter,
    },
  ];

  const COLUMNAS_DESCUENTOS_PROGRAMADOS = [
    {
      Header: "Cuotas",
      accessor: "numeroCuotas",
      Filter: ColumnFilter,
    },
    {
      Header: "Periodo Inicial",
      accessor: "periodoInicialMes",
      Filter: ColumnFilter,
      Cell: ({ value, row }) => {
        return value + "-" + row.original.periodoInicialAnyo;
      },
    },
    {
      Header: "Monto Cuota",
      accessor: (d) => "$" + UtilsFunctions.integerFormatter(d.monto),
      Filter: ColumnFilter,
    },
    {
      Header: "Observación",
      accessor: "observacion",
      Filter: ColumnFilter,
    },
    // {
    //   Header: "Cuota Actual",
    //   accessor: "valor",
    //   Filter: ColumnFilter,
    // },
    {
      Header: "Estado",
      accessor: "prorrogada",
      Filter: ColumnFilter,
      Cell: ({ value, row }) => {
        // console.log("row original aca", row.original)
        if (value) {
          return "Prorrogada";
        }
        if (!value && row.original.activo && !row.original.cancelado) {
          return "Activa";
        }
        if (!value && !row.original.activo && row.original.cancelado) {
          return "Pagada";
        } else {
          return "N/A";
        }
      },
    },
  ];

  async function prorrogarDescuentoId(descuentoId) {
    toast.loading("Prorrogando descuento", estilosAlerta);
    try {
      const data = {
        descuentoProgramadoDetalleId: descuentoId,
      };
      const response = await axiosPrivate.post(
        `${api_service}/api/v1/descuentos/prorrogar-descuento-programado-detalle`,
        data,
        Authorization
      );
      if (response.status === 200) {
        const data = {
          trabajadorId: modalData.trabajadorId,
          periodoId: periodoId,
        };
        const info = await axiosPrivate.post(
          `${api_service}/api/v1/descuentos/programados/detalle-trabajador`,
          data,
          Authorization
        );
        if (info.status === 200) {
          setDescuentosProgramadosTabla(info.data);
          toast.dismiss();
          toast.success(response.data, estilosAlerta);
        }
      }
    } catch (error) {
      console.log("error", error);
      toast.dismiss();
      toast.error(error.response.data.message? error.response.data.message : "Error al prorrogar Descuento", estilosAlerta);
    }
  }

  async function cancelarProrrogaDescuentoId(descuentoId) {
    toast.loading("Cancelando prorroga", estilosAlerta);
    try {
      const response = await axiosPrivate.delete(
        `${api_service}/api/v1/descuentos/eliminar-descuento-programado-detalle`,
        {
          data: {
            descuentoProgramadoDetalleId: descuentoId,
          },
          headers: {
            Authorization: `Bearer ${tokenUser}`,
            ClientId: `${token}`,
          },
        }
      );
      if (response.status === 200) {
        const data = {
          trabajadorId: modalData.trabajadorId,
          periodoId: periodoId,
        };
        const info = await axiosPrivate.post(
          `${api_service}/api/v1/descuentos/programados/detalle-trabajador`,
          data,
          Authorization
        );
        if (info.status === 200) {
          setDescuentosProgramadosTabla(info.data);
          toast.dismiss();
          toast.success(response.data, estilosAlerta);
        }
      }
    } catch (error) {
      console.log("error", error);
      toast.dismiss();
      toast.error(error.response.data.message ? error.response.data.message : "Error al prorrogar Descuento", estilosAlerta);
    }
  }

  async function HandleLiquidarTrabajador() {
    toast.loading("Cargando", estilosAlerta);
    const data = {
      trabajadorId: modalDataContrato.trabajadorId,
      centroCostoId: modalDataContrato.centroCostoId,
      periodoId: periodoId,
    };

    try {
      const response = await axiosPrivate.post(
        `${api_service}/api/v1/trabajadores/calcularLiquidacion`,
        data,
        Authorization
      );
      setShowModalCalculoLiquidacion(true);
      setRowDataLiquidaciones(modalDataContrato);
      navigate("/movimientos/calculo-liquidaciones");
    } catch (error) {
      // console.log("Error", error);
      if (
        error.response.data.message ===
        "Ya existe una liquidacion calculada para el periodo actual"
      ) {
        toast.dismiss();
        toast.error(
          "Este trabajador ya tiene una liquidación calculada para el periodo de " +
            periodoLogin, estilosAlerta
        );
      } else {
        toast.dismiss();
        toast.error(error.response.data.message ? error.response.data.message : "Error al calcular esta liquidación", estilosAlerta);
      }
    }
  }

  const loadingModal = modalData && modalDataContrato && modalData.haberFijo ? true : false

  if (!isVisible) {
    return null;
  }


  return (
    <>
        <div className="primerDivModal">
          <div className="segundoDivModal max-w-6xl">
          {/*content*/}
          <div className="tercerDivModal shadow-lg  max-h-[85vh] ">
            {/*header*/}
            <div className="divHeaderModal border-slate-200">
            <h3 className="titulo-Modal">
                Haberes/Descuentos
              </h3>
            </div>
            {/*body*/}
            <div
              className={`relative p-6 ${
                !loadingModal ? "overflow-hidden" : "overflow-auto"
              } flex-auto dark:bg-hercules-dark-bg-200`}
            >
                {!loadingModal ? (
                  <div className="h-fix">
                    <ModalSpinner />
                  </div>
                ) : (
                  <>                
                    <div className="md:flex md:flex-wrap">
                      <div className="w-full md:w-3/12 md:pr-3 md:mb-11 mb-9">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-first-name"
                        >
                          RUT
                        </label>
                        <input
                          disabled
                          value={UtilsFunctions.rutFormatter(modalDataContrato.rut, {dots: true})}
                          className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                          type="text"
                        />
                      </div>

                      <div className="w-full md:w-3/12 md:px-3 md:mb-11 mb-9">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-first-name"
                        >
                          Nombres
                        </label>
                        <input
                          disabled
                          value={modalDataContrato.nombres}
                          className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                          type="text"
                        />
                      </div>

                      <div className="w-full md:w-3/12 md:px-3 md:mb-11 mb-9">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-first-name"
                        >
                          Apellido Paterno
                        </label>
                        <input
                          disabled
                          value={modalDataContrato.apellidoPaterno}
                          className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                          type="text"
                        />
                      </div>

                      <div className="w-full md:w-3/12 md:pl-3 md:mb-11 mb-9">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-first-name"
                        >
                          Apellido Materno
                        </label>
                        <input
                          disabled
                          value={modalDataContrato.apellidoMaterno}
                          className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                          type="text"
                        />
                      </div>
                    </div>

                    <div className="md:flex md:flex-wrap">
                      <div className="w-full md:w-3/12 md:pr-3 md:mb-11 mb-9">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Número de contrato
                        </label>
                        {modalDataContrato ? (
                          <input
                            disabled
                            value={modalDataContrato.version}
                            className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                            type="text"
                          />
                        ) : null}
                      </div>

                      <div className="w-full md:w-3/12 md:px-3 md:mb-11 mb-9">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Inicio
                        </label>
                        {modalDataContrato ? (
                          <input
                            disabled
                            value={
                              modalDataContrato.fechaIngreso
                                ? modalDataContrato.fechaIngreso
                                : modalDataContrato.fechaAnexo
                            }
                            className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                            type="text"
                          />
                        ) : null}
                      </div>

                      <div className="w-full md:w-3/12 md:px-3 md:mb-11 mb-9">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Termino
                        </label>
                        {modalDataContrato ? (
                          <input
                            disabled
                            value={
                              modalDataContrato.fechaTerminoContrato
                                ? modalDataContrato.fechaTerminoContrato
                                : modalDataContrato.fechaTerminoAnexo
                                ? modalDataContrato.fechaTerminoAnexo
                                : "N/A"
                            }
                            className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                            type="text"
                          />
                        ) : null}
                      </div>

                      <div className="w-full md:w-3/12 md:px-3 md:mb-11 mb-9">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Plazo
                        </label>
                        {modalDataContrato ? (
                          <input
                            disabled
                            value={modalDataContrato.nombrePlazoContrato}
                            className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                            type="text"
                          />
                        ) : null}
                      </div>
                    </div>

                  {/* Aca empiezan las tablas */}
                  <div className="h-full rounded-lg">
                    {modalData ? (
                      <>
                        {/* Primeras dos tablas */}
                        <div className="md:flex">
                          <div className="h-full w-full md:w-1/2 rounded-lg md:pr-3 mb-9">
                            <h3 className="mb-2 font-semibold dark:text-white text-hercules-light-font-secondary">
                              Haberes Fijos
                            </h3>
                            <SimpleTable
                              col={COLUMNAS_HABERES_FIJOS}
                              tableData={modalData.haberFijo}
                              searchBar={false}
                              showFilters={false}
                              hasPagination={false}
                              tableHeight="h-52"
                            />
                          </div>
                          <div className="h-full w-full md:w-1/2 rounded-lg md:pl-3 mb-9">
                            <HaberesVariablesTabla
                              modalDataContrato={modalDataContrato}
                            />
                          </div>
                        </div>

                        {/* Segundas dos tablas */}
                        <div className="md:flex">
                          <div className="h-full w-full md:w-1/2 rounded-lg md:pr-3 mb-9">
                            <HaberesAdicionalesTabla
                              modalDataContrato={modalDataContrato}
                            />
                          </div>

                          <div className="h-full w-full md:w-1/2 rounded-lg md:pl-3 mb-9">
                            <DescuentosAdicionalesTabla
                              modalDataContrato={modalDataContrato}
                            />
                          </div>
                        </div>

                          <div className="h-full w-full rounded-lg mb-9">
                            <h3 className="mb-2 font-semibold dark:text-white text-hercules-light-font-secondary">
                              Descuentos Obligatorios
                            </h3>
                            <HaberesDescuentosTable
                              col={COLUMNAS_DESCUENTOS_OBLIGATORIOS}
                              tableData={modalData.descuentosObligatorios}
                              botonEditar={false}
                            />
                          </div>

                        {modalData.descuentosProgramados.length > 0 ? (
                            <div className="h-full w-full rounded-lg mb-4 md:mb-5">
                              <h3 className="mb-2 font-semibold dark:text-white text-hercules-light-font-secondary">
                                Descuentos Programados
                              </h3>
                              <HaberesDescuentosTable
                                col={COLUMNAS_DESCUENTOS_PROGRAMADOS}
                                tableData={
                                  descuentosProgramadosTabla.length
                                    ? descuentosProgramadosTabla
                                    : modalData.descuentosProgramados
                                }
                                botonProrrogar={true}
                                prorrogarDescuento={(descuentoId) => {
                                  prorrogarDescuentoId(descuentoId);
                                }}
                                cancelarProrrogaDescuento={(descuentoId) => {
                                  cancelarProrrogaDescuentoId(descuentoId);
                                }}
                              />
                            </div>
                        ) : (
                          <div className="mb-3">
                            <h3 className="mb-2 font-semibold dark:text-white text-hercules-light-font-secondary">
                              Descuentos Programados: El trabajador no tiene
                              descuentos programados en este periodo
                            </h3>
                          </div>
                        )}
                      </>
                    ) : null}
                  </div>
                </>
              )}
            </div>
            {/*footer*/}
            <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                type="button"
                onClick={() => {
                  onClose();
                  setEditarMontoHaberVariable({
                    ...editarMontoHaberVariable,
                    estado: false,
                  });
                }}
              >
                Cerrar
              </button>
              <button
                onClick={() => HandleLiquidarTrabajador()}
                className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
              >
                Liquidar ahora
              </button>

              {/* </Link> */}
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
