import React from "react";
import UtilsFunctions from "../../../../utils/UtilsFunctions";
import { ColumnFilter } from "../../../../components";
import SimpleTable from "../../../../components/SimpleTable";
import { useStateContext } from "../../../../contexts/ContextProvider";
import toast from "react-hot-toast";
import { axiosPrivate } from "../../../../common/axiosPrivate";

export default function ModalVerSolicitudVacaciones({
  modalData,
  fechaIngresoLegal,
  sucursal,
  cerrarModal,
}) {
  // Sort registro vacaciones modal data
  if (modalData.registrosVacaciones) {
    modalData.registrosVacaciones.sort((a, b) => {
      return a.periodoVacacion.anyoInicio - b.periodoVacacion.anyoInicio;
    });
  }

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");

  const { screenSize } = useStateContext();

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };
  const COLUMNAS_VACACIONES_SOLICITADAS = [
    {
      Header: "Usuario",
      accessor: "usuarioNombre",
      Filter: ColumnFilter,
    },
    {
      Header: "Evento",
      accessor: "evento",
      Filter: ColumnFilter,
    },
    {
      Header: "Observación",
      accessor: "observacion",
      Filter: ColumnFilter,
    },
    {
      Header: "Fecha",
      accessor: "fecha",
      Filter: ColumnFilter,
    },
  ];

  const COLUMNAS_REGISTROS_VACACIONES = [
    {
      Header: "Periodo",
      accessor: (d) => d.periodoVacacion.anyoInicio,
      Cell: ({ value }) => `${value}-${value + 1}`,
      Filter: ColumnFilter,
    },
    {
      Header: "Fecha Inicio",
      accessor: "fechaInicio",
      Filter: ColumnFilter,
    },
    {
      Header: "FechaTermino",
      accessor: "fechaTermino",
      Filter: ColumnFilter,
    },
    {
      Header: "Días Hábiles",
      accessor: "diasHabiles",
      Cell: ({ value }) => `${value} días`,
      Filter: ColumnFilter,
    },
    {
      Header: "Días Inhábiles",
      accessor: "diasFeriados",
      Cell: ({ value }) => `${value} días`,
      Filter: ColumnFilter,
    },
    {
      Header: "Días Progresivos",
      accessor: "diasProgresivos",
      Cell: ({ value }) => `${value} días`,
      Filter: ColumnFilter,
    },
    {
      Header: "Días Proporcionales",
      accessor: "diasProporcionales",
      Cell: ({ value }) => `${value} días`,
      Filter: ColumnFilter,
    },
    {
      Header: "Tipo Registro",
      accessor: "tipoRegistro",
      Cell: ({ value }) => {
        if (value === "VACACIONES_NORMALES") {
          return "Vacaciones Normales";
        } else if (value === "VACACIONES_PROPORCIONALES") {
          return "Vacaciones Proporcionales";
        } else if (value === "VACACIONES_PROGRESIVAS") {
          return "Vacaciones Progresivas";
        } else {
          return "Vacaciones Normales";
        }
      },
      Filter: ColumnFilter,
    },
  ];

  async function imprimirComprobante() {
    toast.loading("Imprimiendo Comprobante", estilosAlerta);

    const requestBody = {
      registroVacacionId: modalData.id,
    };

    axiosPrivate({
      url: `${api_service}/api/v1/vacaciones/registros/comprobante`,
      method: "POST",
      data: requestBody,
      maxBodyLength: Infinity,
      headers: { Authorization: `Bearer ${tokenUser}`, ClientId: `${token}` },
      responseType: "blob", // importante
      onDownloadProgress: (progressEvent) => {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        console.log("percentCompleted", percentCompleted);
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Comprobante_Vacaciones_${modalData.rut}.pdf`
        );
        document.body.appendChild(link);
        link.click();
        toast.dismiss();
        toast.success(
          "Comprobante de Vacaciones descargado con éxito",
          estilosAlerta
        );
      })
      .catch((error) => {
        console.log(error);
        toast.dismiss();
        toast.error(
          error.response.data.message
            ? error.response.data.message
            : "Error al descargar Comprobante de Vacaciones",
          estilosAlerta
        );
      });
  }

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-screen md:max-w-6xl md:my-6 mx-auto mt-20">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col max-h-[85vh] overflow-y-auto w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t dark:bg-hercules-dark-bg-100 ">
              <h3 className="titulo-Modal">Ver Solicitud de Vacaciones</h3>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto overflow-auto dark:bg-hercules-dark-bg-200">
              <h3 className="text-2xl font-semibold leading-normal mb-3 dark:text-white text-hercules-light-font-secondary">
                Identificación del trabajador
              </h3>

              <div className="flex flex-wrap mt-6">
                <div className="w-full md:w-4/12 md:pr-3 md:mb-11 mb-9">
                  <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                    Nombre
                  </label>
                  <input
                    value={
                      modalData.nombres +
                      " " +
                      modalData.apellidoPaterno +
                      " " +
                      modalData.apellidoMaterno
                    }
                    disabled
                    className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                    type="text"
                  />
                </div>

                <div className="w-full md:w-2/12 md:px-3 md:mb-11 mb-9">
                  <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                    RUT
                  </label>
                  <input
                    value={UtilsFunctions.rutFormatter(modalData.rut, {
                      dots: true,
                    })}
                    disabled
                    className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                    type="text"
                  />
                </div>

                <div className="w-full md:w-3/12 md:px-3 md:mb-11 mb-9">
                  <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                    Fecha de Contratación
                  </label>
                  <input
                    value={fechaIngresoLegal}
                    disabled
                    className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                    type="text"
                  />
                </div>

                <div className="w-full md:w-3/12 md:pl-3 md:mb-11 mb-9">
                  <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                    Sucursal
                  </label>
                  <input
                    value={sucursal}
                    disabled
                    className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                    type="text"
                  />
                </div>
              </div>

              <h3 className="text-2xl font-semibold leading-normal mb-3 dark:text-white text-hercules-light-font-secondary">
                Descripción Uso de Vacaciones
              </h3>

              <div className="flex flex-wrap mt-6">
                <h5 className="text-lg font-normal leading-normal mb-3 dark:text-white text-hercules-light-font-secondary">
                  El trabajador hace uso de su feriado legal desde el{" "}
                  <b>{modalData.fechaInicio}</b> hasta el{" "}
                  <b>{modalData.fechaTermino}</b>, ambas fechas inclusive.
                </h5>
              </div>

              <div className="flex flex-wrap mt-6">
                <div className="w-full md:w-3/12 md:pr-3 md:mb-11 mb-9">
                  <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                    Total días Hábiles del periodo
                  </label>
                  <input
                    value={modalData.diasHabilesTotales}
                    disabled
                    className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                    type="text"
                  />
                </div>

                <div className="w-full md:w-3/12 md:px-3 md:mb-11 mb-9">
                  <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                    Total días Inhábiles del periodo
                  </label>
                  <input
                    value={modalData.diasFeriadosTotales}
                    disabled
                    className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                    type="text"
                  />
                </div>

                <div className="w-full md:w-3/12 md:px-3 md:mb-11 mb-9">
                  <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                    Total días F. Prog incluidos
                  </label>
                  <input
                    value={modalData.diasProgresivosTotales}
                    disabled
                    className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                    type="text"
                  />
                </div>

                <div className="w-full md:w-3/12 md:pl-3 md:mb-11 mb-9">
                  <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                    Total días Proporcionales
                  </label>
                  <input
                    value={modalData.diasProporcionalesTotales}
                    disabled
                    className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                    type="text"
                  />
                </div>
              </div>

              <h3 className="text-2xl font-semibold leading-normal mb-3 dark:text-white text-hercules-light-font-secondary">
                Detalle Registros Vacaciones
              </h3>

              <div className="w-full mb-9">
                <SimpleTable
                  col={COLUMNAS_REGISTROS_VACACIONES}
                  tableData={modalData.registrosVacaciones || []}
                  exportCsvButton={false}
                  hasPagination={false}
                  searchBar={false}
                  showFilters={false}
                  tableHeight="h-64"
                />
              </div>

              <h3 className="text-2xl font-semibold leading-normal mb-3 dark:text-white text-hercules-light-font-secondary">
                Historial
              </h3>

              <div className="w-full mb-9">
                <SimpleTable
                  col={COLUMNAS_VACACIONES_SOLICITADAS}
                  tableData={modalData.historial || []}
                  exportCsvButton={false}
                  hasPagination={false}
                  searchBar={false}
                  showFilters={false}
                  tableHeight="h-56"
                />
              </div>
            </div>

            {/*footer*/}
            <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-bluegray-500 dark:border-bluegray-200 rounded-b">
              <button
                className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-sm md:text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                type="button"
                onClick={() => {
                  cerrarModal();
                  //   reset();
                }}
              >
                Cerrar
              </button>
              <button
                className={` ${
                  modalData.estado == "APROBADO"
                    ? "bg-hercules-light-primary-button hover:shadow-lg hover:scale-105"
                    : "bg-gray-500"
                }  text-white  font-bold uppercase text-sm md:text-base px-7 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400`}
                type="button"
                disabled={modalData.estado == "APROBADO" ? false : true}
                onClick={() => {
                  imprimirComprobante();
                }}
              >
                Imprimir comprobante
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
