import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCentroCostos,
  getCentrosCostosByEmpresaId,
  listaPeriodosHastaActual,
} from "../../Redux/Actions";
import { useStateContext } from "../../contexts/ContextProvider";
import { ColumnFilter } from "../../components";
import UtilsFunctions from "../../utils/UtilsFunctions";
import { useForm } from "react-hook-form";
import { axiosPrivate } from "../../common/axiosPrivate";
import { toast } from "react-hot-toast";
import SimpleTable from "../../components/SimpleTable";

export default function ResumenPagoImposiciones() {
  const {
    register,
    getValues,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      centroCostoId: 0,
      tipoHaberDescuento: "",
      nombreHaberDescuento: "",
      periodoAnyo: "",
    },
  });

  const { refreshTable, setRefreshTable, activeMenu, screenSize } =
    useStateContext();

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const dispatch = useDispatch();
  const [loading] = useState(true);
  const empresaId = localStorage.getItem("empresaId");
  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  useEffect(() => {
    try {
      getPeriodosCargados();
      dispatch(listaPeriodosHastaActual());
      dispatch(getCentroCostos());
      dispatch(getCentrosCostosByEmpresaId(empresaId));
    } catch (error) {
      console.log(error);
    }
  }, [refreshTable, dispatch, loading]);

  const infoCCosto = useSelector((state) => state.centrosCostoEmpresa);
  const anyosData = UtilsFunctions.obtenerAnyosHastaActual();
  const [mesesData, setMesesData] = useState([]);
  const [infoPeriodos, setInfoPeriodos] = useState([]);
  const [infoTabla, setInfoTabla] = useState([]);

  async function getPeriodosCargados() {
    const infoMeses = await axiosPrivate(
      `${api_service}/api/v1/periodos/cargados`,
      Authorization
    );
    setInfoPeriodos(infoMeses.data);
  }

  const handleYearChange = (e) => {
    const year = parseInt(e);
    const yearData = infoPeriodos.find((item) => item.anyo === year);
    if (yearData) {
      const filteredMonths = yearData.periodos.filter(
        (mes) => mes.datosPrevisionalesCargados === true
      );
      const transformedMonths = filteredMonths.map((month) =>
        UtilsFunctions.integerToMonth(month.mes)
      );
      setMesesData(transformedMonths);
    } else {
      setMesesData([]);
    }
  };

  async function onSubmit() {
    const data = getValues();
    try {
      const info = await axiosPrivate(
        `${api_service}/api/v1/periodos/mes/${data.numeroMes}/anyo/${data.periodoAnyo}`,
        Authorization
      );
      const response = await axiosPrivate(
        `${api_service}/api/v1/liquidaciones/pago-imposiciones/periodo/${info.data.id}/empresa/${empresaId}/centro-costo/${data.centroCostoId}`,
        Authorization
      );
      if (!response.data.length > 0) {
        toast.error("No existen registros de este Haber");
        setInfoTabla([]);
      } else {
        setInfoTabla(response.data);
        setRefreshTable(!refreshTable);
        toast.dismiss();
        toast.success("Tabla cargada con éxito", estilosAlerta);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const COLUMNS = [
    {
      Header: "Descripción",
      accessor: "descripcion",
      Cell: ({ value }) => {
        if (
          value === "Subtotal AFP" ||
          value === "Subtotal Isapre" ||
          value === "IPS/Fonasa" ||
          value === "Caja de Compensación" ||
          value === "Mutual" ||
          value === "Total a Pagar"
        ) {
          return <span className="font-black">{value}</span>;
        }
        return value;
      },
      Filter: ColumnFilter,
    },
    {
      Header: "Total",
      accessor: (d) => {
        if (
          d.descripcion === "Subtotal AFP" ||
          d.descripcion === "Subtotal Isapre" ||
          d.descripcion === "IPS/Fonasa" ||
          d.descripcion === "Caja de Compensación" ||
          d.descripcion === "Mutual" ||
          d.descripcion === "Total a Pagar"
        ) {
          return (
            <span className="font-black">
              {UtilsFunctions.integerFormatterValidacionNull(d.total)}
            </span>
          );
        }
        return UtilsFunctions.integerFormatterValidacionNull(d.total);
      },
      Filter: ColumnFilter,
    },
  ];

  const headersCsv = [
    { name: "Descripcion", key: "descripcion" },
    { name: "Total", key: "total" },
  ];

  return (
    <div
      className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
        activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">Informes</div>
      <div className="titulo-Pagina">Resumen pago imposiciones</div>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="pt-3 flex flex-wrap">
          <div className="flex flex-wrap w-full md:min-w-[160px] md:max-w-[245px]">
            <div className="w-4/12 md:w-4/12 md:min-w-[100px] md:max-w-[150px] mr-3 md:mr-0 md:pr-3 mb-3">
              <label
                htmlFor="periodoId"
                className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold"
              >
                Periodo
              </label>
              <select
                {...register("periodoAnyo", {
                  onChange: (e) => {
                    setValue("periodoMes", "");
                    handleYearChange(e.target.value);
                  },
                  required: { value: true, message: "Obligatorio" },
                })}
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option hidden value="">
                  Año
                </option>
                {anyosData.map((anyo, i) => (
                  <option value={anyo} key={i}>
                    {anyo}
                  </option>
                ))}
              </select>
              <span
                className={`dark:text-red-400 text-red-600 text-xs ${
                  !errors.periodoAnyo && "opacity-0 invisible"
                }`}
              >
                {errors.periodoAnyo ? errors.periodoAnyo.message : "hidden"}
              </span>
            </div>

            <div className="w-5/12 md:w-6/12 md:min-w-[142px] md:max-w-[200px] mt-6 md:pr-3 mb-3">
              <select
                {...register("periodoMes", {
                  onChange: (e) => {
                    setValue("numeroMes", e.target.selectedIndex);
                  },
                  required: { value: true, message: "Obligatorio" },
                })}
                disabled={watch("periodoAnyo") === "" ? true : false}
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option hidden value="">
                  Mes
                </option>
                {mesesData.map((mes, i) => (
                  <option value={mes} key={i}>
                    {mes}
                  </option>
                ))}
              </select>
              <span
                className={`dark:text-red-400 text-red-600 text-xs ${
                  !errors.periodoMes && "opacity-0 invisible"
                }`}
              >
                {errors.periodoMes ? errors.periodoMes.message : "hidden"}
              </span>
            </div>
          </div>

          <div className="w-full md:w-4/12 md:min-w-[200px] md:max-w-[300px] mb-9 md:mb-3 md:mx-3">
            <label className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
              Centro de Costo
            </label>
            <select
              {...register("centroCostoId")}
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option value={"0"}>Todos</option>
              {infoCCosto.map((ccosto, i) => (
                <option value={ccosto.id} key={i}>
                  {ccosto.nombre}
                </option>
              ))}
            </select>
          </div>

          <div className="w-4/12 md:w-[110px] md:mt-6 md:ml-3 mb-6 md:mb-0">
            <button
              type="submit"
              className="w-full hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-in-out transition-all duration-400"
            >
              Buscar
            </button>
          </div>
        </div>
      </form>

      {infoTabla.length > 0 && (
        <>
          <div className="h-full md:mr-12 rounded-lg">
            <SimpleTable
              col={COLUMNS}
              tableData={infoTabla}
              showFilters={false}
              searchBar={false}
              hasPagination={false}
            />
          </div>
          <div className="w-7/12 md:mt-6 mt-3 md:min-w-[120px] md:max-w-[200px]">
            <button
              type="button"
              onClick={() => {
                UtilsFunctions.jsonToCSV3(
                  infoTabla,
                  headersCsv,
                  "Resumen Pago Imposiciones"
                );
              }}
              className="w-full hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
            >
              Exportar Tabla
            </button>
          </div>
        </>
      )}
    </div>
  );
}
