import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCentroCostos,
  getCentrosCostosByEmpresaId,
  getDescuentos,
  getHaberes,
  listaPeriodosHastaActual,
} from "../../Redux/Actions";
import { useStateContext } from "../../contexts/ContextProvider";
import { ColumnFilter } from "../../components";
import UtilsFunctions from "../../utils/UtilsFunctions";
import { useForm } from "react-hook-form";
import { axiosPrivate } from "../../common/axiosPrivate";
import { toast } from "react-hot-toast";
import DeclaracionJuradaIngresoCargaFamiliarTabla from "../../components/Informes/DeclaracionJuradaIngresoCargaFamiliarTabla";

export default function DeclaracionJuradaIngresoCargaFamiliar() {
  const {
    register,
    getValues,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      centroCostoId: 0,
      tipoHaberDescuento: "",
      nombreHaberDescuento: "",
    },
  });

  const { refreshTable, setRefreshTable, activeMenu, screenSize } = useStateContext();

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const dispatch = useDispatch();
  const [loading] = useState(true);
  const empresaId = localStorage.getItem("empresaId");
  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const validacionCampo = {
    required: { value: true, message: "Obligatorio" },
  };

  useEffect(() => {
    try {
      dispatch(listaPeriodosHastaActual());
      dispatch(getCentroCostos());
      dispatch(getHaberes());
      dispatch(getDescuentos());
      dispatch(getCentrosCostosByEmpresaId(empresaId));
    } catch (error) {
      console.log(error);
    }
  }, [refreshTable, dispatch, loading]);

  const infoCCosto = useSelector((state) => state.centrosCostoEmpresa);
  const anyosData = UtilsFunctions.obtenerAnyosHastaActual();
  const [infoTabla, setInfoTabla] = useState([]);

  async function onSubmit() {
    const data = getValues();
    try {
      const response = await axiosPrivate(
        `${api_service}/api/v1/liquidaciones/count-liquidaciones-by-trabajador-first-six-months/anyo/${data.periodoAnyo}/empresa/${empresaId}/centro-costo/${data.centroCostoId}`,
        Authorization
      );
      if (!response.data.length > 0) {
        toast.error("No existen registros en este Año y Centro de costo", estilosAlerta);
        setInfoTabla([]);
      } else {
        setInfoTabla(response.data);
        setRefreshTable(!refreshTable);
        toast.dismiss();
        toast.success("Tabla cargada con éxito", estilosAlerta);
      }
    } catch (error) {
      console.log("error", error);
      toast.dismiss()
      toast.error(error.response.data.message ? error.response.data.message : "Error al cargar tabla", estilosAlerta)
    }
  }

  const COLUMNS = [
    {
      Header: "RUT",
      accessor: (d) => UtilsFunctions.rutFormatter(d.rut, { dots: true }),
      Filter: ColumnFilter,
    },
    {
      Header: "Apellido paterno",
      accessor: "apellidoPaterno",
      Filter: ColumnFilter,
    },
    {
      Header: "Apellido materno",
      accessor: "apellidoMaterno",
      Filter: ColumnFilter,
    },
    {
      Header: "Nombre",
      accessor: "nombres",
      Filter: ColumnFilter,
    },
    {
      Header: "Meses",
      accessor: "meses",
      Filter: ColumnFilter,
    },
  ];

  return (
    <div
    className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
      activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">
        Informes
      </div>
      <div className="titulo-Pagina">
        Declaración jurada de ingresos para cargas familiares
      </div>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <div className="pt-3 flex flex-wrap">
          <div className="w-4/12 md:w-1/12 md:min-w-[100px] md:max-w-[150px] mb-3 mr-3 md:mr-0 md:pr-3 ">
            <label
              htmlFor="periodoId"
              className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold"
            >
              Periodo
            </label>
            <select
              {...register("periodoAnyo", {
                required: { value: true, message: "Obligatorio" },
              })}
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
              <option hidden value="">
                Año
              </option>
              {anyosData.map((anyo, i) => (
                <option value={anyo} key={i}>
                  {anyo}
                </option>
              ))}
            </select>
            <span
              className={`dark:text-red-400 text-red-600 text-xs ${
                !errors.periodoAnyo && "opacity-0 invisible"
              }`}
            >
              {errors.periodoAnyo ? errors.periodoAnyo.message : "hidden"}
            </span>
          </div>

          <div className="w-7/12 md:w-4/12 md:min-w-[200px] md:max-w-[300px] mb-3 md:px-3">
            <label className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
              Centro de Costo
            </label>
            <select
              {...register("centroCostoId")}
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option value={"0"}>Todos</option>
              {infoCCosto.map((ccosto, i) => (
                <option value={ccosto.id} key={i}>
                  {ccosto.nombre}
                </option>
              ))}
            </select>
          </div>

          <div className="w-4/12 md:w-[110px] md:mt-6 md:ml-3 mb-6 md:mb-0">
            <button
              type="submit"
              className="w-full hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-in-out transition-all duration-400"
              >
              Buscar
            </button>
          </div>
        </div>
      </form>

      {infoTabla.length > 0 && (
        <>
        <div className="h-full md:mr-12 rounded-lg">
            <DeclaracionJuradaIngresoCargaFamiliarTabla
              col={COLUMNS}
              tableData={infoTabla}
              anyo={watch("periodoAnyo")}
            />
          </div>
        </>
      )}
    </div>
  );
}
