import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InfoHolding } from "../../Redux/Actions";
import { useStateContext } from "../../contexts/ContextProvider";
import UtilsFunctions from "../../utils/UtilsFunctions";

export default function PerfilHolding() {
  const { activeMenu } = useStateContext();
  const dispatch = useDispatch();
  const tokenRut = localStorage.getItem("token-rut");

  useEffect(() => {
    dispatch(InfoHolding(tokenRut));
  }, [dispatch]);

  const holding = useSelector((state) => state.infoHolding);

  return (
    <div
    className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
        activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">Parámetros / Configuración</div>
      <div className="titulo-Pagina">
        Perfil Holding
      </div>

          <div className="flex flex-wrap pt-3 w-full md:w-7/12 ">

            <div className="w-full mb-3">
              <label
                className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold"
                htmlFor="grid-last-name"
              >
                Razón Social
              </label>
              <input
                disabled
                value={holding.razonSocial}
                name="nombre"
                id="nombre"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="text"
              />
            </div>

            <div className="w-full mb-3">
              <label
                className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold"
                htmlFor="grid-last-name"
              >
                Rut
              </label>
              <input
                disabled
                value={UtilsFunctions.rutFormatter(holding.id, {dots: true})}
                name="nombre"
                id="nombre"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="text"
              />
            </div>

            <div className="w-full mb-3">
              <label
                className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold"
                htmlFor="grid-last-name"
              >
                Giro
              </label>
              <input
                disabled
                value={holding.giro}
                name="nombre"
                id="nombre"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="text"
              />
            </div>

            <div className="w-full mb-3">
              <label
                className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold"
                htmlFor="grid-last-name"
              >
                Dirección
              </label>
              <input
                disabled
                value={holding.direccion}
                name="nombre"
                id="nombre"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="text"
              />
            </div>

            <div className="w-full mb-3">
              <label
                className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold"
                htmlFor="grid-last-name"
              >
                Comuna
              </label>
              <input
                disabled
                value={holding.comuna}
                name="nombre"
                id="nombre"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="text"
              />
            </div>

            <div className="w-full mb-3">
              <label
                className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold"
                htmlFor="grid-last-name"
              >
                Ciudad
              </label>
              <input
                disabled
                value={holding.ciudad}
                name="nombre"
                id="nombre"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="text"
              />
            </div>

            <div className="w-full mb-3">
              <label
                className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold"
                htmlFor="grid-last-name"
              >
                Correo Electrónico
              </label>
              <input
                disabled
                value={holding.email}
                name="nombre"
                id="nombre"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="text"
              />
            </div>

            <div className="w-full mb-3">
              <label
                className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold"
                htmlFor="grid-last-name"
              >
                Teléfono
              </label>
              <input
                disabled
                value={holding.telefono}
                name="nombre"
                id="nombre"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="text"
              />
            </div>

          </div>
    </div>
  );
}
