import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useStateContext } from "../../../contexts/ContextProvider";
import { useDispatch, useSelector } from "react-redux";
import {
  getDescuentosBasicos,
  getDescuentosDT,
  getHaberesActivos,
  limpiarInfoMenuEdicion,
} from "../../../Redux/Actions";
import { toast } from "react-hot-toast";
import { axiosPrivate } from "../../../common/axiosPrivate";
import ModalSpinner from "../../ModalSpinner";

export default function NuevoDescuentos({ isVisible, onClose }) {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      nombre: "",
      descuentoDtId: "",
      formulaId: "",
      porcentajeCheckboxes: "",
    },
  });

  const { refreshTable, setRefreshTable, screenSize } = useStateContext();
  const [habilitado, setHabilitado] = useState(true);
  const [botonGuardarHabilitado, setBotonGuardarHabilitado] = useState(true)

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const validacionTextInput = {
    required: { value: true, message: "Obligatorio" },
  };

  const REACT_APP_API_HERCULES_SERVICE =
    process.env.REACT_APP_API_HERCULES_SERVICE;

  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDescuentosDT());
    dispatch(getHaberesActivos());
    dispatch(getDescuentosBasicos());
  }, []);

  const descuentosDT = useSelector((state) => state.getDescuentosDT);
  const haberesactivos = useSelector((state) => state.getHaberesActivos);
  const descuentosBasicos = useSelector((state) => state.getDescuentosBasicos);

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("getDescuentosDT"));
      dispatch(limpiarInfoMenuEdicion("getHaberesActivos"));
      dispatch(limpiarInfoMenuEdicion("getDescuentosBasicos"));
    };
  }, []);

  const loadingModal = descuentosDT?.length > 0 && haberesactivos?.length > 0 && descuentosBasicos?.length > 0 ;

  const onSubmit = async (data) => {
    setBotonGuardarHabilitado(false)
    toast.loading("Creando Descuento", estilosAlerta);
    const payload = {
      nombre: data.nombre,
      descuentoDtId: data.descuentoDtId,
      descuentoBasicoId: null,
      formula: data.formulaId,
      porcentajeCheckboxes: data.porcentajeCheckboxes,
      habilitado: habilitado,
      haberes: Array.isArray(data.haberesDescuento)
        ? data.haberesDescuento
        : [data.haberesDescuento],
    };
    console.log(payload);
    await axiosPrivate
      .post(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/agregardescuento`,
        payload,
        Authorization
      )
      .then((res) => {
        toast.dismiss();
        toast.success("Descuento creado con éxito", estilosAlerta);
        setRefreshTable(!refreshTable);
        onClose();
        reset();
        setBotonGuardarHabilitado(true)
      })
      .catch((error) => {
        toast.dismiss();
        toast.error(
          error.response.data.message
            ? error.response.data.message
            : "Error al crear Descuento",
            estilosAlerta
        );
        console.log(error);
        setBotonGuardarHabilitado(true)
      });
  };

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <div className="primerDivModal">
          <div className="segundoDivModal max-w-xl">
            {/*content*/}
            <div className="tercerDivModal shadow-lg  max-h-[85vh] ">
              {/*header*/}
              <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t dark:bg-hercules-dark-bg-100 ">
              <h3 className="titulo-Modal">
                  Crear Descuento
                </h3>
              </div>
              {/*body*/}
              <div className="relative p-6 flex-auto overflow-auto dark:bg-hercules-dark-bg-200">

              {!loadingModal ? (
                  <div className="h-fix">
                    <ModalSpinner />
                  </div>
                ) : (
                  <>
                  <div className="div-Inputs-EditarHaber">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      Nombre
                    </label>
                    <input
                      {...register("nombre", validacionTextInput)}
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                    />
                    <span
                      className={`text-red-500 text-xs italic ${
                        !errors.nombre && "opacity-0 invisible"
                      }`}
                    >
                      {errors.nombre ? errors.nombre.message : "hidden"}
                    </span>
                </div>

                <div className="div-Inputs-EditarHaber">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      Categoría L.R.E
                    </label>
                    <select
                      {...register("descuentoDtId", validacionTextInput)}
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      <option hidden value="">
                        Seleccionar
                      </option>
                      {descuentosDT.map((descuentoDt) => (
                        <option key={descuentoDt.id} value={descuentoDt.id}>
                          {descuentoDt.glosa}
                        </option>
                      ))}
                    </select>
                    <span
                      className={`text-red-500 text-xs italic ${
                        !errors.descuentoDtId && "opacity-0 invisible"
                      }`}
                    >
                      {errors.descuentoDtId
                        ? errors.descuentoDtId.message
                        : "hidden"}
                    </span>
                </div>

                <div className="div-Inputs-EditarHaber">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      Fórmula
                    </label>
                    <select
                      {...register("formulaId", {
                        ...validacionTextInput,
                        onChange: () => {
                          setValue("porcentajeCheckboxes", "");
                          setValue("haberesDescuento", "");
                        },
                      })}
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      <option hidden value="">
                        Seleccionar
                      </option>
                      <option value="1">Valor</option>
                      <option value="2">Porcentaje</option>
                    </select>
                    <span
                      className={`text-red-500 text-xs italic ${
                        !errors.formulaId && "opacity-0 invisible"
                      }`}
                    >
                      {errors.formulaId ? errors.formulaId.message : "hidden"}
                    </span>
                </div>

                {watch("formulaId") === "2" && (
                  <div className="relative flex flex-wrap -mx-3 mb-3 md:mb-6">
                    <div className="w-full px-3">
                      <label
                        className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Porcentaje de descuento aplicado sobre ...
                      </label>
                      <select
                        {...register("porcentajeCheckboxes", {
                          ...validacionTextInput,
                          onChange: () => {
                            setValue("haberesDescuento", "");
                          },
                        })}
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      >
                        <option hidden value="">
                          Seleccionar
                        </option>
                        <option value="1">Total Imponible</option>
                        <option value="2">Total No Imponible</option>
                        <option value="3">Total Tributable</option>
                        <option value="4">Total No Tributable</option>
                        <option value="6">Monto Tributable</option>
                        <option value="5">Algunos Haberes</option>
                      </select>
                      <span
                        className={`text-red-500 text-xs italic ${
                          !errors.porcentajeCheckboxes && "opacity-0 invisible"
                        }`}
                      >
                        {errors.porcentajeCheckboxes
                          ? errors.porcentajeCheckboxes.message
                          : "hidden"}
                      </span>
                    </div>
                  </div>
                )}

                {watch("porcentajeCheckboxes") === "5" &&
                watch("formulaId") === "2" ? (
                  <div className="relative flex flex-wrap -mx-3 mb-3 md:mb-6">
                    <div className="w-full px-3">
                      <label
                        className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Seleccione los haberes en donde se aplicará el
                        descuento:
                      </label>
                      <div className="border-l-2 border-t-2 border-r-2 border-b-2 h-48 overflow-y-auto px-3">
                        {haberesactivos.map((haber) => (
                          <div
                            key={haber.id}
                            className="py-1 border-b-2 dark:text-white text-hercules-light-font-secondary"
                          >
                            <input
                              {...register(`haberesDescuento`, {
                                validate: (value) => {
                                  return (
                                    value.length > 0 ||
                                    "Debe seleccionar al menos un Haber"
                                  );
                                },
                              })}
                              type="checkbox"
                              value={haber.id}
                              className="mr-2"
                            />
                            <label className="pl-3" htmlFor={haber.id}>
                              {haber.nombre}
                            </label>
                          </div>
                        ))}
                      </div>
                      <span
                        className={`text-red-500 text-xs italic ${
                          !errors.haberesDescuento && "opacity-0 invisible"
                        }`}
                      >
                        {errors.haberesDescuento
                          ? errors.haberesDescuento.message
                          : "hidden"}
                      </span>
                    </div>
                  </div>
                ) : null}

                <div className="flex flex-wrap">
                      <span className="uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold my-auto mr-10">
                        Habilitado
                      </span>
                      <label className="inline-flex relative items-center cursor-pointer">
                        <input
                          type="checkbox"
                          className="sr-only peer"
                          checked={habilitado}
                          readOnly
                        />
                        <div
                          onClick={() => {
                            setHabilitado(!habilitado);
                          }}
                          onChange={(e) => console.log(e.target)}
                          className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"
                        ></div>
                      </label>
                  </div>
                </>
                )}
              </div>
              {/*footer*/}
              <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                  type="button"
                  onClick={() => {
                    onClose();
                    reset();
                  }}
                >
                  Cerrar
                </button>
                <button
                  disabled={!botonGuardarHabilitado}
                  className={` ${!botonGuardarHabilitado? "bg-gray-500" : "bg-hercules-light-primary-button hover:shadow-lg hover:scale-105"}  text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400`}
                  type="submit"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
