import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStateContext } from "../../contexts/ContextProvider";
import { ColumnFilter } from "../../components";
import {
  getTrabajadores,
  hideLoader,
  limpiarInfoMenuEdicion,
  showLoader,
} from "../../Redux/Actions";
import UtilsFunctions from "../../utils/UtilsFunctions";
import SimpleTable from "../../components/SimpleTable";
import ModalAsignarDescuento from "../../components/Edicion/DescuentosFijos/ModalAsignarDescuento";
import ModalVerHistorico from "../../components/Edicion/DescuentosFijos/ModalVerHistorico";
import RutColumnFilter from "../../components/RutColumnFilter";

export default function DescuentosFijos() {
  const { refreshTable, activeMenu } = useStateContext();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader());
      await Promise.all([
        dispatch(getTrabajadores()),
      ]);
      dispatch(hideLoader());
    };

    fetchData().catch((error) => {
      console.log(error.response.data.message);
      dispatch(hideLoader());
    });
  }, [refreshTable, dispatch]);

  useEffect(() =>{
    return () => {
      dispatch(limpiarInfoMenuEdicion("trabajadores"))
    }
  },[])

  const trabajadoresData = useSelector((state) => state.trabajadores);

  const infoDescuentosFijos = [
    {
      id: 1,
      rut: "12335545-8",
      apellidoPaterno: "Navarrete",
      apellidoMaterno: "Guzmán",
      nombres: "Aldo",
      contratoActivo: true,
    },
    {
      id: 2,
      rut: "12315145-8",
      apellidoPaterno: "Salas",
      apellidoMaterno: "Guiñazu",
      nombres: "Alex",
      contratoActivo: true,
    },
    {
      id: 3,
      rut: "12335545-8",
      apellidoPaterno: "León",
      apellidoMaterno: "Fuentes",
      nombres: "Felipe",
      contratoActivo: false,
    },
  ];

  const [showModalVerHistorico, setShowModalVerHistorico] = useState(false);
  const [showModalAsignarDescuento, setShowModalAsignarDescuento] =
    useState(false);
    const [rowModalData, setRowModalData] = useState([]);

  const DescuentosFijosTabla = [
    {
      Header: "RUT",
      accessor: "rut",
      Cell: ({ value }) => UtilsFunctions.rutFormatter(value, { dots: true }),
      Filter: RutColumnFilter,
    },
    {
      Header: "Trabajador",
      accessor: (d) =>
        d.nombres + " " + d.apellido_Paterno + " " + d.apellido_Materno,
      Filter: ColumnFilter,
    },
  ];

  const headersCsv = [
    {
      key: "nombre",
      name: "Nombre",
    },
    {
      key: "rut",
      name: "RUT",
    },
  ];

  return (
    <div
      className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
        activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">Edición</div>
      <div className="titulo-Pagina">Descuentos Fijos</div>

      <div>
        <p className="mt-3 text-xl dark:text-white text-hercules-light-font-secondary">
          Descuentos fijos sirve para asignar un descuento a un trabajador, que
          se repetirá mes a mes.
          <br /> OJO: En esta sección solo se podrán ingresar descuentos fijos a
          trabajadores que tengan un contrato vigente. El descuento debe estar
          creado previamente.
        </p>
      </div>

      <div className="h-full md:mr-12 rounded-lg pt-5">
        <SimpleTable
          col={DescuentosFijosTabla}
          tableData={trabajadoresData || []}
          primaryButton={{
            type: "asignarDescuento",
            onClickFunc: (data) => {
              setShowModalAsignarDescuento(true);
              setRowModalData(data);
            },
            active: true,
          }}
          secondaryButton={{
            type: "verHistorico",
            onClickFunc: (data) => {
              setShowModalVerHistorico(true);
              setRowModalData(data);
            },
            active: true,
          }}
          exportCsv={() =>
            UtilsFunctions.jsonToCSV3(
              infoDescuentosFijos,
              headersCsv,
              "Descuentos Fijos"
            )
          }
        />
      </div>

      {showModalAsignarDescuento && (
        <ModalAsignarDescuento
          isVisible={showModalAsignarDescuento}
          onClose={() => setShowModalAsignarDescuento(false)}
          modalData={rowModalData}
        />
      )}

      {showModalVerHistorico && (
      <ModalVerHistorico
      isVisible={showModalVerHistorico}
      onClose={() => setShowModalVerHistorico(false)}
      modalData={rowModalData}
    />
      )}
    </div>
  );
}
