import React, { useState } from "react";
import { useForm } from "react-hook-form";

const AgregarHaberForm = ({ haberesSelect, sendFormData }) => {
  const formatter = new Intl.NumberFormat("es-CL", {
    styles: "currency",
    currency: "CLP",
    minimumFractionDigits: 0,
  });

  const {
    register,
    reset,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useForm();

  const [formulaSelectDisabled, setFormulaSelectDisabled] = useState(true);
  const [formulaFijo, setFormulaFijo] = useState(true);
  const [formulaSeleccionada, setFormulaSeleccionada] = useState(false);

  function handleHaberChange(e) {
    const haber = haberesSelect[e.target.selectedIndex - 1];
    setValue("haber", haber);

    setValue("categoriaLre", haber.haberesDt.glosa);

    if (haber.haberesDt.fijoVariable === 0) {
      setFormulaSelectDisabled(false);
      setFormulaSeleccionada(false);
      setValue("monto", "Seleccione Fórmula");
      setValue("formula", "0");
    } else {
      setFormulaSelectDisabled(true);
      setValue("formula", haber.haberesDt.fijoVariable?.toString());
      setFormulaSeleccionada(true);

      if (haber.haberesDt.fijoVariable === 1) {
        setValue("monto", "");
        setFormulaFijo(true);
      } else {
        setValue("monto", "Monto Variable");
        setFormulaFijo(false);
      }
    }

    setValue("imponible", haber.haberesDt.imponible ? "Si" : "No");
    setValue("tributable", haber.haberesDt.tributable ? "Si" : "No");
    setValue(
      "constituyeRemuneracion",
      haber.haberesDt.constituyeRemuneracion ? "Si" : "No"
    );
  }

  function handleFormulaChange(e) {
    const formula = e.target.value;
    setFormulaSeleccionada(true);

    if (formula === "1") {
      setValue("monto", "");
      setFormulaFijo(true);
    } else {
      setValue("monto", "Monto Variable");
      setFormulaFijo(false);
    }
  }

  async function handleAgregarHaber() {
    const passFormValidation = await trigger();
    if (!passFormValidation) {
      return null;
    }
    const data = getValues();

    const haber = {
      nombre: data.haber.nombre,
      id: data.haber.id,
      haberesDt: {
        fijoVariable: data.haber.haberesDt.fijoVariable,
        imponible: data.haber.haberesDt.imponible,
        tributable: data.haber.haberesDt.tributable,
        constituyeRemuneracion: data.haber.haberesDt.constituyeRemuneracion,
        glosa: data.haber.haberesDt.glosa,
        codigo: data.haber.haberesDt.codigo,
        id: data.haber.haberesDt.id,
      },
      condicionalAsistenciaTotal: data.haber.condicionalAsistenciaTotal,
      pagoProporcionalDiasTrabajados: data.haber.pagoProporcionalDiasTrabajados,
    };

    const haberes = {
      haber: haber,
      valor: data.monto?.replace(/\./g, "").replace("$", "") || 0,
      valorFijo: data.formula === "1" ? true : false,
      valorVariable: data.formula === "2" ? true : false,
    };

    reset();
    return haberes;
  }

  return (
    <>
    <form>
      <h3 className="pt-3 text-2xl font-normal leading-normal mb-3 dark:text-white text-hercules-light-font-secondary">
        Haberes Contrato
      </h3>
      <div className="md:flex md:flex-wrap">
        <div className="w-full mb-3 md:w-4/12 md:pr-3">
          <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
            Haber
          </label>
          <select
            {...register("haberId", {
              required: { value: true, message: "Debe seleccionar un Haber" },
              validate: (value) => {
                if (value === "Seleccionar Haber") {
                  return "Obligatorio";
                }
                return true;
              },
              onChange: (e) => handleHaberChange(e),
            })}
            name="haberId"
            className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
          >
            <option value="Seleccionar Haber" hidden>
              Seleccionar Haber
            </option>
            {haberesSelect?.map((haber, i) => (
              <option key={i} value={haber.id}>
                {haber.nombre}
              </option>
            ))}
          </select>
                  <span
                    className={`text-red-500 dark:text-red-400 text-xs italic ${
                      !errors.haberId && "opacity-0 invisible"
                    }`}
                  >
                    {errors.haberId
                      ? errors.haberId.message
                      : "hidden"}
                  </span>
        </div>

        <div className="w-full mb-9 md:mb-3 md:px-3 md:w-5/12">
          <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
            Categoría L.R.E
          </label>
          <input
            {...register("categoriaLre")}
            name="categoriaLre"
            disabled
            className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
            id="grid-rut"
            type="text"
          />
        </div>

        <div className="w-full md:w-3/12 mb-3 md:pl-3">
          <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
            Fórmula
          </label>
          <select
            {...register("formula", {
              required: {
                value: true,
                message: "Obligatorio",
              },
              validate: (value) => {
                if (value === "0") {
                  return "Obligatorio";
                }
                return true;
              },
              onChange: (e) => handleFormulaChange(e),
            })}
            name="formula"
            disabled={formulaSelectDisabled}
            className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
          >
            <option value="0" hidden>
              Seleccionar Fórmula
            </option>
            <option value="1">Haber Fijo</option>
            <option value="2">Haber Variable</option>
          </select>
                  <span
                    className={`text-red-500 dark:text-red-400 text-xs italic ${
                      !errors.formula && "opacity-0 invisible"
                    }`}
                  >
                    {errors.formula
                      ? errors.formula.message
                      : "hidden"}
                  </span>

        </div>
      </div>

      <div className="md:flex md:flex-wrap">
        <div className="w-full md:w-2/12 md:pr-3 mb-9 md:mb-3">
          <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
            Imponible
          </label>
          <input
            {...register("imponible")}
            disabled
            name="imponible"
            className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
            id="grid-rut"
            type="text"
          />
        </div>

        <div className="w-full md:w-2/12 md:px-3 mb-9 md:mb-3">
          <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
            Tributable
          </label>
          <input
            {...register("tributable")}
            disabled
            name="tributable"
            className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
            id="grid-rut"
            type="text"
          />
        </div>

        <div className="w-full md:w-3/12 md:px-3 mb-9 md:mb-3">
          <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
            Constituye Remuneración
          </label>
          <input
            {...register("constituyeRemuneracion")}
            disabled
            name="constituyeRemuineracion"
            className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
            id="grid-rut"
            type="text"
          />
        </div>

        <div className="w-full md:w-3/12 md:px-3 mb-3">
          <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
            Monto
          </label>
          <input
            {...register("monto", {
              validate: (value) => {
                if (formulaFijo === true && (value === "" || value === "$")) {
                  return "Debe ingresar un monto válido";
                }
                return true;
              },
              onChange: (e) => {
                setValue(
                  "monto",
                  "$" + formatter.format(getValues("monto")?.replace(/\D/g, ""))
                );
              },
            })}
            disabled={!formulaSeleccionada || !formulaFijo}
            name="monto"
            className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white"
            id="grid-rut"
            type="text"
          />
                  <span
                    className={`text-red-500 dark:text-red-400 text-xs italic ${
                      !errors.monto && "opacity-0 invisible"
                    }`}
                  >
                    {errors.monto
                      ? errors.monto.message
                      : "hidden"}
                  </span>
        </div>

        <div className="w-full md:w-2/12 md:pl-3 mb-6 md:pt-6 md:mb-0">
          <button
                className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
                type="button"
            onClick={async () => sendFormData(await handleAgregarHaber())}
          >
            Agregar
          </button>
        </div>
      </div>
      </form>
</>
  );
};

export default AgregarHaberForm;
