import React from "react";
import { useSelector } from "react-redux";

const Skeleton = ({ width, height, searchBar, pagination, loaderMessage }) => {

  const skeletonTableClassname = `animate-pulse bg-gray-300 dark:bg-hercules-dark-bg-200 ${
    !searchBar ? "rounded-t-md" : ""
  }
  ${!pagination ? "rounded-b-md" : ""} 
  ${width ? `${width}` : "w-full"} ${
    height ? `${height}` : "h-5"
  } text-hercules-light-font-secondary dark:text-dark-text-100 font-semibold text-2xl flex items-center justify-center`;

  const skeletonSearchBarClassname = `animate-pulse bg-gray-300 dark:bg-hercules-dark-bg-200 h-12 rounded-t-md`;

  const skeletonPaginationClassname = `animate-pulse bg-gray-300 dark:bg-hercules-dark-bg-200 h-12 rounded-b-md`;



  return (
    <>
      <div className={skeletonSearchBarClassname} />
      <div className={skeletonTableClassname}>
        {loaderMessage ? loaderMessage : "Cargando datos..."}
      </div>
      <div className={skeletonPaginationClassname} />
    </>
  );
};

export default Skeleton;
