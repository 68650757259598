import React, { useEffect, useState } from "react";
import UtilsFunctions from "../../../utils/UtilsFunctions";
import { useStateContext } from "../../../contexts/ContextProvider";
import { useDispatch, useSelector } from "react-redux";
import { getInfoSolicitudVacacionesTabla, hideLoader, limpiarInfoMenuEdicion, listaPeriodosHastaActual, showLoader } from "../../../Redux/Actions";
import { ColumnFilter } from "../../../components";
import SimpleTable from "../../../components/SimpleTable";
import ModalSolicitudVacaciones from "../components/SolicitudVacaciones/ModalSolicitudVacaciones";
import RutColumnFilter from "../../../components/RutColumnFilter";

export default function SolicitudVacaciones() {

  const dispatch = useDispatch();
  const { activeMenu } = useStateContext();

  const [solicitudData, setSolicitudData] = useState([]);
  const [showModalSolicitudVacaciones, setShowModalSolicitudVacaciones] = useState(false);
  
  const centroCostoId = localStorage.getItem("centroCostoId");
  
  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader());
      await Promise.all([
        dispatch(getInfoSolicitudVacacionesTabla(centroCostoId))
      ]);
      dispatch(hideLoader());
    };

    fetchData().catch((error) => {
      console.log(error.response.data.message);
      dispatch(hideLoader());
    });
  }, [dispatch]);

  const infoSolicitudesVacaciones = useSelector(
    (state) => state.infoSolicitudVacacionesTabla
  );

  useEffect(() =>{
    return () => {
      dispatch(limpiarInfoMenuEdicion("infoSolicitudVacacionesTabla"))
    }
  },[])
  
  useEffect(() => {
    try {
      dispatch(listaPeriodosHastaActual());
    } catch (error) {
      console.log(error);
    }
  }, [ dispatch]);

  const COLUMNS = [
    {
      Header: "RUT",
      accessor: "rut",
      Cell: ({ value }) => UtilsFunctions.rutFormatter(value, { dots: true }),
      Filter: RutColumnFilter,
    },
    {
      Header: "Nombres",
      accessor: (d) => d.nombres + " " + d.apellidoPaterno + " " + d.apellidoMaterno,
      Filter: ColumnFilter,
    },
    {
      Header: "Fecha Ingreso",
      accessor: "fechaIngresoLegal",
      Filter: ColumnFilter,
    },
    {
      Header: "Dias Vacaciones Pendientes",
      accessor: (d) => d.diasVacacionesPendientes === 1 ? d.diasVacacionesPendientes + " día" : d.diasVacacionesPendientes + " días",
      Filter: ColumnFilter,
    },
    {
      Header: "Feriado Progresivo Pendientes",
      accessor: (d) => d.feriadosProgresivosPendientes === 1 ? d.feriadosProgresivosPendientes + " día" : d.feriadosProgresivosPendientes + " días",
      Filter: ColumnFilter,
    },
    {
      Header: "Tiempo trabajado en la empresa",
      accessor: (d) => d.tiempoTrabajadoEnLaEmpresa === 1 ? d.tiempoTrabajadoEnLaEmpresa + " año" : d.tiempoTrabajadoEnLaEmpresa + " años",
      Filter: ColumnFilter,
    },
    {
      Header: "Solicitudes Pendientes",
      accessor: (d) => d.solicitudVacacionesPendientes === true ? "Si" : "No",
      Filter: ColumnFilter,
    },
  ];

  return (
    <>
    <div
      className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
        activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">Vacaciones</div>
      <div className="titulo-Pagina">Solicitud de Vacaciones</div>

        <div className=" relative h-full rounded-lg md:mr-12 md:mt-3">
          <SimpleTable
            col={COLUMNS}
            tableData={infoSolicitudesVacaciones}
            primaryButton={{
              type: "ver",
              onClickFunc: (data) => {
                setShowModalSolicitudVacaciones(true);
                setSolicitudData(data);
              },
              active: true,
            }}
            exportCsvButton={false}
          />
        </div>
    </div>
    {showModalSolicitudVacaciones &&
      <ModalSolicitudVacaciones
        isVisible={showModalSolicitudVacaciones}
        onClose={() => setShowModalSolicitudVacaciones(false)}
        modalData={solicitudData}
      />
    }
    </>
  );
}
