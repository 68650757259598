import React, { useEffect, useState } from "react";
import AgregarAPVsForm from "./AgregarAPVsForm";
import UtilsFunctions from "../../../../utils/UtilsFunctions";
import { axiosPrivate } from "../../../../common/axiosPrivate";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import SimpleTable from "../../../SimpleTable";
import AgregarCuentaDosForm from "../AgregarCajaDosForm";
import { limpiarInfoMenuEdicion, listaApvPeriodo } from "../../../../Redux/Actions";
import ModalSpinner from "../../../ModalSpinner";
import { useStateContext } from "../../../../contexts/ContextProvider";

export default function ModalAPVsCajaDos({
  institucionesApv,
  onClose,
  datosTrabajadorEditar,
  changePage,
}) {
  const {
    reset,
    formState: { errors },
  } = useForm();

  const { screenSize } = useStateContext();

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  }

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;

  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const periodoId = localStorage.getItem("periodoId");
  const dispatch = useDispatch();

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const periodoLogin = UtilsFunctions.getEmpresaPeriodoInfo().periodo;

  const [apvPeriodosTrabajador, setApvPeriodosTrabajador] = useState([]);
  const [apvTrabajadorHistorial, setApvTrabajadorHistorial] = useState([]);

  const [cajaDosTrabajador, setCajaDosTrabajador] = useState([]);
  const [cajaDosTrabajadorHistorial, setCajaDosTrabajadorHistorial] = useState([]);

  const [cargoInfoAPVs, setCargoInfoAPVs] = useState(false)
  const [cargoInfoCajaDosActual, setCargoInfoCajaDosActual] = useState(false)
  const [cargoInfoCajaDosHistorial, setCargoInfoCajaDosHistorial] = useState(false)

  const apvPeriodoResponse = useSelector((state) => state.listaApv);

  useEffect(() => {
    if (datosTrabajadorEditar) {
      dispatch(listaApvPeriodo(datosTrabajadorEditar.id));
    }
  }, [datosTrabajadorEditar]);

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("listaApv"));
      reset({})
    };
  }, []);

  useEffect(() => {
    const periodoActual = [];
    const historial = [];
    apvPeriodoResponse.forEach((element) =>
      element.periodoId.toString() === periodoId
        ? periodoActual.push(element)
        : historial.push(element)
    );
    setApvPeriodosTrabajador(periodoActual);
    setApvTrabajadorHistorial(historial);
    setCargoInfoAPVs(true)
  }, [apvPeriodoResponse]);

  useEffect(() => {
    async function obtenerCajaDosActual() {
      try {
        const trabajadorId = datosTrabajadorEditar.id;
        const response = await axiosPrivate(
          `${api_service}/api/v1/trabajadores/${trabajadorId}/cuenta2/periodo/${periodoId}`,
          Authorization
        );
        if (!response.data.length > 0) {
          setCajaDosTrabajador([]);
        } else {
          setCajaDosTrabajador(response.data);
        }
        setCargoInfoCajaDosActual(true)
      } catch (error) {
        console.log("error", error);
        toast.dismiss();
        toast.error(
          error.response.data.message
            ? error.response.data.message
            : "Error al cargar info de Cuenta Dos",
            estilosAlerta
        );
      }
    }
    obtenerCajaDosActual();
  }, []);

  useEffect(() => {
    async function obtenerCajaDosHistorial() {
      try {
        const trabajadorId = datosTrabajadorEditar.id;
        const response = await axiosPrivate(
          `${api_service}/api/v1/trabajadores/${trabajadorId}/cuenta2/historial`,
          Authorization
        );
        if (!response.data.length > 0) {
          setCajaDosTrabajadorHistorial([]);
        } else {
          setCajaDosTrabajadorHistorial(response.data);
        }
        setCargoInfoCajaDosHistorial(true)
      } catch (error) {
        console.log("error", error);
        toast.dismiss();
        toast.error(
          error.response.data.message
            ? error.response.data.message
            : "Error al cargar historial de Cuenta Dos",
            estilosAlerta
        );
      }
    }
    obtenerCajaDosHistorial();
  }, []);

  async function deleteApv(apvId) {
    try {
      toast.loading("Eliminando registro de APV", estilosAlerta);
      await axiosPrivate.delete(
        `${api_service}/api/v1/trabajadores/eliminarapv`,
        {
          data: {
            trabajadorApvPeriodoId: apvId,
          },
          headers: {
            Authorization: `Bearer ${tokenUser}`,
            ClientId: `${token}`,
          },
        }
      );
      const arrayTable = [...apvPeriodosTrabajador];
      const newArray = arrayTable.filter((apv) => apv.id !== apvId);
      setApvPeriodosTrabajador(newArray);
      toast.dismiss();
      toast.success("APV eliminado con éxito", estilosAlerta);
    } catch (error) {
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al eliminar APV",
          estilosAlerta
      );
      console.log(error);
    }
  }

  async function agregarAPV(apv) {
    if (!apv) {
      return null;
    }
    try {
      const response = await axiosPrivate.post(
        `${api_service}/api/v1/trabajadores/agregarapv`,
        apv,
        Authorization
      );
      if (response.status === 200) {
        const arrayTable = [...apvPeriodosTrabajador];
        console.log("response data", response.data);
        arrayTable.push(response.data);
        setApvPeriodosTrabajador(arrayTable);
        toast.dismiss();
        toast.success("APV guardado con éxito", estilosAlerta);
        reset();
      }
    } catch (error) {
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al agregar APV",
          estilosAlerta
      );
      console.log(error);
    }
  }

  async function deleteCajaDos(cajaId) {
    toast.loading("Eliminando registro de Caja Dos", estilosAlerta);
    try {
      await axiosPrivate.delete(
        `${api_service}/api/v1/trabajadores/cuenta2/${cajaId}`,
        {
          headers: {
            Authorization: `Bearer ${tokenUser}`,
            ClientId: `${token}`,
          },
        }
      );
      const trabajadorId = datosTrabajadorEditar.id;
      const response = await axiosPrivate(
        `${api_service}/api/v1/trabajadores/${trabajadorId}/cuenta2/periodo/${periodoId}`,
        Authorization
      );
      if (!response.data.length > 0) {
        setCajaDosTrabajador([]);
      } else {
        setCajaDosTrabajador(response.data);
      }
      toast.dismiss();
      toast.success("Registro de Caja Dos eliminado con éxito", estilosAlerta);
    } catch (error) {
      console.log("Error", error);
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al eliminar registro de Caja Dos", estilosAlerta
      );
    }
  }

  async function agregarCuentaDos(cuentaDos) {
    if (!cuentaDos) {
      return null;
    }
    toast.loading("Agregando registro de Caja Dos", estilosAlerta);
    try {
      const trabajadorId = datosTrabajadorEditar.id;
      const response = await axiosPrivate.post(
        `${api_service}/api/v1/trabajadores/${trabajadorId}/cuenta2/periodo/${periodoId}`,
        cuentaDos,
        Authorization
      );
      if (response.status === 200) {
        const response = await axiosPrivate(
          `${api_service}/api/v1/trabajadores/${trabajadorId}/cuenta2/periodo/${periodoId}`,
          Authorization
        );
        if (!response.data.length > 0) {
          setCajaDosTrabajador([]);
        } else {
          setCajaDosTrabajador(response.data);
        }
        toast.dismiss();
        toast.success("Registro de Caja Dos agregado con éxito", estilosAlerta);
        reset();
      }
    } catch (error) {
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al agregar registro de Caja Dos",
          estilosAlerta
      );
      console.log(error);
    }
  }

  const apvTableColmunsActual = [
    {
      Header: "Monto",
      accessor: (d) => {
        if (d.tipoPago === 0) {
          return "$ " + UtilsFunctions.integerFormatter(parseInt(d.valor?.replace(".", ",")));
        } else if (d.tipoPago === 1) {
          return d.valor.replace(".", ",") + " UF";
        } else if (d.tipoPago === 2) {
          return d.valor.replace(".", ",") + " %"
        }
      },
    },
    {
      Header: "Unidad",
      accessor: (d) => {
        if (d.tipoPago === 0) {
          return "Pesos";
        } else if (d.tipoPago === 1) {
          return "UF";
        } else if (d.tipoPago === 2) {
          return "% Imponible";
        }
      },
    },
    {
      Header: "Tipo",
      accessor: (d) => d.tipo,
    },
    {
      Header: "Institución",
      accessor: (d) => d.apvNombre,
    },
    {
      Header: "Num. Contrato",
      accessor: (d) => d.numeroContrato,
    },
    {
      Header: "Ind/Col",
      accessor: (d) => d.tipoContrato,
    },
  ];

  const apvTableColmunsHistorial = [
    {
      Header: "Periodo",
      accessor: (d) =>
        UtilsFunctions.periodoToText(d.mesPeriodo, d.anyoPeriodo),
    },
    {
      Header: "Monto",
      accessor: (d) => {
        if (d.tipoPago == 0) {
          return "$ " + UtilsFunctions.integerFormatter(d.valor);
        } else if (d.tipoPago == 1) {
          return d.valor.replace(".", ",") + " UF";
        } else if (d.tipoPago == 2) {
          return d.valor.replace(".", ",") + " %"
        }
      },
    },
    {
      Header: "Unidad",
      accessor: (d) => {
        if (d.tipoPago == 0) {
          return "Pesos";
        } else if (d.tipoPago == 1) {
          return "UF";
        } else if (d.tipoPago == 2) {
          return "% Imponible";
        }
      },
    },
    {
      Header: "Tipo",
      accessor: (d) => d.tipo,
    },
    {
      Header: "Institución",
      accessor: (d) => d.apvNombre,
    },
    {
      Header: "Num. Contrato",
      accessor: (d) => d.numeroContrato,
    },
    {
      Header: "Ind/Col",
      accessor: (d) => d.tipoContrato,
    },
  ];

  const caja2TableColmunsActual = [
    {
      Header: "Monto",
      accessor: (d) => {
        if (d.tipoMonto === 1) {
          return "$ " + UtilsFunctions.integerFormatter(d.monto);
        } else if (d.tipoMonto === 2) {
          return d.monto.toFixed(4).replace(".", ",") + " UF";
        } else if (d.tipoMonto === 3) {
          return d.monto.toFixed(2).replace(".", ",") + " %"
        }
      },
    },
    {
      Header: "Unidad",
      accessor: (d) => {
        if (d.tipoMonto === 1) {
          return "Pesos";
        } else if (d.tipoMonto === 2) {
          return "UF";
        } else if (d.tipoMonto === 3) {
          return "% Imponible";
        }
      },
    },
  ];

  const caja2TableColmunsHistorial = [
    {
      Header: "Periodo",
      accessor: (d) =>
        UtilsFunctions.periodoToText(d.periodoMes, d.periodoAnyo),
    },
    {
      Header: "Monto",
      accessor: (d) => "$ " + UtilsFunctions.integerFormatter(d.monto),
    },
  ];

  const loadingModal = cargoInfoAPVs && cargoInfoCajaDosActual && cargoInfoCajaDosHistorial

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none">
        <div className="segundoDivModal max-w-6xl">
          {/*content*/}
          <div className="tercerDivModal shadow-lg  max-h-[85vh]">
            <div className="divHeaderModal border-slate-200">
              {datosTrabajadorEditar.apellido_Paterno ? (
                <h3 className="titulo-Modal">
                  Editar Trabajador
                </h3>
              ) : (
                <h3 className="titulo-Modal">
                  Nuevo Trabajador
                </h3>
              )}
            </div>

            <div
              className={`relative p-6 ${!loadingModal ? "overflow-hidden" : "overflow-auto"
                } flex-auto dark:bg-hercules-dark-bg-200`}
            >
              {!loadingModal ? (
                <div className="h-fix">
                  <ModalSpinner />
                </div>
              ) : (
                <>
                  <div className="md:flex md:flex-wrap mb-4">
                    <div className="mb-6 md:mb-0 md:w-5/12 md:pr-3">
                      {datosTrabajadorEditar.apellido_Paterno ? (
                        <label className="block tracking-wide dark:text-white text-hercules-light-font-secondary font-medium mb-2">
                          Trabajador:{" "}
                          {datosTrabajadorEditar.apellido_Paterno +
                            " " +
                            datosTrabajadorEditar.apellido_Materno +
                            " " +
                            datosTrabajadorEditar.nombres}
                        </label>
                      ) : (
                        <label className="block tracking-wide dark:text-white text-hercules-light-font-secondary font-medium mb-2">
                          Trabajador:{" "}
                          {datosTrabajadorEditar.apellidoPaterno +
                            " " +
                            datosTrabajadorEditar.apellidoMaterno +
                            " " +
                            datosTrabajadorEditar.nombres}
                        </label>
                      )}
                    </div>

                    <div className="mb-9 md:mb-0 md:w-5/12 md:pl-3">
                      <label className="block tracking-wide dark:text-white text-hercules-light-font-secondary font-medium mb-5">
                        RUT: {UtilsFunctions.rutFormatter(datosTrabajadorEditar.rut, { dots: true })}
                      </label>
                    </div>
                  </div>

                  <AgregarAPVsForm
                    institucionesApv={institucionesApv}
                    datosTrabajadorEditar={datosTrabajadorEditar}
                    sendFormData={(apv) => agregarAPV(apv)}
                  />

                  {apvPeriodosTrabajador.length > 0 ||
                    apvTrabajadorHistorial.length > 0 ? (
                    <>
                      <label
                        className="mt-3 md:mt-0 block tracking-wide dark:text-white text-hercules-light-font-secondary text-base font-semibold mb-2"
                        htmlFor="grid-last-name"
                      >
                        APV Trabajador Periodo {periodoLogin}
                      </label>
                      {apvPeriodosTrabajador.length ? (
                        <SimpleTable
                          col={apvTableColmunsActual}
                          tableData={apvPeriodosTrabajador}
                          primaryButton={{
                            type: "eliminar",
                            onClickFunc: (data) => {
                              deleteApv(data.id);
                            },
                            active: true,
                          }}
                          tableHeight="h-auto"
                          searchBar={false}
                          showFilters={false}
                          hasPagination={false}
                        />
                      ) : (
                        <p className=" dark:text-white text-hercules-light-font-secondary text-sm font-medium ">
                          Este trabajador no tiene APV en este periodo
                        </p>
                      )}

                      <label
                        className="block dark:text-white text-hercules-light-font-secondary tracking-wid text-base font-semibold mt-9 mb-2"
                        htmlFor="grid-last-name"
                      >
                        Historial APV Trabajador
                      </label>
                      {apvTrabajadorHistorial.length ? (
                        <SimpleTable
                          col={apvTableColmunsHistorial}
                          tableData={apvTrabajadorHistorial}
                          searchBar={false}
                          showFilters={false}
                          hasPagination={false}
                          tableHeight="h-auto"
                        />
                      ) : (
                        <p className=" dark:text-white text-hercules-light-font-secondary text-sm font-medium ">
                          Este trabajador no tiene Historial de APV
                        </p>
                      )}
                    </>
                  ) : (
                    <p className=" dark:text-white text-hercules-light-font-secondary text-sm font-medium ">
                      Este trabajador no tiene registros de APV
                    </p>
                  )}

                  <AgregarCuentaDosForm
                    sendFormData={(apv) => agregarCuentaDos(apv)}
                  />

                  {cajaDosTrabajador.length > 0 ||
                    cajaDosTrabajadorHistorial.length > 0 ? (
                    <>
                      <label
                        className="mt-3 md:mt-0 block tracking-wide dark:text-white text-hercules-light-font-secondary text-base font-semibold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Cuenta dos Trabajador Periodo {periodoLogin}
                      </label>
                      {cajaDosTrabajador.length ? (
                        <SimpleTable
                          col={caja2TableColmunsActual}
                          tableData={cajaDosTrabajador}
                          primaryButton={{
                            type: "eliminar",
                            onClickFunc: (data) => {
                              deleteCajaDos(data.id);
                            },
                            active: true,
                          }}
                          tableHeight="h-auto"
                          searchBar={false}
                          showFilters={false}
                          hasPagination={false}
                        />
                      ) : (
                        <p className=" dark:text-white text-hercules-light-font-secondary text-sm font-medium ">
                          Este trabajador no tiene Cuenta dos en este periodo
                        </p>
                      )}

                      <label
                        className="block dark:text-white text-hercules-light-font-secondary tracking-wid text-base font-semibold mt-9 mb-2"
                        htmlFor="grid-last-name"
                      >
                        Historial Cuenta dos Trabajador
                      </label>
                      {cajaDosTrabajadorHistorial.length ? (
                        <SimpleTable
                          col={caja2TableColmunsHistorial}
                          tableData={cajaDosTrabajadorHistorial}
                          searchBar={false}
                          showFilters={false}
                          hasPagination={false}
                          tableHeight="h-auto"
                        />
                      ) : (
                        <p className=" dark:text-white text-hercules-light-font-secondary text-sm font-medium ">
                          Este trabajador no tiene Historial de Cuenta dos
                        </p>
                      )}
                    </>
                  ) : (
                    <p className=" dark:text-white text-hercules-light-font-secondary text-sm font-medium ">
                      Este trabajador no tiene registros de Cuenta dos
                    </p>
                  )}
                </>
              )}
            </div>

            {/* Footer */}
            <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                type="button"
                onClick={() => onClose()}
              >
                Cerrar
              </button>
              <button
                className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 ease-in-out transition-all duration-400"
                type="button"
                onClick={() =>
                  changePage(datosTrabajadorEditar, "2", institucionesApv)
                }
              >
                Gastos Previsionales
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-30 bg-black"></div>
      {/* </form> */}
    </>
  );
}
