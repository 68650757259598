import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCargos,
  hideLoader,
  limpiarInfoMenuEdicion,
  showLoader,
} from "../../Redux/Actions";
import { useStateContext } from "../../contexts/ContextProvider";
import { ColumnFilter, EditarCargo, NuevoCargo } from "../../components";
import UtilsFunctions from "../../utils/UtilsFunctions";
import SimpleTable from "../../components/SimpleTable";
import ColumnFilterOptions from "../../components/ColumnFilterOptions";

const Cargos = () => {
  const cargos = useSelector((state) => state.getCargos);

  const [showModalNuevoCargo, setShowModalNuevoCargo] = useState(false);
  const [showModalEditarCargo, setshowModalEditarCargo] = useState(false);
  const { rowDataCargos, setRowDataCargos } = useStateContext();
  const { refreshTable, activeMenu } = useStateContext();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loader);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader());
      await Promise.all([dispatch(getCargos())]);
      dispatch(hideLoader());
    };
    fetchData().catch((error) => {
      console.log(error.response.data.message);
      dispatch(hideLoader());
    });
  }, [refreshTable, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("getCargos"));
    };
  }, []);

  const COLUMNS = [
    {
      Header: "Nombre",
      accessor: "nombre",
      Filter: ColumnFilter,
    },
    {
      Header: "Descripcion",
      accessor: "observacion",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value === null) {
          return "Sin descripción";
        } else {
          return value;
        }
      },
    },
    {
      Header: "Activo",
      accessor: "activo",
      Filter: ColumnFilterOptions,
      Cell: ({ value }) => {
        if (value === true) {
          return "Si";
        } else {
          return "No";
        }
      },
    },
  ];

  const headersCsv = [
    {
      key: "nombre",
      name: "Nombre",
    },
    {
      key: "observacion",
      name: "Descripcion",
    },
    {
      key: "activo",
      name: "Activo",
    },
  ];

  return (
    <div
      className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
        activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">Edición</div>
      <div className="titulo-Pagina">Cargos</div>
      <div className="h-full md:mr-12 rounded-lg pt-5">
        <SimpleTable
          col={COLUMNS}
          tableData={cargos || []}
          primaryButton={{
            type: "editar",
            onClickFunc: (data) => {
              setshowModalEditarCargo(true);
              setRowDataCargos(data);
            },
            active: true,
          }}
          exportCsv={() =>
            UtilsFunctions.jsonToCSV3(cargos, headersCsv, "Cargos")
          }  
        />

        <button
          onClick={() => setShowModalNuevoCargo(true)}
          disabled={isLoading}
          className={`mt-3 ${
            isLoading
              ? "bg-gray-500"
              : "bg-hercules-light-primary-button hover:shadow-lg"
          } text-white dark:text-dark-primary font-medium uppercase text-normal px-4 py-3 rounded shadow  outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
        >
          Nuevo Cargo
        </button>
      </div>
      {showModalNuevoCargo && (
        <NuevoCargo
          isVisible={showModalNuevoCargo}
          onClose={() => setShowModalNuevoCargo(false)}
        />
      )}
      {showModalEditarCargo && (
        <EditarCargo
          isVisible={showModalEditarCargo}
          onClose={() => setshowModalEditarCargo(false)}
          modalData={rowDataCargos}
        />
      )}
    </div>
  );
};

export default Cargos;
