const initialState = {
  holding: [],
  infoHolding: [],
  empresas: [],
  empresasv2: [],
  getListaEmpresas: [],
  // allEmpresas: [],
  periodosEmpresa: [],
  roles: [],
  rolesActivos: [],
  rolMenu: [],
  listaUsuarios: [],
  repLegales: [],
  repLegalesActivos: [],
  empresasTabla: [],
  paises: [],
  regiones: [],
  comunas: [],
  ciudades: [],
  ccaf: [],
  mutuales: [],
  ccafEmpresa: [],
  mutualEmpresa: [],
  trabajadores: [],
  datosTrabajador: [],
  afpTrabajador: [],
  listaApv: [],
  listaAfp: [],
  listaPeriodos: [],
  listaPeriodosHastaActual: [],
  tasaAfpPeriodo: [],
  listaSalud: [],
  tasaSaludPeriodo: [],
  tablaSalud: [],
  datosTrabajadorCarga: [],
  tramosCargaFamiliar: [],
  tasaCFamiliarPeriodo: [],
  getJornadas: [],
  getTipoTrabajador: [],
  sueldoMinimo: [],
  sueldoMinimoPeriodo: [],
  getHsExtra: [],
  getHsExtraActivadas: [],
  getHaberes: [],
  getHaberesActivos: [],
  getDescuentosBasicos: [],
  getHaberesDT: [],
  getDescuentos: [],
  getDescuentosActivos: [],
  getDescuentosDT: [],
  getContratos: [],
  getContratosResumen: [],
  getTrabajadoresSinContratos: [],
  getCargos: [],
  getCentroCostos: [],
  getSecciones: [],
  listaSaludPeriodo: [],
  parametrosPeriodo: [],
  rtasTopeImponible: [],
  rtasMinPeriodo: [],
  topeAPV: [],
  trabajosPesados: [],
  listaAFPPeriodosTasa: [],
  afcPeriodo: [],
  impuestosRenta: [],
  tramoAsignacionFamiliar: [],
  getBancos: [],
  getCargos: [],
  getCausalesTermContrato: [],
  getSindicatos: [],
  getSindicatosActivosVisibles: [],
  getSindicatosVisibles: [],
  getSindicatosActivos: [],
  movimientosPersonal: [],
  pagadoresSubsidio: [],
  ips: [],
  factoresActualizacion: [],
  contratosMovTrabLista: [],
  contratosSemCorrida: [],
  contratosSemCorridaPorEmpresa: [],
  trabajadoresContratoVigente: [],
  descuentosCuotasProgramados: [],
  periodosAnteriores: [],
  liquidacionPeriodoCCosto: [],
  trabajadoresCalculoLiquidaciones: [],
  descuentosPeriodoCCosto: [],
  remuneracionesMensual: [],
  nacionalidades: [],
  centrosCostoEmpresa: [],
  certificadoSueldo: [],
  causalesFiniquito: [],
  obtenerFiniquitos: [],
  movimientosTrabajadorPeriodo: [],
  loader: false,
  // liquidacionesPorCentroCostoPeriodo: [],
  totalTrabajadores: [],
  contratosVigentes: [],
  contratosVencidos: [],
  contratosFiniquitados: [],
  totalLiquidacionesPeriodo: [],
  totalLiquidacionesUltimosMeses: [],
  totalAportesUltimosMeses: [],
  totalHsExtrasUltimosMeses: [],
  trabajadoresActivos: [],
  infoSolicitudVacacionesTabla: [],
  infoPeriodosVacacionesTrabajador: [],
  infoRegistrosVacacionesCCosto: [],
  infoSolicitudesVacacionesCCosto: [],
  infoSolicitudesAprobadasYPendientes: [],
  infoRegistrosVacacionesTrabajador: [],
  feriadosCentroCosto: [],
  arrayModulosHolding: [],
  ccostoUsuarios: [],
  modulosDisponiblesUsuario: [],
  infoDashboardVacaciones: [],
  infoPeriodosDisponiblesVacacionesTrabajador: [],
  infoRegistrosCargasFamiliaresPeriodo: [],
  infoRegistrosCreditosCCAFPeriodo: [],
  trabajadoresCambioJornada: [],
  trabajadoresEmpresa: [],
  contratosEmpresa: [],
  trabajadoresNominaBancaria: [],
  empresaGratificacion: [],
  getGratificaciones: [],
  getEventos: [],
  getEventosActivos: [],
  getEventosTrabajador: [],
  getDescuentosFijosTrabajador: [],
  getHistorialTrabajadorDescuentosFijosEmpresa: [],
  getSolicitudesVacacionesTrabajador: [],
  infoHorasExtrasCanjeadasEmpresa: [],
  infoAdministrarCanjesHsExtraTrabajador: [],
  infoHistorialCanjesHSExtraUtilizadasTrabajador: [],
  infoRegistroFamiliarTrabajador: [],
  infoPlantillasEmpresa: [],
  infoAtributosContratos: [],
  infoAtributosAnexoContrato: [],
  infoPlantillasConfiguradasEmpresa: [],
};

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case "SHOW_LOADER":
      return {
        ...state,
        loader: true,
      };
    case "HIDE_LOADER":
      return {
        ...state,
        loader: false,
      };
    case "GET_HOLDINGS":
      return {
        ...state,
        holding: action.payload,
      };

    case "INFO_HOLDING":
      return {
        ...state,
        infoHolding: action.payload,
      };

    case "GET_EMPRESAS":
      return {
        ...state,
        empresas: action.payload,
        // allEmpresas: action.payload,
      };

    case "GET_EMPRESASV2":
      return {
        ...state,
        empresasv2: action.payload,
      };

    case "GET_LISTA_EMPRESAS":
      return {
        ...state,
        getListaEmpresas: action.payload,
      };

    case "POST_PERIODO_EMPRESAS":
      return {
        ...state,
        periodosEmpresa: action.payload,
      };

    case "GET_ROLES":
      return {
        ...state,
        roles: action.payload,
      };

    case "GET_ROLES_ACTIVOS":
      return {
        ...state,
        rolesActivos: action.payload,
      };

    case "POST_ROL_MENU":
      return {
        ...state,
        rolMenu: action.payload,
      };
    case "GET_NACIONALIDADES":
      return {
        ...state,
        nacionalidades: action.payload,
      };

    case "LISTAR_USUARIOS":
      return {
        ...state,
        listaUsuarios: action.payload,
      };

    case "FILTRAR_USUARIOS":
      return {
        ...state,
        listaUsuarios: action.payload,
      };

    case "GET_REP":
      return {
        ...state,
        repLegales: action.payload,
      };

    case "GET_REP_LEGALES_ACTIVOS":
      return {
        ...state,
        repLegalesActivos: action.payload,
      };

    case "GET_EMPRESAS_TABLA":
      return {
        ...state,
        empresasTabla: action.payload,
      };

    case "GET_PAISES":
      return {
        ...state,
        paises: action.payload,
      };

    case "GET_REGIONES":
      return {
        ...state,
        regiones: action.payload,
      };
    case "GET_COMUNAS":
      return {
        ...state,
        comunas: action.payload,
      };
    case "GET_CIUDADES":
      return {
        ...state,
        ciudades: action.payload,
      };
    case "LIMPIAR_COMUNAS":
      return {
        ...state,
        comunas: action.payload,
      };

    case "LIMPIAR_CIUDADES":
      return {
        ...state,
        ciudades: action.payload,
      };
    case "GET_CCAF":
      return {
        ...state,
        ccaf: action.payload,
      };
    case "GET_MUTUALES":
      return {
        ...state,
        mutuales: action.payload,
      };

    case "CCAF_EMPRESA":
      return {
        ...state,
        ccafEmpresa: action.payload,
      };

    case "MUTUAL_EMPRESA":
      return {
        ...state,
        mutualEmpresa: action.payload,
      };

    case "GET_TRABAJADORES":
      return {
        ...state,
        trabajadores: action.payload,
      };

    case "OBTENER_TRABAJADOR":
      return {
        ...state,
        trabajador: action.payload,
      };

    case "LIMPIAR_TRABAJADOR":
      return {
        ...state,
        trabajador: null,
      };

    case "DATOS_TRABAJADOR":
      return {
        ...state,
        datosTrabajador: action.payload,
      };

    case "AFP_TRABAJADOR":
      return {
        ...state,
        afpTrabajador: action.payload,
      };

    case "LISTA_APV":
      return {
        ...state,
        listaApv: action.payload,
      };

    case "LISTA_AFP":
      return {
        ...state,
        listaAfp: action.payload,
      };

    case "LISTA_PERIODOS":
      return {
        ...state,
        listaPeriodos: action.payload,
      };

    case "LISTA_PERIODOS_HASTA_ACTUAL":
      return {
        ...state,
        listaPeriodosHastaActual: action.payload,
      };

    case "TASA_AFP_PERIODO":
      return {
        ...state,
        tasaAfpPeriodo: action.payload,
      };

    case "LISTA_SALUD":
      return {
        ...state,
        listaSalud: action.payload,
      };

    case "TASA_SALUD_PERIODO":
      return {
        ...state,
        tasaSaludPeriodo: action.payload,
      };

    case "TABLA_SALUD":
      return {
        ...state,
        tablaSalud: action.payload,
      };

    case "DATOS_TRABAJADOR_CARGA":
      return {
        ...state,
        datosTrabajadorCarga: action.payload,
      };
    case "TRAMOS_CARGA_FAMILIAR":
      return {
        ...state,
        tramosCargaFamiliar: action.payload,
      };

    case "TASA_CARGA_PERIODO":
      return {
        ...state,
        tasaCFamiliarPeriodo: action.payload,
      };

    case "GET_JORNADAS":
      return {
        ...state,
        getJornadas: action.payload,
      };

    case "GET_TIPO_TRABAJADOR":
      return {
        ...state,
        getTipoTrabajador: action.payload,
      };

    case "GET_SUELDO_MINIMO":
      return {
        ...state,
        sueldoMinimo: action.payload,
      };

    case "GET_SUELDO_MINIMO_PERIODO":
      return {
        ...state,
        sueldoMinimoPeriodo: action.payload,
      };

    case "GET_HS_EXTRA":
      return {
        ...state,
        getHsExtra: action.payload,
      };

    case "GET_HS_EXTRA_ACTIVADAS":
      return {
        ...state,
        getHsExtraActivadas: action.payload,
      };

    case "GET_HABERES":
      return {
        ...state,
        getHaberes: action.payload,
      };

    case "GET_HABERES_ACTIVOS":
      return {
        ...state,
        getHaberesActivos: action.payload,
      };

    case "GET_DESCUENTOS_BASICOS":
      return {
        ...state,
        getDescuentosBasicos: action.payload,
      };

    case "GET_HABERESDT":
      return {
        ...state,
        getHaberesDT: action.payload,
      };

    case "GET_DESCUENTOS":
      return {
        ...state,
        getDescuentos: action.payload,
      };

    case "GET_DESCUENTOS_ACTIVOS":
      return {
        ...state,
        getDescuentosActivos: action.payload,
      };

    case "GET_DESCUENTOSDT":
      return {
        ...state,
        getDescuentosDT: action.payload,
      };

    case "GET_CONTRATOS":
      return {
        ...state,
        getContratos: action.payload,
      };

    case "GET_CONTRATOS_RESUMEN":
      return {
        ...state,
        getContratosResumen: action.payload,
      };

    case "GET_TRABAJADORES_SIN_CONTRATOS":
      return {
        ...state,
        getTrabajadoresSinContratos: action.payload,
      };
    case "GET_CARGOS":
      return {
        ...state,
        getCargos: action.payload,
      };
    case "GET_CENTRO_COSTOS":
      return {
        ...state,
        getCentroCostos: action.payload,
      };
    case "GET_SECCIONES":
      return {
        ...state,
        getSecciones: action.payload,
      };
    case "LISTA_SALUD_PERIODO":
      return {
        ...state,
        listaSaludPeriodo: action.payload,
      };
    case "PARAMETROS_PERIODO":
      return {
        ...state,
        parametrosPeriodo: action.payload,
      };
    case "LISTA_AFP_PERIODOS_TASA":
      return {
        ...state,
        listaAFPPeriodosTasa: action.payload,
      };
    case "RTAS_TOPE_IMPONIBLE":
      return {
        ...state,
        rtasTopeImponible: action.payload,
      };
    case "RTAS_MIN_PERIODO":
      return {
        ...state,
        rtasMinPeriodo: action.payload,
      };
    case "TOPE_APV":
      return {
        ...state,
        topeAPV: action.payload,
      };

    case "TRABAJOS_PESADOS":
      return {
        ...state,
        trabajosPesados: action.payload,
      };

    case "AFC_PERIODO":
      return {
        ...state,
        afcPeriodo: action.payload,
      };
    case "IMPUESTOS_RENTA":
      return {
        ...state,
        impuestosRenta: action.payload,
      };

    case "TRAMO_ASIGNACION_FAMILIAR":
      return {
        ...state,
        tramoAsignacionFamiliar: action.payload,
      };

    case "LIMPIAR_INDICADORES_PREVISIONALES":
      return {
        ...state,
        parametrosPeriodo: [],
        listaAFPPeriodosTasa: [],
        rtasTopeImponible: [],
        rtasMinPeriodo: [],
        topeAPV: [],
        trabajosPesados: [],
        afcPeriodo: [],
        impuestosRenta: [],
        tramoAsignacionFamiliar: [],
      };

    case "GET_BANCOS":
      return {
        ...state,
        getBancos: action.payload,
      };

    case "GET_CAUSALES_TERM_CONTRATO":
      return {
        ...state,
        getCausalesTermContrato: action.payload,
      };

    case "GET_SINDICATOS":
      return {
        ...state,
        getSindicatos: action.payload,
      };
    case "GET_SINDICATOS_ACTIVOS_VISIBLES":
      return {
        ...state,
        getSindicatosActivosVisibles: action.payload,
      };

    case "GET_SINDICATOS_VISIBLES":
      return {
        ...state,
        getSindicatosVisibles: action.payload,
      };

    case "SINDICATOS_ACTIVOS":
      // console.log("recibo", action.payload)
      return {
        ...state,
        getSindicatosActivos: action.payload,
      };

    case "MOVIMIENTOS_PERSONAL":
      return {
        ...state,
        movimientosPersonal: action.payload,
      };

    case "PAGADORES_SUBSIDIO":
      return {
        ...state,
        pagadoresSubsidio: action.payload,
      };

    case "IPS":
      return {
        ...state,
        ips: action.payload,
      };

    case "FACTORES_ACTUALIZACION":
      return {
        ...state,
        factoresActualizacion: action.payload,
      };

    case "CONTRATOS_MOV_TRAB_LISTA":
      return {
        ...state,
        contratosMovTrabLista: action.payload,
      };

    case "CONTRATOS_SEM_CORRIDA":
      return {
        ...state,
        contratosSemCorrida: action.payload,
      };
    case "CONTRATOS_SEM_CORRIDA_EMPRESA":
      return {
        ...state,
        contratosSemCorridaPorEmpresa: action.payload,
      };

    case "TRABAJADORES_CONTRATO_VIGENTE":
      return {
        ...state,
        trabajadoresContratoVigente: action.payload,
      };

    case "DESCUENTOS_CUOTAS_PROGRAMADOS":
      return {
        ...state,
        descuentosCuotasProgramados: action.payload,
      };

    case "PERIODOS_ANTERIORES":
      return {
        ...state,
        periodosAnteriores: action.payload,
      };

    case "LIQUIDACION_PERIODO_CCOSTO":
      return {
        ...state,
        liquidacionPeriodoCCosto: action.payload,
      };

    case "TRABAJADORES_CALCULO_LIQUIDACIONES":
      return {
        ...state,
        trabajadoresCalculoLiquidaciones: action.payload,
      };

    case "DESCUENTO_PERIODO_CCOSTO":
      return {
        ...state,
        descuentosPeriodoCCosto: action.payload,
      };
    case "REMUNERACIONES_MENSUAL":
      console.log("recibo", action.payload);
      return {
        ...state,
        remuneracionesMensual: action.payload.trabajadores,
      };

    case "GET_CENTRO_COSTO_EMPRESA":
      return {
        ...state,
        centrosCostoEmpresa: action.payload,
      };

    case "OBTENER_PERIODO_ID":
      return {
        ...state,
        periodoIdObtenido: action.payload,
      };

    case "CERTIFICADO_SUELDO":
      return {
        ...state,
        certificadoSueldo: action.payload,
      };
    case "CAUSALES_FINIQUITO":
      return {
        ...state,
        causalesFiniquito: action.payload,
      };

    case "OBTENER_FINIQUITOS":
      return {
        ...state,
        obtenerFiniquitos: action.payload,
      };

    case "MOVIMIENTOS_TRABAJADOR_PERIODO":
      return {
        ...state,
        movimientosTrabajadorPeriodo: action.payload,
      };

    case "TOTAL_TRABAJADORES":
      return {
        ...state,
        totalTrabajadores: action.payload,
      };

    case "CONTRATOS_VIGENTES":
      return {
        ...state,
        contratosVigentes: action.payload,
      };

    case "CONTRATOS_VENCIDOS":
      return {
        ...state,
        contratosVencidos: action.payload,
      };

    case "CONTRATOS_FINIQUITADOS":
      return {
        ...state,
        contratosFiniquitados: action.payload,
      };

    case "TOTAL_LIQUIDACIONES_PERIODO":
      return {
        ...state,
        totalLiquidacionesPeriodo: action.payload,
      };

    case "TOTAL_LIQUIDACIONES_ULTIMOS_MESES":
      return {
        ...state,
        totalLiquidacionesUltimosMeses: action.payload,
      };

    case "TOTAL_APORTES_ULTIMOS_MESES":
      return {
        ...state,
        totalAportesUltimosMeses: action.payload,
      };

    case "TOTAL_HS_EXTRAS_ULTIMOS_MESES":
      return {
        ...state,
        totalHsExtrasUltimosMeses: action.payload,
      };

    case "LISTA_TRABAJADORES_ACTIVOS":
      return {
        ...state,
        trabajadoresActivos: action.payload,
      };

    case "SOLICITUD_VACACIONES_TABLA":
      return {
        ...state,
        infoSolicitudVacacionesTabla: action.payload,
      };

    case "GET_TRABAJADORES_EMPRESA":
      return {
        ...state,
        trabajadoresEmpresa: action.payload,
      };

    case "CONTRATOS_POR_EMPRESA":
      return {
        ...state,
        contratosEmpresa: action.payload,
      };

    case "GET_TRABAJADORES_NOMINA_BANCARIA":
      return {
        ...state,
        trabajadoresNominaBancaria: action.payload,
      };

    case "PERIODOS_VACACIONES_TRABAJADOR":
      return {
        ...state,
        infoPeriodosVacacionesTrabajador: action.payload,
      };

    case "REGISTROS_VACACIONES_CCOSTO":
      return {
        ...state,
        infoRegistrosVacacionesCCosto: action.payload,
      };

    case "SOLICITUDES_VACACIONES_CCOSTO":
      return {
        ...state,
        infoSolicitudesVacacionesCCosto: action.payload,
      };

    case "SOLICITUDES_APROBADAS_Y_PENDIENTES":
      return {
        ...state,
        infoSolicitudesAprobadasYPendientes: action.payload,
      };

    case "REGISTROS_VACACIONES_TRABAJADOR":
      return {
        ...state,
        infoRegistrosVacacionesTrabajador: action.payload,
      };

    case "FERIADOS_CENTRO_COSTO":
      return {
        ...state,
        feriadosCentroCosto: action.payload,
      };

    case "OBTENER_HORAS_EXTRAS_CANJEADAS_EMPRESA":
      return {
        ...state,
        infoHorasExtrasCanjeadasEmpresa: action.payload,
      };

    case "MODULOS_HOLDING":
      return {
        ...state,
        arrayModulosHolding: action.payload,
      };

    case "LISTADO_CCOSTO":
      return {
        ...state,
        ccostoUsuarios: action.payload,
      };

    case "LISTADO_MODULOS_DISPONIBLES":
      return {
        ...state,
        modulosDisponiblesUsuario: action.payload,
      };

    case "DASHBOARD_VACACIONES":
      return {
        ...state,
        infoDashboardVacaciones: action.payload,
      };

    case "PERIODOS_DISPONIBLES_VACACIONES_TRABAJADOR":
      return {
        ...state,
        infoPeriodosDisponiblesVacacionesTrabajador: action.payload,
      };

    case "REGISTROS_CARGAS_FAMILIARES_PERIODO":
      return {
        ...state,
        infoRegistrosCargasFamiliaresPeriodo: action.payload,
      };

    case "REGISTROS_CREDITOS_CCAF_PERIODO":
      return {
        ...state,
        infoRegistrosCreditosCCAFPeriodo: action.payload,
      };

    case "GET_GRATIFICACION_EMPRESA":
      return {
        ...state,
        empresaGratificacion: action.payload,
      };

    case "LISTA_TRABAJADORES_CAMBIO_JORNADA":
      return {
        ...state,
        trabajadoresCambioJornada: action.payload,
      };

    case "GET_GRATIFICACIONES":
      return {
        ...state,
        getGratificaciones: action.payload,
      };

    case "GET_EVENTOS":
      return {
        ...state,
        getEventos: action.payload,
      };

    case "GET_EVENTOS_ACTIVOS":
      return {
        ...state,
        getEventosActivos: action.payload,
      };

    case "GET_EVENTOS_TRABAJADOR":
      return {
        ...state,
        getEventosTrabajador: action.payload,
      };

    case "GET_DESCUENTOS_FIJOS_TRABAJADOR":
      return {
        ...state,
        getDescuentosFijosTrabajador: action.payload,
      };

    case "GET_HISTORIAL_DESCUENTOS_TRABAJADOR_EMPRESA":
      return {
        ...state,
        getHistorialTrabajadorDescuentosFijosEmpresa: action.payload,
      };

    case "SOLICITUDES_VACACIONES_TRABAJADOR":
      return {
        ...state,
        infoSolicitudesVacacionesTrabajador: action.payload,
      };

    case "ADMINISTRAR_CANJES_HORAS_EXTRA_TRABAJADOR":
      return {
        ...state,
        infoAdministrarCanjesHsExtraTrabajador: action.payload,
      };

    case "OBTENER_HISTORIAL_TRABAJADOR_CANJEHSEXTRA_UTILIZADAS":
      return {
        ...state,
        infoHistorialCanjesHSExtraUtilizadasTrabajador: action.payload,
      };

    case "GET_REGISTRO_FAMILIAR_TRABAJADOR":
      return {
        ...state,
        infoRegistroFamiliarTrabajador: action.payload,
      };
      
      case "PLANTILLAS_EMPRESA":
        return {
          ...state,
          infoPlantillasEmpresa: action.payload,
        };
        
        case "PLANTILLAS_CONFIGURADAS_EMPRESA":
          return {
            ...state,
            infoPlantillasConfiguradasEmpresa: action.payload,
          };
  
        case "ATRIBUTOS_CONTRATOS":
          return {
            ...state,
            infoAtributosContratos: action.payload,
          };
  
          case "ATRIBUTOS_ANEXO_CONTRATO":
            return {
              ...state,
              infoAtributosAnexoContrato: action.payload,
            };

    case "LIMPIAR_INFO_EDICION":
      const estado = action.payload;
      return {
        ...state,
        [estado]: [],
      };

    case "LIMPIAR_REP_LEGALES":
      return {
        ...state,
        repLegales: [],
      };

    default:
      return state;
  }
}

export default rootReducer;
