import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHaberes, hideLoader, limpiarInfoMenuEdicion, showLoader } from "../../Redux/Actions";
import { ColumnFilter, ColumnFilterBoolean } from "../../components";
import { useStateContext } from "../../contexts/ContextProvider";
import UtilsFunctions from "../../utils/UtilsFunctions";
import MantenedorNuevoHaber from "../../components/Edicion/MantenedorHaberes/MantenedorNuevoHaber";
import MantenedorEditarHaber from "../../components/Edicion/MantenedorHaberes/MantenedorEditarHaber";
import SimpleTable from "../../components/SimpleTable";

export default function MantenedorHaberes() {
  const [showModalNuevoHaber, setShowModalNuevoHaber] = useState(false);
  const [showModalEditarHaber, setShowModalEditarHaber] = useState(false);
  const [haberData, setHaberData] = useState(null);
  const { refreshTable, activeMenu } = useStateContext();
  const dispatch = useDispatch();
  const haberes = useSelector((state) => state.getHaberes);
  const isLoading = useSelector((state) => state.loader);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader());
      await Promise.all([
        dispatch(getHaberes()),
        ]);
      dispatch(hideLoader());
    };

    fetchData().catch((error) => {
      console.log(error.response.data.message);
      dispatch(hideLoader());
    });
  }, [refreshTable, dispatch]);

  useEffect(() =>{
    return () => {
      dispatch(limpiarInfoMenuEdicion("getHaberes"))
    }
  },[])

  const COLUMNS = [
    {
      Header: "ID",
      accessor: "id",
      Filter: ColumnFilter,
    },
    {
      Header: "Nombre",
      accessor: "nombre",
      Filter: ColumnFilter,
    },
    {
      Header: "Categoria L.R.E",
      accessor: "haberesDt.glosa",
      Filter: ColumnFilter,
    },
    {
      Header: "Cod. L.R.E",
      accessor: "haberesDt.codigo",
      Filter: ColumnFilter,
    },
    {
      Header: "Imponible",
      accessor: "haberesDt.imponible",
      Filter: ColumnFilterBoolean,
      Cell: ({ value }) => {
        if (value === true) {
          return "Si";
        } else {
          return "No";
        }
      },
    },
    {
      Header: "Tributable",
      accessor: "haberesDt.tributable",
      Filter: ColumnFilterBoolean,
      Cell: ({ value }) => {
        if (value === true) {
          return "Si";
        } else {
          return "No";
        }
      },
    },
    {
      Header: "Constituye remuneración",
      accessor: "haberesDt.constituyeRemuneracion",
      Filter: ColumnFilterBoolean,
      Cell: ({ value }) => {
        if (value === true) {
          return "Si";
        } else {
          return "No";
        }
      },
    },
    {
      Header: "Habilitado",
      accessor: "activo",
      Filter: ColumnFilterBoolean,
      Cell: ({ value }) => {
        if (value === true) {
          return "Si";
        } else {
          return "No";
        }
      },
    },
  ];

  const headersCsv = [
    {
      key: "id",
      name: "ID",
    },
    {
      key: "nombre",
      name: "Nombre",
    },
    {
      key: "haberesDt.glosa",
      name: "Categoria L.R.E",
    },
    {
      key: "haberesDt.codigo",
      name: "Cod. L.R.E",
    },
    {
      key: "haberesDt.imponible",
      name: "Imponible",
    },
    {
      key: "haberesDt.tributable",
      name: "Tributable",
    },
    {
      key: "haberesDt.constituyeRemuneracion",
      name: "Constituye remuneracion",
    },
    {
      key: "activo",
      name: "Habilitado",
    },
  ];

  return (
    <>
      <div
      className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
        activeMenu && "md:w-[calc(100vw_-_23rem)]"
        }`}
      >
      <div className="titulo-Menu">
          Edición
        </div>
        <div className="titulo-Pagina">
          Haberes
        </div>
        <div className="h-full md:mr-12 rounded-lg pt-5">
            <SimpleTable
              col={COLUMNS}
              tableData={haberes || []}
              primaryButton={{
                type: "editar",
                onClickFunc: (data) => {
                  setShowModalEditarHaber(true);
                  setHaberData(data);
                },
                active: true,
              }}
              exportCsv={() =>
                UtilsFunctions.jsonToCSV3(haberes, headersCsv, "Haberes")
              }
              hasPagination={true}
            />
          <button
            onClick={() => setShowModalNuevoHaber(true)}
            disabled={isLoading}
            className={`mt-3 ${isLoading? "bg-gray-500" : "bg-hercules-light-primary-button hover:shadow-lg"} text-white dark:text-dark-primary font-medium uppercase text-normal px-4 py-3 rounded shadow  outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}  
          >
            Nuevo Haber
          </button>
        </div>
      </div>
      {showModalNuevoHaber &&
      <MantenedorNuevoHaber
        isVisible={showModalNuevoHaber}
        onClose={() => setShowModalNuevoHaber(false)}
      />
    }
      {showModalEditarHaber &&
      <MantenedorEditarHaber
        isVisible={showModalEditarHaber}
        onClose={() => setShowModalEditarHaber(false)}
        modalData={haberData}
      />
    }
    </>
  );
}
