import axios from "axios";
import { memoizedRefreshToken } from "./refreshToken";

const api_service = process.env.REACT_APP_API_HERCULES_SERVICE

const instance = axios.create({
    baseURL: api_service
  });

instance.interceptors.request.use(
    async (config) => {
        const authToken = localStorage.getItem("token-user");

        if (authToken) {
            config.headers = {
                ...config.headers,
                authorization: `Bearer ${authToken}`,
            };
        }

        return config;
    },
    (error) => {
        Promise.reject(error);
    }
)

instance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const config = error?.config;
        
        if (localStorage.getItem("token-user") === null) {
            return Promise.reject(error);
        }

        if (localStorage.getItem("refresh-token") === null) {
            return Promise.reject(error);
        }

        if (error?.response?.status === 403) {
            return Promise.reject(error);
        }

        if (error?.response?.status === 401 && !config?.sent) {
            config.sent = true;

            const result = await memoizedRefreshToken();

            if (result) {
                config.headers = {
                    ...config.headers,
                    authorization: `Bearer ${result}`,
                };
            }

            return instance(config);
        }

        return Promise.reject(error);
    }
);

export const axiosPrivate = instance;