const Loader = () => {
    let circleCommonClasses = ' h-2.5 w-2.5 bg-blue-600 rounded-full my-auto';

    return (
        <>
   <div className='flex absolute w-full justify-center inset-0 h-full z-50'>
        <div className={`${circleCommonClasses} mr-1 animate-bounce`}></div>
        <div className={`${circleCommonClasses} mr-1 animate-bounce200`}></div>
        <div className={`${circleCommonClasses} animate-bounce400`}></div>
   </div>
   <div className="opacity-50 absolute inset-0 z-40 bg-white"></div>
        </>
    );
};

export default Loader;