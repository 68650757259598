import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useStateContext } from "../../../contexts/ContextProvider";
import { useDispatch, useSelector } from "react-redux";
import {
  limpiarInfoMenuEdicion,
  obtenerAtributosAnexoContrato,
  obtenerAtributosContrato,
} from "../../../Redux/Actions";
import toast from "react-hot-toast";
import { axiosPrivate } from "../../../common/axiosPrivate";
import Skeleton from "../../Skeleton";

export default function ConfigurarPlantilla({
  plantillaEditar,
  cerrarComponente,
}) {
  const {
    watch,
    register,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      entidadAsignada: "",
    },
  });

  const entidadAsignada = watch("entidadAsignada");
  const { refreshTable, setRefreshTable, screenSize } = useStateContext();
  const [variablesAsignadas, setVariablesAsignadas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const infoOpcionesAtributosContrato = useSelector(
    (state) => state.infoAtributosContratos
  );

  const infoOpcionesAtributosAnexoContrato = useSelector(
    (state) => state.infoAtributosAnexoContrato
  );

  useEffect(() => {
    try {
      setValue("plantillaNombre", plantillaEditar.nombre);
      setValue("entidadAsignada", plantillaEditar.entidadAsignada);
      setVariablesAsignadas(plantillaEditar.variablesAsignadas);
    } catch (error) {
      console.log(error);
    }
  }, [plantillaEditar]);

  useEffect(() => {
    if (
      variablesAsignadas.length > 0 &&
      Object.keys(infoOpcionesAtributosContrato).length > 0 &&
      Object.keys(infoOpcionesAtributosAnexoContrato).length > 0
    ) {
      if (entidadAsignada === "Contrato") {
        variablesAsignadas.forEach((variable, index) => {
          const tipoAtributo = Object.keys(infoOpcionesAtributosContrato).find(
            (key) =>
              infoOpcionesAtributosContrato[key].some(
                (item) => item.nombreAmigable === variable.nombreAmigable
              )
          );
          if (tipoAtributo) {
            setValue(`tipoAtributo_${index}`, tipoAtributo);
            setValue(`subtipoAtributo_${index}`, variable.nombreAtributo);
            setValue(
              `subtipoAtributoNombreInterno_${index}`,
              variable.nombreAtributo
            );
            // setIsLoading(false)
          }
        });
      } else if (entidadAsignada === "AnexoContrato") {
        variablesAsignadas.forEach((variable, index) => {
          const tipoAtributo = Object.keys(
            infoOpcionesAtributosAnexoContrato
          ).find((key) =>
            infoOpcionesAtributosAnexoContrato[key].some(
              (item) => item.nombreAmigable === variable.nombreAmigable
            )
          );
          if (tipoAtributo) {
            setValue(`tipoAtributo_${index}`, tipoAtributo);
            setValue(`subtipoAtributo_${index}`, variable.nombreAtributo);
            setValue(
              `subtipoAtributoNombreInterno_${index}`,
              variable.nombreAtributo
            );
            // setIsLoading(false)
          }
        });
      }
      setIsLoading(false);
    }
  }, [
    entidadAsignada,
    variablesAsignadas,
    setValue,
    infoOpcionesAtributosContrato,
    infoOpcionesAtributosAnexoContrato,
  ]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     await Promise.all([
  //       dispatch(obtenerAtributosContrato()),
  //       dispatch(obtenerAtributosAnexoContrato()),
  //     ]);
  //   };
  //   fetchData().catch((error) => {
  //     console.log(error.response.data.message);
  //   });
  // }, [plantillaEditar]);

  // useEffect(() => {
  //   return () => {
  //     dispatch(limpiarInfoMenuEdicion("infoAtributosContratos"));
  //     dispatch(limpiarInfoMenuEdicion("infoAtributosAnexoContrato"));
  //   };
  // }, []);

  // UseEffect para setear los valores iniciales de los selects si variablesAsignadas tiene datos

  async function onSubmit() {
    toast.loading("Guardando...", estilosAlerta);
    try {
      const formValues = getValues();

      const asignaciones = variablesAsignadas.map((objeto, index) => ({
        nombreVariable: formValues[`VariableNombre_${index}`],
        atributoSeleccionado:
          formValues[`subtipoAtributoNombreInterno_${index}`],
      }));

      const requestBody = {
        plantillaId: plantillaEditar.id,
        entidadAsignada: entidadAsignada,
        asignaciones,
      };

      console.log("requestBody", requestBody);

      const response = await axiosPrivate.post(
        `${api_service}/api/v1/plantillas/asignar`,
        requestBody,
        Authorization
      );
      if (response.status === 200) {
        toast.dismiss();
        toast.success("Datos guardados con éxito", estilosAlerta);
        setRefreshTable(!refreshTable);
        cerrarComponente();
      }
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al guardar datos",
        estilosAlerta
      );
    }
  }

  const entidades = [
    {
      nombre: "Contrato",
      id: "Contrato",
    },
    {
      nombre: "Anexo Contrato",
      id: "AnexoContrato",
    },
  ];

  console.log("watch", watch());
  console.log("plantillaEditar", plantillaEditar);

  if (isLoading) {
    return (
      <Skeleton
        width={"full"}
        height="h-fix"
        loaderMessage="Cargando datos..."
        searchBar={true}
        pagination={true}
      />
    );
  }

  return (
    <div className="pl-1">
      <div className="flex flex-wrap">
        <div className="w-full md:w-5/12 mt-1 mb-6 mr-3">
          <label className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
            Plantilla a configurar
          </label>
          <input
            {...register(`plantillaNombre`)}
            disabled
            className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
            type="text"
            readOnly
          />
        </div>

        <div className="w-full md:w-2/12 mt-1 mb-6 mx-3">
          <label className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
            Entidad
          </label>
          <select
            {...register("entidadAsignada", {
              onChange: (e) => {
                variablesAsignadas.map((objeto, index) => {
                  setValue(`tipoAtributo_${index}`, "");
                  setValue(`subtipoAtributo_${index}`, "");
                  setValue(`subtipoAtributoNombreInterno_${index}`, "");
                });
              },
            })}
            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          >
            <option hidden value="">
              Seleccionar Entidad
            </option>
            {entidades.map((entidad, i) => (
              <option value={entidad.id} key={i}>
                {entidad.nombre}
              </option>
            ))}
          </select>
        </div>

        {!entidadAsignada || entidadAsignada === "" ? (
          <div className="w-full md:w-2/12 mt-1 mb-6 mx-3">
            <button
              className="mt-6 mr-2 bg-hercules-light-primary-button hover:shadow-lg hover:scale-105 text-white  font-bold uppercase text-sm md:text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none ease-in-out transition-all duration-400"
              type="button"
              onClick={() => cerrarComponente()}
            >
              Volver
            </button>
          </div>
        ) : null}
      </div>

      {entidadAsignada &&
      entidadAsignada !== "" &&
      plantillaEditar.id &&
      plantillaEditar.id !== "" ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Encabezados de las columnas */}
          <div className="flex flex-wrap mb-2 mt-3">
            <div className="md:w-4/12 pr-6">
              <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
                Variables
              </label>
            </div>
            <div className="md:w-4/12 pl-5">
              <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
                Tipo Atributo
              </label>
            </div>
            <div className="md:w-4/12 pl-4">
              <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
                Subtipo Atributo
              </label>
            </div>
          </div>

          {/* Cuerpo de las filas con los inputs/selects */}
          <div className="max-h-[25rem] overflow-y-auto ">
            <div className="flex flex-wrap mr-3">
              {variablesAsignadas.map((objeto, index) => {
                const tipoAtributo = watch(`tipoAtributo_${index}`);
                const opcionesTiposAtributos =
                  entidadAsignada === "Contrato"
                    ? Object.keys(infoOpcionesAtributosContrato)
                    : Object.keys(infoOpcionesAtributosAnexoContrato);
                const opcionesSubtipoNombreInterno = tipoAtributo
                  ? entidadAsignada === "Contrato"
                    ? infoOpcionesAtributosContrato[tipoAtributo].map(
                        (item) => item.nombreInterno
                      )
                    : infoOpcionesAtributosAnexoContrato[tipoAtributo].map(
                        (item) => item.nombreInterno
                      )
                  : [];
                const opcionesSubtipo = tipoAtributo
                  ? entidadAsignada === "Contrato"
                    ? infoOpcionesAtributosContrato[tipoAtributo]
                    : infoOpcionesAtributosAnexoContrato[tipoAtributo]
                  : [];

                  opcionesSubtipo.sort((a, b) => {
                    if (a.nombreAmigable < b.nombreAmigable) {
                      return -1;
                    }
                    if (a.nombreAmigable > b.nombreAmigable) {
                      return 1;
                    }
                    return 0;
                  });                  

                return (
                  <div key={`row_${index}`} className="w-full flex my-2">
                    {/* Columna de Variables */}
                    <div className="md:w-4/12 pr-6">
                      <input
                        {...register(`VariableNombre_${index}`, {
                          value: objeto.nombreVariable,
                        })}
                        disabled
                        className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                        readOnly
                      />
                    </div>

                    {/* Columna de Tipo Atributo */}
                    <div className="md:w-4/12 px-6">
                      <select
                        {...register(`tipoAtributo_${index}`, {
                          required: "Obligatorio",
                          onChange: (e) => {
                            setValue(`subtipoAtributo_${index}`, "");
                            setValue(
                              `subtipoAtributoNombreInterno_${index}`,
                              ""
                            );
                          },
                        })}
                        className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      >
                        <option hidden value="">
                          Seleccionar Tipo Atributo
                        </option>
                        {opcionesTiposAtributos.map((opcion, i) => (
                          <option value={opcion} key={i}>
                            {opcion}
                          </option>
                        ))}
                      </select>
                      <span
                        className={`text-red-500 text-xs italic ${
                          !errors[`tipoAtributo_${index}`] &&
                          "opacity-0 invisible"
                        }`}
                      >
                        {errors[`tipoAtributo_${index}`]
                          ? errors[`tipoAtributo_${index}`].message
                          : "hidden"}
                      </span>
                    </div>

                    {/* Columna de Subtipo Atributo */}
                    <div className="md:w-4/12 pl-6">
                      <select
                        {...register(`subtipoAtributo_${index}`, {
                          required: "Obligatorio",
                          onChange: (e) => {
                            const subTipoAtributoNombreInterno =
                              opcionesSubtipoNombreInterno[
                                e.target.selectedIndex - 1
                              ];
                            setValue(
                              `subtipoAtributoNombreInterno_${index}`,
                              subTipoAtributoNombreInterno
                            );
                          },
                        })}
                        disabled={!opcionesSubtipoNombreInterno.length}
                        className="block appearance-none w-full bg-gray-200 border  text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      >
                        <option hidden value="">
                          Seleccionar Subtipo Atributo
                        </option>
                        {opcionesSubtipo.map((subtipo, i) => (
                          <option value={subtipo.nombreInterno} key={i}>
                            {subtipo.nombreAmigable}
                          </option>
                        ))}
                      </select>

                      <span
                        className={`text-red-500 text-xs italic ${
                          !errors[`subtipoAtributo_${index}`] &&
                          "opacity-0 invisible"
                        }`}
                      >
                        {errors[`subtipoAtributo_${index}`]
                          ? errors[`subtipoAtributo_${index}`].message
                          : "hidden"}
                      </span>
                    </div>

                    {/* Columna de Haberes */}
                    {/* ACAA IRIA LA LISTA DE LOS HABERES !!!ACTIVOS!!! Y ME TIENES QUE ENVIAR HABERID */}
                    {tipoAtributo === "haberContrato" || tipoAtributo === "haberAnexo" ? (
                      <div className="md:w-4/12 pl-6">
                      <select
                        // {...register(`subtipoAtributo_${index}`, {
                        //   required: "Obligatorio",
                        //   onChange: (e) => {
                        //     const subTipoAtributoNombreInterno =
                        //       opcionesSubtipoNombreInterno[
                        //         e.target.selectedIndex - 1
                        //       ];
                        //     setValue(
                        //       `subtipoAtributoNombreInterno_${index}`,
                        //       subTipoAtributoNombreInterno
                        //     );
                        //   },
                        // })}
                        // disabled={!opcionesSubtipoNombreInterno.length}
                        className="block appearance-none w-full bg-gray-200 border  text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      >
                        <option hidden value="">
                          Seleccionar Haber
                        </option>
                        {opcionesSubtipo.map((subtipo, i) => (
                          <option value={subtipo.nombreInterno} key={i}>
                            {subtipo.nombreAmigable}
                          </option>
                        ))}
                      </select>

                      <span
                        className={`text-red-500 text-xs italic ${
                          !errors[`subtipoAtributo_${index}`] &&
                          "opacity-0 invisible"
                        }`}
                      >
                        {errors[`subtipoAtributo_${index}`]
                          ? errors[`subtipoAtributo_${index}`].message
                          : "hidden"}
                      </span>
                    </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>

          <div className="flex flex-wrap">
            <button
              className="mt-6 mr-2 bg-hercules-light-primary-button hover:shadow-lg hover:scale-105 text-white  font-bold uppercase text-sm md:text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none ease-in-out transition-all duration-400"
              type="button"
              onClick={() => cerrarComponente()}
            >
              Volver
            </button>

            <button
              className="mt-6 ml-2 bg-hercules-light-primary-button hover:shadow-lg hover:scale-105 text-white  font-bold uppercase text-sm md:text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none ease-in-out transition-all duration-400"
              type="submit"
            >
              Guardar
            </button>
          </div>
        </form>
      ) : null}
    </div>
  );
}
