import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useStateContext } from "../../../contexts/ContextProvider";
import { toast } from "react-hot-toast";
import { axiosPrivate } from "../../../common/axiosPrivate";

const NuevoCargo = ({ isVisible, onClose }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { refreshTable, setRefreshTable, screenSize } = useStateContext();
  const [habilitado, setHabilitado] = useState(true);
  const [botonGuardarHabilitado, setBotonGuardarHabilitado] = useState(true)

  const validacionTextInput = {
    required: { value: true, message: "Obligatorio" },
  };

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  if (!isVisible) {
    return null;
  }

  const onSubmit = async (data) => {
    setBotonGuardarHabilitado(false)
    toast.loading("Creando Cargo", estilosAlerta);
    const payload = {
      nombre: data.nombre,
      observacion: data.descripcion,
      activo: habilitado,
    };
    await axiosPrivate
      .post(
        `${api_service}/api/v1/contratos/nuevocargo`,
        payload,
        Authorization
      )
      .then((response) => {
        toast.dismiss();
        toast.success("Cargo creado con éxito", estilosAlerta);
        setRefreshTable(!refreshTable);
        onClose();
        reset();
        setBotonGuardarHabilitado(true)
      })
      .catch((error) => {
        toast.dismiss();
        toast.error(error.response.data.message? error.response.data.message : "Error al crear Cargo", estilosAlerta);
        console.log(error);
        setBotonGuardarHabilitado(true)
      });
  };

  return (
    <>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <div className="primerDivModal">
          <div className="segundoDivModal max-w-lg">
            {/*content*/}
            <div className="tercerDivModal shadow-lg  max-h-[85vh] ">
              {/*header*/}
              <div className="divHeaderModal border-slate-200">
              <h3 className="titulo-Modal">
                  Nuevo Cargo
                </h3>
                {/* <button
                  className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => onClose()}
                >
                  <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button> */}
              </div>
              {/*body*/}
              <div className="relative p-6 flex-auto overflow-auto dark:bg-hercules-dark-bg-200">

                  <div className="div-Inputs-EditarHaber">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-first-name"
                    >
                      Nombre
                    </label>
                    <input
                      {...register("nombre", validacionTextInput)}
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                    />
                    <span
                      className={`text-red-500 text-xs italic ${
                        !errors.nombre && "opacity-0 invisible"
                      }`}
                    >
                      {errors.nombre ? errors.nombre.message : "hidden"}
                    </span>
                  </div>

                  <div className="div-Inputs-EditarHaber">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-first-name"
                    >
                      Descripcion
                    </label>
                    <input
                      {...register("descripcion", validacionTextInput)}
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                    />
                    <span
                      className={`text-red-500 text-xs italic ${
                        !errors.descripcion && "opacity-0 invisible"
                      }`}
                    >
                      {errors.descripcion
                        ? errors.descripcion.message
                        : "hidden"}
                    </span>
                  </div>

                  <div className="flex flex-wrap mt-6">
                  <span className="uppercase dark:text-white tracking-wide text-hercules-light-font-secondary text-xs font-bold my-auto mr-10 mb-1 md:mb-0 ">
                      Activo
                    </span>
                    <label className="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        checked={habilitado}
                        readOnly
                      />
                      <div
                        onClick={() => setHabilitado(!habilitado)}
                        className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"
                      ></div>
                    </label>
                  </div>

              </div>
              {/*footer*/}
              <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                  type="button"
                  onClick={() => {
                    onClose();
                    reset();
                  }}
                >
                  Cerrar
                </button>

                <button
                  disabled={!botonGuardarHabilitado}
                  className={` ${!botonGuardarHabilitado? "bg-gray-500" : "bg-hercules-light-primary-button hover:shadow-lg hover:scale-105"}  text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400`}
                  type="submit"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </form>
    </>
  );
};

export default NuevoCargo;
