import React from "react";

const ModalSpinner = () => {
  return (
    <div className="flex items-center min-h-full absolute align-middle z-50 bg-white dark:bg-hercules-dark-bg-200 min-w-full -mx-6 -my-6">
      <div className=" inline-block align-middle mx-auto border-t-blue-ribbon-500 animate-spin ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
    </div>
  );
};

export default ModalSpinner;
