import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCentroCostos,
  getCentrosCostosByEmpresaId,
  getDescuentos,
  getHaberes,
  listaPeriodosHastaActual,
} from "../../Redux/Actions";
import { useStateContext } from "../../contexts/ContextProvider";
import { ColumnFilter,  } from "../../components";
import UtilsFunctions from "../../utils/UtilsFunctions";
import { useForm } from "react-hook-form";
import { axiosPrivate } from "../../common/axiosPrivate";
import { toast } from "react-hot-toast";
import SimpleTable from "../../components/SimpleTable";

export default function InformesHaberesDescuentos() {
  const {
    register,
    getValues,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      centroCostoId: 0,
      tipoHaberDescuento: "",
      nombreHaberDescuento: "",
      periodoAnyo: "",
    },
  });

  const anyosData = UtilsFunctions.obtenerAnyosHastaActual();
  const [mesesData, setMesesData] = useState([]);
  const [infoPeriodos, setInfoPeriodos] = useState([]);
  const listaHaberes = useSelector((state) => state.getHaberes);
  const listaDescuentos = useSelector((state) => state.getDescuentos);
  const [infoTabla, setInfoTabla] = useState([]);
  const { refreshTable, setRefreshTable, activeMenu, screenSize } = useStateContext();
  const infoCCosto = useSelector((state) => state.centrosCostoEmpresa);

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const dispatch = useDispatch();
  const empresaId = localStorage.getItem("empresaId");
  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
    },
  };

  const validacionCampo = {
    required: { value: true, message: "Obligatorio" },
  };

  useEffect(() => {
    try {
      getPeriodosCargados()
      dispatch(listaPeriodosHastaActual());
      dispatch(getCentroCostos());
      dispatch(getHaberes());
      dispatch(getDescuentos());
      dispatch(getCentrosCostosByEmpresaId(empresaId));
    } catch (error) {
      console.log(error);
    }
  }, [refreshTable, dispatch]);

  async function getPeriodosCargados(){
    const infoMeses = await axiosPrivate(`${api_service}/api/v1/periodos/cargados`, Authorization)
    setInfoPeriodos(infoMeses.data)
  }

  const handleYearChange = (e) => {
    const year = parseInt(e);
    const yearData = infoPeriodos.find((item) => item.anyo === year);
    if (yearData) {
      const filteredMonths = yearData.periodos.filter(
        (mes) => mes.datosPrevisionalesCargados === true
      );
      const transformedMonths = filteredMonths.map((month) => (
        UtilsFunctions.integerToMonth(month.mes)
      ));
      setMesesData(transformedMonths);

    } else {
      setMesesData([]);
    }
  };

  async function onSubmit() {
    const data = getValues();
    try {
      const info = await axiosPrivate(
        `${api_service}/api/v1/periodos/mes/${data.numeroMes}/anyo/${data.periodoAnyo}`,
        Authorization
      );
      if (watch("tipoHaberDescuento") == "haber") {
        const response = await axiosPrivate(
          `${api_service}/api/v1/liquidaciones/periodo/${info.data.id}/empresa/${empresaId}/centro-costo/${data.centroCostoId}/haber/${data.nombreHaberDescuento}`,
          Authorization
        );
        console.log("response", response)
        if (!response.data.length > 0) {
          toast.dismiss()
          toast.error("No existen registros de este Haber", estilosAlerta);
          setInfoTabla([]);
        } else {
          setInfoTabla(response.data);
          setRefreshTable(!refreshTable);
          toast.dismiss();
          toast.success("Tabla cargada con éxito", estilosAlerta);
        }
      }
      if (watch("tipoHaberDescuento") == "descuento") {
        const response = await axiosPrivate(
          `${api_service}/api/v1/liquidaciones/periodo/${info.data.id}/empresa/${empresaId}/centro-costo/${data.centroCostoId}/descuento/${data.nombreHaberDescuento}`,
          Authorization
        );
        if (!response.data.length > 0) {
          toast.dismiss()
          toast.error("No existen registros de este Descuento", estilosAlerta);
          setInfoTabla([]);
        } else {
          setInfoTabla(response.data);
          setRefreshTable(!refreshTable);
          toast.dismiss();
          toast.success("Tabla cargada con éxito", estilosAlerta);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const COLUMNS_HABERES = [
    {
      Header: "RUT",
      accessor: (d) => UtilsFunctions.rutFormatter(d.rut, { dots: true }),
      Filter: ColumnFilter,
    },
    {
      Header: "Apellido paterno",
      accessor: "apellidoPaterno",
      Filter: ColumnFilter,
    },
    {
      Header: "Apellido materno",
      accessor: "apellidoMaterno",
      Filter: ColumnFilter,
    },
    {
      Header: "Nombre",
      accessor: "nombres",
      Filter: ColumnFilter,
    },
    {
      Header: "Haber",
      accessor: "haber",
      Filter: ColumnFilter,
    },
    {
      Header: "Monto",
      accessor: (d) => "$" + UtilsFunctions.integerFormatter(d.valor),
      Filter: ColumnFilter,
    },
    {
      Header: "Centro de Costo",
      accessor: "centroCosto",
      Filter: ColumnFilter,
    },
  ];

  const COLUMNS_DESCUENTOS = [
    {
      Header: "RUT",
      accessor: (d) => UtilsFunctions.rutFormatter(d.rut, { dots: true }),
      Filter: ColumnFilter,
    },
    {
      Header: "Apellido paterno",
      accessor: "apellidoPaterno",
      Filter: ColumnFilter,
    },
    {
      Header: "Apellido materno",
      accessor: "apellidoMaterno",
      Filter: ColumnFilter,
    },
    {
      Header: "Nombre",
      accessor: "nombres",
      Filter: ColumnFilter,
    },
    {
      Header: "Descuento",
      accessor: "descuento",
      Filter: ColumnFilter,
    },
    {
      Header: "Monto",
      accessor: (d) => "$" + UtilsFunctions.integerFormatter(d.valor),
      Filter: ColumnFilter,
    },
    {
      Header: "Centro de Costo",
      accessor: "centroCosto",
      Filter: ColumnFilter,
    },
  ];

  const headersCsvHaber = [
    { name: "RUT", key: "rut" },
    { name: "Nombre", key: "nombres" },
    { name: "Apellido Paterno", key: "apellidoPaterno" },
    { name: "Apellido Materno", key: "apellidoMaterno" },
    { name: "Haber", key: "haber" },
    { name: "Monto", key: "valor" },
    { name: "Centro de Costo", key: "centroCosto" },
  ];

  const headersCsvDescuento = [
    { name: "RUT", key: "rut" },
    { name: "Nombre", key: "nombres" },
    { name: "Apellido Paterno", key: "apellidoPaterno" },
    { name: "Apellido Materno", key: "apellidoMaterno" },
    { name: "Descuento", key: "descuento" },
    { name: "Monto", key: "valor" },
    { name: "Centro de Costo", key: "centroCosto" },
  ];

  return (
    <div
    className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
      activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">
        Informes
      </div>
      <div className="titulo-Pagina">
        Informes de haberes y descuentos
      </div>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="pt-3 flex flex-wrap">

        <div className="flex flex-wrap w-full md:min-w-[160px] md:max-w-[245px]">
            <div className="w-4/12 md:w-4/12 md:min-w-[100px] md:max-w-[150px] pr-3 mb-3">
              <label
                htmlFor="periodoId"
                className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold"
                >
                Periodo
              </label>
                <select
                  {...register("periodoAnyo", {
                    onChange: (e) => {
                      setValue("periodoMes", "");
                      handleYearChange(e.target.value)
                    },
                    required: { value: true, message: "Obligatorio" },
                  })}
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                  <option hidden value="">
                    Año
                  </option>
                  {anyosData.map((anyo, i) => (
                    <option value={anyo} key={i}>
                      {anyo}
                    </option>
                  ))}
                </select>
                <span
                  className={`dark:text-red-400 text-red-600 text-xs ${
                    !errors.periodoAnyo && "opacity-0 invisible"
                  }`}
                >
                  {errors.periodoAnyo ? errors.periodoAnyo.message : "hidden"}
                </span>
            </div>

            <div className="w-5/12 md:w-6/12 md:min-w-[142px] md:max-w-[200px] mt-6 md:pr-3 mb-3">
              <select
                {...register("periodoMes", {
                  onChange: (e) => {
                    setValue("numeroMes", e.target.selectedIndex);
                  },
                  required: { value: true, message: "Obligatorio" },
                })}
                disabled={watch("periodoAnyo") === "" ? true : false}
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                >
                <option hidden value="">
                  Mes
                </option>
                {mesesData.map((mes, i) => (
                  <option value={mes} key={i}>
                    {mes}
                  </option>
                ))}
              </select>
              <span
                className={`dark:text-red-400 text-red-600 text-xs ${
                  !errors.periodoMes && "opacity-0 invisible"
                }`}
              >
                {errors.periodoMes ? errors.periodoMes.message : "hidden"}
              </span>
            </div>
          </div>

          {/* Div de los ultimos 3 campos y boton */}
          <div className=" flex flex-col w-full md:flex-row md:w-8/12">
            <div className="w-full md:w-2/12 md:min-w-[150px] md:max-w-[200px] md:pl-3 mb-3">
              <label className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
                Tipo
              </label>
                <select
                  {...register("tipoHaberDescuento", {
                    onChange: (e) => {
                      setInfoTabla([]);
                    },
                    required: { value: true, message: "Obligatorio" },
                  })}
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                >
                  <option hidden value="">
                    Seleccionar
                  </option>
                  <option value="haber" key="1">
                    Haber
                  </option>
                  <option value="descuento" key="2">
                    Descuento
                  </option>
                </select>
                <span
                  className={`dark:text-red-400 text-red-600 text-xs ${
                    !errors.tipoHaberDescuento && "opacity-0 invisible"
                  }`}
                >
                  {errors.tipoHaberDescuento
                    ? errors.tipoHaberDescuento.message
                    : "hidden"}
                </span>
            </div>

            <div className="w-full md:w-4/12 md:min-w-[280px] md:max-w-[300px] md:px-3 mb-3">
              <label
                htmlFor="periodoId"
                className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold"
              >
                Nombre
              </label>
                <select
                  {...register("nombreHaberDescuento", validacionCampo, {
                    onChange: () => {
                      setInfoTabla([]);
                    },
                  })}
                  disabled={watch("tipoHaberDescuento") === ""}
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                >
                  <option hidden value="">
                    Seleccionar
                  </option>

                  {watch("tipoHaberDescuento") === "" && (
                    <option selected>Seleccione un Tipo</option>
                  )}

                  {watch("tipoHaberDescuento") === "haber" &&
                    listaHaberes.map((haber, i) => (
                      <option value={haber.id} key={i}>
                        {haber.nombre}
                      </option>
                    ))}

                  {watch("tipoHaberDescuento") === "descuento" &&
                    listaDescuentos.map((descuento, i) => (
                      <option value={descuento.id} key={i}>
                        {descuento.nombre}
                      </option>
                    ))}
                </select>
                <span
                  className={`dark:text-red-400 text-red-600 text-xs ${
                    !errors.nombreHaberDescuento && "opacity-0 invisible"
                  }`}
                >
                  {errors.nombreHaberDescuento
                    ? errors.nombreHaberDescuento.message
                    : "hidden"}
                </span>
            </div>

            <div className="w-full md:w-3/12 md:min-w-[200px] md:max-w-[300px] md:px-3 mb-9">
              <label className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
                Centro de Costo
              </label>
                <select
                  {...register("centroCostoId", {
                    onChange: (e) => {
                      const centroCosto =
                        infoCCosto[e.target.selectedIndex - 1];
                      setValue("centroCosto", centroCosto);
                    },
                  })}
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                >
                  <option value={"0"}>Todos</option>
                  {infoCCosto.map((ccosto, i) => (
                    <option value={ccosto.id} key={i}>
                      {ccosto.nombre}
                    </option>
                  ))}
                </select>
            </div>

            <div className="w-3/12 md:w-2/12 md:mt-6 mb-6 md:mb-0 md:min-w-[120px] md:max-w-[130px] md:pl-3">
              <button
                type="submit"
                className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none ease-in-out transition-all duration-400"
                >
                Buscar
              </button>
            </div>
          </div>
        </div>
      </form>

      {infoTabla.length > 0 && 
        <div className="h-full md:mr-12 rounded-lg">
        <SimpleTable
            col={
              watch("tipoHaberDescuento") == "haber"
                ? COLUMNS_HABERES
                : watch("tipoHaberDescuento") == "descuento" &&
                  COLUMNS_DESCUENTOS
            }
            tableData={infoTabla}
            exportCsv={() => {
              watch("tipoHaberDescuento") == "haber"
                ? UtilsFunctions.jsonToCSV3(
                    infoTabla,
                    headersCsvHaber,
                    "Informe Haberes"
                  )
                : watch("tipoHaberDescuento") == "descuento" &&
                  UtilsFunctions.jsonToCSV3(
                    infoTabla,
                    headersCsvDescuento,
                    "Informe Descuentos"
                  );
            }}
          />
        </div>
     }
    </div>
  );
}
