import axios from 'axios';
import React from 'react'
import { Provider } from 'react-redux'
import App from './App'
import { store } from './Redux/Store'
import "./index.css";
import { ContextProvider } from "./contexts/ContextProvider";
import { createRoot } from "react-dom/client";

// import dotenv from "dotenv";
// dotenv.config();

// axios.defaults.baseURL = process.env

// As of React 18
const root = createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <ContextProvider>
    <App />
    </ContextProvider>
  </Provider>
)