import { axiosPrivate } from "../../../common/axiosPrivate";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useStateContext } from "../../../contexts/ContextProvider";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import UtilsFunctions from "../../../utils/UtilsFunctions";

export default function EditarGrupoSindicato({
  isVisible,
  onClose,
  modalData,
}) {
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const [botonGuardarHabilitado, setBotonGuardarHabilitado] = useState(true)
  const [habilitado, setHabilitado] = useState(true);
  const { refreshTable, setRefreshTable, screenSize } = useStateContext();
  const validacionTextInput = {
    required: { value: true, message: "Obligatorio" },
  };

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const rutValidations = {
    required: { value: true, message: "Campo obligatorio" },
    pattern: {
      value: /^(\d{1,2}(?:[\.]?\d{3}){2}-[\dkK])$/,
      message:
        "El formato del RUT es incorrecto (ej. 18.405.136-K o 18405136-K)",
    },
    validate: {
      rutValido: (v) =>
        UtilsFunctions.validateRut(v) || "El RUT ingresado no es válido",
    },
  };

  const options = [];

  for (let i = 1; i <= 10; i++) {
    options.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }

  useEffect(() => {
    if (modalData) {
      reset({
        id: modalData.id,
        nombre: modalData.nombre,
        numeroSindicato: modalData.numeroSindicato,
        rutSindicato: UtilsFunctions.rutFormatter(modalData.rutSindicato, {dots: true}),
      });
      setHabilitado(modalData.activo);
    }
  }, [modalData]);

  const onSubmit = async (data) => {
    setBotonGuardarHabilitado(false)
    toast.loading("Editando Grupo de Sindicato", estilosAlerta);
    try {
      const payload = {
        sindicatoId: data.id,
        nombre: data.nombre,
        activo: habilitado,
        numeroSindicato: data.numeroSindicato,
        rutSindicato: UtilsFunctions.rutFormatter(data.rutSindicato, {dots: false}),
      };
      await axiosPrivate.put(
        `${api_service}/api/v1/trabajadores/sindicato`,
        payload,
        Authorization
      );
      toast.dismiss();
      toast.success("Grupo de Sindicato editado con éxito", estilosAlerta);
      onClose();
      reset();
      setRefreshTable(!refreshTable);
      setBotonGuardarHabilitado(true)
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error(error.response.data.message? error.response.data.message : "Error al editar Grupo de Sindicato", estilosAlerta);
      setBotonGuardarHabilitado(true)
    }
  };

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <div className="primerDivModal">
          <div className="segundoDivModal max-w-md">
            {/*content*/}
            <div className="tercerDivModal shadow-lg  max-h-[85vh] ">
              {/*header*/}
              <div className="divHeaderModal border-slate-200">
                <h3 className="titulo-Modal">
                  Editar Grupo de Sindicato
                </h3>
              </div>
              {/*body*/}
              <div className="relative p-6 flex-auto overflow-auto dark:bg-hercules-dark-bg-200">
                {/* Primera fila modal */}

                <div className="div-Inputs-EditarHaber">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-first-name"
                    >
                      RUT Organización Sindical
                    </label>
                    <input
                      {...register("rutSindicato", {
                        ...rutValidations,
                        onChange: () => {
                          if (getValues("rutSindicato") !== "-") {
                            setValue(
                              "rutSindicato",
                              UtilsFunctions.rutFormatter(getValues("rutSindicato"), {
                                dots: true,
                              })
                            );
                          }
                          if (getValues("rutSindicato") === "-") {
                            setValue("rutSindicato", "");
                          }
                        },        
                      })}
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="rutSindicato"
                      type="text"
                    />
                    <span
                      className={`text-red-500 text-xs italic ${
                        !errors.rutSindicato && "opacity-0 invisible"
                      }`}
                    >
                      {errors.rutSindicato
                        ? errors.rutSindicato.message
                        : "hidden"}
                    </span>
                  </div>

                  <div className="div-Inputs-EditarHaber">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-first-name"
                    >
                      Nombre
                    </label>
                    <input
                      {...register("nombre", validacionTextInput)}
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="nombre"
                      type="text"
                    />
                    <span
                      className={`text-red-500 text-xs italic ${
                        !errors.nombre && "opacity-0 invisible"
                      }`}
                    >
                      {errors.nombre
                        ? errors.nombre.message
                        : "hidden"}
                    </span>
                  </div>

                  <div className="div-Inputs-EditarHaber">
                    <label 
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                      >
                      Número de sindicato
                    </label>

                    <select
                      {...register("numeroSindicato", validacionTextInput)}
                      className="block appearance-none w-36 bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      <option value="" hidden>Seleccionar</option>
                      {options}
                    </select>
                    <span
                      className={`text-red-500 text-xs italic ${
                        !errors.numeroSindicato && "opacity-0 invisible"
                      }`}
                    >
                      {errors.numeroSindicato
                        ? errors.numeroSindicato.message
                        : "hidden"}
                    </span>
                  </div>

                  <div className="flex flex-wrap">
                    <span className="uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold my-auto mr-10">
                      Activo
                    </span>
                    <label className="inline-flex relative items-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        checked={habilitado}
                        readOnly
                      />
                      <div
                        onClick={() => {
                          setHabilitado(!habilitado);
                        }}
                        className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"
                      ></div>
                    </label>
                  </div>

              </div>
              {/*footer*/}
              <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                  type="button"
                  onClick={() => {
                    onClose();
                    reset();
                  }}
                >
                  Cerrar
                </button>
                <button
                  disabled={!botonGuardarHabilitado}
                  className={` ${!botonGuardarHabilitado? "bg-gray-500" : "bg-hercules-light-primary-button hover:shadow-lg hover:scale-105"}  text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400`}
                  type="submit"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
