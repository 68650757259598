import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCentroCostos, listaPeriodosHastaActual } from "../../Redux/Actions";
import { useStateContext } from "../../contexts/ContextProvider";
import { ColumnFilter, Loader } from "../../components";
import UtilsFunctions from "../../utils/UtilsFunctions";
import { useForm } from "react-hook-form";
import { axiosPrivate } from "../../common/axiosPrivate";
import { toast } from "react-hot-toast";
import SimpleTable from "../../components/SimpleTable";
import { Link } from "react-router-dom";

export default function NuevoInformeDescuentoCuotas() {
  const {
    register,
    getValues,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      centroCostoId: 0,
      periodoAnyo: "",
    },
  });

  const { refreshTable, activeMenu, screenSize } = useStateContext();
  const dispatch = useDispatch();
  const [mesesData, setMesesData] = useState([]);
  const [infoPeriodos, setInfoPeriodos] = useState([]);
  const infoCCosto = useSelector((state) => state.getCentroCostos);
  const anyosData = UtilsFunctions.obtenerAnyosHastaActual();
  const [infoDescuentos, setInfoDescuentos] = useState([]);

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const empresaId = localStorage.getItem("empresaId");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  async function getPeriodosCargados(){
    const infoMeses = await axiosPrivate(`${api_service}/api/v1/periodos/cargados`, Authorization)
    setInfoPeriodos(infoMeses.data)
  }

  const handleYearChange = (e) => {
    const year = parseInt(e);
    const yearData = infoPeriodos.find((item) => item.anyo === year);
    if (yearData) {
      const filteredMonths = yearData.periodos.filter(
        (mes) => mes.datosPrevisionalesCargados === true
      );
      const transformedMonths = filteredMonths.map((month) => (
        UtilsFunctions.integerToMonth(month.mes)
      ));
      setMesesData(transformedMonths);

    } else {
      setMesesData([]);
    }
  };

  useEffect(() => {
    try {
      getPeriodosCargados()
      dispatch(listaPeriodosHastaActual());
      dispatch(getCentroCostos());
    } catch (error) {
      console.log(error);
    }
  }, [refreshTable, dispatch]);

  async function buscarLiquidacionesPorPeriodoCCosto() {
    const data = getValues();
    toast.loading("Cargando información", estilosAlerta);
    try {
      const info = await axiosPrivate.get(
        `${api_service}/api/v1/periodos/mes/${data.numeroMes}/anyo/${data.periodoAnyo}`,
        Authorization
      );
      const response = await axiosPrivate.get(
        `${api_service}/api/v1/descuentos/programados/periodo/${info.data.id}/empresa/${empresaId}/centro-costo/${data.centroCostoId}`,
        Authorization
      );
      console.log("response", response);
      if (!response.data.length > 0) {
        setInfoDescuentos([]);
        toast.dismiss();
        toast.error("Sin Descuentos en este Periodo y Centro de costo", estilosAlerta);
      } else {
        setInfoDescuentos(response.data);
        toast.dismiss();
        toast.success("Descuentos cargados con éxito", estilosAlerta);
      }
    } catch (error) {
      console.log("error", error);
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al cargar Descuentos",
          estilosAlerta
      );
    }
  }

  const headersCsv = [
    { name: "RUT", key: "rut" },
    { name: "Apellido Paterno", key: "apellidoPaterno" },
    { name: "Apellido Materno", key: "apellidoMaterno" },
    { name: "Nombres", key: "nombres" },
    { name: "Observacion", key: "observacion" },
    { name: "Cuotas Pagadas", key: "numeroCuotasPagadas" },
    { name: "Numero Cuotas", key: "numeroCuotas" },
    { name: "Monto", key: "monto" },
    { name: "Centro de Costo", key: "centroCostoNombre" },
  ];

  const COLUMNS = [
    {
      Header: "RUT",
      accessor: (d) => UtilsFunctions.rutFormatter(d.rut, { dots: true }),
      Filter: ColumnFilter,
    },
    {
      Header: "Trabajador",
      accessor: (d) =>
        d.apellidoPaterno + " " + d.apellidoMaterno + " " + d.nombres,
      Filter: ColumnFilter,
    },
    {
      Header: "Observación",
      accessor: "observacion",
      Filter: ColumnFilter,
    },
    {
      Header: "Cuota",
      accessor: (d) => d.cuotaNumero + " de " + d.numeroCuotas,
      Filter: ColumnFilter,
    },
    {
      Header: "Monto",
      accessor: (d) => "$" + UtilsFunctions.integerFormatter(d.monto),
      Filter: ColumnFilter,
    },
    {
      Header: "Centro de costo",
      accessor: "centroCostoNombre",
      Filter: ColumnFilter,
    },
  ];

  return (
    <div
      className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
        activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">Movimientos</div>
      <div className="titulo-Pagina">Informe descuento en cuotas</div>
      <form
        className="w-full"
        onSubmit={handleSubmit(buscarLiquidacionesPorPeriodoCCosto)}
      >
        <div className="pt-3 flex flex-wrap">
          <div className="flex flex-wrap w-full md:w-fit">
            <div className="w-4/12 md:w-2/12 md:min-w-[100px] md:max-w-[200px] pr-3 pb-3">
              <label
                htmlFor="periodoId"
                className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold"
              >
                Periodo
              </label>
              <select
                {...register("periodoAnyo", {
                  onChange: (e) => {
                    setValue("periodoMes", "");
                    handleYearChange(e.target.value)
                    setInfoDescuentos([]);
                  },
                  required: { value: true, message: "Obligatorio" },
                })}
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option hidden value="">
                  Año
                </option>
                {anyosData.map((anyo, i) => (
                  <option value={anyo} key={i}>
                    {anyo}
                  </option>
                ))}
              </select>
              <span
                className={`dark:text-red-400 text-red-600 text-xs ${
                  !errors.periodoAnyo && "opacity-0 invisible"
                }`}
              >
                {errors.periodoAnyo ? errors.periodoAnyo.message : "hidden"}
              </span>
            </div>

            <div className="w-5/12 md:w-1/12 md:min-w-[130px] md:max-w-[200px] mt-6 md:mr-3 pb-3">
              <select
                {...register("periodoMes", {
                  onChange: (e) => {
                    setValue("numeroMes", e.target.selectedIndex);
                    setInfoDescuentos([]);
                  },
                  required: { value: true, message: "Obligatorio" },
                })}
                disabled={watch("periodoAnyo") === "" ? true : false}
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option hidden value="">
                  Mes
                </option>
                {mesesData.map((mes, i) => (
                  <option value={mes} key={i}>
                    {mes}
                  </option>
                ))}
              </select>
              <span
                className={`dark:text-red-400 text-red-600 text-xs ${
                  !errors.periodoMes && "opacity-0 invisible"
                }`}
              >
                {errors.periodoMes ? errors.periodoMes.message : "hidden"}
              </span>
            </div>
          </div>

          <div className="w-full md:w-72 md:px-3 pb-3">
            <label className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
              Centro de Costo
            </label>
            <select
              {...register("centroCostoId", {
                onChange: (e) => {
                  const centroCosto = infoCCosto[e.target.selectedIndex - 1];
                  setValue("centroCosto", centroCosto);
                  setInfoDescuentos([]);
                },
              })}
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option hidden value="">
                Seleccionar
              </option>
              <option value={"0"}>Todos</option>
              {infoCCosto.map((ccosto, i) => (
                <option value={ccosto.id} key={i}>
                  {ccosto.nombre}
                </option>
              ))}
            </select>
          </div>

          <div className="w-full md:w-3/12 mt-3 md:px-3 pb-6 md:pb-3">
            <button
              type="submit"
              className="mt-3 hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
            >
              Buscar
            </button>
          </div>
        </div>
      </form>
      {infoDescuentos.length > 0 && (
        <div className=" relative h-full rounded-lg md:mr-12">
          <SimpleTable
            col={COLUMNS}
            tableData={infoDescuentos}
            exportCsv={() =>
              UtilsFunctions.jsonToCSV(
                infoDescuentos,
                headersCsv,
                "Informe Descuento En Cuotas.csv"
              )
            }
          />
        </div>
      )}
      <div>
        <Link to="/movimientos/descuento-cuotas">
          <button className="mt-3 hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400">
            Ver Cuotas
          </button>
        </Link>
      </div>
    </div>
  );
}
