import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCausalesTermContrato, hideLoader, limpiarInfoMenuEdicion, showLoader } from "../../Redux/Actions";
import { ColumnFilter } from "../../components/";
import { useStateContext } from "../../contexts/ContextProvider";
import SimpleTable from "../../components/SimpleTable";
import UtilsFunctions from "../../utils/UtilsFunctions";

export default function CausalesTerminoContrato() {
  const { refreshTable, activeMenu } = useStateContext();

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader());
      await Promise.all([
        dispatch(getCausalesTermContrato())
      ]);
      dispatch(hideLoader());
    };

    fetchData().catch((error) => {
      console.log(error.response.data.message);
      dispatch(hideLoader());
    });
  }, [refreshTable, dispatch]);

  const causales = useSelector((state) => state.getCausalesTermContrato);

  useEffect(() =>{
    return () => {
      dispatch(limpiarInfoMenuEdicion("getCausalesTermContrato"))
    }
  },[])

  const COLUMNS = [
    {
      Header: "Artículo",
      accessor: "glosa",
      Filter: ColumnFilter,
    },
    {
      Header: "Código L.R.E",
      accessor: "codigo",
      Filter: ColumnFilter,
    },
  ];

  const headersCsv = [
    {
      key: "glosa",
      name: "Articulo",
    },
    {
      key: "codigo",
      name: "Codigo L.R.E",
    },
  ];

  return (
    <div
    className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
      activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">
        Edición
      </div>
      <div className="titulo-Pagina">
        Causales términos contrato
      </div>
      <div className="h-full md:mr-12 rounded-lg pt-5">
          <SimpleTable
          col={COLUMNS}
          tableData={causales || []}
          exportCsv={() =>
            UtilsFunctions.jsonToCSVv2(causales, headersCsv, "Causales Término Contrato")
          }  
          />
      </div>
    </div>
  );
}
