import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDescuentos, hideLoader, limpiarInfoMenuEdicion, showLoader } from "../../Redux/Actions";
import UtilsFunctions from "../../utils/UtilsFunctions";
import {
  ColumnFilter,
  ColumnFilterBoolean,
  EditarDescuentos,
  NuevoDescuentos,
} from "../../components";
import { useStateContext } from "../../contexts/ContextProvider";
import SimpleTable from "../../components/SimpleTable";

export default function MantenedorHsExtra() {
  const [showModalEditarDescuento, setShowModalEditarDescuento] =
    useState(false);
  const [descuentoData, setDescuentoData] = useState(null);
  const [showModalNuevoDescuento, setShowModalNuevoDescuento] = useState(false);
  const { refreshTable, activeMenu } = useStateContext();
  const descuentos = useSelector((state) => state.getDescuentos);
  const isLoading = useSelector((state) => state.loader);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader());
      await Promise.all([dispatch(getDescuentos())]);
      dispatch(hideLoader());
    };

    fetchData().catch((error) => {
      console.log(error.response.data.message);
      dispatch(hideLoader());
    });
  }, [refreshTable, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("getDescuentos"));
    };
  }, []);

  const COLUMNS = [
    {
      Header: "ID",
      accessor: "id",
      Filter: ColumnFilter,
    },
    {
      Header: "Nombre",
      accessor: "nombre",
      Filter: ColumnFilter,
    },
    {
      Header: "Categoría L.R.E.",
      accessor: "descuentoDt.glosa",
      Filter: ColumnFilter,
    },
    {
      Header: "Código L.R.E.",
      accessor: "descuentoDt.codigo",
      Filter: ColumnFilter,
    },
    {
      Header: "Habilitado",
      accessor: "activo",
      Cell: ({ value }) => {
        return UtilsFunctions.booleanToString(value);
      },
      Filter: ColumnFilterBoolean,
    },
  ];

  const headersCsv = [
    {
      key: "id",
      name: "ID",
    },
    {
      key: "nombre",
      name: "Nombre",
    },
    {
      key: "descuentoDt.glosa",
      name: "Categoria L.R.E",
    },
    {
      key: "descuentoDt.codigo",
      name: "Cod. L.R.E",
    },
    {
      key: "activo",
      name: "Habilitado",
    },
  ];

  return (
    <div
    className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
      activeMenu && "md:w-[calc(100vw_-_23rem)]"
    }`}
    >
      <div className="titulo-Menu">
        Edición
      </div>
      <div className="titulo-Pagina">
        Descuentos
      </div>
      <div className="h-full md:mr-12 rounded-lg pt-5">
        <SimpleTable
          col={COLUMNS}
          tableData={descuentos || []}
          primaryButton={{
            type: "editar",
            onClickFunc: (data) => {
              setShowModalEditarDescuento(true);
              setDescuentoData(data);
            },
            active: true,
            descuento: true,
          }}
          exportCsv={() =>
            UtilsFunctions.jsonToCSV3(descuentos, headersCsv, "Descuentos")
          }
    />
        <button
          onClick={() => setShowModalNuevoDescuento(true)}
          disabled={isLoading}
          className={`mt-3 ${isLoading? "bg-gray-500" : "bg-hercules-light-primary-button hover:shadow-lg"} text-white dark:text-dark-primary font-medium uppercase text-normal px-4 py-3 rounded shadow  outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}  
      >
          Nuevo Descuento
        </button>
      </div>

      {showModalNuevoDescuento && (
        <NuevoDescuentos
          isVisible={showModalNuevoDescuento}
          onClose={() => setShowModalNuevoDescuento(false)}
        />
      )}
      {showModalEditarDescuento && (
        <EditarDescuentos
          isVisible={showModalEditarDescuento}
          onClose={() => setShowModalEditarDescuento(false)}
          modalData={descuentoData}
        />
      )}
    </div>
  );
}
