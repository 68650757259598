import React, { useEffect, useState } from "react";
import { ColumnFilter } from "../..";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { axiosPrivate } from "../../../common/axiosPrivate";
import { useStateContext } from "../../../contexts/ContextProvider";
import { getMutual, limpiarInfoMenuEdicion } from "../../../Redux/Actions";
import UtilsFunctions from "../../../utils/UtilsFunctions";
import SimpleTable from "../../SimpleTable";

export default function NuevoModalEditarMutual({
  idEmpresa,
  mutualData,
  razonSocialEmpresa,
  cerrarModal,
  nombreMutualActual,
}) {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      tasaMutual: 0 + "%",
    },
  });

  const [listaMutualEmpresaTasa, setListaMutualEmpresaTasa] = useState([]);
  const anyosData = UtilsFunctions.obtenerAnyosHastaActual();
  const [mesesData, setMesesData] = useState([]);
  const [botonGuardarHabilitado, setBotonGuardarHabilitado] = useState(true)

  const { screenSize } = useStateContext();

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const api_service =
    process.env.REACT_APP_API_HERCULES_SERVICE;

  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const periodoId = localStorage.getItem("periodoId");
  const dispatch = useDispatch();

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const mutual = useSelector((state) => state.mutuales);

  useEffect(() => {
    dispatch(getMutual());
    setListaMutualEmpresaTasa(mutualData);
  }, [mutualData]);

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("mutuales"));
    };
  }, []);

  async function handleGuardarMutual(data) {
    setBotonGuardarHabilitado(false)
    const copiarListaMutual = listaMutualEmpresaTasa;

    toast.loading("Editando Mutual", estilosAlerta);
    try {

      const info = await axiosPrivate.get(
        `${api_service}/api/v1/periodos/mes/${data.numeroMes}/anyo/${data.periodoAnyo}`,
        Authorization
      );
      setValue("periodoId", info.data.id);

      const requestBody = {
        mutualId: data.mutualId,
        tasa: data.tasaMutual.replace("%", "").replace(",", "."),
        periodoId: info.data.id,
      };

      const response = await axiosPrivate.put(
        `${api_service}/api/v1/empresas/${idEmpresa}/mutual`,
        requestBody,
        Authorization
      );
      if (response.status === 200) {

        const elemento = copiarListaMutual.filter(
          (e) =>
            e.periodo.mes === parseInt(getValues("numeroMes")) &&
            e.periodo.anyo === parseInt(getValues("periodoAnyo"))
        );
        const index = copiarListaMutual.indexOf(elemento[0]);
        const mutualEditar = copiarListaMutual.splice(index, 1)[0];
        mutualEditar["mutual"]["glosa"] = data.mutualGlosa;
        mutualEditar["tasa"] = data.tasaMutual.replace("%", "").replace(".", ",");
        copiarListaMutual.splice(index, 0, mutualEditar);
        const nuevoArray = [...copiarListaMutual];
        setListaMutualEmpresaTasa(nuevoArray);
        nombreMutualActual(listaMutualEmpresaTasa[0].mutual.glosa);
        reset();
        toast.dismiss();
        toast.success("Mutual editada con éxito", estilosAlerta);
      }
      setBotonGuardarHabilitado(true)
    } catch (error) {
      console.log(error);

      if (
        error.response &&
        error.response.status === 404 &&
        error.response.data.message ===
        "No existe información sobre Mutualidad para el periodo seleccionado"
      ) {
        try {
          // Lógica adicional para actualizar la tabla cuando la API devuelve un estado 404
          const payload = {
            periodoId: getValues("periodoId"),
            mutualId: data.mutualId,
            tasa: data.tasaMutual.replace("%", "").replace(",", "."),
          };
          console.log("payload nuevo", payload);
          await axiosPrivate.post(
            `${api_service}/api/v1/empresas/${idEmpresa}/mutual`,
            payload,
            Authorization
          );

          const mutualId = {
            mutual: {
              glosa: data.mutualGlosa,
              id: data.mutualId,
            },
            periodo: {
              id: getValues("periodoId"),
              mes: data.numeroMes,
              anyo: data.periodoAnyo,
            },
            tasa: data.tasaMutual.replace("%", "").replace(",", "."),
          };
          console.log("mutualId", mutualId);
          copiarListaMutual.push(mutualId);
          copiarListaMutual.sort((a, b) => b.periodo.id - a.periodo.id);
          const nuevoArray = [...copiarListaMutual];
          setListaMutualEmpresaTasa(nuevoArray);
          toast.dismiss();
          toast(
            "No existía registro de Mutual para la empresa en el periodo seleccionado.\nSe agregó un nuevo registro de Mutual en ese periodo.", estilosAlerta
          );
          reset();
        } catch (innerError) {
          console.log("error", innerError);
          toast.dismiss();
          toast.error(
            error.response.data.message
              ? error.response.data.message
              : "Error al editar Mutual",
              estilosAlerta
          );
        }
      } else {
        toast.dismiss();
        toast.error(
          error.response.data.message
            ? error.response.data.message
            : "Error al editar Mutual",
            estilosAlerta
        );
        console.log("error", error);
      }
      setBotonGuardarHabilitado(true)
    }
  }

  const MUTUAL_COLMUNS = [
    {
      Header: "Periodo",
      accessor: (data) =>
        UtilsFunctions.periodoToText(data.periodo.mes, data.periodo.anyo),
      Filter: ColumnFilter,
    },
    {
      Header: "Mutual",
      accessor: "mutual.glosa",
      Filter: ColumnFilter,
    },
    {
      Header: "Tasa",
      accessor: (d) => (d.tasa + "%").replace(".", ","),
      Filter: ColumnFilter,
    },
  ];

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative mt-20 max-w-[370px] md:w-760 md:my-6 mx-auto md:max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col max-h-[85vh] overflow-y-auto w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t dark:bg-hercules-dark-bg-100 ">
              <h3 className="titulo-Modal">
                Ver/Editar Mutual
              </h3>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto overflow-auto dark:bg-hercules-dark-bg-200">
              <label className="block tracking-wide dark:text-white text-hercules-light-font-secondary font-bold ml-1 mb-5">
                Empresa: {razonSocialEmpresa}
              </label>

              <SimpleTable
                col={MUTUAL_COLMUNS}
                tableData={listaMutualEmpresaTasa}
                searchBar={false}
                showFilters={false}
                tableHeight="h-72"
              />

              <form onSubmit={handleSubmit(handleGuardarMutual)}>
              <div className="flex flex-wrap mt-6">

              <div className="relative w-full md:w-5/12 md:mr-6">
                    <label
                      className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      Mutual
                    </label>
                    <select
                      {...register("mutualId", {
                        required: {
                          value: true,
                          message: "Obligatorio",
                        },
                        onChange: (e) => {
                          const mutualSeleccionada =
                            mutual[e.target.selectedIndex - 1];
                          setValue("mutualGlosa", mutualSeleccionada.glosa);
                        },
                      })}
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      <option hidden value="">
                        Seleccionar
                      </option>
                      {mutual.map((mutual, i) => (
                        <>
                          <option value={mutual.id} key={i}>
                            {mutual.glosa}
                          </option>
                        </>
                      ))}
                    </select>
                    <span
                      className={`text-red-500 text-xs italic ${
                        !errors.mutualId && "opacity-0 invisible"
                      }`}
                    >
                      {errors.mutualId ? errors.mutualId.message : "hidden"}
                    </span>
                  </div>

                  <div className="relative md:w-2/12 w-4/12 md:mr-2 mr-3">
                    <label
                      className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      Periodo
                    </label>
                    <select
                      {...register("periodoAnyo", {
                        onChange: (e) => {
                          setMesesData(
                            UtilsFunctions.getMesesSegunAnio(e.target.value)
                          );
                          setValue("periodoMes", "");
                        },
                        required: {
                          value: true,
                          message: "Obligatorio",
                        },
                      })}
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      <option hidden value="">
                        Año
                      </option>
                      {anyosData.map((anyo, i) => (
                        <option value={anyo} key={i}>
                          {anyo}
                        </option>
                      ))}
                    </select>
                    <span
                      className={`text-red-500 text-xs italic ${
                        !errors.periodoAnyo && "opacity-0 invisible"
                      }`}
                    >
                      {errors.periodoAnyo
                        ? errors.periodoAnyo.message
                        : "hidden"}
                    </span>
                  </div>


                  <div className="relative md:w-3/12 w-6/12 pt-1">
                    <select
                      {...register("periodoMes", {
                        onChange: (e) => {
                          setValue("numeroMes", e.target.selectedIndex);
                        },
                        required: {
                          value: true,
                          message: "Obligatorio",
                        },
                      })}
                      disabled={watch("periodoAnyo") === "" ? true : false}
                      className="block w-full mt-6 appearance-none bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      <option hidden value="">
                        Mes
                      </option>
                      {mesesData.map((mes, i) => (
                        <option value={mes} key={i}>
                          {mes}
                        </option>
                      ))}
                    </select>
                    <span
                      className={`text-red-500 text-xs italic ${
                        !errors.periodoMes && "opacity-0 invisible"
                      }`}
                    >
                      {errors.periodoMes ? errors.periodoMes.message : "hidden"}
                    </span>
                  </div>
                </div>

                <div className="flex flex-wrap">
                  <div className="md:w-2/12 w-3/12 mr-5">
                    <label
                      className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      Monto
                    </label>
                    <input
                      {...register("tasaMutual", {
                        validate: (value) => {
                          if (
                            value === "" ||
                            value === "%" ||
                            parseInt(value) < 0
                          ) {
                            return "Mayor a 0%";
                          }
                          return true;
                        },
                        onChange: () => {
                          setValue(
                            "tasaMutual",
                            getValues("tasaMutual")?.replace(/[^\d.,]/g, "") +
                              "%"
                          );
                        },
                      })}
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                    />
                    <span
                      className={`text-red-500 text-xs italic ${
                        !errors.tasaMutual && "opacity-0 invisible"
                      }`}
                    >
                      {errors.tasaMutual ? errors.tasaMutual.message : "hidden"}
                    </span>
                  </div>

                  <div className="md:w-4/12 mt-7">
                    <button
                  disabled={!botonGuardarHabilitado}
                  className={` ${!botonGuardarHabilitado? "bg-gray-500" : "bg-hercules-light-primary-button hover:shadow-lg hover:scale-105"}  text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400`}
                      type="submit"
                    >
                      Actualizar
                    </button>
                  </div>
                </div>
              </form>
            </div>
            {/*footer*/}
            <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                type="button"
                onClick={() => {
                  cerrarModal();
                  reset();
                }}
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
