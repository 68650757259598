import { unparse } from "papaparse";
import { useState } from "react";
import { toast } from "react-hot-toast";

const booleanToString = (boolean) => {
  if (boolean === true) {
    return "Sí";
  }
  return "No";
};

const sortListObjectsById = (list) => {
  if (list === undefined) {
    return [];
  } else {
    return list.sort((a, b) => {
      return a.id - b.id;
    });
  }
};

function cleanRut(rut) {
  return typeof rut === "string"
    ? rut.replace(/^0+|[^0-9kK]+/g, "").toUpperCase()
    : "";
}

function validateRut(rut) {
  if (typeof rut !== "string") {
    return false;
  }

  // if it starts with 0 we return false
  // so a rut like 00000000-0 will not pass
  if (/^0+/.test(rut)) {
    return false;
  }

  if (!/^0*(\d{1,3}(\.?\d{3})*)-?([\dkK])$/.test(rut)) {
    return false;
  }

  rut = cleanRut(rut);

  let t = parseInt(rut.slice(0, -1), 10);
  let m = 0;
  let s = 1;

  while (t > 0) {
    s = (s + (t % 10) * (9 - (m++ % 6))) % 11;
    t = Math.floor(t / 10);
  }

  const v = s > 0 ? "" + (s - 1) : "K";
  return v === rut.slice(-1);
}

function toLowercaseWithoutAccents(word) {
  // Convert the word to lowercase
  word = word.toLowerCase();

  // Normalize the word to NFD
  word = word.normalize("NFD");

  // Remove the accents
  word = word.replace(/[^\u0000-\u007F]/g, "");

  return word;
}

function rutFormatter(
  rut,
  options = {
    dots: false,
  }
) {
  rut = cleanRut(rut);

  let result;
  if (options.dots) {
    result = rut.slice(-4, -1) + "-" + rut.substr(rut.length - 1);
    for (let i = 4; i < rut.length; i += 3) {
      result = rut.slice(-3 - i, -i) + "." + result;
    }
  } else {
    result = rut.slice(0, -1) + "-" + rut.substr(rut.length - 1);
  }
  return result;
}

function rolFormatter(rol) {
  return (
    rol?.slice(5).replaceAll("_", " ").toLowerCase().slice(0, 1).toUpperCase() +
    rol?.slice(5).replaceAll("_", " ").toLowerCase().slice(1)
  );
}

function formatearHaberes(haberes) {
  const nuevoArray = [];
  haberes.forEach((haber) => {
    const nuevoHaber = {
      haberId: parseInt(haber.haber.id),
      valorFijo: haber.valorFijo == true ? 1 : 0,
      valorVariable: haber.valorVariable == true ? 1 : 0,
      valor: haber.valor,
    };
    nuevoArray.push(nuevoHaber);
  });
  return nuevoArray;
}

function integerFormatter(integer) {
  const formatter = new Intl.NumberFormat("es-CL", {
    styles: "currency",
    currency: "CLP",
    minimumFractionDigits: 0,
  });

  const roundedInteger = Math.round(integer);

  return formatter.format(roundedInteger);
}

function integerFormatterValidacionNull(integer) {
  const formatter = new Intl.NumberFormat("es-CL", {
    styles: "currency",
    currency: "CLP",
    minimumFractionDigits: 0,
  });

  const roundedInteger = Math.round(integer);

  if (integer === null) {
    console.log("integer", integer);
    return "";
  } else {
    return "$" + formatter.format(roundedInteger);
  }
}

function periodoToText(mes, anio) {
  const periodoText = {
    1: "Enero",
    2: "Febrero",
    3: "Marzo",
    4: "Abril",
    5: "Mayo",
    6: "Junio",
    7: "Julio",
    8: "Agosto",
    9: "Septiembre",
    10: "Octubre",
    11: "Noviembre",
    12: "Diciembre",
  };
  return `${periodoText[mes]}/${anio}`;
}

function arrayMeses() {
  const mes = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  return mes;
}

function obtenerAnyosHastaActual() {
  const anyoBase = 2022;
  const fechaActual = new Date();
  const anyoActual = fechaActual.getFullYear();
  const arrayAnyos = [];

  for (let index = anyoBase; index <= anyoActual; index++) {
    arrayAnyos.push(index);
  }

  return arrayAnyos.reverse();
}

function sueldoMinimoProporcional(
  sueldoMinimo,
  horasSemanales,
  maximoHorasSemanales
) {
  // console.log(sueldoMinimo, horasSemanales, maximoHorasSemanales);
  if (horasSemanales > 30) {
    return sueldoMinimo;
  } else {
    return Math.round((horasSemanales * sueldoMinimo) / maximoHorasSemanales);
  }
}

function limpiarTextoPlantillas(texto) {
  // Eliminar tildes
  const textoSinTildes = texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

  // Reemplazar el caracter "N°" por la palabra "Numero"
  const textoModificado = textoSinTildes.replace(/N°/g, "Numero ");

  return textoModificado;
}

function jsonToCSVv2(data, headers, fileName) {
  try {
    toast.loading("Generando archivo CSV...");
    const get = (obj, path) => {
      if (Array.isArray(path)) {
        const values = path.map((p) => {
          return p
            .split(".")
            .reduce(
              (a, c) =>
                a && a[c]
                  ? a[c] === true
                    ? "Verdadero"
                    : a[c]
                  : a[c] === false
                  ? "Falso"
                  : a[c],
              obj
            );
        });
        return values.filter((value) => typeof value === "string").join(" ");
      } else {
        return path
          .split(".")
          .reduce(
            (a, c) =>
              a && a[c]
                ? a[c] === true
                  ? "Verdadero"
                  : a[c]
                : a[c] === false
                ? "Falso"
                : a[c],
            obj
          );
      }
    };
    const extractedData = [];
    data.forEach((item) => {
      if (item.rut) {
        item.rut = UtilsFunctions.rutFormatter(item.rut, { dots: true });
      }
      if (item.trabajadorRut) {
        item.trabajadorRut = UtilsFunctions.rutFormatter(item.trabajadorRut, {
          dots: true,
        });
      }
      const rowData = {};
      headers.forEach((header) => {
        rowData[header.name] = get(item, header.key);
      });
      extractedData.push(rowData);
    });
    const csv = unparse(extractedData, { delimiter: ";" });
    const csvSinTildes = limpiarTextoPlantillas(csv);
    const blob = new Blob([csvSinTildes], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, fileName);
      toast.dismiss();
      toast.success("Archivo CSV generado con éxito");
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toast.dismiss();
        toast.success("Archivo CSV generado con éxito");
      }
    }
  } catch (error) {
    toast.dismiss();
    toast.error("Error al generar archivo CSV");
    console.log(error);
  }
}

function jsonToCSV(data, headers, fileName) {
  try {
    toast.loading("Generando archivo CSV...");
    const get = (obj, path) =>
      path
        .split(".")
        .reduce(
          (a, c) =>
            a && a[c]
              ? a[c] === true
                ? "Verdadero"
                : a[c]
              : a[c] === false
              ? "Falso"
              : a[c],
          obj
        );
    const extractedData = [];
    data.forEach((item) => {

      if (item.rut) {
        item.rut = UtilsFunctions.rutFormatter(item.rut, { dots: true });
      }

      if(fileName === "Remuneraciones Mensual"){
        if(item.diasTrabajados){
          item.diasTrabajados = item.diasTrabajados + " " + "dias"
        }
        item.sueldoBase
          ? (item.sueldoBase = "$" + UtilsFunctions.integerFormatter(item.sueldoBase))
          : (item.sueldoBase = "$0");

          item.cantidadHorasExtras
          ? (item.cantidadHorasExtras = item.cantidadHorasExtras + " " + "horas")
          : (item.cantidadHorasExtras = "0 horas");
  
          item.horasExtras
          ? (item.horasExtras = "$" + UtilsFunctions.integerFormatter(item.horasExtras))
          : (item.horasExtras = "$0");

          item.cantidadHorasDescuento
          ? (item.cantidadHorasDescuento = item.cantidadHorasDescuento + " " + "horas")
          : (item.cantidadHorasDescuento = "0 horas");
          
          item.horasDescuento
          ? (item.horasDescuento = "$" + UtilsFunctions.integerFormatter(item.horasDescuento))
          : (item.horasDescuento = "$0");

          item.gratificacion
          ? (item.gratificacion = "$" + UtilsFunctions.integerFormatter(item.gratificacion))
          : (item.gratificacion = "$0");

          item.otrosImponibles < 0
          ? (item.otrosImponibles = "-$" + UtilsFunctions.integerFormatter(item.otrosImponibles * -1))
          : (item.otrosImponibles = "$" + UtilsFunctions.integerFormatter(item.otrosImponibles));

          item.rentaImponible < 0
          ? (item.rentaImponible = "-$" + UtilsFunctions.integerFormatter(item.rentaImponible * -1))
          : (item.rentaImponible = "$" + UtilsFunctions.integerFormatter(item.rentaImponible));

          item.cargasFamiliares
          ? (item.cargasFamiliares = item.cargasFamiliares + " " + "cargas")
          : (item.cargasFamiliares = "0 cargas");
          
          item.valorCargas
          ? (item.valorCargas = "$" + UtilsFunctions.integerFormatter(item.valorCargas))
          : (item.valorCargas = "$0");

          item.colacion
          ? (item.colacion = "$" + UtilsFunctions.integerFormatter(item.colacion))
          : (item.colacion = "$0");

          item.otrosNoImponibles < 0
          ? (item.otrosNoImponibles = "-$" + UtilsFunctions.integerFormatter(item.otrosNoImponibles * -1))
          : (item.otrosNoImponibles = "$" + UtilsFunctions.integerFormatter(item.otrosNoImponibles));
          
          item.totalHaberes < 0
          ? (item.totalHaberes = "-$" + UtilsFunctions.integerFormatter(item.totalHaberes * -1))
          : (item.totalHaberes = "$" + UtilsFunctions.integerFormatter(item.totalHaberes));
          
          item.cotizacionPrevisional < 0
          ? (item.cotizacionPrevisional = "-$" + UtilsFunctions.integerFormatter(item.cotizacionPrevisional * -1))
          : (item.cotizacionPrevisional = "$" + UtilsFunctions.integerFormatter(item.cotizacionPrevisional));

          item.cotizacionSalud < 0
          ? (item.cotizacionSalud = "-$" + UtilsFunctions.integerFormatter(item.cotizacionSalud * -1))
          : (item.cotizacionSalud = "$" + UtilsFunctions.integerFormatter(item.cotizacionSalud));

          item.impuestoUnicoSegundaCategoria < 0
          ? (item.impuestoUnicoSegundaCategoria = "-$" + UtilsFunctions.integerFormatter(item.impuestoUnicoSegundaCategoria * -1))
          : (item.impuestoUnicoSegundaCategoria = "$" + UtilsFunctions.integerFormatter(item.impuestoUnicoSegundaCategoria));

          item.otrosDescuentos < 0
          ? (item.otrosDescuentos = "-$" + UtilsFunctions.integerFormatter(item.otrosDescuentos * -1))
          : (item.otrosDescuentos = "$" + UtilsFunctions.integerFormatter(item.otrosDescuentos));
          
          item.totalDescuentos < 0
          ? (item.totalDescuentos = "-$" + UtilsFunctions.integerFormatter(item.totalDescuentos * -1))
          : (item.totalDescuentos = "$" + UtilsFunctions.integerFormatter(item.totalDescuentos));

          item.anticipos < 0
          ? (item.anticipos = "-$" + UtilsFunctions.integerFormatter(item.anticipos * -1))
          : (item.anticipos = "$" + UtilsFunctions.integerFormatter(item.anticipos));
          
          item.descuentosCuotas
          ? (item.descuentosCuotas = "$" + UtilsFunctions.integerFormatter(item.descuentosCuotas))
          : (item.descuentosCuotas = "$0");

          item.saldoNegativo
          ? (item.saldoNegativo = "$" + UtilsFunctions.integerFormatter(item.saldoNegativo))
          : (item.saldoNegativo = "$0");

          item.liquidoAPagar < 0
          ? (item.liquidoAPagar = "-$" + UtilsFunctions.integerFormatter(item.liquidoAPagar * -1))
          : (item.liquidoAPagar = "$" + UtilsFunctions.integerFormatter(item.liquidoAPagar));

        }

      const rowData = {};
      headers.forEach((header) => {
        rowData[header.name] = get(item, header.key);
      });
      extractedData.push(rowData);
    });
    const csv = unparse(extractedData, { delimiter: ";" });
    const csvSinTildes = limpiarTextoPlantillas(csv);
    const blob = new Blob([csvSinTildes], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, fileName);
      toast.dismiss();
      toast.success("Archivo CSV generado con éxito");
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toast.dismiss();
        toast.success("Archivo CSV generado con éxito");
      }
    }
  } catch (error) {
    toast.dismiss();
    toast.error("Error al generar archivo CSV");
    console.log(error);
  }
}

function jsonToCSV3(data, headers, fileName) {
  try {
    toast.loading("Generando archivo CSV...");
    const get = (obj, path) =>
      path
        .split(".")
        .reduce(
          (a, c) =>
            a && a[c]
              ? a[c] === true
                ? "Verdadero"
                : a[c]
              : a[c] === false
              ? "Falso"
              : a[c],
          obj
        );
    const extractedData = [];
    data.forEach((item) => {
      if (item.activo === true) {
        item.activo = "Si";
      } else if (item.activo === false) {
        item.activo = "No";
      }

      if (item.rut) {
        item.rut = UtilsFunctions.rutFormatter(item.rut, { dots: true });
      }
      if (item.trabajadorRut) {
        item.trabajadorRut = UtilsFunctions.rutFormatter(item.trabajadorRut, {
          dots: true,
        });
      }

      if (fileName === "Haberes") {
        if (item.haberesDt.imponible === true) {
          item.haberesDt.imponible = "Si";
        } else {
          item.haberesDt.imponible = "No";
        }

        if (item.haberesDt.tributable === true) {
          item.haberesDt.tributable = "Si";
        } else {
          item.haberesDt.tributable = "No";
        }

        if (item.haberesDt.constituyeRemuneracion === true) {
          item.haberesDt.constituyeRemuneracion = "Si";
        } else {
          item.haberesDt.constituyeRemuneracion = "No";
        }
      }

      if (fileName === "Grupo de Sindicatos") {
        item.rutSindicato = UtilsFunctions.rutFormatter(item.rutSindicato, {
          dots: true,
        });
      }

      if (fileName === "Jornadas Laborales") {
        if (item.tipoHorasExtra.recargo) {
          item.tipoHorasExtra.recargo = (
            (7 * item.tipoHorasExtra.recargo) /
            30 /
            item.horasSemana
          )
            .toFixed(4)
            .replace(".", ",");
        }
        item.horasSemana = item.horasSemana + " " + "horas"
      }

      if (fileName === "Seccion/Area") {
        item.empresa =
          item.seccionesHasCentrosCostos[0].centroCosto.empresa.nombreFantasia;
        item.centroCosto = item.seccionesHasCentrosCostos.map((e, index) =>
          index === 0 ? e.centroCosto.nombre : ", " + e.centroCosto.nombre
        );
      }

      if (fileName === "Contratos_Anexos") {
        item.rutTrabajador = UtilsFunctions.rutFormatter(item.rutTrabajador, {
          dots: true,
        });

        item.fechaTerminoContrato = item.fechaTerminoContrato ? item.fechaTerminoContrato : "Indefinido";        
        item.estado =
            item.estado == 1
            ? "Vigente"
            : item.estado == 2
            ? "Vencido"
            : item.estado == 3 && "Finiquitado";
      }

      if (fileName === "Trabajadores Finiquitados") {
        item.total
          ? (item.total = "$" + UtilsFunctions.integerFormatter(item.total))
          : (item.total = "$0");

        item.causal ? (item.causal = item.causal) : (item.causal = "N/A");
      }

      if (fileName === "Horas Extra") {
        item.recargo = (Math.round((item.recargo - 1) * 100) + "%").replace(
          ".",
          ","
        );
      }

      if (fileName === "Movimientos de Haberes y Descuentos") {
        if (item.semanaCorrida === true) {
          item.semanaCorrida = "Si";
        } else {
          item.semanaCorrida = "No";
        }
      }

      if (fileName === "Descuentos en Cuotas") {
        if (item.activo === "Si") {
          item.activo = "Vigente";
        } else if (item.activo === "No") {
          item.activo = "Liquidada";
        }
        item.monto
        ? (item.monto = "$" + UtilsFunctions.integerFormatter(item.monto))
        : (item.monto = "$0");
      }

      if (fileName === "Liquidaciones") {
        if (item.liquidacionCalculada === true) {
          item.liquidacionCalculada = "Calculada";
        } else {
          item.liquidacionCalculada = "Pendiente";
    }
  }

      if (fileName === "Informe Haberes" || fileName === "Informe Descuentos") {
        item.valor
          ? (item.valor = "$" + UtilsFunctions.integerFormatter(item.valor))
          : (item.valor = "$0");
      }

      if (fileName === "Informe Centro Costos") {

        item.totalBrutoHombres
          ? (item.totalBrutoHombres =
              "$" + UtilsFunctions.integerFormatter(item.totalBrutoHombres))
          : (item.totalBrutoHombres = "$0");

        item.totalImponibleHombres
          ? (item.totalImponibleHombres =
              "$" + UtilsFunctions.integerFormatter(item.totalImponibleHombres))
          : (item.totalImponibleHombres = "$0");

        item.totalNoImponibleHombres
          ? (item.totalNoImponibleHombres =
              "$" +
              UtilsFunctions.integerFormatter(item.totalNoImponibleHombres))
          : (item.totalNoImponibleHombres = "$0");

        item.aportePatronalHomres
          ? (item.aportePatronalHomres =
              "$" + UtilsFunctions.integerFormatter(item.aportePatronalHomres))
          : (item.aportePatronalHomres = "$0");

        item.totalBrutoMujeres
          ? (item.totalBrutoMujeres =
              "$" + UtilsFunctions.integerFormatter(item.totalBrutoMujeres))
          : (item.totalBrutoMujeres = "$0");

        item.totalImponibleMujeres
          ? (item.totalImponibleMujeres =
              "$" + UtilsFunctions.integerFormatter(item.totalImponibleMujeres))
          : (item.totalImponibleMujeres = "$0");

        item.totalNoImponibleMujeres
          ? (item.totalNoImponibleMujeres =
              "$" +
              UtilsFunctions.integerFormatter(item.totalNoImponibleMujeres))
          : (item.totalNoImponibleMujeres = "$0");

        item.aportePatronalMujeres
          ? (item.aportePatronalMujeres =
              "$" + UtilsFunctions.integerFormatter(item.aportePatronalMujeres))
          : (item.aportePatronalMujeres = "$0");
      }

      if (fileName === "Resumen Pago Imposiciones") {
        item.total
          ? (item.total = "$" + UtilsFunctions.integerFormatter(item.total))
          : (item.total = "$0");
      }

      if (fileName === "Usuarios") {
        item.roles =
          item.roles[0].nombre
            .slice(5)
            .replaceAll("_", " ")
            .toLowerCase()
            .slice(0, 1)
            .toUpperCase() +
          item.roles[0].nombre
            .slice(5)
            .replaceAll("_", " ")
            .toLowerCase()
            .slice(1);
      }

      if (fileName === "Movimientos de Trabajdores"){
        item.diasTrabajados = item.diasTrabajados + " " + "dias"
        item.horasExtra = item.horasExtra + " " + "horas"
        item.horasDescuento = item.horasDescuento + " " + "horas"
      }

      const rowData = {};
      headers.forEach((header) => {
        rowData[header.name] = get(item, header.key);
      });
      extractedData.push(rowData);
    });
    const csv = unparse(extractedData, { delimiter: ";" });
    const csvSinTildes = limpiarTextoPlantillas(csv);
    const blob = new Blob([csvSinTildes], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, fileName);
      toast.dismiss();
      toast.success("Archivo CSV generado con éxito");
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toast.dismiss();
        toast.success("Archivo CSV generado con éxito");
      }
    }
  } catch (error) {
    toast.dismiss();
    toast.error("Error al generar archivo CSV");
    console.log(error);
  }
}

function getRefreshToken() {
  return localStorage.getItem("refresh-token");
}

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

function getEmpresaPeriodoInfo() {
  const empresa = localStorage.getItem("empresa");
  const rutEmpresa = localStorage.getItem("token-rut");
  const periodoMes = localStorage.getItem("periodoMes");
  const periodoAnyo = localStorage.getItem("periodoAnyo");

  return {
    empresa,
    rutEmpresa: rutFormatter(rutEmpresa, { dots: true }),
    periodo: periodoToText(periodoMes, periodoAnyo),
  };
}

function getEmpresaCCostoInfo() {
  const empresa = localStorage.getItem("empresa");
  const rutEmpresa = localStorage.getItem("token-rut");
  const centroCosto = localStorage.getItem("centroCosto");
  const centroCostoId = localStorage.getItem("centroCostoId");

  return {
    empresa,
    rutEmpresa: rutFormatter(rutEmpresa, { dots: true }),
    centroCosto: centroCosto,
  };
}

function integerToMonth(int) {
  const month = {
    1: "Enero",
    2: "Febrero",
    3: "Marzo",
    4: "Abril",
    5: "Mayo",
    6: "Junio",
    7: "Julio",
    8: "Agosto",
    9: "Septiembre",
    10: "Octubre",
    11: "Noviembre",
    12: "Diciembre",
  };

  return month[int];
}

function monthToInteger(month) {
  const int = {
    Enero: 1,
    Febrero: 2,
    Marzo: 3,
    Abril: 4,
    Mayo: 5,
    Junio: 6,
    Julio: 7,
    Agosto: 8,
    Septiembre: 9,
    Octubre: 10,
    Noviembre: 11,
    Diciembre: 12,
  };

  return int[month];
}

function getMesesSegunAnio(anio) {
  const fechaActual = new Date();
  const anyoActual = fechaActual.getFullYear();
  const mesActual = fechaActual.getMonth() + 1;
  const arrayMeses = [];
  if (anio == anyoActual) {
    for (let index = 1; index <= mesActual; index++) {
      arrayMeses.push(integerToMonth(index));
    }
  } else {
    for (let index = 1; index <= 12; index++) {
      arrayMeses.push(integerToMonth(index));
    }
  }
  return arrayMeses;
}

function test(mes) {
  if (mes + 5 > 12) {
    return [...Array(12 - mes + 1).keys()].map((i) => i + mes);
  } else {
    return [...Array(5).keys()].map((i) => i + mes);
  }
}

function checkForMissingHeaders(headers, requiredHeaders, nombrePlantilla) {
  if(!headers){
    throw new Error("Error al importar " + nombrePlantilla + ": El archivo esta vacio o el formato de las cabeceras es incorrecto")
  }
  const missingHeaders = requiredHeaders.filter(
    (header) => !headers.includes(header)
  );
  return missingHeaders;
}

function validarColumnasPlantilla(data, headersObligatorios, nombrePlantilla) {
  const headers = data[0] && Object.keys(data[0]);
  const missingHeaders = UtilsFunctions.checkForMissingHeaders(
    headers,
    headersObligatorios,
    nombrePlantilla
  );
  if (missingHeaders.length > 0) {
    throw new Error("Error al importar " + nombrePlantilla + ": Faltan las siguientes columnas: " +
    missingHeaders.join(", "))
  }
}

const UtilsFunctions = {
  validarColumnasPlantilla,
  checkForMissingHeaders,
  toLowercaseWithoutAccents,
  booleanToString,
  sortListObjectsById,
  rutFormatter,
  validateRut,
  cleanRut,
  rolFormatter,
  formatearHaberes,
  integerFormatter,
  integerFormatterValidacionNull,
  periodoToText,
  arrayMeses,
  sueldoMinimoProporcional,
  jsonToCSV,
  jsonToCSV3,
  getRefreshToken,
  getCookie,
  getEmpresaPeriodoInfo,
  getEmpresaCCostoInfo,
  obtenerAnyosHastaActual,
  getMesesSegunAnio,
  integerToMonth,
  monthToInteger,
  jsonToCSVv2,
  test,
};

export default UtilsFunctions;
