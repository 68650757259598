import React, { useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import EdicionBlanco from "../assets/EdicionBlanco.svg";
import EdicionAzul from "../assets/EdicionAzul.svg";
import ParametrosBlanco from "../assets/ParametrosBlanco.svg";
import ParametrosAzul from "../assets/ParametrosAzul.svg";
import MovimientosBlanco from "../assets/MovimientosBlanco.svg";
import MovimientosAzul from "../assets/MovimientosAzul.svg";
import InformesBlanco from "../assets/InformesBlanco.svg";
import InformesAzul from "../assets/InformesAzul.svg";
import HerramientasBlanco from "../assets/HerramientasBlanco.svg";
import HerramientasAzul from "../assets/HerramientasAzul.svg";
import { AiFillHome } from "react-icons/ai";

import { useState } from "react";
import UtilsFunctions from "../utils/UtilsFunctions";
import { useStateContext } from "../contexts/ContextProvider";
import { DarkModeContext } from "../contexts/DarkModeProvider";

export default function SidebarItem({ item, func, isOpen, setOpen }) {
  // console.log("este es item", item)
  // const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const location = useLocation();
  const { activeMenu, setActiveMenu } = useStateContext();
  const userTheme = localStorage.getItem("userTheme");
  const { darkMode } = useContext(DarkModeContext);

  const SidebarTitleIcon = ({ title, color }) => {
    switch (title) {
      case "Inicio":
        if (color === "blanco" || darkMode) {
          return <AiFillHome className="h-6 w-6 ml-3" />;
        }
        if (color === "azul" && !darkMode) {
          return <AiFillHome className="h-6 w-6 text-hercules-light-secondary-button ml-3" />;
        }
      case "Edición":
        if (color === "blanco" || darkMode) {
          return <img src={EdicionBlanco} height="24" width="24" className="ml-3" />;
        }
        if (color === "azul"  && !darkMode) {
          return <img src={EdicionAzul} height="24" width="24" className="ml-3"/>;

        }
      case "Parámetros":
        if (color === "blanco" || darkMode) {
          return <img src={ParametrosBlanco} height="24" width="24" className="ml-3"/>;        }
        if (color === "azul" && !darkMode) {
          return <img src={ParametrosAzul} height="24" width="24" className="ml-3"/>;
        }
      case "Movimientos":
        if (color === "blanco" || darkMode) {
          return <img src={MovimientosBlanco} height="24" width="24" className="ml-3"/>;
        }
        if (color === "azul" && !darkMode) {
          return <img src={MovimientosAzul} height="24" width="24" className="ml-3"/>;
        }
      case "Informes":
        if (color === "blanco" || darkMode) {
          return <img src={InformesBlanco} height="24" width="24" className="ml-3"/>;
        }
        if (color === "azul" && !darkMode) {
          return <img src={InformesAzul} height="24" width="24" className="ml-3"/>;
        }
      case "Herramientas":
        if (color === "blanco" || darkMode) {
          return <img src={HerramientasBlanco} height="24" width="24" className="ml-3"/>;
        }
        if (color === "azul" && !darkMode) {
          return <img src={HerramientasAzul} height="24" width="24" className="ml-3"/>;
        }
      default:
        return null;
    }
  };

  const [parentMenus, setParentMenus] = useState([]);

  if (item.childrens) {
    return (
      <div className={``}>
        <div
          onClick={() => {
            setOpen(item.title);
            if (item.title === "Inicio") {
              navigate("/inicio");
            }
          }}
          className={`mx-6 h-12  text-xl hover:cursor-pointer  ${
            isOpen
              ? `dark:bg-hercules-dark-primary-button dark:text-white bg-white text-black ${
                  item.title === "Inicio" ? "rounded-md" : "rounded-t-md"
                } font-medium mb-0`
              : location.pathname.includes(
                  UtilsFunctions.toLowercaseWithoutAccents(item.title)
                )
              ? "font-normal dark:bg-hercules-dark-primary-button dark:text-white bg-white text-black rounded-md"
              : "font-normal hover:bg-hercules-pure-white hover:bg-opacity-10 rounded-md"
          }  flex text-white  items-center text-base mb-1`}
        >
          {isOpen ? (
            <SidebarTitleIcon title={item.title} color="azul"/>
          ) : (
            location.pathname.includes(UtilsFunctions.toLowercaseWithoutAccents(item.title)) ? (
              <SidebarTitleIcon title={item.title} color="azul"/>
            ) : (
              <SidebarTitleIcon title={item.title} color="blanco" />
            )
          )}
          {/* Arriba van los iconos */}
          <p className="text-lg ml-3 transition ease-in-out duration-300 ">
            {item.title}
          </p>
          {/* <MdKeyboardArrowDown className="ml-auto text-lg" /> */}
        </div>
        <div
          className={`mx-6 ease-in-out duration-300 ${
            isOpen ? "translate-x-0" : "-translate-x-full"
          }`}
        >
          {isOpen &&
            item.childrens.map((children, i) =>
              !children.childrens.length > 0 ? (
                <Link
                  to={children.path}
                  key={i}
                  onClick={()=>{func()}}
                >
                  <p
                    className={`py-3 px-7 text-base   ${
                      item.childrens.length - 1 === i && "rounded-b-md mb-1"
                    } ${
                      location.pathname === "/" + children.path
                        ? "dark:text-white text-black bg-opacity-100 bg-white dark:bg-hercules-dark-primary-button font-medium "
                        : "hover:bg-white font-normal hover:text-white hover:bg-opacity-20 text-white bg-hercules-light-primary bg-opacity-10"
                    }`}
                  >
                    {children.title}
                  </p>
                </Link>
              ) : (
                <React.Fragment key={i}>
                  <div
                    onClick={() => setOpenSubMenu(!openSubMenu)}
                    key={children.title}
                    className={`py-3 pl-7 hover:cursor-pointer ${
                      isOpen ? "translate-x-0" : "-translate-x-full"
                    } ease-in-out flex items-center ${
                      location.pathname.includes(
                        UtilsFunctions.toLowercaseWithoutAccents(children.title)
                      )
                        ? "text-black bg-white dark:bg-hercules-dark-primary-button dark:text-white font-medium"
                        : "hover:bg-white hover:bg-opacity-20 bg-hercules-light-primary bg-opacity-10"
                    }  text-white`}
                  >
                    <p className=" text-base ">{children.title}</p>
                  </div>
                  <div className="">
                    {openSubMenu &&
                      children.childrens.map((subChildren, i) => (
                        <Link to={subChildren.path} key={subChildren.title} onClick={()=>{func()}}>
                          <p
                            className={`pl-12 py-3 text-normal   ${
                              location.pathname === "/" + subChildren.path
                                ? "text-black bg-white dark:bg-hercules-dark-primary-button dark:text-white font-medium"
                                : "hover:bg-white hover:bg-opacity-20 bg-hercules-light-primary bg-opacity-10 text-white"
                            }`}
                          >
                            {subChildren.title}
                          </p>
                        </Link>
                      ))}
                  </div>
                </React.Fragment>
              )
            )}
        </div>
      </div>
    );
  } else {
    return (
      <Link to={item.path}>
        <p className="">{item.title}</p>
      </Link>
    );
  }
}
