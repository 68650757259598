import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { axiosPrivate } from "../../../common/axiosPrivate";
import { useStateContext } from "../../../contexts/ContextProvider";

export default function AdvertenciaEliminarContratoAnexo({data, onClose, onCloseAlert}){

  console.log("data", data)

  const { refreshTable, setRefreshTable, screenSize, contratoId } = useStateContext();
  const [botonGuardarHabilitado, setBotonGuardarHabilitado] = useState(true)

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };


    const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
  
    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`,
        ClientId: `${token}`,
      },
    };
  
    async function eliminarContratoAnexo(){
      setBotonGuardarHabilitado(false)
        toast.loading("Eliminando", estilosAlerta)
        try {
          if(parseInt(data.version) !== 1){
            await axiosPrivate.delete(
              `${api_service}/api/v1/contratos/anexos/${data.contratoAnexoId}`,
              Authorization
            );
            setRefreshTable(!refreshTable)
            onClose(); 
            toast.dismiss()
            toast.success("Anexo eliminado con éxito", estilosAlerta) 
          }else{
            await axiosPrivate.delete(
              `${api_service}/api/v1/contratos/${contratoId}`,
              Authorization
            );  
            setRefreshTable(!refreshTable)
            onClose();
            toast.dismiss()
            toast.success("Contrato eliminado con éxito", estilosAlerta)  
          }
          setBotonGuardarHabilitado(true)
        } catch (error) {
          console.log("Error", error)
          toast.dismiss()
          setBotonGuardarHabilitado(true)
          toast.error(error.response.data.message? error.response.data.message : "Error al eliminar Contrato / Anexo", estilosAlerta)
        }
      }
    
    
    return (
        <>
      <div
        className="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center"
        id="modal-id"
      >
        <div className="relative w-auto my-24 mx-auto max-w-md">
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col max-h-[85vh] overflow-y-auto w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t dark:bg-hercules-dark-bg-100 ">
                  <h3 className="text-3xl text-hercules-light-font-secondary font-semibold leading-normal dark:text-white">
                    Advertencia
                  </h3>
                </div>
    
                {/*body*/}
                <div  className="relative p-6 flex-auto overflow-auto dark:bg-hercules-dark-bg-200">
                  <div className="flex flex-wrap -mx-3">
                    <div className="w-full pl-3">
                      <p className="dark:text-white text-hercules-light-font-secondary">
                        ¿Está seguro de que desea eliminar este contrato/anexo?
                      </p>
                    </div>
                  </div>
                </div>
    
                {/*footer*/}
                <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-5 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 ease-in-out transition-all duration-400"
                    type="button"
                    onClick={() => onCloseAlert()}
                    disabled={!botonGuardarHabilitado}
                  >
                    No
                  </button>
                  <button
                  disabled={!botonGuardarHabilitado}
                  className={` ${!botonGuardarHabilitado? "bg-gray-500" : "bg-hercules-light-primary-button hover:shadow-lg hover:scale-105"}  text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400`}
                    type="button"
                    onClick={() => eliminarContratoAnexo()}
                  >
                    Si
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
        className=" opacity-25 fixed inset-0 z-40 bg-black"
        id="modal-id-backdrop"
      ></div>
        </>
      );
    }