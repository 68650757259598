import toast from "react-hot-toast";

const optionArt22 = [
  {
    id: true,
    nombre: "Si",
  },
  {
    id: false,
    nombre: "No",
  },
];

const trabajoPesado = [
  {
    id: 0,
    nombre: "No",
  },
  {
    id: 1,
    nombre: "Trabajo menos pesado 2%",
  },
  {
    id: 2,
    nombre: "Trabajo pesado 4%",
  },
];

function agregarHaber(haberes, setTablaHaberesEditada, haberesAnexo, setHaberesAnexo, setTieneSemanaCorrida, setTieneGratificaciones, estilosAlerta) {
    if (!haberes) {
      return null;
    }

    if (/^ *$/.test(haberes.valor) && !haberes.valorVariable) {
      console.log("Valor vacio");
      return null;
    }

    setTablaHaberesEditada(true);
    if (haberes.valorVariable) {
      haberes.valor = -1;
    }
    toast.loading("Agregando Haber", estilosAlerta)
    try {
    const nuevoArray = [...haberesAnexo, haberes];
    setHaberesAnexo(nuevoArray);
    const encontrarSemanaCorrida = nuevoArray.find(
      (e) => e.haber.haberesDt.id == "4"
    );
    if (encontrarSemanaCorrida?.haber) {
      setTieneSemanaCorrida(true);
    } else {
      setTieneSemanaCorrida(false);
    }
    const encontrarGratificacion6 = nuevoArray.find(
      (e) => e.haber.haberesDt.id === 6
    );
    const encontrarGratificacion22 = nuevoArray.find(
      (e) => e.haber.haberesDt.id === 22
    );
    if (encontrarGratificacion22 || encontrarGratificacion6) {
      setTieneGratificaciones(true);
    } else {
      setTieneGratificaciones(false);
    }
      toast.dismiss()
      toast.success("Haber agregado con éxito", estilosAlerta)  
    } catch (error) {
      console.log("Error", error)
      toast.dismiss()
      toast.error(error.response.data.message ? error.response.data.message : "Error al agregar Haber", estilosAlerta)
    }
  }

  // async function onSubmit(data) {
  //   setBotonGuardarHabilitado(false)
  //   try {
  //     if (!haberesAnexo.find((e) => e.haber.haberesDt.codigo === 2101)) {
  //       toast.dismiss();
  //       toast.error("El contrato debe tener un Haber de Sueldo Base");
  //       return null;
  //     }

  //     const anyoAnexo = data.fechaAnexo && data.fechaAnexo.split("-")[0];
  //     const mesAnexo = data.fechaAnexo && data.fechaAnexo.split("-")[1];
  //     const diaAnexo = data.fechaAnexo && data.fechaAnexo.split("-")[2];
  //     const fechaAnexo =
  //       data.fechaAnexo && `${diaAnexo}-${mesAnexo}-${anyoAnexo}`;

  //     const anyoTerminoContrato = data.fechaTerminoAnexo
  //       ? data.fechaTerminoAnexo.split("-")[0]
  //       : null;
  //     const mesTerminoContrato = data.fechaTerminoAnexo
  //       ? data.fechaTerminoAnexo.split("-")[1]
  //       : null;
  //     const diaTerminoContrato = data.fechaTerminoAnexo
  //       ? data.fechaTerminoAnexo.split("-")[2]
  //       : null;
  //     const fechaTerminoContrato = `${diaTerminoContrato}-${mesTerminoContrato}-${anyoTerminoContrato}`;

  //     const payload = {
  //       contratoId: modalData.fila.id,
  //       estado: 1,
  //       trabajadorId: modalData.fila.trabajador.id,
  //       fechaAnexo: fechaAnexo,
  //       fechaTerminoContrato: data.fechaTerminoAnexo
  //         ? fechaTerminoContrato
  //         : null,
  //       articulo22: data.articulo22 == "true" ? true : false,
  //       cargoId: data.cargoId,
  //       trabajoPesado: data.trabajoPesadoId,
  //       gratificacionId: data.gratificacionId,
  //       plazoContratoId: data.plazoContratoId,
  //       jornadaId: data.articulo22 == "true" ? 0 : data.jornadaId,
  //       seccionId: data.seccionId,
  //       centroCostoId: data.centroCostoId,
  //       semanaCorrida: tieneSemanaCorrida,
  //       haberes: UtilsFunctions.formatearHaberes(haberesAnexo),
  //     };
  //     setPayloadSinGratificacion(payload)

  //     if (!haberesAnexo.find((e) =>e.haber.haberesDt.codigo === 2106 ||e.haber.haberesDt.codigo === 2122)) {
  //       toast.dismiss();
  //       setShowModalGratificacionLegal(true)
  //       setBotonGuardarHabilitado(true)
  //       return null;
  //     }

  //     toast.loading("Creando Anexo", estilosAlerta);
  //     await axiosPrivate.post(
  //       `${api_service}/api/v1/trabajadores/nuevoanexocontrato`,
  //       payload,
  //       Authorization
  //     );
  //     toast.dismiss();
  //     toast.success("Anexo creado con éxito", estilosAlerta);
  //     setRefreshTable(!refreshTable);
  //     setBotonGuardarHabilitado(true)
  //     reset();
  //     onClose();
  //   } catch (error) {
  //     toast.dismiss();
  //     toast.error(error.response.data.message ? error.response.data.message : "Error al crear Anexo", estilosAlerta);
  //     console.log(error);
  //     setBotonGuardarHabilitado(true)
  //   }
  // }

  const functionsContratos = {
    agregarHaber,
    optionArt22,
    trabajoPesado,
  }

  export default functionsContratos