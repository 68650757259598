import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStateContext } from "../../contexts/ContextProvider";
import { ColumnFilter } from "../../components";
import {
  getTrabajadores,
  hideLoader,
  limpiarInfoMenuEdicion,
  showLoader,
} from "../../Redux/Actions";
import UtilsFunctions from "../../utils/UtilsFunctions";
import SimpleTable from "../../components/SimpleTable";
import RutColumnFilter from "../../components/RutColumnFilter";
import ModalRegistroFamiliar from "../../components/Movimientos/RegistroFamiliar/ModalRegistroFamiliar";

export default function RegistroFamiliar(){

    const { refreshTable, activeMenu } = useStateContext();
    const dispatch = useDispatch();
  
    const [showModal, setShowModal] = useState(false);
      const [rowModalData, setRowModalData] = useState([]);
  
    useEffect(() => {
      const fetchData = async () => {
        dispatch(showLoader());
        await Promise.all([
          dispatch(getTrabajadores()),
        ]);
        dispatch(hideLoader());
      };
  
      fetchData().catch((error) => {
        console.log(error.response.data.message);
        dispatch(hideLoader());
      });
    }, [refreshTable, dispatch]);
  
    useEffect(() =>{
      return () => {
        dispatch(limpiarInfoMenuEdicion("trabajadores"))
      }
    },[])
  
    const trabajadoresData = useSelector((state) => state.trabajadores);

    const DescuentosFijosTabla = [
        {
          Header: "RUT",
          accessor: "rut",
          Cell: ({ value }) => UtilsFunctions.rutFormatter(value, { dots: true }),
          Filter: RutColumnFilter,
        },
        {
          Header: "Trabajador",
          accessor: (d) =>
            d.nombres + " " + d.apellido_Paterno + " " + d.apellido_Materno,
          Filter: ColumnFilter,
        },
      ];

      return (
        <div
          className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
            activeMenu && "md:w-[calc(100vw_-_23rem)]"
          }`}
        >
          <div className="titulo-Menu">Movimientos</div>
          <div className="titulo-Pagina">Registro Familiar</div>
    
              <div className="h-full md:mr-12 rounded-lg pt-5">
            <SimpleTable
              col={DescuentosFijosTabla}
              tableData={trabajadoresData || []}
              primaryButton={{
                type: "editar",
                onClickFunc: (data) => {
                  setShowModal(true);
                  setRowModalData(data);
                },
                active: true,
              }}
              exportCsv={false}
            />
          </div>
        
          {showModal && (
          <ModalRegistroFamiliar
          isVisible={showModal}
          onClose={() => setShowModal(false)}
          modalData={rowModalData}
        />
          )}
        </div>
      );
    
}