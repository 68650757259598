import React, { useEffect, useState } from "react";
import ColumnFilter from "../../../ColumnFilter";
import HaberesDescuentosTable from "../HaberesDescuentosTable";
import { axiosPrivate } from "../../../../common/axiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import UtilsFunctions from "../../../../utils/UtilsFunctions";
import { toast } from "react-hot-toast";
import { getHaberes, limpiarInfoMenuEdicion } from "../../../../Redux/Actions";
import SpinnerRevisarHabDesc from "../../../SpinnerEspecificoRevHabDesc";
import { useStateContext } from "../../../../contexts/ContextProvider";

export default function HaberesAdicionalesTabla({
  modalDataContrato,
}) {
  const {
    register,
    reset,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const periodoId = localStorage.getItem("periodoId");
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true)
  const { screenSize } = useStateContext();

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
    },
  };

  const formatter = new Intl.NumberFormat("es-CL", {
    styles: "currency",
    currency: "CLP",
    minimumFractionDigits: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await Promise.all([
        dispatch(getHaberes()),
        obtenerHaberesAdicionales(modalDataContrato)
      ]);
      setIsLoading(false);
    };
    fetchData().catch((error) => {
      console.log(error.response.data.message);
      setIsLoading(false);
    });
  }, [dispatch, modalDataContrato]);

  useEffect(() =>{
    return () => {
      dispatch(limpiarInfoMenuEdicion("getHaberes"))
    }
  },[])

  // console.log("isLoading", isLoading)

  const listaHaberes = useSelector((state) => state.getHaberes);

  const [haberesAdicionalesTabla, setHaberesAdicionalesTabla] = useState([]);

  async function obtenerHaberesAdicionales(modalDataContrato) {
    try {
      const data = {
        contratoId: modalDataContrato.contratoId,
        anexoContratoId: modalDataContrato.anexoContratoId
          ? modalDataContrato.anexoContratoId
          : null,
        periodoId: periodoId,
        trabajadorId: modalDataContrato.trabajadorId,
      };
      const infoHaberes = await axiosPrivate.post(
        `${api_service}/api/v1/contratos/obtenerHaberesAdicionales`,
        data,
        Authorization
      );
      if (infoHaberes.status === 200) {
            setHaberesAdicionalesTabla(infoHaberes.data);
      }
    } catch (error) {
      console.log("Error", error);
    }
  }

  async function onSubmit(data) {
    toast.loading("Agregando Haber", estilosAlerta);
    try {
    const payload = {
      contratoId: modalDataContrato.contratoId,
      anexoContratoId:
        modalDataContrato.version !== 1
          ? modalDataContrato.anexoContratoId
          : null,
      periodoId: periodoId,
      haberId: data.haberId,
      monto: data.monto?.replace(/\./g, "").replace("$", "") || 0,
    };
      const info = await axiosPrivate.post(
        `${api_service}/api/v1/contratos/agregarHaberAdicional`,
        payload,
        Authorization
      );
      const nuevaInfo = info.data;
      const nuevoArray = [...haberesAdicionalesTabla, nuevaInfo];
      setHaberesAdicionalesTabla(nuevoArray);
      reset()
      toast.dismiss()
      toast.success("Haber agregado con éxito", estilosAlerta);
    } catch (error) {
        console.log("Error", error)
        toast.dismiss()
        toast.error(error.response.data.message? error.response.data.message : "Error al agregar Haber", estilosAlerta);  
    }
  }

  async function eliminarHaber(haberId, index) {
    toast.loading("Eliminando Haber", estilosAlerta)
    try {
      const info = await axiosPrivate.delete(
        `${api_service}/api/v1/contratos/eliminarHaberAdicional/${haberId}`,
        Authorization
      );
      haberesAdicionalesTabla.splice(index, 1);
      const arrayEditado = [...haberesAdicionalesTabla];
      setHaberesAdicionalesTabla(arrayEditado);
      toast.dismiss()
      toast.success("Haber eliminado con éxito", estilosAlerta)
    } catch (error) {
      console.log(error);
      toast.dismiss()
      toast.error(error.response.data.message? error.response.data.message : "Error al eliminar Haber", estilosAlerta)
    }
  }


  const COLUMNAS_HABERES_ADICIONALES = [
    {
      Header: "Nombre",
      accessor: "nombreHaber",
      Filter: ColumnFilter,
    },
    {
      Header: "Monto",
      accessor: "monto",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value === -1) {
          return "Variable";
        } else {
          return "$" + UtilsFunctions.integerFormatter(value);
        }
      },
    },
  ];

  return (
    <>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <h3 className="mb-2 font-semibold dark:text-white text-hercules-light-font-secondary">Haberes Adicionales</h3>
          <div className="w-full md:mb-0">
            <div className="flex flex-wrap">
              <div className="w-6/12 md:w-5/12 mb-3 md:mb-0 md:pr-3">
                <select
                  {...register("haberId", {
                    required: {
                      value: true,
                      message: "Obligatorio",
                    },
                    validate: (value) => {
                      if (value === "Seleccionar Haber") {
                        return "Obligatorio";
                      }
                      return true;
                    },
                  })}
                  name="haberId"
                  className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                >
                  <option hidden value="Seleccionar Haber">
                    Seleccionar
                  </option>
                  {listaHaberes?.map((haber, i) => (
                    <option key={i} value={haber.id}>
                      {haber.nombre}
                    </option>
                  ))}
                </select>
          {errors.haberId && (
            <span className="text-red-500 dark:text-red-400 text-xs italic">
              {errors.haberId.message}
            </span>
          )}

              </div>
              <div className="w-6/12 md:w-3/12 mb-3 md:mb-0 pl-3 md:pl-0 md:px-3">
                    <input
                      placeholder="Monto"
                      {...register("monto", {
                        validate: (value) => {
                          if (value === "" || value === "$" || value === "$0") {
                            return "Mayor a 0";
                          }
                          return true;
                        },    
                        onChange: () => {
                            setValue(
                              "monto",
                              "$" + formatter.format(getValues("monto")?.replace(/\D/g, ""))
                            );
                          },
            
                      })}
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                    />
          {errors.monto && (
            <span className="text-red-500 dark:text-red-400 text-xs italic">
              {errors.monto.message}
            </span>
          )}
              </div>
              <div className="w-6/12 md:w-3/12 mb-3 md:mb-0 md:pl-3">
                <button
                     className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-sm px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
                  type="submit"
                >
                  Agregar
                </button>
              </div>
            </div>
          </div>

          {isLoading ? (
            <div className="pt-6">
              <SpinnerRevisarHabDesc />
            </div>
          ) : (
          <HaberesDescuentosTable
            col={COLUMNAS_HABERES_ADICIONALES}
            tableData={haberesAdicionalesTabla}
            botonEliminar={true}
            borrarHaberDescuento={(haberPeriodoId, index) => {
              eliminarHaber(haberPeriodoId, index);
            }}
          />
          )}
        </div>
      </form>
    </>
  );
}
