import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, limpiarInfoMenuEdicion, listaSaludPeriodo, showLoader } from "../../Redux/Actions";
import { ColumnFilter } from "../../components/";
import { useStateContext } from "../../contexts/ContextProvider";
import SimpleTable from "../../components/SimpleTable";

export default function PrevisionSalud() {
  const { refreshTable, activeMenu } = useStateContext();
  const dispatch = useDispatch();

  const periodoId = localStorage.getItem("periodoId");
  const infoListaSalud = useSelector((state) => state.listaSaludPeriodo);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader());
      await Promise.all([
        dispatch(listaSaludPeriodo(periodoId))
      ]);
      dispatch(hideLoader());
    };

    fetchData().catch((error) => {
      console.log(error.response.data.message);
      dispatch(hideLoader());
    });
  }, [refreshTable, dispatch]);


  useEffect(() =>{
    return () => {
      dispatch(limpiarInfoMenuEdicion("listaSaludPeriodo"))
    }
  },[])

  const COLUMNS = [
    {
      Header: "Nombre",
      accessor: "salud.glosa",
      Filter: ColumnFilter,
    },
    {
      Header: "Porcentaje",
      accessor: (d) => (d.tasa + "%").replace(".", ","),
      Filter: ColumnFilter,
    },
    {
      Header: "Cod. Previred",
      accessor: "salud.codigo",
      Filter: ColumnFilter,
    },
    {
      Header: "Cod. L.R.E.",
      accessor: "salud.codigoLr",
      Filter: ColumnFilter,
    },
  ];

  return (
    <div
    className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
      activeMenu && "md:w-[calc(100vw_-_23rem)]"
    }`}
    >
      <div className="titulo-Menu">
        Edición
      </div>
      <div className="titulo-Pagina">
        Prevision Salud
      </div>
      <div className="h-full md:mr-12 rounded-lg pt-5">
          <SimpleTable
            col={COLUMNS}
            tableData={infoListaSalud || []}
            tableHeight="h-fix"
            exportCsvButton={false}
          />
      </div>
    </div>
  );
}
