import { axiosPrivate } from "../../../../common/axiosPrivate";
import React, { useState } from "react";
import { useStateContext } from "../../../../contexts/ContextProvider";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

export default function ModificarClave({ isVisible, onClose }) {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      claveUsuario: "",
      confirmarClaveUsuario: "",
    },
  });

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { refreshTable, setRefreshTable, screenSize } = useStateContext();

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const userId = localStorage.getItem("userId");
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const onSubmit = async (data) => {
    try {
      toast.loading("Editando clave", estilosAlerta);
      const payload = {
        userId: userId,
        newPassword: data.claveUsuario,
        oldPassword: data.oldPassword,
      };
      const response = await axiosPrivate.put(
        `${api_service}/api/v1/usuarios/password`,
        payload,
        Authorization
      );
      if (response.status === 200) {
        toast.dismiss();
        toast.success("Clave editada con éxito", estilosAlerta);
        setRefreshTable(!refreshTable);
        reset();
        onClose();
      }
    } catch (error) {
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al editar clave",
          estilosAlerta
      );
      console.log(error);
    }
  };

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-screen my-6 mx-auto max-w-lg">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col max-h-[85vh] overflow-y-auto w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t dark:bg-hercules-dark-bg-100 ">
                <h3 className="text-3xl text-hercules-light-font-secondary font-semibold leading-normal dark:text-white">
                  Cambiar clave
                </h3>
              </div>
              {/*body*/}
              <div className="relative p-6 flex-auto overflow-auto dark:bg-hercules-dark-bg-200">
                {/* Primera fila modal */}

                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-first-name"
                    >
                      Clave Actual
                    </label>

                    <div className="flex items-center relative">
                      <input
                        {...register("oldPassword", {
                          required: { value: true, message: "Obligatorio" },
                        })}
                        className="appearance-none pr-12 block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type={showOldPassword ? "text" : "password"}
                      />
                      <button
                        type="button"
                        className="absolute right-2 cursor-pointer"
                        onClick={() => setShowOldPassword(!showOldPassword)}
                      >
                        {showOldPassword ? (
                          <AiFillEyeInvisible className="text-2xl text-gray-500" />
                        ) : (
                          <AiFillEye className="text-2xl text-gray-500" />
                        )}
                      </button>
                    </div>

                    {errors.oldPassword && (
                      <p className="text-red-600 text-xs">
                        {errors.oldPassword.message}
                      </p>
                    )}
                  </div>
                </div>


                {/* Segunda fila modal */}

                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-first-name"
                    >
                      Nueva Clave
                    </label>

                    <div className="flex items-center relative">
                      <input
                        {...register("claveUsuario", {
                          required: { value: true, message: "Obligatorio" },
                          minLength: {
                            value: 6,
                            message: "Al menos 6 caracteres",
                          },
                          maxLength: {
                            value: 50,
                            message: "Máximo 50 caracteres",
                          },
                          pattern: {
                            value: /^\S*$/,
                            message: "No puede tener espacios en blanco",
                          },
                        })}
                        className="appearance-none pr-12 block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type={showPassword ? "text" : "password"}
                      />
                      <button
                        type="button"
                        className="absolute right-2 cursor-pointer"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <AiFillEyeInvisible className="text-2xl text-gray-500" />
                        ) : (
                          <AiFillEye className="text-2xl text-gray-500" />
                        )}
                      </button>
                    </div>

                    {errors.claveUsuario && (
                      <p className="text-red-600 text-xs">
                        {errors.claveUsuario.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-first-name"
                    >
                      Confirmar Clave
                    </label>
                    <input
                      {...register("confirmarClaveUsuario", {
                        validate: (value) =>
                          value === watch("claveUsuario") ||
                          "Las contraseñas no coinciden",
                      })}
                      className="appearance-none pr-12 block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="password"
                    />
                    {errors.confirmarClaveUsuario && (
                      <p className="text-red-600 text-xs">
                        {errors.confirmarClaveUsuario.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              {/*footer*/}
              <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                  type="button"
                  onClick={() => {
                    reset();
                    onClose();
                  }}
                >
                  Cerrar
                </button>
                <button
                  className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
                  type="submit"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </form>
    </>
  );
}
