import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getEventos,
  hideLoader,
  limpiarInfoMenuEdicion,
  showLoader,
} from "../../Redux/Actions";
import {
  ColumnFilter,
} from "../../components";
import { useStateContext } from "../../contexts/ContextProvider";
import SimpleTable from "../../components/SimpleTable";
import NuevoEvento from "../../components/Edicion/Eventos/NuevoEvento";
import EditarEvento from "../../components/Edicion/Eventos/EditarEvento";
import EliminarEvento from "../../components/Edicion/Eventos/EliminarEvento";
import ColumnFilterOptions from "../../components/ColumnFilterOptions";

export default function Eventos() {
  const dispatch = useDispatch();

  const [showModalNuevoRepresentante, setShowModalNuevoRepresentante] =
    useState(false);
  const [showModalEditarRepresentante, setShowModalEditarRepresentante] =
    useState(false);
    const [showModalEliminarEvento, setShowModalEliminarEvento] =
    useState(false);
  const [representanteData, setRepresentanteData] = useState(null);
  const { refreshTable, activeMenu } = useStateContext();
  const isLoading = useSelector((state) => state.loader);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader());
      await Promise.all([dispatch(getEventos())]);
      dispatch(hideLoader());
    };

    fetchData().catch((error) => {
      console.log(error.response.data.message);
      dispatch(hideLoader());
    });
  }, [refreshTable, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("getEventos"));
    };
  }, []);

  const eventos = useSelector((state) => state.getEventos);

  const COLUMNS = [
    {
      Header: "Nombre",
      accessor: "nombre",
      Filter: ColumnFilter,
    },
    {
      Header: "Habilitado",
      accessor: "activo",
      Filter: ColumnFilterOptions,
      Cell: ({ value }) => {
        if (value === true) {
          return "Si";
        } else {
          return "No";
        }
      },
    },
  ];

  return (
    <div
      className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
        activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">Edición</div>
      <div className="titulo-Pagina">Eventos</div>
      <div className="h-full md:mr-12 rounded-lg pt-5">
        <SimpleTable
          col={COLUMNS}
          tableData={eventos || []}
          primaryButton={{
            type: "editar",
            onClickFunc: (data) => {
              setShowModalEditarRepresentante(true);
              setRepresentanteData(data);
            },
            active: true,
          }}
          secondaryButton={{
            type: "eliminar",
            onClickFunc: (data) => {
                setShowModalEliminarEvento(true);
                setRepresentanteData(data);
              },
              active: true,  
          }}
          exportCsvButton={false}
          // exportCsv={() =>
          //   UtilsFunctions.jsonToCSV3(
          //     repLegales,
          //     headersCsv,
          //     "Representantes Legales"
          //   )
          // }
        />
      </div>
      <div>
        <button
          onClick={() => setShowModalNuevoRepresentante(true)}
          disabled={isLoading}
          className={`mt-3 ${
            isLoading
              ? "bg-gray-500"
              : "bg-hercules-light-primary-button hover:shadow-lg"
          } text-white dark:text-dark-primary font-medium uppercase text-normal px-4 py-3 rounded shadow  outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
        >
          Nuevo Evento
        </button>

        {showModalNuevoRepresentante && (
          <NuevoEvento
            isVisible={showModalNuevoRepresentante}
            onClose={() => setShowModalNuevoRepresentante(false)}
          />
        )}
        {showModalEditarRepresentante && (
          <EditarEvento
            isVisible={showModalEditarRepresentante}
            onClose={() => setShowModalEditarRepresentante(false)}
            modalData={representanteData}
          />
        )}
                {showModalEliminarEvento && (
          <EliminarEvento
            isVisible={showModalEliminarEvento}
            onClose={() => setShowModalEliminarEvento(false)}
            modalData={representanteData}
          />
        )}
      </div>
    </div>
  );
}
