import React, { useEffect, useState } from "react";
import { useStateContext } from "../../../contexts/ContextProvider";
import { useDispatch, useSelector } from "react-redux";
import { ColumnFilter } from "../../../components";
import {
  getInfoSolicitudVacacionesTabla,
  getPeriodosVacacionesPorTrabajador,
  hideLoader,
  limpiarInfoMenuEdicion,
  showLoader,
} from "../../../Redux/Actions";
import SimpleTable from "../../../components/SimpleTable";
import ModalEditarPeriodo from "../components/Periodos/ModalEditarPeriodo";
import ModalAgregarPeriodo from "../components/Periodos/ModalAgregarPeriodo";
import TablaSeleccionarTrabajadorPeriodo from "../components/Periodos/TablaSeleccionarTrabajador";
import toast from "react-hot-toast";
import UtilsFunctions from "../../../utils/UtilsFunctions";
import RutColumnFilter from "../../../components/RutColumnFilter";

export default function AgregarPeriodo() {
  const dispatch = useDispatch();
  const { refreshTable, setRefreshTable, activeMenu, screenSize } =
    useStateContext();

  const [modalData, setModalData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalNuevoPeriodo, setShowModalNuevoPeriodo] = useState(false);
  const isLoading = useSelector((state) => state.loader);

  const [radioSeleccionado, setRadioSeleccionado] = useState(false);
  const [valueIDTrabajador, setValueIDTrabajador] = useState(false);
  const [nombreTrabajador, setNombreTrabajador] = useState(false);

  const centroCostoId = localStorage.getItem("centroCostoId");

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader());
      await Promise.all([
        dispatch(getInfoSolicitudVacacionesTabla(centroCostoId)),
      ]);
      dispatch(hideLoader());
    };

    fetchData().catch((error) => {
      console.log(error.response.data.message);
      dispatch(hideLoader());
    });
  }, [dispatch]);


  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader());
      await Promise.all([
        dispatch(getPeriodosVacacionesPorTrabajador(valueIDTrabajador)),
      ]);
      dispatch(hideLoader());
    };

    fetchData().catch((error) => {
      console.log(error.response.data.message);
      dispatch(hideLoader());
    });
  }, [dispatch, refreshTable]);

  const infoSolicitudesVacaciones = useSelector(
    (state) => state.infoSolicitudVacacionesTabla
  );

  const periodoVacacionesTrabajador = useSelector(
    (state) => state.infoPeriodosVacacionesTrabajador
  );

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("infoSolicitudVacacionesTabla"));
      dispatch(limpiarInfoMenuEdicion("infoPeriodosVacacionesTrabajador"));
    };
  }, []);

  const ColumnasTrabajadores = [
    {
      Header: "RUT",
      accessor: "rut",
      Cell: ({ value }) => UtilsFunctions.rutFormatter(value, { dots: true }),
      Filter: RutColumnFilter,
    },
    {
      Header: "Nombres",
      accessor: (d) =>
        d.nombres + " " + d.apellidoPaterno + " " + d.apellidoMaterno,
      Filter: ColumnFilter,
    },
    {
      Header: "Fecha de Contratación",
      accessor: "fechaIngresoLegal",
      Filter: ColumnFilter,
    },
  ];

  const COLUMNS = [
    {
      Header: "Periodo",
      accessor: (d) => d.anyoInicio + " - " + (d.anyoInicio + 1),
      Filter: ColumnFilter,
    },
    {
      Header: "Días vacaciones disp.",
      accessor: (d) => d.diasVacaciones + " días",
      Filter: ColumnFilter,
    },
    {
      Header: "Días progresivos",
      accessor: (d) => d.feriadoProgresivo + " días",
      Filter: ColumnFilter,
    },
    {
      Header: "Días proporcionales",
      accessor: (d) => d.diasProporcionales + " días",
      Filter: ColumnFilter,
    },
    // {
    //   Header: "Tipo",
    //   accessor: (d) =>
    //     d.tipoPeriodo === "NORMAL"
    //       ? "Normal"
    //       : d.tipoPeriodo === "PROPORCIONAL"
    //       ? "Proporcional"
    //       : d.tipoPeriodo === "Progresiva"
    //       ? "Progresiva"
    //       : "",
    //   Filter: ColumnFilter,
    // },
    {
      Header: "Estado",
      accessor: (d) => (d.activo === true ? "Activo" : "Inactivo"),
      Filter: ColumnFilter,
    },
  ];

  return (
    <>
      <div
        className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
          activeMenu && "md:w-[calc(100vw_-_23rem)]"
        }`}
      >
        <div className="titulo-Menu">Vacaciones</div>
        <div className="titulo-Pagina">Agregar Periodo</div>

        <div className=" relative h-full rounded-lg md:mr-12 md:mt-3">
          <div className="h-full rounded-lg py-5 overflow-auto mb-6">
            <p className="text-xl text-hercules-light-font-secondary dark:text-dark-primary">
              Lista de Trabajadores:
            </p>
            <TablaSeleccionarTrabajadorPeriodo
              col={ColumnasTrabajadores}
              tableData={infoSolicitudesVacaciones}
              botonRadio={true}
              idSleccionado={(trabajadorId) => {
                setRefreshTable(!refreshTable);
                const fetchData = async () => {
                  toast.loading("Cargando periodos")
                  await Promise.all([
                    setValueIDTrabajador(trabajadorId),
                    setRadioSeleccionado(false),
                    dispatch(getPeriodosVacacionesPorTrabajador(trabajadorId)),
                  ]);
                  toast.dismiss();
                  toast.success("Periodos cargados correctamente",estilosAlerta);  
                };
            
                fetchData().catch((error) => {
                  console.log(error.response.data.message);
                  toast.error(error.response.data.message ? error.response.data.message : "Error al cargar periodos")
                });            
              }}
              trabajadorSeleccionado={(trabajadorSeleccionado) => {
                setNombreTrabajador(trabajadorSeleccionado);
              }}
              radioSeleccionado={radioSeleccionado}
            />
          </div>

          {valueIDTrabajador && periodoVacacionesTrabajador.length ? (
            <>
              <h3 className="text-xl md:text-2xl md:pt-5 font-normal leading-normal dark:text-white text-hercules-light-font-secondary">
                Datos de periodos de vacaciones para el trabajador{" "}
                <b>{nombreTrabajador}</b>
              </h3>

              <h5 className="text-base md:text-lg font-normal leading-normal mb-6 md:mb-3 dark:text-white text-hercules-light-font-secondary mt-3 md:mt-1">
                Dias de vacaciones disponibles (no se deben considerar los días
                progresivos en este ingreso). Los días progresivos se ingresan
                de forma separada.
              </h5>

              <div className=" relative h-full rounded-lg md:mt-3">
                <SimpleTable
                  tableHeight="h-96"
                  col={COLUMNS}
                  tableData={periodoVacacionesTrabajador}
                  primaryButton={{
                    type: "editar",
                    onClickFunc: (data) => {
                      setModalData(data);
                      setShowModal(true);
                    },
                    active: true,
                  }}
                  exportCsvButton={false}
                />
                <button
                  onClick={() => setShowModalNuevoPeriodo(true)}
                  disabled={isLoading}
                  className={`mt-3 ${
                    isLoading
                      ? "bg-gray-500"
                      : "bg-hercules-light-primary-button hover:shadow-lg"
                  } text-white dark:text-dark-primary font-medium uppercase text-normal px-4 py-3 rounded shadow  outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
                >
                  Nuevo Periodo
                </button>
              </div>
            </>
          ): null }
        </div>
      </div>

      {showModal && (
        <ModalEditarPeriodo
          isVisible={showModal}
          onClose={() => setShowModal(false)}
          modalData={modalData}
        />
      )}
      {showModalNuevoPeriodo && (
        <ModalAgregarPeriodo
          isVisible={showModalNuevoPeriodo}
          onClose={() => setShowModalNuevoPeriodo(false)}
          idTrabajador={valueIDTrabajador}
          arrayPeriodos={periodoVacacionesTrabajador}
        />
      )}
    </>
  );
}
