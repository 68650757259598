import React, { useEffect, useMemo, useState } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useFilters,
  usePagination,
  useRowSelect,
} from "react-table";
import {
  TbArrowsSort,
  TbSortAscending,
  TbSortDescending,
} from "react-icons/tb";
import GlobalFilter from "../../GlobalFilter";
import { BiSearch } from "react-icons/bi";
import {
  MdArrowBackIos,
  MdArrowForwardIos,
  MdLastPage,
  MdFirstPage,
} from "react-icons/md";
import { useStateContext } from "../../../contexts/ContextProvider";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import Skeleton from "../../Skeleton";
import UtilsFunctions from "../../../utils/UtilsFunctions";
import { useForm } from "react-hook-form";

export default function TablaExportarHabDescMasivos({
  col,
  tableData,
  listaHaberes,
  listaDescuentos,
}) {
  // eslint-disable-next-line
  const isLoading = useSelector((state) => state.loader);
  const columns = useMemo(() => col, []);
  const data = useMemo(() => [...tableData], [tableData]);
  const { screenSize } = useStateContext();

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const {
    register,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues:{
      tipoHaberDescuento: "",
      haberId: "",
      descuentoId: "",
    }
  });

  const validacion = {
    required: { value: true, message: "Obligatorio" },
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    canPreviousPage,
    canNextPage,
    gotoPage,
    pageCount,
    setPageSize,
    pageOptions,
    state,
    rows,
    selectedFlatRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
          disableGlobalFilter: true,
        },
        ...columns,
      ]);
    }
  );

  const { pageIndex, pageSize } = state;

  const { globalFilter } = state;

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      );
    }
  );

  useEffect(() => {}, [selectedFlatRows]);

  const infoHaberSeleccionado = selectedFlatRows.map((e) => {
    return {
      rut: e.original.rutTrabajador,
      trabajador: e.original.nombres + " " + e.original.apellidoPaterno + " " + e.original.apellidoMaterno,
      contratoId: e.original.contratoId,
      haberId: watch("haberId"),
      haber: watch("nombreHaber"),
      monto: 0
    };
  });

  // console.log("infoHaberSeleccionado", infoHaberSeleccionado)

  const headersCsvHaber = [
    { name: "RUT", key: "rut" },
    { name: "Trabajador", key: "trabajador" },
    { name: "ID Haber", key: "haberId" },
    { name: "Haber", key: "haber" },
    { name: "Monto", key: "monto" },
  ];

  function descargarPlantillaHaberes() {
    if (selectedFlatRows.length) {
      UtilsFunctions.jsonToCSVv2(
        infoHaberSeleccionado,
        headersCsvHaber,
        `Plantilla Importar Haberes.csv`
      );
    } else {
      toast.dismiss();
      toast.error("Debe seleccionar al menos un Trabajador", estilosAlerta);
    }
  }

  const infoDescuentoSeleccionado = selectedFlatRows.map((e) => {
    return {
      rut: e.original.rutTrabajador,
      trabajador: e.original.nombres + " " + e.original.apellidoPaterno + " " + e.original.apellidoMaterno,
      contratoId: e.original.contratoId,
      descuentoId: watch("descuentoId"),
      descuento: watch("nombreDescuento"),
      monto: 0
    };
  });

  const headersCsvDescuento = [
    { name: "RUT", key: "rut" },
    { name: "Trabajador", key: "trabajador" },
    { name: "ID Descuento", key: "descuentoId" },
    { name: "Descuento", key: "descuento" },
    { name: "Monto", key: "monto" },
  ];

  function descargarPlantillaDescuentos() {
    if (selectedFlatRows.length) {
      UtilsFunctions.jsonToCSVv2(
        infoDescuentoSeleccionado,
        headersCsvDescuento,
        `Plantilla Importar Descuentos.csv`
      );
    } else {
      toast.dismiss();
      toast.error("Debe seleccionar al menos un Trabajador", estilosAlerta);
    }
  }

  if (isLoading) {
    return (
      <Skeleton
        width={"w-full"}
        height={"h-fix"}
        searchBar={true}
        pagination={true}
      />
    );
  }

  return (
    <>
      <div className="bg-white dark:bg-hercules-dark-bg-100 rounded-tl-none rounded-tr-md border-t  border-l border-r border-gray-300 dark:border-dark-bg-200 h-12 flex p-3">
        <>
          <span className="ml-auto ">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          </span>
          <span className="mx-3 pt-1 text-xl dark:text-dark-text-100 text-black hover:cursor-pointer">
            <BiSearch />
          </span>
        </>

      </div>
      <div className="h-fix overflow-auto border-x-1  bg-white dark:bg-hercules-dark-bg-200  dark:border-dark-bg-200 border-gray-300 border-t-1">
        <table {...getTableProps()} className="w-full rounded-lg">
          <thead className="text-left sticky top-0 border-b-1 border-b-hercules-light-secondary dark:border-b-hercules-dark-secondary-button bg-hercules-light-table-header dark:bg-hercules-dark-bg-200">
            {headerGroups.map((headerGroup) => {
              const { key, ...restHeaderGroup } =
                headerGroup.getHeaderGroupProps();
              return (
                <React.Fragment key={key}>
                  <tr {...restHeaderGroup} className="h-10 flex-auto">
                    {headerGroup.headers.map((column) => {
                      const { key, ...restColumn } = column.getHeaderProps(
                        column.getSortByToggleProps()
                      );
                      return (
                        <React.Fragment key={key}>
                          <th
                            {...restColumn}
                            className="py-2 px-4 text-hercules-light-font-secondary font-medium dark:text-white"
                          >
                            <div className="flex">
                              <span className="">
                                {column.render("Header")}
                              </span>
                              <span className="ml-1 mt-auto mb-auto">
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <TbSortDescending />
                                  ) : (
                                    <TbSortAscending />
                                  )
                                ) : (
                                  <TbArrowsSort />
                                )}
                              </span>
                            </div>
                          </th>
                        </React.Fragment>
                      );
                    })}
                  </tr>
                  <tr {...headerGroup.getHeaderGroupProps()} className="h-10">
                    {headerGroup.headers.map((column) => {
                      const { key, ...restColumn } = column.getHeaderProps();
                      return (
                        <th key={key} {...restColumn} className="pl-4 pb-4">
                          <div className="w-fit pr-4">
                            {column.canFilter ? column.render("Filter") : null}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                </React.Fragment>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()} className="h-full">
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <React.Fragment key={i}>
                  <tr
                    {...row.getRowProps()}
                    className="group bg-white dark:bg-hercules-dark-bg-200   dark:text-dark-text-100 dark:border-b-hercules-dark-secondary-button border-b border-hercules-light-secondary text-black h-12 dark:hover:bg-white dark:hover:bg-opacity-10 transition duration-100 ease-in-out hover:bg-light-accent hover:bg-opacity-20"
                  >
                    {row.cells.map((cell, i) => {
                      return (
                        <td
                          key={i}
                          {...cell.getCellProps()}
                          className="py-2 px-4 text-base font-normal"
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className=" border-1 dark:border-t-hercules-dark-secondary-button border-t-hercules-light-secondary border-gray-300 dark:border-dark-bg-200 dark:bg-hercules-dark-bg-200   rounded-b-md h-14 flex bg-white">
        <div className="ml-auto py-3 flex">
          <p className="hidden sm:block pt-1 text-black dark:text-white">
            Filas por página
          </p>
          <select
            name=""
            id=""
            className="ml-2 h-8 text-sm bg-white border  rounded-sm"
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block p-4 text-black dark:text-white">
          Página {pageIndex + 1} de {pageOptions.length}
        </div>
        <div className="flex mr-4 pl-4">
          <button
            className="bg-white text-black dark:text-white dark:bg-opacity-0 rounded-full hover:bg-gray-200 hover:text-black my-2 w-8 mr-6"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <MdFirstPage className="pl-1 text-2xl" />
          </button>
          <button
            className="bg-white text-black rounded-full dark:text-white dark:bg-opacity-0  hover:bg-gray-200 hover:text-black my-2 mr-6 w-8"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <MdArrowBackIos className="ml-2" />
          </button>
          <button
            className="bg-white text-black rounded-full dark:text-white dark:bg-opacity-0  hover:bg-gray-200 hover:text-black my-2 mr-6 w-8"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <MdArrowForwardIos className="ml-2" />
          </button>
          <button
            className="bg-white text-black rounded-full dark:text-white dark:bg-opacity-0  hover:bg-gray-200 hover:text-black my-2 w-8"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <MdLastPage className="pl-1 text-2xl" />
          </button>
        </div>
      </div>

      <div className="md:flex md:flex-wrap pt-4 md:pt-5">
        <div className="w-full relative md:w-2/12 md:mr-3 mb-6 md:mb-0">
        <label className=" dark:text-white text-hercules-light-font-secondary">
            Plantilla
          </label>
          <select
            {...register(
              "tipoHaberDescuento",
              {
                onChange: (e) => {
                  setValue("haberId", "");
                  setValue("descuentoId", "");
                },
                ...validacion,
              }
            )}
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
            <option value="" hidden>
              Tipo
            </option>
            <option value="haber">Haber</option>
            <option value="descuento">Descuento</option>
          </select>
        </div>

        <div className="w-full md:w-3/12 md:mx-3 md:mb-3">
          <label className="dark:text-white text-hercules-light-font-secondary">
            Nombre
          </label>
          {watch("tipoHaberDescuento") === "haber" ? (
            <>
              <select
                {...register("haberId", {
                  ...validacion,
                  onChange:(e) => {
                    const nombre = listaHaberes.find(
                      (haber) => haber.id == e.target.value
                    );
                    setValue("nombreHaber", nombre.nombre);
                  }
                })}
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option hidden value="">
                  Seleccionar un haber
                </option>
                {listaHaberes.map((haber, i) => (
                  <option value={haber.id} key={i}>
                    {haber.nombre}
                  </option>
                ))}
              </select>
            </>
          ) : watch("tipoHaberDescuento") === "descuento" ? (
            <>
              <select
                {...register("descuentoId", {
                  ...validacion,
                  onChange:(e) =>{
                    const nombre = listaDescuentos.find(
                      (descuento) => descuento.id == e.target.value
                    );
                    setValue("nombreDescuento", nombre.nombre);
                  }
                })}
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option hidden value="">
                  Seleccionar un descuento
                </option>
                {listaDescuentos.map((descuento, i) => (
                  <option value={descuento.id} key={i}>
                    {descuento.nombre}
                  </option>
                ))}
              </select>
            </>
          ) : (
            <input
              disabled
              value={"Seleccione Plantilla"}
              className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
              id="grid-rut"
              type="text"
            />
          )}
        </div>
        
        <div className="w-full md:w-max mt-3 md:mt-6 md:ml-3 md:mb-0">
          <button
            onClick={() => {
              if(watch("tipoHaberDescuento") === "" || (watch("haberId") === "" && watch("descuentoId") === "")){
                toast.dismiss()
                toast.error("Seleccione Tipo de plantilla y Nombre de Haber o Descuento")
                return null
              }
              if(watch("tipoHaberDescuento") === "haber"){
                descargarPlantillaHaberes();
              }else if(watch("tipoHaberDescuento") === "descuento"){
                descargarPlantillaDescuentos();
              }
            }}
            className="hover:scale-105 bg-hercules-dark-primary-button text-white font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
          >
            Descargar Plantilla
          </button>
        </div>
      </div>

    </>
  );
}
