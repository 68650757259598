import React, { useState } from "react";
import { Link } from "react-router-dom";
import landing1 from "../assets/landing1.jpeg";
import landing2 from "../assets/landing2.webp";
import MyCarousel from "../components/ui/Carrusel";
import logoVertical from "../assets/Blanco_Vertical.png";
import img1 from "../assets/img1.jpeg";
import img2 from "../assets/img2.jpeg";
import img3 from "../assets/img3.jpeg";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { axiosPrivate } from "../common/axiosPrivate";


  // Primer intento de desplazamiento suave

  // document.addEventListener('DOMContentLoaded', function() {
  //   document.querySelector('a[href="#contacto"]').addEventListener('click', function(event) {
  //     // Evita el comportamiento predeterminado del enlace
  //     event.preventDefault();
      
  //     // Encuentra el elemento de la sección de contacto
  //     const contactoSection = document.getElementById('contacto');
      
  //     // Realiza el desplazamiento suave hacia la sección de contacto
  //     contactoSection.scrollIntoView({
  //       behavior: 'smooth', // Utiliza un desplazamiento suave
  //       block: 'start'      // Alinea el inicio de la sección en la parte superior del viewport
  //     });
  //   });
  // });


// Segundo intento de desplazamiento suave

// function scrollToSection(sectionId) {
//   const targetSection = document.getElementById(sectionId);
//   if (targetSection) {
//       // Calcula la posición del elemento de destino
//       const targetOffset = targetSection.offsetTop;

//       // Animación de desplazamiento suave
//       window.scrollTo({
//           top: targetOffset,
//           behavior: 'smooth' // Desplazamiento suave
//       });
//   }
// }

// document.addEventListener('DOMContentLoaded', function() {
// const contactoLink = document.querySelector('a[href="#contacto"]');
// if (contactoLink) {
//     contactoLink.addEventListener('click', function(event) {
//         event.preventDefault(); // Evita el comportamiento predeterminado de redireccionamiento
//         scrollToSection('contacto'); // Desplázate suavemente hacia la sección de contacto
//     });
// }
// });

const LandingPage = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const Authorization = {
    headers: {
      ClientId: "76494210",
    },
  };


  const [open, setOpen] = useState(false);

  const validacionTextInput = {
    required: { value: true, message: "Obligatorio" },
  };

  async function onSubmit (data) {
    // Aquí puedes manejar el envío de datos, como enviarlos a través de una API
    toast.loading("Enviando correo...")
    console.log(data);
    try {
      const payload = {
        email: data.email,
        nombre: data.nombre,
        asunto: data.asunto,
        mensaje: data.mensaje,
      }
      const info = await axiosPrivate.post(
        `${api_service}/api/v1/auth/contacto`,
        payload,
        Authorization,
      );
      console.log("Info", info)
      toast.dismiss()
      toast.success("Mensaje enviado con éxito")
      reset()
    } catch (error) {
      console.log(error)
      toast.dismiss()
      toast.error(error.response.data.message? error.response.data.message : "Error al enviar mensaje")
    }
  };
  

  return (
    <div className="w-full bg-white">
      <div className="h-[8vh] min-h-[70px] flex items-center border-b-2">
        <div className="flex w-[90vw] mx-auto max-w-7xl md:items-center md:justify-between md:flex-row md:px-6 lg:px-8">
          <div className="flex flex-row ml-6 items-center justify-between lg:justify-start">
            <button
              onClick={() => setOpen(!open)}
              className="rounded-lg  md:hidden focus:outline-none focus:shadow-outline"
            >
              <svg fill="currentColor" viewBox="0 0 20 20" className="w-8 h-8">
                <path
                  x-show="!open"
                  className={`${open && "hidden"} `}
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
                <path
                  x-show="open"
                  className={`${!open && "hidden"} `}
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
            <a
              href="/"
              className="text-lg font-bold tracking-tighter text-hercules-dark-primary-button transition duration-500 ease-in-out transform tracking-relaxed lg:pr-8"
            >
              {" "}
              Hércules{" "}
            </a>
          </div>

          <nav
            className={`${
              open ? "flex absolute mt-14 bg-white w-full z-10" : "hidden"
            }   flex-col items-center flex-grow  border-hercules-dark-primary-button md:pb-0 md:flex md:justify-end md:flex-row lg:border-l-2 lg:pl-2`}
          >
            <a
              className="px-4 py-2 mt-2 text-sm text-gray-500 md:mt-0  focus:outline-none focus:shadow-outline"
              href="http://www.helpcom.cl/"
            >
              Helpcom
            </a>
            <a
              className="px-4 py-2 mt-2 mb-4 md:mb-0 text-sm text-gray-500 md:mt-0 focus:outline-none focus:shadow-outline"
              href="#contacto"
            >
              Contacto
            </a>
          </nav>
          <div className="ml-auto">
            <Link to={"/login"}>
              <button className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-semibold text-base px-8 py-3 rounded-xl shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400">
                Ingresar
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className=" ">
        <section>
          <div className="bg-black relative w-screen h-screen md:h-[55vh] min-h-590">
            <img
              className="h-full w-full object-cover"
              src={landing2}
              alt="Background"
            />
            <div className="absolute top-0 left-0 h-full w-full bg-black opacity-70"></div>
            <div className="px-8 absolute top-1/3 left-1/2 w-full md:max-w-6xl transform -translate-x-1/2 -translate-y-1/3 text-white ">
              <div className="flex mb-2">
                <h2 className="max-w-5xl text-xl leading-none tracking-tighter text-neutral-600 md:text-xl lg:text-2l xl:text-3xl lg:max-w-6xl">
                  Bienvenido a Hércules
                </h2>
                <span className="bg-hercules-light-primary-button h-1 w-24 mt-auto mb-3 ml-1 hidden md:block"></span>
              </div>
              <h1 className="max-w-5xl font-medium text-left text-3xl leading-none tracking-tighter text-neutral-600 md:text-2xl lg:text-4xl xl:text-5xl lg:max-w-6xl">
                El más poderoso Software de Remuneraciones.
              </h1>
              <p className="max-w-2xl mt-6 text-lg leading-relaxed text-left text-white">
                {/* Hércules es un software de remuneraciones multiempresas,
                multiusuario y multiniveles: puedes tratar a tu empresa como un
                holding a cargo de diferentes empresas con RUT propio, con
                múltiples trabajadores y asignarle a los usuarios el rol que
                elijas.
                <br /> */}
                Gestiona en segundos y de manera online tus liquidaciones;
                actualiza y calcula haberes y descuentos; genera libro de
                remuneraciones electrónico, reportes y mucho más.
              </p>
              <div className="w-full">
                <button className="mt-4  mr-auto bg-hercules-light-primary-button text-white dark:text-dark-primary font-medium uppercase text-base px-4 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150">
                  Adquirir Servicio
                </button>
              </div>
            </div>
          </div>
        </section>

        <section className="px-8 pb-4 pt-12 hidden md:block">
          <div className="grid grid-cols-2 gap-4 items-center mx-auto max-w-5xl">
            <div className="mb-24">
              <h1 className="text-3xl font-semibold">
                Beneficios de confiar tus remuneraciones a Hércules
              </h1>
              <hr className="w-60 rounded-md bg-hercules-light-primary-button h-1 my-4"></hr>
            </div>
            <div>
              <p className="text-lg mr-2 ">
                {/* <b> */}
                Más de 15 años nos avalan como empresa proveedores de software
                de mas de 500 socios operando con las distintas aplicaciones de{" "}
                <strong className="font-normal">HELPCOM</strong>.
                <br />
                Hércules es una herramienta que une nuestra experiencia en
                aplicaciones de escritorio y remuneraciones para proporcionarle
                a nuestros socios la mejor experiencia online.
                {/* </b> */}
              </p>

              <ul className="mt-4">
                <li className="flex items-center mb-3">
                  <div>
                    <svg
                      className="w-8 h-8 mr-2 text-hercules-light-primary-button"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
                    </svg>
                  </div>
                  <div>
                    <span className="text-black text-base">
                      Maneja múltiples empresas y sus sucursales.
                    </span>
                  </div>
                </li>

                <li className="flex items-center mb-3">
                  <div>
                    <svg
                      className="w-8 h-8 mr-2 text-hercules-light-primary-button"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
                    </svg>
                  </div>
                  <div>
                    <span className="text-black text-base">
                      Agrega los usuarios que necesites con el nivel de acceso
                      que desees.
                    </span>
                  </div>
                </li>

                <li className="flex items-center mb-3">
                  <div>
                    <svg
                      className="w-8 h-8 mr-2 text-hercules-light-primary-button"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
                    </svg>
                  </div>
                  <div>
                    <span className="text-black text-base">
                      Interacción directa con PREVIRED y DIRECCIÓN DEL TRABAJO.
                    </span>
                  </div>
                </li>

                <li className="flex items-center mb-3">
                  <div>
                    <svg
                      className="w-8 h-8 mr-2 text-hercules-light-primary-button"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
                    </svg>
                  </div>
                  <div>
                    <span className="text-black text-base">
                      Haberes y descuentos normalizados y personalizables.
                    </span>
                  </div>
                </li>

                <li className="flex mb-3">
                  <div>
                    <svg
                      className="w-8 h-8 mr-2 text-hercules-light-primary-button"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
                    </svg>
                  </div>
                  <div>
                    <span className="text-black text-base">
                      Parámetros y datos de actualización mensuales y anuales
                      manejados de forma centralizada.
                    </span>
                  </div>
                </li>

                <li className="flex items-center mb-3">
                  <div>
                    <svg
                      className="w-8 h-8 mr-2 text-hercules-light-primary-button"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
                    </svg>
                  </div>
                  <div>
                    <span className="text-black text-base">
                      Cálculo de remuneraciones y reportería en segundos.
                    </span>
                  </div>
                </li>

                <li className="flex items-center mb-3">
                  <div>
                    <svg
                      className="w-8 h-8 mr-2 text-hercules-light-primary-button"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
                    </svg>
                  </div>
                  <div>
                    <span className="text-black text-base">
                      Incluye soporte técnico especializado.
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section>
          <div className=" flex flex-col items-center px-8 pb-4 md:mb-0 pt-12">
            {/* Celular */}
            <div className="flex flex-col w-full md:hidden text-left max-w-max pb-12 lg:max-w-5xl ">
              <div className="w-full mx-auto">
                <h1 className="text-2xl font-semibold">
                  Beneficios de confiar tus remuneraciones a Hércules
                </h1>
              </div>

              <span className="w-60 rounded-md bg-hercules-light-primary-button h-1 my-4"></span>
              <div>
                <p className="text-lg mr-2 ">
                  {/* <b> */}
                  Más de 15 años nos avalan como empresa proveedores de software
                  de mas de 500 socios operando con las distintas aplicaciones
                  de <strong className="font-normal">HELPCOM</strong>.
                  <br />
                  Hércules es una herramienta que une nuestra experiencia en
                  aplicaciones de escritorio y remuneraciones para
                  proporcionarle a nuestros socios la mejor experiencia online.
                  {/* </b> */}
                </p>
                <ul className="mt-4">
                  <li className="flex items-center mb-3">
                    <div>
                      <svg
                        className="w-7 h-7 mt-1 mr-2 top-0 text-hercules-light-primary-button"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
                      </svg>
                    </div>
                    <div>
                      <span className="text-black text-base mt-1">
                        Maneja múltiples empresas y sus sucursales.
                      </span>
                    </div>
                  </li>

                  <li className="flex items-center mb-3">
                    <div>
                      <svg
                        className="w-7 h-7 mt-1 mr-2 top-0 text-hercules-light-primary-button"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
                      </svg>
                    </div>
                    <div>
                      <span className="text-black text-base">
                        Agrega los usuarios que necesites con el nivel de acceso
                        que desees.
                      </span>
                    </div>
                  </li>

                  <li className="flex items-center mb-3">
                    <div>
                      <svg
                        className="w-7 h-7 mt-1 mr-2 top-0 text-hercules-light-primary-button"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
                      </svg>
                    </div>
                    <div>
                      <span className="text-black text-base">
                        Interacción directa con PREVIRED y DIRECCIÓN DEL
                        TRABAJO.
                      </span>
                    </div>
                  </li>

                  <li className="flex items-center mb-3">
                    <div>
                      <svg
                        className="w-7 h-7 mr-2 text-hercules-light-primary-button"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
                      </svg>
                    </div>
                    <div>
                      <span className="text-black text-base">
                        Haberes y descuentos normalizados y personalizables.
                      </span>
                    </div>
                  </li>

                  <li className="flex items-center mb-3">
                    <div>
                      <svg
                        className="w-7 h-7 mr-2 text-hercules-light-primary-button"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
                      </svg>
                    </div>
                    <div>
                      <span className="text-black text-base">
                        Cálculo de remuneraciones y reportería en segundos.
                      </span>
                    </div>
                  </li>

                  <li className="flex items-center mb-3">
                    <div>
                      <svg
                        className="w-7 h-7 mr-2 text-hercules-light-primary-button"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
                      </svg>
                    </div>
                    <div>
                      <span className="text-black text-base">
                        Parámetros y datos de actualización mensuales y anuales
                        manejados de forma centralizada.
                      </span>
                    </div>
                  </li>

                  <li className="flex items-center mb-3">
                    <div>
                      <svg
                        className="w-7 h-7 mr-2 text-hercules-light-primary-button"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
                      </svg>
                    </div>
                    <div>
                      <span className="text-black text-base">
                        Cálculo de remuneraciones y reportería en segundos.
                      </span>
                    </div>
                  </li>

                  <li className="flex items-center mb-3">
                    <div>
                      <svg
                        className="w-7 h-7 mr-2 text-hercules-light-primary-button"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
                      </svg>
                    </div>
                    <div>
                      <span className="text-black text-base">
                        Incluye soporte técnico especializado.
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            {/* <div className="flex flex-col w-full text-left pb-12 justify-center lg:max-w-5xl">
              <div className="w-full mx-auto">
                <h1 className="text-2xl font-semibold text-center">
                  Conoce el Software por dentro
                </h1>
              </div>
              <span className="w-60 rounded-md bg-hercules-light-primary-button mx-auto md:mb-8 h-1 my-4"></span>
              <div className="pb-6 md:max-w-xl md:w-full mx-auto md:h-96">
                <iframe
                  src="https://www.youtube.com/embed/XHOmBV4js_E?start=2s"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  className="w-full md:h-96 h-48"
                ></iframe>
              </div>
              <div className="mx-auto">
                <p className="mt-3">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt
                  sapiente sed quo enim vitae iusto, alias voluptatem quis.
                </p>
              </div>
              <div className="w-full md:max-w-xs md:mx-auto">
                <button className="mt-4 w-full bg-hercules-light-primary-button text-white dark:text-dark-primary font-medium uppercase text-14 px-4 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150">
                  Adquirir Servicio
                </button>
              </div>
            </div> */}

            {/* <div className="flex flex-col items-center w-full lg:max-w-5xl">
              <div className="w-full">
                <h1 className="text-2xl font-semibold text-center">
                  Socios de HELPCOM que ya han confiado en nuestro sistema de
                  remuneraciones
                </h1>
              </div>
              <span className="w-60 rounded-md bg-hercules-light-primary-button h-1 my-4 md:mb-8"></span>
              <div className="max-w-lg mx-auto md:hidden block">
                <MyCarousel />
              </div>
              <div className="max-w-lg mx-auto hidden md:block">
                <div className="flex justify-center">
                  <img
                    src={img2}
                    alt=""
                    className="mx-8 max-h-20 max-w-20 object-contain align-middle"
                    key="img2"
                  />
                  <img
                    src={img1}
                    alt=""
                    className="mx-8 max-h-20 max-w-20 object-contain align-middle"
                    key="img1"
                  />
                  <img
                    src={img3}
                    alt=""
                    className="mx-8 max-h-20 max-w-20 object-contain align-middle"
                    key="img3"
                  />
                </div>
              </div>
            </div> */}
          </div>

          {/* Contact form */}
          {/* <div className="flex justify-center items-center max-w-full px-8 mb-8">
            <span className="h-0.5 w-400 bg-coolgray-500"></span>
            <p className="mx-4 text-coolgray-500">¿Necesitas asesoramiento personalizado?</p>
            <span className="h-0.5 w-400 bg-coolgray-500"></span>
          </div> */}

          <div id="contacto" className="flex flex-col items-center w-full px-8 mb-16">
            <div className="flex flex-col w-full text-left lg:max-w-5xl">
              <div className="w-full mx-auto">
                <h1 className="text-2xl font-semibold text-center">
                  Contáctanos para más información
                </h1>
              </div>
              <span className="w-60 rounded-md bg-hercules-light-primary-button h-1 my-4 mx-auto md:mb-8"></span>
              <div className="w-full mx-auto  max-w-md ">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  method="POST"
                  className="flex flex-col w-full mx-auto lg:max-w-5xl"
                >
                  <div className="flex flex-col w-full mx-auto">

                    <div className="flex flex-col w-full mx-auto mb-3">
                      <input
                        {...register("nombre", validacionTextInput)}
                        type="text"
                        id="nombre"
                        placeholder="Nombre"
                        className={` ${errors.nombre ? "border-red-500" : ""} w-full px-4 py-2 mt-2 border-0 border-b-1 rounded-sm focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent`}
                      />
                    <span
                      className={`text-red-500 text-xs italic ${
                        !errors.nombre && "opacity-0 invisible"
                      }`}
                    >
                      {errors.nombre ? errors.nombre.message : "hidden"}
                    </span>
                    </div>

                    <div className="flex flex-col w-full mx-auto mb-3">
                      <input
                        {...register("email", {
                          ...validacionTextInput,
                          pattern: {
                            value:
                              /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,4}$/,
                            message: "Correo electrónico inválido",
                          },
                        })}
                        type="email"
                        id="email"
                        className={` ${errors.email ? "border-red-500" : ""} w-full px-4 py-2 mt-2 border-0 border-b-1 rounded-sm focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent`}
                        placeholder="Email"
                      />
                    <span
                      className={`text-red-500 text-xs italic ${
                        !errors.email && "opacity-0 invisible"
                      }`}
                    >
                      {errors.email ? errors.email.message : "hidden"}
                    </span>
                    </div>

                    <div className="flex flex-col w-full mx-auto mb-3">
                      <select
                        {...register("asunto", validacionTextInput)}
                        id="asunto"
                        className={` ${errors.asunto ? "border-red-500" : ""} w-full px-4 py-2 mt-2 border-0 border-b-1 rounded-sm focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent`}
                      >
                        <option value="" hidden >Asunto</option>
                        <option value="contratacion">
                          Contratación del servicio
                        </option>
                        <option value="soporte">Soporte</option>
                        <option value="otros">Otros</option>
                      </select>
                    <span
                      className={`text-red-500 text-xs italic ${
                        !errors.asunto && "opacity-0 invisible"
                      }`}
                    >
                      {errors.asunto ? errors.asunto.message : "hidden"}
                    </span>
                    </div>

                    <div className="flex flex-col w-full mx-auto ">
                      <textarea
                        {...register("mensaje", validacionTextInput)}
                        id="mensaje"
                        className={` ${errors.mensaje ? "border-red-500" : ""} w-full px-4 py-2 mt-2 border-0 border-b-1 rounded-sm focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent`}
                        rows="4"
                      />
                    <span
                      className={`text-red-500 text-xs italic ${
                        !errors.mensaje && "opacity-0 invisible"
                      }`}
                    >
                      {errors.mensaje ? errors.mensaje.message : "hidden"}
                    </span>
                    </div>
                    <div className="flex flex-col w-full mx-auto">
                      <button
                        type="submit"
                        className="px-8 py-2 mt-4 text-sm font-medium text-white transition duration-500 ease-in-out transform bg-hercules-light-primary-button rounded-md hover:bg-hercules-dark-primary-button focus:shadow-outline focus:outline-none focus:ring-2 ring-offset-current ring-offset-2"
                      >
                        Enviar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {/* <form onSubmit={handleSubmit(onSubmit)} className="w-full max-w-lg">
<div className="mb-4">
        <label htmlFor="nombre" className="block text-gray-700 text-sm font-bold mb-2">
          Nombre
        </label>
        <input
          {...register('nombre', { required: 'Campo requerido' })}
          type="text"
          id="nombre"
          className={`form-input ${errors.nombre ? 'border-red-500' : ''}`}
        />
        {errors.nombre && <p className="text-red-500 text-xs italic">{errors.nombre.message}</p>}
      </div>

      <div className="mb-4">
        <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">
          Email
        </label>
        <input
          {...register('email', {
            required: 'Campo requerido',
            pattern: {
              value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,4}$/,
              message: 'Correo electrónico inválido',
            },
          })}
          type="email"
          id="email"
          className={`form-input ${errors.email ? 'border-red-500' : ''}`}
        />
        {errors.email && <p className="text-red-500 text-xs italic">{errors.email.message}</p>}
      </div>

      <div className="mb-4">
        <label htmlFor="asunto" className="block text-gray-700 text-sm font-bold mb-2">
          Asunto
        </label>
        <select
          {...register('asunto', { required: 'Seleccione un asunto' })}
          id="asunto"
          className={`form-select ${errors.asunto ? 'border-red-500' : ''}`}
        >
          <option value="">Asunto</option>
          <option value="contratacion">Contratación del servicio</option>
          <option value="soporte">Soporte</option>
          <option value="otros">Otros</option>
        </select>
        {errors.asunto && <p className="text-red-500 text-xs italic">{errors.asunto.message}</p>}
      </div>

      <div className="mb-4">
        <label htmlFor="mensaje" className="block text-gray-700 text-sm font-bold mb-2">
          Mensaje
        </label>
        <textarea
          {...register('mensaje', { required: 'Campo requerido' })}
          id="mensaje"
          className={`form-textarea ${errors.mensaje ? 'border-red-500' : ''}`}
        />
        {errors.mensaje && <p className="text-red-500 text-xs italic">{errors.mensaje.message}</p>}
      </div>

      <div className="flex items-center justify-center">
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Enviar
        </button>
      </div>

</form> */}
        </section>

        <footer
          className="bg-hercules-light-footer-black w-full "
          aria-labelledby="footer-heading"
        >
          {/* <div className="px-8 flex py-4 mx-auto  max-w-6xl  sm:px-6 lg:px-16">
            <div className="md:ml-auto py-8 px-6">
              <img
                src={logoVertical}
                alt=""
                className="mx-auto hidden md:block "
                srcset=""
              />
            </div>
            <div className="md:mr-auto">
              <div className="">
                <div className="flex flex-col  w-full mx-auto  mt-4 lg:flex-row lg:max-w-5xl ">
                  <div className="flex flex-col  w-full  lg:mb-0 lg:w-1/2">

                    <div>
                    <h1 className="text-base font-semibold text-white items-start">
                      Horarios de atención:
                    </h1>
                    <div className="flex flex-col items-center justify-left md:w-56  lg:flex-row">
                      <div className="flex flex-col  w-full  mx-auto md:mx-0  lg:mb-0 ">
                        <h1 className="text-base font-normal  text-white">
                          Lunes a Viernes
                        </h1>
                        <p className="text-sm font-light text-gray-400">
                          8:00 am - 12:00 pm
                        </p>
                      </div>
                    </div>
                    </div>
                    
                    <div className="flex flex-col w-full mx-auto  lg:mb-0 ">
                      <h1 className="text-base font-semibold text-white">
                        Celular:
                      </h1>
                      <p className="text-sm font-light w-full text-gray-400">
                        9 8877 6655
                      </p>
                    </div>

                    <div className="flex flex-col  w-full mx-auto lg:mb-0 ">
                      <h1 className="text-base font-semibold text-white">
                        Email:
                      </h1>
                      <p className="text-sm font-light text-gray-400">
                        hercules@helpcom.cl
                      </p>
                    </div>

                    <div className="flex flex-col  w-full mx-auto lg:mb-0 ">
                      <h1 className="text-base font-semibold text-white">
                        Dirección:
                      </h1>
                      <p className="text-sm font-light text-gray-400">
                        Calle 1 4433, Santiago, Chile
                      </p>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="flex py-4">
            <div className="md:ml-auto py-4 pr-6">
              <img
                src={logoVertical}
                alt=""
                className="mx-auto hidden md:block "
                srcset=""
                height="70"
                width="70"
              />
            </div>

            <div className="md:mr-auto">
              <div className="flex flex-col  w-full mx-auto  mt-4 lg:flex-row lg:max-w-5xl ">
                <div>
                  <h1 className="text-base font-semibold text-white items-start">
                    Horarios de atención:
                  </h1>
                  <div className="flex flex-col items-center justify-left md:w-56  lg:flex-row">
                    <div className="flex flex-col  w-full  mx-auto md:mx-0  lg:mb-0 ">
                      <h1 className="text-base font-normal  text-white">
                        Lunes a Viernes
                      </h1>
                      <p className="text-sm font-light text-gray-400">
                        9:00 - 19:00
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col w-full mx-auto  lg:mb-0 ">
                  <div>
                    <h1 className="text-base font-semibold text-white">
                      Teléfono:
                    </h1>
                    <p className="text-sm font-light w-full text-gray-400">
                      45 2 402046
                    </p>
                  </div>
                  <div>
                    <h1 className="text-base font-semibold text-white">
                      Email:
                    </h1>
                    <p className="text-sm font-light text-gray-400">
                      hercules@helpcom.cl
                    </p>
                  </div>
                </div>

                <div className="flex flex-col  w-full mx-auto lg:mb-0 ">
                  <h1 className="text-base font-semibold text-white">
                    Dirección:
                  </h1>
                  <p className="text-sm font-light text-gray-400">
                    Los Periodistas 0133, Temuco, Chile
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* <div class="row flex">
            <div class="column">Contenido 1</div>
            <div class="column">Contenido 2</div>
            <div class="column">Contenido 3</div>
            <div class="column">Contenido 4</div>
          </div> */}

          <div className="flex flex-col pb-3 items-center justify-center">
            <span className="text-sm font-light text-gray-400">
              Copyright © 2023 Helpcom
            </span>
            <span className="text-sm  font-light text-gray-400">
              Todos los derechos reservados.
            </span>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default LandingPage;
