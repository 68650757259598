import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTrabajadoresEmpresa,
  hideLoader,
  limpiarInfoMenuEdicion,
  showLoader,
} from "../../Redux/Actions";
import { useStateContext } from "../../contexts/ContextProvider";
import { ColumnFilter, Loader } from "../../components";
import ImprimirLiquidacionesTable from "../../components/Movimientos/ImprimirLiquidaciones/ImprimirLiquidacionesTable";
import UtilsFunctions from "../../utils/UtilsFunctions";
import { axiosPrivate } from "../../common/axiosPrivate";
import SimpleTable from "../../components/SimpleTable";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";

export default function ImpresionLiquidacionesIndividuales() {
  const { refreshTable, activeMenu, screenSize } = useStateContext();
  const dispatch = useDispatch();
  const [trabajadorFiltradoTabla, setTrabajadorFiltradoTabla] = useState([]);
  const [rutTrabajador, setRUTTrabajador] = useState("")
  const empresaId = localStorage.getItem("empresaId");

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const REACT_APP_API_HERCULES_SERVICE =
    process.env.REACT_APP_API_HERCULES_SERVICE;
    
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const trabajadoresData = useSelector((state) => state.trabajadoresEmpresa);
  console.log("trabajadoresData", trabajadoresData)

  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader());
      await Promise.all([dispatch(getTrabajadoresEmpresa(empresaId))]);
      dispatch(hideLoader());
    };

    fetchData().catch((error) => {
      console.log(error.response.data.message);
      dispatch(hideLoader());
    });
  }, [refreshTable, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("trabajadoresData"));
    };
  }, []);

  async function filtrarLiquidacionTrabajador(idTrabajador) {
    toast.loading("Cargando liquidaciones", estilosAlerta);
    try {
      const data = {
        trabajadorId: idTrabajador,
      };
      const response = await axiosPrivate.post(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/trabajadores/liquidaciones/empresa/${empresaId}`,
        data,
        Authorization
      );
      // console.log("response", response);
      toast.dismiss();
      if (response.data.length > 0) {
        setTrabajadorFiltradoTabla(response.data);
        toast.success("Liquidaciones cargadas con éxito", estilosAlerta);
      } else {
        setTrabajadorFiltradoTabla([]);
        toast("Sin Liquidaciones para este trabajador", estilosAlerta);
      }
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al cargar Liquidaciones",
        estilosAlerta
      );
    }
  }

  const COLUMNAS_TRABAJADORES = [
    {
      Header: "RUT",
      accessor: (d) => UtilsFunctions.rutFormatter(d.rut, { dots: true }),
      Filter: ColumnFilter,
    },
    {
      Header: "Trabajador",
      accessor: (d) =>
        d.apellidoPaterno + " " + d.apellidoMaterno + " " + d.nombres,
      Filter: ColumnFilter,
    },
  ];

  const COLUMNS = [
    {
      Header: "Periodo",
      accessor: (d) =>
        UtilsFunctions.periodoToText(d.periodoMes, d.periodoAnyo),
      Filter: ColumnFilter,
    },
    {
      Header: "RUT",
      accessor: (d) =>
        UtilsFunctions.rutFormatter(d.rutTrabajador, { dots: true }),
      Filter: ColumnFilter,
    },
    {
      Header: "Trabajador",
      accessor: (d) =>
        d.apellidoPaternoTrabajador +
        " " +
        d.apellidoMaternoTrabajador +
        " " +
        d.nombresTrabajador,
      Filter: ColumnFilter,
    },
    {
      Header: "Líquido a pagar",
      accessor: (d) => "$" + UtilsFunctions.integerFormatter(d.liquidoAPagar),
      Filter: ColumnFilter,
    },
  ];

  return (
    <div
      className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
        activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">Movimientos</div>
      <div className="titulo-Pagina">
        Impresión de liquidaciones por Trabajador
      </div>

      <div className="h-full md:mr-12 rounded-lg pt-6">
        <div className="mb-6">
          <Link to="/movimientos/impresion-liquidaciones">
            <button className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none ease-in-out transition-all duration-400">
              Imprimir Por Periodo
            </button>
          </Link>
        </div>

        <SimpleTable
          col={COLUMNAS_TRABAJADORES}
          tableData={trabajadoresData}
          primaryButton={{
            type: "ver",
            onClickFunc: (data) => {
              setRUTTrabajador(data.rut)
              filtrarLiquidacionTrabajador(data.id);
            },
            active: true,
          }}
          exportCsvButton={false}
        />
      </div>

      {trabajadorFiltradoTabla.length ? (
        <div className="h-full md:mr-12 rounded-lg pt-5">
          <ImprimirLiquidacionesTable
            col={COLUMNS}
            tableData={trabajadorFiltradoTabla}
            rutTrabajador={rutTrabajador}
            tipo="RUT"
          />
        </div>
      ) : (
        <div className="pt-10">
          <p className="text-lg font-medium dark:text-white text-hercules-light-font-secondary">
            Seleccione un trabajador para ver sus liquidaciones
          </p>
        </div>
      )}
    </div>
  );
}
