import React, { useState, useEffect } from "react";
import ColumnFilter from "../../components/ColumnFilter";
import { useDispatch, useSelector } from "react-redux";
import { useStateContext } from "../../contexts/ContextProvider";
import {
  getContratosResumen,
  getTrabajadoresSinContratos,
  hideLoader,
  limpiarInfoMenuEdicion,
  showLoader,
} from "../../Redux/Actions";
import UtilsFunctions from "../../utils/UtilsFunctions";
import {
  CrearNuevoContrato,
  CrearNuevoAnexo,
  TablaContratosAnexos,
} from "../../components";
import SimpleTable from "../../components/SimpleTable";
import AntiguoVerContratoAnexo from "../../components/Edicion/MantenedorContratosAnexos/AntiguoVerContratoAnexo";
import NuevoCrearAnexoContrato from "../../components/Edicion/MantenedorContratosAnexos/NuevoCrearAnexoContrato";
import NuevoVerContratoAnexo from "../../components/Edicion/MantenedorContratosAnexos/NuevoVerContratoAnexo";
import RutColumnFilter from "../../components/RutColumnFilter";

export default function MantenedorContratosAnexo() {
  const dispatch = useDispatch();
  const [showModalCrearNuevoContrato, setShowModalCrearNuevoContrato] =
    useState(false);
    const [showModalCrearNuevoAnexoAntiguo, setShowModalCrearNuevoAnexoAntiguo] =
    useState(false);
  const [showModalCrearNuevoAnexo, setShowModalCrearNuevoAnexo] =
    useState(false);
  const [showModalVerContratoAnexo, setShowModalVerContratoAnexo] =
    useState(false);
  const {
    rowDataContratos,
    setRowDataContratos,
    rowDataAnexo,
    refreshTable,
    activeMenu,
  } = useStateContext();
  const empresaId = localStorage.getItem("empresaId");

  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader());
      // Aqui se hace el dispatch de las acciones que son necesarias para obtener los datos de la tabla
      await Promise.all([
        dispatch(getContratosResumen(empresaId)),
        dispatch(getTrabajadoresSinContratos()),
      ]);
      dispatch(hideLoader());
    };
    fetchData().catch((error) => {
      console.log(error.response.data.message);
      dispatch(hideLoader());
    });
  }, [refreshTable, dispatch]);
  
  const contratosResumen = useSelector((state) => state.getContratosResumen);
  const sinContrato = useSelector((state) => state.getTrabajadoresSinContratos);

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("getContratosResumen"));
      dispatch(limpiarInfoMenuEdicion("getTrabajadoresSinContratos"));
    };
  }, []);

  // console.log("contratos", contratos);

  const COLUMNS_TRABAJADOR = [
    {
      Header: "RUT",
      accessor: "rut",
      Cell: ({ value }) => UtilsFunctions.rutFormatter(value, { dots: true }),
      Filter: RutColumnFilter,
    },
    {
      Header: "Trabajador",
      accessor: (d) =>
        d.apellidoPaterno + " " + d.apellidoMaterno + " " + d.nombres,
      Filter: ColumnFilter,
    },
  ];


  const COLUMNS_RESUMEN = [
    {
      Header: "RUT",
      accessor: "rutTrabajador",
      Cell: ({ value }) => UtilsFunctions.rutFormatter(value, { dots: true }),
      Filter: RutColumnFilter,
    },
    {
      Header: "Trabajador",
      accessor: (d) =>
        d.apellidoPaterno +
        " " +
        d.apellidoMaterno +
        " " +
        d.nombres,
      Filter: ColumnFilter,
    },
    {
      Header: "Fecha inicio",
      accessor: "fechaIngresoLegal",
      Filter: ColumnFilter,
    },
    {
      Header: "Fecha término",
      accessor: (d) => d.fechaTerminoContrato? d.fechaTerminoContrato : "Indefinido",
      Filter: ColumnFilter,
    },
    {
      Header: "C. Costo",
      accessor: "centroCosto",
      Filter: ColumnFilter,
    },
    {
      Header: "Área/Sección",
      accessor: "seccion",
      Filter: ColumnFilter,
    },
    {
      Header: "Jornada",
      accessor: (d) => d.articulo22? "No aplica" : d.jornada,
      Filter: ColumnFilter,
    },
    {
      Header: "Version",
      accessor: "version",
      Filter: ColumnFilter,
    },
    {
      Header: "Estado",
      accessor: (d) => 
           d.estado === 1
          ? "Vigente"
          : d.estado === 2
          ? "Vencido"
          : d.estado === 3 && "Finiquitado"
      ,
      Filter: ColumnFilter,
    },
  ];

  const headersCsv = [
    {
      key: "rutTrabajador",
      name: "RUT",
    },
    {
      key: "nombres",
      name: "Nombre",
    },
    {
      key: "apellidoPaterno",
      name: "Apellido Paterno",
    },
    {
      key: "apellidoMaterno",
      name: "Apellido Materno",
    },
    {
      key: "fechaIngresoLegal",
      name: "Fecha inicio",
    },
    {
      key: "fechaTerminoContrato",
      name: "Fecha termino",
    },
    {
      key: "centroCosto",
      name: "Centro de Costo",
    },
    {
      key: "seccion",
      name: "Seccion o Area",
    },
    {
      key: "jornada",
      name: "Jornada",
    },
    {
      key: "version",
      name: "Version",
    },
    {
      key: "estado",
      name: "Estado",
    },
  ];

  return (
    <div
      className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
        activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">Edición</div>
      <div className="titulo-Pagina">Mantenedor de Contratos</div>
      <div className="md:flex md:flex-row-reverse">
        <div className="md:w-1/3 md:ml-6 md:mr-12 md:pt-9 md:my-5 mt-6 mb-3 text-hercules-light-font-secondary dark:text-dark-primary">
          <p className="text-lg">
            Para crear un contrato nuevo, busque al trabajador y presione "Nuevo
            contrato".
            <br />
            <br />
            En caso de tener un contrato vigente, no podrá crear otro hasta que
            esté finiquitado. <br />
            <br />
            Si quiere modificar las condiciones de un contrato vigente, busque
            el contrato y presione "Crear Anexo".
          </p>
        </div>
        <div className="h-full rounded-lg pt-5 md:mr-3 md:w-2/3 overflow-auto">
          <p className="text-xl text-hercules-light-font-secondary dark:text-dark-primary">
            Trabajadores sin Contrato:
          </p>
          <SimpleTable
            col={COLUMNS_TRABAJADOR}
            tableData={sinContrato || []}
            primaryButton={{
              type: "nuevoContrato",
              onClickFunc: (data) => {
                setShowModalCrearNuevoContrato(true);
                setRowDataContratos(data);
              },
              active: true,
            }}
            exportCsvButton={false}
            tableHeight="h-96"
          />
        </div>
      </div>
      
      <div className="h-full md:mr-12 rounded-lg pt-9">
        <p className="text-xl text-hercules-light-font-secondary dark:text-dark-primary">
          Contratos/Anexos:
        </p>
        <TablaContratosAnexos
          col={COLUMNS_RESUMEN}
          tableData={contratosResumen || []}
          botonCrearAnexo={true}
          botonVer={true}
          abrirModalNuevoAnexo={() => setShowModalCrearNuevoAnexo(true)}
          abrirModalVerContratoAnexo={() => setShowModalVerContratoAnexo(true)}
          exportCsv={() =>
            UtilsFunctions.jsonToCSV3(contratosResumen, headersCsv, "Contratos_Anexos")
          }  
      />
      </div>

      {showModalCrearNuevoContrato ? (
        <div>
          <CrearNuevoContrato
            isVisible={showModalCrearNuevoContrato}
            onClose={() => setShowModalCrearNuevoContrato(false)}
            modalData={rowDataContratos}
          />
        </div>
      ) : null}

      {showModalCrearNuevoAnexo ? (
        <div>
          <NuevoCrearAnexoContrato
            isVisible={showModalCrearNuevoAnexo}
            onClose={() => setShowModalCrearNuevoAnexo(false)}
            modalData={rowDataAnexo}
          />
        </div>
      ) : null}


      {showModalVerContratoAnexo ? (
        <div>
          <NuevoVerContratoAnexo
            isVisible={showModalVerContratoAnexo}
            onClose={() => setShowModalVerContratoAnexo(false)}
            modalData={rowDataAnexo}
          />
        </div>
      ) : null}
    </div>
  );
}
