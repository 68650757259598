import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import img1 from '../../assets/img1.jpeg';
import img2 from '../../assets/img2.jpeg';
import img3 from '../../assets/img3.jpeg';


function MyCarousel() {
    return (
      <Carousel
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        showArrows={false}
        autoPlay={true}
        infiniteLoop={true}
      >
        <div className='h-40 w-28  mx-auto  flex'>
          <img src={img1} className='object-contain align-middle' alt="Carousel Image 1" />
        </div>
        <div className='h-40 mx-auto flex'>
          <img src={img2} className='object-scale-down align-middle ' alt="Carousel Image 2" />
        </div>
        <div className='h-40 mx-auto py-6 flex'>
          <img src={img3} className='object-scale-down align-middle' alt="Carousel Image 3" />
        </div>
      </Carousel>
    );
  }

export default MyCarousel;
  