import React from "react";
import { useForm } from "react-hook-form";
import { useStateContext } from "../../../../contexts/ContextProvider";
import toast from "react-hot-toast";
import { axiosPrivate } from "../../../../common/axiosPrivate";

export default function ModalAgregarPeriodo({
  idTrabajador,
  arrayPeriodos,
  onClose,
}) {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const validacionCampoObligatorio = {
    required: { value: true, message: "Obligatorio" },
  };

  const today = new Date();
  const now = today.toLocaleDateString("en-UK");
  const anyoActual = now.split("/")[2];

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const { refreshTable, setRefreshTable, screenSize } = useStateContext();

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  async function onSubmit(data) {
    toast.loading("Agregando Periodo", estilosAlerta);
    try {
      const requestBody = {
        trabajadorId: idTrabajador,
        diasProporcionales: 0,
        anyoInicio: data.anyoNuevoPeriodo,
        diasVacaciones: data.diasVacacionesDisponibles,
        feriadoProgresivo: data.diasProgresivos,
        anyoNuevoPeriodo: data.anyoNuevoPeriodo,
      };
      const response = await axiosPrivate.post(
        `${api_service}/api/v1/vacaciones/periodos/nuevo`,
        requestBody,
        Authorization
      );
      if (response.status === 200) {
        toast.dismiss();
        toast.success("Periodo agregado con éxito", estilosAlerta);
        setRefreshTable(!refreshTable);
        onClose();
        reset();
      }
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error(error.response.data.message? error.response.data.message : "Error al agregar Periodo", estilosAlerta);
    }
  }

  return (
    <>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="primerDivModal">
          <div className="segundoDivModal max-w-xl">
            {/*content*/}
            <div className="tercerDivModal shadow-lg  max-h-[85vh] ">
              {/*header*/}
              <div className="divHeaderModal border-slate-200">
                <h3 className="titulo-Modal">Agregar Periodo</h3>
              </div>
              {/*body*/}
              <div className="relative p-6 overflow-auto flex-auto dark:bg-hercules-dark-bg-200">
                {/* {!loadingModal && <ModalSpinner />} */}
                <>
                  {/* Primera fila modal */}

                  <div className="md:flex md:flex-wrap mt-4">
                    <div className="w-full md:w-4/12 md:pr-3 mb-9">
                      <label
                        className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Año
                      </label>
                      <input
                        {...register(
                          "anyoNuevoPeriodo",
                          validacionCampoObligatorio
                        )}
                        min={2000}
                        max={anyoActual - 1}
                        name="anyoNuevoPeriodo"
                        id="anyoNuevoPeriodo"
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="number"
                      />
                      <span
                        className={`text-red-500 dark:text-red-400 text-xs italic ${!errors.anyoNuevoPeriodo && "opacity-0 invisible"
                          }`}
                      >
                        {errors.anyoNuevoPeriodo
                          ? errors.anyoNuevoPeriodo.message
                          : "hidden"}
                      </span>
                    </div>

                    <div className="w-full md:w-4/12 md:px-3 mb-9">
                      <label
                        className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Días Vacaciones Disp.
                      </label>
                      <input
                        {...register(
                          "diasVacacionesDisponibles",
                          validacionCampoObligatorio
                        )}
                        min={0}
                        max={15}
                        name="diasVacacionesDisponibles"
                        id="diasVacacionesDisponibles"
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="number"
                      />
                      <span
                        className={`text-red-500 dark:text-red-400 text-xs italic ${!errors.diasVacacionesDisponibles &&
                          "opacity-0 invisible"
                          }`}
                      >
                        {errors.diasVacacionesDisponibles
                          ? errors.diasVacacionesDisponibles.message
                          : "hidden"}
                      </span>
                    </div>

                    <div className="w-full md:w-4/12 md:pl-3 mb-9">
                      <label
                        className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Días Progresivos
                      </label>
                      <input
                        {...register(
                          "diasProgresivos",
                          validacionCampoObligatorio
                        )}
                        min={0}
                        name="diasProgresivos"
                        id="diasProgresivos"
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="number"
                      />
                      <span
                        className={`text-red-500 dark:text-red-400 text-xs italic ${!errors.diasProgresivos && "opacity-0 invisible"
                          }`}
                      >
                        {errors.diasProgresivos
                          ? errors.diasProgresivos.message
                          : "hidden"}
                      </span>
                    </div>
                  </div>
                </>
              </div>

              {/*footer*/}
              <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-sm md:text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                  type="button"
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cerrar
                </button>
                <button
                  className="bg-hercules-light-primary-button hover:shadow-lg hover:scale-105 text-white  font-bold uppercase text-sm md:text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
                  type="submit"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-20 bg-black"></div>
      </form>
    </>
  );
}
