import React, { useEffect, useMemo } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useFilters,
  usePagination,
  useRowSelect,
} from "react-table";
import {
  TbArrowsSort,
  TbSortAscending,
  TbSortDescending,
} from "react-icons/tb";
import GlobalFilter from "./GlobalFilter";
import { BsDownload } from "react-icons/bs";
import { BiSearch } from "react-icons/bi";
import {
  MdArrowBackIos,
  MdArrowForwardIos,
  MdLastPage,
  MdFirstPage,
  MdEdit,
  MdOutlineVisibility,
  MdDelete,
  MdCheck,
  MdOutlineEvent,
  MdPublishedWithChanges,
  MdOutlineManageAccounts,
} from "react-icons/md";
import { HiSwitchHorizontal } from "react-icons/hi";
import { HiOutlineDocumentMinus } from "react-icons/hi2";
import { useSelector } from "react-redux";
import Skeleton from "./Skeleton";
import { AiFillLock } from "react-icons/ai";
import { IoDocumentTextOutline } from "react-icons/io5";

export default function SimpleTable({
  col,
  tableData,
  primaryButton,
  secondaryButton,
  liquidarButton,
  exportCsv,
  borders = true,
  searchBar = true,
  searchBarSearch = true,
  exportCsvButton = true,
  showFilters = true,
  hasPagination = true,
  tableHeight = "h-fix",
  rowConditional = false,
  rowConditionalFunc,
  loaderMessage = "Cargando datos...",
}) {
  // Row conditional
  // 
  // if (rowConditional && rowConditionalFunc(row.original)) {
  //   return null;
  // }
  // 
  // Si rowConditional es true, se va a poder usar rowConditionalFunc para ocultar filas. 
  // rowConditionalFunc debe retornar un booleano, si es true, se muestra la fila, si es false, se oculta.
  // A rowConditional se le va a pasar como parametro el objeto row.original, para que se pueda acceder a sus propiedades.
  // Ejemplo:
  // rowConditionalFunc = {(row) => {
  //   return row.id === 1;
  // }}
  // 
  // Este ejemplo hace que solo se muestre la fila que tenga id 1.

  /*
 Ejemplo body button
  primaryButton = {
    type: "editar", // editar, eliminar, ver
    onClickFunc: () => {
      console.log("editar");
    },
    active: true,
  }
 */

  // eslint-disable-next-line
  const isLoading = useSelector((state) => state.loader);

  const columns = useMemo(() => col, []);
  const data = useMemo(() => [...tableData], [tableData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    nextPage,
    previousPage,
    prepareRow,
    canPreviousPage,
    canNextPage,
    gotoPage,
    pageCount,
    setPageSize,
    pageOptions,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const { pageIndex, pageSize } = state;

  const { globalFilter } = state;

  if (isLoading) {
    return (
      <Skeleton
            width={"full"}
            height={tableHeight}
            loaderMessage={loaderMessage}
            searchBar={true}
            pagination={true}
          />
    )
  }

  return (
    <>
      {searchBar && (
        <div className={`bg-white dark:bg-hercules-dark-bg-100 ${borders ? "" : "rounded-tl-none"} border-t  border-l border-r rounded-t-md border-gray-300 dark:border-dark-bg-200 h-12 flex p-3`}>
          {searchBarSearch && (
            <>
              <span className="ml-auto ">
                <GlobalFilter
                  filter={globalFilter}
                  setFilter={setGlobalFilter}
                />
              </span>
              <span className="mx-3 pt-1 text-xl dark:text-dark-text-100 text-black hover:cursor-pointer">
                <BiSearch />
              </span>
            </>
          )}
          {searchBarSearch && exportCsvButton && (
            <span className={`border-1 mt-1`}></span>
          )}
          {exportCsvButton && (
            <span
              className={`${
                searchBarSearch ? "ml-3" : "ml-auto"
              }  pt-0.5 text-black dark:text-dark-text-100 text-xl hover:cursor-pointer`}
            >
              <BsDownload onClick={() => exportCsv()} />
            </span>
          )}
        </div>
      )}
      <div
        className={`${
          !hasPagination && "border-b-1 rounded-md"
        } ${tableHeight} overflow-auto border-x-1  bg-white dark:bg-hercules-dark-bg-200  dark:border-dark-bg-200 border-gray-300 border-t-1 `}
      >
        <table
          key="tableKey"
          {...getTableProps()}
          className="w-full  rounded-lg"
        >
          <thead
            className={`text-left sticky top-0 border-b-1 border-b-hercules-light-secondary dark:border-b-hercules-dark-secondary-button bg-hercules-light-table-header ${
              searchBar
                ? "dark:bg-hercules-dark-bg-200"
                : "dark:bg-hercules-dark-bg-100"
            }   `}
          >
            {headerGroups.map((headerGroup, i) => {
              const { key, ...restHeaderGroup } =
                headerGroup.getHeaderGroupProps();
              return (
                <React.Fragment key={i}>
                  <tr {...restHeaderGroup} className="h-10 flex-auto ">
                    {headerGroup.headers.map((column, i) => {
                      const { key, ...restColumn } = column.getHeaderProps(
                        column.getSortByToggleProps()
                      );
                      return (
                        <React.Fragment key={i}>
                          <th
                            {...restColumn}
                            className={`py-2 px-4 text-hercules-light-font-secondary font-medium dark:text-white ${column.columns && "border-b-1 border-white"}  ${column.Header == "Hombres" && "border-r-1"}`}
                          >
                            <div className={`flex ${column.columns && "justify-center"} `}>
                              <span className="">
                                {column.render("Header")}
                              </span>
                              {!column.columns && 
                              <span className="ml-1 mt-auto mb-auto">
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <TbSortDescending />
                                  ) : (
                                    <TbSortAscending />
                                  )
                                ) : (
                                  <TbArrowsSort />
                                )}
                              </span>
                            }
                            </div>
                          </th>
                        </React.Fragment>
                      );
                    })}
                    {primaryButton && primaryButton.type === "cambiarEmpresa" ? (
                      <th className="px-3 text-hercules-light-font-secondary font-medium dark:text-white">
                        Cambiar Empresa
                      </th>
                    ) : primaryButton && primaryButton.type === "editar" ? (
                      <th className="px-3 text-hercules-light-font-secondary font-medium dark:text-white">
                        Editar
                      </th>
                    ) : primaryButton && primaryButton.type === "nuevoContrato" ? (
                      <th className="px-3 text-hercules-light-font-secondary font-medium dark:text-white">
                        Nuevo Contrato
                      </th>
                    ) : primaryButton && primaryButton.type === "ver" ? (
                      <th className="px-3 text-hercules-light-font-secondary font-medium dark:text-white">
                        Ver Detalle
                      </th>
                    ) : primaryButton && primaryButton.type === "asignarDescuento" ? (
                      <th className="text-center text-hercules-light-font-secondary font-medium dark:text-white">
                        Asignar Descuento
                      </th>
                    ) : primaryButton && primaryButton.type === "canjearHorasExtra" ? (
                      <th className="text-center text-hercules-light-font-secondary font-medium dark:text-white">
                        Canjear Horas
                      </th>
                    ) : primaryButton && primaryButton.type === "exportarComprobante" ? (
                      <th className="text-center text-hercules-light-font-secondary font-medium dark:text-white">
                        Exportar
                      </th>
                    )  : primaryButton && (
                      <th className="px-3 text-hercules-light-font-secondary font-medium dark:text-white"></th>
                    ) }
                    {secondaryButton && secondaryButton.type === "finiquitarTrabajador" ? (
                      <th className="px-3 text-hercules-light-font-secondary font-medium dark:text-white">
                        Finiquitar Trabajador
                      </th>
                    ) : secondaryButton && secondaryButton.type === "cambiarClaveUsuario" ? (
                      <th className="px-3 text-hercules-light-font-secondary font-medium dark:text-white">
                        Clave
                      </th>
                    ) : secondaryButton && secondaryButton.type === "eliminar" ? (
                      <th className="px-3 text-hercules-light-font-secondary font-medium dark:text-white">
                        Eliminar
                      </th>
                    ) : secondaryButton && secondaryButton.type === "modificarCCosto" ? (
                      <th className="px-3 text-hercules-light-font-secondary font-medium dark:text-white">
                        Centros de Costo
                      </th>
                      ) : secondaryButton && secondaryButton.type === "eventos" ? (
                        <th className="px-3 text-hercules-light-font-secondary font-medium dark:text-white">
                          Eventos
                        </th>
                    ) : secondaryButton && secondaryButton.type === "verHistorico" ? (
                      <th className="text-center px-3 text-hercules-light-font-secondary font-medium dark:text-white">
                        Ver Histórico
                      </th>
                    ) : secondaryButton && secondaryButton.type === "comprobante" ? (
                      <th className="text-center px-3 text-hercules-light-font-secondary font-medium dark:text-white">
                        Comprobante
                      </th>
                    ) : secondaryButton && secondaryButton.type === "administrarComprobante" ? (
                      <th className="text-center text-hercules-light-font-secondary font-medium dark:text-white">
                        Administrar
                      </th>
                    ) : secondaryButton && (
                      <th className="px-3 text-hercules-light-font-secondary font-medium dark:text-white"></th>
                    )}
                    
                    {liquidarButton && liquidarButton.type === "liquidarDescuento" && (
                      <th className="px-3 text-hercules-light-font-secondary font-medium dark:text-white">
                        Liquidar Descuento
                      </th>
                    ) }

                  </tr>
                  {showFilters && (
                    <tr {...headerGroup.getHeaderGroupProps()} className="h-10">
                      {headerGroup.headers.map((column, i) => {
                        const { key, ...restColumn } = column.getHeaderProps();
                        return (
                          <th key={key} {...restColumn} className="pl-4 pb-4">
                            <div className="w-fit pr-4">
                              {column.canFilter
                                ? column.render("Filter")
                                : null}
                            </div>
                          </th>
                        );
                      })}
                      {primaryButton?.active && <th></th>}
                      {secondaryButton?.active && <th></th>}
                      {liquidarButton?.active && <th></th>}
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
          </thead>
          {hasPagination ? (
            <tbody {...getTableBodyProps()} className="h-full ">
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    key={i}
                    {...row.getRowProps()}
                    className="group bg-white dark:bg-hercules-dark-bg-200 dark:text-dark-text-100 dark:border-b-hercules-dark-secondary-button border-b border-hercules-light-secondary text-black h-12 dark:hover:bg-white dark:hover:bg-opacity-10 transition duration-100 ease-in-out hover:bg-light-accent hover:bg-opacity-20"
                  >
                    {row.cells.map((cell, i) => {
                      return (
                        <td
                          key={i}
                          {...cell.getCellProps}
                          className="py-2 px-4 text-base font-normal"
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                    {primaryButton?.active && (
                      <td className="">
                        {primaryButton?.type === "editar" ? (
                          <MdEdit
                            onClick={() =>
                              primaryButton.onClickFunc(row.original)
                            }
                            className="mr-3 text-2xl group-hover:cursor-pointer text-green-500"
                          />
                        ) : primaryButton?.type === "nuevoContrato" ? (
                          <MdEdit
                            onClick={() =>
                              primaryButton.onClickFunc(row.original)
                            }
                            className="mr-3 text-2xl group-hover:cursor-pointer text-green-500"
                          />
                        ) : primaryButton?.type === "eliminar" ? (
                          <MdDelete
                            onClick={() =>
                              primaryButton.onClickFunc(row.original)
                            }
                            className="mr-3 text-2xl group-hover:cursor-pointer text-red-500"
                          />
                        ) : primaryButton?.type === "ver" ? (
                          <MdOutlineVisibility
                            onClick={() =>
                              primaryButton.onClickFunc(row.original)
                            }
                            className="mr-3 text-2xl group-hover:cursor-pointer text-blue-500"
                          />
                        ) : primaryButton?.type === "cambiarEmpresa" ? (
                          <HiSwitchHorizontal
                            onClick={() =>
                              primaryButton.onClickFunc(row.original)
                            }
                            className="ml-6 mr-3 text-2xl group-hover:cursor-pointer text-green-500"
                          />
                        ) : primaryButton?.type === "asignarDescuento" ? (
                          <MdEdit
                            onClick={() =>
                              primaryButton.onClickFunc(row.original)
                            }
                            className="mx-auto text-2xl group-hover:cursor-pointer text-blue-500"
                          />
                        ) : primaryButton?.type === "canjearHorasExtra" ? (
                          <MdPublishedWithChanges
                            onClick={() =>
                              primaryButton.onClickFunc(row.original)
                            }
                            className="mx-auto text-2xl group-hover:cursor-pointer text-green-500"
                          />
                        ) : null}
                      </td>
                    )}
                    {secondaryButton?.active && (
                      <td className="">
                        {secondaryButton?.type === "editar" ? (
                          <MdEdit
                            onClick={() =>
                              secondaryButton.onClickFunc(row.original)
                            }
                            className="mr-3 text-2xl group-hover:cursor-pointer text-green-500"
                          />
                        ) : secondaryButton?.type === "eliminar" ? (
                          <MdDelete
                            onClick={() =>{
                              secondaryButton.onClickFunc(row.original)}
                            }
                            className="mr-3 text-2xl group-hover:cursor-pointer text-red-500"
                          />
                        ) : secondaryButton?.type === "ver" ? (
                          <MdOutlineVisibility
                            onClick={() =>
                              secondaryButton.onClickFunc(row.original)
                            }
                            className="mr-3 text-2xl group-hover:cursor-pointer text-blue-500"
                          />
                        ) : secondaryButton?.type === "finiquitarTrabajador" ? (
                          <HiOutlineDocumentMinus
                            onClick={() =>
                              secondaryButton.onClickFunc(row.original)
                            }
                            className="ml-6 mr-3 text-2xl group-hover:cursor-pointer text-green-500"
                          />
                        ) : secondaryButton?.type === "cambiarClaveUsuario" ? (
                          <AiFillLock
                            onClick={() =>
                              secondaryButton.onClickFunc(row.original)
                            }
                            className="ml-6 mr-3 text-2xl group-hover:cursor-pointer text-green-500"
                          />
                        ) : secondaryButton?.type === "eventos" ? (
                          <MdOutlineEvent
                            onClick={() =>
                              secondaryButton.onClickFunc(row.original)
                            }
                            className="ml-6 mr-3 text-2xl group-hover:cursor-pointer text-green-500"
                          />
                        ) : secondaryButton?.type === "modificarCCosto" ? (
                          <MdEdit
                            onClick={() =>
                              secondaryButton.onClickFunc(row.original)
                            }
                            className="ml-6 mr-3 text-2xl group-hover:cursor-pointer text-green-500"
                          />
                        ) : secondaryButton?.type === "verHistorico" ? (
                          <MdOutlineVisibility
                            onClick={() =>
                              secondaryButton.onClickFunc(row.original)
                            }
                            className="mx-auto text-2xl group-hover:cursor-pointer text-blue-500"
                          />
                        ) : secondaryButton?.type === "comprobante" ? (
                          <IoDocumentTextOutline
                            onClick={() =>
                              secondaryButton.onClickFunc(row.original)
                            }
                            className="mx-auto text-2xl group-hover:cursor-pointer text-green-500"
                          />
                        ) : secondaryButton?.type === "administrarComprobante" ? (
                          <MdOutlineManageAccounts
                            onClick={() =>
                              secondaryButton.onClickFunc(row.original)
                            }
                            className="mx-auto text-2xl group-hover:cursor-pointer text-green-500"
                          />
                        ) : null}
                      </td>
                    )}

                    {liquidarButton?.active && (
                      <td className="">
                        {liquidarButton?.type === "liquidarDescuento" ? (
                          <MdCheck
                            onClick={() =>
                              liquidarButton.onClickFunc(row.original)
                            }
                            className={`mr-3 text-2xl group-hover:cursor-pointer ${row.original.liquidado ? "text-gray-500" : "text-green-500"}`}
                          />
                        ) : null}
                      </td>
                    )}

                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody {...getTableBodyProps()} className="h-full ">
              {rows.map((row, i) => {
                if (rowConditional && !rowConditionalFunc(row.original)) {
                  return null;
                }
                prepareRow(row);
                return (
                  <tr
                    key={i}
                    {...row.getRowProps()}
                    className="group bg-white dark:bg-hercules-dark-bg-200   dark:text-dark-text-100 dark:border-b-hercules-dark-secondary-button border-b border-hercules-light-secondary text-black h-12 dark:hover:bg-white dark:hover:bg-opacity-10 transition duration-100 ease-in-out hover:bg-light-accent hover:bg-opacity-20"
                  >
                    {row.cells.map((cell, i) => {
                      return (
                        <td
                          key={i}
                          {...cell.getCellProps}
                          className="py-2 px-4 font-normal"
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                    {primaryButton?.active && (
                      <td className="">
                        {primaryButton?.type === "editar" ? (
                          <MdEdit
                            onClick={() =>
                              primaryButton.onClickFunc(row.original)
                            }
                            className="mr-3 text-2xl group-hover:cursor-pointer text-green-500"
                          />
                        ) : primaryButton?.type === "nuevoContrato" ? (
                          <MdEdit
                            onClick={() =>
                              primaryButton.onClickFunc(row.original)
                            }
                            className="mr-3 text-2xl group-hover:cursor-pointer text-green-500"
                          />
                        ) : primaryButton?.type === "eliminar" ? (
                          <MdDelete
                            onClick={() =>
                              primaryButton.onClickFunc(row.original)
                            }
                            className="mr-3 text-2xl group-hover:cursor-pointer text-red-500"
                          />
                        ) : primaryButton?.type === "ver" ? (
                          <MdOutlineVisibility
                            onClick={() =>
                              primaryButton.onClickFunc(row.original)
                            }
                            className="mr-3 text-2xl group-hover:cursor-pointer text-blue-500"
                          />
                        ) : primaryButton?.type === "exportarComprobante" ? (
                          <IoDocumentTextOutline
                            onClick={() =>
                              primaryButton.onClickFunc(row.original)
                            }
                            className="mx-auto text-2xl group-hover:cursor-pointer text-green-500"
                          />
                        ) : null}
                      </td>
                    )}
                    {secondaryButton?.active && (
                      <td className="">
                        {secondaryButton?.type === "editar" ? (
                          <MdEdit
                            onClick={() => secondaryButton.onClickFunc(row.original)}
                            className="mr-3 text-2xl group-hover:cursor-pointer text-green-500"
                          />
                        ) : secondaryButton?.type === "eliminar" ? (
                          <MdDelete
                            onClick={() => secondaryButton.onClickFunc(row.original)}
                            className="mr-3 text-2xl group-hover:cursor-pointer text-red-500"
                          />
                        ) : secondaryButton?.type === "ver" ? (
                          <MdOutlineVisibility
                            onClick={() => secondaryButton.onClickFunc(row.original)}
                            className="mr-3 text-2xl group-hover:cursor-pointer text-blue-500"
                          />
                        ) : null}
                      </td>
                    )}
                    {liquidarButton?.active && (
                      <td className="">
                        {liquidarButton?.type === "liquidarDescuento" ? (
                          <MdCheck
                            onClick={() => liquidarButton.onClickFunc()}
                            className="mr-3 text-2xl group-hover:cursor-pointer text-green-500"
                          />
                        ) : null}
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
      {hasPagination && (
        <div className=" border-1 dark:border-t-hercules-dark-secondary-button border-t-hercules-light-secondary border-gray-300 dark:border-dark-bg-200 dark:bg-hercules-dark-bg-200   rounded-b-md h-14 flex bg-white">
          <div className="ml-auto py-3 flex">
            <p className="hidden sm:block pt-1 text-black dark:text-white">
              Filas por página
            </p>
            <select
              name=""
              id=""
              className="ml-2 h-8 pt-1.5 text-sm bg-white border rounded-sm"
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
            >
              {[10, 20, 30, 40, 50].map((pageSize, i) => (
                <option key={i} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block p-4 text-black dark:text-white">
            Página {pageIndex + 1} de {pageOptions.length}
          </div>
          <div className="flex mr-4 pl-4">
            <button
              className="bg-white text-black dark:text-white dark:bg-opacity-0 rounded-full hover:bg-gray-200 hover:text-black my-2 w-8 mr-6"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              <MdFirstPage className="pl-1 text-2xl" />
            </button>
            <button
              className="bg-white text-black rounded-full dark:text-white dark:bg-opacity-0  hover:bg-gray-200 hover:text-black my-2 mr-6 w-8"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              <MdArrowBackIos className="ml-2" />
            </button>
            <button
              className="bg-white text-black rounded-full dark:text-white dark:bg-opacity-0  hover:bg-gray-200 hover:text-black my-2 mr-6 w-8"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              <MdArrowForwardIos className="ml-2" />
            </button>
            <button
              className="bg-white text-black rounded-full dark:text-white dark:bg-opacity-0  hover:bg-gray-200 hover:text-black my-2 w-8"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              <MdLastPage className="pl-1 text-2xl" />
            </button>
          </div>
        </div>
      )}
    </>
  );
}
