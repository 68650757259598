import React, { useState } from "react";
import { useStateContext } from "../../../contexts/ContextProvider";
import { axiosPrivate } from "../../../common/axiosPrivate";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import ModalSpinner from "../../ModalSpinner";

export default function EliminarEvento({ isVisible, onClose, modalData }) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: modalData,
  });

  const [botonGuardarHabilitado, setBotonGuardarHabilitado] = useState(true)
  const { refreshTable, setRefreshTable, screenSize } = useStateContext();
  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const onSubmit = async (data) => {
    setBotonGuardarHabilitado(false)
    try {
      toast.loading("Eliminando Evento", estilosAlerta);
      const response = await axiosPrivate.delete(
        `${api_service}/api/v1/trabajadores/evento/${modalData.id}`,
        Authorization
      );
      if (response.status === 200) {
        toast.dismiss();
        toast.success("Evento eliminado con éxito", estilosAlerta);
        setRefreshTable(!refreshTable);
        onClose();
      }
      setBotonGuardarHabilitado(true)
    } catch (error) {
      toast.dismiss();
      toast.error(error.response.data.message? error.response.data.message : "Error al eliminar Evento", estilosAlerta)
      console.log(error);
      setBotonGuardarHabilitado(true)
    }
  };

  const loadingModal = modalData ? true : false;

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="primerDivModal">
          <div className="segundoDivModal max-w-lg">
            {/*content*/}
            <div className="tercerDivModal shadow-lg  max-h-[85vh] ">
              {/*header*/}
              <div className="divHeaderModal border-slate-200">
                <h3 className="titulo-Modal">Eliminar Evento</h3>
              </div>
              {/*body*/}
              <div className="relative p-6 flex-auto overflow-auto dark:bg-hercules-dark-bg-200">
                {!loadingModal && <ModalSpinner />}

                <div className="div-Inputs-EditarHaber">
                  <label
                    className="dark:text-white block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-last-name"
                  >
                    Nombre
                  </label>
                  <input
                    {...register("nombre")}
                    disabled
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="text"
                  />
                </div>

              </div>
              {/*footer*/}
              <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                  type="button"
                  onClick={() => {
                    onClose();
                    reset();
                  }}
                >
                  Cerrar
                </button>
                <button
                  disabled={!botonGuardarHabilitado}
                  className={` ${!botonGuardarHabilitado? "bg-gray-500" : "bg-red-500 hover:shadow-lg hover:scale-105"}  text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400`}
                  type="submit"
                >
                  Eliminar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </form>
    </>
  );
}
