import React, { useEffect, useState } from "react";
import { useStateContext } from "../../../contexts/ContextProvider";
import { axiosPrivate } from "../../../common/axiosPrivate";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import UtilsFunctions from "../../../utils/UtilsFunctions";
import { useDispatch, useSelector } from "react-redux";
import ColumnFilter from "../../ColumnFilter";
import {
  getCargos,
  getCentrosCostosByEmpresaId,
  getGratificacionByEmpresa,
  getHaberes,
  getJornadas,
  getSecciones,
  limpiarInfoMenuEdicion,
} from "../../../Redux/Actions";
import AgregarHaberForm from "./AgregarHaberForm";
import TablaHaberes from "./TablaHaberes";
import ModalSpinner from "../../ModalSpinner";
import ModalAdvertenciaGratificacionLegal from "./ModalAdvertenciaGratificacion";

export default function CrearNuevoContrato({ isVisible, onClose, modalData }) {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      gratificacionId: "",
    },
  });

  const dispatch = useDispatch();

  const [botonGuardarHabilitado, setBotonGuardarHabilitado] = useState(true)
  const { refreshTable, setRefreshTable, screenSize } = useStateContext();
  const [sueldoMinimoProporcional, setSueldoMinimoProporcional] =
    useState(null);
  const [haberesAnexo, setHaberesAnexo] = useState([]);
  const [tieneSemanaCorrida, setTieneSemanaCorrida] = useState(false);
  const [tieneGratificaciones, setTieneGratificaciones] = useState(false);
  const [showModalGratificacionLegal, setShowModalGratificacionLegal] =
    useState(false);
  const nombreEmpresa = localStorage.getItem("empresa");
  const watchDataFechaIngreso = watch("fechaIngreso", "");
  const watchDataPlazoContratoId = watch("plazoContratoId", "");
  const watchArt22 = watch("articulo22", false);
  const cargos = useSelector((state) => state.getCargos);
  const jornadas = useSelector((state) => state.getJornadas);
  const secciones = useSelector((state) => state.getSecciones);
  const haberesLista = useSelector((state) => state.getHaberes);
  const listaCCosto = useSelector((state) => state.centrosCostoEmpresa);
  const gratificacionEmpresa = useSelector((state) => state.empresaGratificacion);
  const [payloadSinGratificacion, setPayloadSinGratificacion] = useState({});

  console.log("gratificacionEmpresa", gratificacionEmpresa)

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const validacionCampoObligatorio = {
    required: { value: true, message: "Obligatorio" },
  };

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const empresaId = localStorage.getItem("empresaId");

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  useEffect(() => {
    dispatch(getCargos());
    dispatch(getJornadas());
    dispatch(getCentrosCostosByEmpresaId(empresaId));
    dispatch(getSecciones());
    dispatch(getHaberes());
    dispatch(getGratificacionByEmpresa(empresaId))
    if (modalData) {
      const nombreTrabajador =
        modalData.nombres +
        " " +
        modalData.apellidoPaterno +
        " " +
        modalData.apellidoMaterno;
      const rutTrabajador = UtilsFunctions.rutFormatter(modalData.rut, {
        dots: true,
      });
      const direccionTrabajador =
        modalData.calle +
        " " +
        modalData.numero +
        " " +
        (modalData.departamento == "N/A" ? "" : modalData.departamento);
      const comuna = modalData.ciudad.comuna.nombre;
      const ciudad = modalData.ciudad.nombre;
      reset({
        nombreTrabajador: nombreTrabajador,
        rut: rutTrabajador,
        direccion: direccionTrabajador,
        comuna: comuna,
        ciudad: ciudad,
      });
    }
  }, [modalData, refreshTable, tieneSemanaCorrida]);

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("getCargos"));
      dispatch(limpiarInfoMenuEdicion("getJornadas"));
      dispatch(limpiarInfoMenuEdicion("centrosCostoEmpresa"));
      dispatch(limpiarInfoMenuEdicion("getSecciones"));
      dispatch(limpiarInfoMenuEdicion("getHaberes"));
      dispatch(limpiarInfoMenuEdicion("empresaGratificacion"));
      reset({});
    };
  }, []);

  useEffect(() => {
    setValue("gratificacionId", gratificacionEmpresa.id)
    setValue("gratificacionNombre", gratificacionEmpresa.nombre)
    setValue("gratificacionTexto", gratificacionEmpresa.observacion)
  }, [dispatch, tieneGratificaciones]);

  function agregarHaber(haberes) {
    if (!haberes) {
      return null;
    }
    if (
      (haberes.haber.haberesDt.codigo === 2106 ||
        haberes.haber.haberesDt.codigo === 2122) &&
      haberesAnexo.find(
        (e) =>
          e.haber.haberesDt.codigo === 2106 || e.haber.haberesDt.codigo === 2122
      )
    ) {
      toast.dismiss();
      toast.error("El contrato ya tiene un Haber de Gratificación", estilosAlerta);
      return null;
    }
    if (/^ *$/.test(haberes.valor) && !haberes.valorVariable) {
      return null;
    }
    // if (
    //   haberes.haber.nombre === "Sueldo Base" &&
    //   parseInt(haberes.valor) < sueldoMinimoProporcional
    // ) {
    //   toast.dismiss();
    //   toast.error(
    //     "El Sueldo Base mínimo de esta jornada es de $" +
    //       UtilsFunctions.integerFormatter(sueldoMinimoProporcional), estilosAlerta
    //   );
    //   return null;
    // }
    if (haberes.valorVariable) {
      haberes.valor = -1;
    }
    toast.loading("Agregando Haber", estilosAlerta);
    try {
      const nuevoArray = [...haberesAnexo, haberes];
      setHaberesAnexo(nuevoArray);
      const encontrarSemanaCorrida = nuevoArray.find(
        (e) => e.haber.haberesDt.id == "4"
      );
      if (encontrarSemanaCorrida?.haber) {
        setTieneSemanaCorrida(true);
      } else {
        setTieneSemanaCorrida(false);
      }
      const encontrarGratificacion6 = nuevoArray.find(
        (e) => e.haber.haberesDt.id == 6
      );
      const encontrarGratificacion22 = nuevoArray.find(
        (e) => e.haber.haberesDt.id == 22
      );
      if (encontrarGratificacion22 || encontrarGratificacion6) {
        setTieneGratificaciones(true);
      } else {
        setTieneGratificaciones(false);
      }
      toast.dismiss();
      toast.success("Haber agregado con éxito", estilosAlerta);
    } catch (error) {
      console.log("Error", error);
      toast.dismiss();
      toast.error(error.response.data.message? error.response.data.message : "Error al agregar Haber", estilosAlerta);
    }
  }

  async function onSubmit(data) {
    try {
      toast.loading("Creando contrato...");
      if (!haberesAnexo.find((e) => e.haber.haberesDt.codigo === 2101)) {
        toast.dismiss();
        toast.error("El contrato debe tener un Haber de Sueldo Base", estilosAlerta);
        return null;
      }

      const anyoIngreso = data.fechaIngreso.split("-")[0];
      const mesIngreso = data.fechaIngreso.split("-")[1];
      const diaIngreso = data.fechaIngreso.split("-")[2];
      const fechaIngreso = `${diaIngreso}-${mesIngreso}-${anyoIngreso}`;

      const anyoIngresoLegal = data.fechaIngresoLegal.split("-")[0];
      const mesIngresoLegal = data.fechaIngresoLegal.split("-")[1];
      const diaIngresoLegal = data.fechaIngresoLegal.split("-")[2];
      const fechaIngresoLegal = `${diaIngresoLegal}-${mesIngresoLegal}-${anyoIngresoLegal}`;

      const anyoTerminoContrato = data.fechaTerminoContrato
        ? data.fechaTerminoContrato.split("-")[0]
        : null;
      const mesTerminoContrato = data.fechaTerminoContrato
        ? data.fechaTerminoContrato.split("-")[1]
        : null;
      const diaTerminoContrato = data.fechaTerminoContrato
        ? data.fechaTerminoContrato.split("-")[2]
        : null;
      const fechaTerminoContrato = `${diaTerminoContrato}-${mesTerminoContrato}-${anyoTerminoContrato}`;

      const payload = {
        estado: 1,
        trabajadorId: modalData.id,
        fechaIngreso: fechaIngreso,
        fechaIngresoLegal: fechaIngresoLegal,
        fechaTerminoContrato: data.fechaTerminoContrato
          ? fechaTerminoContrato
          : null,
        articulo22: data.articulo22 == "true" ? true : false,
        cargoId: data.cargoId,
        trabajoPesado: data.trabajoPesado,
        gratificacionId: data.gratificacionId ? data.gratificacionId : 3,
        plazoContratoId: data.plazoContratoId,
        jornadaId: data.articulo22 == "true" ? 0 : data.jornadaId,
        seccionId: data.seccionId,
        centroCostoId: data.centroCostoId,
        semanaCorrida: tieneSemanaCorrida,
        haberes: UtilsFunctions.formatearHaberes(haberesAnexo),
      };
      setPayloadSinGratificacion(payload);
      setBotonGuardarHabilitado(false)

      if (
        !haberesAnexo.find(
          (e) =>
            e.haber.haberesDt.codigo === 2106 ||
            e.haber.haberesDt.codigo === 2122
        )
      ) {
        // Caso para cuando no tiene Gratificacion Legal
        toast.dismiss();
        setShowModalGratificacionLegal(true);
        setBotonGuardarHabilitado(true)
        return null;
      }

      await axiosPrivate.post(
        `${api_service}/api/v1/trabajadores/nuevocontrato`,
        payload,
        Authorization
      );
      toast.dismiss();
      toast.success("Contrato creado con éxito", estilosAlerta);
      setBotonGuardarHabilitado(true)
      setRefreshTable(!refreshTable);
      reset();
      onClose();
    } catch (error) {
      toast.dismiss();
      if (
        error.response.data.message !==
        "gratificacionId: Debe seleccionar un tipo de gratificación o sin gratificación"
      ) {
        toast.error(error.response.data.message ? error.response.data.message : "Error al crear Contrato", estilosAlerta);
      }
      console.log(error);
      setBotonGuardarHabilitado(true)
    }
  }

  const trabajoPesado = [
    {
      id: 0,
      nombre: "No",
    },
    {
      id: 1,
      nombre: "Trabajo menos pesado 2%",
    },
    {
      id: 2,
      nombre: "Trabajo pesado 4%",
    },
  ];

  const objGratificacion = [
    {
      nombre: "Art 50 código del trabajo",
      id: 1,
      texto:
        "Gratificación del 25% de lo percibido por el trabajador, con un máximo de 4.75 IMM al año",
    },
    {
      nombre: "Art 47 código del trabajo",
      id: 2,
      texto:
        "Gratificación no inferior al 30% de las utilidades anuales de la empresa",
    },
  ];

  const TABLA_HABERES_TRABAJADOR = [
    {
      Header: "Nombre",
      accessor: "haber.nombre",
      Filter: ColumnFilter,
    },
    {
      Header: "Categoría L.R.E.",
      accessor: "haber.haberesDt.glosa",
      Filter: ColumnFilter,
    },
    {
      Header: "Imponible",
      accessor: "haber.haberesDt.imponible",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value === true || value === "true") {
          return "Si";
        } else {
          return "No";
        }
      },
    },
    {
      Header: "Tributable",
      accessor: "haber.haberesDt.tributable",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value === true || value === "true") {
          return "Si";
        } else {
          return "No";
        }
      },
    },
    {
      Header: "Constituye Remuneración",
      accessor: "haber.haberesDt.constRemuneracion",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value == "true") {
          return "Si";
        } else {
          return "No";
        }
      },
    },
    {
      Header: "Fijo o Variable",
      accessor: "valorFijo",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value === true) {
          return "Fijo";
        } else {
          return "Variable";
        }
      },
    },
    {
      Header: "Monto",
      accessor: "valor",
      Filter: ColumnFilter,
      Cell: ({ value, row }) => {
        if (parseInt(value) === -1 || row.original.valorVariable === true) {
          return "N/A";
        } else {
          return "$" + UtilsFunctions.integerFormatter(value);
        }
      },
    },
    {
      Header: "Cond. asistencia total",
      accessor: "haber.condicionalAsistenciaTotal",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value === true || value === "true") {
          return "Si";
        } else {
          return "No";
        }
      },
    },
    {
      Header: "Proporcional a dias trabajados",
      accessor: "haber.pagoProporcionalDiasTrabajados",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value === true || value === "true") {
          return "Si";
        } else {
          return "No";
        }
      },
    },
  ];

  const loadingModal = modalData;

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="primerDivModal">
          <div className="segundoDivModal max-w-6xl">
            {/*content*/}
            <div className="tercerDivModal shadow-lg  max-h-[90vh] ">
              {/*header*/}
              <div className="divHeaderModal border-slate-200">
                <h3 className="titulo-Modal">Nuevo Contrato</h3>
              </div>
              {/*body*/}
              {!loadingModal && <ModalSpinner />}
              <>
                <div className="relative p-6 flex-auto overflow-auto dark:bg-hercules-dark-bg-200">
                  <div className="w-full md:w-4/12 md:pr-3 md:mb-11 mb-9">
                    <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                      Empresa
                    </label>
                    <input
                      value={nombreEmpresa}
                      disabled
                      className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                      type="text"
                    />
                  </div>

                  <div className="md:flex md:flex-wrap">
                    <div className="w-full md:w-4/12 md:pr-3 md:mb-11 mb-9">
                      <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                        Nombre Trabajador
                      </label>
                      <input
                        {...register("nombreTrabajador")}
                        disabled
                        className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                      />
                    </div>

                    <div className="w-full md:w-4/12 md:px-3 md:mb-11 mb-9">
                      <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                        Rut Trabajador
                      </label>
                      <input
                        {...register("rut")}
                        disabled
                        className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="flex flex-wrap">
                    <div className="w-full md:w-4/12 md:pr-3 md:mb-11 mb-9">
                      <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                        Dirección
                      </label>
                      <input
                        {...register("direccion")}
                        disabled
                        className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                        id="direccion"
                      />
                    </div>

                    <div className="w-full md:w-4/12 md:px-3 md:mb-11 mb-9">
                      <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                        Comuna
                      </label>
                      <input
                        {...register("comuna")}
                        disabled
                        className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                        id="comuna"
                      />
                    </div>

                    <div className="w-full md:w-4/12 md:pl-3 md:mb-11 mb-9">
                      <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                        Ciudad
                      </label>
                      <input
                        {...register("ciudad")}
                        disabled
                        className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                        id="ciudad"
                      />
                    </div>
                  </div>

                  <div className="div-Inputs-EditarHaber md:w-4/12 md:pr-3">
                    <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                      Duración del Contrato
                    </label>
                    <select
                      {...register(
                        "plazoContratoId",
                        validacionCampoObligatorio,
                        {
                          onChange: (e) => {
                            if (e.target.value == 2) {
                              console.log("es Indefinido");
                              setValue("fechaTerminoContrato", "");
                            }
                          },
                        }
                      )}
                      className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                    >
                      <option value="" key="0" hidden>
                        Seleccionar duración de contrato
                      </option>
                      <option value="1" key="1">
                        Plazo Fijo
                      </option>
                      <option value="2" key="2">
                        Indefinido
                      </option>
                    </select>
                    <span
                      className={`text-red-500 dark:text-red-400 text-xs italic ${
                        !errors.plazoContratoId && "opacity-0 invisible"
                      }`}
                    >
                      {errors.plazoContratoId
                        ? errors.plazoContratoId.message
                        : "hidden"}
                    </span>
                  </div>

                  <div className="md:flex md:flex-wrap">
                    <div className="div-Inputs-EditarHaber md:w-4/12 md:pr-3">
                      <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                        Fecha de Ingreso
                      </label>
                      <input
                        {...register(
                          "fechaIngreso",
                          validacionCampoObligatorio
                        )}
                        className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        type="date"
                      />
                      <span
                        className={`text-red-500 dark:text-red-400 text-xs italic ${
                          !errors.fechaIngreso && "opacity-0 invisible"
                        }`}
                      >
                        {errors.fechaIngreso
                          ? errors.fechaIngreso.message
                          : "hidden"}
                      </span>
                    </div>

                    <div className="div-Inputs-EditarHaber md:w-4/12 md:px-3">
                      <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                        Fecha de Ingreso Legal
                      </label>
                      <input
                        {...register(
                          "fechaIngresoLegal",
                          validacionCampoObligatorio
                        )}
                        className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        type="date"
                      />
                      <span
                        className={`text-red-500 dark:text-red-400 text-xs italic ${
                          !errors.fechaIngresoLegal && "opacity-0 invisible"
                        }`}
                      >
                        {errors.fechaIngresoLegal
                          ? errors.fechaIngresoLegal.message
                          : "hidden"}
                      </span>
                    </div>

                    <div className="w-full md:w-4/12 md:pl-3">
                      <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                        Fecha de término Contrato
                      </label>
                      {watchDataPlazoContratoId &&
                      watchDataPlazoContratoId == "1" ? (
                        <>
                          <input
                            min={watchDataFechaIngreso}
                            {...register(
                              "fechaTerminoContrato",
                              validacionCampoObligatorio
                            )}
                            className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                            type="date"
                          />
                          <span
                            className={`text-red-500 dark:text-red-400 text-xs italic ${
                              !errors.fechaTerminoContrato &&
                              "opacity-0 invisible"
                            }`}
                          >
                            {errors.fechaTerminoContrato
                              ? errors.fechaTerminoContrato.message
                              : "hidden"}
                          </span>
                        </>
                      ) : watchDataPlazoContratoId == "2" ? (
                        <input
                          className="appearance-none block w-full bg-gray-300 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                          type="text"
                          disabled
                          value={"No aplica"}
                        />
                      ) : (
                        <>
                          <input
                            className="appearance-none block w-full bg-gray-300 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                            type="text"
                            disabled
                            value={"Seleccionar duración de Contrato"}
                          />
                          <span
                            className={`text-red-500 dark:text-red-400 text-xs italic ${
                              !errors.plazoContratoId && "opacity-0 invisible"
                            }`}
                          >
                            {errors.plazoContratoId
                              ? "Seleccionar duración de contrato"
                              : "hidden"}
                          </span>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="md:flex md:flex-wrap">
                    <div className="div-Inputs-EditarHaber md:w-4/12 md:pr-3">
                      <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                        Trabajador Artículo 22
                      </label>
                      <select
                        {...register("articulo22", validacionCampoObligatorio)}
                        className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                      >
                        <option value="" hidden>
                          Elegir opción
                        </option>
                        <option value={true}>Si</option>
                        <option value={false}>No</option>
                      </select>
                      <span
                        className={`text-red-500 dark:text-red-400 text-xs italic ${
                          !errors.articulo22 && "opacity-0 invisible"
                        }`}
                      >
                        {errors.articulo22
                          ? errors.articulo22.message
                          : "hidden"}
                      </span>
                    </div>

                    <div className="div-Inputs-EditarHaber md:w-4/12 md:px-3">
                      <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                        Jornada Laboral
                      </label>
                      {watchArt22 == "true" || watchArt22 == true ? (
                        <input
                          className="appearance-none block w-full bg-gray-300 text-gray-900 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                          disabled
                          value={"No aplica"}
                        />
                      ) : (
                        <>
                          <select
                            {...register("jornadaId", {
                              onChange: (e) => {
                                const jornada = jornadas.find(
                                  (jornada) => jornada.id == e.target.value
                                );
                                setSueldoMinimoProporcional(
                                  jornada.sueldoMinimoProporcional
                                );
                              },
                              ...validacionCampoObligatorio,
                            })}
                            className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                          >
                            <option value="" hidden>
                              Elegir jornada
                            </option>
                            {jornadas?.map((el) => (
                              <>
                                <option value={el.id}>{el.nombre}</option>
                              </>
                            ))}
                          </select>
                          <span
                            className={`text-red-500 dark:text-red-400 text-xs italic ${
                              !errors.jornadaId && "opacity-0 invisible"
                            }`}
                          >
                            {errors.jornadaId
                              ? errors.jornadaId.message
                              : "hidden"}
                          </span>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="md:flex md:flex-wrap">
                    <div className="div-Inputs-EditarHaber md:w-4/12 md:pr-3">
                      <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                        Centro de Costo
                      </label>
                      <select
                        {...register(
                          "centroCostoId",
                          validacionCampoObligatorio
                        )}
                        className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                      >
                        <option value="" hidden>
                          Seleccionar Centro de Costo
                        </option>
                        {listaCCosto?.map((cCosto, i) => (
                          <>
                            <option key={i} value={cCosto.id}>
                              {cCosto.nombre}
                            </option>
                          </>
                        ))}
                      </select>
                  <span
                    className={`text-red-500 dark:text-red-400 text-xs italic ${
                      !errors.jornadaId && "opacity-0 invisible"
                    }`}
                  >
                    {errors.centroCostoId
                      ? errors.centroCostoId.message
                      : "hidden"}
                  </span>
                    </div>

                    <div className="div-Inputs-EditarHaber md:w-4/12 md:px-3">
                      <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                        Sección/Área
                      </label>
                      <select
                        {...register("seccionId", validacionCampoObligatorio)}
                        className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                      >
                        <option value="" hidden>
                          Seleccionar Sección
                        </option>
                        {secciones?.map((el) => (
                          <>
                            <option value={el.id}>{el.nombre}</option>
                          </>
                        ))}
                      </select>
                  <span
                    className={`text-red-500 dark:text-red-400 text-xs italic ${
                      !errors.seccionId && "opacity-0 invisible"
                    }`}
                  >
                    {errors.seccionId
                      ? errors.seccionId.message
                      : "hidden"}
                  </span>
                    </div>

                    <div className="div-Inputs-EditarHaber md:w-4/12 md:pl-3">
                      <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                        Cargo
                      </label>
                      <select
                        {...register("cargoId", validacionCampoObligatorio)}
                        className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                      >
                        <option value="" hidden>
                          Seleccionar Cargo
                        </option>
                        {cargos?.map((el) => (
                          <>
                            <option value={el.id}>{el.nombre}</option>
                          </>
                        ))}
                      </select>
                  <span
                    className={`text-red-500 dark:text-red-400 text-xs italic ${
                      !errors.cargoId && "opacity-0 invisible"
                    }`}
                  >
                    {errors.cargoId
                      ? errors.cargoId.message
                      : "hidden"}
                  </span>
                    </div>
                  </div>

                    <div className="div-Inputs-EditarHaber md:w-4/12 md:pr-3">
                      <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                        Trabajo Pesado
                      </label>
                      <select
                        {...register(
                          "trabajoPesado",
                          validacionCampoObligatorio
                        )}
                        className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                      >
                        <option value="" hidden>
                          Seleccionar Tipo de Trabajo
                        </option>
                        {trabajoPesado.map((el) => (
                          <>
                            <option value={el.id}>{el.nombre}</option>
                          </>
                        ))}
                      </select>
                      <div>
                  <span
                    className={`text-red-500 dark:text-red-400 text-xs italic ${
                      !errors.trabajoPesado && "opacity-0 invisible"
                    }`}
                  >
                    {errors.trabajoPesado
                      ? errors.trabajoPesado.message
                      : "hidden"}
                  </span>
                      </div>
                    </div>

                  <hr className="mb-6" />

                  {/* Fin del primer Formulario */}

                  {/* Empieza el formulario de la tabla */}

                  <AgregarHaberForm
                    haberesSelect={haberesLista}
                    sendFormData={(haberes) => agregarHaber(haberes)}
                  />

                  {/* Tabla de Haberes */}
                  <div className="rounded-lg">
                    <TablaHaberes
                      col={TABLA_HABERES_TRABAJADOR}
                      tableData={haberesAnexo}
                      botonEliminar={true}
                      borrarHaber={(index) => {
                        toast.loading("Eliminando Haber", estilosAlerta);
                        try {
                          haberesAnexo.splice(index, 1);
                          const arrayEditado = [...haberesAnexo];
                          setHaberesAnexo(arrayEditado);
                          const encontrarSemanaCorrida = arrayEditado.find(
                            (e) =>
                              e.haber.haberesDt.id == 4 ||
                              e.haber.haberesDt.id == "4"
                          );

                          if (encontrarSemanaCorrida) {
                            setTieneSemanaCorrida(true);
                          } else {
                            setTieneSemanaCorrida(false);
                          }
                          const encontrarGratificacion6 = arrayEditado.find(
                            (e) => e.haber.haberesDt.id == 6
                          );
                          const encontrarGratificacion22 = arrayEditado.find(
                            (e) => e.haber.haberesDt.id == 22
                          );
                          if (
                            encontrarGratificacion6 ||
                            encontrarGratificacion22
                          ) {
                            setTieneGratificaciones(true);
                          } else {
                            setValue("gratificacionId", 3);
                            setValue("gratificacionNombre", "");
                            setValue("gratificacionTexto", "");
                            setTieneGratificaciones(false);
                          }
                          toast.dismiss();
                          toast.success("Haber eliminado con éxito", estilosAlerta);
                        } catch (error) {
                          console.log("Error", error);
                          toast.dismiss();
                          toast.error("Error al eliminar Haber", estilosAlerta);
                        }
                      }}
                    />
                  </div>

                  {/* Fin de Haberes */}

                  {tieneGratificaciones && (
                  <div className="relative mt-5 flex-auto ">
                  <div className="w-full md:w-min mb-6 md:mb-0 ">
                    <label
                      className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold"
                    >
                      Gratificación
                    </label>
                  </div>
                  <div className="flex flex-wrap">

                    {watch("gratificacionId") !== "" &&
                    <div className="w-full md:w-3/12 mr-3 mb-6 md:mb-0 ">
                      <input
                        disabled
                        {...register("gratificacionNombre")}
                        className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        id="grid-rut"
                        type="text"
                      />
                    </div>
                     }

                    {watch("gratificacionId") !== "" &&
                    <div className="w-full md:w-8/12 mx-3 mb-6 md:mb-0 ">
                      <input
                        disabled
                        {...register("gratificacionTexto")}
                        className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        id="grid-rut"
                        type="text"
                      />
                    </div>
                     }
                     
                  </div>
                </div>

                )}

                </div>
              </>
              {/*footer*/}
              <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                  type="button"
                  onClick={() => {
                    onClose();
                    reset();
                  }}
                >
                  Cerrar
                </button>
                <button
                  type="submit"
                  disabled={!botonGuardarHabilitado}
                  className={` ${!botonGuardarHabilitado? "bg-gray-500" : "bg-hercules-light-primary-button hover:shadow-lg hover:scale-105"}  text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400`}
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-30 bg-black"></div>
      </form>
      {showModalGratificacionLegal && (
        <ModalAdvertenciaGratificacionLegal
          onCloseAlert={() => {
            setShowModalGratificacionLegal(false);
          }}
          onCloseAndReset={() => {
            setShowModalGratificacionLegal(false);
            onClose();
            reset();
          }}
          confirmarCambiosPayload={payloadSinGratificacion}
          tipoContratoAnexo="Contrato"
        />
      )}
    </>
  );
}
