import React, { useMemo, useState } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useFilters,
  usePagination,
  useRowSelect,
} from "react-table";
import {
  TbArrowsSort,
  TbSortAscending,
  TbSortDescending,
} from "react-icons/tb";
import GlobalFilter from "../../GlobalFilter";
import { BsDownload } from "react-icons/bs";
import { BiSearch } from "react-icons/bi";
import {
  MdArrowBackIos,
  MdArrowForwardIos,
  MdLastPage,
  MdFirstPage,
  MdOutlineCalculate,
} from "react-icons/md";
import { useStateContext } from "../../../contexts/ContextProvider";
import { axiosPrivate } from "../../../common/axiosPrivate";
import CalculoLiquidacionIndividual from "./ModalLiquidacionIndividual";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import Skeleton from "../../Skeleton";

export default function CalculoLiquidacionesTable({
  col,
  tableData,
  botonLiquidaciones,
  exportCsv,
}) {
  // eslint-disable-next-line
  const columns = useMemo(() => col, []);
  const data = useMemo(() => [...tableData], [tableData]);
  const {
    rowDataLiquidaciones,
    setRowDataLiquidaciones,
    rowDataHabDescLiquidaciones,
    setRowDataHabDescLiquidaciones,
    rowDataMovimientosTrabajador,
    setRowDataMovimientosTrabajador,
    rowDataTrabajadoresSeleccionados,
    setRowDataTrabajadoresSeleccionados,
    showModalCalculoLiquidacion,
    setShowModalCalculoLiquidacion,
    refreshTable,
    setRefreshTable,
    screenSize,
  } = useStateContext();
  const [showModalLiquidacionIndividual, setShowModalLiquidacionIndividual] =
    useState(false);
    const [botonGuardarHabilitado, setBotonGuardarHabilitado] = useState(true)

    const isMobile = screenSize <= 768;
    const estilosAlerta = {
      style: {
        marginTop: isMobile ? "80px" : "",
      },
    };  

    const empresaId = localStorage.getItem("empresaId");
  const isLoading = useSelector((state) => state.loader);
  const periodoId = localStorage.getItem("periodoId");
  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    canPreviousPage,
    canNextPage,
    gotoPage,
    pageCount,
    setPageSize,
    pageOptions,
    state,
    rows,
    selectedFlatRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              {!row.original.liquidacionCalculada && (
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              )}
            </div>
          ),
          disableGlobalFilter: true,
        },
        ...columns,
      ]);
    }
  );

  const { pageIndex, pageSize } = state;

  const { globalFilter } = state;

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
        setRowDataTrabajadoresSeleccionados(selectedFlatRows);
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      );
    }
  );

  async function handleCalcularLiquidacion(row) {
    toast.loading("Cargando", estilosAlerta);
    setRowDataLiquidaciones(row);
    try {
      const dataLiquidaciones = {
        trabajadorId: row.trabajadorId,
        centroCostoId: row.centroCostoId,
        periodoId: periodoId,
      };

      const dataMovimientos = {
        trabajadorId: row.trabajadorId,
        centroCostoId: row.centroCostoId,
        contratoId: row.contratoId,
        anexoContratoId: row.anexoContratoId ? row.anexoContratoId : null,
        periodoId: periodoId,
      };

      const infoLiquidaciones = await axiosPrivate.post(
        `${api_service}/api/v1/trabajadores/calcularLiquidacion`,
        dataLiquidaciones,
        Authorization
      );

      const infoMovimientos = await axiosPrivate.post(
        `${api_service}/api/v1/contratos/movimientosTrabajadorPeriodo`,
        dataMovimientos,
        Authorization
      );
      if (infoLiquidaciones.status === 200 && infoMovimientos.status === 200) {
        setShowModalLiquidacionIndividual(true);
        toast.dismiss();
        const dataTotal = infoLiquidaciones.data;
        const haberes = [];
        const descuentos = [];
        infoLiquidaciones.data[0].haberes.forEach((e) => {
          haberes.push(e);
        });

        infoLiquidaciones.data[0].descuentos.forEach((e) => {
          descuentos.push(e);
        });

        setRowDataHabDescLiquidaciones({
          haberes,
          descuentos,
          dataTotal,
        });
        setRowDataMovimientosTrabajador(infoMovimientos.data);
      }
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al calcular liquidación individual",
          estilosAlerta
      );
    }
  }

  async function calcularLiquidacionesSeleccionadas() {
    if (selectedFlatRows.length === 0) {
      toast.dismiss();
      toast.error("Debe seleccionar por lo menos un Trabajador", estilosAlerta);
      return null;
    }
    toast.dismiss();
    toast.loading("Calculando Liquidaciones seleccionadas", estilosAlerta);
    setBotonGuardarHabilitado(false)
    try {
      const trabajadoresIdFiltrados = selectedFlatRows.filter(
        (e) => e.original.liquidacionCalculada == false
      );
      const mapeoTrabajadores = trabajadoresIdFiltrados.map((e) => {
        return {
          trabajadorId: e.original.trabajadorId,
        };
      });
      const data = {
        periodoId: periodoId,
        trabajadores: mapeoTrabajadores,
        empresaId: empresaId
      };
      const info = await axiosPrivate.post(
        // `${api_service}/api/v1/trabajadores/calculoMasivoLiquidaciones`,
        `${api_service}/api/v1/trabajadores/liquidacion/masiva`,
        data,
        Authorization
      );
      console.log("info recibida", info);
      if (info.status === 200) {
        setRefreshTable(!refreshTable);
        toast.dismiss();
        toast.success("Liquidaciones calculadas con éxito", estilosAlerta);
      }
      setBotonGuardarHabilitado(true)
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error(error.response.data.message ? error.response.data.message : "Error al calcular Liquidaciones", estilosAlerta);
      setBotonGuardarHabilitado(true)
    }
  }

  if (isLoading) {
    return (
      <Skeleton
        width="w-full"
        height="h-fix"
        searchBar={true}
        pagination={true}
      />
    );
  }

  return (
    <>
      <div className="bg-white dark:bg-hercules-dark-bg-100 border-t  border-l border-r border-gray-300 dark:border-dark-bg-200 h-12 flex rounded-t-md p-3">
        <>
          <span className="ml-auto">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          </span>
          <span className="mx-3 pt-1 text-xl dark:text-dark-text-100 text-black hover:cursor-pointer">
            <BiSearch />
          </span>
        </>

        <span className="border-1 mt-1"></span>
        <span className="ml-3 pt-0.5 text-xl hover:cursor-pointer dark:text-white text-hercules-light-font-secondary">
          <BsDownload onClick={() => exportCsv()} />
        </span>
      </div>

      <div className="h-fix overflow-auto border-x-1  bg-white dark:bg-hercules-dark-bg-200  dark:border-dark-bg-200 border-gray-300 border-t-1">
        <table {...getTableProps()} className="w-full rounded-lg">
          <thead className="text-left sticky top-0 border-b-1 border-b-hercules-light-secondary dark:border-b-hercules-dark-secondary-button bg-hercules-light-table-header dark:bg-hercules-dark-bg-200">
            {headerGroups.map((headerGroup) => {
              const { key, ...restHeaderGroup } =
                headerGroup.getHeaderGroupProps();
              return (
                <React.Fragment key={key}>
                  <tr {...restHeaderGroup} className="h-10 flex-auto">
                    {headerGroup.headers.map((column) => {
                      const { key, ...restColumn } = column.getHeaderProps(
                        column.getSortByToggleProps()
                      );
                      return (
                        <React.Fragment key={key}>
                          <th
                            {...restColumn}
                            className="py-2 px-4 text-hercules-light-font-secondary font-medium dark:text-white"
                          >
                            <div className="flex">
                              <span className="">
                                {column.render("Header")}
                              </span>
                              <span className="ml-1 mt-auto mb-auto">
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <TbSortDescending />
                                  ) : (
                                    <TbSortAscending />
                                  )
                                ) : (
                                  <TbArrowsSort />
                                )}
                              </span>
                            </div>
                          </th>
                        </React.Fragment>
                      );
                    })}
                    {botonLiquidaciones && (
                      <th className="px-3 text-hercules-light-font-secondary font-medium dark:text-white">
                        Cálculo individual
                      </th>
                    )}
                  </tr>
                  <tr {...headerGroup.getHeaderGroupProps()} className="h-10">
                    {headerGroup.headers.map((column) => {
                      const { key, ...restColumn } = column.getHeaderProps();
                      return (
                        <th key={key} {...restColumn} className="pl-4 pb-4">
                          <div className="w-fit pr-4">
                            {column.canFilter ? column.render("Filter") : null}
                          </div>
                        </th>
                      );
                    })}
                    {botonLiquidaciones && <th></th>}
                  </tr>
                </React.Fragment>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()} className="h-full">
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <React.Fragment key={i}>
                  <tr
                    {...row.getRowProps()}
                    className="group bg-white dark:bg-hercules-dark-bg-200   dark:text-dark-text-100 dark:border-b-hercules-dark-secondary-button border-b border-hercules-light-secondary text-black h-12 dark:hover:bg-white dark:hover:bg-opacity-10 transition duration-100 ease-in-out hover:bg-light-accent hover:bg-opacity-20"
                  >
                    {row.cells.map((cell, i) => {
                      return (
                        <td
                          key={i}
                          {...cell.getCellProps}
                          className={`py-2 px-4 text-base font-normal ${
                            row.original.liquidacionCalculada == false
                              ? ""
                              : "dark:text-red-300 text-red-500 "
                          }`}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                    {botonLiquidaciones && (
                      <td className="mx-6">
                        <MdOutlineCalculate
                          className={`ml-6 text-2xl group-hover:cursor-pointer ${
                            !row.original.liquidacionCalculada
                              ? " text-green-600"
                              : "dark:text-red-300 text-red-500 "
                          }`}
                          onClick={() => {
                            handleCalcularLiquidacion(row.original)
                          }}
                        />
                      </td>
                    )}
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className=" border-1 dark:border-t-hercules-dark-secondary-button border-t-hercules-light-secondary border-gray-300 dark:border-dark-bg-200 dark:bg-hercules-dark-bg-200   rounded-b-md h-14 flex bg-white">
        <div className="ml-auto py-3 flex">
          <p className="hidden sm:block pt-1 text-black dark:text-white">
            Filas por página
          </p>
          <select
            name=""
            id=""
            className="ml-2 h-8 text-sm bg-white border  rounded-sm"
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block p-4 text-black dark:text-white">
          Página {pageIndex + 1} de {pageOptions.length}
        </div>
        <div className="flex mr-4 pl-4">
          <button
            className="bg-white text-black dark:text-white dark:bg-opacity-0 rounded-full hover:bg-gray-200 hover:text-black my-2 w-8 mr-6"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <MdFirstPage className="pl-1 text-2xl" />
          </button>
          <button
            className="bg-white text-black rounded-full dark:text-white dark:bg-opacity-0  hover:bg-gray-200 hover:text-black my-2 mr-6 w-8"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <MdArrowBackIos className="ml-2" />
          </button>
          <button
            className="bg-white text-black rounded-full dark:text-white dark:bg-opacity-0  hover:bg-gray-200 hover:text-black my-2 mr-6 w-8"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <MdArrowForwardIos className="ml-2" />
          </button>
          <button
            className="bg-white text-black rounded-full dark:text-white dark:bg-opacity-0  hover:bg-gray-200 hover:text-black my-2 w-8"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <MdLastPage className="pl-1 text-2xl" />
          </button>
        </div>
      </div>

      {showModalLiquidacionIndividual && (
          <CalculoLiquidacionIndividual
            isVisible={showModalLiquidacionIndividual}
            onClose={() =>setShowModalLiquidacionIndividual(false)}
            modalData={rowDataLiquidaciones}
            modalDataHaberesDescuentos={rowDataHabDescLiquidaciones}
            modalDataMovTrabajador={rowDataMovimientosTrabajador}
            trabajadoresSeleccionados={rowDataTrabajadoresSeleccionados}
          />
        )}
      {showModalCalculoLiquidacion && (
          <CalculoLiquidacionIndividual
            isVisible={showModalCalculoLiquidacion}
            onClose={() =>setShowModalCalculoLiquidacion(false)}
            modalData={rowDataLiquidaciones}
            modalDataHaberesDescuentos={rowDataHabDescLiquidaciones}
            modalDataMovTrabajador={rowDataMovimientosTrabajador}
            trabajadoresSeleccionados={rowDataTrabajadoresSeleccionados}
          />
        )}
      <div className="flex mt-3">
        <button
          onClick={() => calcularLiquidacionesSeleccionadas()}
          disabled={!botonGuardarHabilitado}
          className={` ${!botonGuardarHabilitado? "bg-gray-500" : "bg-hercules-light-primary-button hover:shadow-lg hover:scale-105"}  text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400`}
  >
          Calcular liquidaciones seleccionadas
        </button>
      </div>
    </>
  );
}
