import React, { useEffect, useState } from "react";
import ModalSpinner from "../../ModalSpinner";
import UtilsFunctions from "../../../utils/UtilsFunctions";
import TablaModalAsignarDescuentos from "./TablaModalAsignarDescuentos";
import ColumnFilter from "../../ColumnFilter";
import { getHistorialDescuentosFijosTrabajadorEmpresa, limpiarInfoMenuEdicion } from "../../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";

export default function ModalVerHistorico({
  modalData,
  isVisible,
  onClose,
}) {

  const loadingModal = true;
  const [botonGuardarHabilitado, setBotonGuardarHabilitado] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const empresaId = localStorage.getItem("empresaId");

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await Promise.all([dispatch(getHistorialDescuentosFijosTrabajadorEmpresa(modalData.id, empresaId))]);
      setIsLoading(false);
    };
    fetchData().catch((error) => {
      console.log(error.response.data.message);
      setIsLoading(false);
    });
  }, [dispatch, modalData]);

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("getHistorialTrabajadorDescuentosFijosEmpresa"));
    };
  }, []);

  const historial = useSelector((state) => state.getHistorialTrabajadorDescuentosFijosEmpresa);

  const COLUMNAS_DESCUENTOS = [
    {
      Header: "Descuento Fijo",
      accessor: "nombreDescuento",
      Filter: ColumnFilter,
    },
    // {
    //   Header: "Unidad",
    //   accessor: "tipoMonto",
    //   Filter: ColumnFilter,
    // },
    {
      Header: "Monto",
      accessor: "valor",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value === -1) {
          return "Variable";
        } else {
          return "$" + UtilsFunctions.integerFormatter(value);
        }
      },
    },
    {
      Header: "Periodo",
      accessor: (d) => UtilsFunctions.periodoToText(d.mesPeriodo, d.anyoPeriodo),
      Filter: ColumnFilter,
    },
  ];

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <div className="primerDivModal">
        <div className="segundoDivModal max-w-5xl">
          {/*content*/}
          <div className="tercerDivModal shadow-lg  max-h-[85vh] ">
            {/*header*/}
            <div className="divHeaderModal border-slate-200">
              <h3 className="titulo-Modal">Historial Descuentos</h3>
            </div>
            {/*body*/}
            <div
              className={`relative p-6 ${
                !loadingModal ? "overflow-hidden" : "overflow-auto"
              } flex-auto dark:bg-hercules-dark-bg-200`}
            >
              {!loadingModal ? (
                <div className="h-fix">
                  <ModalSpinner />
                </div>
              ) : (
                <>
                  <div className="md:flex md:flex-wrap">
                    <div className="w-full md:w-3/12 md:pr-3 md:mb-11 mb-9">
                      <label
                        className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                        htmlFor="grid-first-name"
                      >
                        RUT
                      </label>
                      <input
                        disabled
                        value={UtilsFunctions.rutFormatter(
                          modalData.rut,
                          { dots: true }
                        )}
                        className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                      />
                    </div>

                    <div className="w-full md:w-5/12 md:px-3 md:mb-11 mb-9">
                      <label
                        className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                        htmlFor="grid-first-name"
                      >
                        Nombres
                      </label>
                      <input
                        disabled
                        value={modalData.nombres + " " + modalData.apellido_Paterno + " " + modalData.apellido_Materno}
                        className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="md:flex">
                    <div className="h-full w-full rounded-lg md:pr-3 mb-9">
                      <TablaModalAsignarDescuentos
                      col={COLUMNAS_DESCUENTOS}
                      tableData={historial}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            {/*footer*/}
            <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                type="button"
                onClick={() => {
                  onClose();
                }}
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
