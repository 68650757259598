import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { axiosPrivate } from "../../../common/axiosPrivate";
import { useStateContext } from "../../../contexts/ContextProvider";

export default function ModalMotivoEliminarDescuento({
  isVisible,
  onClose,
  modalData
}) {
  // console.log("modalData", modalData);
  const { refreshTable, setRefreshTable, screenSize } = useStateContext();

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const userId = localStorage.getItem("userId");
  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  
  useEffect(() => {
    reset(modalData);
  }, [modalData]);

  const validacionTextInput = {
    required: {value: true, message: "Obligatorio"},
    minLength: {value: 5, message: "Debe tener 5 caracteres como mínimo"}
  }

  const { register, handleSubmit, reset, formState: {errors} } = useForm({
    defaultValues: modalData,
  });

  const onSubmit = async (data) => {
    try {
      toast.loading("Eliminando Descuento en cuotas", estilosAlerta);
      const response = await axiosPrivate.delete(
        `${api_service}/api/v1/descuentos/cancelar-descuento-programado`,
        {
          data: {
            descuentoProgramadoId: modalData.id,
            usuarioId: userId,
            observacion: data.motivo    
               },
          headers: {
            Authorization: `Bearer ${tokenUser}`, ClientId : `${token}`,
          },
        },
      );
      if (response.status === 200) {
        toast.dismiss();
        toast.success("Descuento en cuotas eliminado con éxito", estilosAlerta);
        setRefreshTable(!refreshTable);
        onClose();
        reset();
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.response.data.message ? error.response.data.message : "Error al eliminar Descuento en cuotas" , estilosAlerta);
      console.log(error);
    }
  };

  if (!isVisible) {
    return null;
  }


  return (
    <>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-screen my-6 mx-auto max-w-lg">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col max-h-[85vh] overflow-y-auto w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t dark:bg-hercules-dark-bg-100 ">
              <h3 className="text-3xl text-hercules-light-font-secondary font-semibold leading-normal dark:text-white">
                Motivo
              </h3>
            </div>
            {/*body*/}
            <div  className="relative p-6 flex-auto overflow-auto dark:bg-hercules-dark-bg-200">
                  {/* Primera fila */}
                  <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full px-3">
                      <label
                        className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Indique el motivo por el cual elimina este descuento
                      </label>
                      <textarea
                        {...register("motivo", validacionTextInput)}
                        rows="5"
                        className=" resize-none appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                      {errors.motivo && <p className="text-red-600 text-xs">{errors.motivo.message}</p>}
                    </div>
                  </div>
              </div>
            {/*footer*/}
            <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                type="button"
                onClick={() => {
                  onClose();
                  reset();
                }}
              >
                Cerrar
              </button>
              <button
                className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
                type="submit"
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </form>
    </>
     );
}
