import React, {createContext, useContext, useState} from "react";

const StateContext = createContext();

const initialState = {
    notification: false,
    userProfile: false,
}

export const ContextProvider = ({children}) => {
    const [activeMenu, setActiveMenu] = useState(true);
    const [isClicked, setIsClicked] = useState(initialState);
    const [screenSize, setScreenSize] = useState(undefined);
    const [rowData, setRowData] = useState(undefined);
    const [rowDataContratos, setRowDataContratos] = useState(undefined);
    const [rowDataAnexo, setRowDataAnexo] = useState(undefined);
    const [verAnexoContrato, setVerAnexoContrato] = useState(false);
    const [rowDataHaberes, setRowDataHaberes] = useState(undefined);
    const [rowDataHorasExtra, setRowDataHorasExtra] = useState(undefined);
    const [rowDataJornadas, setRowDataJornadas] = useState(undefined);
    const [rowDataUsuarios, setRowDataUsuarios] = useState(undefined);
    const [rowDataCentroCosto, setRowDataCentroCosto] = useState(undefined);
    const [rowDataSeccionArea, setRowDataSeccionArea] = useState(undefined);
    const [rowDataCargos, setRowDataCargos] = useState(undefined);
    const [rowDataSindicato, setRowDataSindicato] = useState(false);
    const [rowDataMovimientosTrabajador, setRowDataMovimientosTrabajador] = useState(false);
    const [movimientosActualesTrabajador, setMovimientosActualesTrabajador] = useState(undefined)
    const [rowDataMovimientosTrabajadorHExtraHDescuento, setRowDataMovimientosTrabajadorHExtraHDescuento] = useState(false);
    const [rowDataHsExtraHsDescuentoYDomingos, setRowDataHsExtraHsDescuentoYDomingos] = useState(false);
    const [rowDataHaberesDescuentos, setRowDataHaberesDescuentos] = useState(undefined);
    const [rowDataLiquidaciones, setRowDataLiquidaciones] = useState(false);
    const [rowDataHabDescLiquidaciones, setRowDataHabDescLiquidaciones] = useState(false);
    const [rowDataEmpresa, setRowDataEmpresa] = useState(false);
    const [rowDataCCAF, setRowDataCCAF] = useState(false);
    const [rowDataMutual, setRowDataMutual] = useState(false);
    const [rowDataTrabajadoresSeleccionados, setRowDataTrabajadoresSeleccionados] = useState(false);
    // const [rowDataModalSemCorrida, setRowDataModalSemCorrida] = useState(undefined);
    const [modalDataEliminarDescuento, setModalDataEliminarDescuento] = useState(undefined)
    const [modalDataLiquidarDescuento, setModalDataLiquidarDescuento] = useState(undefined)
    const [showModalCalculoLiquidacion, setShowModalCalculoLiquidacion] = useState(false)
    const [modalDataFiniquito, setModalDataFiniquito] = useState(undefined);
    const [modalDataVerFiniquito, setModalDataVerFiniquito] = useState(undefined);
    const [modalVerCambioEmpresa, setModalVerCambioEmpresa] = useState(undefined);
    const [rowDataGestionarVacaciones, setRowDataGestionarVacaciones] = useState(undefined);
    const [contratoId, setContratoId] = useState(undefined);
    const [openNotifications, setOpenNotifications] = useState(false)
    const [refreshTable, setRefreshTable] = useState(false);
    const [userMenu, setUserMenu] = useState([]);
    const [userProfileIsOpen, setUserProfileIsOpen] = useState(false);


    const handleClick = (clicked) => setIsClicked({ ...initialState, [clicked]: !initialState[clicked] });

    return (
        <StateContext.Provider value={{
            activeMenu,
            setActiveMenu,
            isClicked,
            setIsClicked,
            handleClick,
            screenSize,
            setScreenSize,
            rowData,
            setRowData,
            rowDataContratos,
            setRowDataContratos,
            rowDataAnexo,
            setRowDataAnexo,
            verAnexoContrato,
            setVerAnexoContrato,
            rowDataHaberes,
            setRowDataHaberes,
            rowDataHorasExtra,
            setRowDataHorasExtra,
            rowDataJornadas,
            setRowDataJornadas,
            rowDataUsuarios,
            setRowDataUsuarios,
            rowDataCentroCosto,
            setRowDataCentroCosto,
            rowDataSeccionArea,
            setRowDataSeccionArea,
            rowDataCargos,
            setRowDataCargos,
            rowDataSindicato,
            setRowDataSindicato,
            rowDataMovimientosTrabajador,
            setRowDataMovimientosTrabajador,
            movimientosActualesTrabajador,
            setMovimientosActualesTrabajador,
            rowDataMovimientosTrabajadorHExtraHDescuento,
            setRowDataMovimientosTrabajadorHExtraHDescuento,
            rowDataHsExtraHsDescuentoYDomingos,
            setRowDataHsExtraHsDescuentoYDomingos,
            rowDataHaberesDescuentos, 
            setRowDataHaberesDescuentos,
            rowDataLiquidaciones,
            setRowDataLiquidaciones,
            rowDataHabDescLiquidaciones,
            setRowDataHabDescLiquidaciones,
            rowDataEmpresa,
            setRowDataEmpresa,
            rowDataCCAF,
            setRowDataCCAF,
            rowDataMutual,
            setRowDataMutual,
            rowDataTrabajadoresSeleccionados,
            setRowDataTrabajadoresSeleccionados,
            // rowDataModalSemCorrida,
            // setRowDataModalSemCorrida,
            modalDataEliminarDescuento,
            setModalDataEliminarDescuento,
            modalDataLiquidarDescuento,
            setModalDataLiquidarDescuento,
            showModalCalculoLiquidacion,
            setShowModalCalculoLiquidacion,
            modalDataFiniquito,
            setModalDataFiniquito,
            modalDataVerFiniquito,
            setModalDataVerFiniquito,
            modalVerCambioEmpresa,
            setModalVerCambioEmpresa,
            rowDataGestionarVacaciones,
            setRowDataGestionarVacaciones,
            contratoId,
            setContratoId,
            openNotifications,
            setOpenNotifications,
            refreshTable,
            setRefreshTable,
            userMenu,
            setUserMenu,
            userProfileIsOpen,
            setUserProfileIsOpen
        }}>
            {children}
        </StateContext.Provider>
    )
}

export const useStateContext = () => useContext(StateContext);