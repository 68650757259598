import React, { useState, useEffect } from "react";
import { ColumnFilter } from "../../components";
import RutColumnFilter from "../../components/RutColumnFilter";
import UtilsFunctions from "../../utils/UtilsFunctions";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import { useStateContext } from "../../contexts/ContextProvider";
import SimpleTable from "../../components/SimpleTable";
import { axiosPrivate } from "../../common/axiosPrivate";
import TablaExportarHabDescMasivos from "../../components/Movimientos/ImportarHaberesDescuentosMasivos/TablaExportarHabDescMasivos";
import { useDispatch, useSelector } from "react-redux";
import {
  getDescuentos,
  getHaberes,
  hideLoader,
  limpiarInfoMenuEdicion,
  listaTrabajadoresParaCambioJornada,
  obtenerContratosSemanaCorridaPorEmpresa,
  showLoader,
} from "../../Redux/Actions";
import TablaHaberesDescuentosTrabajador from "../../components/Movimientos/MovimientosHabDescTrab.jsx/HaberesTrabTable";
import { parse } from "papaparse";
import ModalErrorImportarHaberes from "../../components/Movimientos/ImportarHaberesDescuentosMasivos/NuevoModalErrorImportarHaberes";
import ModalErrorImportarDescuentos from "../../components/Movimientos/ImportarHaberesDescuentosMasivos/NuevoModalErrorImportarDescuentos";
import GuiaImportacionPlantillas from "../../components/Movimientos/GuiaImportacionPlantillas";
import ColumnFilterOptions from "../../components/ColumnFilterOptions";

export default function MovimientosHaberesDescuentos() {
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const periodoId = localStorage.getItem("periodoId");
  const mes = localStorage.getItem("periodoMes");
  const anyo = localStorage.getItem("periodoAnyo");

  const empresaId = localStorage.getItem("empresaId");

  const validacion = {
    required: { value: true, message: "Obligatorio" },
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader());
      await Promise.all([
        dispatch(listaTrabajadoresParaCambioJornada(periodoId, empresaId)),
        dispatch(
          obtenerContratosSemanaCorridaPorEmpresa(
            periodoId,
            mes,
            anyo,
            empresaId
          )
        ),
        dispatch(getHaberes()),
        dispatch(getDescuentos()),
      ]);
      dispatch(hideLoader());
    };

    fetchData().catch((error) => {
      console.log(error.response.data.message);
      dispatch(hideLoader());
    });
  }, [dispatch]);

  const listaHaberes = useSelector((state) => state.getHaberes);
  const listaDescuentos = useSelector((state) => state.getDescuentos);

  const [errorRecibidoHaberes, setErrorRecibidoHaberes] = useState([]);
  const [errorRecibidoDescuentos, setErrorRecibidoDescuentos] = useState([]);
  const [showModalErrorHaberes, setShowModalErrorHaberes] = useState(false);
  const [showModalErrorDescuentos, setShowModalErrorDescuentos] =
    useState(false);
  const [showModalGuiaImportacion, setShowModalGuiaImportacion] =
    useState(false);
  const { activeMenu, screenSize } = useStateContext();
  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const [arrayHaberesImportados, setArrayHaberesImportados] = useState([]);
  const [arrayDescuentosImportados, setArrayDescuentosImportados] = useState(
    []
  );

  // Estado para tabla de Descargar Plantilla
  const listaMovimientosTrabPeriodo = useSelector(
    (state) => state.trabajadoresCambioJornada
  );

  // Estado para tabla de Mov. de Haberes y Descuentos
  const infoContratosMovTrab = useSelector(
    (state) => state.contratosSemCorridaPorEmpresa
  );

  function importarHaberes(array) {
    toast.dismiss();
    toast.loading("Editando", estilosAlerta);
    const arrayValoresActualizados = [];
    try {
      // Validar que las columnas requeridas estén presentes
      const headersRequeridos = [
        "RUT",
        "Trabajador",
        "ID Haber",
        "Haber",
        "Monto",
      ];
      UtilsFunctions.validarColumnasPlantilla(
        array,
        headersRequeridos,
        "plantilla de haberes"
      );

      array.map((e) => {
        arrayValoresActualizados.push({
          RUT: e["RUT"],
          Trabajador: e["Trabajador"],
          "ID Haber": e["ID Haber"],
          Haber: e["Haber"],
          Monto: e["Monto"],
          Version: e["Version"],
        });

        setArrayHaberesImportados(arrayValoresActualizados);
        toast.dismiss();
        toast.success("Datos importados con éxito", estilosAlerta);
      });
    } catch (error) {
      console.log("error", error);
      toast.dismiss();
      toast.error(error.message);
    }
  }

  function importarDescuentos(array) {
    toast.dismiss();
    toast.loading("Editando", estilosAlerta);
    const arrayValoresActualizados = [];
    try {
      // Validar que las columnas requeridas estén presentes
      const headersRequeridos = [
        "RUT",
        "Trabajador",
        "ID Descuento",
        "Descuento",
        "Monto",
      ];
      UtilsFunctions.validarColumnasPlantilla(
        array,
        headersRequeridos,
        "plantilla de descuentos"
      );

      array.map((e) => {
        arrayValoresActualizados.push({
          RUT: e["RUT"],
          Trabajador: e["Trabajador"],
          "ID Descuento": e["ID Descuento"],
          Descuento: e["Descuento"],
          Monto: e["Monto"],
          Version: e["Version"],
        });

        setArrayDescuentosImportados(arrayValoresActualizados);
        toast.dismiss();
        toast.success("Datos importados con éxito", estilosAlerta);
      });
    } catch (error) {
      console.log("error", error);
      toast.dismiss();
      toast.error(error.message);
    }
  }

  const csvToJson2 = (csv) => {
    parse(csv, {
      delimiter: ";",
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        if (watch("tipoHaberDescuento") === "haber") {
          importarHaberes(result.data);
        } else if (watch("tipoHaberDescuento") === "descuento") {
          importarDescuentos(result.data);
        } else {
          toast.dismiss();
          toast.error("Error en la importación");
        }
      },
    });
  };

  const fileReader = new FileReader();

  const [file, setFile] = useState();

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("trabajadoresCambioJornada"));
      dispatch(limpiarInfoMenuEdicion("contratosSemCorridaPorEmpresa"));
    };
  }, []);

  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  async function importarHabDesc() {
    if (!file) {
      toast.dismiss();
      toast.error("Primero debe subir una plantilla", estilosAlerta);
      return null;
    }
    if (watch("tipoHaberDescuento") === "") {
      toast.dismiss();
      toast.error(
        "Seleccione si importa una plantilla de Haberes o Descuentos"
      );
      return null;
    }

    try {
      toast.loading("Importando datos", estilosAlerta);
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvToJson2(text);
      };

      fileReader.readAsText(file);
    } catch (error) {
      toast.dismiss();
      toast.error(
        "Error al importar datos, por favor revise su plantilla",
        estilosAlerta
      );
      console.log("error", error);
    }
  }

  const COL_MOV_HAB_DESC = [
    {
      Header: "RUT",
      accessor: "rut",
      Cell: ({ value }) => UtilsFunctions.rutFormatter(value, { dots: true }),
      Filter: RutColumnFilter,
    },
    {
      Header: "Trabajador",
      accessor: (d) =>
        d.apellidoPaterno + " " + d.apellidoMaterno + " " + d.nombres,
      Filter: ColumnFilter,
    },
    {
      Header: "Centro Costo",
      accessor: "nombreCentroCosto",
      Filter: ColumnFilter,
    },
    {
      Header: "N° Contrato",
      accessor: "version",
      Filter: ColumnFilter,
    },
    {
      Header: "Días hab. p. sem. corrida",
      accessor: "diasHabiles",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value === null) {
          return "0";
        } else {
          return value;
        }
      },
    },
    {
      Header: "Feriados p. sem. corrida",
      accessor: "diasFeriados",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value === null) {
          return "0";
        } else {
          return value;
        }
      },
    },
    {
      Header: "Semana Corrida",
      accessor: "semanaCorrida",
      Filter: ColumnFilterOptions,
      Cell: ({ value }) => {
        if (value === true) {
          return "Si";
        } else {
          return "No";
        }
      },
    },
  ];

  const COL_EXPORTAR_PLANTILLA_MOV_HAB_DESC = [
    {
      Header: "RUT",
      accessor: "rutTrabajador",
      Cell: ({ value }) => UtilsFunctions.rutFormatter(value, { dots: true }),
      Filter: RutColumnFilter,
    },
    {
      Header: "Trabajador",
      accessor: (d) =>
        d.apellidoPaterno + " " + d.apellidoMaterno + " " + d.nombres,
      Filter: ColumnFilter,
    },
    {
      Header: "Centro Costo",
      accessor: "centroCostoNombre",
      Filter: ColumnFilter,
    },
    {
      Header: "Sección",
      accessor: "seccionNombre",
      Filter: ColumnFilter,
    },
    {
      Header: "Cargo",
      accessor: "cargoNombre",
      Filter: ColumnFilter,
    },
  ];

  const headersCsvMovHabDesc = [
    { name: "RUT", key: "rut" },
    { name: "Apellido paterno", key: "apellidoPaterno" },
    { name: "Apellido materno", key: "apellidoMaterno" },
    { name: "Nombre", key: "nombres" },
    { name: "Centro Costo", key: "nombreCentroCosto" },
    { name: "Numero Contrato", key: "version" },
    { name: "Dias habiles para semana corrida", key: "diasHabiles" },
    { name: "Feriados para semana corrida", key: "diasFeriados" },
    { name: "Semana Corrida", key: "semanaCorrida" },
  ];

  async function guardarCargaMasivaHaberes() {
    const trabajadoresHaberesMonto = arrayHaberesImportados.map((e) => {
      return {
        rutTrabajador: e["RUT"].replaceAll(".", ""),
        haberId: e["ID Haber"],
        monto: e.Monto,
      };
    });

    try {
      toast.loading("Guardando Haberes");
      const data = {
        trabajadoresHaberesMonto: trabajadoresHaberesMonto,
        periodoId: periodoId,
      };
      const info = await axiosPrivate.post(
        `${api_service}/api/v1/trabajadores/haberes/carga`,
        data,
        Authorization
      );
      setErrorRecibidoHaberes(info.data);
      if (info.data.length > 0) {
        setShowModalErrorHaberes(true);
        toast.dismiss();
        toast.error(
          "Hay " + info.data.length + " Haberes que presentan inconvenientes"
        );
      } else {
        toast.dismiss();
        toast.success("Haberes cargados");
      }
    } catch (error) {
      toast.dismiss();
      toast.error(
        "Hubo un error al guardar Haberes, por favor revise que los datos sean correctos"
      );
    }
  }

  async function guardarCargaMasivaDescuentos() {
    const trabajadoresDescuentoMonto = arrayDescuentosImportados.map((e) => {
      return {
        rutTrabajador: e["RUT"].replaceAll(".", ""),
        descuentoId: e["ID Descuento"],
        monto: e.Monto,
      };
    });
    try {
      toast.loading("Guardando Descuentos");
      const data = {
        trabajadoresDescuentosMonto: trabajadoresDescuentoMonto,
        periodoId: periodoId,
      };
      const info = await axiosPrivate.post(
        `${api_service}/api/v1/trabajadores/descuentos/carga`,
        data,
        Authorization
      );
      setErrorRecibidoDescuentos(info.data);
      if (info.data.length > 0) {
        setShowModalErrorDescuentos(true);
        toast.dismiss();
        toast.error(
          "Hay " + info.data.length + " Descuentos que presentan inconvenientes"
        );
      } else {
        toast.dismiss();
        toast.success("Descuentos cargados");
      }
    } catch (error) {
      toast.dismiss();
      toast.error(
        "Hubo un error al guardar los Descuentos, por favor revise que los datos sean correctos"
      );
    }
  }

  const COLUMNAS_HABERES = [
    {
      Header: "RUT",
      accessor: "RUT",
      Cell: ({ value }) => UtilsFunctions.rutFormatter(value, { dots: true }),
      Filter: RutColumnFilter,
    },
    {
      Header: "Haber ID",
      accessor: "ID Haber",
      Filter: ColumnFilter,
    },
    {
      Header: "Haber",
      accessor: "Haber",
      Filter: ColumnFilter,
    },
    {
      Header: "Monto",
      accessor: (d) => "$" + UtilsFunctions.integerFormatter(d.Monto),
      Filter: ColumnFilter,
    },
  ];

  const COLUMNAS_DESCUENTOS = [
    {
      Header: "RUT",
      accessor: "RUT",
      Cell: ({ value }) => UtilsFunctions.rutFormatter(value, { dots: true }),
      Filter: RutColumnFilter,
    },
    {
      Header: "Trabajador",
      accessor: "Trabajador",
      Filter: ColumnFilter,
    },
    {
      Header: "Descuento ID",
      accessor: "ID Descuento",
      Filter: ColumnFilter,
    },
    {
      Header: "Descuento",
      accessor: "Descuento",
      Filter: ColumnFilter,
    },
    {
      Header: "Monto",
      accessor: (d) => "$" + UtilsFunctions.integerFormatter(d.Monto),
      Filter: ColumnFilter,
    },
  ];

  const texto = (
    <>
      <p className="pb-3">
        Para exportar esta plantilla es necesario seleccionar al menos un
        trabajador, pero se puede editar manualmente y subir los trabajadores
        que desee, mientras corresponda el formato. Esta plantilla tiene las
        siguientes columnas, por favor verifique que los datos ingresados tengan
        el formato requerido:
      </p>
      <ul>
        <li className="py-2">
          <b>Correlativo: </b>Número entero de 4 dígitos. Rellenar con ceros a
          la izquierda si es necesario.
        </li> 
        <li className="py-2">
          <b>* RUT: </b>En formato nn.nnn.nnn-d, vendrá por defecto cuando se selecciona al trabajador (OBLIGATORIO).
        </li>
        <li className="py-2">
          <b>* Trabajador: </b>Nombre del trabajador. Vendrá por defecto cuando se selecciona al trabajador.
        </li>
        <li className="py-2">
          <b>* ID Haber/Descuento: </b> Número natural. Se puede revisar cual es la id de un haber o descuento en “Edición” `{'>'}` “Haberes” o “Descuentos”, y la correspondiente columna de ID (OBLIGATORIO).
        </li>
        <li className="py-2">
          <b>* Haber o Descuento: </b>Nombre del haber o descuento. Este campo no se valida; se valida únicamente el ID que corresponde a este texto.
        </li>
        <li className="py-2">
          <b>* Monto: </b>En pesos. Número natural (OBLIGATORIO).
        </li>
      </ul>
    </>
  );

  return (
    <div
      className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
        activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">Movimientos</div>
      <div className="titulo-Pagina">Movimientos de Haberes/Descuentos</div>

      <div>
        <p className="mt-3 dark:text-white text-hercules-light-font-secondary">
          Ante cualquier duda o conflicto al importar plantillas, le
          recomendamos revisar{" "}
          <u>
            <button
              onClick={() => setShowModalGuiaImportacion(true)}
              className="hover:cursor-pointer font-medium underline"
            >
              esta guia
            </button>
          </u>
        </p>
      </div>

      <div className="rounded-lg pt-5 ">
        <div className="flex max-w-2xl">
          {/* Boton para ver movimientos */}
          <button
            className={` w-7/12 rounded-t text-center py-3 md:font-semibold text-xs md:text-sm lg:text-base dark:border-x dark:border-dark-bg-200 ${
              activeTab === 1
                ? "border-t border-x bg-white dark:bg-hercules-dark-bg-100 dark:text-gray-50 text-hercules-light-font-secondary border-gray-300 dark:border-dark-bg-200"
                : "bg-gray-600 text-white opacity-70"
            }`}
            onClick={() => handleTabClick(1)}
          >
            Mov. de Haberes y Descuentos
          </button>

          {/* Boton para importar plantilla */}
          <button
            className={` w-5/12 rounded-t text-center py-3 ml-1 md:font-semibold text-xs md:text-sm lg:text-base ${
              activeTab === 2
                ? "border-t border-x bg-white dark:bg-hercules-dark-bg-100 dark:text-gray-50 text-hercules-light-font-secondary border-gray-300 dark:border-dark-bg-200"
                : "bg-gray-600 text-white opacity-70"
            }`}
            onClick={() => handleTabClick(2)}
          >
            Importar Plantilla
          </button>

          {/* Boton para descargar plantilla */}
          <button
            className={` w-5/12 rounded-t text-center py-3 ml-1 md:font-semibold text-xs md:text-sm lg:text-base ${
              activeTab === 3
                ? "border-t border-x  bg-white dark:bg-hercules-dark-bg-100 dark:text-gray-50 text-hercules-light-font-secondary border-gray-300 dark:border-dark-bg-200"
                : "bg-gray-600 text-white opacity-70"
            }`}
            onClick={() => handleTabClick(3)}
          >
            Descargar Plantilla
          </button>
        </div>

        <div>
          <div className={`${activeTab === 1 ? "block " : "hidden"} `}>
            <div className="h-full rounded-b-lg md:mr-12">
              <TablaHaberesDescuentosTrabajador
                col={COL_MOV_HAB_DESC}
                tableData={infoContratosMovTrab || []}
                botonRevisar={true}
                exportCsv={() =>
                  UtilsFunctions.jsonToCSV3(
                    infoContratosMovTrab,
                    headersCsvMovHabDesc,
                    "Movimientos de Haberes y Descuentos"
                  )
                }
              />
            </div>
          </div>

          <div className={`${activeTab === 2 ? "block " : "hidden"} `}>
            {/* Ya importo plantilla de Haberes */}
            {arrayHaberesImportados.length ? (
              <div className="h-full md:mr-12 rounded-lg ">
                <SimpleTable
                  col={COLUMNAS_HABERES}
                  tableData={arrayHaberesImportados}
                  exportCsvButton={false}
                  borders={false}
                />

                <div className="mt-3 flex">
                  <div className="w-full md:w-52 mb-6 md:mb-0">
                    <button
                      onClick={() => setArrayHaberesImportados([])}
                      className="w-full hover:scale-105 bg-hercules-dark-primary-button text-white font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
                    >
                      Limpiar tabla
                    </button>
                  </div>

                  <div className="w-full pl-3">
                    <button
                      onClick={() => guardarCargaMasivaHaberes()}
                      className="mr-1 mb-1 hover:scale-105 bg-hercules-dark-primary-button text-white font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none ease-in-out transition-all duration-400"
                    >
                      Guardar
                    </button>
                  </div>
                </div>
              </div>
            ) : // Ya importo plantilla de Descuentos
            arrayDescuentosImportados.length ? (
              <div className="h-full md:mr-12 rounded-lg">
                <SimpleTable
                  col={COLUMNAS_DESCUENTOS}
                  tableData={arrayDescuentosImportados}
                  exportCsvButton={false}
                  borders={false}
                />

                <div className="mt-3 flex">
                  <div className="w-full md:w-52 mb-6 md:mb-0">
                    <button
                      onClick={() => setArrayDescuentosImportados([])}
                      className="w-full hover:scale-105 bg-hercules-dark-primary-button text-white font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
                    >
                      Limpiar tabla
                    </button>
                  </div>

                  <div className="w-full pl-3">
                    <button
                      onClick={() => guardarCargaMasivaDescuentos()}
                      className="mr-1 mb-1 hover:scale-105 bg-hercules-dark-primary-button text-white font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none ease-in-out transition-all duration-400"
                    >
                      Guardar
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              // Aun no importa plantilla
              <div>
                <div className="h-608 bg-white dark:bg-hercules-dark-bg-100 border-gray-300 dark:border-dark-bg-200 md:mr-12 rounded-b-md rounded-tr-md border-y border-x">
                  <h4 className="text-xl md:mr-20 md:ml-11 md:pt-11 md:pb-20 dark:text-gray-50 text-hercules-light-font-secondary">
                    Aún no ha importado una plantilla de Haberes/Descuentos o
                    puede que haya intentado cargar una plantilla con errores.
                    Por favor cargue una plantilla para luego importarla.
                  </h4>
                </div>

                {/* Selector de tipo plantilla */}
                <form onSubmit={handleSubmit(importarHabDesc)}>
                  <div className="md:flex md:flex-wrap pt-4 md:pt-5">
                    <div className="w-full relative md:w-2/12 md:mr-3 mb-6 md:mb-0">
                      <label className=" dark:text-white text-hercules-light-font-secondary">
                        Plantilla
                      </label>
                      <select
                        {...register("tipoHaberDescuento", {
                          onChange: (e) => {
                            setValue("haberId", "");
                            setValue("descuentoId", "");
                          },
                          ...validacion,
                        })}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      >
                        <option value="" hidden>
                          Seleccionar
                        </option>
                        <option value="haber">Haber</option>
                        <option value="descuento">Descuento</option>
                      </select>
                      <span
                        className={`dark:text-red-400 text-red-600 italic text-xs ${
                          !errors.tipoHaberDescuento && "opacity-0 invisible"
                        }`}
                      >
                        {errors.tipoHaberDescuento ? "Obligatorio" : "hidden"}
                      </span>
                    </div>

                    <div className="md:w-4/12 pt-5 md:pt-0 md:mx-3">
                      <label className="dark:text-white text-hercules-light-font-secondary">
                        Cargar archivo:
                      </label>

                      <input
                        // {...register("plantillaCargada", validacion)}
                        type={"file"}
                        id={"xlsxFileInput"}
                        accept={".csv"}
                        onChange={handleOnChange}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-2.5 px-4 leading-tight focus:outline-none focus:bg-white"
                      />
                      {/* <span
                    className={`dark:text-red-400 text-red-600 italic text-xs ${
                    file ? "opacity-0 invisible" : "hidden"
                    }`}
                  >
                    {!file && "Obligatorio"}
                  </span> */}
                    </div>

                    <div className="w-full pt-6 md:w-2/12 md:ml-3 mb-6 md:mb-0">
                      <button
                        type="submit"
                        className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
                      >
                        Importar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>

          <div className={`${activeTab === 3 ? "block " : "hidden"} `}>
            <div className="h-full rounded-b-lg md:mr-12">
              <TablaExportarHabDescMasivos
                col={COL_EXPORTAR_PLANTILLA_MOV_HAB_DESC}
                tableData={listaMovimientosTrabPeriodo || []}
                listaHaberes={listaHaberes}
                listaDescuentos={listaDescuentos}
              />
            </div>
          </div>
        </div>
      </div>

      {showModalErrorHaberes && (
        <ModalErrorImportarHaberes
          modalData={errorRecibidoHaberes}
          isVisible={showModalErrorHaberes}
          onClose={() => setShowModalErrorHaberes(false)}
        />
      )}
      {showModalErrorDescuentos && (
        <ModalErrorImportarDescuentos
          modalData={errorRecibidoDescuentos}
          isVisible={showModalErrorDescuentos}
          onClose={() => setShowModalErrorDescuentos(false)}
        />
      )}

      {showModalGuiaImportacion && (
        <GuiaImportacionPlantillas
          nombrePlantilla="Movimientos de Haberes/Descuentos"
          body={texto}
          // imagen={ImagenEjemploCCAF}
          isVisible={showModalGuiaImportacion}
          onClose={() => setShowModalGuiaImportacion(false)}
        />
      )}
    </div>
  );
}
