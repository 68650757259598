import React, { useMemo, useState } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useFilters,
  usePagination,
  useRowSelect,
} from "react-table";
import {
  TbArrowsSort,
  TbSortAscending,
  TbSortDescending,
} from "react-icons/tb";
import { BsDownload } from "react-icons/bs";
import { BiSearch } from "react-icons/bi";
import {
  MdArrowBackIos,
  MdArrowForwardIos,
  MdLastPage,
  MdFirstPage,
  MdCheck,
  MdClose,
  MdDoDisturb,
} from "react-icons/md";
import { useStateContext } from "../../../../contexts/ContextProvider";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { GlobalFilter } from "../../../../components";
import Skeleton from "../../../../components/Skeleton";
import ModalRechazarVacaciones from "./ModalRechazarVacaciones";
import ModalAnularVacaciones from "./ModalAnularVacaciones";

export default function TableGestionarVacaciones({
  col,
  tableData,
  exportCsv,
  checkButton,
  rechazarButton,
  anularButton,
}) {
  // eslint-disable-next-line
  const columns = useMemo(() => col, []);
  const data = useMemo(() => [...tableData], [tableData]);
  const [showModalRechazar, setShowModalRechazar] = useState(false);
  const [showModalAnular, setShowModalAnular] = useState(false);

  const { screenSize } = useStateContext();
  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const isLoading = useSelector((state) => state.loader);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    canPreviousPage,
    canNextPage,
    gotoPage,
    pageCount,
    setPageSize,
    pageOptions,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const { pageIndex, pageSize } = state;

  const { globalFilter } = state;

  if (isLoading) {
    return (
      <Skeleton
        width="w-full"
        height="h-fix"
        searchBar={true}
        pagination={true}
      />
    );
  }

  return (
    <>
      <div className="bg-white dark:bg-hercules-dark-bg-100 border-t  border-l border-r border-gray-300 dark:border-dark-bg-200 h-12 flex rounded-t-md p-3">
        <>
          <span className="ml-auto">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          </span>
          <span className="mx-3 pt-1 text-xl dark:text-dark-text-100 text-black hover:cursor-pointer">
            <BiSearch />
          </span>
        </>

        <span className="border-1 mt-1"></span>
        <span className="ml-3 pt-0.5 text-xl hover:cursor-pointer dark:text-white text-hercules-light-font-secondary">
          <BsDownload onClick={() => exportCsv()} />
        </span>
      </div>

      <div className="h-fix overflow-auto border-x-1  bg-white dark:bg-hercules-dark-bg-200  dark:border-dark-bg-200 border-gray-300 border-t-1">
        <table {...getTableProps()} className="w-full rounded-lg">
          <thead className="text-left sticky top-0 border-b-1 border-b-hercules-light-secondary dark:border-b-hercules-dark-secondary-button bg-hercules-light-table-header dark:bg-hercules-dark-bg-200">
            {headerGroups.map((headerGroup, i) => {
              const { key, ...restHeaderGroup } =
                headerGroup.getHeaderGroupProps();
              return (
                <React.Fragment key={i}>
                  <tr {...restHeaderGroup} className="h-10 flex-auto">
                    {headerGroup.headers.map((column, i) => {
                      const { key, ...restColumn } = column.getHeaderProps(
                        column.getSortByToggleProps()
                      );
                      return (
                        <React.Fragment key={i}>
                          <th
                            {...restColumn}
                            className={`py-2 px-4 text-hercules-light-font-secondary font-medium dark:text-white ${
                              column.columns && "border-b-1 border-white"
                            }  ${column.Header == "Hombres" && "border-r-1"}`}
                          >
                            <div className="flex">
                              <span className="">
                                {column.render("Header")}
                              </span>
                              <span className="ml-1 mt-auto mb-auto">
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <TbSortDescending />
                                  ) : (
                                    <TbSortAscending />
                                  )
                                ) : (
                                  <TbArrowsSort />
                                )}
                              </span>
                            </div>
                          </th>
                        </React.Fragment>
                      );
                    })}
                    {checkButton ? (
                      <th className="px-3 text-hercules-light-font-secondary font-medium dark:text-white"></th>
                    ): null }
                    {rechazarButton ? (
                      <th className="px-3 text-hercules-light-font-secondary font-medium dark:text-white"></th>
                    ) : null}
                    {anularButton && (
                      <th className="px-3 text-hercules-light-font-secondary font-medium dark:text-white"></th>
                    )}
                  </tr>

                  <tr {...headerGroup.getHeaderGroupProps()} className="h-10">
                    {headerGroup.headers.map((column) => {
                      const { key, ...restColumn } = column.getHeaderProps();
                      return (
                        <th key={key} {...restColumn} className="pl-4 pb-4">
                          <div className="w-fit pr-4">
                            {column.canFilter ? column.render("Filter") : null}
                          </div>
                        </th>
                      );
                    })}
                    {checkButton?.active && <th></th>}
                    {rechazarButton?.active && <th></th>}
                    {anularButton?.active && <th></th>}
                  </tr>
                </React.Fragment>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()} className="h-full">
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <React.Fragment key={i}>
                  <tr
                    {...row.getRowProps()}
                    className={` ${
                      row.original.estado === "RECHAZADO"
                        ? "hover:bg-red-500 hover:bg-opacity-25 dark:hover:bg-red-500 dark:hover:bg-opacity-25"
                        : row.original.estado === "ANULADO"
                        ? "hover:bg-yellow-400 hover:bg-opacity-30 dark:hover:bg-yellow-400 dark:hover:bg-opacity-30"
                        : row.original.estado === "APROBADO"
                        ? "hover:bg-green-500 hover:bg-opacity-20 dark:hover:bg-green-500 dark:hover:bg-opacity-20"
                        : "dark:hover:bg-white dark:hover:bg-opacity-10 hover:bg-light-accent hover:bg-opacity-20"
                    } group bg-white dark:bg-hercules-dark-bg-200 dark:text-dark-text-100 dark:border-b-hercules-dark-secondary-button border-b border-hercules-light-secondary text-black h-12 transition duration-100 ease-in-out `}
                  >
                    {row.cells.map((cell, i) => {
                      return (
                        <td
                          key={i}
                          {...cell.getCellProps}
                          className="py-2 px-4 text-base font-normal"
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                    {checkButton && (
                      <td className="mx-6">
                        <MdCheck
                          title="Aprobar"
                          className={`ml-6 text-2xl  ${
                            row.original.estado === "PENDIENTE"
                              ? "text-green-600 group-hover:cursor-pointer"
                              : "text-gray-700"
                          } `}
                          onClick={() => {
                            if (row.original.estado === "PENDIENTE") {
                              checkButton.onClickFunc(row.original);
                            } else {
                              toast.dismiss();
                              toast.error(
                                "No se pueden aprobar vacaciones ya " +
                                  (row.original.estado === "APROBADO"
                                    ? "aprobadas" : row.original.estado === "ANULADO" ? "anuladas"
                                    : "rechazadas"),
                                estilosAlerta
                              );
                            }
                          }}
                        />
                      </td>
                    )}
                    {rechazarButton && (
                      <td className="mx-6 pr-3">
                        <MdClose
                          title="Rechazar"
                          className={`ml-6 text-2xl ${
                            row.original.estado === "PENDIENTE"
                              ? "text-red-500 group-hover:cursor-pointer"
                              : "text-gray-700"
                          } `}
                          onClick={() => {
                            if (row.original.estado === "PENDIENTE") {
                              rechazarButton.onClickFunc(row.original);
                            } else {
                              toast.dismiss();
                              toast.error(
                                "No se pueden rechazar vacaciones ya " +
                                  (row.original.estado === "RECHAZADO"
                                    ? "rechazadas" : row.original.estado === "ANULADO" ? "anuladas"
                                    : "aprobadas"),
                                estilosAlerta
                              );
                            }
                          }}
                        />
                      </td>
                    )}
                    {anularButton && (
                      <td className="mx-6 pr-3">
                        <MdDoDisturb
                          title="Anular"
                          className="ml-6 text-2xl text-red-500 group-hover:cursor-pointer"
                          onClick={() => {
                            if (row.original.estado === "PENDIENTE") {
                              anularButton.onClickFunc(row.original);
                            } 
                            if (row.original.estado === "APROBADO") {
                              anularButton.onClickFunc(row.original);
                            }
                            if ( row.original.estado === "ANULADO") {
                              toast.dismiss();
                              toast.error("No se pueden anular vacaciones ya anuladas", estilosAlerta);
                            }
                          }}
                        />
                      </td>
                    )}
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className=" border-1 dark:border-t-hercules-dark-secondary-button border-t-hercules-light-secondary border-gray-300 dark:border-dark-bg-200 dark:bg-hercules-dark-bg-200   rounded-b-md h-14 flex bg-white">
        <div className="ml-auto py-3 flex">
          <p className="hidden sm:block pt-1 text-black dark:text-white">
            Filas por página
          </p>
          <select
            name=""
            id=""
            className="ml-2 h-8 text-sm bg-white border  rounded-sm"
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block p-4 text-black dark:text-white">
          Página {pageIndex + 1} de {pageOptions.length}
        </div>
        <div className="flex mr-4 pl-4">
          <button
            className="bg-white text-black dark:text-white dark:bg-opacity-0 rounded-full hover:bg-gray-200 hover:text-black my-2 w-8 mr-6"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <MdFirstPage className="pl-1 text-2xl" />
          </button>
          <button
            className="bg-white text-black rounded-full dark:text-white dark:bg-opacity-0  hover:bg-gray-200 hover:text-black my-2 mr-6 w-8"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <MdArrowBackIos className="ml-2" />
          </button>
          <button
            className="bg-white text-black rounded-full dark:text-white dark:bg-opacity-0  hover:bg-gray-200 hover:text-black my-2 mr-6 w-8"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <MdArrowForwardIos className="ml-2" />
          </button>
          <button
            className="bg-white text-black rounded-full dark:text-white dark:bg-opacity-0  hover:bg-gray-200 hover:text-black my-2 w-8"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <MdLastPage className="pl-1 text-2xl" />
          </button>
        </div>
      </div>
      {showModalRechazar && (
        <ModalRechazarVacaciones
          isVisible={showModalRechazar}
          onClose={() => setShowModalRechazar(false)}
        />
      )}
      {showModalAnular && (
        <ModalAnularVacaciones
          isVisible={showModalAnular}
          onClose={() => setShowModalAnular(false)}
        />
      )}
    </>
  );
}
