import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStateContext } from "../../contexts/ContextProvider";
import { ColumnFilter } from "../../components";
import { getListaSindicatosVisibles, hideLoader, limpiarInfoMenuEdicion, showLoader } from "../../Redux/Actions";
import UtilsFunctions from "../../utils/UtilsFunctions";
import EditarGrupoSindicato from "../../components/Edicion/GruposDeSindicatos/EditarGrupoSindicato";
import SimpleTable from "../../components/SimpleTable";
import NuevoGrupoSindicato from "../../components/Edicion/GruposDeSindicatos/NuevoGrupoSindicato";
import RutColumnFilter from "../../components/RutColumnFilter";
import ColumnFilterOptions from "../../components/ColumnFilterOptions";

const GruposSindicatos = () => {
  const { refreshTable, activeMenu } = useStateContext();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader());
      await Promise.all([
        dispatch(getListaSindicatosVisibles())
      ]);
      dispatch(hideLoader());
    };

    fetchData().catch((error) => {
      console.log(error.response.data.message);
      dispatch(hideLoader());
    });
  }, [refreshTable, dispatch]);

  const infoSindicatos = useSelector(
    (state) => state.getSindicatosVisibles
  );

  useEffect(() =>{
    return () => {
      dispatch(limpiarInfoMenuEdicion("getSindicatosVisibles"))
    }
  },[])

  const [showModalNuevoSindicato, setShowModalNuevoSindicato] = useState(false);
  const [showModalEditarSindicato, setShowModalEditarSindicato] =
    useState(false);
  const { rowDataSindicato, setRowDataSindicato } = useStateContext();

  const GruposSindicatosTabla = [
    {
      Header: "Nombre",
      accessor: "nombre",
      Filter: ColumnFilter,
    },
    {
      Header: "Numero Sindicato",
      accessor: "numeroSindicato",
      Filter: ColumnFilter,
    },
    {
      Header: "RUT Sindicato",
      accessor: "rutSindicato",
      Cell: ({ value }) => UtilsFunctions.rutFormatter(value, { dots: true }),
      Filter: RutColumnFilter,
    },
    {
      Header: "Activo",
      accessor: "activo",
      Filter: ColumnFilterOptions,
      Cell: ({ value }) => {
        if (value === true) {
          return "Si";
        } else {
          return "No";
        }
      },
    },
  ];

  const headersCsv = [
    {
      key: "nombre",
      name: "Nombre",
    },
    {
      key: "numeroSindicato",
      name: "Numero Sindicato",
    },
    {
      key: "rutSindicato",
      name: "RUT Sindicato",
    },
    {
      key: "activo",
      name: "Activo",
    },
  ];

  return (
    <div
    className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
      activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">
        Edición
      </div>
      <div className="titulo-Pagina">
        Grupos de sindicatos
      </div>
      <div className="h-full md:mr-12 rounded-lg pt-5">
          <SimpleTable
            col={GruposSindicatosTabla}
            tableData={infoSindicatos || []}
            primaryButton={{
              type: "editar",
              onClickFunc: (data) => {
                setShowModalEditarSindicato(true);
                setRowDataSindicato(data);
              },
              active: true,
            }}
            exportCsv={() =>
              UtilsFunctions.jsonToCSV3(infoSindicatos, headersCsv, "Grupo de Sindicatos")
            }  
          />

        <button
          onClick={() => setShowModalNuevoSindicato(true)}
          className="mt-3 bg-hercules-light-primary-button text-white dark:text-dark-primary font-medium uppercase text-normal px-4 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        >
          Nuevo Sindicato
        </button>
      </div>
      <NuevoGrupoSindicato
        isVisible={showModalNuevoSindicato}
        onClose={() => setShowModalNuevoSindicato(false)}
      />
      <EditarGrupoSindicato
        isVisible={showModalEditarSindicato}
        onClose={() => setShowModalEditarSindicato(false)}
        modalData={rowDataSindicato}
      />
    </div>
  );
};

export default GruposSindicatos;
