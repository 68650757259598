import React from "react";

const SpinnerRevisarHabDesc = () => {
  return (
    <div className="flex items-center pt-3 h-full align-middle bg-white dark:bg-hercules-dark-bg-200 min-w-full ">
      <div className=" inline-block align-middle mx-auto border-t-blue-ribbon-500 animate-spin ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
    </div>
  );
};

export default SpinnerRevisarHabDesc;
