import React, { useState } from "react";
import PaginaUnoEditarTrabajador from "./PaginaUnoEditarTrabajador";
import PaginaDosEditarTrabajador from "./PaginaDosEditarTrabajador";
import ModalAPVsCajaDos from "./ModalAPVs&Caja2";

const EditarTrabajadorMantenedor = ({ isVisible, onClose, infoTrabajadorAEditar }) => {
  const [pagina, setPagina] = useState("1");
  const [apvs, setApvs] = useState(null);

  const handleChangePage = (trabajadorData, page, apvs) => {
    setApvs(apvs);
    setPagina(page);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <>
      {pagina === "1" ? (
        <PaginaUnoEditarTrabajador
          onClose={() => onClose()}
          changePage={(trabajadorData, page, apvs) =>
            handleChangePage(trabajadorData, page, apvs)
          }
          datosTrabajadorEditar={infoTrabajadorAEditar}
        />
      ) : null}
      {pagina === "2" ? (
        <PaginaDosEditarTrabajador
          onClose={() => {
            onClose();
            setPagina("1");
          }}
          institucionesApv={apvs}
          datosTrabajadorEditar={infoTrabajadorAEditar}
          changePage={(trabajadorData, page, apvs) =>
            handleChangePage(trabajadorData, page, apvs)
          }
        />
      ) : null}
      {pagina === "3" ? (
        <ModalAPVsCajaDos
        changePage={(trabajadorData, page, apvs) =>
          handleChangePage(trabajadorData, page, apvs)
        }
      onClose={() => {
          onClose();
          setPagina("1");
        }}
        institucionesApv={apvs}
        datosTrabajadorEditar={infoTrabajadorAEditar}
        />
      ) : null }
    </>
  );
};

export default EditarTrabajadorMantenedor;
