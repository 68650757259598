import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  hideLoader,
  limpiarInfoMenuEdicion,
  obtenerTrabajadoresCalculoLiquidaciones,
  showLoader,
} from "../../Redux/Actions";
import { useStateContext } from "../../contexts/ContextProvider";
import { ColumnFilter } from "../../components";
import CalculoLiquidacionesTable from "../../components/Movimientos/CalculoLiquidaciones/CalculoLiquidacionesTable";
import UtilsFunctions from "../../utils/UtilsFunctions";
import RutColumnFilter from "../../components/RutColumnFilter";
import ColumnFilterOptions from "../../components/ColumnFilterOptions";

export default function CalculoLiquidacion() {
  const { refreshTable, activeMenu, setRowDataLiquidaciones } =
    useStateContext();
  const dispatch = useDispatch();
  const periodoId = localStorage.getItem("periodoId");
  const empresaId = localStorage.getItem("empresaId");

  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader());
      await Promise.all([
        dispatch(obtenerTrabajadoresCalculoLiquidaciones(periodoId, empresaId)),
      ]);
      dispatch(hideLoader());
    };

    fetchData().catch((error) => {
      console.log(error.response.data.message);
      dispatch(hideLoader());
    });

    return () => {
      setRowDataLiquidaciones(null);
    };
  }, [refreshTable, dispatch]);

  const infoTrabajadoresCalculoLiquidaciones = useSelector(
    (state) => state.trabajadoresCalculoLiquidaciones
  );

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("trabajadoresCalculoLiquidaciones"));
    };
  }, []);

  function conteo(array) {
    let contador = 0;
    const largoArray = array.length;
    array.map((e) => {
      if (e.liquidacionCalculada !== false) {
        contador += 1;
      }
    });
    return {
      calculadas: contador,
      noCalculadas: largoArray - contador,
    };
  }

  const COLUMNS = [
    {
      Header: "RUT",
      accessor: "rut",
      Cell: ({ value }) => UtilsFunctions.rutFormatter(value, { dots: true }),
      Filter: RutColumnFilter,
    },
    {
      Header: "Apellido paterno",
      accessor: "apellidoPaterno",
      Filter: ColumnFilter,
    },
    {
      Header: "Apellido materno",
      accessor: "apellidoMaterno",
      Filter: ColumnFilter,
    },
    {
      Header: "Nombre",
      accessor: "nombres",
      Filter: ColumnFilter,
    },
    {
      Header: "Estado",
      accessor: "liquidacionCalculada",
      Filter: ColumnFilterOptions,
      Cell: ({ value }) => {
        if (value === false) {
          return "Pendiente";
        } else {
          return "Calculada";
        }
      },
    },
  ];

  const headersCsv = [
    { name: "RUT", key: "rut" },
    { name: "Apellido paterno", key: "apellidoPaterno" },
    { name: "Apellido materno", key: "apellidoMaterno" },
    { name: "Nombre", key: "nombres" },
    { name: "Estado", key: "liquidacionCalculada" },
  ];

  return (
    <div
      className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
        activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">Movimientos</div>
      <div className="titulo-Pagina">Cálculo de liquidación general</div>
      <div className="md:flex md:flex-wrap mt-5">
        <div className="w-full md:w-2/12 pb-6 md:pb-5">
          <label
            className="block uppercase tracking-wide dark:text-red-300 text-red-500 text-xs font-bold mb-2"
            htmlFor="grid-last-name"
          >
            Liquidaciones Calculadas
          </label>
          <input
            disabled
            value={
              infoTrabajadoresCalculoLiquidaciones.length > 0
                ? conteo(infoTrabajadoresCalculoLiquidaciones).calculadas
                : 0
            }
            name="nombre"
            id="liquidacionesCalculadas"
            className="appearance-none block w-28 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="text"
          />
        </div>

        <div className="w-full md:w-2/12 pb-6 md:pb-5">
          <label
            className="block uppercase tracking-wide dark:text-dark-text-100 text-black text-xs font-bold mb-2"
            htmlFor="grid-last-name"
          >
            Liquidaciones No Calculadas
          </label>
          <input
            disabled
            value={
              infoTrabajadoresCalculoLiquidaciones.length > 0
                ? conteo(infoTrabajadoresCalculoLiquidaciones).noCalculadas
                : 0
            }
            name="nombre"
            id="liquidacionesCalculadas"
            className="appearance-none block w-28 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="text"
          />
        </div>
      </div>

      <div className="h-full md:mr-12 rounded-lg">
        <CalculoLiquidacionesTable
          col={COLUMNS}
          tableData={infoTrabajadoresCalculoLiquidaciones}
          botonLiquidaciones={true}
          exportCsv={() =>
            UtilsFunctions.jsonToCSV3(
              infoTrabajadoresCalculoLiquidaciones,
              headersCsv,
              "Liquidaciones"
            )
          }
        />
      </div>
    </div>
  );
}
