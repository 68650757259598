import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  listaPeriodosHastaActual,
} from "../Redux/Actions";
import LoginImage from "../assets/man-593333.jpg";
import UtilsFunctions from "../utils/UtilsFunctions";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { axiosPrivate } from "../common/axiosPrivate";
import { useForm } from "react-hook-form";

export default function SeleccionarEmpresayPeriodo() {
  const {
    register,
    getValues,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      periodoAnyo: "",
      periodoMes: "",
    },
  });

  function presionaTecla(event) {
    if (event.keyCode == 13) {
      handleLogin();
    }
  }

  const [empresas, setEmpresas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [infoPeriodos, setInfoPeriodos] = useState([]);
  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const usuarioId = localStorage.getItem("userId");
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const dispatch = useDispatch();
  let navigate = useNavigate();

  async function getEmpresasUsuario(usuarioId) {
    await axiosPrivate
      .get(`/api/v1/usuarios/${usuarioId}/empresas`, Authorization)
      .then((response) => {
        // console.log("response", response)
        setEmpresas(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getPeriodosCargados(){
    const infoMeses = await axiosPrivate(`${api_service}/api/v1/periodos/cargados`, Authorization)
    setInfoPeriodos(infoMeses.data)
  }

  const campoObligatorio = {
    required: { value: true, message: "Obligatorio" },
  };

  useEffect(() => {
    getEmpresasUsuario(usuarioId);
    dispatch(listaPeriodosHastaActual());
    getPeriodosCargados();
  }, [dispatch, usuarioId]);

  const anyosData = UtilsFunctions.obtenerAnyosHastaActual();
  const [mesesData, setMesesData] = useState([]);

  const handleYearChange = (e) => {
    const year = parseInt(e);

    // Encontrar el objeto en infoPeriodos que tiene el 'anyo' igual a year.
    const yearData = infoPeriodos.find((item) => item.anyo === year);

    if (yearData) {
      // Filtrar los meses que cumplen con las condiciones y establecerlos en mesesData.
      const filteredMonths = yearData.periodos.filter(
        (mes) => mes.datosPrevisionalesCargados === true
      );
      const transformedMonths = filteredMonths.map((month) => (
        UtilsFunctions.integerToMonth(month.mes)
      ));
      setMesesData(transformedMonths);

    } else {
      // Si no se encontró ningún año con el valor seleccionado, establecer mesesData como un arreglo vacío.
      setMesesData([]);
    }
  };

  async function handleLogin() {
    const data = getValues();
    setLoading(true);

    try {
      const info = await axiosPrivate.get(
        `${api_service}/api/v1/periodos/mes/${data.numeroMes}/anyo/${data.periodoAnyo}`,
        Authorization
      );
      localStorage.setItem("rutEmpresa", data.rutEmpresa);
      localStorage.setItem("empresa", data.empresa);
      localStorage.setItem("empresaId", data.empresaId);
      localStorage.setItem("periodoId", info.data.id);
      localStorage.setItem("periodoMes", data.numeroMes);
      localStorage.setItem("periodoAnyo", data.periodoAnyo);
      setLoading(false);
      navigate("/inicio");
    } catch (error) {
      setLoading(false);
      setErrorMessage(error.response.data);
      toast.error(error.response.data.message? error.response.data.message : "Error al iniciar sesión")
    }
  }
  
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 h-screen w-full" onKeyDown={(event) => presionaTecla(event)} >
      <div className="hidden sm:block">
        <img className="w-full h-full object-cover" src={LoginImage} alt="" />
      </div>

      <div className="sm:bg-bluegray-200 bgLP00542248879581black/40 flex flex-col justify-center sm:px-3">
        <img
          className="absolute sm:hidden w-full h-full object-cover mix-blend-overlay"
          src={LoginImage}
          alt=""
        />

        <form
          className="relative max-w-[400px] w-full mx-auto bg-white p-4 rounded-lg"
          onSubmit={handleSubmit(handleLogin)}
        >
          <h2 className="text-3xl font-bold text-center py-6 ">
            Seleccionar Empresa y Periodo
          </h2>
          <div className="relative flex flex-col py-2">
            <label htmlFor="empresaId">Empresa</label>
            <select
              {...register("empresaId", {
                onChange: (e) => {
                  const empresa =
                    empresas[e.target.selectedIndex - 1].nombreFantasia;
                  setValue("empresa", empresa);
                  const rutEmpresa =
                  empresas[e.target.selectedIndex - 1].rutEmpresa;
                setValue("rutEmpresa", rutEmpresa);
                },
                ...campoObligatorio,
              })}
              name="empresaId"
              className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
            >
              <option value="" hidden>
                Seleccionar Empresa
              </option>
              {empresas.map((el, i) => (
                <option key={i} value={el.empresaId}>
                  {el.nombreFantasia}
                </option>
              ))}
            </select>
            <span
              className={`text-red-500 text-xs italic ${
                !errors.empresaId && "opacity-0 invisible"
              }`}
            >
              {errors.empresaId ? errors.empresaId.message : "hidden"}
            </span>
          </div>

          <div className="pt-1 py-2">
            <label htmlFor="periodoId">Periodo</label>
            <div className="flex ">
              <div className="w-full md:w-44 mb-6 md:mb-0 pr-3">
                <select
                  {...register("periodoAnyo", {
                    onChange: (e) => {
                      setValue("periodoMes", "");
                      handleYearChange(e.target.value)
                    },
                    ...campoObligatorio,
                  })}
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                >
                  <option hidden value="">
                    Año
                  </option>
                  {anyosData.map((anyo, i) => (
                    <option value={anyo} key={i}>
                      {anyo}
                    </option>
                  ))}
                </select>
                <span
                  className={`text-red-500 text-xs italic ${
                    !errors.periodoAnyo && "opacity-0 invisible"
                  }`}
                >
                  {errors.periodoAnyo ? errors.periodoAnyo.message : "hidden"}
                </span>
              </div>

              <div className="w-full md:w-48">
                <select
                  {...register("periodoMes", {
                    onChange: (e) => {
                      setValue("numeroMes", e.target.selectedIndex);
                    },
                    ...campoObligatorio
                  })}
                  disabled={watch("periodoAnyo") === "" ? true : false}
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                >
                  <option hidden value="">
                  Mes
                  </option>
                  {mesesData.map((mes, i) => (
                    <option value={mes} key={i}>
                      {mes}
                    </option>
                  ))}
                </select>
                <span
                  className={`text-red-500 text-xs italic ${
                    !errors.periodoMes && "opacity-0 invisible"
                  }`}
                >
                  {errors.periodoMes ? errors.periodoMes.message : "hidden"}
                </span>
              </div>
            </div>
          </div>

          <button
            className="hover:scale-105 w-full my-3 md:my-5 py-2 bg-hercules-dark-primary-button text-white  font-semibold text-base px-8 rounded-xl shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-2 ease-in-out transition-all duration-400"
            type="submit"
          >
            Ingresar
          </button>
          <div className="flex">
            {errorMessage === "" ? null : (
              <p className="hidden lg:block text-red-500 text-sm">
                {errorMessage}
              </p>
            )}
          </div>
        </form>
      </div>
      {loading && (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
          <div className="border-t-blue-ribbon-500 animate-spin ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
          <h2 className="text-center text-white text-xl font-semibold">
            Cargando...
          </h2>
          <p className="w-1/3 text-center text-white">
            Esto puede tardar unos segundos, por favor no cierres esta página.
          </p>
        </div>
      )}
    </div>
  );
}
