import React from 'react';

export const DarkModeContext = React.createContext({});

export const DarkModeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = React.useState(false);
  
  

  React.useEffect(() => {
    if (localStorage.getItem("userTheme")) {
      setDarkMode(localStorage.getItem("userTheme") === "dark" ? true : false)
    }
    
    if (darkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }

  }, [darkMode]);

  return (
    <DarkModeContext.Provider value={{ darkMode, setDarkMode }}>
      {children}
    </DarkModeContext.Provider>
  )
};