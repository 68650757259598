import { axiosPrivate } from "../../../common/axiosPrivate";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useStateContext } from "../../../contexts/ContextProvider";
import {
  limpiarInfoMenuEdicion,
  listaAfp,
} from "../../../Redux/Actions";
import UtilsFunctions from "../../../utils/UtilsFunctions";
import { useForm } from "react-hook-form";
import SimpleTable from "../../SimpleTable";
import ModalSpinner from "../../ModalSpinner";

export default function NuevoAFPTrabajador({
  isVisibleEditorAfp,
  onCloseEditorAfp,
  modalData,
  updateTrabajadorAfp,
}) {
  const {
    register,
    watch,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      periodoAnyo: "",
      tasa: "",
      sis: "",
    },
  });

  const dispatch = useDispatch();
  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const [botonGuardarHabilitado, setBotonGuardarHabilitado] = useState(true)
  const afps = useSelector((state) => state.listaAfp);
  const afpTrabajadorData = useSelector((state) => state.afpTrabajador);
  const [listaAFPData, setListaAFPData] = useState([]);
  const { refreshTable, setRefreshTable, screenSize } = useStateContext();
  const anyosData = UtilsFunctions.obtenerAnyosHastaActual();
  const [mesesData, setMesesData] = useState([]);
  const [infoPeriodos, setInfoPeriodos] = useState([]);
const [botonAgregarVisible, setBotonAgregarVisible] = useState(false);
  const [dataEditar, setDataEditar] = useState({});
  const tasaValue = parseFloat(watch("tasa")?.replace(" %", ""));
  const sisValue = parseFloat(watch("sis")?.replace(" %", ""));
  const total =
    !isNaN(tasaValue) && !isNaN(sisValue)
      ? `${Math.round((tasaValue + sisValue) * 100) / 100} %`
      : "";

      const isMobile = screenSize <= 768;
      const estilosAlerta = {
        style: {
          marginTop: isMobile ? "80px" : "",
        },
      };
    
  useEffect(() => {
    getPeriodosCargados()
    dispatch(listaAfp());
    const rutTrabajador = UtilsFunctions.rutFormatter(modalData.rutTrabajador, {
      dots: true,
    });
    setValue(
      "nombres",
      modalData.nombres +
        " " +
        modalData.apellidoPaterno +
        " " +
        modalData.apellidoMaterno
    );
    setValue("rut", rutTrabajador);
    setValue("trabajadorId", modalData.trabajadorId);
  }, []);

  useEffect(() => {
    if (afpTrabajadorData.length > 0) {
      setListaAFPData(afpTrabajadorData);
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("listaAfp"));
      reset({});
    };
  }, []);

  async function getPeriodosCargados(){
    const infoMeses = await axiosPrivate(`${api_service}/api/v1/periodos/cargados`, Authorization)
    setInfoPeriodos(infoMeses.data)
  }

  const handleYearChange = (e) => {
    const year = parseInt(e);
    const yearData = infoPeriodos.find((item) => item.anyo === year);
    if (yearData) {
      const filteredMonths = yearData.periodos.filter(
        (mes) => mes.datosPrevisionalesCargados === true
      );
      const transformedMonths = filteredMonths.map((month) => (
        UtilsFunctions.integerToMonth(month.mes)
      ));
      setMesesData(transformedMonths);

    } else {
      // Si no se encontró ningún año con el valor seleccionado, establecer mesesData como un arreglo vacío.
      setMesesData([]);
    }
  };

  async function onSubmitTasaAfp(data) {
    toast.loading("Calculando", estilosAlerta);
    try {
      console.log("data recibida", data);
      const info = await axiosPrivate.get(
        `${api_service}/api/v1/periodos/mes/${data.numeroMes}/anyo/${data.periodoAnyo}`,
        {
          headers: {
            Authorization: `Bearer ${tokenUser}`,
            ClientId: `${token}`,
          },
        }
      );
      setValue("periodoId", info.data.id);

      const payload = {
        afpId: data.afpId,
        periodoId: info.data.id,
      };
      const response = await axiosPrivate.post(
        `${api_service}/api/v1/trabajadores/tasaperiodoafp`,
        payload,
        Authorization
      );
      //   console.log("response", response);
      if (response.status === 200) {
        console.log("response", response);
        setValue("tasa", `${response.data.tasa} %`);
        setValue("sis", `${response.data.sis} %`);
        setBotonAgregarVisible(true);
        setDataEditar({
          nombreAfp: response.data.afp.glosa,
          tasa: response.data.tasa,
          sis: response.data.sis,
        });
        // console.log("Actualizo valores");
      }
      toast.dismiss();
      toast.success("Calculado con éxito", estilosAlerta);
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al calcular", estilosAlerta
      );
    }
  }

  async function onSubmitAgregarAfp() {
    setBotonGuardarHabilitado(false)
    const copiaListaAFPData = listaAFPData;

    try {
      toast.loading("Editando AFP", estilosAlerta);
      const payload = {
        periodoId: getValues("periodoId"),
        trabajadorId: modalData?.trabajadorId,
        afpId: getValues("afpId"),
      };

      const response = await axiosPrivate.put(
        `${api_service}/api/v1/trabajadores/actualizarafptrabajador`,
        payload,
        Authorization
      );
      if (response.status === 200) {
        const elemento = copiaListaAFPData.filter(
          (e) =>
            e.periodo.mes === parseInt(getValues("numeroMes")) &&
            e.periodo.anyo === parseInt(getValues("periodoAnyo"))
        );
        const index = copiaListaAFPData.indexOf(elemento[0]);
        const afpEditar = copiaListaAFPData.splice(index, 1)[0];
        afpEditar["nombreAfp"] = dataEditar.nombreAfp;
        afpEditar["sis"] = dataEditar.sis;
        afpEditar["tasa"] = dataEditar.tasa;

        copiaListaAFPData.splice(index, 0, afpEditar);
        const nuevoArray = [...copiaListaAFPData];
        setListaAFPData(nuevoArray);
        updateTrabajadorAfp(listaAFPData[0]?.nombreAfp);
        setRefreshTable(!refreshTable);
        toast.dismiss();
        setBotonAgregarVisible(false);
        toast.success("AFP editada con éxito", estilosAlerta);
        reset();
      }
      setBotonGuardarHabilitado(true)
    } catch (error) {
      if (
        error.response &&
        error.response.status === 404 &&
        error.response.data.message ===
          "No existe registro de afp para el trabajador en el periodo seleccionado"
      ) {
        try {
          const payload = {
            periodoId: getValues("periodoId"),
            trabajadorId: modalData?.trabajadorId,
            afpId: getValues("afpId"),
          };
          const response = await axiosPrivate.post(
            `${api_service}/api/v1/trabajadores/agregarafptrabajador`,
            payload,
            Authorization
          );

          copiaListaAFPData.push(response.data);
          copiaListaAFPData.sort((a, b) => b.periodo.id - a.periodo.id);
          const nuevoArray = [...copiaListaAFPData];
          setListaAFPData(nuevoArray);
          toast.dismiss();
          setBotonAgregarVisible(false);
          toast(
            "No existía registro de AFP para el trabajador en el periodo seleccionado.\nSe agregó un nuevo registro de AFP en ese periodo.", estilosAlerta
          );
          reset();
        } catch (innerError) {
          toast.dismiss();
          toast.error(
            error.response.data.message ? error.response.data.message : "Error al actualizar AFP", estilosAlerta
          );
        }
      } else {
        toast.dismiss();
        toast.error(error.response.data.message ? error.response.data.message : "Error al actualizar AFP", estilosAlerta);
      }
      setBotonGuardarHabilitado(true)
    }
  }

  const loadingModal = afpTrabajadorData.length > 0 && afps.length > 0;

  const COLUMNS = [
    {
      Header: "AFP",
      accessor: "nombreAfp",
    },
    {
      Header: "Periodo",
      accessor: (d) =>
        UtilsFunctions.periodoToText(d.periodo.mes, d.periodo.anyo),
    },
    {
      Header: "Tasa",
      accessor: (d) => `${Math.round(d.tasa * 100) / 100}%`.replace(".", ","),
    },
    {
      Header: "SIS",
      accessor: (d) => `${Math.round(d.sis * 100) / 100}%`.replace(".", ","),
    },
    {
      Header: "Total AFP",
      accessor: (d) =>
        `${Math.round((d.tasa + d.sis) * 100) / 100}%`.replace(".", ","),
    },
  ];

  if (!isVisibleEditorAfp) {
    return null;
  }

  console.log("watch", watch());
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="segundoDivModal max-w-3xl">
          {/*content*/}
          <div className="tercerDivModal shadow-lg  max-h-[85vh]">
            {/*header*/}
            <div className="divHeaderModal border-slate-200">
              <h3 className="titulo-Modal">
                AFP Trabajador
              </h3>
            </div>
            {/*body*/}
            <div
              className={`relative p-6 flex-auto dark:bg-hercules-dark-bg-200 ${
                !loadingModal ? "overflow-hidden" : "overflow-auto"
              }`}
            >
              {!loadingModal ? (
                <div className="h-fix">
                  <ModalSpinner />
                </div>
              ) : (
                <>
                  <div className="md:flex md:flex-wrap mb-4">
                    <div className="mb-9 md:mb-6 md:w-1/2 md:pr-3">
                      <label
                        className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Nombre
                      </label>
                      <input
                        disabled
                        {...register("nombres")}
                        className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                      />
                    </div>
                    <div className="mb-9 md:mb-6 md:w-1/2 md:pl-3">
                      <label
                        className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        RUT
                      </label>
                      <input
                        disabled
                        {...register("rut")}
                        className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        type="text"
                      />
                    </div>
                  </div>

                  <form onSubmit={handleSubmit(onSubmitTasaAfp)}>
                    <div className="flex flex-wrap">
                      {/* Selector AFP */}
                      <div className="relative w-full md:w-4/12 md:pr-3 mb-2">
                        <label
                          className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          AFP
                        </label>
                        <select
                          {...register("afpId", {
                            required: {
                              value: true,
                              message: "Obligatorio",
                            },
                            onChange: () => {
                              setValue("tasa", "");
                              setValue("sis", "");
                              setBotonAgregarVisible(false);
                            },
                          })}
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        >
                          <option hidden value="">
                            Seleccione una AFP
                          </option>
                          {afps.map((afp) => (
                            <option value={afp.id} key={afp.id}>
                              {afp.glosa}
                            </option>
                          ))}
                        </select>
                        <span
                          className={`text-red-500 text-xs italic ${
                            !errors.afpId && "opacity-0 invisible"
                          }`}
                        >
                          {errors.afpId ? errors.afpId.message : "hidden"}
                        </span>
                      </div>

                      {/* Selector de Periodo/Año */}
                      <div className="relative md:w-2/12 w-4/12 md:pl-3 md:pr-2 pr-3">
                        <label
                          className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Periodo
                        </label>
                        <select
                          {...register("periodoAnyo", {
                            onChange: (e) => {
                              handleYearChange(e.target.value)
                              setValue("periodoMes", "");
                              setValue("tasa", "");
                              setValue("sis", "");
                              setBotonAgregarVisible(false);
                            },
                            required: {
                              value: true,
                              message: "Obligatorio",
                            },
                          })}
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        >
                          <option hidden value="">
                            Año
                          </option>
                          {anyosData.map((anyo, i) => (
                            <option value={anyo} key={i}>
                              {anyo}
                            </option>
                          ))}
                        </select>
                        <span
                          className={`text-red-500 text-xs italic ${
                            !errors.periodoAnyo && "opacity-0 invisible"
                          }`}
                        >
                          {errors.periodoAnyo
                            ? errors.periodoAnyo.message
                            : "hidden"}
                        </span>
                      </div>

                      {/* Selector de Periodo/Mes */}
                      <div className="relative md:w-3/12 w-6/12 pt-1">
                        <select
                          {...register("periodoMes", {
                            onChange: (e) => {
                              setValue("numeroMes", e.target.selectedIndex);
                              setValue("tasa", "");
                              setValue("sis", "");
                              setBotonAgregarVisible(false);
                            },
                            required: {
                              value: true,
                              message: "Obligatorio",
                            },
                          })}
                          disabled={watch("periodoAnyo") === "" ? true : false}
                          className="block w-full mt-6 appearance-none bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        >
                          <option hidden value="">
                            Mes
                          </option>
                          {mesesData.map((mes, i) => (
                            <option value={mes} key={i}>
                              {mes}
                            </option>
                          ))}
                        </select>
                        <span
                          className={`text-red-500 text-xs italic ${
                            !errors.periodoMes && "opacity-0 invisible"
                          }`}
                        >
                          {errors.periodoMes
                            ? errors.periodoMes.message
                            : "hidden"}
                        </span>
                      </div>

                      <div className="w-full md:pl-3 md:w-3/12 mb-7 md:mt-7 mt-3">
                        <button
                          className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 ease-in-out transition-all duration-400"
                          type="submit"
                        >
                          Calcular
                        </button>
                      </div>
                    </div>
                  </form>

                  <div className="flex flex-wrap">
                    <div className="w-1/3 md:w-1/4 pr-3">
                      <label
                        className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Tasa
                      </label>
                      <input
                        disabled
                        {...register("tasa")}
                        // value={getValues("tasa") !== "" ? `${getValues("tasa")} %` : ""}
                        className="mb-6 appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                      />
                    </div>

                    <div className="w-1/3 md:w-1/4 px-3">
                      <label
                        className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        SIS
                      </label>
                      <input
                        disabled
                        {...register("sis")}
                        // value={`${getValues("sis")} %`}
                        className="mb-6 appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                      />
                    </div>

                    <div className="w-1/3 md:w-1/4 pl-3">
                      <label
                        className="block tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Total
                      </label>
                      <input
                        disabled
                        {...register("total")}
                        value={total}
                        className="mb-6 appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                      />
                    </div>

                    <div className="w-full md:pl-3 md:w-3/12 mb-7 md:mt-7">
                      <button
                        disabled={!botonAgregarVisible || !botonGuardarHabilitado}
                        hidden={!botonAgregarVisible}
                        className={` ${!botonGuardarHabilitado? "bg-gray-500" : "bg-hercules-light-primary-button hover:shadow-lg hover:scale-105"}  text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400`}
                              onClick={() => {
                          onSubmitAgregarAfp();
                        }}
                      >
                        Actualizar
                      </button>
                    </div>
                  </div>

                  <SimpleTable
                    col={COLUMNS}
                    tableData={listaAFPData}
                    searchBar={false}
                    showFilters={false}
                    tableHeight="h-72"
                  />
                </>
              )}
            </div>
            {/*footer*/}
            <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                type="button"
                onClick={() => {
                  onCloseEditorAfp();
                  reset();
                  setBotonAgregarVisible(false);
                }}
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
