import React, { useState } from "react";
import { Link } from "react-router-dom";
import { GiAbstract014 } from "react-icons/gi";
import { MdOutlineCancel } from "react-icons/md";
import SidebarItem from "./SidebarItem";
import { useStateContext } from "../contexts/ContextProvider";
import { axiosPrivate } from "../common/axiosPrivate";
import UtilsFunctions from "../utils/UtilsFunctions";
import HerculesLogoH from "../assets/Blanco_horizontal.png";

const Sidebar = () => {

  const rutEmpresa = localStorage.getItem("rutEmpresa");
  const rutEmpresaFormateado = UtilsFunctions.rutFormatter(rutEmpresa, { dots: true })

  const { activeMenu, setActiveMenu, screenSize, userMenu, setUserMenu } =
    useStateContext();
  const sidebarEmpresaData = UtilsFunctions.getEmpresaPeriodoInfo();
  const handleCloseSidebar = () => {
    if (activeMenu && screenSize <= 768) {
      setActiveMenu(false);
    }
  };
  
  const REACT_APP_API_HERCULES_SERVICE =
    process.env.REACT_APP_API_HERCULES_SERVICE;

  async function handleGetUserMenu() {
    try {
      const moduloId = localStorage.getItem("moduloId");
      const tokenRut = localStorage.getItem("token-rut-slice");
      const token = localStorage.getItem("token-user");
      const Authorization = {
        headers: {
          Authorization: `Bearer ${token}`,
          ClientId: `${tokenRut}`,
        },
      };


      // api api Obtener Menu segun Rol y replicar en SidebarVacaciones : /rol/modulo/2/menus
      const response = await axiosPrivate.post(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/rol/modulo/${moduloId}/menus`,
        {
          rolId: localStorage.getItem("token-rol-id"),
        },
        Authorization
      );

      if (response.status === 200) {
        const data = response.data;
        setUserMenu(data);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const [openMenus, setOpenMenus] = useState({
    Edición: false,
    Parámetros: false,
    Movimientos: false,
    Informes: false,
    Herramientas: false,
  });

  const setOpenMenu = (key) => {
    setOpenMenus((prevState) => {
      // If the key is already true, set it to false
      if (prevState[key]) {
        return {
          ...prevState,
          [key]: false,
        }
      }
      // Create a new state object with all values set to false
      const newState = Object.fromEntries(
        Object.keys(prevState).map((menuKey) => [menuKey, false])
      );
      // Set the specified key to true in the new state object
      newState[key] = true;
      return newState;
    });
  };

  const handleOpenMenu = (menu) => {
    setOpenMenu(menu);
  }

  if (userMenu.length === 0) {
    handleGetUserMenu();
  }

  return (
    <div
      className={` h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10 duration-200 ${
        activeMenu ? "translate-x-0" : "-translate-x-full"
      }`}
    >
      {activeMenu && (
        <>
          <div className="flex justify-between items-center">
            <Link
              to="/"
              onClick={handleCloseSidebar}
              className="items-center gap-3 md:ml-3 mt-4 flex text-3xl font-extrabold tracking-tight dark:text-white text-white"
            >
              <img src={HerculesLogoH} className=" h-20 object-cover ml-8 mt-4" />
            </Link>
            <button
              onClick={() => setActiveMenu((prevActiveMenu) => !prevActiveMenu)}
            >
              <MdOutlineCancel className="mr-3 mt-4 text-xl rounded-full block md:hidden text-white" />
            </button>
          </div>
          <div className="md:hidden">
            <div className="flex flex-col items-center ml-9 mt-6">
              <div className="flex mr-auto my-1">
                <label className="text-sm text-white font-bold">Empresa:</label>
                <span className="text-sm ml-2 font-semibold text-white">
                  {sidebarEmpresaData.empresa}
                </span>
              </div>
              <div className="mr-auto my-1">
                <label className="text-sm text-white font-bold">RUT:</label>
                <span className="text-sm ml-2 font-semibold text-white">
                {rutEmpresaFormateado}
                </span>
              </div>
              <div className="mr-auto mt-1">
                <label className="text-sm text-white font-bold">Periodo:</label>

                <span className="text-sm ml-2 text-white font-semibold">
                  {sidebarEmpresaData.periodo}
                </span>
              </div>
            </div>
          </div>
          <div className="mt-3 md:mt-10">
            {userMenu.map((item, index) => (
              <SidebarItem key={index} item={item} func={handleCloseSidebar} isOpen={openMenus[item.title]} setOpen={(menu) => handleOpenMenu(menu)} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;
