import { axiosPrivate } from "../../../common/axiosPrivate";
import React, { useEffect, useState } from "react";
import { useStateContext } from "../../../contexts/ContextProvider";
import { useDispatch } from "react-redux";
import ColumnFilter from "../../../components/ColumnFilter";
import { toast } from "react-hot-toast";
import UtilsFunctions from "../../../utils/UtilsFunctions";
import ModalSpinner from "../../ModalSpinner";
import SimpleTable from "../../SimpleTable";

export default function CalculoLiquidacionIndividual({
  isVisible,
  onClose,
  modalData,
}) {
  const dispatch = useDispatch();


  // console.log("modalData", modalData)

  const empresaId = localStorage.getItem("empresaId");
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const periodoId = localStorage.getItem("periodoId");
  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const { refreshTable, setRefreshTable, setShowModalCalculoLiquidacion, screenSize } =
    useStateContext();
  const [loadingModal, setLoadingModal] = useState(false);
  const [haberes, setHaberes] = useState([]);
  const [descuentos, setDescuentos] = useState([]);
  const [movimientos, setMovimientos] = useState([]);
  const [botonGuardarHabilitado, setBotonGuardarHabilitado] = useState(true)

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };  

  async function calcularLiquidaciones(modalData) {
    try {

      // Api Antigua de liquidaciones
      // const data = {
      //   trabajadorId: modalData.trabajadorId,
      //   centroCostoId: modalData.centroCostoId,
      //   contratoId: modalData.contratoId,
      //   anexoContratoId: modalData.anexoContratoId
      //     ? modalData.anexoContratoId
      //     : null,
      //   periodoId: periodoId,
      // };

      // const infoLiquidaciones = await axiosPrivate.post(
      //   `${api_service}/api/v1/trabajadores/calcularLiquidacion`,
      //   data,
      //   Authorization
      // );

      const infoMovimientos = await axiosPrivate(
        `${api_service}/api/v1/contratos/${modalData.contratoId}/movimientos/periodo/${periodoId}`,
        Authorization
      );

        const nuevoPayload = {
          trabajadorId: modalData.trabajadorId,
          periodoId: periodoId,
          empresaId: empresaId
        }
  
        const infoNuevaApiLiquidacionesIndividuales = await axiosPrivate.post(
          `${api_service}/api/v1/trabajadores/liquidacion`,
          nuevoPayload,
          Authorization
        );

      // if (infoLiquidaciones.status === 200 && infoMovimientos.status === 200) {
      //   const dataTotal = infoLiquidaciones.data[0];
      //   const haberes = [];
      //   const descuentos = [];
      //   infoLiquidaciones.data[0].haberes.forEach((e) => {
      //     haberes.push(e);
      //   });

      //   infoLiquidaciones.data[0].descuentos.forEach((e) => {
      //     descuentos.push(e);
      //   });

      //   setHaberes(haberes);
      //   setDescuentos(descuentos);
      //   setMovimientos(infoMovimientos.data);

      //   setInput({
      //     ...input,
      //     id: modalData.id,
      //     rut: modalData.rut,
      //     trabajadorId: modalData.trabajadorId,
      //     nombre: modalData.nombres,
      //     apPaterno: modalData.apellidoPaterno,
      //     apMaterno: modalData.apellidoMaterno,
      //     centroCosto: modalData.nombreCentroCosto,
      //     centroCostoId: modalData.centroCostoId,
      //     periodo: "01-2022",
      //     diasTrabajados: infoLiquidaciones.data[0].diasTrabajados,
      //     diasAusencia: 30 - infoLiquidaciones.data[0].diasTrabajados,
      //     horasExtraId: modalData.horasExtraId,
      //     horasExtra: modalData.horasExtraSuma ? modalData.horasExtraSuma : 0,
      //     horasDescuento: modalData.horasDescuentoSuma
      //       ? modalData.horasDescuentoSuma
      //       : 0,
      //     totalImponible: dataTotal.totalImponible,
      //     totalTributable: dataTotal.totalTributable,
      //     totalNoImponible: dataTotal.totalNoImponible,
      //     totalNoTributable: dataTotal.totalNoTributable,
      //     totalHaberes: dataTotal.totalHaberes,
      //     totalDescuentos: dataTotal.totalDescuentos,
      //     liquidoAPagar: dataTotal.totalLiquido,
      //     saldoNegativo: dataTotal.saldoNegativo,

      //     // diasTrabajados: dataTotal.diasEfectivamenteTrabajados,
      //     // diasAusencia: dataTotal.diasInasistencia,

      //     // totalImponible: dataTotal.totalHaberesImponibles,
      //     // totalTributable: dataTotal.totalHaberesTributables,
      //     // totalNoImponible: dataTotal.totalHaberesNoImponibles,
      //     // totalNoTributable: dataTotal.totalHaberesNoTributables,
      //     // totalHaberes: dataTotal.totalHaberes,
      //     // totalDescuentos: dataTotal.totalDescuentos,
      //     // liquidoAPagar: dataTotal.sueldoLiquido,
      //     // saldoNegativo: dataTotal.saldoNegativo,
      //   });
      //   toast.dismiss();
      //   setLoadingModal(true);
      // }

      if (infoNuevaApiLiquidacionesIndividuales.status === 200 && infoMovimientos.status === 200) {
        setHaberes(infoNuevaApiLiquidacionesIndividuales.data.haberesLiquidacion)
        setDescuentos(infoNuevaApiLiquidacionesIndividuales.data.descuentosLiquidacion);
        setMovimientos(infoMovimientos.data);

        setInput({
          ...input,
          id: modalData.id,
          rut: modalData.rut,
          trabajadorId: modalData.trabajadorId,
          nombre: modalData.nombres,
          apPaterno: modalData.apellidoPaterno,
          apMaterno: modalData.apellidoMaterno,
          centroCosto: modalData.nombreCentroCosto,
          centroCostoId: modalData.centroCostoId,
          periodo: "01-2022",
          horasExtraId: modalData.horasExtraId,
          horasExtra: modalData.horasExtraSuma ? modalData.horasExtraSuma : 0,
          horasDescuento: modalData.horasDescuentoSuma
            ? modalData.horasDescuentoSuma
            : 0,

          diasTrabajados: infoNuevaApiLiquidacionesIndividuales.data.diasEfectivamenteTrabajados,
          diasAusencia: infoNuevaApiLiquidacionesIndividuales.data.diasInasistencia,

          totalImponible: infoNuevaApiLiquidacionesIndividuales.data.totalHaberesImponibles,
          totalTributable: infoNuevaApiLiquidacionesIndividuales.data.totalHaberesTributables,
          totalNoImponible: infoNuevaApiLiquidacionesIndividuales.data.totalHaberesNoImponibles,
          totalNoTributable: infoNuevaApiLiquidacionesIndividuales.data.totalHaberesNoTributables,
          totalHaberes: infoNuevaApiLiquidacionesIndividuales.data.totalHaberes,
          totalDescuentos: infoNuevaApiLiquidacionesIndividuales.data.totalDescuentos,
          liquidoAPagar: infoNuevaApiLiquidacionesIndividuales.data.sueldoLiquido,
          saldoNegativo: infoNuevaApiLiquidacionesIndividuales.data.saldoNegativo,
        });
        toast.dismiss();
        setLoadingModal(true);
      }

    } catch (error) {
      console.log(error);
      toast.error(
        error.response.data.message ? error.response.data.message : "Error al cargar información", estilosAlerta
      );
    }
  }

  useEffect(() => {
    if (modalData) {
      calcularLiquidaciones(modalData);
    }
    if(modalData.liquidacionCalculada){
      setBotonGuardarHabilitado(false)
    }
  }, [dispatch, modalData]);

  const [input, setInput] = useState({
    id: "",
    rut: "",
    trabajadorId: "",
    nombre: "",
    apPaterno: "",
    apMaterno: "",
    centroCosto: "",
    centroCostoId: "",
    diasTrabajados: "",
    diasAusencia: "",
    horasExtra: "",
    horasDescuento: "",
    periodo: "",
    horasExtraId: "",
    totalImponible: "",
    totalTributable: "",
    totalNoImponible: "",
    totalNoTributable: "",
    totalHaberes: "",
    totalDescuentos: "",
    liquidoAPagar: "",
    saldoNegativo: "",
  });

  async function grabarLiquidacion() {
    if(modalData.liquidacionCalculada){
      toast.dismiss()
      toast.error("Liquidación ya calculada")
      return null
    }
    setBotonGuardarHabilitado(false)
    try {
      toast.loading("Guardando Liquidación", estilosAlerta);
      // const payload = {
      //   periodoId: periodoId,
      //   centroCostoIds: [input.centroCostoId],
      //   trabajadores: [
      //     {
      //       trabajadorId: input.trabajadorId,
      //       centroCostoId: input.centroCostoId,
      //     },
      //   ],
      // };
      const data = {
        periodoId: periodoId,
        trabajadores: [
          {
            trabajadorId: input.trabajadorId,
          },
        ],
        empresaId: empresaId
      };
      await axiosPrivate
        .post(
          `${api_service}/api/v1/trabajadores/liquidacion/masiva`,
          data,
          Authorization
        )
        .then((res) => {
          toast.dismiss();
          toast.success("Liquidación guardada correctamente", estilosAlerta);
          setRefreshTable(!refreshTable);
          onClose();
          setShowModalCalculoLiquidacion(false);
        });
        setBotonGuardarHabilitado(true)
    } catch (error) {
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al guardar liquidación",
          estilosAlerta
      );
      console.log("error", error);
      setBotonGuardarHabilitado(true)
    }
  }

  const COLUMNAS_HABERES = [
    {
      Header: "Nombre",
      accessor: "nombre",
      Filter: ColumnFilter,
    },
    {
      Header: "Monto",
      accessor: (d) =>
        d.valor < 0
          ? "- $" + UtilsFunctions.integerFormatter(d.valor * -1)
          : "$" + UtilsFunctions.integerFormatter(d.valor),
      Filter: ColumnFilter,
    },
  ];

  const Columnas_Haberes_Descuentos_Nueva = [
    {
      Header: "Nombre",
      accessor: "nombre",
      Filter: ColumnFilter,
    },
    {
      Header: "Monto",
      accessor: (d) =>
        d.valor < 0
          ? "- $" + UtilsFunctions.integerFormatter(d.valor * -1)
          : "$" + UtilsFunctions.integerFormatter(d.valor),
      Filter: ColumnFilter,
    },
  ];

  const COLUMNAS_MOVIMIENTOS = [
    {
      Header: "Movimiento",
      accessor: "movimientoPersonal",
      Filter: ColumnFilter,
    },
    {
      Header: "Fecha inicio",
      accessor: "fechaMovimientoDesde",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value === null) {
          return "N/A";
        } else {
          return value;
        }
      },
    },
    {
      Header: "Fecha término",
      accessor: "fechaMovimientoHasta",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value === null) {
          return "N/A";
        } else {
          return value;
        }
      },
    },
    // {
    //   Header: "M. imponible M.A.",
    //   accessor: "baseImponibleMesAnterior",
    //   Filter: ColumnFilter,
    //   Cell: ({ value }) => {
    //     if (value === null) {
    //       return "0";
    //     } else {
    //       return value;
    //     }
    //   },
    // },
  ];

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <div className="primerDivModal">
        <div className="segundoDivModal max-w-6xl">
          {/*content*/}
          <div className="tercerDivModal shadow-lg  max-h-[85vh] ">
            {/*header*/}
            <div className="divHeaderModal border-slate-200">
              <h3 className="titulo-Modal">
                Calculo de liquidación individual
              </h3>
            </div>
            {/*body*/}
            <div
              className={`relative p-6 ${
                !loadingModal ? "overflow-hidden" : "overflow-auto"
              } flex-auto dark:bg-hercules-dark-bg-200`}
            >
              {/* {!loadingModal && <ModalSpinner />} */}

              {!loadingModal ? (
                <div className="h-fix">
                  <ModalSpinner />
                </div>
              ) : (
                <>
                    <h3 className="text-3xl font-normal leading-normal mb-3 dark:text-white text-hercules-light-font-secondary">
                    Datos del trabajador
                  </h3>
                  {/* Primera fila modal */}
                  <div className="md:flex md:flex-wrap">
                    <div className="w-full md:w-4/12 md:pr-3 mb-9">
                      <label
                        className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Trabajador
                      </label>
                      <input
                        disabled
                        value={`${input.nombre} ${input.apPaterno} ${input.apMaterno}`}
                        name="nombre"
                        id="nombre"
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                      />
                    </div>

                    <div className="w-full md:w-2/12 md:pl-3 mb-9">
                      <label
                        className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        RUT
                      </label>
                      <input
                        disabled
                        value={UtilsFunctions.rutFormatter(input.rut, {
                          dots: true,
                        })}
                        name="rut"
                        id="rut"
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                      />
                    </div>
                  </div>

                  <h3 className="text-3xl font-normal leading-normal mb-3 dark:text-white text-hercules-light-font-secondary">
                    Datos requeridos para calcular liquidación
                  </h3>

                  <div className="h-full md:flex rounded-lg">

                    {/* Tabla Haberes */}
                    <div className="w-full md:w-1/2 md:pr-3 mb-9">
                      <h3 className="md:pr-3 mb-2 dark:text-white text-hercules-light-font-secondary">
                        Haberes
                      </h3>
                      <SimpleTable
                        col={COLUMNAS_HABERES}
                        tableData={haberes}
                        exportCsvButton={false}
                        hasPagination={false}
                        searchBar={false}
                        showFilters={false}
                        tableHeight="h-56"
                        rowConditional={true}
                        rowConditionalFunc={(row) => row.monto !== 0}
                      />
                    </div>

                    {/* Tabla Descuentos */}
                    <div className="w-full md:w-1/2 md:pl-3 mb-9">
                      <h3 className="mb-2 dark:text-white text-hercules-light-font-secondary">
                        Descuentos
                      </h3>
                      <SimpleTable
                        col={COLUMNAS_HABERES}
                        tableData={descuentos}
                        exportCsvButton={false}
                        hasPagination={false}
                        searchBar={false}
                        showFilters={false}
                        tableHeight="h-56"
                        rowConditional={true}
                        rowConditionalFunc={(row) => row.monto !== 0}
                      />
                    </div>
                  </div>

                  {/* Tabla Movimientos */}
                  <div className="h-full w-full rounded-lg mb-9">
                    <h3 className="mb-2 dark:text-white text-hercules-light-font-secondary">
                      Movimientos
                    </h3>
                    {movimientos.length ? (
                      <SimpleTable
                        col={COLUMNAS_MOVIMIENTOS}
                        tableData={movimientos}
                        exportCsvButton={false}
                        hasPagination={false}
                        searchBar={false}
                        showFilters={false}
                        tableHeight="h-56"
                      />
                    ) : (
                      <p className=" dark:text-white text-hercules-light-font-secondary text-sm font-semibold mb-3">
                        Este trabajador no tiene movimientos registrados durante
                        este periodo
                      </p>
                    )}
                  </div>

                  <div className="relative mt-3 flex-auto">
                    <h3 className="text-3xl font-normal leading-normal mb-3 dark:text-white text-hercules-light-font-secondary">
                        Totales
                      </h3>
                      <div className="flex flex-wrap">
                        <div className="w-1/2 md:w-3/12 pr-3 mb-9">
                          <label
                            className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                            htmlFor="grid-last-name"
                          >
                            Total Imponible
                          </label>
                          <input
                            disabled
                            value={
                              "$" +
                              UtilsFunctions.integerFormatter(
                                input.totalImponible
                              )
                            }
                            name="totalImponible"
                            id="totalImponible"
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            type="text"
                          />
                        </div>

                        <div className="w-1/2 md:w-3/12 pl-3 md:pr-3 mb-9">
                          <label
                            className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                            htmlFor="grid-last-name"
                          >
                            Total Tributable
                          </label>
                          <input
                            disabled
                            value={
                              "$" +
                              UtilsFunctions.integerFormatter(
                                input.totalTributable
                              )
                            }
                            name="totalTributable"
                            id="totalTributable"
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            type="text"
                          />
                        </div>

                        <div className="w-1/2 md:w-3/12 pr-3 md:pl-3 mb-9">
                          <label
                            className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                            htmlFor="grid-last-name"
                          >
                            Total No Imponible
                          </label>
                          <input
                            disabled
                            value={
                              "$" +
                              UtilsFunctions.integerFormatter(
                                input.totalNoImponible
                              )
                            }
                            name="totalNoImponible"
                            id="totalNoImponible"
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            type="text"
                          />
                        </div>

                        <div className="w-1/2 md:w-3/12 pl-3 mb-9">
                          <label
                            className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                            htmlFor="grid-last-name"
                          >
                            Total No Tributable
                          </label>
                          <input
                            disabled
                            value={
                              "$" +
                              UtilsFunctions.integerFormatter(
                                input.totalNoTributable
                              )
                            }
                            name="totalNoTributable"
                            id="totalNoTributable"
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            type="text"
                          />
                        </div>
                      </div>

                      <h3 className="text-3xl font-normal leading-normal mb-3 dark:text-white text-hercules-light-font-secondary">
                        Resumen
                      </h3>

                      <div className="flex flex-wrap">
                        <div className="w-1/2 md:w-3/12 pr-3 mb-9">
                          <label
                            className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                            htmlFor="grid-last-name"
                          >
                            Total Haberes
                          </label>
                          <input
                            disabled
                            value={
                              "$" +
                              UtilsFunctions.integerFormatter(
                                input.totalHaberes
                              )
                            }
                            name="totalHaberes"
                            id="totalHaberes"
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            type="text"
                          />
                        </div>

                        <div className="w-1/2 md:w-3/12 pl-3 md:pr-3 mb-9">
                          <label
                            className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                            htmlFor="grid-last-name"
                          >
                            Total Descuentos
                          </label>
                          <input
                            disabled
                            value={
                              "$" +
                              UtilsFunctions.integerFormatter(
                                input.totalDescuentos
                              )
                            }
                            name="totalDescuentos"
                            id="totalDescuentos"
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            type="text"
                          />
                        </div>

                        <div className="w-1/2 md:w-3/12 pr-3 md:pl-3 mb-6">
                          <label
                            className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                            htmlFor="grid-last-name"
                          >
                            Líquido A Pagar
                          </label>
                          <input
                            disabled
                            value={
                              "$" +
                              UtilsFunctions.integerFormatter(
                                input.liquidoAPagar
                              )
                            }
                            name="liquidoAPagar"
                            id="liquidoAPagar"
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            type="text"
                          />
                        </div>

                        <div className="w-1/2 md:w-3/12 pl-3 mb-6">
                          <label
                            className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                            htmlFor="grid-last-name"
                          >
                            Saldo Negativo
                          </label>
                          <input
                            disabled
                            value={
                              "$" +
                              UtilsFunctions.integerFormatter(
                                input.saldoNegativo
                              )
                            }
                            name="saldoNegativo"
                            id="saldoNegativo"
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            type="text"
                          />
                        </div>
                      </div>
                  </div>
                </>
              )}
            </div>

            {/*footer*/}
            <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                type="button"
                onClick={() => {
                  onClose();
                  setShowModalCalculoLiquidacion(false);
                }}
              >
                Cerrar
              </button>
              <button
                disabled={!botonGuardarHabilitado}
                className={` ${!botonGuardarHabilitado? "bg-gray-500" : "bg-hercules-light-primary-button hover:shadow-lg hover:scale-105"}  text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400`}
                type="button"
                onClick={() => grabarLiquidacion()}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
