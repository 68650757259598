import React, { useState } from 'react'
import { useAsyncDebounce } from 'react-table' 

const ColumnFilterBloqueadoActivo = ({ column }) => {
    const { filterValue, setFilter } = column
    const [value, setValue] = useState(filterValue)
    const onChange = useAsyncDebounce(value => {
        setFilter(value || undefined)
    }, 400)

  return (
    <>
        {/* Search:{' '} */}
        <select className='w-15 font-normal  bg-white  rounded' value={value || ""} placeholder=" Filtrar..." onChange={(e) => {
            setValue(e.target.value)
            onChange(e.target.value)
        }}>
            <option value="">Todos</option>
            <option value="true">Activo</option>
            <option value="false">Bloqueado</option>
        </select>
    </>
  )
}

export default ColumnFilterBloqueadoActivo