import React from "react";
import UtilsFunctions from "../../../utils/UtilsFunctions";

export default function ModalErrorActualizarJornadaContrato({
    erroresRecibidos,
    isVisible,
    onClose,
}) {

function modalBody(errores) {
    return (
      <>
        <div>
          <p className="text-lg">
            Los siguientes RUT presentaron errores:
          </p>
          <div className="mt-6">
            {errores.map((trabajador) => {
              return (
                <div className="mb-6">
                  <p><b>RUT:</b> {UtilsFunctions.rutFormatter(trabajador.rut, {dots: true})} </p>
                  <p><b>Error:</b> {trabajador.error}</p>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  }

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <div className="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center">
        <div className="relative my-20 mx-auto max-w-xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col max-h-[85vh] overflow-y-auto w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t dark:bg-hercules-dark-bg-100 ">
              <h3 className="text-3xl text-hercules-light-font-secondary font-semibold leading-normal dark:text-white">
              Error al actualizar Haberes
            </h3>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto overflow-auto dark:bg-hercules-dark-bg-200">
                {/* Primera fila modal */}
                <div className="mb-4 text-slate-500 text-lg leading-relaxed dark:text-white text-hercules-light-font-secondary">
                      {modalBody(erroresRecibidos)}
                </div>
            {/* <h4 className="text-sm pr-8 pb-1 md:ml-auto dark:text-white text-hercules-light-font-secondary font-semibold">
              Error {pagina} de {erroresRecibidos?.length}
            </h4> */}
            </div>

            {/*footer*/}
            <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                type="button"
                onClick={() => {
                  onClose();
                }}
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
