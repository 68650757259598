import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getJornadas, hideLoader, limpiarInfoMenuEdicion, showLoader } from "../../Redux/Actions";
import { useStateContext } from "../../contexts/ContextProvider";
import {
  EditarJornada,
  ColumnFilter,
  NuevaJornada,
} from "../../components";
import UtilsFunctions from "../../utils/UtilsFunctions";
import SimpleTable from "../../components/SimpleTable";
import ColumnFilterOptions from "../../components/ColumnFilterOptions";

export default function MantenedorJornadas2() {
  const dispatch = useDispatch();
  const { refreshTable, activeMenu, rowDataJornadas, setRowDataJornadas } =
    useStateContext();
  const [showModalNuevaJornada, setShowModalNuevaJornada] = useState(false);
  const [showModalEditarJornadas, setShowModalEditarJornadas] = useState(false);
  const jornadas = useSelector((state) => state.getJornadas);
  const isLoading = useSelector((state) => state.loader);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader());
      await Promise.all([
        dispatch(getJornadas()),
          ]);
      dispatch(hideLoader());
    };
    fetchData().catch((error) => {
      console.log(error.response.data.message);
      dispatch(hideLoader());
    });
  }, [refreshTable, dispatch])


  useEffect(() =>{
    return () => {
      dispatch(limpiarInfoMenuEdicion("getJornadas"))
      dispatch(limpiarInfoMenuEdicion("getTipoTrabajador"));
      dispatch(limpiarInfoMenuEdicion("getHsExtraActivadas"));
      dispatch(limpiarInfoMenuEdicion("sueldoMinimoPeriodo"));
    }
  },[])

  const COLUMNS = [
    {
      Header: "Cantidad de horas semanales",
      accessor: (d) => d.horasSemana + " horas",
      Filter: ColumnFilter,
    },
    {
      Header: "Nombre",
      accessor: "nombre",
      Filter: ColumnFilter,
    },
    {
      Header: "Tipo de trabajador",
      accessor: "tipoTrabajador.nombre",
      Filter: ColumnFilter,
    },
    {
      Header: "Factor Hora Extra",
      accessor: (d) =>
        ((7 * d.tipoHorasExtra.recargo) / 30 / d.horasSemana)
          .toFixed(4)
          .replace(".", ","),
      Filter: ColumnFilter,
    },
    {
      Header: "Habilitado",
      accessor: "activo",
      Filter: ColumnFilterOptions,
      Cell: ({ value }) => {
        if (value === true) {
          return "Si";
        } else {
          return "No";
        }
      },
    },
  ];

  const headersCsv = [
    {
      key: "horasSemana",
      name: "Cantidad de horas semanales",
    },
    {
      key: "nombre",
      name: "Nombre Recargo",
    },
    {
      key: "tipoTrabajador.nombre",
      name: "Tipo de trabajador",
    },
    {
      key: "tipoHorasExtra.recargo",
      name: "Factor Hora Extra",
    },
    {
      key: "activo",
      name: "Activo",
    },
  ];

  return (
    <div
    className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
      activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">
        Jornadas
      </div>
      <div className="titulo-Pagina">
        Mantenedor de Jornadas
      </div>
      <div className="h-full md:mr-12 rounded-lg pt-5">
          <SimpleTable
            col={COLUMNS}
            tableData={jornadas || []}
            primaryButton={{
              type: "editar",
              onClickFunc: (data) => {
                setShowModalEditarJornadas(true);
                setRowDataJornadas(data);
              },
              active: true,
            }}
            exportCsv={() =>
              UtilsFunctions.jsonToCSV3(jornadas, headersCsv, "Jornadas Laborales")
            }  
          />

        <button
          onClick={() => setShowModalNuevaJornada(true)}
          disabled={isLoading}
          className={`mt-3 ${isLoading? "bg-gray-500" : "bg-hercules-light-primary-button hover:shadow-lg"} text-white dark:text-dark-primary font-medium uppercase text-normal px-4 py-3 rounded shadow  outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}  
      >
          Nueva Jornada
        </button>
      </div>
        {showModalEditarJornadas &&
        <EditarJornada
          isVisible={showModalEditarJornadas}
          onClose={() => setShowModalEditarJornadas(false)}
          modalData={rowDataJornadas}
        />
      }
      {showModalNuevaJornada &&
      <NuevaJornada
        isVisible={showModalNuevaJornada}
        onClose={() => setShowModalNuevaJornada(false)}
      />
    }
    </div>
  );
}
