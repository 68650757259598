import React from "react";
import { useStateContext } from "../../../contexts/ContextProvider";
import { axiosPrivate } from "../../../common/axiosPrivate";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
export default function AgregarSemanaCorrida({
  isVisible,
  onClose,
  modalData,
}) {
  const {
    register,
    reset,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const { refreshTable, setRefreshTable, screenSize } = useStateContext();
  const periodoId = localStorage.getItem("periodoId");

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const validacion = {
    required: { value: true, message: "Obligatorio" },
  };

  async function handleAgregarSemanaCorrida() {
    try {
      toast.loading("Agregando Semana corrida", estilosAlerta);
      const data = getValues();
      const payload = {
        contratoAnexoId: modalData,
        diasHabiles: data.diasHabiles,
        diasFeriados: data.diasFeriados,
        periodoId: periodoId,
      };

      const info = await axiosPrivate.post(
        `${api_service}/api/v1/contratos/semanaCorrida`,
        payload,
        Authorization
      );
      toast.dismiss();
      toast.success(info.data, estilosAlerta);
      setRefreshTable(!refreshTable);
      reset();
      onClose();
    } catch (error) {
      toast.dismiss();
      console.log(error);
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al agregar Semana corrida",
          estilosAlerta
      );
    }
  }

  if (!isVisible) {
    return null;
  }

  // console.log("watch", watch());

  return (
    <>
      <div className="primerDivModal">
        <div className="segundoDivModal max-w-lg">
          {/*content*/}
          <div className="tercerDivModal shadow-lg  max-h-[85vh] ">
            <form
              className="w-full"
              onSubmit={handleSubmit(handleAgregarSemanaCorrida)}
            >
              {/*header*/}
              <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t dark:bg-hercules-dark-bg-100 ">
                <h3 className="text-3xl text-hercules-light-font-secondary font-semibold leading-normal dark:text-white">
                  Semana Corrida
                </h3>
              </div>

              {/*body*/}
              <div className="relative p-6 pb-0 flex-auto overflow-auto dark:bg-hercules-dark-bg-200">
                {/* Primera fila */}
                <label
                  className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                  htmlFor="diasHabiles"
                >
                  Días Habiles
                </label>
                <div className="flex flex-wrap mb-6 ">
                  <div className="w-3/12 md:w-2/12 pr-3 ">
                    <input
                      {...register("diasHabiles", {
                        validate: (value) => {
                          if (value === "" || value < 0) {
                            return "Igual o mayor a 0";
                          }
                          return true;
                        },
                        ...validacion,
                      })}
                      className="appearance-none w-full md:w-16 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 pl-4 pr-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="number"
                      step="1"
                      min="0"
                    />
                  </div>

                  <div className="w-9/12 md:w-10/12 pl-3 ">
                    <p className="dark:text-bluegray-300 text-hercules-light-font-secondary">
                      Ingrese la cantidad de dias hábiles del mes (contabilice
                      los días sábados si es que se trabaja).
                    </p>
                    <span
                      className={`text-red-500 text-xs italic ${
                        !errors.diasHabiles && "opacity-0 invisible"
                      }`}
                    >
                      {errors.diasHabiles
                        ? errors.diasHabiles.message
                        : "hidden"}
                    </span>
                  </div>
                </div>

                {/* Segunda fila */}
                <label
                  className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                  htmlFor="diasFeriados"
                >
                  Dias Feriados
                </label>

                <div className="flex flex-wrap mb-6 ">
                <div className="w-3/12 md:w-2/12 pr-3 ">
                    <input
                      {...register("diasFeriados", {
                        validate: (value) => {
                          if (value === "" || value < 0) {
                            return "Igual o mayor a 0";
                          }
                          return true;
                        },
                        ...validacion,
                      })}
                      className="appearance-none w-full md:w-16 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 pl-4 pr-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="number"
                      step="1"
                      min="0"
                    />
                  </div>

                  <div className="w-9/12 md:w-10/12 pl-3 ">
                    <p className="dark:text-bluegray-300 text-hercules-light-font-secondary">
                      Ingrese la cantidad de dias domingos y festivos del mes
                      (los días feriados que sean tambien domingo contabilicelos
                      como un solo día).
                    </p>
                    <span
                      className={`text-red-500 text-xs italic ${
                        !errors.diasFeriados && "opacity-0 invisible"
                      }`}
                    >
                      {errors.diasFeriados
                        ? errors.diasFeriados.message
                        : "hidden"}
                    </span>
                  </div>
                </div>
              </div>

              {/*footer*/}
              <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                  type="button"
                  onClick={() => {
                    onClose();
                    reset();
                  }}
                >
                  Cerrar
                </button>
                <button
                  className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
                  type="submit"
                >
                  Guardar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
