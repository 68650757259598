import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmpresas,
  limpiarInfoMenuEdicion,
} from "../../../Redux/Actions";
import { toast } from "react-hot-toast";
import { axiosPrivate } from "../../../common/axiosPrivate";
import { useStateContext } from "../../../contexts/ContextProvider";
import ModalSpinner from "../../ModalSpinner";
import ModalSpinnerEspecificoSeccionArea from "../../ModalSpinnerEspecificoSeccionArea";

const EditarSeccion = ({ isVisible, onClose, modalData }) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: "",
      nombre: "",
      descripcion: "",
      habilitado: true,
      empresa: "",
      centrosCosto: [],
    },
  });

  const { refreshTable, setRefreshTable, screenSize } = useStateContext();
  const [botonGuardarHabilitado, setBotonGuardarHabilitado] = useState(true)

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const dispatch = useDispatch();

  const validacionTextInput = {
    required: { value: true, message: "Obligatorio" },
  };

  useEffect(() => {
    dispatch(getEmpresas());
    setValue("id", modalData.id);
    setValue("nombre", modalData.nombre);
    setValue("descripcion", modalData.descripcion);
    setValue("habilitado", modalData.activo);
    setValue(
      "empresa",
      modalData.seccionesHasCentrosCostos[0].centroCosto.empresa.id
    );
    setValue(
      "centrosCosto",
      modalData.seccionesHasCentrosCostos.map((e) =>
        e.centroCosto.id.toString()
      )
    );
    setInput({
      habilitado: modalData.activo
    })
    getCentrosCostosByEmpresaId(
      modalData.seccionesHasCentrosCostos[0].centroCosto.empresa.id
    );
  }, [dispatch, modalData]);

  const empresas = useSelector((state) => state.empresas);

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("empresas"));
      setDespachoCentrosDeCosto(false);
      setCambioEmpresa(false);
      reset({});
    };
  }, []);

  const [centrosDeCostoEmpresa, setCentrosDeCostoEmpresa] = useState([]);
  const [despachoCentrosDeCosto, setDespachoCentrosDeCosto] = useState(false);
  const [cambioEmpresa, setCambioEmpresa] = useState(false);

  async function getCentrosCostosByEmpresaId(empresaId) {
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");

    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`,
        ClientId: `${token}`,
      },
    };

    try {
      const centrosDeCosto = await axiosPrivate.get(
        `${api_service}/api/v1/empresas/${empresaId}/centros-de-costo`,
        Authorization
      );
      setCentrosDeCostoEmpresa(centrosDeCosto.data);
      setDespachoCentrosDeCosto(true);
      setCambioEmpresa(false);
    } catch (error) {
      console.log(error);
    }
  }

  const [input, setInput] = useState({
    habilitado: true,
  });

  const validateAtLeastOneCentroCostoSelected = (value) => {
    return value.length > 0;
  };

  const onSubmit = async (data) => {
    setBotonGuardarHabilitado(false)
    toast.loading("Editando Sección/Área", estilosAlerta);
    if (centrosDeCostoEmpresa.length === 0) {
      toast.dismiss();
      toast.error(
        "Debe seleccionar al menos un Centro de Costo. Si la empresa seleccionada no tiene ninguno, debe crearle uno y tenerlo habilitado.", estilosAlerta
      );
      setBotonGuardarHabilitado(true)
      return;
    }
    const payload = {
      id: data.id,
      nombre: data.nombre,
      descripcion: data.descripcion,
      activo: input.habilitado,
      centrosCosto: Array.isArray(data.centrosCosto)
        ? data.centrosCosto
        : [data.centrosCosto],
    };
    await axiosPrivate
      .put(
        `${api_service}/api/v1/empresas/editarseccion`,
        payload,
        Authorization
      )
      .then((response) => {
        toast.dismiss();
        toast.success("Sección/Área editada con éxito", estilosAlerta);
        setRefreshTable(!refreshTable);
        setCentrosDeCostoEmpresa([]);
        onClose();
        reset();
        setBotonGuardarHabilitado(true)
      })
      .catch((error) => {
        console.log(error);
        toast.dismiss();
        toast.error(error.response.data.message? error.response.data.message : "Error al editar Sección/Área", estilosAlerta);
        setBotonGuardarHabilitado(true)
      });
  };

  const loadingModal = empresas?.length > 0 && despachoCentrosDeCosto;

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
      <div className="primerDivModal">
          <div className="segundoDivModal max-w-lg">
            {/*content*/}
            <div className="tercerDivModal shadow-lg  max-h-[85vh] ">
              {/*header*/}
              <div className="divHeaderModal border-slate-200">
                <h3 className="titulo-Modal">
                  Sección/Área
                </h3>
              </div>
              {/*body*/}
              <div className="relative p-6 flex-auto overflow-auto dark:bg-hercules-dark-bg-200">
                {!loadingModal ? (
                  <div className="h-fix">
                    <ModalSpinner />
                  </div>
                ) : (
                  <>
                      <div className="div-Inputs-EditarHaber">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-first-name"
                        >
                          Nombre
                        </label>
                        <input
                          {...register("nombre", validacionTextInput)}
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                        />
                        <span
                          className={`text-red-500 text-xs italic ${
                            !errors.nombre && "opacity-0 invisible"
                          }`}
                        >
                          {errors.nombre ? errors.nombre.message : "hidden"}
                        </span>
                      </div>

                      <div className="div-Inputs-EditarHaber">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-first-name"
                        >
                          Descripción
                        </label>
                        <input
                          {...register("descripcion", validacionTextInput)}
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                        />
                        <span
                          className={`text-red-500 text-xs italic ${
                            !errors.descripcion && "opacity-0 invisible"
                          }`}
                        >
                          {errors.descripcion ? errors.descripcion.message : "hidden"}
                        </span>
                      </div>

                      <div className="div-Inputs-EditarHaber">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Empresa
                        </label>
                        <select
                          {...register("empresa", {
                            onChange: (e) => {
                              setCambioEmpresa(true);
                              getCentrosCostosByEmpresaId(e.target.value);
                              setValue("centrosCosto", []);
                            },
                          })}
                          name="empresa"
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        >
                          {empresas.map((empresa) => {
                            return (
                              <option key={empresa.id} value={empresa.id}>
                                {empresa.nombreFantasia}
                              </option>
                            );
                          })}
                        </select>
                        <span
                          className={`text-red-500 text-xs italic ${
                            !errors.empresa && "opacity-0 invisible"
                          }`}
                        >
                          {errors.empresa ? errors.empresa.message : "hidden"}
                        </span>
                      </div>

                    {cambioEmpresa ? (
                      <div className="py-6">
                        <ModalSpinnerEspecificoSeccionArea />
                      </div>
                    ) : centrosDeCostoEmpresa?.length > 0 && watch("empresa") !== "" ? (
                      <div className="w-full pb-1">
                      <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                        Centros de costo:
                            </label>
                            <div className="border-l-2 border-t-2 border-r-2 border-b-2 h-32 overflow-y-auto px-3">
                              {centrosDeCostoEmpresa?.map((centroCosto) => {
                                return (
                                  <div
                                    className="py-1 border-b-2 dark:text-white text-hercules-light-font-secondary"
                                    key={centroCosto.id}
                                  >
                                    <input
                                      type="checkbox"
                                      className="mr-2"
                                      value={centroCosto.id}
                                      {...register(`centrosCosto`, {
                                        validate:
                                          validateAtLeastOneCentroCostoSelected,
                                      })}
                                    />
                                    <label className="pl-3">
                                      {centroCosto.nombre}
                                    </label>
                                  </div>
                                );
                              })}
                            </div>
                        <span
                          className={`text-red-500 text-xs italic ${
                            !errors.centrosCosto && "opacity-0 invisible"
                          }`}
                        >
                          Debe seleccionar al menos un centro de costo
                        </span>
                          </div>
                        ) : (
                          watch("empresa") !== "" ? (
                            <div className="w-full mb-6">
                              <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
                                No hay centros de costo asociados a la empresa
                                seleccionada
                              </label>
                              </div>
                          ) : null
                        )
                    }

                    <div className="flex flex-wrap">
                      <span className="uppercase dark:text-white tracking-wide text-hercules-light-font-secondary text-xs font-bold my-auto mr-10 mb-1 md:mb-0 ">
                          Activo
                        </span>
                        <label className="inline-flex relative items-center cursor-pointer">
                          <input
                            type="checkbox"
                            className="sr-only peer"
                            checked={input.habilitado}
                            readOnly
                          />

                          <div
                            onClick={() =>
                              setInput({
                                ...input,
                                habilitado: !input.habilitado,
                              })
                            }
                            className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"
                          ></div>
                        </label>
                      </div>
                  </>
                )}
              </div>
              {/*footer*/}
              <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                  type="button"
                  onClick={() => {
                    onClose();
                    setCentrosDeCostoEmpresa([]);
                    reset();
                  }}
                >
                  Cerrar
                </button>
                <button
                  disabled={!botonGuardarHabilitado}
                  className={` ${!botonGuardarHabilitado? "bg-gray-500" : "bg-hercules-light-primary-button hover:shadow-lg hover:scale-105"}  text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400`}
                  type="submit"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </form>
    </>
  );
};

export default EditarSeccion;
