import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useStateContext } from "../../../contexts/ContextProvider";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useFilters,
  useRowSelect,
} from "react-table";
import {
  TbArrowsSort,
  TbSortAscending,
  TbSortDescending,
} from "react-icons/tb";
import { MdDelete } from "react-icons/md";

export default function TablaModalAsignarDescuentos({
  col,
  tableData,
  botonEliminarDescuento,
  eliminarDescuentoFijo,
}) {

  const columns = useMemo(() => col, []);
  var data = useMemo(() => [...tableData], [tableData]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      useRowSelect
    );

  return (
    <>
      <div>
        {/* {isLoading ? (
            <div className="pt-6">
              <SpinnerRevisarHabDesc />
            </div>
          ) : ( */}
        {/* <HaberesDescuentosTable
          col={COLUMNAS_HABERES_ADICIONALES}
          tableData={haberesAdicionalesTabla}
          botonEliminar={true}
          borrarHaberDescuento={(haberPeriodoId, index) => {
            eliminarHaber(haberPeriodoId, index);
          }}
        /> */}
        {/* )} */}
      </div>

      <div className="border-b-1 rounded-md h-52 overflow-auto border-x-1  bg-white dark:bg-hercules-dark-bg-200  dark:border-dark-bg-200 border-gray-300 border-t-1">
        <table
          key="tableKey"
          {...getTableProps()}
          className="w-full  rounded-lg"
        >
          <thead className="text-center sticky top-0 border-b-1 border-b-hercules-light-secondary dark:border-b-hercules-dark-secondary-button bg-hercules-light-table-header dark:bg-hercules-dark-bg-100">
            {headerGroups.map((headerGroup, i) => {
              const { key, ...restHeaderGroup } =
                headerGroup.getHeaderGroupProps();
              return (
                <React.Fragment key={i}>
                  <tr {...restHeaderGroup} className="h-10 flex-auto">
                    {headerGroup.headers.map((column, i) => {
                      const { key, ...restColumn } = column.getHeaderProps(
                        column.getSortByToggleProps()
                      );
                      return (
                        <React.Fragment key={i}>
                          <th
                            {...restColumn}
                            className="py-2 px-4 text-hercules-light-font-secondary font-medium dark:text-white"
                          >
                            <div className="flex">
                              <span className="">
                                {column.render("Header")}
                              </span>
                              <span className="ml-1 mt-auto mb-auto">
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <TbSortDescending />
                                  ) : (
                                    <TbSortAscending />
                                  )
                                ) : (
                                  <TbArrowsSort />
                                )}
                              </span>
                            </div>
                          </th>
                        </React.Fragment>
                      );
                    })}
                    {botonEliminarDescuento && (
                      <th className="px-3 text-black font-medium dark:text-white">
                        Eliminar
                      </th>
                    )}

                  </tr>
                </React.Fragment>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()} className="h-full ">
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  key={i}
                  {...row.getRowProps()}
                  className="group bg-white dark:bg-hercules-dark-bg-200 dark:text-dark-text-100 dark:border-b-hercules-dark-secondary-button border-b border-hercules-light-secondary text-black h-12 dark:hover:bg-white dark:hover:bg-opacity-10 transition duration-100 ease-in-out hover:bg-light-accent hover:bg-opacity-20"
                >
                  {row.cells.map((cell, i) => {
                    return (
                      <td
                        key={i}
                        {...cell.getCellProps}
                        className="py-2 px-4 font-normal"
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                  {botonEliminarDescuento && (
                    <td
                      style={{
                        padding: "2px 4px",
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                      className="text-14 mx-6 font-normal"
                    >
                      <div
                        style={{
                          display: "inline-block",
                          verticalAlign: "middle",
                        }}
                      >
                        <MdDelete
                          className="text-2xl group-hover:cursor-pointer text-red-500"
                          onClick={() => {
                            console.log(row.original);
                            eliminarDescuentoFijo(row.original.id);
                          }}
                        />
                      </div>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
