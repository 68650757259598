import React, { useState } from "react";
import FichaTrabajadorPaginaUno from "./FichaTrabajadorPaginaUno";
import FichaTrabajadorPaginaDos from "./FichaTrabajadorPaginaDos";
import ModalAPVsCajaDos from "../EditarTrabajador/ModalAPVs&Caja2";

const NuevoTrabajadorMantenedor = ({ isVisible, onClose }) => {
  const [pagina, setPagina] = useState("1");
  const [trabajadorData, setTrabajadorData] = useState(null);
  const [apvs, setApvs] = useState(null);

  const handleChangePage = (trabajadorData, page, apvs) => {
    setTrabajadorData(trabajadorData);
    setApvs(apvs);
    setPagina(page);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <>
      {pagina === "1" ? (
        <FichaTrabajadorPaginaUno
          onClose={() => onClose()}
          changePage={(trabajadorData, page, apvs) =>
            handleChangePage(trabajadorData, page, apvs)
          }
        />
      ) : null}
      {pagina === "2" ? (
        <FichaTrabajadorPaginaDos
          onClose={() => {
            onClose();
            setPagina("1");
          }}
          institucionesApv={apvs}
          datosTrabajador={trabajadorData}
          changePage={(trabajadorData, page, apvs) => handleChangePage(trabajadorData, page, apvs)}
        />
      ) : null}

      {pagina === "3" ? (
        <ModalAPVsCajaDos
          onClose={() => {
            onClose();
            setPagina("1");
          }}
          institucionesApv={apvs}
          datosTrabajadorEditar={trabajadorData}
          changePage={(trabajadorData, page, apvs) => handleChangePage(trabajadorData, page, apvs)}
        />
      ) : null}
    </>
  );
};

export default NuevoTrabajadorMantenedor;
