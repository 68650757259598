import { axiosPrivate } from "../../../common/axiosPrivate";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStateContext } from "../../../contexts/ContextProvider";
import {
  getTipoTrabajador,
  getSueldoMinimoPeriodo,
  getHsExtraActivadas,
} from "../../../Redux/Actions";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import UtilsFunctions from "../../../utils/UtilsFunctions";
import ModalSpinner from "../../ModalSpinner";

export default function NuevaJornada({ isVisible, onClose }) {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      jornadaId: "",
      nombre: "",
      horasSemana: "",
      tipoTrabajadorId: "",
    },
  });

  const [botonGuardarHabilitado, setBotonGuardarHabilitado] = useState(true)
  const tipoTrabajadorIdWatch = watch("tipoTrabajadorId", "");
  const horasSemanaWatch = watch("horasSemana", "45");
  const [sueldoMinimoTrabajador, setSueldoMinimoTrabajador] = useState(null);
  const { refreshTable, setRefreshTable, screenSize } = useStateContext();
  const [habilitado, setHabilitado] = useState(true);
  const tipoTrabajador = useSelector((state) => state.getTipoTrabajador);
  const horasExtra = useSelector((state) => state.getHsExtraActivadas);
  const sueldoMinimoPeriodo = useSelector((state) => state.sueldoMinimoPeriodo);

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const periodoId = localStorage.getItem("periodoId");

  const validacionTextInput = {
    required: { value: true, message: "Obligatorio" },
  };

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTipoTrabajador());
    dispatch(getHsExtraActivadas());
    dispatch(getSueldoMinimoPeriodo(periodoId));
  }, []);

  if (!isVisible) {
    return null;
  }

  function handleSueldoMinimoProporcional(e) {
    const sueldoMinimo = sueldoMinimoPeriodo?.find(
      (sueldo) => sueldo.tipoTrabajador.id == e.target.value
    )?.valor;
    console.log("sueldo minimo", sueldoMinimo);
    setSueldoMinimoTrabajador(sueldoMinimo);
    if (horasSemanaWatch !== "") {
      setValue(
        "sueldoProporcional",
        "$ " +
          UtilsFunctions.integerFormatter(
            UtilsFunctions.sueldoMinimoProporcional(
              sueldoMinimo,
              horasSemanaWatch,
              45
            )
          )
      );
    }
  }

  const onSubmit = async (data) => {
    setBotonGuardarHabilitado(false)
    try {
      toast.loading("Creando Jornada Laboral");
      const payload = {
        cantidadHoras: data.horasSemana,
        nombre: data.nombre,
        habilitado: data.activo,
        tipoTrabajadorId: data.tipoTrabajadorId,
        tipoHorasExtraId: data.recargoId,
      };
      await axiosPrivate.post(
        `${api_service}/api/v1/trabajadores/agregarjornada`,
        payload,
        Authorization
      );
      toast.dismiss();
      toast.success("Jornada Laboral creada con éxito", estilosAlerta);
      onClose();
      setRefreshTable(!refreshTable);
      reset({});
      setBotonGuardarHabilitado(true)
    } catch (error) {
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al crear Jornada Laboral",
          estilosAlerta
      );
      console.log(error);
      setBotonGuardarHabilitado(true)
    }
  };

  const loadingModal =
  tipoTrabajador?.length > 0 &&
  horasExtra?.length > 0 &&
  sueldoMinimoPeriodo?.length > 0;

  return (
    <>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <div className="primerDivModal">
          <div className="segundoDivModal max-w-lg">
            {/*content*/}
            <div className="tercerDivModal shadow-lg  max-h-[85vh] ">
              {/*header*/}
              <div className="divHeaderModal border-slate-200">
              <h3 className="titulo-Modal">
                  Nueva Jornada
                </h3>
              </div>
              {/*body*/}
              <div className={`relative p-6 flex-auto dark:bg-hercules-dark-bg-200 ${!loadingModal ? "overflow-hidden" : "overflow-auto"}`}>
              {!loadingModal ? (
                  <div className="h-fix">
                    <ModalSpinner />
                  </div>
                ) : (
                  <>
                  <div className="div-Inputs-EditarHaber">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      Nombre
                    </label>
                    <input
                      {...register("nombre", validacionTextInput)}
                      className=" appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                    />
                    <span
                      className={`text-red-500 text-xs italic ${
                        !errors.nombre && "opacity-0 invisible"
                      }`}
                    >
                      {errors.nombre ? errors.nombre.message : "hidden"}
                    </span>
                  </div>

                  <div className="div-Inputs-EditarHaber">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-first-name"
                    >
                      Cantidad de horas a la semana
                    </label>
                    <input
                      {...register("horasSemana", {
                        ...validacionTextInput,
                        min: { value: 0, message: "Valor mínimo 0" },
                        max: { value: 45, message: "Valor máximo 45" },
                        onChange: (e) => {
                          if (tipoTrabajadorIdWatch !== "") {
                            setValue(
                              "sueldoProporcional",
                              "$ " +
                                UtilsFunctions.integerFormatter(
                                  UtilsFunctions.sueldoMinimoProporcional(
                                    sueldoMinimoTrabajador,
                                    e.target.value,
                                    45
                                  )
                                )
                            );
                          }
                        },
                      })}
                      defaultValue="45"
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="number"
                      step=".1"
                      min="0"
                      max="45"
                    />
                    <span
                      className={`text-red-500 text-xs italic ${
                        !errors.horasSemana && "opacity-0 invisible"
                      }`}
                    >
                      {errors.horasSemana
                        ? errors.horasSemana.message
                        : "hidden"}
                    </span>
                  </div>

                  <div className="div-Inputs-EditarHaber">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      Tipo Trabajador
                    </label>
                    <select
                      {...register("tipoTrabajadorId", {
                        ...validacionTextInput,
                        validate: (value) => value !== "",
                        onChange: (e) => {
                          handleSueldoMinimoProporcional(e);
                        },
                      })}
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      <option hidden value="">
                        Seleccionar tipo de trabajador
                      </option>
                      {tipoTrabajador.map((tipoTrabajador, i) => (
                        <option
                          key={tipoTrabajador.id}
                          value={tipoTrabajador.id}
                        >
                          {tipoTrabajador.nombre}
                        </option>
                      ))}
                    </select>
                    <span
                      className={`text-red-500 text-xs italic ${
                        !errors.tipoTrabajadorId && "opacity-0 invisible"
                      }`}
                    >
                      {errors.tipoTrabajadorId
                        ? errors.tipoTrabajadorId.message
                        : "hidden"}
                    </span>
                  </div>

                  <div className="div-Inputs-EditarHaber">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      Sueldo Mínimo Proporcional
                    </label>
                    <input
                      {...register("sueldoProporcional")}
                      disabled
                      defaultValue={
                        "Seleccionar tipo trabajador y cantidad de horas semanales"
                      }
                      className="mb-9 appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                    />
                  </div>

                  <div className="div-Inputs-EditarHaber">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      Recargo
                    </label>
                    <select
                      {...register("recargoId", validacionTextInput)}
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      <option value="" hidden>
                        Seleccionar recargo
                      </option>
                      {horasExtra.map((el, index) => (
                        <option key={index} value={el.id}>
                          {el.nombre}
                        </option>
                      ))}
                    </select>
                    <span
                      className={`text-red-500 text-xs italic ${
                        !errors.recargoId && "opacity-0 invisible"
                      }`}
                    >
                      {errors.recargoId ? errors.recargoId.message : "hidden"}
                    </span>
                  </div>

                  <div className="flex flex-wrap mt-6">
                      <span className="uppercase dark:text-white tracking-wide text-hercules-light-font-secondary text-xs font-bold my-auto mr-10 mb-1 md:mb-0 ">
                        Habilitado
                      </span>
                      <label className="inline-flex relative items-center hover:cursor-pointer">
                        <input
                          {...register("activo")}
                          type="checkbox"
                          className="sr-only peer"
                          checked={habilitado}
                          readOnly
                        />
                        <div
                          onClick={() => {
                            setHabilitado(!habilitado);
                          }}
                          className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"
                        ></div>
                      </label>
                    </div>
                </>
                )}
              </div>
              {/*footer*/}
              <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                  type="button"
                  onClick={() => {
                    onClose();
                    reset();
                  }}
                >
                  Cerrar
                </button>
                <button
                  disabled={!botonGuardarHabilitado}
                  className={` ${!botonGuardarHabilitado? "bg-gray-500" : "bg-hercules-light-primary-button hover:shadow-lg hover:scale-105"}  text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400`}
                  type="submit"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </form>
    </>
  );
}
