import React, { useState, useEffect } from "react";
import ColumnFilter from "../../components/ColumnFilter";
import { useDispatch, useSelector } from "react-redux";
import { useStateContext } from "../../contexts/ContextProvider";
import {
  descuentosEnCuotasOProgramados,
  hideLoader,
  limpiarInfoMenuEdicion,
  showLoader,
  trabajadoresContratoVigente,
} from "../../Redux/Actions";
import UtilsFunctions from "../../utils/UtilsFunctions";
import TrabContratoVigenteTable from "../../components/Movimientos/DescuentoEnCuotas/TrabContratoVigenteTable";
import { axiosPrivate } from "../../common/axiosPrivate";
import ModalMotivoEliminarDescuento from "../../components/Movimientos/DescuentoEnCuotas/ModalMotivoEliminarDescuento";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import SimpleTable from "../../components/SimpleTable";
import ModalVerDescuentoEnCuotas from "../../components/Movimientos/DescuentoEnCuotas/VerDescuentoEnCuotas/ModalVerDescuentoEnCuotas";
import { useForm } from "react-hook-form";
import ModalMotivoLiquidarDescuento from "../../components/Movimientos/DescuentoEnCuotas/ModalMotivoLiquidarDescuento";
import RutColumnFilter from "../../components/RutColumnFilter";
import ColumnFilterVigenteLiquidada from "../../components/ColumnFilterVigenteLiquidada";
export default function DescuentoEnCuotas() {
  const {
    register,
    getValues,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      trabajadorId: "",
      montoCuota: "$0",
      montoTotal: 0,
      cantidadCuotas: 0,
    },
  });

  const dispatch = useDispatch();
  const {
    refreshTable,
    setRefreshTable,
    activeMenu,
    modalDataEliminarDescuento,
    setModalDataEliminarDescuento,
    modalDataLiquidarDescuento,
    setModalDataLiquidarDescuento,
    screenSize,
  } = useStateContext();
  const [showModalEliminarDescuento, setShowModalEliminarDescuento] =
    useState(false);
  const [showModalLiquidarDescuento, setShowModalLiquidarDescuento] =
    useState(false);
  const [showModalVerDescuentoEnCuotas, setShowModalVerDescuentoEnCuotas] =
    useState(false);
  const [modalData, setModalData] = useState([]);
  const [infoTablaCuotas, setInfoTablaCuotas] = useState([]);
  const [mesesPosterioresData, setMesesPosterioresData] = useState([]);
  const [radioSeleccionado, setRadioSeleccionado] = useState(false);
  const [botonGuardarHabilitado, setBotonGuardarHabilitado] = useState(true)

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const periodoMes = localStorage.getItem("periodoMes");
  const periodoAnyo = localStorage.getItem("periodoAnyo");
  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const validacionSelect = {
    required: { value: true, message: "Obligatorio" },
  };

  const formatter = new Intl.NumberFormat("es-CL", {
    styles: "currency",
    currency: "CLP",
    minimumFractionDigits: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader());
      await Promise.all([
        dispatch(trabajadoresContratoVigente()),
        dispatch(descuentosEnCuotasOProgramados()),
      ]);
      dispatch(hideLoader());
    };
    fetchData().catch((error) => {
      console.log(error.response.data.message);
      dispatch(hideLoader());
    });
  }, [dispatch, refreshTable]);

  useEffect(() => {
    const mesesPosteriores = getMesesPosteriores(periodoMes, periodoAnyo);
    setMesesPosterioresData(mesesPosteriores);
  }, []);

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("trabajadoresContratoVigente"));
      dispatch(limpiarInfoMenuEdicion("descuentosCuotasProgramados"));
      reset({});
    };
  }, []);

  const getMesesPosteriores = (periodoMes, periodoAnyo) => {
    const mesesPosteriores = [];
    let mes = periodoMes - 1;
    let anyo = periodoAnyo;

    for (let i = 0; i < 6; i++) {
      mes++;
      if (mes > 12) {
        mes = 1;
        anyo++;
      }
      mesesPosteriores.push(UtilsFunctions.integerToMonth(mes) + " - " + anyo);
    }
    return mesesPosteriores;
  };

  const listaTrabajadoresContratoVigente = useSelector(
    (state) => state.trabajadoresContratoVigente
  );
  const listaDescuentos = useSelector(
    (state) => state.descuentosCuotasProgramados
  );

  async function funcionVerDescuentoCuota(data) {
    try {
      const payload = {
        descuentoProgramadoId: data.id,
      };
      const response = await axiosPrivate.post(
        `${api_service}/api/v1/trabajadores/obtenerDetalleDescuentoProgramado`,
        payload,
        Authorization
      );
      setModalData(data);
      setInfoTablaCuotas(response.data);
      setShowModalVerDescuentoEnCuotas(true);
    } catch (error) {
      console.log("error", error);
    }
  }

  function eliminarDescuento(descuentoId) {
    setModalDataEliminarDescuento(descuentoId);
    setShowModalEliminarDescuento(true);
  }

  function liquidarDescuento(descuentoId) {
    if(descuentoId.liquidado){
      toast.dismiss()
      toast.error("Este descuento ya esta liquidado")
      return null
    }
    setModalDataLiquidarDescuento(descuentoId);
    setShowModalLiquidarDescuento(true);
  }

  async function crearDescuento() {
    if (watch("trabajadorId") === "") {
      toast.error("Debe seleccionar un trabajador", estilosAlerta);
      return null;
    }
    toast.loading("Creando Descuento en cuotas", estilosAlerta);
    const data = getValues();
    try {
      setBotonGuardarHabilitado(false)
      const numeroMes = UtilsFunctions.monthToInteger(
        data.mesSeleccionado.split(" ")[0]
      );
      const numeroAnyo = data.mesSeleccionado.split(" ")[2];

      const info = await axiosPrivate(
        `${api_service}/api/v1/periodos/mes/${numeroMes}/anyo/${numeroAnyo}`,
        Authorization
      );

      const payload = {
        trabajadorId: data.trabajadorId,
        periodoInicialId: info.data.id,
        montoCuota: data.montoCuota.replace(/\./g, "").replace("$", ""),
        numeroCuotas: data.cantidadCuotas,
        observacion: data.observacion,
        nombreDescuentoProgramado: data.nombreDescuentoProgramado,
      };

      console.log("payload", payload);
      await axiosPrivate.post(
        `${api_service}/api/v1/trabajadores/agregarDescuentoCuotas`,
        payload,
        Authorization
      );
      setRefreshTable(!refreshTable);
      reset();
      setRadioSeleccionado(true);
      toast.dismiss();
      toast.success("Descuento en cuotas creado con éxito", estilosAlerta);
      setBotonGuardarHabilitado(true)
    } catch (error) {
      console.log(error);
      setRefreshTable(!refreshTable);
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al crear Descuento en cuotas",
        estilosAlerta
      );
      setBotonGuardarHabilitado(true)
    }
  }

  const COLUMNS_TRABAJADOR = [
    {
      Header: "RUT",
      accessor: "rut",
      Cell: ({ value }) => UtilsFunctions.rutFormatter(value, { dots: true }),
      Filter: RutColumnFilter,
    },
    {
      Header: "Trabajador",
      accessor: (d) =>
        d.apellidoPaterno + " " + d.apellidoMaterno + " " + d.nombres,
      Filter: ColumnFilter,
    },
  ];

  const COLUMNS = [
    {
      Header: "RUT",
      accessor: "trabajadorRut",
      Cell: ({ value }) => UtilsFunctions.rutFormatter(value, { dots: true }),
      Filter: RutColumnFilter,
    },
    {
      Header: "Trabajador",
      accessor: (d) =>
        d.trabajadorApellidoPaterno +
        " " +
        d.trabajadorApellidoMaterno +
        " " +
        d.trabajadorNombre,
      Filter: ColumnFilter,
    },
    {
      Header: "Cuotas",
      accessor: "numeroCuotas",
      Filter: ColumnFilter,
    },
    {
      Header: "Cuota Inicial",
      accessor: (d) =>
        UtilsFunctions.periodoToText(d.periodoMes, d.periodoAnio),
      Filter: ColumnFilter,
    },
    {
      Header: "Monto",
      accessor: (d) => "$" + UtilsFunctions.integerFormatter(d.monto),
      Filter: ColumnFilter,
    },
    {
      Header: "Estado",
      accessor: "activo",
      Filter: ColumnFilterVigenteLiquidada,
      Cell: ({ value }) => {
        if (value === true) {
          return "Vigente";
        } else {
          return "Liquidadado";
        }
      },
    },
    {
      Header: "Nombre Descuento",
      accessor: "nombreDescuentoProgramado",
      Filter: ColumnFilter,
    },
    {
      Header: "Observación",
      accessor: "observacion",
      Filter: ColumnFilter,
    },
  ];
  
  const headersCsv = [
    { name: "RUT", key: "trabajadorRut" },
    { name: "Nombre Trabajador", key: "trabajadorNombre" },
    { name: "Cuotas", key: "numeroCuotas" },
    { name: "Cuota Inicial Mes", key: "periodoMes" },
    { name: "Cuota Inicial Anno", key: "periodoAnio" },
    { name: "Monto", key: "monto" },
    { name: "Estado", key: "activo" },
    { name: "Nombre Descuento", key: "nombreDescuentoProgramado" },
    { name: "Observacion", key: "observacion" },
  ];

  return (
    <div
      className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
        activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">Movimientos</div>
      <div className="titulo-Pagina">Descuento en cuotas</div>
      <div className="md:flex md:flex-row md:mr-12">
        <div className="h-full md:pr-4 rounded-lg pt-5 md:w-7/12 overflow-auto">
          <p className="text-xl text-hercules-light-font-secondary dark:text-dark-primary">
            Lista de Trabajadores:
          </p>
          <TrabContratoVigenteTable
            col={COLUMNS_TRABAJADOR}
            tableData={listaTrabajadoresContratoVigente}
            botonRadio={true}
            idSleccionado={(trabajadorId) => {
              setValue("trabajadorId", trabajadorId);
              setRadioSeleccionado(false);
            }}
            radioSeleccionado={radioSeleccionado}
          />
        </div>

        <div className="md:w-5/12 md:pl-4 rounded mt-5 md:mt-0">
          <div className="relative flex-auto">
            <h6 className="text-3xl font-light pb-2 dark:text-white text-hercules-light-font-secondary">
              Datos del descuento
            </h6>
            <p className="form-check-label pb-1 tracking-wide dark:text-white text-hercules-light-font-secondary text-sm">
              Acorde al periodo en el que inició sesion, podrá seleccionar este
              mes o los 5 meses posteriores.
            </p>

            <form
              className="w-full flex flex-wrap"
              onSubmit={handleSubmit(crearDescuento)}
            >
              <div className="w-full md:min-w-[130px] md:max-w-[200px] md:mb-2 mb-3 md:pr-3 mt-3 md:mt-0">
                <label
                  htmlFor="periodoId"
                  className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold"
                >
                  Periodo
                </label>
                <select
                  {...register("mesSeleccionado", {
                    onChange: (e) => {
                      setValue("numeroMes", e.target.selectedIndex);
                    },
                    ...validacionSelect,
                  })}
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                >
                  <option hidden value="">
                    Mes
                  </option>
                  {mesesPosterioresData.map((mes, i) => (
                    <option value={mes} key={i}>
                      {mes}
                    </option>
                  ))}
                </select>
                <span
                  className={`dark:text-red-400 text-red-600 text-xs ${
                    !errors.mesSeleccionado && "opacity-0 invisible"
                  }`}
                >
                  {errors.mesSeleccionado
                    ? errors.mesSeleccionado.message
                    : "hidden"}
                </span>
              </div>

              <div className="flex flex-col w-full md:flex-row md:w-6/12">
                <div className="w-full md:w-5/12 md:min-w-[90px] md:max-w-[150px] md:mb-2 mb-3 md:mr-3">
                  <label
                    className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                    htmlFor="grid-last-name"
                  >
                    Monto cuota
                  </label>
                  <input
                    {...register("montoCuota", {
                      validate: (value) => {
                        if (value === "" || value === "$" || value === "$0") {
                          return "Mayor a $0";
                        }
                        return true;
                      },
                      onChange: (e) => {
                        setValue(
                          "montoCuota",
                          "$" +
                            formatter.format(
                              getValues("montoCuota")?.replace(/\D/g, "")
                            )
                        );
                        setValue(
                          "montoTotal",
                          "$" +
                            formatter.format(
                              parseInt(e.target.value.replace(/\D/g, "")) *
                                parseInt(watch("cantidadCuotas"))
                            )
                        );
                      },
                      ...validacionSelect,
                    })}
                    id="montoCuota"
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="text"
                  />
                  <span
                    className={`dark:text-red-400 text-red-600 text-xs ${
                      !errors.montoCuota && "opacity-0 invisible"
                    }`}
                  >
                    {errors.montoCuota ? errors.montoCuota.message : "hidden"}
                  </span>
                </div>

                <div className="w-full md:w-3/12 md:min-w-[80px] md:max-w-[100px] md:mb-2 mb-3">
                  <label
                    className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                    htmlFor="grid-last-name"
                  >
                    N° Cuotas
                  </label>
                  <input
                    {...register("cantidadCuotas", {
                      onChange: (e) => {
                        const cleanedValue = e.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );

                        if (e.target.value === "") {
                          setValue("cantidadCuotas", "1");
                        } else {
                          setValue("cantidadCuotas", cleanedValue);
                        }
                        setValue(
                          "montoTotal",
                          "$" +
                            formatter.format(
                              parseInt(watch("montoCuota").replace(/\D/g, "")) *
                                (parseInt(cleanedValue)
                                  ? parseInt(cleanedValue)
                                  : parseInt(e.target.value))
                            )
                        );
                      },
                      validate: (value) => {
                        if (value === "" || value === "0" || value === 0) {
                          return "Mayor a 0";
                        }
                        return true;
                      },
                      ...validacionSelect,
                    })}
                    id="cantidadCuotas"
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="number"
                    step="1"
                    onKeyDown={(e) => {
                      const allowedKeys = [
                        "Backspace",
                        "Tab",
                        "Escape",
                        "Delete",
                        "ArrowLeft",
                        "ArrowRight",
                        "0",
                        "1",
                        "2",
                        "3",
                        "4",
                        "5",
                        "6",
                        "7",
                        "8",
                        "9", // Números del 0 al 9
                      ];
                      if (!allowedKeys.includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <span
                    className={`dark:text-red-400 text-red-600 text-xs ${
                      !errors.cantidadCuotas && "opacity-0 invisible"
                    }`}
                  >
                    {errors.cantidadCuotas
                      ? errors.cantidadCuotas.message
                      : "hidden"}
                  </span>
                </div>
              </div>

              <div className="w-full md:w-5/12 md:mb-2 mb-3">
                <label
                  className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                  htmlFor="grid-last-name"
                >
                  Nombre Descuento
                </label>
                <input
                  {...register("nombreDescuentoProgramado", validacionSelect)}
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  maxLength="40"
                />
                <span
                  className={`dark:text-red-400 text-red-600 text-xs ${
                    !errors.nombreDescuentoProgramado && "opacity-0 invisible"
                  }`}
                >
                  {errors.nombreDescuentoProgramado ? errors.nombreDescuentoProgramado.message : "hidden"}
                </span>
              </div>

              <div className="w-full md:w-12/12 md:min-w-[150px] md:max-w-[450px] md:mb-2 mb-3">
                <label
                  className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                  htmlFor="grid-last-name"
                >
                  Observación
                </label>
                <textarea
                  {...register("observacion", validacionSelect)}
                  rows="3"
                  cols="50"
                  id="observacion"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                />
                <span
                  className={`dark:text-red-400 text-red-600 text-xs ${
                    !errors.observacion && "opacity-0 invisible"
                  }`}
                >
                  {errors.observacion ? errors.observacion.message : "hidden"}
                </span>
              </div>

              <div className="flex flex-wrap mb-4 ">
                <div className="w-full md:w-5/12 md:mb-2 mb-3 md:mr-3">
                  <label
                    className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                    htmlFor="grid-last-name"
                  >
                    Monto total
                  </label>
                  <input
                    disabled
                    {...register("montoTotal")}
                    name="montoTotal"
                    id="montoTotal"
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="text"
                  />
                </div>

                <div className="w-full md:w-3/12 md:mb-2 mb-3">
                  <br />
                  <button
                  disabled={!botonGuardarHabilitado}
                  className={` ${!botonGuardarHabilitado? "bg-gray-500" : "bg-hercules-light-primary-button hover:shadow-lg hover:scale-105"}  text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400`}
                    type="submit"
                  >
                    Ingresar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="h-full md:mr-12 rounded-lg pt-3 md:pt-6">
        <p className="text-xl text-hercules-light-font-secondary dark:text-dark-primary">
          Trabajadores con Descuento:
        </p>
        <SimpleTable
          col={COLUMNS}
          tableData={listaDescuentos}
          primaryButton={{
            type: "ver",
            onClickFunc: (data) => {
              funcionVerDescuentoCuota(data);
            },
            active: true,
          }}
          secondaryButton={{
            type: "eliminar",
            onClickFunc: (data) => {
              eliminarDescuento(data);
            },
            active: true,
          }}
          liquidarButton={{
            type: "liquidarDescuento",
            onClickFunc: (data) => {
              liquidarDescuento(data);
            },
            active: true,
          }}
          exportCsv={() =>
            UtilsFunctions.jsonToCSV3(
              listaDescuentos,
              headersCsv,
              "Descuentos en Cuotas"
            )
          }
        />
      </div>
      <div>
        <Link to="/movimientos/informe-descuento-cuotas">
          <button className="mt-3 hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400">
            Informe Descuento en cuotas
          </button>
        </Link>
      </div>
      <ModalVerDescuentoEnCuotas
        isVisible={showModalVerDescuentoEnCuotas}
        onClose={() => setShowModalVerDescuentoEnCuotas(false)}
        modalData={modalData}
        infoTabla={infoTablaCuotas}
      />
      <ModalMotivoEliminarDescuento
        isVisible={showModalEliminarDescuento}
        onClose={() => setShowModalEliminarDescuento(false)}
        modalData={modalDataEliminarDescuento}
      />
      <ModalMotivoLiquidarDescuento
        isVisible={showModalLiquidarDescuento}
        onClose={() => setShowModalLiquidarDescuento(false)}
        modalData={modalDataLiquidarDescuento}
      />
    </div>
  );
}
