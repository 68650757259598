import React from "react";

const Button = ({bgColor, text, colorText, onClickFunc, type="" }) => {
  return (
    <button
      onClick={() => onClickFunc()}
      type={type}
      className={`mt-3 bg-${bgColor} text-${colorText} active:bg-${bgColor}-700 hover:bg-${bgColor}-700 font-bold uppercase text-sm px-4 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-500`}
    >
      {text}
    </button>
  );
};

export default Button;
