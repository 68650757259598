import React, { useEffect, useRef, useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { FaUserCircle } from "react-icons/fa";
import UtilsFunctions from "../../../utils/UtilsFunctions";
import { useStateContext } from "../../../contexts/ContextProvider";
import DarkModeSwitch from "../../../components/DarkModeSwitch";
import UserProfileVacaciones from "./UserProfileVacaciones";

const NavButton = ({ customFunc, icon, color, dotColor }) => (
  <button
    type="button"
    onClick={customFunc}
    className="relative text-xl rounded-lg p-3 text-hercules-light-secondary-button dark:hover:bg-gray-700 hover:bg-light-gray"
  >
    <span
      style={{ background: dotColor }}
      className="absolute inline-flex rounded-full h-2 w-2 right-2 top-4"
    />
    {icon}
  </button>
);

export default function NavbarVacaciones () {
  const navbarData = UtilsFunctions.getEmpresaCCostoInfo();
  const { setActiveMenu, screenSize, setScreenSize } = useStateContext();
  const userEmail = localStorage.getItem("email-user");
  const rolName = UtilsFunctions.rolFormatter(localStorage.getItem("rol"));

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [setScreenSize]);

  const [isOpen, setIsOpen] = useState(false);

  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize, setActiveMenu]);

  return (
    <div className="flex  p-2 md:mx-14 relative">
      <NavButton
        customFunc={() => setActiveMenu((prevActiveMenu) => !prevActiveMenu)}
        color="blue"
        icon={<AiOutlineMenu />}
      />
      <div className="hidden md:block">
        <div className="flex items-center flex-col ml-6 mt-2">
          <div className="flex">
            <label className="text-black dark:text-white text-sm font-medium">Empresa:</label>
            <p className="text-black dark:text-white text-sm ml-2 font-normal">{navbarData.empresa}</p>
            <label className="ttext-black dark:text-white text-sm font-medium ml-2">RUT:</label>
            <p className="text-black dark:text-white text-sm ml-2 font-normal">
              {navbarData.rutEmpresa}
            </p>
          </div>

          <div className="flex mr-auto">
            <label className="text-black dark:text-white text-sm font-medium">Centro de costo:</label>
            <p className="text-black dark:text-white text-sm ml-2 font-normal">{navbarData.centroCosto}</p>
          </div>
        </div>
      </div>

      <div className="flex ml-auto" ref={wrapperRef}>
      <DarkModeSwitch />
        <div
          className={` p-3 flex cursor-pointer hover:bg-light-gray dark:hover:bg-gray-700  rounded-xl`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <FaUserCircle className="text-2xl mt-1 text-hercules-light-secondary-button"  />
          <span className="pl-2 text-black dark:text-white font-normal text-xs">
            <p>{userEmail}</p>
            <p>{rolName}</p>
          </span>
        </div>
        {isOpen && <UserProfileVacaciones/>}
      </div>
    </div>
  );
};