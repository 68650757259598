import { axiosPrivate } from "../../../common/axiosPrivate";
import React, { useEffect, useState } from "react";
import { useStateContext } from "../../../contexts/ContextProvider";
import UtilsFunctions from "../../../utils/UtilsFunctions";
import { toast } from "react-hot-toast";
import ModalSpinner from "../../ModalSpinner";
import { useForm } from "react-hook-form";

export default function EditarHExtraHDescuento({
  isVisible,
  onClose,
  modalData,
  modalDataHs,
}) {
  const {
    register,
    handleSubmit,
    reset,
  } = useForm();
  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const periodoId = localStorage.getItem("periodoId");

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };
  const { refreshTable, setRefreshTable, screenSize } = useStateContext();
  const [botonGuardarHabilitado, setBotonGuardarHabilitado] = useState(true)

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  useEffect(() => {
    reset({
      id: modalDataHs.horasExtraId,
      rut: UtilsFunctions.rutFormatter(modalData.rut, { dots: true }),
      nombreTrabajador:
        modalData.nombres +
        " " +
        modalData.apellidoPaterno +
        " " +
        modalData.apellidoMaterno,
      nContrato: modalData.version,
      contratoId: modalData.contratoId,
      anexoContratoId: modalData.anexoContratoId,
      inicio: modalData.fechaIngreso
        ? modalData.fechaIngreso
        : modalData.fechaAnexo,
      termino:
        modalData.plazoContratoId === 2
          ? "N/A"
          : modalData.fechaTerminoContrato
          ? modalData.fechaTerminoContrato
          : modalData.fechaTerminoAnexo
          ? modalData.fechaTerminoAnexo
          : "N/A",
      plazo: modalData.nombrePlazoContrato,
      horasExtraHora: Math.trunc(modalDataHs.horasExtras),
      horasExtraMinuto:
        modalDataHs.horasExtras > 0
          ? Math.round(
              (modalDataHs.horasExtras - Math.trunc(modalDataHs.horasExtras)) *
                60
            )
          : 0,
      horasDescuentoHora: Math.trunc(modalDataHs.horasDescuento),
      horasDescuentoMinuto:
        modalDataHs.horasDescuento > 0
          ? Math.round(
              (modalDataHs.horasDescuento -
                Math.trunc(modalDataHs.horasDescuento)) *
                60
            )
          : 0,
      horasExtraId: modalDataHs.horasExtraId,
      horasDomingoHora: Math.trunc(modalDataHs.horasDomingo),
      horasDomingoMinuto:
        modalDataHs.horasDomingo > 0
          ? Math.round(
              (modalDataHs.horasDomingo -
                Math.trunc(modalDataHs.horasDomingo)) *
                60
            )
          : 0,
      horasExtraDomingoHora: Math.trunc(modalDataHs.horasExtrasDomingo),
      horasExtraDomingoMinuto:
        modalDataHs.horasExtrasDomingo > 0
          ? Math.round(
              (modalDataHs.horasExtrasDomingo -
                Math.trunc(modalDataHs.horasExtrasDomingo)) *
                60
            )
          : 0,
    });
  }, [modalData, modalDataHs, refreshTable]);

  const onSubmit = async (data) => {
    setBotonGuardarHabilitado(false)
    try {
      toast.loading("Editando Horas Extra y Descuento", estilosAlerta);
      const minutosExtra =
        parseInt(data.horasExtraHora) * 60 +
        (data.horasExtraMinuto ? parseInt(data.horasExtraMinuto) : 0);
      const minutosDescuento =
        parseInt(data.horasDescuentoHora) * 60 +
        (data.horasDescuentoMinuto ? parseInt(data.horasDescuentoMinuto) : 0);
      const minutosDomingo =
        parseInt(data.horasDomingoHora) * 60 +
        parseInt(data.horasDomingoMinuto);
      const minutosExtraDomingo =
        parseInt(data.horasExtraDomingoHora) * 60 +
        parseInt(data.horasExtraDomingoMinuto);

      const payload = {
        horasExtraId: data.horasExtraId,
        horasExtras: minutosExtra ? minutosExtra : 0,
        horasDescuento: minutosDescuento ? minutosDescuento : 0,
        contratoId: data.contratoId,
        anexoContratoId:
          data.anexoContratoId === 0 ? null : data.anexoContratoId,
        periodoId: periodoId,
        horasDomingo: minutosDomingo ? minutosDomingo : 0,
        horasExtrasDomingo: minutosExtraDomingo ? minutosExtraDomingo : 0,
      };

      let info;
      if (payload.horasExtraId === -1) {
        info = await axiosPrivate.post(
          `${api_service}/api/v1/contratos/agregarHorasExtraDescuento`,
          payload,
          Authorization
        );
      } else {
        info = await axiosPrivate.put(
          `${api_service}/api/v1/contratos/agregarHorasExtraDescuento`,
          payload,
          Authorization
        );
      }
      toast.dismiss();
      toast.success("Horas Extra y Descuento editadas con éxito", estilosAlerta);
      setRefreshTable(!refreshTable);
      onClose()
      setBotonGuardarHabilitado(true)
    } catch (error) {
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al editar Horas Extra y Descuento",
          estilosAlerta
      );
      console.log(error);
      setBotonGuardarHabilitado(true)
    }
  };

  const loadingModal = modalData && modalDataHs;

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="primerDivModal">
          <div className="segundoDivModal max-w-lg">
            {/*content*/}
            <div className="tercerDivModal shadow-lg  max-h-[85vh] ">
              {/*header*/}
              <div className="divHeaderModal border-slate-200">
                <h3 className="titulo-Modal">Horas Extra y Descuento</h3>
              </div>
              {/*body*/}

              <div
                className={`relative p-6 ${
                  !loadingModal ? "overflow-hidden" : "overflow-auto"
                } flex-auto dark:bg-hercules-dark-bg-200`}
              >
                {!loadingModal ? (
                  <div className="h-fix">
                    <ModalSpinner />
                  </div>
                ) : (
                  <>
                  {/* RUT y Nombre */}
                    <div className="md:flex md:flex-wrap">
                      <div className="w-full md:w-4/12 md:pr-3 md:mb-11 mb-9">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          RUT Trabajador
                        </label>
                        <input
                          {...register("rut")}
                          disabled
                          name="rut"
                          id="rut"
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                        />
                      </div>
                      <div className="w-full md:w-8/12 md:pl-3 md:mb-11 mb-9">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Nombre Trabajador
                        </label>
                        <input
                          {...register("nombreTrabajador")}
                          disabled
                          name="nombre"
                          id="nombre"
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                        />
                      </div>
                    </div>

                  {/* Version y Plazo */}
                    <div className="flex flex-wrap">
                      <div className="w-6/12 md:w-4/12 pr-3 md:mb-11 mb-9">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Versión Contrato
                        </label>
                        <input
                          {...register("nContrato")}
                          disabled
                          name="nContrato"
                          id="nContrato"
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                        />
                      </div>

                      <div className="w-6/12 md:w-5/12 pl-3 md:mb-11 mb-9">
                        <label
                          className="block uppercase w-full tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Plazo Contrato
                        </label>
                        <input
                          {...register("plazo")}
                          disabled
                          name="plazo"
                          id="plazo"
                          className="appearance-none block w-full md:w-10/12 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                        />
                      </div>
                    </div>

                  {/* Fecha Inicio y Termino */}
                  <div className="flex flex-wrap">
                      <div className="w-6/12 md:w-4/12 pr-3 md:mb-11 mb-9">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Fecha Inicio Contrato
                        </label>
                        <input
                          {...register("inicio")}
                          disabled
                          name="inicio"
                          id="inicio"
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                        />
                      </div>

                      <div className="w-6/12 md:w-5/12 pl-3 md:mb-11 mb-9">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Fecha Término Contrato
                        </label>
                        <input
                          {...register("termino")}
                          disabled
                          name="termino"
                          id="termino"
                          className="appearance-none block w-full md:w-10/12 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                        />
                      </div>
                    </div>

                    {/* Horas Extra y Descuento */}
                    <div className="flex">
                      <div className="w-6/12 md:w-4/12 pr-3 md:mb-9 mb-6">
                          <label
                            className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                            htmlFor="grid-last-name"
                          >
                            Horas Extra
                          </label>
                          <div className="flex items-center border-2 w-fit border-gray-400 rounded ">
                            <div className="relative">
                              <input
                                {...register("horasExtraHora")}
                                type="number"
                                name="horasExtraHora"
                                min="0"
                                max="999"
                                step="1"
                                className="dark:text-white block w-16 text-lg font-bold text-center border-transparent focus:border-transparent focus:ring-0 bg-transparent text-gray-900  border-none focus:outline-none"
                                placeholder="00"
                              />
                            </div>

                            <div className="relative">
                              <span className="absolute top-1/2 left-0 transform -translate-y-1/2 text-lg font-bold text-gray-600">
                                :
                              </span>

                              <input
                                {...register("horasExtraMinuto")}
                                type="number"
                                name="horasExtraMinuto"
                                min="0"
                                max="59"
                                step="1"
                                className="dark:text-white block w-16 ml-3 text-lg font-bold text-center border-transparent focus:border-transparent focus:ring-0 bg-transparent text-gray-900  border-none focus:outline-none  "
                                placeholder="00"
                              />
                            </div>
                          </div>
                      </div>

                      <div className="w-6/12 md:w-4/12 pl-3 md:mb-9 mb-6">
                          <label
                            className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                            htmlFor="grid-last-name"
                          >
                            Horas Descuento
                          </label>
                          <div className="flex items-center border-2 w-fit border-gray-400 rounded ">
                            <div className="relative">
                              <input
                                {...register("horasDescuentoHora")}
                                type="number"
                                name="horasDescuentoHora"
                                id="horasDescuentoHora"
                                min="0"
                                max="999"
                                step="1"
                                className="dark:text-white block w-16 text-lg font-bold text-center border-transparent focus:border-transparent focus:ring-0 bg-transparent text-gray-900  border-none focus:outline-none"
                                placeholder="00"
                              />
                            </div>

                            <div className="relative">
                              <span className="absolute top-1/2 left-0 transform -translate-y-1/2 text-lg font-bold text-gray-600">
                                :
                              </span>

                              <input
                                {...register("horasDescuentoMinuto")}
                                type="number"
                                name="horasDescuentoMinuto"
                                id="horasDescuentoMinuto"
                                min="0"
                                max="59"
                                step="1"
                                className="dark:text-white block w-16 ml-3 text-lg font-bold text-center border-transparent focus:border-transparent focus:ring-0 bg-transparent text-gray-900  border-none focus:outline-none  "
                                placeholder="00"
                              />
                            </div>
                          </div>
                      </div>
                    </div>

                    {/* Horas Extra y Descuento Domingos */}
                    <div className="flex">
                      <div className="w-6/12 md:w-4/12 pr-3">
                          <label
                            className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                            htmlFor="grid-last-name"
                          >
                            Horas Domingo
                          </label>
                          <div className="flex items-center border-2 w-fit border-gray-400 rounded ">
                            <div className="relative">
                              <input
                                {...register("horasDomingoHora")}
                                type="number"
                                name="horasDomingoHora"
                                id="horasDomingoHora"
                                min="0"
                                max="999"
                                step="1"
                                className="dark:text-white block w-16 text-lg font-bold text-center border-transparent focus:border-transparent focus:ring-0 bg-transparent text-gray-900  border-none focus:outline-none"
                                placeholder="00"
                              />
                            </div>

                            <div className="relative">
                              <span className="absolute top-1/2 left-0 transform -translate-y-1/2 text-lg font-bold text-gray-600">
                                :
                              </span>

                              <input
                                {...register("horasDomingoMinuto")}
                                type="number"
                                name="horasDomingoMinuto"
                                id="horasDomingoMinuto"
                                min="0"
                                max="59"
                                step="1"
                                className="dark:text-white block w-16 ml-3 text-lg font-bold text-center border-transparent focus:border-transparent focus:ring-0 bg-transparent text-gray-900  border-none focus:outline-none  "
                                placeholder="00"
                              />
                            </div>
                          </div>
                      </div>

                      <div className="w-6/12 md:w-4/12 pl-3">
                          <label
                            className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                            htmlFor="grid-last-name"
                          >
                            Horas Extra Domingo
                          </label>
                          <div className="flex items-center border-2 w-fit border-gray-400 rounded ">
                            <div className="relative">
                              <input
                                {...register("horasExtraDomingoHora")}
                                type="number"
                                name="horasExtraDomingoHora"
                                id="horasExtraDomingoHora"
                                min="0"
                                max="999"
                                step="1"
                                className="dark:text-white block w-16 text-lg font-bold text-center border-transparent focus:border-transparent focus:ring-0 bg-transparent text-gray-900  border-none focus:outline-none"
                                placeholder="00"
                              />
                            </div>

                            <div className="relative">
                              <span className="absolute top-1/2 left-0 transform -translate-y-1/2 text-lg font-bold text-gray-600">
                                :
                              </span>

                              <input
                                {...register("horasExtraDomingoMinuto")}
                                type="number"
                                name="horasExtraDomingoMinuto"
                                id="horasExtraDomingoMinuto"
                                min="0"
                                max="59"
                                step="1"
                                className="dark:text-white block w-16 ml-3 text-lg font-bold text-center border-transparent focus:border-transparent focus:ring-0 bg-transparent text-gray-900  border-none focus:outline-none  "
                                placeholder="00"
                              />
                            </div>
                          </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

              {/*footer*/}
              <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                  type="button"
                  onClick={() => {
                    onClose();
                    reset({});
                  }}
                >
                  Cerrar
                </button>
                <button
                  disabled={!botonGuardarHabilitado}
                  className={` ${!botonGuardarHabilitado? "bg-gray-500" : "bg-hercules-light-primary-button hover:shadow-lg hover:scale-105"}  text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400`}
                  type="submit"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </form>
    </>
  );
}
