import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getListaEmpresasTabla,
  hideLoader,
  limpiarInfoMenuEdicion,
  showLoader,
} from "../../Redux/Actions";
import { useStateContext } from "../../contexts/ContextProvider";
import { ColumnFilter, Button } from "../../components";
import UtilsFunctions from "../../utils/UtilsFunctions";
import SimpleTable from "../../components/SimpleTable";
import CrearNuevaEmpresa from "../../components/Edicion/MantenedorEmpresa/MantenedorNuevaEmpresa";
import EditarEmpresa from "../../components/Edicion/MantenedorEmpresa/MantenedorEditarEmpresa";
import { axiosPrivate } from "../../common/axiosPrivate";
import RutColumnFilter from "../../components/RutColumnFilter";

export default function MantenedorEmpresas() {
  const { refreshTable, activeMenu } = useStateContext();
  const [showModalNuevaEmpresa, setShowModalNuevaEmpresa] = useState(false);
  const [showModalEditarEmpresa, setShowModalEditarEmpresa] = useState(false);
  const [empresaData, setEmpresaData] = useState(null);
  const [ccafData, setCcafData] = useState(null);
  const [mutualData, setMutualData] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("getListaEmpresas"));
    };
  }, []);

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;

  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  async function getEmpresaCcafMutualData(empresaId) {
    try {
      const infoCCAF = await axiosPrivate(
        `${api_service}/api/v1/empresas/${empresaId}/ccaf`,
        Authorization
      );
      const infoMutuales = await axiosPrivate(
        `${api_service}/api/v1/empresas/${empresaId}/mutuales`,
        Authorization
      );
      if (infoCCAF.status == 200 && infoMutuales.status == 200) {
        setCcafData(infoCCAF.data);
        setMutualData(infoMutuales.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader());
      await Promise.all([dispatch(getListaEmpresasTabla())]);
      dispatch(hideLoader());
    };

    fetchData().catch((error) => {
      console.log(error.response.data.message);
      dispatch(hideLoader());
    });
  }, [refreshTable, dispatch]);

  const empresasData = useSelector((state) => state.getListaEmpresas);

  const COLUMNS = [
    {
      Header: "RUT",
      accessor: "rut",
      Cell: ({ value }) => UtilsFunctions.rutFormatter(value, { dots: true }),
      Filter: RutColumnFilter,

    },
    {
      Header: "Razón Social",
      accessor: "razonSocial",
      Filter: ColumnFilter,
    },
    {
      Header: "Giro",
      accessor: "giro",
      Filter: ColumnFilter,
    },
    {
      Header: "Nombre Representante Legal",
      accessor: (d) =>
        d.representanteLegal.nombres +
        " " +
        d.representanteLegal.apellidoPaterno +
        " " +
        d.representanteLegal.apellidoMaterno,
      Filter: ColumnFilter,
    },
  ];

  const headersCsv = [
    { name: "RUT", key: "rut" },
    { name: "Razon Social", key: "razonSocial" },
    { name: "Giro", key: "giro" },
    {
      name: "Nombre Representante Legal",
      key: [
        "representanteLegal.nombres",
        "representanteLegal.apellidoPaterno",
        "representanteLegal.apellidoMaterno",
      ],
    },
  ];

  return (
    <div
      className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
        activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">Edición</div>
      <div className="titulo-Pagina">Empresas</div>
      <div className="h-full md:mr-12 rounded-lg pt-5">
        <SimpleTable
          col={COLUMNS}
          tableData={empresasData || []}
          primaryButton={{
            type: "editar",
            onClickFunc: (data) => {
              getEmpresaCcafMutualData(data.id);
              setShowModalEditarEmpresa(true);
              setEmpresaData(data);
            },
            active: true,
          }}
          exportCsv={() =>
            UtilsFunctions.jsonToCSVv2(empresasData, headersCsv, "Empresas")
          }
        />
      </div>
      <Button
        bgColor={"hercules-light-primary-button"}
        text={"Nueva Empresa"}
        colorText={"white"}
        onClickFunc={() => setShowModalNuevaEmpresa(true)}
        type={"button"}
      />
      {showModalNuevaEmpresa && (
        <CrearNuevaEmpresa
          isVisible={showModalNuevaEmpresa}
          onClose={() => setShowModalNuevaEmpresa(false)}
        />
      )}
      {showModalEditarEmpresa && (
        <EditarEmpresa
          isVisible={showModalEditarEmpresa}
          onClose={() => setShowModalEditarEmpresa(false)}
          modalData={empresaData}
          ccafData={ccafData}
          mutualData={mutualData}
        />
      )}
    </div>
  );
}
