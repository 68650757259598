import { axiosPrivate } from "../../../common/axiosPrivate";
import React, { useEffect, useRef, useState } from "react";
import { useStateContext } from "../../../contexts/ContextProvider";
import UtilsFunctions from "../../../utils/UtilsFunctions";
import { toast } from "react-hot-toast";
import ModalSpinner from "../../ModalSpinner";
import { useForm } from "react-hook-form";
import SimpleTable from "../../SimpleTable";
import ColumnFilter from "../../ColumnFilter";
import { useDispatch, useSelector } from "react-redux";
import { limpiarInfoMenuEdicion, obtenerHistorialTrabajadorCanjeHorasExtraUtilizadas } from "../../../Redux/Actions";

export default function ModalGenerarComprobante({
  isVisible,
  onClose,
  modalData,
}) {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fechaInicio: "",
      duracion: "",
    },
  });

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const periodoId = localStorage.getItem("periodoId");
  const empresaId = localStorage.getItem("empresaId");

  const navbarData = UtilsFunctions.getEmpresaPeriodoInfo();

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };
  const { refreshTable, setRefreshTable, screenSize } = useStateContext();
  const [botonGuardarHabilitado, setBotonGuardarHabilitado] = useState(true);
  const [maxFechaTermino, setMaxFechaTermino] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const validacionCampoObligatorio = {
    required: { value: true, message: "Obligatorio" },
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await Promise.all([
        dispatch(obtenerHistorialTrabajadorCanjeHorasExtraUtilizadas(empresaId, modalData.trabajadorId))
      ]);
      setIsLoading(false);
    };
    fetchData().catch((error) => {
      console.log(error.response.data.message);
      setIsLoading(false);
    });
  }, [dispatch, modalData]);

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("infoHistorialCanjesHSExtraUtilizadasTrabajador"));
    };
  }, []);

  const infoHistorialCanjes = useSelector((state) => state.infoHistorialCanjesHSExtraUtilizadasTrabajador);

  useEffect(() => {
    reset({
      //   id: modalDataHs.horasExtraId,
      rut: UtilsFunctions.rutFormatter(modalData.rut, { dots: true }),
      nombreTrabajador:
        modalData.nombres +
        " " +
        modalData.apellidoPaterno +
        " " +
        modalData.apellidoMaterno,
      periodo: navbarData.periodo,
      periodoId: periodoId,
      fechaInicio: "",
      duracion: "",
      diasDisponiblesInput:
        modalData.totalDiasCanjeadosDisponibles === 1
          ? modalData.totalDiasCanjeadosDisponibles + " día"
          : modalData.totalDiasCanjeadosDisponibles + " días",
      diasDisponibles: modalData.totalDiasCanjeadosDisponibles,
    });
  }, [modalData, refreshTable]);

  const handleFechaInicioChange = (e) => {
    const fechaInicio = new Date(e.target.value);
    if (!isNaN(fechaInicio)) {
      const fechaMaxima = new Date(fechaInicio);
      fechaMaxima.setDate(
        fechaMaxima.getDate() + modalData.totalDiasCanjeadosDisponibles
      );

      // Convertir fecha a formato YYYY-MM-DD
      const yyyy = fechaMaxima.getFullYear();
      const mm = String(fechaMaxima.getMonth() + 1).padStart(2, "0"); // Los meses empiezan desde 0
      const dd = String(fechaMaxima.getDate()).padStart(2, "0");
      const maxFecha = `${yyyy}-${mm}-${dd}`;

      setMaxFechaTermino(maxFecha);
    }
  };

  const onSubmit = async (data) => {
    console.log("data", data);
    setBotonGuardarHabilitado(false);
    try {
      toast.loading("Utilizando Horas Extra", estilosAlerta);
      const payload = {
        trabajadorId: modalData.trabajadorId,
        empresaId: parseInt(empresaId),
        dias: parseInt(data.duracion),
        fechaInicio: data.fechaInicio,
        fechaTermino: data.fechaTermino,
      };
      await axiosPrivate.post(
        `${api_service}/api/v1/movimientos-trabajador/canjear-horas-extras/usar-dias`,
        payload,
        Authorization
      );
      toast.dismiss();
      toast.success("Horas Extra utilizadas con éxito", estilosAlerta);
      setRefreshTable(!refreshTable);
      onClose();
      setBotonGuardarHabilitado(true);
    } catch (error) {
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al utilizar Horas Extra",
        estilosAlerta
      );
      console.log(error);
      setBotonGuardarHabilitado(true);
    }
  };

  const loadingModal = modalData;

  async function exportarComprobante(data){
    toast.loading("Descargando Comprobante", estilosAlerta)
    try {

      axiosPrivate({
        url: `${api_service}/api/v1/movimientos-trabajador/canjear-horas-extras/comprobante/${data.id}`,
        method: "GET",
        // data: data,
        maxBodyLength: Infinity,
        headers: { Authorization: `Bearer ${tokenUser}`, ClientId: `${token}` },
        responseType: "blob", // importante
        onDownloadProgress: (progressEvent) => {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Comprobante_Vacaciones_${modalData.rut}.pdf`
          );
          document.body.appendChild(link);
          link.click();
          toast.dismiss();
          toast.success("Comprobante descargado con éxito 1", estilosAlerta);
        })
        .catch((error) => {
          console.log(error);
          toast.dismiss();
          toast.error("Error al descargar Comprobante 1", estilosAlerta);
        });

      toast.dismiss()
      toast.success("Comprobante generado con éxito 2", estilosAlerta)  
    } catch (error) {
      toast.dismiss()
      console.log(error)
      toast.error(error.response.data.message ? error.response.data.message : "Error al descargar Comprobante 2", estilosAlerta)
    }
  }

  const COLUMNS = [
    {
      Header: "Empresa",
      accessor: "nombreFantasia",
      Filter: ColumnFilter,
    },
    {
      Header: "Fecha Solicitud",
      accessor: "fechaSolicitud",
      Filter: ColumnFilter,
    },
    {
      Header: "Fecha Inicio",
      accessor: "fechaInicio",
      Filter: ColumnFilter,
    },
    {
      Header: "Fecha Termino",
      accessor: "fechaTermino",
      Filter: ColumnFilter,
    },
    {
      Header: "Dias",
      accessor: "dias",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value === null) {
          return "0 días";
        } else if ( value === 1) {
          return value + " día";
        } else {
          return value + " días";
        }
      },
    },
  ];

  if (!isVisible) {
    return null;
  }

  console.log("infoHistorialCanjes", infoHistorialCanjes)

  return (
    <>
      <div className="primerDivModal">
        <div className="segundoDivModal max-w-3xl">
          {/*content*/}
          <div className="tercerDivModal shadow-lg  max-h-[85vh] ">
            {/*header*/}
            <div className="divHeaderModal border-slate-200">
              <h3 className="titulo-Modal">Generar Comprobante</h3>
            </div>
            {/*body*/}

            <div
              className={`relative p-6 ${
                !loadingModal ? "overflow-hidden" : "overflow-auto"
              } flex-auto dark:bg-hercules-dark-bg-200`}
            >
              {!loadingModal ? (
                <div className="h-fix">
                  <ModalSpinner />
                </div>
              ) : (
                <>
                  {/* RUT y Nombre */}
                  <div className="md:flex md:flex-wrap">
                    <div className="w-full md:w-3/12 md:pr-3 md:mb-11 mb-9">
                      <label
                        className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        RUT Trabajador
                      </label>
                      <input
                        {...register("rut")}
                        disabled
                        name="rut"
                        id="rut"
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                      />
                    </div>

                    <div className="w-full md:w-7/12 md:px-3 md:mb-11 mb-9">
                      <label
                        className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Nombre Trabajador
                      </label>
                      <input
                        {...register("nombreTrabajador")}
                        disabled
                        name="nombre"
                        id="nombre"
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                      />
                    </div>

                    <div className="w-full md:w-2/12 md:pl-3 md:mb-11 mb-9">
                      <label
                        className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                        htmlFor="grid-last-name"
                      >
                        Días Disponibles
                      </label>
                      <input
                        {...register("diasDisponiblesInput")}
                        disabled
                        name="diasDisponiblesInput"
                        id="diasDisponiblesInput"
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                      />
                    </div>
                  </div>

                  {/* <h5 className="text-lg font-normal leading-normal mb-3 dark:text-white text-hercules-light-font-secondary">
                      Por favor revisar que los datos ingresados sean correctos
                    </h5> */}

                  {/* Duracion, Inicio y Termino */}
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="md:flex md:flex-wrap">
                      <div className="w-full md:w-2/12 md:pr-3 md:mb-11 mb-9">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Duracion
                        </label>
                        <input
                          {...register("duracion", validacionCampoObligatorio)}
                          name="duracion"
                          id="duracion"
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="number"
                          max={modalData.totalDiasCanjeadosDisponibles}
                          min={0}
                        />
                        <span
                          className={`text-red-500 dark:text-red-400 text-xs italic ${
                            !errors.duracion && "opacity-0 invisible"
                          }`}
                        >
                          {errors.duracion ? errors.duracion.message : "hidden"}
                        </span>
                      </div>

                      <div className="w-full md:w-4/12 md:px-3 md:mb-11 mb-9">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Fecha Inicio
                        </label>
                        <input
                          {...register("fechaInicio", {
                            ...validacionCampoObligatorio,
                            onChange: (e) => handleFechaInicioChange(e),
                          })}
                          name="fechaInicio"
                          id="fechaInicio"
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="date"
                        />
                        <span
                          className={`text-red-500 dark:text-red-400 text-xs italic ${
                            !errors.fechaInicio && "opacity-0 invisible"
                          }`}
                        >
                          {errors.fechaInicio
                            ? errors.fechaInicio.message
                            : "hidden"}
                        </span>
                      </div>

                      <div className="w-full md:w-4/12 md:px-3 md:mb-11 mb-9">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Fecha Termino
                        </label>
                        <input
                          {...register(
                            "fechaTermino",
                            validacionCampoObligatorio
                          )}
                          disabled={watch("fechaInicio") === "" ? true : false}
                          placeholder={
                            watch("duracion") === ""
                              ? "Indique Duración"
                              : watch("fechaInicio") === ""
                              ? "Indique Inicio"
                              : false
                          }
                          name="fechaTermino"
                          id="fechaTermino"
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type={watch("fechaInicio") === "" ? "text" : "date"}
                          max={maxFechaTermino}
                          min={watch("fechaInicio")}
                        />
                        <span
                          className={`text-red-500 dark:text-red-400 text-xs italic ${
                            !errors.fechaTermino && "opacity-0 invisible"
                          }`}
                        >
                          {errors.fechaTermino
                            ? errors.fechaTermino.message
                            : "hidden"}
                        </span>
                      </div>

                      <div className="w-full md:w-2/12 md:pl-3 md:mt-6 mt-3">
                        <button
                          className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-sm md:text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 ease-in-out transition-all duration-400"
                          type="submit"
                        >
                          Generar
                        </button>
                      </div>
                    </div>
                  </form>

                  <div>
                    <h3 className="text-2xl font-semibold leading-normal mb-3 dark:text-white text-hercules-light-font-secondary">
                      Historial de Comprobantes
                    </h3>

                    <div className="w-full mb-9">
                      <SimpleTable
                        col={COLUMNS}
                        tableData={infoHistorialCanjes || []}
                        exportCsvButton={false}
                        hasPagination={false}
                        searchBar={false}
                        showFilters={false}
                        tableHeight="h-56"
                        primaryButton={{
                          type: "exportarComprobante",
                          onClickFunc: (data) => {
                            exportarComprobante(data);
                          },
                          active: true,
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>

            {/*footer*/}
            <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                type="button"
                onClick={() => {
                  onClose();
                  reset({});
                }}
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
