import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import UtilsFunctions from "../../../utils/UtilsFunctions";
import toast from "react-hot-toast";
import { axiosPrivate } from "../../../common/axiosPrivate";
import ModalSpinner from "../../ModalSpinner";
import { useStateContext } from "../../../contexts/ContextProvider";
import SimpleTable from "../../SimpleTable";
import ColumnFilter from "../../ColumnFilter";
import { getRegistroFamiliarTrabajador, limpiarInfoMenuEdicion } from "../../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";

export default function ModalRegistroFamiliar({
  isVisible,
  onClose,
  modalData,
}) {
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: modalData,
  });
  const dispatch = useDispatch();
  const [botonGuardarHabilitado, setBotonGuardarHabilitado] = useState(true);
  const { refreshTable, setRefreshTable, screenSize } = useStateContext();
  // const [registroFamiliarTrabajadorTable, setRegistroFamiliarTrabajadorTable] = useState([]);

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const validacionTextInput = {
    required: { value: true, message: "Obligatorio" },
    pattern: {
      value: /^[A-Za-zÁÉÍÓÚáéíóúñÑ ]+$/g,
      message: "Solo se permiten letras",
    },
  };

  const validacionFecha = {
    required: { value: true, message: "Obligatorio" },
  };

  const rutValidations = {
    required: { value: true, message: "Debe ingresar un rut" },
    pattern: {
      value: /^(\d{1,2}(?:[\.]?\d{3}){2}-[\dkK])$/,
      message:
        "El formato del RUT es incorrecto (ej. 18.405.136-K o 18405136-K)",
    },
    validate: {
      rutValido: (v) =>
        UtilsFunctions.validateRut(v) || "El RUT ingresado no es válido",
    },
  };

  useEffect(() => {
    if (modalData) {
      reset({
        apellidoMaterno: modalData.apellido_Materno,
        apellidoPaterno: modalData.apellido_Paterno,
        nombres: modalData.nombres,
        rut: UtilsFunctions.rutFormatter(modalData.rut, { dots: true }),
      });
    }
  }, [modalData]);

  useEffect(() => {
    const fetchData = async () => {
    //   setIsLoading(true);
      await Promise.all([
        dispatch(getRegistroFamiliarTrabajador(modalData.id)),
      ]);
    //   setIsLoading(false);
    };
    fetchData().catch((error) => {
      console.log(error.response.data.message);
    //   setIsLoading(false);
    });
  }, [dispatch, modalData.id, refreshTable]);

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("infoRegistroFamiliarTrabajador"));
    };
  }, []);

  const registroFamiliarTrabajador = useSelector(
    (state) => state.infoRegistroFamiliarTrabajador
  );

  // useEffect(() => {
  //   setRegistroFamiliarTrabajadorTable(registroFamiliarTrabajador)
  // }, [modalData]);

  // console.log("registroFamiliarTrabajador", registroFamiliarTrabajador)

  const onSubmit = async (data) => {
    setBotonGuardarHabilitado(false);
    try {
      const payload = {
        trabajadorId: modalData.id,
        nombre: data.nombresFamiliar + " " + data.apellidoPaternoFamiliar + " " + data.apellidoMaternoFamiliar,
        parentesco: data.parentesco,
        fechaNacimiento: data.fechaNacimiento,
        // rut: UtilsFunctions.rutFormatter(data.rut, { dots: false }),
      };
    //   console.log("payload", payload)
      toast.loading("Agregando Registro Familiar", estilosAlerta);
      const response = await axiosPrivate.post(
        `${api_service}/api/v1/trabajadores/registro-familiar`,
        payload,
        Authorization
      );
      if (response.status === 200) {
        dispatch(getRegistroFamiliarTrabajador(modalData.id))
        toast.dismiss();
        toast.success("Registro Familiar agregado con éxito", estilosAlerta);
        setRefreshTable(!refreshTable);
        // onClose();
      }
      setBotonGuardarHabilitado(true);
    } catch (error) {
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al agregar Registro Familiar",
        estilosAlerta
      );
      console.log(error);
      setBotonGuardarHabilitado(true);
    }
  };

  const loadingModal = modalData ? true : false;

  const COLUMNS = [
    // {
    //   Header: "RUT",
    //   accessor: (d) => UtilsFunctions.rutFormatter(d.rut, { dots: true }),
    //   Filter: ColumnFilter,
    // },
    {
      Header: "Nombre",
      accessor: "nombre",
      Filter: ColumnFilter,
    },
    {
        Header: "Parentesco",
        accessor: "parentesco",
        Filter: ColumnFilter,
      },
      {
        Header: "F. Nacimiento",
        accessor: "fechaNacimiento",
        Filter: ColumnFilter,
      },    
  ];

  if (!isVisible) {
    return null;
  }

  async function eliminarRegistro(data) {
    toast.loading("Eliminando Registro", estilosAlerta)
    try {
      const response = await axiosPrivate.delete(
        `${api_service}/api/v1/trabajadores/registro-familiar`,
        {
            data: {
              registroFamiliarId: data.id,
            },
            headers: {
              Authorization: `Bearer ${tokenUser}`,
              ClientId: `${token}`,
            },
          }
        );
      if (response.status === 200) {
        toast.dismiss();
        toast.success("Registro eliminado con éxito", estilosAlerta);
        // onClose();
        setRefreshTable(!refreshTable);
      }
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error(error.response.data.message? error.response.data.message : "Error al eliminar Registro", estilosAlerta);
    }
  }

  return (
    <>
        <div className="primerDivModal">
          <div className="segundoDivModal max-w-6xl">
            {/*content*/}
            <div className="tercerDivModal shadow-lg  max-h-[85vh] ">
              {/*header*/}
              <div className="divHeaderModal border-slate-200">
                <h3 className="titulo-Modal">Registro Familiar</h3>
              </div>
              {/*body*/}
              <div className="relative p-6 flex-auto overflow-auto dark:bg-hercules-dark-bg-200">
                {!loadingModal && <ModalSpinner />}

                <h3 className="text-2xl font-normal leading-normal mb-3 dark:text-white text-hercules-light-font-secondary">
                  Funcionario
                </h3>

                <div className="flex flex-wrap">

                <div className="input-EditarEmpresa md:w-3/12">
                    <label
                      className="dark:text-white block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      RUT Representante Legal
                    </label>
                    <input
                      {...register("rut")}
                      disabled
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                    />
                  </div>

                  <div className="input-EditarEmpresa md:w-3/12">
                    <label
                      className="dark:text-white block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      Nombre
                    </label>
                    <input
                      {...register("nombres")}
                      disabled
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                    />
                  </div>

                  <div className="input-EditarEmpresa md:w-3/12">
                    <label
                      className="dark:text-white block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      Apellido Paterno
                    </label>
                    <input
                      {...register("apellidoPaterno")}
                      disabled
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                    />
                  </div>

                  <div className="input-EditarEmpresa md:w-3/12">
                    <label
                      className="dark:text-white block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      Apellido Materno
                    </label>
                    <input
                      {...register("apellidoMaterno")}
                      disabled
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                    />
                  </div>

                </div>

                <h3 className="text-2xl font-normal leading-normal mb-3 dark:text-white text-hercules-light-font-secondary">
                  Agregar Familiar
                </h3>

                <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-wrap">
                  <div className="input-EditarEmpresa md:w-3/12">
                    <label
                      className="dark:text-white block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      RUT
                    </label>
                    <input
                      {...register("rutFamiliar", {
                        // ...rutValidations,
                        onChange: () => {
                          if (getValues("rutFamiliar") !== "-") {
                            setValue(
                              "rutFamiliar",
                              UtilsFunctions.rutFormatter(getValues("rutFamiliar"), {
                                dots: true,
                              })
                            );
                          }
                          if (getValues("rutFamiliar") === "-") {
                            setValue("rutFamiliar", "");
                          }
                        },
                      })}
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                    />
                    <span
                      className={`text-red-500 dark:text-red-400 text-xs italic ${
                        !errors.rutFamiliar && "opacity-0 invisible"
                      }`}
                    >
                      {errors.rutFamiliar ? errors.rutFamiliar.message : "hidden"}
                    </span>
                  </div>

                  <div className="input-EditarEmpresa md:w-3/12">
                    <label
                      className="dark:text-white block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      Nombre
                    </label>
                    <input
                      {...register("nombresFamiliar", validacionTextInput)}
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                    />
                    <span
                      className={`text-red-500 dark:text-red-400 text-xs italic ${
                        !errors.nombresFamiliar && "opacity-0 invisible"
                      }`}
                    >
                      {errors.nombresFamiliar ? errors.nombresFamiliar.message : "hidden"}
                    </span>
                  </div>

                  <div className="input-EditarEmpresa md:w-3/12">
                    <label
                      className="dark:text-white block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      Apellido Paterno
                    </label>
                    <input
                      {...register("apellidoPaternoFamiliar", validacionTextInput)}
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                    />
                    <span
                      className={`text-red-500 dark:text-red-400 text-xs italic ${
                        !errors.apellidoPaternoFamiliar && "opacity-0 invisible"
                      }`}
                    >
                      {errors.apellidoPaternoFamiliar
                        ? errors.apellidoPaternoFamiliar.message
                        : "hidden"}
                    </span>
                  </div>

                  <div className="input-EditarEmpresa md:w-3/12">
                    <label
                      className="dark:text-white block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      Apellido Materno
                    </label>
                    <input
                      {...register("apellidoMaternoFamiliar", validacionTextInput)}
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                    />
                    <span
                      className={`text-red-500 dark:text-red-400 text-xs italic ${
                        !errors.apellidoMaternoFamiliar && "opacity-0 invisible"
                      }`}
                    >
                      {errors.apellidoMaternoFamiliar
                        ? errors.apellidoMaternoFamiliar.message
                        : "hidden"}
                    </span>
                  </div>
                </div>

                <div className="flex flex-wrap">
                  <div className="input-EditarEmpresa md:w-3/12">
                    <label
                      className="dark:text-white block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      Parentesco
                    </label>
                    <select
                      {...register("parentesco", {
                        required: "Obligatorio",
                        // onChange: (e) => {
                        //   const gratificacion =
                        //   gratificacionesData[e.target.selectedIndex - 1];
                        //   setValue("gratificacion", gratificacion);
                        //   setValue("gratificacionTexto", gratificacion.observacion);
                        // },
                      })}
                      name="parentesco"
                      className="appearance-none block w-full md:w-64 bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                    >
                      <option hidden value="">
                        Seleccionar Parentesco
                      </option>
                      <option value="HIJO">
                        Hijo
                      </option>
                      <option value="HIJA">
                        Hija
                      </option>
                      <option value="CONYUGUE">
                        Conyugue
                      </option>
                    </select>

                    <span
                      className={`text-red-500 dark:text-red-400 text-xs italic ${
                        !errors.parentesco && "opacity-0 invisible"
                      }`}
                    >
                      {errors.parentesco ? errors.parentesco.message : "hidden"}
                    </span>
                  </div>

                  <div className="input-EditarEmpresa md:w-3/12">
                    <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                      Fecha de Nacimiento
                    </label>
                    <input
                      {...register("fechaNacimiento", validacionFecha)}
                      className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                      type="date"
                    />
                    {errors.fechaNacimiento && (
                      <p className="text-red-600 text-xs">
                        {errors.fechaNacimiento.message}
                      </p>
                    )}
                  </div>

                  <div className="w-full md:pl-3 md:w-3/12 mb-6 mt-3 md:mt-6">
                    <button
                      disabled={!botonGuardarHabilitado}
                      className={` ${
                        !botonGuardarHabilitado
                          ? "bg-gray-500"
                          : "bg-hercules-light-primary-button hover:shadow-lg hover:scale-105"
                      }  text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400`}
                      type="submit"
                    >
                      Agregar
                    </button>
                  </div>
                </div>
                </form>

                <SimpleTable
                col={COLUMNS}
                tableData={registroFamiliarTrabajador || []}
                hasPagination={false}
                searchBar={false}
                showFilters={false}
                tableHeight="h-72"
                secondaryButton={{
                  type: "eliminar",
                        onClickFunc: (data) => {
                    eliminarRegistro(data);
                  },
                  active: true,
                }}
                  />

              </div>
              {/*footer*/}
              <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                  type="button"
                  onClick={() => {
                    onClose();
                    reset();
                  }}
                >
                  Cerrar
                </button>
                {/* <button
                  disabled={!botonGuardarHabilitado}
                  className={` ${
                    !botonGuardarHabilitado
                      ? "bg-gray-500"
                      : "bg-hercules-light-primary-button hover:shadow-lg hover:scale-105"
                  }  text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400`}
                  type="submit"
                >
                  Guardar
                </button> */}
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
