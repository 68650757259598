import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  hideLoader,
  limpiarInfoMenuEdicion,
  movimientosTrabajadorPeriodo,
  showLoader,
} from "../../Redux/Actions";
import { ColumnFilter } from "../../components";
import { useStateContext } from "../../contexts/ContextProvider";
import UtilsFunctions from "../../utils/UtilsFunctions";
import SimpleTable from "../../components/SimpleTable";
import RutColumnFilter from "../../components/RutColumnFilter";
import ModalCanjearHorasExtra from "../../components/Movimientos/CanjearHorasExtra/ModalCanjearHorasExtra";

export default function CanjearHorasExtra() {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const { refreshTable, activeMenu } = useStateContext();
  const dispatch = useDispatch();

  const periodoId = localStorage.getItem("periodoId");
  const empresaId = localStorage.getItem("empresaId");

  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader());
      await Promise.all([
        dispatch(movimientosTrabajadorPeriodo(periodoId, empresaId)),
      ]);
      dispatch(hideLoader());
    };

    fetchData().catch((error) => {
      console.log(error.response.data.message);
      dispatch(hideLoader());
    });
  }, [refreshTable, dispatch]);

  const listaMovimientosTrabPeriodo = useSelector(
    (state) => state.movimientosTrabajadorPeriodo
  );

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("movimientosTrabajadorPeriodo"));
    };
  }, []);

  const COLUMNS = [
    {
      Header: "RUT",
      accessor: "rut",
      Cell: ({ value }) => UtilsFunctions.rutFormatter(value, { dots: true }),
      Filter: RutColumnFilter,
    },
    {
      Header: "Trabajador",
      accessor: (d) =>
        d.apellidoPaterno + " " + d.apellidoMaterno + " " + d.nombres,
      Filter: ColumnFilter,
    },
    {
      Header: "Horas Extra",
      accessor: "horasExtra",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value === null) {
          return "0 hrs.";
        } else {
          return value + " hrs.";
        }
      },
    },
  ];

  const headersCsv = [
    {
      key: "rut",
      name: "RUT",
    },
    {
      key: "nombres",
      name: "Nombres",
    },
    {
      key: "apellidoPaterno",
      name: "Apellido Paterno",
    },
    {
      key: "apellidoMaterno",
      name: "Apellido Materno",
    },
    {
      key: "Horas Extra",
      name: "horasExtra",
    },
  ];

  return (
    <>
      <div
        className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
          activeMenu && "md:w-[calc(100vw_-_23rem)]"
        }`}
      >
        <div className="titulo-Menu">Movimientos</div>
        <div className="titulo-Pagina">Canjear Horas Extra</div>
        <div className="h-full md:mr-12 rounded-lg pt-5">
          <SimpleTable
            col={COLUMNS}
            tableData={listaMovimientosTrabPeriodo || []}
            primaryButton={{
              type: "canjearHorasExtra",
              onClickFunc: (data) => {
                setShowModal(true);
                setModalData(data);
              },
              active: true,
            }}
            exportCsv={() =>
              UtilsFunctions.jsonToCSV3(listaMovimientosTrabPeriodo, headersCsv, "Haberes")
            }
            hasPagination={true}
          />
        </div>
      </div>
      {showModal && (
        <ModalCanjearHorasExtra
          isVisible={showModal}
          onClose={() => setShowModal(false)}
          modalData={modalData}
        />
      )}
    </>
  );
}
