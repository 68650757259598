import { axiosPrivate } from "../../../common/axiosPrivate";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  limpiarInfoMenuEdicion,
  movimientosPersonal,
  pagadoresSubsidio,
} from "../../../Redux/Actions";
import ColumnFilter from "../../ColumnFilter";
import TablaAgregarEliminarMov from "./TablaAgregarEliminarMov";
import { toast } from "react-hot-toast";
import NuevoAgregarMovimientoForm from "./NuevoAgregarMovimientoForm";
import ModalSpinner from "../../ModalSpinner";
import UtilsFunctions from "../../../utils/UtilsFunctions";
import { useStateContext } from "../../../contexts/ContextProvider";

export default function EditarMovMensuales({
  isVisible,
  onClose,
  modalData,
  modalDataContrato,
}) {
  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");

  const dispatch = useDispatch();

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const [listaMovimientos, setListaMovimientos] = useState([]);
  const [modificadaListaMovimientos, setModificadaListaMovimientos] =
    useState(false);
  const infoMovimientosPersonal = useSelector(
    (state) => state.movimientosPersonal
  );
  const infoPagadoresSubsidio = useSelector((state) => state.pagadoresSubsidio);

  const { screenSize } = useStateContext();

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  useEffect(() => {
    if (isVisible) {
      dispatch(movimientosPersonal());
      dispatch(pagadoresSubsidio());
      if (modalDataContrato && !modificadaListaMovimientos) {
        setListaMovimientos(modalDataContrato);
      }
    }
  }, [dispatch, modalDataContrato]);

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("movimientosPersonal"));
      dispatch(limpiarInfoMenuEdicion("pagadoresSubsidio"));
    };
  }, []);

  async function eliminarMovimiento(movimientoTrabajadorId) {
    toast.loading("Eliminando Movimiento", estilosAlerta);
    try {
      const info = await axiosPrivate.delete(
        `${api_service}/api/v1/contratos/movimientoTrabajador/${movimientoTrabajadorId}`,
        Authorization
      );
      toast.dismiss();
      toast.success("Movimiento eliminado con éxito", estilosAlerta);
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.success(error.response.data.message? error.response.data.message : "Error al eliminar Movimiento", estilosAlerta);
    }
  }

  async function agregarMovimiento(movimiento) {
    if (!movimiento) {
      return null;
    }
    toast.loading("Agregando Movimiento", estilosAlerta);
    try {
      const info = await axiosPrivate.post(
        `${api_service}/api/v1/contratos/nuevoMovimientoTrabajador`,
        movimiento,
        Authorization
      );

      const dataModificada = {
        nombreMovimiento: info.data[0].movimientoPersonal,
        movimientoTrabajadorId: info.data[0].id,
        desde: movimiento.fechaMovimientoDesde
          ? `${movimiento.fechaMovimientoDesde.split("-")[2]}-${
              movimiento.fechaMovimientoDesde.split("-")[1]
            }-${movimiento.fechaMovimientoDesde.split("-")[0]}`
          : null,
        hasta: movimiento.fechaMovimientoHasta
          ? `${movimiento.fechaMovimientoHasta.split("-")[2]}-${
              movimiento.fechaMovimientoHasta.split("-")[1]
            }-${movimiento.fechaMovimientoHasta.split("-")[0]}`
          : null,
        dias: info.data[0].dias ? info.data[0].dias : null,
      };

      const nuevoArray = [...listaMovimientos, dataModificada];
      setListaMovimientos(nuevoArray);
      setModificadaListaMovimientos(true);
      toast.dismiss();
      toast.success("Movimiento agregado con éxito", estilosAlerta);
    } catch (error) {
      console.log("error", error);
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al agregar Movimiento",
          estilosAlerta
      );
    }
  }

  const COLUMNAS_MOVIMIENTOS = [
    {
      Header: "Movimiento",
      accessor: "nombreMovimiento",
      Filter: ColumnFilter,
    },
    {
      Header: "Inicio",
      accessor: "desde",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value == null) {
          return "N/A";
        } else {
          return `${value.split("-")[2]}-${value.split("-")[1]}-${
            value.split("-")[0]
          }`;
        }
      },
    },
    {
      Header: "Término",
      accessor: "hasta",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value == null) {
          return "N/A";
        } else {
          return `${value.split("-")[2]}-${value.split("-")[1]}-${
            value.split("-")[0]
          }`;
        }
      },
    },
    {
      Header: "Días",
      accessor: "dias",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value == null) {
          return "N/A";
        } else {
          return value;
        }
      },
    },
  ];

  const loadingModal =
  modalData &&
  infoMovimientosPersonal?.length > 0 &&
   infoPagadoresSubsidio?.length > 0;

  if (!isVisible) {
    return null;
  }

  return (
    <>
        <div className="primerDivModal">
          <div className="segundoDivModal max-w-6xl">
          {/*content*/}
          <div className="tercerDivModal shadow-lg  max-h-[85vh] ">
            {/*header*/}
            <div className="divHeaderModal border-slate-200">
              <h3 className="titulo-Modal">
                Movimientos Mensuales
              </h3>
            </div>
            {/*body*/}
            <div
              className={`relative p-6 ${
                !loadingModal ? "overflow-hidden" : "overflow-auto"
              } flex-auto dark:bg-hercules-dark-bg-200`}
            >
              {!loadingModal ? (
                <div className="h-fix">
                  <ModalSpinner />
                </div>
              ) : (
                <>
                    <div className="md:flex md:flex-wrap">
                      <div className="w-full md:w-3/12 md:pr-3 md:mb-11 mb-9">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-first-name"
                        >
                          RUT
                        </label>
                        <input
                          disabled
                          value={UtilsFunctions.rutFormatter(modalData.rut, {dots: true})}
                          className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                        />
                      </div>

                      <div className="w-full md:w-3/12 md:px-3 md:mb-11 mb-9">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-first-name"
                        >
                          Nombres
                        </label>
                        <input
                          disabled
                          value={modalData.nombres}
                          className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                        />
                      </div>

                      <div className="w-full md:w-3/12 md:px-3 md:mb-11 mb-9">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-first-name"
                        >
                          Apellido Paterno
                        </label>
                        <input
                          disabled
                          value={modalData.apellidoPaterno}
                          className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                        />
                      </div>

                      <div className="w-full md:w-3/12 md:pl-3 md:mb-11 mb-9">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-first-name"
                        >
                          Apellido Materno
                        </label>
                        <input
                          disabled
                          value={modalData.apellidoMaterno}
                          className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                        />
                      </div>
                    </div>

                    <div className="md:flex md:flex-wrap">
                      <div className="w-full md:w-3/12 md:pr-3 md:mb-11 mb-9">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Número de contrato
                        </label>
                        <input
                          disabled
                          value={modalData.version}
                          className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                        />
                      </div>

                      <div className="w-full md:w-3/12 md:px-3 md:mb-11 mb-9">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Inicio
                        </label>
                        <input
                          disabled
                          value={
                            modalData.fechaIngreso
                              ? modalData.fechaIngreso
                              : modalData.fechaAnexo
                          }
                          className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                        />
                      </div>

                      <div className="w-full md:w-3/12 md:px-3 md:mb-11 mb-9">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Termino
                        </label>
                        <input
                          disabled
                          value={
                            modalData.fechaTerminoContrato
                              ? modalData.fechaTerminoContrato
                              : modalData.fechaTerminoAnexo
                              ? modalData.fechaTerminoAnexo
                              : "N/A"
                          }
                          className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                        />
                      </div>

                      <div className="w-full md:w-3/12 md:pl-3 mb-11">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Plazo
                        </label>
                        <input
                          disabled
                          value={modalData.nombrePlazoContrato}
                          className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                        />
                      </div>
                    </div>

                  <NuevoAgregarMovimientoForm
                    movimientosSelect={infoMovimientosPersonal}
                    sendFormData={(movimiento) => agregarMovimiento(movimiento)}
                    modalData={modalData}
                    listaEmpresas={infoPagadoresSubsidio}
                  />

                  {listaMovimientos.length ? (
                    <div className="h-full rounded-lg">
                      <TablaAgregarEliminarMov
                        col={COLUMNAS_MOVIMIENTOS}
                        tableData={listaMovimientos}
                        botonEliminar={true}
                        borrarMovimiento={(movimientoTrabajadorId, index) => {
                          eliminarMovimiento(movimientoTrabajadorId);
                          listaMovimientos.splice(index, 1);
                          const arrayEditado = [...listaMovimientos];
                          setListaMovimientos(arrayEditado);
                        }}
                      />
                    </div>
                  ) : (
                    <p className=" dark:text-white text-hercules-light-font-secondary text-sm font-medium ">
                      Este trabajador no registra Movimientos en este periodo
                    </p>
                  )}
                </>
              )}
              </div>

            {/*footer*/}
            <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                type="button"
                onClick={() => {
                  onClose();
                }}
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
