import React, { useEffect, useState } from "react";
import UtilsFunctions from "../../utils/UtilsFunctions";
import { useForm } from "react-hook-form";
import { useStateContext } from "../../contexts/ContextProvider";
import { useDispatch, useSelector } from "react-redux";
import { getCentroCostos, getCentrosCostosByEmpresaId, listaPeriodosHastaActual } from "../../Redux/Actions";
import { ColumnFilter } from "../../components";
import CertificadoSueldoTabla from "../../components/Herramientas/CertificadoSueldo/CertificadoSueldoTabla";
import { axiosPrivate } from "../../common/axiosPrivate";
import { toast } from "react-hot-toast";

export default function CertificadoSueldo() {
  const {
    register,
    getValues,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      centroCostoId: 0,
    },
  });

  const dispatch = useDispatch();
  const { refreshTable, setRefreshTable, activeMenu, screenSize } = useStateContext();
  const infoCCosto = useSelector((state) => state.centrosCostoEmpresa);
  const anyosData = UtilsFunctions.obtenerAnyosHastaActual();
  const [infoCertificados, setInfoCertificados] = useState(true);

  const empresaId = localStorage.getItem("empresaId");
  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };


  useEffect(() => {
    try {
      dispatch(listaPeriodosHastaActual());
      dispatch(getCentrosCostosByEmpresaId(empresaId));
    } catch (error) {
      console.log(error);
    }
  }, [refreshTable, dispatch]);

  async function buscarCertSueldoPeriodoCCosto() {
    const data = getValues();
    toast.loading("Cargando", estilosAlerta);
    try {
      const response = await axiosPrivate(
        `${api_service}/api/v1/trabajadores/liquidaciones/certificados-sueldo/anio/${data.periodoAnyo}/empresa/${empresaId}/centroCosto/${data.centroCostoId}`,
        Authorization
      );
      console.log("response", response);
      if (!response.data.length > 0) {
        setInfoCertificados([]);
        toast.dismiss();
        toast.error("Sin información en este Periodo y Centro de costo", estilosAlerta);
      } else {
        setInfoCertificados(response.data);
        toast.dismiss();
        toast.success("Certificados de sueldo cargados con éxito", estilosAlerta);
      }
    } catch (error) {
      console.log("error", error);
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al cargar Certificados de sueldo",
          estilosAlerta
      );
    }
    setRefreshTable(!refreshTable);
  }

  const COLUMNS = [
    {
      Header: "RUT",
      accessor: (d) =>
        UtilsFunctions.rutFormatter(d.trabajador.rut, { dots: true }),
      Filter: ColumnFilter,
    },
    {
      Header: "Nombres",
      accessor: "trabajador.nombres",
      Filter: ColumnFilter,
    },
    {
      Header: "Apellido paterno",
      accessor: "trabajador.apellidoPaterno",
      Filter: ColumnFilter,
    },
    {
      Header: "Apellido materno",
      accessor: "trabajador.apellidoMaterno",
      Filter: ColumnFilter,
    },
    {
      Header: "Liquidaciones",
      accessor: "numeroLiquidaciones",
      Filter: ColumnFilter,
    },
  ];

  return (
    <div
      className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
        activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">Herramientas</div>
      <div className="titulo-Pagina">Certificado de Sueldo</div>

      <form onSubmit={handleSubmit(buscarCertSueldoPeriodoCCosto)}>
        <div className="pt-3 flex flex-wrap">

          <div className="w-4/12 md:w-4/12 md:min-w-[100px] md:max-w-[200px] pr-3 mb-3">
            <label className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
              Periodo
            </label>
              <select
                {...register("periodoAnyo", {
                  onChange: (e) => {
                    setInfoCertificados([]);
                  },
                  required: { value: true, message: "Obligatorio" },
                })}
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option hidden value="">
                  Año
                </option>
                {anyosData.map((anyo, i) => (
                  <option value={anyo} key={i}>
                    {anyo}
                  </option>
                ))}
              </select>
              <span
                className={`dark:text-red-400 text-red-600 text-xs ${
                  !errors.periodoAnyo && "opacity-0 invisible"
                }`}
              >
                {errors.periodoAnyo ? errors.periodoAnyo.message : "hidden"}
              </span>
          </div>

          <div className="w-6/12 md:w-72 pl-3 mb-6 md:mb-0 px-3">
            <label class="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
              Centro de Costo
            </label>
            <select
              {...register("centroCostoId", {
                onChange: (e) => {
                  const centroCosto = infoCCosto[e.target.selectedIndex - 1];
                  setValue("centroCosto", centroCosto);
                  setInfoCertificados([]);
                },
              })}
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option hidden value="">
                Seleccionar
              </option>
              <option value={"0"}>Todos</option>
              {infoCCosto.map((ccosto, i) => (
                <option value={ccosto.id} key={i}>
                  {ccosto.nombre}
                </option>
              ))}
            </select>
          </div>

          <div className="w-full md:w-3/12 md:mt-6 md:pl-3 mb-6 md:mb-0">
            <button
              type="submit"
              className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
            >
              Buscar
            </button>
          </div>
        </div>
      </form>

      {infoCertificados.length > 0 && (
        <div className=" relative h-full rounded-lg md:mr-12">
          <CertificadoSueldoTabla
            col={COLUMNS}
            tableData={infoCertificados}
            anyo={watch("periodoAnyo")}
          />
        </div>
      )}
    </div>
  );
}
