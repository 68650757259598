import React, { useState } from "react";
import { ColumnFilter } from "../../components";
import { useStateContext } from "../../contexts/ContextProvider";
import SimpleTable from "../../components/SimpleTable";
import { useForm } from "react-hook-form";
import UtilsFunctions from "../../utils/UtilsFunctions";
import { toast } from "react-hot-toast";
import { axiosPrivate } from "../../common/axiosPrivate";

export default function FactoresActualizacion() {
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { activeMenu, screenSize } = useStateContext();
  const anyosData = UtilsFunctions.obtenerAnyosHastaActual();
  const [infoFactoresActualizacion, setInfoFactoresActualizacion] = useState([]);

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };
  
  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  async function buscarAnyo() {
    const data = getValues();
    toast.loading("Cargando", estilosAlerta);

    try {
      const payload = {
        anyo: data.periodoAnyo,
      };
      console.log("payload", payload);
      const response = await axiosPrivate.post(
        `${api_service}/api/v1/trabajadores/factoresactualizacion`,
        payload,
        Authorization
      );
      console.log("response", response);
      if (!response.data.length > 0) {
        setInfoFactoresActualizacion([]);
        toast.dismiss();
        toast.error("Sin Información en este Año", estilosAlerta);
      } else {
        setInfoFactoresActualizacion(response.data);
        toast.dismiss();
        toast.success("Factores de actualización cargados con éxito", estilosAlerta);
      }
    } catch (error) {
      console.log("error", error);
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al cargar Factores de actualización",
          estilosAlerta
      );
    }
  }

  const COLUMNS = [
    {
      Header: "Mes",
      accessor: "mes",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value === 1) {
          return "Enero";
        }
        if (value === 2) {
          return "Febrero";
        }
        if (value === 3) {
          return "Marzo";
        }
        if (value === 4) {
          return "Abril";
        }
        if (value === 5) {
          return "Mayo";
        }
        if (value === 6) {
          return "Junio";
        }
        if (value === 7) {
          return "Julio";
        }
        if (value === 8) {
          return "Agosto";
        }
        if (value === 9) {
          return "Septiembre";
        }
        if (value === 10) {
          return "Octubre";
        }
        if (value === 11) {
          return "Noviembre";
        }
        if (value === 12) {
          return "Diciembre";
        }
      },
    },
    {
      Header: "Factor",
      accessor: "factor",
      Filter: ColumnFilter,
    },
  ];

  return (
    <div
      className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
        activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">Parámetros</div>

      <div className="titulo-Pagina">Factores de actualización</div>

      <form
        className="w-full"
        onSubmit={handleSubmit(buscarAnyo)}
      >
        <div>
          <p className="mt-2 dark:text-white text-hercules-light-font-secondary text-2xl ">
            Corrección monetaria
          </p>
        </div>
        <div className="md:flex flex-wrap pt-3 ">
          <div className="w-full md:w-[260px]">
            <p className="mt-2 font-medium dark:text-white text-hercules-light-font-secondary">
              Porcentaje del actualización año:
            </p>
          </div>
          <div className="flex mt-3 md:mt-0">
          <div className="w-4/12 md:w-1/12 md:min-w-[100px] md:max-w-[150px] mr-3 md:mr-0 md:pr-3">
            <select
              {...register("periodoAnyo", {
                onChange: () => setInfoFactoresActualizacion([]),
                required: { value: true, message: "Obligatorio" },
              })}
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option hidden value="">
                Año
              </option>
              {anyosData.map((anyo, i) => (
                <option value={anyo} key={i}>
                  {anyo}
                </option>
              ))}
            </select>
            <span
              className={`dark:text-red-400 text-red-600 text-xs ${
                !errors.periodoAnyo && "opacity-0 invisible"
              }`}
            >
              {errors.periodoAnyo ? errors.periodoAnyo.message : "hidden"}
            </span>
          </div>
          <div className="md:w-2/12">
            <button
              type="submit"
              className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none ease-in-out transition-all duration-400"
            >
              Buscar
            </button>
          </div>
          </div>
        </div>
      </form>

      {infoFactoresActualizacion.length > 0 && (
        <>
        <div className="h-full md:mr-12 rounded-lg">
            <SimpleTable
              col={COLUMNS}
              tableData={infoFactoresActualizacion}
              searchBar={false}
              showFilters={false}
              hasPagination={false}
              tableHeight="h-96"
            />
          </div>
          <p className=" mt-6 font-medium dark:text-white text-hercules-light-font-secondary">
            Nota: Los valores se mostrarán el mes de diciembre, cuando estén
            completos los factores de actualización.
          </p>
        </>
      )}
    </div>
  );
}
