import React from "react";
import toast from "react-hot-toast";
import { useStateContext } from "../../../../contexts/ContextProvider";
import { axiosPrivate } from "../../../../common/axiosPrivate";

export default function ModalEliminarFeriado({modalData, onClose}){

  const { refreshTable, setRefreshTable, screenSize } = useStateContext();

  const api_service =
  process.env.REACT_APP_API_HERCULES_SERVICE;

const tokenRut = localStorage.getItem("token-rut");
const token = tokenRut.slice(0, 8);
const tokenUser = localStorage.getItem("token-user");

const Authorization = {
  headers: {
    Authorization: `Bearer ${tokenUser}`,
    ClientId: `${token}`,
  },
};

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  async function eliminarFeriado() {
    toast.loading("Eliminando Feriado", estilosAlerta)
    try {
      const response = await axiosPrivate.delete(
        `${api_service}/api/v1/vacaciones/feriados`,
        {
            data: [modalData.id],
            headers: {
              Authorization: `Bearer ${tokenUser}`,
              ClientId: `${token}`,
            },
          }
        );
      if (response.status === 200) {
        toast.dismiss();
        toast.success("Feriado eliminado con éxito", estilosAlerta);
        onClose();
        setRefreshTable(!refreshTable);
      }
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error(error.response.data.message? error.response.data.message : "Error al eliminar Feriado", estilosAlerta);
    }
  }

    return(
        <>
      <div className="primerDivModal">
        <div className="segundoDivModal max-w-2xl">
          {/*content*/}
          <div className="tercerDivModal shadow-lg  max-h-[85vh] ">
            {/*header*/}
            <div className="divHeaderModal border-slate-200">
              <h3 className="titulo-Modal">
                Eliminar Feriado
              </h3>
            </div>
            {/*body*/}
            <div
              className="relative p-6 overflow-auto flex-auto dark:bg-hercules-dark-bg-200"
            >
              {/* {!loadingModal && <ModalSpinner />} */}
                <>
                  {/* Primera fila modal */}

                  <h5 className="text-lg font-normal leading-normal mb-3 dark:text-white text-hercules-light-font-secondary">
                Eliminar feriado con fecha <b>{modalData.fecha}</b> por el día de <b>{modalData.descripcion}</b>.
              </h5>

                </>
            </div>

            {/*footer*/}
            <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-sm md:text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                type="button"
                onClick={() => {
                  onClose();
                }}
              >
                Cerrar
              </button>
              <button
                className="bg-hercules-light-primary-button hover:shadow-lg hover:scale-105 text-white  font-bold uppercase text-sm md:text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
                type="button"
                onClick={() => eliminarFeriado()}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-20 bg-black"></div>

        </>
    )
}