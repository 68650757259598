import React, { useState } from 'react'
import { useAsyncDebounce } from 'react-table' 

const ColumnFilterVigenteLiquidada = ({ column }) => {
    const { filterValue, setFilter } = column
    const [value, setValue] = useState(filterValue)
    const onChange = useAsyncDebounce(value => {
        setFilter(value || undefined)
    }, 400)

  return (
    <>
        <select className='w-24 font-normal text-sm h-8 border-0 bg-white  rounded' value={value || ""} placeholder=" Filtrar..." onChange={(e) => {
            setValue(e.target.value)
            onChange(e.target.value)
        }}>
            <option value="">Todos</option>
            <option value="true">Vigente</option>
            <option value="false">Liquidada</option>
        </select>
    </>
  )
}

export default ColumnFilterVigenteLiquidada