import React, { useEffect, useState } from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import { ColumnFilter } from "../../components";
import { toast } from "react-hot-toast";
import UtilsFunctions from "../../utils/UtilsFunctions";
import axios from "axios";
import { useForm } from "react-hook-form";
import { axiosPrivate } from "../../common/axiosPrivate";
import SimpleTable from "../../components/SimpleTable";
import { parse } from "papaparse";
import TablaIngresoCargasFamiliaresyCreditosCCAF from "../../components/Movimientos/ImportarCreditosCCAFyCargasFamiliares/TableCreditosCCAFyCargasFamiliares";
import {
  hideLoader,
  limpiarInfoMenuEdicion,
  obtenerRegistrosCargasFamiliaresPeriodo,
  obtenerRegistrosCreditosCCAFPeriodo,
  showLoader,
} from "../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import RutColumnFilter from "../../components/RutColumnFilter";
import GuiaImportacionPlantillas from "../../components/Movimientos/GuiaImportacionPlantillas";
import ImagenEjemploCCAF from "../../assets/Ejemplo Plantilla Creditos CCAF.jpg";
import ModalMensajeCreditosCAFFyCargasFamiliares from "../../components/Movimientos/ImportarCreditosCCAFyCargasFamiliares/ModalMensajeCreditosCAFFyCargasFamiliares";

export default function NuevoIngresoCreditosCCAF() {
  const {
    register,
    getValues,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      periodoId: "",
      periodoAnyo: "",
    },
  });

  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const { activeMenu, screenSize, refreshTable, setRefreshTable } =
    useStateContext();
  const [file, setFile] = useState();
  const [arrayCreditos, setArrayCreditos] = useState([]);
  const [botonGuardarHabilitado, setBotonGuardarHabilitado] = useState(true);
  const [showModalGuiaImportacion, setShowModalGuiaImportacion] =
    useState(false);
    const [infoPeriodos, setInfoPeriodos] = useState([]);
    const [errorRecibido, setErrorRecibido] = useState([]);
    const [showModalError, setShowModalError] = useState(false);
  
    // console.log("arrayCreditos", arrayCreditos)

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const periodoId = localStorage.getItem("periodoId");
  const mesLogin = localStorage.getItem("periodoMes");
  const anyoLogin = localStorage.getItem("periodoAnyo");

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const empresaId = localStorage.getItem("empresaId");
  const dispatch = useDispatch();

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const infoTablaCreditosCCAF = useSelector(
    (state) => state.infoRegistrosCreditosCCAFPeriodo
  );

  async function getPeriodosCargados(){
    const infoMeses = await axiosPrivate(`${api_service}/api/v1/periodos/cargados`, Authorization)
    setInfoPeriodos(infoMeses.data)
  }

  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader());
      await Promise.all([
        dispatch(obtenerRegistrosCreditosCCAFPeriodo(periodoId, empresaId)),
        getPeriodosCargados()
      ]);
      dispatch(hideLoader());
    };

    fetchData().catch((error) => {
      console.log(error.response.data.message);
      dispatch(hideLoader());
    });
  }, [dispatch, refreshTable]);

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("contratosSemCorridaPorEmpresa"));
    };
  }, []);

  const validacionTextInput = {
    required: { value: true, message: "Obligatorio" },
  };

  const anyosData = UtilsFunctions.obtenerAnyosHastaActual();
  const [mesesData, setMesesData] = useState([]);

  const handleYearChange = (e) => {
    const year = parseInt(e);

    // Encontrar el objeto en infoPeriodos que tiene el 'anyo' igual a year.
    const yearData = infoPeriodos.find((item) => item.anyo === year);

    if (yearData) {
      // Filtrar los meses que cumplen con las condiciones y establecerlos en mesesData.
      const filteredMonths = yearData.periodos.filter(
        (mes) => mes.datosPrevisionalesCargados === true
      );
      const transformedMonths = filteredMonths.map((month) => (
        UtilsFunctions.integerToMonth(month.mes)
      ));
      setMesesData(transformedMonths);

    } else {
      // Si no se encontró ningún año con el valor seleccionado, establecer mesesData como un arreglo vacío.
      setMesesData([]);
    }
  };

  const fileReader = new FileReader();

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  function descargarPlantillaCreditosCAFF() {
    try {
      toast.loading("Descargando Plantilla", estilosAlerta);
      axiosPrivate({
        url: `${api_service}/api/v1/descuentos/ccaf/descargar-plantilla`,
        method: "GET",
        //   data: data,
        maxBodyLength: Infinity,
        headers: { Authorization: `Bearer ${tokenUser}`, ClientId: `${token}` },
        responseType: "blob", // importante
        onDownloadProgress: (progressEvent) => {
          Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        },
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "PlantillaCreditosCCAF.csv");
        document.body.appendChild(link);
        link.click();
        toast.dismiss();
        toast.success("Plantilla descargada con éxito", estilosAlerta);
      });
    } catch (error) {
      console.log(error);
    }
  }

  const csvToJson2 = (csv) => {
    parse(csv, {
      delimiter: ";",
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        try {
          const headersRequeridos = [
            "Rut Afiliado",
            "Monto Descuento",
            "Tipo Descuento",
            "Numero de Cuota",
            "Cuotas Autorizadas",
          ];
          UtilsFunctions.validarColumnasPlantilla(
            result.data,
            headersRequeridos,
            "Plantilla de Ingreso Créditos CCAF"
          );
  
          toast.dismiss();
          toast.success("Datos importados con éxito", estilosAlerta);
          setArrayCreditos(result.data);
          } catch (error) {
            console.log("error", error);
            toast.dismiss();
            toast.error(error.message);      
        }
      },
    });
  };

  async function cargarDatosCreditosCCAF() {
    if (!file) {
      toast.dismiss();
      toast.error("Primero debe subir una plantilla", estilosAlerta);
      return null;
    }
    const infoValue = getValues();
    try {
      toast.loading("Importando datos", estilosAlerta);
      const infoPeriodo = await axiosPrivate.get(
        `${api_service}/api/v1/periodos/mes/${infoValue.numeroMes}/anyo/${infoValue.periodoAnyo}`,
        Authorization
      );
      setValue("periodoId", infoPeriodo.data.id);
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvToJson2(text);
      };
      fileReader.readAsText(file);
    } catch (error) {
      toast.dismiss();
      toast.error("Error al importar datos", estilosAlerta);
      console.log("error", error);
    }
  }

  async function guardarCreditosCCAF() {
    const infoValue = getValues();
    setBotonGuardarHabilitado(false);
    const creditosCCAF = arrayCreditos.map((e) => {
      if (!e["Rut Afiliado"]) {
        toast.error("Hay filas sin RUT", estilosAlerta);
        throw new Error("Hay filas sin RUT");
      }
      if (
        !e["Rut Afiliado"] ||
        !e["Monto Descuento"] ||
        !e["Numero de Cuota"] ||
        !e["Tipo Descuento"] ||
        !e["Cuotas Autorizadas"]
      ) {
        toast.error("Hay campos sin completar", estilosAlerta);
        throw new Error("Hay campos sin completar");
      }
      return {
        rutTrabajador: e["Rut Afiliado"].replaceAll(".", ""),
        montoDescuento: e["Monto Descuento"].replaceAll(".", ""),
        tipoDescuento: e["Tipo Descuento"],
        numeroCuota: e["Numero de Cuota"],
        cuotasAutorizadas: e["Cuotas Autorizadas"],
      };
    });
    toast.loading("Guardando Créditos CCAF", estilosAlerta);
    try {
      const data = {
        descuentosCcaf: creditosCCAF,
        periodoId: infoValue.periodoId,
      };
      const info = await axios.post(
        `${api_service}/api/v1/descuentos/ccaf`,
        data,
        Authorization
      );
      if (Array.isArray(info.data) && info.data.length > 0) {
        setErrorRecibido(info.data);
        setShowModalError(true);
        toast.dismiss();
        toast.error(
          "Hay " + info.data.length + (info.data.length > 1 ? " créditos" : " crédito") + " con error",
          estilosAlerta
        );
      } else {
        toast.dismiss();
        toast.success("Créditos CCAF cargados", estilosAlerta);
        setRefreshTable(!refreshTable);
        setArrayCreditos([]);  
      }
      setBotonGuardarHabilitado(true);
    } catch (error) {
      console.log("error", error);
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Hubo un error al cargar Créditos CCAF",
        estilosAlerta
      );
      setBotonGuardarHabilitado(true);
    }
  }

  const COLUMNS = [
    {
      Header: "RUT",
      accessor: "rutTrabajador",
      Cell: ({ value }) => UtilsFunctions.rutFormatter(value, { dots: true }),
      Filter: RutColumnFilter,
    },
    {
      Header: "Nombres",
      accessor: (d) =>
        d.nombres + " " + d.apellidoPaterno + " " + d.apellidoMaterno,
      Filter: ColumnFilter,
    },
    {
      Header: "Monto Descuento",
      accessor: "monto",
      Cell: ({ value }) => "$" + UtilsFunctions.integerFormatter(value),
      Filter: ColumnFilter,
    },
  ];

  const COLUMNAS_TABLA_IMPORTADA = [
    {
      Header: "Correlativo",
      accessor: "Correlativo",
      Filter: ColumnFilter,
    },
    {
      Header: "RUT",
      accessor: "Rut Afiliado",
      Cell: ({ value }) => UtilsFunctions.rutFormatter(value, { dots: true }),
      Filter: RutColumnFilter,
    },
    {
      Header: "Nombre Afiliado",
      accessor: "Nombre Afiliado",
      Filter: ColumnFilter,
    },
    {
      Header: "Monto Descuento",
      accessor: "Monto Descuento",
      Cell: ({ value }) => "$" + UtilsFunctions.integerFormatter(value),
      Filter: ColumnFilter,
    },
    {
      Header: "Numero de Cuota",
      accessor: "Numero de Cuota",
      Filter: ColumnFilter,
    },
    {
      Header: "Tipo Descuento",
      accessor: "Tipo Descuento",
      Filter: ColumnFilter,
    },
    {
      Header: "Cuotas Autorizadas",
      accessor: "Cuotas Autorizadas",
      Filter: ColumnFilter,
    },
  ];

  const texto = (
    <>
      <p className="pb-3">
        Esta plantilla tiene las siguientes columnas, por favor verifique que
        los datos ingresados tengan el formato requerido:
      </p>
      <ul>
        <li className="py-2">
          <b>Correlativo: </b>Número entero de 4 dígitos. Rellenar con ceros a
          la izquierda si es necesario.
        </li>
        <li className="py-2">
          <b>* RUT: </b>Campo alfanumérico compuesto por el RUT + dígito
          verificador, sin puntos. Este campo tiene un
          largo de 9 digitos, en caso de ser necesario, se debe rellenar con
          ceros a la izquierda. Campo obligatorio. Ejemplo: 12345678-9
        </li>
        <li className="py-2">
          <b>* Monto Descuento: </b>Numero natural de largo 7 como máximo. Este
          campo solo admite números sin caracteres especiales. Campo
          obligatorio. Ejemplo: 22000
        </li>
        <li className="py-2">
          <b>* Número de cuota del mes: </b>Número natural de largo 3 como máximo.
          Campo obligatorio. Ejemplo: 1
        </li>
        <li className="py-2">
          <b>* Tipo de descuento: </b>Número natural de largo 1. Campo
          obligatorio, cuyas opciones son:
          <ul className="pt-2 pl-1">
            <li>1: Crédito</li>
            <li>3: Descuento Adicional</li>
            <li>4: Seguro</li>
            <li>5: Línea de crédito</li>
            <li>6: Grupo controlado</li>
            <li>7: Intercaja</li>
          </ul>
        </li>
        <li className="py-2">
          <b>* Cuotas autorizadas: </b>Número natural de largo 3 como máximo. Este
          campo solo admite números sin caracteres especiales. Campo
          obligatorio. Ejemplo: 2
        </li>
      </ul>
    </>
  );

  return (
    <div
      className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
        activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">Movimientos</div>
      <div className="titulo-Pagina">Ingreso Créditos CCAF</div>

      <div>
        <p className="mt-3 dark:text-white text-hercules-light-font-secondary">
          Ante cualquier duda o conflicto al importar plantillas, le
          recomendamos revisar{" "}
          <u>
            <button
              onClick={() => setShowModalGuiaImportacion(true)}
              className="hover:cursor-pointer font-medium underline"
            >
              esta guia
            </button>
          </u>
        </p>
      </div>

      <div className="rounded-lg pt-5 ">
        <div className="flex max-w-2xl">
          {/* Boton para ver pestaña registro creditos ccaf */}
          <button
            className={` w-6/12 rounded-t text-center py-3 font-semibold border-x border-t ${
              activeTab === 1
                ? "border-gray-300 dark:border-dark-bg-200 bg-white dark:bg-hercules-dark-bg-100  dark:text-gray-50 text-hercules-light-font-secondary"
                : "border-gray-600 bg-gray-600 text-white opacity-70 dark:border-dark-bg-200"
            }`}
            onClick={() => handleTabClick(1)}
          >
            Ver Registros de Créditos CCAF
          </button>

          {/* Boton para ver pestaña plantilla importada */}
          <button
            className={` w-6/12 rounded-t text-center py-3 ml-1 font-semibold border-x border-t ${
              activeTab === 2
                ? "bg-white dark:bg-hercules-dark-bg-100 dark:text-gray-50 text-hercules-light-font-secondary border-t border-x border-gray-300 dark:border-dark-bg-200"
                : "border-gray-600 bg-gray-600 text-white opacity-70 dark:border-dark-bg-200"
            }`}
            onClick={() => handleTabClick(2)}
          >
            Descargar/Importar Plantilla
          </button>
        </div>

        <div>
          <div className={`${activeTab === 1 ? "block " : "hidden"} `}>
            {infoTablaCreditosCCAF.length > 0 ? (
              <div className="h-full rounded-b-lg md:mr-12">
                <TablaIngresoCargasFamiliaresyCreditosCCAF
                  col={COLUMNS}
                  tableData={infoTablaCreditosCCAF || []}
                  tipoTabla="creditosCCAF"
                />
              </div>
            ) : (
              <div className="h-608 bg-white dark:bg-hercules-dark-bg-100 border-gray-300 dark:border-dark-bg-200 md:mr-12 rounded-b-md rounded-tr-md border-y border-x">
                <h4 className="text-xl md:mr-20 md:ml-11 md:pt-11 md:pb-20 dark:text-gray-50 text-hercules-light-font-secondary">
                  En el periodo {UtilsFunctions.integerToMonth(mesLogin)}/
                  {anyoLogin} no hay registros de trabajadores con Créditos CCAF, puede descargar la plantilla e importar los Créditos CCAF que desee.
                </h4>
              </div>
            )}
          </div>

          <div className={`${activeTab === 2 ? "block" : "hidden"}`}>
            {arrayCreditos.length ? (
              <>
                <div className="h-full md:mr-12 rounded-b-lg">
                  <SimpleTable
                    col={COLUMNAS_TABLA_IMPORTADA}
                    tableData={arrayCreditos}
                    exportCsvButton={false}
                    borders={false}
                  />
                </div>

                {/* Div con Total de Cargas y botones de Eliminar Plantilla y Guardar */}
                <div className="py-2 flex justify-between md:mr-12">
                  <div>
                    <label className=" dark:text-white text-hercules-light-font-secondary">
                      Total Cargas
                    </label>
                    <input
                      value={arrayCreditos?.length}
                      disabled
                      className=" mr-1 w-24 appearance-none block bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-last-name"
                      type="text"
                    />
                  </div>

                  <div className="pt-3 flex ml-auto ">
                    <button
                      onClick={() => {
                        setArrayCreditos([]);
                        setValue("periodoMes", "")
                        setValue("periodoAnyo", "")
                        setValue("periodoId", "")
                        setFile()
                      }}
                      className="bg-gray-500 text-white my-1 font-bold uppercase text-base px-6 py-2 rounded-md shadow outline-none focus:outline-none mr-1 ease-in-out transition-all duration-400"
                    >
                      Limpiar Tabla
                    </button>

                    <button
                      onClick={() => {
                        guardarCreditosCCAF();
                      }}
                      disabled={!botonGuardarHabilitado}
                      className={` ${
                        !botonGuardarHabilitado
                          ? "bg-gray-500"
                          : "bg-hercules-light-primary-button hover:shadow-lg hover:scale-105"
                      }  text-white my-1 font-bold uppercase text-base px-6 py-2 rounded-md shadow outline-none focus:outline-none ml-1 ease-in-out transition-all duration-400`}
                    >
                      Guardar
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <div className="h-608 bg-white dark:bg-hercules-dark-bg-100 border-gray-300 dark:border-dark-bg-200 md:mr-12 rounded-b-md rounded-tr-md border-y border-x">
              <h4 className="text-xl md:mr-20 md:ml-11 md:pt-11 md:pb-20 dark:text-gray-50 text-hercules-light-font-secondary">
                Aún no ha importado una plantilla de Créditos CCAF o puede
                que haya intentado cargar una plantilla con errores. Por favor cargue una plantilla para luego importarla.
              </h4>
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        className={`pt-3 flex flex-wrap ${
          activeTab === 2 && !arrayCreditos.length ? "block" : "hidden"
        }`}
      >
        {/* Boton para descargar plantilla */}
        <div className="w-full md:w-fit mt-3 md:mt-6 md:pr-3 mb-3 md:mb-0">
          <button
            onClick={() => descargarPlantillaCreditosCAFF()}
            className="hover:scale-105 bg-hercules-dark-primary-button text-white font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
          >
            Descargar plantilla
          </button>
          <span className="md:border-[2px] border-hercules-light-font-secondary dark:border-white md:ml-3"></span>
        </div>

        <form onSubmit={handleSubmit(cargarDatosCreditosCCAF)}>
          <div className="flex flex-wrap md:w-full">
            {/* Selector de año y mes */}
            <div className="flex flex-wrap md:w-fit">
              <div className="w-4/12 md:min-w-[100px] md:max-w-[200px] pr-3 mb-3">
                <label className=" dark:text-white text-hercules-light-font-secondary">
                  Periodo
                </label>
                <select
                  {...register("periodoAnyo", {
                    onChange: (e) => {
                      setValue("periodoMes", "");
                      handleYearChange(e.target.value)
                    },
                    ...validacionTextInput,
                  })}
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                >
                  <option hidden value="">
                    Año
                  </option>
                  {anyosData.map((anyo, i) => (
                    <option value={anyo} key={i}>
                      {anyo}
                    </option>
                  ))}
                </select>
                <span
                  className={`dark:text-red-400 text-red-600 text-xs ${
                    !errors.periodoAnyo && "opacity-0 invisible"
                  }`}
                >
                  {errors.periodoAnyo ? errors.periodoAnyo.message : "hidden"}
                </span>
              </div>

              <div className="w-5/12 md:w-6/12 md:min-w-[130px] md:max-w-[200px] mt-6 md:mr-3 mb-3">
                <select
                  {...register("periodoMes", {
                    onChange: (e) => {
                      setValue("numeroMes", e.target.selectedIndex);
                    },
                    ...validacionTextInput,
                  })}
                  disabled={watch("periodoAnyo") === "" ? true : false}
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                >
                  <option hidden value="">
                    Mes
                  </option>
                  {mesesData.map((mes, i) => (
                    <option value={mes} key={i}>
                      {mes}
                    </option>
                  ))}
                </select>
                <span
                  className={`dark:text-red-400 text-red-600 text-xs ${
                    !errors.periodoMes && "opacity-0 invisible"
                  }`}
                >
                  {errors.periodoMes ? errors.periodoMes.message : "hidden"}
                </span>
              </div>
            </div>

            {/* Input para seleccionar archivo */}
            <div className="md:w-96 md:pt-5 md:px-3">
              <input
                type={"file"}
                id={"xlsxFileInput"}
                accept={".csv"}
                onChange={handleOnChange}
                required
                className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
              />
            </div>

            {/* Boton para importar */}
            <div className="w-full mt-6 md:mt-5 md:w-2/12 mb-6 md:mb-0">
              <button
                type="submit"
                className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 pt-3 pb-4 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
              >
                Importar
              </button>
            </div>
          </div>
        </form>
      </div>

      {showModalError && (
        <ModalMensajeCreditosCAFFyCargasFamiliares
          nombrePlantilla="Créditos CCAF"
          erroresRecibidos={errorRecibido}
          isVisible={showModalError}
          onClose={() => setShowModalError(false)}
        />
      )}

      {showModalGuiaImportacion && (
        <GuiaImportacionPlantillas
          nombrePlantilla="Créditos CCAF"
          body={texto}
          imagen={ImagenEjemploCCAF}
          isVisible={showModalGuiaImportacion}
          onClose={() => setShowModalGuiaImportacion(false)}
        />
      )}

    </div>
  );
}
