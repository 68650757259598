import React from "react";
import { useForm } from "react-hook-form";

export default function AgregarConceptoFiniquito({sendFormData}) {
  const {
    register,
    reset,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useForm();

  const formatter = new Intl.NumberFormat("es-CL", {
    styles: "currency",
    currency: "CLP",
    minimumFractionDigits: 0,
  });

  const validacionTextInput = {
    required: { value: true, message: "Este campo es obligatorio" },
  };

  async function handleAgregarConcepto() {
    const passFormValidation = await trigger();
    if (!passFormValidation) {
      return null;
    }
    const data = getValues();
    const concepto = {
      tipoConcepto: data.tipoConcepto,
      descripcion: data.descripcion,
      valor: data.valor?.replace(/\./g, "").replace("$", "") || 0,
    };
    reset();
    return concepto;
  }

  return (
    <form>
      <div className="flex flex-wrap">
        <div className="div-Inputs-EditarHaber md:w-3/12 md:pr-3">
          <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
            Tipo
          </label>
          <select
            {...register("tipoConcepto", {
              required: { value: true, message: "Debe seleccionar un Haber" },
              validate: (value) => {
                if (value === "Seleccionar Haber") {
                  return "Obligatorio";
                }
                return true;
              },
            })}
            name="tipoConcepto"
            className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
          >
            <option selected value="Seleccionar Haber" hidden>
              Seleccionar Tipo
            </option>
            <option value="Haber" key="1">
            Haberes
          </option>
          <option value="Descuento" key="2">
            Descuentos
          </option>
          </select>
          <span
            className={`text-red-500 dark:text-red-400 text-xs italic ${
              !errors.tipoConcepto && "opacity-0 invisible"
            }`}
          >
            {errors.tipoConcepto ? errors.tipoConcepto.message : "hidden"}
          </span>
        </div>

        <div className="w-full md:w-5/12 md:px-3 md:mb-11 mb-9">
          <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
            Descripción
          </label>
          <input
            {...register("descripcion", validacionTextInput)}
            name="descripcion"
            className="appearance-none block w-full bg-gray-100 text-gray-700 border-gray-400 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
            id="grid-rut"
            type="text"
          />
        </div>

        <div className="div-Inputs-EditarHaber md:w-2/12 md:px-3">
          <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
            Valor
          </label>
          <input
            {...register("valor", {
              validate: (value) => {
                if (value === "" || value === "$" || value === "$0") {
                  return "Mayor a 0";
                }
                return true;
              },
              onChange: (e) => {
                setValue(
                  "valor",
                  "$" + formatter.format(getValues("valor")?.replace(/\D/g, ""))
                );
              },
            })}
            name="valor"
            class="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
            id="grid-rut"
            type="text"
          />
          <span
            className={`text-red-500 dark:text-red-400 text-xs italic ${
              !errors.valor && "opacity-0 invisible"
            }`}
          >
            {errors.valor ? errors.valor.message : "hidden"}
          </span>
        </div>

        <div className="div-Inputs-EditarHaber md:w-2/12 md:pl-3">
          <button
                  className="md:mt-6 hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none ease-in-out transition-all duration-400"
                  type="button"
              onClick={async () => sendFormData(await handleAgregarConcepto())}
          >
            Agregar
          </button>
        </div>

      </div>
    </form>
  );
}
