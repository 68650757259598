import { axiosPrivate } from "../common/axiosPrivate";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import LoginImage from "../assets/man-593333.jpg";
import UtilsFunctions from "../utils/UtilsFunctions";
import { useStateContext } from "../contexts/ContextProvider";
import axios from "axios";
import { DarkModeContext } from "../contexts/DarkModeProvider";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import {
  hideLoader,
  limpiarInfoMenuEdicion,
  modulosDisponiblesUsuario,
  showLoader,
} from "../Redux/Actions";

export default function SeleccionarModulo() {
  const {
    register,
    getValues,
    watch,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm();

  const { setUserMenu } = useStateContext();

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;

  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader());
      await Promise.all([dispatch(modulosDisponiblesUsuario())]);
      dispatch(hideLoader());
    };

    fetchData().catch((error) => {
      console.log(error.response.data.message);
      dispatch(hideLoader());
    });
  }, [dispatch]);

  const modulosDisponibles = useSelector(
    (state) => state.modulosDisponiblesUsuario
  );

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("modulosDisponiblesUsuario"));
    };
  }, []);

  const campoObligatorio = {
    required: { value: true, message: "Obligatorio" },
  };

  function presionaTecla(event) {
    // console.log("Este es event", event)
    if (event.keyCode == 13) {
      handleLogin();
    }
  }

  async function handleGetUserMenu() {
    try {
      const tokenRut = localStorage.getItem("token-rut");
      const token = tokenRut.slice(0, 8);
      const tokenUser = localStorage.getItem("token-user");
      const validacionTextInput = {
        required: { value: true, message: "Obligatorio" },
      };
    
      const Authorization = {
        headers: {
          Authorization: `Bearer ${tokenUser}`,
          ClientId: `${token}`,
        },
      };    

      const data = {
        rolId: localStorage.getItem("token-rol-id"),
      };
      const response = await axiosPrivate.post(
        `${api_service}/api/v1/rol/modulo/${watch("modulo")}/menus`,
        data,
        Authorization
      );

      if (response.status === 200) {
        const data = response.data;
        setUserMenu(data);
        localStorage.setItem("userMenu", JSON.stringify(data));
      }
    } catch (error) {
      toast.dismiss()
      toast.error("Error al obtener menú del rol");
    }
  }

  async function handleLogin() {
    const value = getValues();

    const passFormValidation = await trigger();
    if (!passFormValidation) {
      return null;
    }
    setLoading(true);
    const data = getValues();
    try {
      setLoading(false);
      if (data.modulo === "1") {
        navigate("/seleccionar-empresa-periodo");
      }
      if (data.modulo === "2") {
        navigate("/seleccionar-empresa-centro-costo");
      }
      localStorage.setItem("moduloId", data.modulo);
      await handleGetUserMenu();
    } catch (error) {
      setLoading(false);
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al seleccionar módulo"
      );
    }
  }

  return (
    <div
      className="grid grid-cols-1 sm:grid-cols-2 h-screen w-full"
      onKeyDown={(event) => presionaTecla(event)}
    >
      <div className="hidden sm:block">
        <img className="w-full h-full object-cover" src={LoginImage} alt="" />
      </div>

      <div className="sm:bg-bluegray-200 bgLP00542248879581black/40 flex flex-col justify-center sm:px-3">
        <img
          className="absolute sm:hidden w-full h-full object-cover mix-blend-overlay"
          src={LoginImage}
          alt=""
        />

        <form
          className="relative max-w-[500px] w-full mx-auto bg-white p-4 rounded-lg"
          onSubmit={handleSubmit(handleLogin)}
        >
          <h2 className="text-4xl font-bold text-center py-6 ">
            Seleccionar Módulo
          </h2>

          <div className="relative flex flex-col py-2">
            <label htmlFor="modulo">Módulo</label>
            <select
              {...register("modulo", campoObligatorio)}
              name="modulo"
              className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
            >
              <option value="" hidden>
                Seleccionar Módulo
              </option>
              {modulosDisponibles.map((modulo) => (
                <option key={modulo.id} value={modulo.id}>
                  {modulo.nombre}
                </option>
              ))}
              {/* <option key="Remuneraciones" value="Remuneraciones">
                Remuneraciones
              </option>
              <option key="Vacaciones" value="Vacaciones">
                Vacaciones
              </option> */}
            </select>
            <span
              className={`text-red-500 text-xs italic ${
                !errors.modulo && "opacity-0 invisible"
              }`}
            >
              {errors.modulo ? errors.modulo.message : "hidden"}
            </span>
          </div>

          <button
            className="hover:scale-105 w-full my-5 py-2 bg-hercules-dark-primary-button text-white  font-semibold text-base px-8 rounded-xl shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-2 ease-in-out transition-all duration-400"
            type="submit"
          >
            Continuar
          </button>
        </form>
      </div>
      {loading && (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
          <div className="border-t-blue-ribbon-500 animate-spin ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
          <h2 className="text-center text-white text-xl font-semibold">
            Cargando...
          </h2>
          <p className="w-1/3 text-center text-white">
            Esto puede tardar unos segundos, por favor no cierres esta página.
          </p>
        </div>
      )}
    </div>
  );
}
