import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { AiFillHome } from "react-icons/ai";
import { MdCardTravel, MdOutlineFreeCancellation } from "react-icons/md";
import { RiSettings3Line } from "react-icons/ri";
import { BiCalendarCheck } from "react-icons/bi";
import { IoCalendarNumberOutline } from "react-icons/io5";
import UtilsFunctions from "../../../utils/UtilsFunctions";
import { DarkModeContext } from "../../../contexts/DarkModeProvider";

export default function SidebarItemVacaciones({ item, func }) {
  const location = useLocation();
  const { darkMode } = useContext(DarkModeContext);

  const SidebarTitleIcon = ({ title, color }) => {
    switch (title) {
      
      case "Inicio Vacaciones":
        if (color === "blanco" || darkMode) {
          return <AiFillHome className="h-6 w-6 ml-3" />;
        }
        if (color === "azul" && !darkMode) {
          return (
            <AiFillHome className="h-6 w-6 text-hercules-light-secondary-button ml-3" />
          );
        }

      case "Solicitar Vacaciones":
        if (color === "blanco" || darkMode) {
          return <MdCardTravel className="h-6 w-6 ml-3" />;
        }
        if (color === "azul" && !darkMode) {
          return (
            <MdCardTravel className="h-6 w-6 text-hercules-light-secondary-button ml-3" />
          );
        }

      case "Gestionar Vacaciones":
        if (color === "blanco" || darkMode) {
          return <RiSettings3Line className="h-6 w-6 ml-3" />;
        }
        if (color === "azul" && !darkMode) {
          return (
            <RiSettings3Line className="h-6 w-6 text-hercules-light-secondary-button ml-3" />
          );
        }

        case "Anular Vacaciones":
          if (color === "blanco" || darkMode) {
            return <MdOutlineFreeCancellation className="h-6 w-6 ml-3" />;
          }
          if (color === "azul" && !darkMode) {
            return (
              <MdOutlineFreeCancellation className="h-6 w-6 text-hercules-light-secondary-button ml-3" />
            );
          }  

        case "Agregar Periodo":
          if (color === "blanco" || darkMode) {
            return <BiCalendarCheck className="h-6 w-6 ml-3" />;
          }
          if (color === "azul" && !darkMode) {
            return (
              <BiCalendarCheck className="h-6 w-6 text-hercules-light-secondary-button ml-3" />
            );
          }
  
        case "Administrar Feriados":
        if (color === "blanco" || darkMode) {
          return <IoCalendarNumberOutline className="h-6 w-6 ml-3" />;
        }
        if (color === "azul" && !darkMode) {
          return (
            <IoCalendarNumberOutline  className="h-6 w-6 text-hercules-light-secondary-button ml-3" />
          );
        }

      default:
        return null;
    }
  };

  return (
    <>
      <div
        className={`mx-6 h-12 text-xl hover:cursor-pointer  ${
          location.pathname.includes(
            UtilsFunctions.toLowercaseWithoutAccents(item.path)
          )
            ? "font-normal dark:bg-hercules-dark-primary-button dark:text-white bg-white text-black rounded-md"
            : "font-normal hover:bg-hercules-pure-white hover:bg-opacity-10 rounded-md"
        }  ${
          location.pathname === "/" + item.path &&
          "dark:text-white hover:bg-opacity-100 text-black bg-opacity-100 bg-white dark:bg-hercules-dark-primary-button font-medium "
        } flex text-white  items-center text-base mb-1`}
      >
        {location.pathname.includes(
          UtilsFunctions.toLowercaseWithoutAccents(item.path)
        ) ? (
          <SidebarTitleIcon title={item.title} color="azul" />
        ) : (
          <SidebarTitleIcon title={item.title} color="blanco" />
        )}
        {/* Arriba van los iconos */}

        <Link
          to={item.path}
          key={item.title}
          onClick={() => {
            func();
          }}
        >
          <p
            className={`py-3 px-7 text-lg transition ease-in-out duration-300 srounded-b-md `}
          >
            {item.title}
          </p>
        </Link>

      </div>
    </>
  );
}
