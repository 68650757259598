import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { axiosPrivate } from "../../../common/axiosPrivate";
import UtilsFunctions from "../../../utils/UtilsFunctions";
import { useStateContext } from "../../../contexts/ContextProvider";
import { lastDayOfMonth } from "date-fns";

export default function NuevoAgregarMovimientoForm({
  movimientosSelect,
  sendFormData,
  modalData,
  listaEmpresas,
}) {
  const formatter = new Intl.NumberFormat("es-CL", {
    styles: "currency",
    currency: "CLP",
    minimumFractionDigits: 0,
  });

  const {
    register,
    reset,
    getValues,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      empresaPagadoraId: "",
      movimientoId: "",
    },
  });

  const [diasMovimiento, setDiasMovimiento] = useState("0");
  const [listaFiltradaMovimientos, setListaFiltradaMovimientos] = useState([]);
  const [listaEmpresasFiltrada, setListaEmpresasFiltrada] = useState([]);
  const [calculoPorBack, setCalculoPorBack] = useState(false);
  const { screenSize } = useStateContext();

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const periodoId = localStorage.getItem("periodoId");
  const periodoMes = localStorage.getItem("periodoMes");
  const periodoAnyo = localStorage.getItem("periodoAnyo");

    // Usar date-fns para obtener el último día del mes
    const firstDay = new Date(periodoAnyo, periodoMes - 1, 1);
    const lastDay = lastDayOfMonth(new Date(periodoAnyo, periodoMes - 1));

  // Formatear las fechas en el formato YYYY-MM-DD
  const minDate = firstDay.toISOString().split('T')[0];
  const maxDate = lastDay.toISOString().split('T')[0];
  
  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  useEffect(() => {
    if (movimientosSelect.length) {
      const filtrarLista = movimientosSelect.filter(
        (mov) =>
          mov.codigo !== "0" &&
          mov.codigo !== "1" &&
          // mov.codigo !== "2" && (Retiro)
          mov.codigo !== "7" &&
          mov.codigo !== "8"
      );
      setListaFiltradaMovimientos(filtrarLista);
    }

    if (watch("desde") !== "" && watch("hasta") !== "") {
      const diasDesde = new Date(watch("desde")).getTime();
      const diasHasta = new Date(watch("hasta")).getTime();
      const diferencia = diasHasta - diasDesde;
      const diasLicenciaMedica = isNaN(diferencia)
        ? 0
        : diferencia / (1000 * 60 * 60 * 24) + 1;
      setDiasMovimiento(diasLicenciaMedica);
    }
  }, [movimientosSelect, watch("desde"), watch("hasta")]);

  const validacionTextInput = {
    required: { value: true, message: "Obligatorio" },
  };

  async function cambiarValores(e) {
    const register = e.target.name;
    const data = getValues();
    if (register === "mImponiblePSubsidio") {
      setValue(
        "montoSubsidio",
        "$" +
          formatter.format(
            Math.round(
              (data.mImponiblePSubsidio.replace(/\D/g, "") / 30) *
                diasMovimiento
            )
          )
      );
      setValue(
        "baseImpLM",
        "$" +
          formatter.format(
            Math.round(
              (data.mImponiblePSubsidio.replace(/\D/g, "") / 30) *
                diasMovimiento
            )
          )
      );
      setValue(
        "baseImpLMSC",
        "$" +
          formatter.format(
            Math.round(
              (data.mImponiblePSubsidio.replace(/\D/g, "") / 30) *
                diasMovimiento
            )
          )
      );
      setValue(
        "sis",
        "$" +
          formatter.format(
            Math.round(
              (data.mImponiblePSubsidio.replace(/\D/g, "") / 30) *
                diasMovimiento *
                (data.sisEsteMes / 100)
            )
          )
      );
      setValue(
        "scLicenciaMedica",
        "$" +
          formatter.format(
            Math.round(
              (data.mImponiblePSubsidio.replace(/\D/g, "") / 30) *
                diasMovimiento *
                (data.porcentajeSeguroCesantia / 100)
            )
          )
      );
    }

    if (register === "baseImpLM") {
      setValue(
        "sis",
        "$" +
          formatter.format(
            Math.round(
              data.baseImpLM.replace(/\D/g, "") * (data.sisEsteMes / 100)
            )
          )
      );
    }

    if (register === "baseImpLMSC") {
      setValue(
        "scLicenciaMedica",
        "$" +
          formatter.format(
            Math.round(
              data.baseImpLMSC.replace(/\D/g, "") *
                (data.porcentajeSeguroCesantia / 100)
            )
          )
      );
    }
  }

  async function calcularSubsidioLicencia() {
    const passFormValidation = await trigger(["desde", "hasta"]);
    if (!passFormValidation) {
      return null;
    }
    toast.loading("Calculando", estilosAlerta);
    try {
      const data = {
        trabajadorId: modalData.trabajadorId,
        mes: periodoMes,
        anyo: periodoAnyo,
      };
      const respuesta = await axiosPrivate.post(
        `${api_service}/api/v1/trabajadores/calculo-subsidio`,
        data,
        Authorization
      );
      // console.log("respuesta", respuesta);
      if (respuesta.status == 200) {
        setValue("tipoContrato", respuesta.data.tipoContrato);
        setValue(
          "antiguedadMasOnceAnyos",
          respuesta.data.antiguedadMasOnceAnyos
        );

        setValue(
          "montoSubsidio",
          "$" +
            UtilsFunctions.integerFormatter(
              Math.round(
                (parseInt(respuesta.data.montoImponible) / 30) * diasMovimiento
              )
            )
        );
        setValue(
          "mImponiblePSubsidio",
          "$" +
            UtilsFunctions.integerFormatter(
              parseInt(respuesta.data.montoImponible)
            )
        );
        setValue(
          "baseImpLM",
          "$" +
            UtilsFunctions.integerFormatter(
              Math.round(
                (parseInt(respuesta.data.montoImponible) / 30) * diasMovimiento
              )
            )
        );
        setValue(
          "baseImpLMSC",
          "$" +
            UtilsFunctions.integerFormatter(
              Math.round(
                (parseInt(respuesta.data.montoImponible) / 30) * diasMovimiento
              )
            )
        );
        setValue(
          "sis",
          "$" +
            UtilsFunctions.integerFormatter(
              Math.round(
                (parseInt(respuesta.data.montoImponible) / 30) *
                  diasMovimiento *
                  (respuesta.data.sis / 100)
              )
            )
        );
        setValue(
          "scLicenciaMedica",
          "$" +
            UtilsFunctions.integerFormatter(
              Math.round(
                (parseInt(respuesta.data.montoImponible) / 30) *
                  diasMovimiento *
                  (respuesta.data.porcentajeSeguroCesantia / 100)
              )
            )
        );
        setValue(
          "porcentajeSeguroCesantia",
          respuesta.data.porcentajeSeguroCesantia
        );
        setValue("sisEsteMes", respuesta.data.sis);
        setCalculoPorBack(true);

        toast.dismiss();
        toast.success("Calculado con éxito", estilosAlerta);
      }
    } catch (error) {
      console.log("error", error);
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al calcular, por favor ingrese los datos manualmente",
        estilosAlerta
      );
    }
  }

  async function agregarMovimientos() {
    const passFormValidation = await trigger();

    if (!passFormValidation) {
      return null;
    }

    const infoValue = getValues();

    let movimiento;
    if (
      infoValue.movimiento.codigo === "3" ||
      infoValue.movimiento.codigo === "6"
    ) {
      movimiento = {
        contratoId: modalData.contratoId,
        anexoContratoId:
          modalData.version !== 1 ? modalData.anexoContratoId : null,
        movimientoPersonalId: infoValue.movimiento.id,
        fechaMovimientoDesde:
          infoValue.desde == ""
            ? null
            : `${infoValue.desde.split("-")[2]}-${
                infoValue.desde.split("-")[1]
              }-${infoValue.desde.split("-")[0]}`,
        fechaMovimientoHasta:
          infoValue.hasta == ""
            ? null
            : `${infoValue.hasta.split("-")[2]}-${
                infoValue.hasta.split("-")[1]
              }-${infoValue.hasta.split("-")[0]}`,
        // montoImponible sera null hasta que se elimine en back
        montoImponible: null,
        periodoId: periodoId,
        // Estos tienen informacion en Subsidios y Accidentes, en los demas mov va null
        pagadorSubsidioId: infoValue.empresaPagadoraId,
        baseImponibleMesAnterior:
          infoValue.mImponiblePSubsidio?.replace(/\./g, "").replace("$", "") ||
          0,
        baseSisSubsidio:
          infoValue.baseImpLM?.replace(/\./g, "").replace("$", "") || 0,
        baseSeguroCesantiaSubsidio:
          infoValue.baseImpLMSC?.replace(/\./g, "").replace("$", "") || 0,
        sisSubsidio: infoValue.sis?.replace(/\./g, "").replace("$", "") || 0,
        seguroCesantiaSubsidio:
          infoValue.scLicenciaMedica?.replace(/\./g, "").replace("$", "") || 0,
      };
    } else {
      movimiento = {
        contratoId: modalData.contratoId,
        anexoContratoId:
          modalData.version !== 1 ? modalData.anexoContratoId : null,
        movimientoPersonalId: infoValue.movimiento.id,
        fechaMovimientoDesde:
          infoValue.desde == ""
            ? null
            : `${infoValue.desde.split("-")[2]}-${
                infoValue.desde.split("-")[1]
              }-${infoValue.desde.split("-")[0]}`,
        fechaMovimientoHasta:
          infoValue.hasta == ""
            ? null
            : `${infoValue.hasta.split("-")[2]}-${
                infoValue.hasta.split("-")[1]
              }-${infoValue.hasta.split("-")[0]}`,
        // montoImponible sera null hasta que se elimine en back
        montoImponible: null,
        periodoId: periodoId,
        // Estos tienen informacion en Subsidios y Accidentes, en los demas mov va null
        pagadorSubsidioId: null,
        baseImponibleMesAnterior: null,
        baseSisSubsidio: null,
        baseSeguroCesantiaSubsidio: null,
        sisSubsidio: null,
        seguroCesantiaSubsidio: null,
      };
    }
    setCalculoPorBack(false);
    reset();
    setDiasMovimiento("0");
    return movimiento;
  }

  // console.log("Watch", watch());
  // console.log("calculoPorBack", calculoPorBack);

  return (
    <form>
      <h3 className="text-2xl font-normal leading-normal mb-5 md:mb-3 dark:text-white text-hercules-light-font-secondary">
        Movimientos Trabajador
      </h3>
      <div className="md:flex md:flex-wrap">
        <div className="div-Inputs-EditarHaber md:w-5/12 md:pr-3">
          <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
            Movimiento
          </label>
          <select
            {...register("movimientoId", {
              onChange: (e) => {
                const movimiento =
                  listaFiltradaMovimientos[e.target.selectedIndex - 1];
                setValue("movimiento", movimiento);

                if (movimiento.codigo === "3") {
                  const filtrarEmpresas = listaEmpresas.filter(
                    (empresa) => empresa.codigoMovimientoPersonal === "3"
                  );
                  setListaEmpresasFiltrada(filtrarEmpresas);
                }
                if (movimiento.codigo === "6") {
                  const filtrarEmpresas = listaEmpresas.filter(
                    (empresa) => empresa.codigoMovimientoPersonal === "6"
                  );
                  setListaEmpresasFiltrada(filtrarEmpresas);
                }
                setValue("desde", "");
                setValue("hasta", "");
                setDiasMovimiento("0");
                setValue("montoSubsidio", "");
                setValue("mImponiblePSubsidio", "");
                setValue("baseImpLM", "");
                setValue("baseImpLMSC", "");
                setValue("scLicenciaMedica", "");
                setValue("sis", "");
                setValue("antiguedadMasOnceAnyos", "");
                setValue("empresaPagadoraId", "");
                setValue("porcentajeSeguroCesantia", "");
                setValue("sisEsteMes", "");
                setValue("tipoContrato", "");
              },
              ...validacionTextInput,
            })}
            name="movimientoId"
            className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
          >
            <option value="" hidden key="">
              Seleccionar
            </option>
            {listaFiltradaMovimientos.map((movimiento, i) => (
              <option key={i} value={movimiento.id}>
                {movimiento.glosa}
              </option>
            ))}
          </select>
          <span
            className={`text-red-500 dark:text-red-400 text-xs italic ${
              !errors.movimientoId && "opacity-0 invisible"
            }`}
          >
            {errors.movimientoId ? errors.movimientoId.message : "hidden"}
          </span>
        </div>

        {watch("movimiento.obligatoriaFechaDesde") && (
          <div className="div-Inputs-EditarHaber md:w-2/12 md:px-3">
            <label
              className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
              htmlFor="grid-last-name"
            >
              Desde
            </label>
            <input
              {...register("desde", validacionTextInput)}
              name="desde"
              onChange={(e) => {
                setValue("desde", e.target.value);
                cambiarValores(e);
              }}
              min={minDate}
              max={maxDate}
              className=" hover:cursor-pointer appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-last-name"
              type="date"
            />
            <span
              className={`text-red-500 dark:text-red-400 text-xs italic ${
                !errors.desde && "opacity-0 invisible"
              }`}
            >
              {errors.desde ? errors.desde.message : "hidden"}
            </span>
          </div>
        )}

        {watch("movimiento.obligatoriaFechaHasta") && (
          <div className="div-Inputs-EditarHaber md:w-2/12 md:px-3">
            <label
              className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
              htmlFor="grid-last-name"
            >
              Hasta
            </label>
            <input
              {...register("hasta", validacionTextInput)}
              name="hasta"
              min={minDate}
              max={maxDate}
              onChange={(e) => {
                setValue("hasta", e.target.value);
                cambiarValores(e);
              }}
              className=" hover:cursor-pointer appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-last-name"
              type="date"
            />
            <span
              className={`text-red-500 dark:text-red-400 text-xs italic ${
                !errors.hasta && "opacity-0 invisible"
              }`}
            >
              {errors.hasta ? errors.hasta.message : "hidden"}
            </span>
          </div>
        )}

        {watch("movimientoId") === "4" || watch("movimientoId") === "7" ? (
          <div className="w-full md:w-2/12 md:pl-3 md:mt-6">
            <button
              className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 ease-in-out transition-all duration-400"
              type="button"
              onClick={() => {
                calcularSubsidioLicencia();
              }}
            >
              Calcular
            </button>
          </div>
        ) : null}
      </div>

      {watch("movimientoId") !== "" ? (
        <div className="mt-9 md:mt-0">
          <div className="md:flex md:flex-wrap">
            {(watch("movimientoId") === "4" && calculoPorBack) ||
            watch("movimientoId") === "5" ||
            (watch("movimientoId") === "7" && calculoPorBack) ||
            watch("movimientoId") === "10" ? (
              <div className="w-full md:w-3/12 md:pr-3 md:mb-11 mb-9">
                <label
                  className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                  htmlFor="grid-first-name"
                >
                  Dias de movimiento
                </label>
                <input
                  disabled
                  value={diasMovimiento}
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                />
              </div>
            ) : null}

            {(watch("movimientoId") === "4" || watch("movimientoId") === "7") &&
            calculoPorBack ? (
              <div className="w-full md:w-3/12 md:px-3 md:mb-11 mb-9">
                <label
                  className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                  htmlFor="grid-first-name"
                >
                  Monto Subsidio
                </label>
                <input
                  {...register("montoSubsidio", {
                    onChange: () => {
                      setValue(
                        "montoSubsidio",
                        "$" +
                          formatter.format(
                            getValues("montoSubsidio")?.replace(/\D/g, "")
                          )
                      );
                    },
                  })}
                  name="montoSubsidio"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="montoSubsidio"
                  type="text"
                />
              </div>
            ) : null}

            {watch("movimientoId") === "4" && calculoPorBack ? (
              <div className="div-Inputs-EditarHaber md:w-3/12 md:px-3">
                <label
                  className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                  htmlFor="grid-first-name"
                >
                  Monto imponible para subsidio
                </label>
                <input
                  {...register("mImponiblePSubsidio", {
                    validate: (value) => {
                      if (value === "" || value === "$" || value === "$0") {
                        return "Mayor a $0";
                      }
                      return true;
                    },
                    onChange: (e) => {
                      setValue(
                        "mImponiblePSubsidio",
                        "$" +
                          formatter.format(
                            getValues("mImponiblePSubsidio")?.replace(/\D/g, "")
                          )
                      );
                      cambiarValores(e);
                    },
                  })}
                  name="mImponiblePSubsidio"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="mImponiblePSubsidio"
                  type="text"
                />
                <span
                  className={`text-red-500 dark:text-red-400 text-xs italic ${
                    !errors.mImponiblePSubsidio && "opacity-0 invisible"
                  }`}
                >
                  {errors.mImponiblePSubsidio
                    ? errors.mImponiblePSubsidio.message
                    : "hidden"}
                </span>
              </div>
            ) : null}

            {(watch("movimientoId") === "4" || watch("movimientoId") === "7") &&
            calculoPorBack ? (
              <div className="div-Inputs-EditarHaber md:w-3/12 md:pl-3">
                <label
                  className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                  htmlFor="grid-first-name"
                >
                  Base imponible licencia médica
                </label>
                <input
                  {...register("baseImpLM", {
                    validate: (value) => {
                      if (value === "" || value === "$" || value === "$0") {
                        return "Mayor a $0";
                      }
                      return true;
                    },
                    onChange: (e) => {
                      setValue(
                        "baseImpLM",
                        "$" +
                          formatter.format(
                            getValues("baseImpLM")?.replace(/\D/g, "")
                          )
                      );
                      cambiarValores(e);
                    },
                  })}
                  name="baseImpLM"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="baseImpLMbaseImpLM"
                  type="text"
                />
                <span
                  className={`text-red-500 dark:text-red-400 text-xs italic ${
                    !errors.baseImpLM && "opacity-0 invisible"
                  }`}
                >
                  {errors.baseImpLM ? errors.baseImpLM.message : "hidden"}
                </span>
              </div>
            ) : null}
          </div>

          {(watch("movimientoId") === "4" || watch("movimientoId") === "7") &&
          calculoPorBack ? (
            <div className="md:flex md:flex-wrap">
              <div className="div-Inputs-EditarHaber md:w-3/12 md:pr-3">
                <label
                  className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                  htmlFor="grid-first-name"
                >
                  Base imponible licencia médica S.C.
                </label>
                <input
                  {...register("baseImpLMSC", {
                    validate: (value) => {
                      if (value === "" || value === "$" || value === "$0") {
                        return "Mayor a $0";
                      }
                      return true;
                    },
                    onChange: (e) => {
                      setValue(
                        "baseImpLMSC",
                        "$" +
                          formatter.format(
                            getValues("baseImpLMSC")?.replace(/\D/g, "")
                          )
                      );
                      cambiarValores(e);
                    },
                  })}
                  name="baseImpLMSC"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="baseImpLMSC"
                  type="text"
                />
                <span
                  className={`text-red-500 dark:text-red-400 text-xs italic ${
                    !errors.baseImpLMSC && "opacity-0 invisible"
                  }`}
                >
                  {errors.baseImpLMSC ? errors.baseImpLMSC.message : "hidden"}
                </span>
              </div>

              <div className="w-full md:w-3/12 md:px-3 md:mb-11 mb-9">
                <label
                  className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                  htmlFor="grid-first-name"
                >
                  S.C. Licencia médica
                </label>
                <input
                  disabled
                  {...register("scLicenciaMedica")}
                  name="scLicenciaMedica"
                  className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="scLicenciaMedica"
                  type="text"
                />
              </div>

              <div className="w-full md:w-3/12 md:px-3 md:mb-11 mb-9">
                <label
                  className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                  htmlFor="grid-first-name"
                >
                  S.I.S.
                </label>
                <input
                  disabled
                  {...register("sis")}
                  name="sis"
                  className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="sis"
                  type="text"
                />
              </div>

              <div className="div-Inputs-EditarHaber md:w-3/12 md:px-3">
                <label
                  className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                  htmlFor="grid-last-name"
                >
                  Empresa pagadora de subsidio
                </label>
                <select
                  {...register("empresaPagadoraId", {
                    required: {
                      value: true,
                      message: "Obligatorio",
                    },
                    validate: (value) => value !== "",
                  })}
                  name="empresaPagadoraId"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                >
                  <option value="" hidden key="">
                    Seleccionar Empresa
                  </option>
                  {listaEmpresasFiltrada.map((empresa, i) => (
                    <option key={i} value={empresa.id}>
                      {empresa.glosa}
                    </option>
                  ))}
                </select>
                <span
                  className={`text-red-500 dark:text-red-400 text-xs italic ${
                    !errors.empresaPagadoraId && "opacity-0 invisible"
                  }`}
                >
                  {errors.empresaPagadoraId
                    ? errors.empresaPagadoraId.message
                    : "hidden"}
                </span>
              </div>
            </div>
          ) : null}

          {((watch("movimientoId") === "4" || watch("movimientoId") === "7") &&
            watch("sis") !== "") ||
          (watch("movimientoId") !== "4" && watch("movimientoId") !== "7") ? (
            <div className="mb-9">
              <button
                className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none ease-in-out transition-all duration-400"
                type="button"
                onClick={async () => sendFormData(await agregarMovimientos())}
              >
                Agregar
              </button>
            </div>
          ) : null}
        </div>
      ) : null}
    </form>
  );
}
