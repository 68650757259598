import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { axiosPrivate } from "../../common/axiosPrivate";
import { FiPlus, FiMinus } from "react-icons/fi";
import { getModulosHolding, getRoles, hideLoader, limpiarInfoMenuEdicion, showLoader } from "../../Redux/Actions";
import UtilsFunctions from "../../utils/UtilsFunctions";
import { toast } from "react-hot-toast";
import { useStateContext } from "../../contexts/ContextProvider";

const getMenusIds = (menus) => {
  const ids = [];
  menus.forEach((menu) => {
    ids.push(menu.id.toString());
    if (menu.childrens.length > 0) {
      const childrensIds = getMenusIds(menu.childrens);
      ids.push(...childrensIds);
    }
  });
  return ids;
};

const checkMenusState = (menus, menusId) => {
  const checkedMenus = [];
  menus.forEach((menu) => {
    const newMenu = { ...menu };
    if (menusId.includes(menu.id.toString())) {
      newMenu.checked = true;
    } else {
      newMenu.checked = false;
    }
    if (menu.childrens?.length > 0) {
      const childrensMenus = checkMenusState(menu.childrens, menusId);
      newMenu.childrens = childrensMenus;
      checkedMenus.push(newMenu);
    } else {
      checkedMenus.push(newMenu);
    }
  });
  return checkedMenus;
};

const Accesos = () => {
  const { activeMenu, screenSize, refreshTable, setRefreshTable, } = useStateContext();

  const isMobile = screenSize <= 768;
const estilosAlerta = {
  style: {
    marginTop: isMobile ? "80px" : "",
  },
};

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      moduloId: "",
      rolId: "",
      checkedMenus: "",
    }
  });
  
  const [menus, setMenus] = useState([]);

  const REACT_APP_API_HERCULES_SERVICE =
    process.env.REACT_APP_API_HERCULES_SERVICE;

  async function getAllMenus(moduloId) {
    try {
      const tokenRut = localStorage.getItem("token-rut");
      const token = tokenRut.slice(0, 8);
      const tokenUser = localStorage.getItem("token-user");

      const Authorization = {
        headers: {
          Authorization: `Bearer ${tokenUser}`,
          ClientId: `${token}`,
        },
      };

      const info = await axiosPrivate.get(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/rol/modulo/${moduloId}/menus`,
        Authorization
      );

      if (info.status === 200) {
        const menuSinInicio = info.data.filter((e) => e.title !== "Inicio Vacaciones");
        setMenus(menuSinInicio);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getAllMenusByRolId(rolId) {
    try {
      toast.loading("Cargando", estilosAlerta);
      const tokenRut = localStorage.getItem("token-rut");
      const token = tokenRut.slice(0, 8);
      const tokenUser = localStorage.getItem("token-user");

      const Authorization = {
        headers: {
          Authorization: `Bearer ${tokenUser}`,
          ClientId: `${token}`,
        },
      };

      const data = {
        rolId: rolId,
      };
      const info = await axiosPrivate.post(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/rol/modulo/${watch("moduloId")}/menus`,
        data,
        Authorization
      );

      if (info.status === 200 && info.data.length > 0) {
        const userMenus = getMenusIds(info.data);
        const newMenus = checkMenusState(menus, userMenus);
        console.log("newMenus", newMenus)
        setMenus(newMenus);
        setValue("checkedMenus", userMenus);
      } else {
        const userMenus = [];
        const newMenus = checkMenusState(menus, userMenus);
        setMenus(newMenus);
        setValue("checkedMenus", userMenus);
      }
      toast.dismiss();
      toast.success("Menús cargados con éxito", estilosAlerta);
    } catch (error) {
      toast.dismiss();
      toast.error("Error al cargar los menús asociados al rol seleccionado", estilosAlerta);
      console.log(error);
    }
  }

  useEffect(() => {
    dispatch(getRoles());
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader());
      await Promise.all([
        dispatch(getModulosHolding()),
      ]);
      dispatch(hideLoader());
    };

    fetchData().catch((error) => {
      console.log(error.response.data.message);
      dispatch(hideLoader());
    });
  }, [refreshTable, dispatch]);

  const infoModulosHolding = useSelector(
    (state) => state.arrayModulosHolding
  );

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("arrayModulosHolding"));
    };
  }, []);


  const roles = useSelector((state) => state.roles);

  function handleCheckMenu(e) {
    const menuId = parseInt(e.target.value);
    const isChecked = e.target.checked;

    const parents = [];

    const findMenu = (menuId, menus) => {
      for (let i = 0; i < menus.length; i++) {
        if (menus[i].id === menuId) {
          return menus[i];
        } else if (menus[i].childrens) {
          const menu = findMenu(menuId, menus[i].childrens);
          if (menu) {
            parents.push(menus[i]);
            return menu;
          }
        }
      }
    };

    const menu = findMenu(menuId, menus);

    const checkMenu = (menu, isChecked) => {
      menu.checked = isChecked;
      if (menu.childrens) {
        menu.childrens.forEach((child) => checkMenu(child, isChecked));
      }
    };

    checkMenu(menu, isChecked);

    parents.forEach((parent) => {
      const intermediateChecked = parent.childrens.some(
        (child) => child.checked
      );
      parent.checked = intermediateChecked;
    });

    const getCheckedMenus = (menus) => {
      let checkedMenus = [];
      menus.forEach((menu) => {
        if (menu.checked) {
          checkedMenus.push(menu.id.toString());
        }
        if (menu.childrens) {
          checkedMenus = checkedMenus.concat(getCheckedMenus(menu.childrens));
        }
      });
      return checkedMenus;
    };

    const checkedMenus = getCheckedMenus(menus);

    setValue("checkedMenus", checkedMenus);
  }

  const [openMenu, setOpenMenu] = useState({
    edicion: false,
    parametros: false,
    movimientos: false,
    informes: false,
    herramientas: false,
    configuracion: false,
  });

  function renderMenuTree(menus, level = 0, parent = null) {
    function handleOpenMenu(menu) {
      const menuTitle = menu.title
        .toLowerCase()
        .replace("ó", "o")
        .replace("á", "a");

      if (
        menuTitle === "parametros" &&
        openMenu.parametros &&
        openMenu.configuracion
      ) {
        setOpenMenu({
          ...openMenu,
          parametros: !openMenu.parametros,
          configuracion: false,
        });
      } else if (menu.childrens) {
        setOpenMenu({
          ...openMenu,
          [menuTitle]: !openMenu[menuTitle],
        });
      }
    }

    return (
      <ul className="">
        {menus.map((menu) => {
          return (
            <React.Fragment key={menu.id}>
              <li
                className={`pl-4 
              mb-1
              ${parent?.title === "Edición" && !openMenu.edicion && "hidden"}
              ${
                parent?.title === "Parámetros" &&
                !openMenu.parametros &&
                "hidden"
              }
              ${
                parent?.title === "Configuración" &&
                !openMenu.configuracion &&
                "hidden"
              }
              ${
                parent?.title === "Movimientos" &&
                !openMenu.movimientos &&
                "hidden"
              }
              ${parent?.title === "Informes" && !openMenu.informes && "hidden"} 
              ${
                parent?.title === "Herramientas" &&
                !openMenu.herramientas &&
                "hidden"
              }
              ${
                menu.childrens?.length > 0
                  ? ""
                  : "odd:bg-bluegray-100 dark:odd:bg-light-accent dark:odd:bg-opacity-20"
              }
              `}
              >
                <div className="flex items-center ">
                  <div
                    className={`${
                      level === 1
                        ? menu?.childrens?.length > 0
                          ? "pl-6"
                          : "pl-12"
                        : ""
                    } ${level === 2 ? "pl-20" : ""} flex items-center  } py-1`}
                  >

                    {/* esta funcion indica si se muestran los signos "+"/"-" o si no se muestra nada cuando no hay submenu  */}
                    {menu?.childrens?.length > 0 ? (
                      openMenu[
                        menu?.title
                          .toLowerCase()
                          .replace("ó", "o")
                          .replace("á", "a")
                      ] ? (
                        <FiMinus
                          className="mr-2 text-hercules-light-font-secondary dark:text-dark-primary cursor-pointer"
                          onClick={() => handleOpenMenu(menu)}
                        />
                      ) : (
                        <FiPlus
                          className="mr-2 text-hercules-light-font-secondary dark:text-dark-primary cursor-pointer"
                          onClick={() => handleOpenMenu(menu)}
                        />
                      )
                    ) : null}
                    <input
                      {...register("checkedMenus", {
                        // required: {
                        //   value: true,
                        //   message: "Debe seleccionar al menos un menú",
                        // },
                        onChange: (e) => handleCheckMenu(e),
                      })}
                      type="checkbox"
                      value={menu.id}
                      id={menu.id}
                      className="mr-2 w-4 cursor-pointer h-4 text-indigo-600 dark:text-light-accent border-gray-300 rounded"
                    />
                    <label
                      htmlFor={menu.id}
                      className={`text-md cursor-pointer text-hercules-light-font-secondary dark:text-dark-primary ${
                        menu.childrens.length > 0 ? "font-bold" : "font-medium"
                      }`}
                    >
                      {menu.title}
                    </label>
                  </div>
                </div>
              </li>
              {menu?.childrens?.length > 0 &&
                renderMenuTree(menu.childrens, level + 1, menu)}
            </React.Fragment>
          );
        })}
      </ul>
    );
  }

  async function onSubmit(data) {
    toast.loading("Guardando", estilosAlerta);
    const tokenRut = localStorage.getItem("token-rut");
    const token = tokenRut.slice(0, 8);
    const tokenUser = localStorage.getItem("token-user");
    const payload = {
      idRol: data.rolId,
      menusId: data.checkedMenus,
    };

    const Authorization = {
      headers: {
        Authorization: `Bearer ${tokenUser}`,
        ClientId: `${token}`,
      },
    };
    
    await axiosPrivate
      .put(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/rol/modulo/${data.moduloId}/menus/rol`,
        payload,
        Authorization
      )
      .then((res) => {
        toast.dismiss();
        toast.success("Rol editado con éxito", estilosAlerta);
        console.log(res);
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(err.response.data.message ? err.response.data.message : "Error al guardar los cambios", estilosAlerta);
        console.log(err);
      });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} action="">
      <div
        className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
          activeMenu && "md:w-[calc(100vw_-_23rem)]"
        }`}
      >
        <div className="titulo-Menu">Parámetros / Configuración</div>
        <div className="titulo-Pagina">
          Accesos
        </div>

        <div className="pt-3 flex flex-wrap">

        <div className="relative w-full md:w-3/12 md:pr-3 md:mb-0 mb-6">
            <label
              className="block  tracking-wide text-hercules-light-font-secondary dark:text-white text-sm font-bold mb-2"
              htmlFor="grid-last-name"
            >
              Seleccionar Módulo
            </label>
            <select
              {...register("moduloId", {
                required: { value: true, message: "Debe seleccionar un módulo" },
                onChange: (e) => {getAllMenus(e.target.value); setValue("rolId", "")}
              })}
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-state"
            >
              <option value="" className="hidden">
                Seleccionar Módulo
              </option>
              {infoModulosHolding &&
                infoModulosHolding.map((modulo) => (
                  <option key={modulo.id} value={modulo.id}>
                    {modulo.nombre}
                  </option>
                ))}
            </select>
          </div>

          {watch("moduloId") !== "" ? 
          <div className="relative w-full md:w-3/12 md:pl-3 md:mb-0 mb-6">
            <label
              className="block  tracking-wide text-hercules-light-font-secondary dark:text-white text-sm font-bold mb-2"
              htmlFor="grid-last-name"
            >
              Seleccionar rol
            </label>
            <select
              {...register("rolId", {
                required: { value: true, message: "Debe seleccionar un rol" },
                onChange: (e) => getAllMenusByRolId(e.target.value),
              })}
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-state"
            >
              <option value="" className="hidden">
                Seleccionar rol
              </option>
              {roles &&
                roles.map((rol) => (
                  <option key={rol.id} value={rol.id}>
                    {UtilsFunctions.rolFormatter(rol.nombre)}
                  </option>
                ))}
            </select>
          </div>
          : null}
        </div>

        {menus.length > 0  && watch("rolId") !== "" ?
        <>
        <div className="h-full rounded-lg md:pt-5 md:mr-12">
          <div className="w-full h-608 overflow-y-auto border-whisper-200 border-1  rounded">
            {menus && renderMenuTree(menus)}
          </div>
        </div>
        <button
          type="submit"
          className="mt-3 bg-hercules-light-primary-button text-white dark:text-dark-primary font-medium uppercase text-normal px-4 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        >
          Guardar Cambios
        </button>
        </>
         : null}
      </div>
    </form>
  );
};

export default Accesos;
