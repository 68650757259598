import axios from "axios";
import mem from "mem";

function getRefreshToken() {
    return localStorage.getItem("refresh-token");
}

function getRutEmpresa() {
    return localStorage.getItem("token-rut-slice");
}

const REACT_APP_API_HERCULES_SERVICE =
    process.env.REACT_APP_API_HERCULES_SERVICE;

const refreshTokenFn = async () => {
    try {
        const rutEmpresa = getRutEmpresa();
        const refreshToken = getRefreshToken();
        const response = await axios.post(
            `${REACT_APP_API_HERCULES_SERVICE}/api/v1/auth/refreshtoken`,
            {
                refreshToken: refreshToken,
            },
            {
                headers: {
                    ClientId: rutEmpresa,
                }
            }
        );

        if (response.status === 200) {
            localStorage.setItem("token-user", response.data.accessToken);
            localStorage.setItem("refresh-token", response.data.refreshToken);
            return response.data.accessToken;
        }
    } catch (error) {
        console.log(error);
    }
}

const maxAge = 10000;

export const memoizedRefreshToken = mem(refreshTokenFn, { maxAge });