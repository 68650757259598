import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, ips, limpiarInfoMenuEdicion, showLoader } from "../../Redux/Actions";
import { ColumnFilter } from "../../components/";
import { useStateContext } from "../../contexts/ContextProvider";
import SimpleTable from "../../components/SimpleTable";

export default function IPS() {
  const { refreshTable, activeMenu } = useStateContext();

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader());
      await Promise.all([
        dispatch(ips())
      ]);
      dispatch(hideLoader());
    };

    fetchData().catch((error) => {
      console.log(error.response.data.message);
      dispatch(hideLoader());
    });
  }, [refreshTable, dispatch]);

  const infoIPS = useSelector((state) => state.ips);

  useEffect(() =>{
    return () => {
      dispatch(limpiarInfoMenuEdicion("ips"))
    }
  },[])

  const COLUMNS = [
    {
      Header: "Nombre",
      accessor: "glosa",
      Filter: ColumnFilter,
    },
    {
      Header: "Código",
      accessor: "codigoLre",
      Filter: ColumnFilter,
    },
    {
      Header: "Código previred",
      accessor: "codigo",
      Filter: ColumnFilter,
    },
  ];
  return (
    <div
    className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
      activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">
        Edición
      </div>
      <div className="titulo-Pagina">
        IPS (Ex INP)
      </div>
      <div className="h-full md:mr-12 rounded-lg pt-5">
          <SimpleTable col={COLUMNS} tableData={infoIPS || []} exportCsvButton={false}/>
      </div>
    </div>
  );
}
