import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRoles, hideLoader, showLoader } from "../../Redux/Actions";
import { RolTable } from "../../components";
import { axiosPrivate } from "../../common/axiosPrivate";
import { useStateContext } from "../../contexts/ContextProvider";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";

export default function Roles2() {
  const { refreshTable, activeMenu, screenSize } = useStateContext();

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader());
      await Promise.all([dispatch(getRoles())]);
      dispatch(hideLoader());
    };

    fetchData().catch((error) => {
      console.log(error.response.data.message);
      dispatch(hideLoader());
    });
  }, [refreshTable, dispatch]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const roleValidation = {
    required: {
      value: true,
      message: "Obligatorio",
    },
    minLength: {
      value: 3,
      message: "Al menos 3 caracteres",
    },
    maxLength: {
      value: 20,
      message: "Máximo 20 caracteres",
    },
  };

  const onSubmit = async (data) => {
    try {
      toast.loading("Creando Rol", estilosAlerta);
      const payload = {
        nombre: "ROLE_" + data.nombre.toUpperCase().replace(" ", "_"),
      };
      const response = await axiosPrivate.post(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/rol/nuevo_rol`,
        payload,
        Authorization
      );
      if (response.status === 200) {
        toast.dismiss();
        toast.success("Rol creado con éxito", estilosAlerta);
        dispatch(getRoles());
        reset();
      }
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error("Error al crear Rol", estilosAlerta);
    }
  };

  const rolesInfo = useSelector((state) => state.roles);

  const REACT_APP_API_HERCULES_SERVICE =
    process.env.REACT_APP_API_HERCULES_SERVICE;

  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const COLUMNS = [
    {
      Header: "Nombre",
      accessor: "nombre",
      Cell: ({ value }) => {
        return (
          value
            .slice(5)
            .replace("_", " ")
            .toLowerCase()
            .slice(0, 1)
            .toUpperCase() +
          value.slice(5).replace("_", " ").toLowerCase().slice(1)
        );
      },
    },
    {
      Header: "Activo",
      accessor: "activo",
      Cell: ({ value }) => {
        if (value === true) {
          return "Si";
        } else {
          return "No";
        }
      },
    },
  ];

  return (
    <div
      className={`dark:shadow-none md:mt-12 md:ml-20 md:pr-20 mt-28 mx-7 pb-12 rounded-xl ${
        activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">Parámetros / Configuración</div>
      <div className="titulo-Pagina">Roles</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex pt-3">
          <div className="md:flex items-center pt-3 w-full md:mr-3">
            <div className="w-full md:w-1/3 ">
              <label
                className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                htmlFor="grid-last-name"
              >
                Nombre nuevo rol
              </label>
              <input
                {...register("nombre", roleValidation)}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="text"
              />
              <span
                    className={`text-red-500 dark:text-red-400 text-xs italic ${
                      !errors.nombre && "opacity-0 invisible"
                    }`}
                  >
                    {errors.nombre ? errors.nombre.message : "hidden"}
                  </span>
            </div>
            <div className="w-5/12 md:w-1/3 mb-6 md:mb-0">
              <button
                className="md:ml-3 w-full md:w-fit md:mt- bg-hercules-light-primary-button text-white dark:text-dark-primary font-medium uppercase text-normal px-4 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                type="submit"
              >
                Nuevo Rol
              </button>
            </div>
          </div>
        </div>
      </form>
      <div className="h-full md:mr-12 rounded-lg">
        <RolTable col={COLUMNS} tableData={rolesInfo} />
      </div>
    </div>
  );
}
