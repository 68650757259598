import React, { useState } from "react";
import { Link } from "react-router-dom";
import ModificarClave from "./Parametros/Configuracion/Usuarios/ModificarClave";

const UserProfile = () => {
  const handleLogout = () => {
    const userTheme = localStorage.getItem("userTheme");
    localStorage.clear();
    localStorage.setItem("userTheme", userTheme);
  };

  const [showModalPerfil, setShowModalPerfil] = useState(false);

  return (
    <>
      <div className={`relative `}>
        <ul className="absolute top-0 right-0 rounded z-10 mt-14 w-44 bg-light-gray dark:bg-hercules-dark-bg-100 dark:border-hercules-dark-bg-200  border border-gray-200 text-hercules-light-font-secondary dark:text-white font-medium text-md">
          <li
            onClick={() => {
              setShowModalPerfil(true);
            }}
            className="hover:bg-gray-100 dark:hover:bg-hercules-dark-secondary-button hover:dark:text-black rounded pl-4 py-2 cursor-pointer"
          >
            Modificar Clave
          </li>
          <Link to="/seleccionar-empresa-periodo">
            <li className="hover:bg-gray-100 dark:hover:bg-hercules-dark-secondary-button hover:dark:text-black rounded pl-4 py-2 cursor-pointer">
              Cambiar Empresa y Periodo
            </li>
          </Link>
          <Link to="/seleccionar-modulo">
            <li className="hover:bg-gray-100 dark:hover:bg-hercules-dark-secondary-button hover:dark:text-black rounded pl-4 py-2 cursor-pointer">
              Cambiar Módulo
            </li>
          </Link>
          <Link to={"/"}>
            <li
              onClick={() => handleLogout()}
              className="hover:bg-gray-100 dark:hover:bg-hercules-dark-secondary-button hover:dark:text-black rounded pl-4 py-2 cursor-pointer"
            >
              Cerrar Sesión
            </li>
          </Link>
        </ul>
      </div>

      {showModalPerfil ? (
        <ModificarClave
          isVisible={showModalPerfil}
          onClose={() => setShowModalPerfil(false)}
          // modalData={rowDataUsuarios}
        />
      ) : null}
    </>
  );
};

export default UserProfile;
