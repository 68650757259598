import React from "react";

export default function SeguroCesantiaPopUp({ onCloseAlert, enableSelect }) {
  return (
    <>
      <div
        className="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center"
        id="modal-id"
      >
        <div className="segundoDivModal max-w-md">
        <div className="tercerDivModal shadow-lg  max-h-[85vh]">
            {/*header*/}
            <div className="divHeaderModal border-slate-200">
              <h3 className="titulo-Modal">
                Seguro de Cesantía</h3>
            </div>
            <div  className="relative p-6 flex-auto overflow-auto dark:bg-hercules-dark-bg-200">
              <p className="mb-4 text-slate-500 text-lg leading-relaxed dark:text-white text-hercules-light-font-secondary">
                El seguro de cesantía empezó a regir desde octubre de 2002. Si
                su trabajador ingresó a trabajar antes de esa fecha y no ha
                suscrito seguro de cesantía, desmarque esta opción. En caso
                contrario, es obligación pagar seguro de cesantía.
              </p>
            </div>
            <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                onClick={() => {
                  onCloseAlert();
                }}
                type="button"
              >
                Cerrar
              </button>
              <button
                onClick={() => {
                  enableSelect();
                  onCloseAlert();
                }}
                className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-sm px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
                type="button"
              >
                Trabajador ingresó antes de octubre de 2002
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className=" opacity-25 fixed inset-0 z-40 bg-black"
        id="modal-id-backdrop"
      ></div>
    </>
  );
}
