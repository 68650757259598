import React from "react";
import { useForm } from "react-hook-form";
import UtilsFunctions from "../../utils/UtilsFunctions";
import { useStateContext } from "../../contexts/ContextProvider";
import { useState } from "react";
import { MdDateRange } from "react-icons/md";
import { toast } from "react-hot-toast";
import { axiosPrivate } from "../../common/axiosPrivate";

export default function CerrarAbrirMes() {
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const anyosData = UtilsFunctions.obtenerAnyosHastaActual();
  const { activeMenu, screenSize } = useStateContext();
  const [infoMeses, setInfoMeses] = useState([]);
  const [cerrado, setCerrar] = useState({
    Enero: false,
    Febrero: false,
    Marzo: false,
    Abril: false,
    Mayo: false,
    Junio: false,
    Julio: false,
    Agosto: false,
    Septiembre: false,
    Octubre: false,
    Noviembre: false,
    Diciembre: false,
  });

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  async function verMesesPorAnyo() {
    const anyo = getValues("periodoAnyo");
    console.log("año a buscar", anyo);
    const infoMesesAnyo = await axiosPrivate.get(
      `${api_service}/api/v1/periodos/año/${anyo}`,
      Authorization
    );
    console.log("infoMesesAnyo", infoMesesAnyo.data);

    if (Array.isArray(infoMesesAnyo.data)) {
      const nuevosEstados = {};
      infoMesesAnyo.data.forEach((objeto) => {
        const { mes, cerrado } = objeto;
        nuevosEstados[UtilsFunctions.integerToMonth(mes)] = cerrado;
      });
      setCerrar((prevState) => {
        return {
          ...prevState,
          ...nuevosEstados,
        };
      });
    }

    setInfoMeses(infoMesesAnyo.data);
  }

  async function cerrarAbrirMes(mes) {
    const fecha = new Date();
    const numeroMes = fecha.getMonth() + 1;
    const numeroAnyo = fecha.getFullYear();

    console.log("mes de", mes);
    toast.loading("Editando estado del periodo", estilosAlerta);
    const data = getValues();
    console.log("data", data);
    if (mes.anyo === numeroAnyo && mes.mes > numeroMes) {
      toast.dismiss();
      toast.error(
        "No se puede editar el estado de un mes posterior al mes actual",
        estilosAlerta
      );
    } else {
      try {
        const infoId = await axiosPrivate.get(
          `${api_service}/api/v1/periodos/mes/${mes.mes}/anyo/${data.periodoAnyo}`,
          Authorization
        );
        setCerrar({
          ...cerrado,
          [UtilsFunctions.integerToMonth(mes.mes)]:
            !cerrado[UtilsFunctions.integerToMonth(mes.mes)],
        });
        const response = await axiosPrivate.put(
          `${api_service}/api/v1/periodos/abrirCerrarPeriodo/${infoId.data.id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${tokenUser}`,
              ClientId: `${token}`,
            },
          }
        );
        toast.dismiss();
        toast.success("Estado del periodo actualizado con éxito", estilosAlerta);
      } catch (error) {
        console.log("error", error);
        toast.dismiss();
        toast.error(error.response.data.message? error.response.data.message : "Error al actualizar estado del periodo", estilosAlerta);
      }
    }
  }

  const renderizarDivMes = () => {
    return (
      <div className="grid grid-cols-3 gap-4 md:grid-cols-4 ">
        {infoMeses.map((mes) => (
          <div
            className="w-full py-4 flex flex-col items-center bg-white dark:bg-hercules-dark-bg-200 rounded-lg shadow-md dark:shadow-coolgray-800 border border-gray-300 border-color"
            key={mes.mes}
          >
            <MdDateRange className="dark:text-coolgray-50" size={34} />
            <span className="dark:text-white text-hercules-light-font-secondary uppercase tracking-wide text-xs font-bold my-2">
              {UtilsFunctions.integerToMonth(mes.mes)}
            </span>
            <label className="inline-flex relative items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={!cerrado[UtilsFunctions.integerToMonth(mes.mes)]}
                readOnly
              />
              <div
                onClick={() => cerrarAbrirMes(mes)}
                className="w-11 h-6 bg-gray-200 dark:bg-gray-500 rounded-full peer peer-focus:ring-green-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"
              ></div>
            </label>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div
      className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
        activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">Herramientas</div>
      <div className="titulo-Pagina">Cerrar o abrir mes en proceso</div>

      <form onSubmit={handleSubmit(verMesesPorAnyo)} className="pt-3 flex flex-wrap">
      <div className="w-4/12 md:w-fit pr-3 mb-3">
            <label
              htmlFor="periodoId"
              className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold"
            >
              Periodo
          </label>
          <select
            {...register("periodoAnyo", {
              onChange: () => {
                setInfoMeses([]);
              },
              required: { value: true, message: "Obligatorio" },
            })}
            className="block appearance-none w-24 bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          >
            <option hidden value="">
              Año
            </option>
            {anyosData.map((anyo, i) => (
              <option value={anyo} key={i}>
                {anyo}
              </option>
            ))}
          </select>
          <span
            className={`text-red-500 dark:text-red-400 text-xs italic ${
              !errors.periodoAnyo && "opacity-0 invisible"
            }`}
          >
            {errors.periodoAnyo ? errors.periodoAnyo.message : "hidden"}
          </span>
        </div>

        <div className="w-6/12 md:w-3/12 mt-3 pl-3 mb-6 md:mb-0">
          <button
            // onClick={() => verMesesPorAnyo()}
            className="mt-3 hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
          >
            Ver Meses
          </button>
        </div>
      </form>

      {infoMeses.length > 0 && (
        <>
          <div className="flex flex-wrap md:mt-2">

            <div className="flex flex-wrap md:w-2/12 mb-3 ">
              <div className="mb-6 md:mb-0 flex items-center justify-between">
                <span className="dark:text-white text-hercules-light-font-secondary uppercase tracking-wide text-xs font-bold mr-4 my-2">
                  Meses abiertos
                </span>
                <label className="inline-flex relative items-center">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    checked={true}
                    readOnly
                    disabled
                  />
                  <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-green-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>
                </label>
              </div>
            </div>

            {/* <span className="mb-2 border-[2px] border-hercules-light-font-secondary dark:border-white"></span> */}

            <div className="flex flex-wrap md:w-2/12 ml-3 mb-3">
              <div className="mb-6 md:mb-0 flex items-center justify-between">
                <span className="dark:text-white text-hercules-light-font-secondary uppercase tracking-wide text-xs font-bold mr-4 my-2">
                  Meses cerrados
                </span>
                <label className="inline-flex relative items-center">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    checked={false}
                    readOnly
                    disabled
                  />
                  <div className="w-11 h-6 bg-gray-200 dark:bg-gray-500 rounded-full peer peer-focus:ring-green-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>
                </label>
              </div>
            </div>

          </div>

          <div className=" relative h-full rounded-lg md:mr-12">
            {renderizarDivMes()}
            <p className="mt-5 ml-1 dark:text-white text-hercules-light-font-secondary">
              Al cerrar un mes no se podrá ingresar, modificar o eliminar ningún
              registro de dicho periodo
            </p>
          </div>
        </>
      )}
    </div>
  );
}
