import React, { useEffect, useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import {
  Card,
  Title,
  AreaChart,
  Grid,
  Metric,
  Text,
  BadgeDelta,
  Flex,
} from "@tremor/react";
import { useDispatch, useSelector } from "react-redux";
import {
  contratosFiniquitados,
  contratosVencidos,
  contratosVigentes,
  totalAportesUltimos6Meses,
  totalHsExtrasUltimos6Meses,
  totalLiquidacionesPeriodo,
  totalLiquidacionesUltimos6Meses,
  totalTrabajadores,
} from "../Redux/Actions";
import UtilsFunctions from "../utils/UtilsFunctions";

// Data formater que transforma el valor a millones
const dataFormatter = (value) => {
  // return `${value / 1000000}M`;
  return `$ ${(value / 1000000).toFixed(2)}M`;
};

const Inicio = () => {
  const periodoId = localStorage.getItem("periodoId");
  const dispatch = useDispatch();
  const periodoLogin = UtilsFunctions.getEmpresaPeriodoInfo().periodo

  useEffect(() => {
    dispatch(totalTrabajadores());
    dispatch(contratosVigentes());
    dispatch(contratosVencidos());
    dispatch(contratosFiniquitados());
    dispatch(totalLiquidacionesPeriodo(periodoId));
    dispatch(totalLiquidacionesUltimos6Meses());
    dispatch(totalAportesUltimos6Meses());
    dispatch(totalHsExtrasUltimos6Meses());
  }, [dispatch]);

  const infoTotalTrabajadores = useSelector((state) => state.totalTrabajadores);
  const infoContratosVencidos = useSelector((state) => state.contratosVencidos);
  const infoTotalLiquidaciones = useSelector(
    (state) => state.totalLiquidacionesPeriodo
  );
  const infoTotalLiquidacionesUltimosMeses = useSelector(
    (state) => state.totalLiquidacionesUltimosMeses
  );
  const infoTotalAportesUltimosMeses = useSelector(
    (state) => state.totalAportesUltimosMeses
  );
  const infoTotalHsExtrasUltimosMeses = useSelector(
    (state) => state.totalHsExtrasUltimosMeses
  );

  const { activeMenu } = useStateContext();

  const dataLiquidacionesUltimosMeses = infoTotalLiquidacionesUltimosMeses.map(
    (item) => ({
      ...item,
      "Sueldo Líquido": parseInt(item["Sueldo Líquido"].replace(/\$|\.|,/g, "")),
    })
  );

  const dataAportesUltimosMeses = infoTotalAportesUltimosMeses.map((item) => ({
    ...item,
    "Total Previsional": parseInt(item["Total Previsional"].replace(/\$|\.|,/g, "")),
    "Total CCAF": parseInt(item["Total CCAF"].replace(/\$|\.|,/g, "")),
    "Total Mutual": parseInt(item["Total Mutual"].replace(/\$|\.|,/g, "")),
  }));

  const dataHsExtrasUltimosMeses = infoTotalHsExtrasUltimosMeses.map((item) => ({
    ...item,
    "Valor Horas Extra": parseInt(item["Valor Horas Extra"].replace(/\$|\.|,/g, "")),
  }));

  const colors = {
    increase: "emerald",
    moderateIncrease: "emerald",
    unchanged: "orange",
    moderateDecrease: "rose",
    decrease: "rose",
  };

  return (
    <div
      className={`dark:shadow-none md:mt-12 mt-28 md:mx-14 px-6 pb-6 ${
        activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="text-4xl mb-6 pt-1 font-semibold text-hercules-light-font-secondary dark:text-dark-primary">
        Bienvenido a Hércules
      </div>
      <div className="h-full">
        <Grid numItemsLg={3} className="gap-6 mb-6">

        <Card>
            <Text>Total Liquidaciones {periodoLogin}</Text>
            <Flex
              justifyContent="start"
              alignItems="baseline"
              className="truncate space-x-3"
            >
              <Metric>{infoTotalLiquidaciones.metric}</Metric>
              <Text className="truncate">
                desde {infoTotalLiquidaciones.metricPrev}
              </Text>
            </Flex>
            <Flex justifyContent="start" className="space-x-2 mt-4">
              <BadgeDelta deltaType={infoTotalLiquidaciones.deltaType} />
              <Flex justifyContent="start" className="space-x-1 truncate">
                <Text color={colors[infoTotalLiquidaciones.deltaType]}>
                  {infoTotalLiquidaciones.delta}
                </Text>
                <Text className="truncate">respecto al mes anterior</Text>
              </Flex>
            </Flex>
          </Card>

          <Card className="flex items-center">
            <div className="mb-3">
              <Text>Trabajadores activos</Text>
              <Flex
                justifyContent="start"
                alignItems="baseline"
                className="truncate space-x-3"
              >
                <Metric>{infoTotalTrabajadores.metric}</Metric>
              </Flex>
            </div>
          </Card>

          <Card className="flex items-center">
            <div className="mb-3">
              <Text>Contratos vencidos</Text>
              <Flex
                justifyContent="start"
                alignItems="baseline"
                className="truncate space-x-3"
              >
                <Metric>{infoContratosVencidos.metric}</Metric>
              </Flex>
            </div>
          </Card>

        </Grid>

        <Grid numItemsMd={2} className="mb-6 gap-6">
          <Card className="" key={"carta4"}>
            <Title>Aporte Empleador Últimos 6 meses </Title>
            <AreaChart
              className="h-72 mt-2"
              data={dataAportesUltimosMeses}
              index="date"
              categories={["Total Previsional", "Total CCAF", "Total Mutual"]}
              colors={["orange", "indigo", "cyan"]}
              valueFormatter={dataFormatter}
              noDataText="Sin Información"
              />
          </Card>
          <Card className="">
            <Title>Total Horas Extras Últimos 6 meses </Title>
            <AreaChart
              className="h-72 mt-2"
              data={dataHsExtrasUltimosMeses}
              index="date"
              categories={["Valor Horas Extra"]}
              colors={["orange"]}
              valueFormatter={dataFormatter}
              noDataText="Sin Información"
            />
          </Card>
        </Grid>

        <Grid>
          <Card>
            <Title>Gastos previsionales Últimos 6 meses </Title>
            <AreaChart
              className="h-72 mt-2"
              data={dataLiquidacionesUltimosMeses}
              index="date"
              categories={["Sueldo Líquido"]}
              colors={["orange"]}
              valueFormatter={dataFormatter}
              noDataText="Sin Información"
            />
          </Card>
        </Grid>
      </div>
    </div>
  );
};

export default Inicio;
