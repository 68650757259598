import React, { useMemo, useState } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useFilters,
  usePagination,
  useRowSelect,
} from "react-table";
import {
  TbArrowsSort,
  TbSortAscending,
  TbSortDescending,
} from "react-icons/tb";
import {
  MdDelete,
  MdOutlineVisibility,
} from "react-icons/md";

const TablaHaberes = ({ col, tableData, botonEliminar, borrarHaber, botonEditarHaber, editarHaber }) => {
  // eslint-disable-next-line
  const columns = useMemo(() => col, []);
  var data = useMemo(() => [...tableData], [tableData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  return (
    <>
      <div
        className="h-96 overflow-auto border-x-1  bg-white dark:bg-hercules-dark-bg-200  dark:border-dark-bg-200 border-gray-300 border-t-1"
      >
        <table {...getTableProps()} className="w-full rounded-lg">
          <thead className="text-left sticky top-0 border-b-1 border-b-hercules-light-secondary dark:border-b-hercules-dark-secondary-button bg-hercules-light-table-header dark:bg-hercules-dark-bg-100">
            {headerGroups.map((headerGroup) => {
              const { key, ...restHeaderGroup } =
                headerGroup.getHeaderGroupProps();
              return (
                <>
                  <tr {...restHeaderGroup} className="h-10 flex-auto">
                    {headerGroup.headers.map((column) => {
                      const { key, ...restColumn } = column.getHeaderProps(
                        column.getSortByToggleProps()
                      );
                      return (
                        <>
                          <th 
                          {...restColumn}
                          className="py-2 px-4 text-hercules-light-font-secondary font-medium dark:text-white"
                          >
                            <div className="flex">
                              <span className="">
                                {column.render("Header")}
                              </span>
                              <span className="ml-1 mt-auto mb-auto">
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <TbSortDescending />
                                  ) : (
                                    <TbSortAscending />
                                  )
                                ) : (
                                  <TbArrowsSort />
                                )}
                              </span>
                            </div>
                          </th>
                        </>
                      );
                    })}
                    {botonEliminar && <th className="px-3 text-black font-medium dark:text-white">Eliminar</th>}
                    {botonEditarHaber && <th className="px-3 text-black font-medium dark:text-white">Editar</th>}
                  </tr>
                </>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()} className="h-full">
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className="group bg-white dark:bg-hercules-dark-bg-200   dark:text-dark-text-100 dark:border-b-hercules-dark-secondary-button border-b border-hercules-light-secondary text-black h-12 dark:hover:bg-white dark:hover:bg-opacity-10 transition duration-100 ease-in-out hover:bg-light-accent hover:bg-opacity-20"
                  >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps}
                        className="py-2 px-4 text-14 font-normal"
                        >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                  {botonEliminar && (
                    <td className="mx-6">
                      <MdDelete
                            className="mr-3 text-2xl group-hover:cursor-pointer text-red-500"
                            onClick={() => {
                          borrarHaber(row.index)
                        }}
                      />
                    </td>
                  )}

                  {botonEditarHaber && (
                    <td className="mx-6">
                      <MdOutlineVisibility
                        className="ml-6 text-2xl group-hover:cursor-pointer text-green-600"
                        onClick={() => {
                          const haber = {
                            haber: {
                              nombre: row.original.haber.nombre,
                              // id: "",
                              haberesDt: {
                                fijoVariable: row.original.haber.haberesDt.fijoVariable,
                                imponible: row.original.haber.haberesDt.imponible,
                                tributable: row.original.haber.haberesDt.tributable,
                                constRemuneracion: row.original.haber.haberesDt.constituyeRemuneracion,
                                glosa: row.original.haber.haberesDt.glosa,
                                codigo: row.original.haber.haberesDt.codigo,
                                id: row.original.haber.haberesDt.id,
                              },
                            },
                            valor: row.original.valor,
                            valorFijo: row.original.valorFijo,
                            valorVariable: row.original.valorVariable,
                          }
                          editarHaber(haber)
                        }}
                      />
                    </td>
                  )}

                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TablaHaberes;