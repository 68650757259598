import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStateContext } from "../../contexts/ContextProvider";
import { ColumnFilter, ModalSpinner } from "../../components";
import {
  afcPeriodo,
  hideLoader,
  impuestosRenta,
  limpiarInfoMenuEdicion,
  listaAFPPeriodosTasa,
  parametrosPeriodo,
  rentasMinimasPeriodo,
  rentasTopeImponible,
  showLoader,
  topeAPV,
  trabajosPesados,
  tramoAsignacionFamiliar,
} from "../../Redux/Actions";
import UtilsFunctions from "../../utils/UtilsFunctions";
import SimpleTable from "../../components/SimpleTable";
import { useForm } from "react-hook-form";
import { axiosPrivate } from "../../common/axiosPrivate";
import { toast } from "react-hot-toast";

const IndicadoresPrevisionales = () => {
  const {
    register,
    getValues,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      periodoAnyo: "",
      periodoMes: "",
    },
  });

  const anyosData = UtilsFunctions.obtenerAnyosHastaActual();
  const [mesesData, setMesesData] = useState([]);
  const [infoPeriodos, setInfoPeriodos] = useState([]);

  const { activeMenu, screenSize } = useStateContext();
  const dispatch = useDispatch();

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const validacionTextInput = {
    required: { value: true, message: "Obligatorio" },
  };

  const infoParametrosPeriodo = useSelector((state) => state.parametrosPeriodo);
  const infoListaAFPPeriodosTasa = useSelector((state) => state.listaAFPPeriodosTasa);
  const infoRtasTopeImponible = useSelector((state) => state.rtasTopeImponible);
  const infoRtasMinPeriodo = useSelector((state) => state.rtasMinPeriodo);
  const infoTopeAPV = useSelector((state) => state.topeAPV);
  const infoTrabajosPesados = useSelector((state) => state.trabajosPesados);
  const infoAFCPeriodo = useSelector((state) => state.afcPeriodo);
  const infoImpuestosRenta = useSelector((state) => state.impuestosRenta);
  const infoTramoAsignacionFamiliar = useSelector(
    (state) => state.tramoAsignacionFamiliar
  );

  async function getPeriodosCargados(){
    const infoMeses = await axiosPrivate(`${api_service}/api/v1/periodos/cargados`, Authorization)
    setInfoPeriodos(infoMeses.data)
  }

  const handleYearChange = (e) => {
    const year = parseInt(e);
    const yearData = infoPeriodos.find((item) => item.anyo === year);
    if (yearData) {
      const filteredMonths = yearData.periodos.filter(
        (mes) => mes.datosPrevisionalesCargados === true
      );
      const transformedMonths = filteredMonths.map((month) => (
        UtilsFunctions.integerToMonth(month.mes)
      ));
      setMesesData(transformedMonths);

    } else {
      // Si no se encontró ningún año con el valor seleccionado, establecer mesesData como un arreglo vacío.
      setMesesData([]);
    }
  };

  async function buscarIndicadoresPrevisionales() {
    const data = getValues();
    toast.loading("Cargando Indicadores Previsionales", estilosAlerta);
    try {
      const info = await axiosPrivate.get(
        `${api_service}/api/v1/periodos/mes/${data.numeroMes}/anyo/${data.periodoAnyo}`,
        Authorization
      );
      // console.log("periodoId", info);
      if (info.status == 200) {
        const fetchData = async () => {
          dispatch(showLoader());
          await Promise.all([
            dispatch(parametrosPeriodo(info.data.id)),
            dispatch(listaAFPPeriodosTasa(info.data.id)),
            dispatch(rentasTopeImponible(info.data.id)),
            dispatch(rentasMinimasPeriodo(info.data.id)),
            dispatch(topeAPV(info.data.id)),
            dispatch(trabajosPesados(info.data.id)),
            dispatch(afcPeriodo(info.data.id)),
            dispatch(impuestosRenta(info.data.id)),
            dispatch(tramoAsignacionFamiliar(info.data.id)),
          ]);
          dispatch(hideLoader());
          toast.dismiss();
          toast.success("Indicadores Previsionales cargados con éxito", estilosAlerta);
        };
        fetchData().catch((error) => {
          console.log(error.response.data.message);
          dispatch(hideLoader());
        });
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Error al cargar Indicadores Previsionales", estilosAlerta);
    }
  }

  useEffect(() => {
    // dispatch(limpiarInfoIndicadores());
    getPeriodosCargados()
    return () => {
      dispatch(limpiarInfoMenuEdicion("parametrosPeriodo"))
      dispatch(limpiarInfoMenuEdicion("listaAFPPeriodosTasa"))
      dispatch(limpiarInfoMenuEdicion("rtasTopeImponible"))
      dispatch(limpiarInfoMenuEdicion("rtasMinPeriodo"))
      dispatch(limpiarInfoMenuEdicion("topeAPV"))
      dispatch(limpiarInfoMenuEdicion("trabajosPesados"))
      dispatch(limpiarInfoMenuEdicion("afcPeriodo"))
      dispatch(limpiarInfoMenuEdicion("impuestosRenta"))
      dispatch(limpiarInfoMenuEdicion("tramoAsignacionFamiliar"))
    }

  },[])

  const RtasTopeImponibles = [
    {
      Header: "Rentas Topes Imponibles",
      accessor: "rentaTopeImponible",
      Filter: ColumnFilter,
      disableFilters: true,
    },
    {
      Header: "Cantidad",
      accessor: (d) => d.cantidad.toString().replace(".", ","),
      Filter: ColumnFilter,
      disableFilters: true,
    },
    {
      Header: "Unidad",
      accessor: "unidad",
      Filter: ColumnFilter,
      disableFilters: true,
    },
    {
      Header: "Pesos",
      accessor: (d) => "$" + UtilsFunctions.integerFormatter(d.pesos),
      Filter: ColumnFilter,
    },
  ];

  const RtasMinImponibles = [
    {
      Header: "Rentas Mínimas Imponibles",
      accessor: "tipoTrabajador.nombre",
      Filter: ColumnFilter,
      disableFilters: true,
    },
    {
      Header: "Pesos",
      accessor: (d) => "$" + UtilsFunctions.integerFormatter(d.valor),
      Filter: ColumnFilter,
      disableFilters: true,
    },
  ];

  const TopeAPV = [
    {
      Header: "Tope",
      accessor: "tope",
      Filter: ColumnFilter,
      disableFilters: true,
    },
    {
      Header: "Cantidad",
      accessor: (d) => d.cantidad.toString().replace(".", ","),
      Filter: ColumnFilter,
      disableFilters: true,
    },
    {
      Header: "Unidad",
      accessor: "unidad",
      Filter: ColumnFilter,
      disableFilters: true,
    },
    {
      Header: "Pesos",
      accessor: (d) => "$" + UtilsFunctions.integerFormatter(d.pesos),
      Filter: ColumnFilter,
      disableFilters: true,
    },
  ];

  const ColAFPPeriodosTasa = [
    {
      Header: "A.F.P.",
      accessor: "afp.glosa",
      Filter: ColumnFilter,
      disableFilters: true,
    },
    {
      Header: "Tasa A.F.P.",
      accessor: (d) => {
        if (d.tasa) {
          return d.tasa.toString().replace(".", ",") + "%";
        } else {
          return "0%";
        }
      },
      Filter: ColumnFilter,
      disableFilters: true,
    },

    {
      Header: "S.I.S.",
      accessor: (d) => {
        if (d.sis) {
          return d.sis.toString().replace(".", ",") + "%";
        } else {
          return "0%";
        }
      },
      Filter: ColumnFilter,
      disableFilters: true,
    },
    {
      Header: "Porcentaje",
      accessor: (d) => {
        if (d.sis && d.tasa) {
          return (d.sis + d.tasa).toFixed(2).toString().replace(".", ",") + "%";
        } else {
          return "0%";
        }
      },
      Filter: ColumnFilter,
      disableFilters: true,
    },
    {
      Header: "Cód. Previred",
      accessor: "afp.codigo",
      Filter: ColumnFilter,
      disableFilters: true,
    },
    {
      Header: "Cód. L.R.",
      accessor: "afp.codigoLr",
      Filter: ColumnFilter,
      disableFilters: true,
    },
  ];

  const ColAFCPeriodo = [
    {
      Header: "Tipo Trabajador",
      accessor: "tipoTrabajador.nombre",
      Filter: ColumnFilter,
      disableFilters: true,
      enableRowSpan: true,
    },
    {
      Header: "AFC",
      accessor: "plazoContrato.nombre",
      Filter: ColumnFilter,
      disableFilters: true,
    },
    {
      Header: "Empleador",
      accessor: (d) => {
        if (d.empresa) {
          return d.empresa.toString().replace(".", ",") + "%";
        } else {
          return "0%";
        }
      },
      Filter: ColumnFilter,
      disableFilters: true,
    },
    {
      Header: "Trabajador",
      accessor: (d) => {
        if (d.trabajador) {
          return d.trabajador.toString().replace(".", ",") + "%";
        } else {
          return "0%";
        }
      },
      Filter: ColumnFilter,
      disableFilters: true,
    },
  ];

  const ColTramoAsignacionFamiliar = [
    {
      Header: "Tramo",
      accessor: "tramoAsignacion.id",
      Filter: ColumnFilter,
      disableFilters: true,
      Cell: ({ value, row }) => {
        if (value) {
          return value + "(" + row.original.tramoAsignacion.codigo + ")";
        }
      },
    },
    {
      Header: "Monto",
      accessor: (d) => {
        if (d.valor) {
          return "$" + UtilsFunctions.integerFormatter(d.valor);
        } else {
          return "$0";
        }
      },
      Filter: ColumnFilter,
      disableFilters: true,
    },
    {
      Header: "Renta min",
      accessor: (d) => "$" + UtilsFunctions.integerFormatter(d.rentaMaxima),
      Filter: ColumnFilter,
      disableFilters: true,
    },
    {
      Header: "Renta max",
      accessor: (d) => "$" + UtilsFunctions.integerFormatter(d.rentaMinima),
      Filter: ColumnFilter,
      disableFilters: true,
    },
  ];

  const ColTrabajosPesados = [
    {
      Header: "Tipo trabajo",
      accessor: "trabajoPesado",
      Filter: ColumnFilter,
      disableFilters: true,
    },
    {
      Header: "Financ. empleador",
      accessor: (d) => d.financiaEmpleador.toString().replace(".", ",") + "%",
      Filter: ColumnFilter,
      disableFilters: true,
    },
    {
      Header: "Financ. Trabajador",
      accessor: (d) => d.financiaTrabajador.toString().replace(".", ",") + "%",
      Filter: ColumnFilter,
      disableFilters: true,
    },
  ];

  const colImpRenta = [
    {
      Header: "Desde",
      accessor: (d) => d.desde.toString().replace(".", ",") + " UTM",
      Filter: ColumnFilter,
      disableFilters: true,
    },
    {
      Header: "Desde (pesos)",
      accessor: (d) => {
        if (d.pesos) {
          return "$" + UtilsFunctions.integerFormatter(d.pesos);
        } else {
          return "$0";
        }
      },
      Filter: ColumnFilter,
      disableFilters: true,
    },
    {
      Header: "Hasta",
      accessor: (d) => d.hasta.toString().replace(".", ",") + " UTM",
      Filter: ColumnFilter,
      disableFilters: true,
    },
    {
      Header: "Hasta (pesos)",
      accessor: (d) => {
        if (d.pesos2) {
          return "$" + UtilsFunctions.integerFormatter(d.pesos2);
        } else {
          return "$0";
        }
      },
    },
    {
      Header: "Factor",
      accessor: (d) => d.factor.toString().replace(".", ",") + "%",
      Filter: ColumnFilter,
      disableFilters: true,
    },
    {
      Header: "Rebaja",
      accessor: (d) => d.rebaja.toString().replace(".", ",") + " UTM",
      Filter: ColumnFilter,
      disableFilters: true,
    },
    {
      Header: "Rebaja (pesos)",
      accessor: (d) => {
        if (d.pesos3) {
          return "$" + UtilsFunctions.integerFormatter(d.pesos3);
        } else {
          return "$0";
        }
      },
      Filter: ColumnFilter,
      disableFilters: true,
    },
  ];

  return (
    <div
    className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
      activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">
        Edición
      </div>
      <div className="titulo-Pagina">
        Indicadores Previsionales
      </div>

      <form className="w-full" onSubmit={handleSubmit(buscarIndicadoresPrevisionales)} >
      <div className="flex flex-wrap pt-5 pb-2">

        <div className="flex w-full md:w-4/12 mb-3 md:mb-0" >
        <div className="w-4/12 md:w-6/12 md:mr-2 mr-3">
          <label
            htmlFor="periodoId"
            className="dark:text-white text-hercules-light-font-secondary"
          >
            Periodo
          </label>
          <div className="w-full md:mb-0">
            <select
              {...register("periodoAnyo", {
                onChange: (e) => {
                  setValue("periodoMes", "");
                  handleYearChange(e.target.value)
                },
                ...validacionTextInput
              })}
              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option hidden value="">
                Año
              </option>
              {anyosData.map((anyo, i) => (
                <option value={anyo} key={i}>
                  {anyo}
                </option>
              ))}
            </select>
            <span
                  className={`dark:text-red-400 text-red-600 text-xs ${
                    !errors.periodoAnyo && "opacity-0 invisible"
                  }`}
                >
                  {errors.periodoAnyo ? errors.periodoAnyo.message : "hidden"}
                </span>
          </div>
        </div>

        <div className="w-6/12 md:w-6/12 mt-6">
          <select
            {...register("periodoMes", {
              onChange: (e) => {
                setValue("numeroMes", e.target.selectedIndex);
              },
              ...validacionTextInput
            })}
            disabled={watch("periodoAnyo") === "" ? true : false}
            className="block w-full appearance-none bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
            <option hidden value="">
              Mes
            </option>
            {mesesData.map((mes, i) => (
              <option value={mes} key={i}>
                {mes}
              </option>
            ))}
          </select>
                <span
                  className={`dark:text-red-400 text-red-600 text-xs ${
                    !errors.periodoMes && "opacity-0 invisible"
                  }`}
                >
                  {errors.periodoMes ? errors.periodoMes.message : "hidden"}
                </span>
        </div>
        </div>


        <div className="w-full mb-6 md:w-3/12 md:mt-6 md:pl-6 md:mb-0 ">
          <button
            type="submit"
            className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
          >
            Buscar
          </button>
        </div>
      </div>
      </form>

        <div className="md:mr-12">
        {infoParametrosPeriodo.id && (
          <div className="flex flex-wrap">
            <div className="w-6/12 md:w-2/12 mb-3 md:mb-3 pr-3 md:mr-3">
              <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                Días
              </label>
              <input
                defaultValue={infoParametrosPeriodo?.diasTotales}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="text"
                disabled
              />
            </div>
            <div className="w-6/12 md:w-2/12 mb-3 md:mb-6 md:mr-3">
              <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                Valor U.F.
              </label>
              <input
                value={
                  "$" + infoParametrosPeriodo?.uf?.toString().replace(".", ",")
                }
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="text"
                disabled
                readOnly
              />
            </div>
            <div className="w-6/12 md:w-2/12 mb-3 md:mb-6 pr-3 md:mr-3">
              <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                Valor U.T.M
              </label>
              <input
                value={"$ " + UtilsFunctions.integerFormatter(infoParametrosPeriodo?.utm)}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="text"
                disabled
                readOnly
              />
            </div>
            <div className="w-6/12 md:w-2/12 mb-3 md:mb-6">
              <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                Valor U.T.A
              </label>
              <input
                // value={
                //   "$" + infoParametrosPeriodo?.uta?.toString().replace(".", ",")
                // }
                value={"$ " + UtilsFunctions.integerFormatter(infoParametrosPeriodo?.uta)}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="text"
                disabled
                readOnly
              />
            </div>
          </div>
        )}

        {infoListaAFPPeriodosTasa.length > 0 && 
          <div className="flex mb-3">
            <div className="h-full w-full rounded-lg pt-5">
              <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                Tasa cotización obligatoria A.F.P.
              </label>
                <SimpleTable
                  col={ColAFPPeriodosTasa}
                  tableData={infoListaAFPPeriodosTasa}
                  showFilters={false}
                  hasPagination={false}
                  searchBar={false}
                  tableHeight="h-auto"
                  />
            </div>
          </div>
        }

          <div className="md:flex mb-3">
          {infoRtasTopeImponible.length > 0 && 
            <div className="h-full w-full md:w-1/2 mb-3 md:mb-0 rounded-lg md:mr-3 pt-5">
              <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                Rentas topes imponibles
              </label>
              <SimpleTable
                col={RtasTopeImponibles}
                tableData={infoRtasTopeImponible}
                showFilters={false}
                hasPagination={false}
                searchBar={false}
                tableHeight="h-auto"
              />
            </div>
          }
          {infoRtasMinPeriodo.length > 0 && 
            <div className="h-full w-full md:w-1/2 rounded-lg md:ml-3 pt-5">
              <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                Rentas mínimas imponibles
              </label>
              <SimpleTable
                col={RtasMinImponibles}
                tableData={infoRtasMinPeriodo}
                showFilters={false}
                hasPagination={false}
                searchBar={false}
                tableHeight="h-auto"
              />
            </div>
          }
          </div>

          <div className="md:flex mb-3">
          {infoTopeAPV.length > 0 && 
            <div className="h-full w-full mb-3 md:mb-0 md:w-1/2 rounded-lg md:mr-3 pt-5">
              <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                Topes A.P.V.
              </label>
              <SimpleTable
                col={TopeAPV}
                tableData={infoTopeAPV}
                showFilters={false}
                hasPagination={false}
                searchBar={false}
                tableHeight="h-auto"
              />
            </div>
          }
          {infoTrabajosPesados.length > 0 && 
            <div className="h-full w-full md:w-1/2 rounded-lg md:ml-3 pt-5">
              <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                Cotización trabajo pesado
              </label>
              <SimpleTable
                col={ColTrabajosPesados}
                tableData={infoTrabajosPesados}
                showFilters={false}
                hasPagination={false}
                searchBar={false}
                tableHeight="h-auto"
              />
            </div>
                                  }
          </div>

          <div className="md:flex mb-3">
          {infoAFCPeriodo.length > 0 && 
            <div className="h-full w-full mb-3 md:mb-3 md:w-1/2 rounded-lg md:mr-3 pt-5">
              <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                Porcentajes aportes A.F.C.
              </label>
              <SimpleTable
                col={ColAFCPeriodo}
                tableData={infoAFCPeriodo}
                showFilters={false}
                hasPagination={false}
                searchBar={false}
                tableHeight="h-auto"
              />
            </div>
            }
          {infoTramoAsignacionFamiliar.length > 0 && 
            <div className="h-full w-full md:w-1/2 rounded-lg md:ml-3 pt-5">
              <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                Montos asignaciones familiares
              </label>
              <SimpleTable
                col={ColTramoAsignacionFamiliar}
                tableData={infoTramoAsignacionFamiliar}
                showFilters={false}
                hasPagination={false}
                searchBar={false}
                tableHeight="h-auto"
              />
            </div>
            }
          </div>

          {infoImpuestosRenta.length > 0 && 
          <div className="flex mb-3">
            <div className="h-full w-full rounded-lg pt-5">
              <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                Tramos Impuesto a la renta
              </label>
              <SimpleTable
                col={colImpRenta}
                tableData={infoImpuestosRenta}
                showFilters={false}
                hasPagination={false}
                searchBar={false}
                tableHeight="h-auto"
              />
            </div>
          </div>
        }
        </div>
    </div>
  );
};

export default IndicadoresPrevisionales;
