import React, { useContext } from 'react';
import { DarkModeContext } from "../contexts/DarkModeProvider";
import { MdDarkMode, MdLightMode } from "react-icons/md";

const DarkModeSwitch = () => {
  const { darkMode, setDarkMode } = useContext(DarkModeContext);

  return (
    <div className=' mt-2'>
      {darkMode ? (
        <MdLightMode
          className="text-2xl text-gray-300 hover:bg-gray-700 cursor-pointer focus:outline-none focus:ring-4 focus:ring-gray-700 p-2 rounded-lg h-10 w-10"
          onClick={() => {
            setDarkMode(false);
            localStorage.setItem("userTheme", "light")
          }}
        />
      ) : (
        <MdDarkMode
          className="text-2xl text-gray-600 hover:bg-gray-100 cursor-pointer focus:outline-none focus:ring-4 focus:ring-gray-200 p-2 rounded-lg h-10 w-10"
          onClick={() => {
            setDarkMode(true);
            localStorage.setItem("userTheme", "dark")
          }}
        />
      )}
    </div>
    
    
  )
}

export default DarkModeSwitch;