import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import UtilsFunctions from "../../../utils/UtilsFunctions";
import { useDispatch, useSelector } from "react-redux";
import { useStateContext } from "../../../contexts/ContextProvider";
import {
  getCiudades,
  getComunas,
  getGratificacionesLegales,
  getPaises,
  getRegiones,
  getRepLegalesActivos,
  limpiarInfoMenuEdicion,
} from "../../../Redux/Actions";
import { axiosPrivate } from "../../../common/axiosPrivate";
import { toast } from "react-hot-toast";
import ModalSpinner from "../../ModalSpinner";
import NuevoModalEditarCAFF from "./NuevoModalEditarCCAF";
import NuevoModalEditarMutual from "./NuevoModalEditarMutual";

export default function EditarEmpresa({
  isVisible,
  onClose,
  modalData,
  ccafData,
  mutualData,
}) {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      idEmpresa: "",
      nombreFantasia: "",
      rut: "",
      razonSocial: "",
      giro: "",
      representanteLegalId: "",
      representanteRut: "",
      ciudadId: "",
      comunaId: "",
      regionId: "",
      paisId: "",
      comunaNombre: "",
      ciudadNombre: "",
      calle: "",
      numero: "",
      depto: "",
      actividadEconomica: "",
      telefono: "",
      nombreCCAFActual: "",
      nombreMutualActual: "",
    },
  });

  const { refreshTable, setRefreshTable, screenSize } = useStateContext();
  const [isVisibleCcaf, setIsVisibleCcaf] = useState(false);
  const [isVisibleMutual, setIsVisibleMutual] = useState(false);

  const paises = useSelector((state) => state.paises);
  const regionesData = useSelector((state) => state.regiones);
  const comunasData = useSelector((state) => state.comunas);
  const ciudadesData = useSelector((state) => state.ciudades);
  const representantes = useSelector((state) => state.repLegalesActivos);
  const gratificacionesData = useSelector((state) => state.getGratificaciones);

  const [botonGuardarHabilitado, setBotonGuardarHabilitado] = useState(true);

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const REACT_APP_API_HERCULES_SERVICE =
    process.env.REACT_APP_API_HERCULES_SERVICE;

  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const periodoId = localStorage.getItem("periodoId");

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGratificacionesLegales())
  }, [dispatch])


  useEffect(() => {
    if (ccafData) {
      setValue("nombreCCAFActual", ccafData[0].ccaf.glosa);
    }
  }, [ccafData]);

  useEffect(() => {
    if (mutualData) {
      setValue("nombreMutualActual", mutualData[0].mutual.glosa);
    }
  }, [mutualData]);

  useEffect(() => {
    if (modalData && isVisible) {
      reset({
        idEmpresa: modalData.id,
        nombreFantasia: modalData.nombreFantasia,
        rut: UtilsFunctions.rutFormatter(modalData.rut, { dots: true }),
        razonSocial: modalData.razonSocial,
        giro: modalData.giro,
        representanteLegalId: modalData.representanteLegal.id,
        representanteRut: UtilsFunctions.rutFormatter(
          modalData.representanteLegal.rut,
          { dots: true }
        ),
        ciudadId: modalData.ciudad.id,
        comunaId: modalData.ciudad.comuna.id,
        regionId: modalData.ciudad.comuna.region.id,
        paisId: modalData.ciudad.comuna.region.pais.id,
        comunaNombre: modalData.ciudad.comuna.nombre,
        ciudadNombre: modalData.ciudad.nombre,
        calle: modalData.calle,
        numero: modalData.numero,
        depto: modalData.depto,
        actividadEconomica: modalData.actividadEconomica,
        telefono: modalData.telefono,
        gratificacionId: modalData.gratificacion.id,
        gratificacionTexto: modalData.gratificacion.observacion
      });
      dispatch(getPaises());
      dispatch(getRepLegalesActivos());
    }
  }, [modalData, isVisible]);

  useEffect(() => {
    if (watch("paisId") && modalData && isVisible) {
      dispatch(getRegiones(watch("paisId")));
    }
  }, [watch("paisId")]);

  useEffect(() => {
    if (watch("regionId") && modalData && isVisible) {
      dispatch(getComunas(watch("regionId")));
    }
  }, [watch("regionId")]);

  useEffect(() => {
    if (watch("comunaId") && modalData && isVisible) {
      dispatch(getCiudades(watch("comunaId")));
    }
  }, [watch("comunaId")]);

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("paises"));
      dispatch(limpiarInfoMenuEdicion("regiones"));
      dispatch(limpiarInfoMenuEdicion("comunas"));
      dispatch(limpiarInfoMenuEdicion("ciudades"));
      dispatch(limpiarInfoMenuEdicion("repLegalesActivos"));
      dispatch(limpiarInfoMenuEdicion("empresaGratificacion"));
      reset({});
    };
  }, []);

  const validacionTextInput = {
    required: { value: true, message: "Obligatorio" },
  };

  const rutValidations = {
    required: { value: true, message: "Obligatorio" },
    pattern: {
      value: /^(\d{1,2}(?:[\.]?\d{3}){2}-[\dkK])$/,
      message:
        "El formato del RUT es incorrecto (ej. 18.405.136-K o 18405136-K)",
    },
    validate: {
      rutValido: (v) =>
        UtilsFunctions.validateRut(v) || "El RUT ingresado no es válido",
    },
  };

  async function onSubmit(data) {
    setBotonGuardarHabilitado(false);
    toast.loading("Editando Empresa", estilosAlerta);
    try {
      const requestBody = {
        periodoId: periodoId,
        idEmpresa: data.idEmpresa,
        nombreFantasia: data.nombreFantasia,
        rut: UtilsFunctions.rutFormatter(data.rut, { dots: false }),
        razonSocial: data.razonSocial,
        giro: data.giro,
        ciudadId: data.ciudadId,
        calle: data.calle,
        numero: data.numero,
        depto: data.depto,
        actividadEconomica: data.actividadEconomica,
        telefono: data.telefono,
        representanteId: data.representanteLegalId,
        gratificacionId: data.gratificacionId,
      };
      const response = await axiosPrivate.put(
        `${REACT_APP_API_HERCULES_SERVICE}/api/v1/empresas/editarempresa`,
        requestBody,
        Authorization
      );
      if (response.status === 200) {
        setRefreshTable(!refreshTable);
        toast.dismiss();
        toast.success("Empresa editada con éxito", estilosAlerta);
        onClose();
        reset();
      }
      setBotonGuardarHabilitado(true);
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al editar Empresa",
        estilosAlerta
      );
      setBotonGuardarHabilitado(true);
    }
  }

  const loadingModal =
    paises?.length > 0 &&
    regionesData?.length > 0 &&
    comunasData?.length > 0 &&
    ciudadesData?.length > 0 &&
    representantes?.length > 0;

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-30 outline-none focus:outline-none">
          <div className="segundoDivModal max-w-6xl">
            {/*content*/}
            <div className="tercerDivModal shadow-lg  max-h-[85vh] ">
              {/*header*/}
              <div className="dark:bg-hercules-dark-bg-100 flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <h3 className="titulo-Modal">Editar Empresa</h3>
              </div>
              {/*body*/}
              <div className="relative py-6 px-3 flex-auto overflow-auto dark:bg-hercules-dark-bg-200">
                {!loadingModal ? (
                  <div className="h-fix">
                    <ModalSpinner />
                  </div>
                ) : (
                  <>
                    {/* Información basica de la empresa */}
                    <div className="flex flex-wrap">
                      <div className="input-EditarEmpresa md:w-4/12">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-nombre-fantasia"
                        >
                          Nombre de fantasía
                        </label>
                        <input
                          {...register("nombreFantasia", validacionTextInput)}
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                        />
                        <span
                          className={`text-red-500 text-xs italic ${
                            !errors.nombreFantasia && "opacity-0 invisible"
                          }`}
                        >
                          {errors.nombreFantasia
                            ? errors.nombreFantasia.message
                            : "hidden"}
                        </span>
                      </div>
                      <div className="input-EditarEmpresa md:w-2/12">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-first-name"
                        >
                          RUT Empresa
                        </label>
                        <input
                          {...register("rut", {
                            ...rutValidations,
                            onChange: () => {
                              if (getValues("rut") !== "-") {
                                setValue(
                                  "rut",
                                  UtilsFunctions.rutFormatter(
                                    getValues("rut"),
                                    {
                                      dots: true,
                                    }
                                  )
                                );
                              }
                              if (getValues("rut") === "-") {
                                setValue("rut", "");
                              }
                            },
                          })}
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                        />
                        <span
                          className={`text-red-500 text-xs italic ${
                            !errors.rut && "opacity-0 invisible"
                          }`}
                        >
                          {errors.rut ? errors.rut.message : "hidden"}
                        </span>
                      </div>
                      <div className="input-EditarEmpresa md:w-3/12">
                        <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                          Razón Social
                        </label>
                        <input
                          {...register("razonSocial", validacionTextInput)}
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                        />
                        <span
                          className={`text-red-500 text-xs italic ${
                            !errors.razonSocial && "opacity-0 invisible"
                          }`}
                        >
                          {errors.razonSocial
                            ? errors.razonSocial.message
                            : "hidden"}
                        </span>
                      </div>
                      <div className="input-EditarEmpresa md:w-3/12">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Giro
                        </label>
                        <input
                          {...register("giro", validacionTextInput)}
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                        />
                        <span
                          className={`text-red-500 text-xs italic ${
                            !errors.giro && "opacity-0 invisible"
                          }`}
                        >
                          {errors.giro ? errors.giro.message : "hidden"}
                        </span>
                      </div>
                    </div>

                    {/* Rep Legal */}
                    <div className="flex flex-wrap">
                      <div className="input-EditarEmpresa md:w-4/12">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Representante Legal
                        </label>
                        <select
                          {...register("representanteLegalId", {
                            required: "Debe seleccionar un representante legal",
                            onChange: (e) => {
                              const representante =
                                representantes[e.target.selectedIndex - 1];
                              setValue("representante", representante);
                              setValue(
                                "representanteRut",
                                UtilsFunctions.rutFormatter(representante.rut, {
                                  dots: true,
                                })
                              );
                            },
                          })}
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        >
                          <option hidden value="">
                            Seleccionar Representante Legal
                          </option>
                          {representantes.map((representante, i) => (
                            <option value={representante.id} key={i}>
                              {representante.nombres +
                                " " +
                                representante.apellidoPaterno +
                                " " +
                                representante.apellidoMaterno}
                            </option>
                          ))}
                        </select>
                        <span
                          className={`text-red-500 text-xs italic ${
                            !errors.representanteLegalId &&
                            "opacity-0 invisible"
                          }`}
                        >
                          {errors.representanteLegalId
                            ? errors.representanteLegalId.message
                            : "hidden"}
                        </span>
                      </div>
                      <div className="input-EditarEmpresa md:w-3/12 pb-6">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          RUT Rep. Legal
                        </label>
                        <input
                          disabled
                          {...register("representanteRut")}
                          className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                          id="grid-last-name"
                          type="text"
                        />
                      </div>
                    </div>

                    {/* Ubicacion */}
                    <div className="flex flex-wrap">
                      <div className="input-EditarEmpresa md:w-2/12">
                        <label
                          className="dark:text-white block uppercase tracking-wide text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          País
                        </label>
                        <select
                          {...register("paisId", {
                            required: "Debe seleccionar un país",
                            onChange: () => {
                              setValue("regionId", "");
                              setValue("comunaId", "");
                              setValue("ciudadId", "");
                            },
                          })}
                          value={watch("paisId") || ""}
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-state"
                        >
                          <option hidden value="">
                            Seleccionar País
                          </option>
                          {paises.map((pais) => (
                            <option value={pais.id} key={pais.id}>
                              {pais.nombre}
                            </option>
                          ))}
                        </select>
                        <span
                          className={`text-red-500 text-xs italic ${
                            !errors.paisId && "opacity-0 invisible"
                          }`}
                        >
                          {errors.paisId ? errors.paisId.message : "hidden"}
                        </span>
                      </div>

                      <div className="input-EditarEmpresa md:w-4/12">
                        <label
                          className="dark:text-white block uppercase tracking-wide text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Región
                        </label>
                        <select
                          {...register("regionId", {
                            required: "Debe seleccionar una región",
                            onChange: (e) => {
                              setValue("comunaId", "");
                              setValue("ciudadId", "");
                            },
                          })}
                          value={watch("regionId") || ""}
                          //   disabled={paisIdWatch === "" ? true : false}
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        >
                          <option hidden value="">
                            Seleccionar Región
                          </option>
                          {regionesData.map((region) => (
                            <option value={region.id} key={region.id}>
                              {region.nombre}
                            </option>
                          ))}
                        </select>
                        <span
                          className={`text-red-500 text-xs italic ${
                            !errors.regionId && "opacity-0 invisible"
                          }`}
                        >
                          {errors.regionId ? errors.regionId.message : "hidden"}
                        </span>
                      </div>

                      <div className="input-EditarEmpresa md:w-3/12">
                        <label
                          className="block dark:text-white uppercase tracking-wide text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Comuna
                        </label>
                        <select
                          {...register("comunaId", {
                            required: {
                              value: true,
                              message: "Debe seleccionar una comuna",
                            },
                            onChange: (e) => {
                              setValue("ciudadId", "");
                            },
                          })}
                          value={watch("comunaId") || ""}
                          //   disabled={regionIdWatch === "" ? true : false}
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        >
                          {watch("comunaId") === "" &&
                          watch("regionId") !== "" ? (
                            <option hidden value="">
                              Seleccionar Comuna
                            </option>
                          ) : null}
                          {comunasData.map((comuna) => (
                            <option value={comuna.id} key={comuna.id}>
                              {comuna.nombre}
                            </option>
                          ))}
                        </select>
                        <span
                          className={`text-red-500 text-xs italic ${
                            !errors.comunaId && "opacity-0 invisible"
                          }`}
                        >
                          {errors.comunaId ? errors.comunaId.message : "hidden"}
                        </span>
                      </div>

                      <div className="input-EditarEmpresa md:w-3/12">
                        <label
                          className="dark:text-white block uppercase tracking-wide text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Ciudad
                        </label>
                        <select
                          {...register("ciudadId", {
                            required: {
                              value: true,
                              message: "Debe seleccionar una ciudad",
                            },
                          })}
                          value={watch("ciudadId") || ""}
                          disabled={watch("comunaId") === "" ? true : false}
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-state"
                        >
                          {watch("comunaId") === "" ? (
                            <option hidden value="">
                              Seleccionar Comuna
                            </option>
                          ) : watch("ciudadId") === "" ? (
                            <option hidden value="">
                              Seleccionar Ciudad
                            </option>
                          ) : null}
                          {ciudadesData.map((ciudad) => (
                            <option value={ciudad.id} key={ciudad.id}>
                              {ciudad.nombre}
                            </option>
                          ))}
                        </select>
                        <span
                          className={`text-red-500 text-xs italic ${
                            !errors.ciudadId && "opacity-0 invisible"
                          }`}
                        >
                          {errors.ciudadId ? errors.ciudadId.message : "hidden"}
                        </span>
                      </div>
                    </div>

                    {/* Direccion */}
                    <div className="flex flex-wrap">
                      <div className="input-EditarEmpresa md:w-4/12">
                        <label
                          className="dark:text-white block uppercase tracking-wide text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Calle
                        </label>
                        <input
                          {...register("calle", {
                            required: "Campo obligatorio",
                          })}
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        />
                        <span
                          className={`text-red-500 text-xs italic ${
                            !errors.calle && "opacity-0 invisible"
                          }`}
                        >
                          {errors.calle ? errors.calle.message : "hidden"}
                        </span>
                      </div>
                      <div className="input-EditarEmpresa md:w-2/12">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Número
                        </label>
                        <input
                          {...register("numero", {
                            required: "Campo obligatorio",
                          })}
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                        />
                        <span
                          className={`text-red-500 text-xs italic ${
                            !errors.numero && "opacity-0 invisible"
                          }`}
                        >
                          {errors.numero ? errors.numero.message : "hidden"}
                        </span>
                      </div>
                      <div className="input-EditarEmpresa md:w-2/12 pb-6">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Depto.
                        </label>
                        <input
                          {...register("depto")}
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-last-name"
                          type="text"
                        />
                        {errors.depto && (
                          <p className="text-red-600 text-xs">
                            {errors.depto.message}
                          </p>
                        )}
                      </div>
                    </div>

                    {/* Gratificacion */}
                    <div className="flex flex-wrap">
                      <div className="input-EditarEmpresa w-full md:w-3/12">
                        <label className="dark:text-white block uppercase tracking-wide text-hercules-light-font-secondary text-xs font-bold mb-2">
                          Gratificación
                        </label>
                        <select
                          {...register("gratificacionId", {
                            required: "Obligatorio",
                            onChange: (e) => {
                              const gratificacion =
                              gratificacionesData[e.target.selectedIndex - 1];
                              setValue("gratificacion", gratificacion);
                              setValue("gratificacionTexto", gratificacion.observacion);
                            },
                              })}
                          name="gratificacionId"
                          className="appearance-none block w-full md:w-64 bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        >
                      <option hidden value="">
                        Seleccionar Gratificación
                      </option>
                      {gratificacionesData.map((gratificacion, i) => (
                        <>
                          <option key={i} value={gratificacion.id}>
                            {gratificacion.nombre}
                          </option>
                        </>
                      ))}
                        </select>
                        <span
                          className={`dark:text-red-400 text-red-600 italic text-xs ${
                            !errors.gratificacionId && "opacity-0 invisible"
                          }`}
                        >
                          {errors.gratificacionId ? "Obligatorio" : "hidden"}
                        </span>
                      </div>

                      <div className="input-EditarEmpresa md:w-8/12 pb-6">
                        <label
                          className="dark:text-white block uppercase tracking-wide text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Observación
                        </label>
                        <input
                          disabled
                          {...register("gratificacionTexto")}
                          className="appearance-none block w-full bg-gray-300 border-gray-400 text-gray-900 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                          id="grid-last-name"
                          type="text"
                        />
                      </div>
                    </div>

                    {/* Act Economica y Tel */}
                    <div className="flex flex-wrap">
                      <div className="input-EditarEmpresa md:w-3/12">
                        <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                          Actividad Económica
                        </label>
                        <input
                          {...register("actividadEconomica", {
                            required: "Campo obligatorio",
                          })}
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-last-name"
                          type="text"
                        />
                        <span
                          className={`text-red-500 text-xs italic ${
                            !errors.actividadEconomica && "opacity-0 invisible"
                          }`}
                        >
                          {errors.actividadEconomica
                            ? errors.actividadEconomica.message
                            : "hidden"}
                        </span>
                      </div>
                      <div className="input-EditarEmpresa md:w-3/12">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Teléfono
                        </label>
                        <input
                          {...register("telefono", {
                            required: "Campo obligatorio",
                          })}
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-last-name"
                          type="text"
                        />
                        <span
                          className={`text-red-500 text-xs italic ${
                            !errors.telefono && "opacity-0 invisible"
                          }`}
                        >
                          {errors.telefono ? errors.telefono.message : "hidden"}
                        </span>
                      </div>
                    </div>

                    {/* CCAF y Mutual */}
                    <div className="flex flex-wrap px-3">
                      <div className="relative w-full md:w-4/12 mb-3">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          CCAF
                        </label>

                        <input
                          {...register("nombreCCAFActual")}
                          disabled
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-last-name"
                          type="text"
                        />
                      </div>
                      <div className="w-full md:w-2/12 md:px-3 md:mb-0 mb-6">
                        <button
                          onClick={() => setIsVisibleCcaf(true)}
                          type="button"
                          className="inline-block md:mt-6 mb-3 mr-1 px-6 py-3.5 hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-sm rounded-md shadow-md hover:shadow-lg outline-none focus:outline-none leading-tight ease-in-out transition-all duration-400"
                        >
                          Ver/Editar
                        </button>
                      </div>
                      <div className="relative w-full md:w-4/12 mb-3">
                        <label
                          className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Mutual
                        </label>

                        <input
                          {...register("nombreMutualActual")}
                          name="mutualId"
                          disabled
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-last-name"
                          type="text"
                        />
                      </div>
                      <div className="w-full md:w-2/12 md:px-3">
                        <button
                          onClick={() => setIsVisibleMutual(true)}
                          type="button"
                          className="inline-block md:mt-6 mb-3 mr-1 px-6 py-3.5 hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-sm rounded-md shadow-md hover:shadow-lg outline-none focus:outline-none leading-tight ease-in-out transition-all duration-400"
                        >
                          Ver/Editar
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>

              {/*footer*/}
              <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className=" dark:text-white text-hercules-light-font-secondary hover:scale-105 font-bold uppercase text-base px-6 py-3 rounded outline-none focus:outline-none mr-3 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    onClose();
                    reset();
                  }}
                >
                  Cerrar
                </button>
                <button
                  disabled={!botonGuardarHabilitado}
                  className={` ${
                    !botonGuardarHabilitado
                      ? "bg-gray-500"
                      : "bg-hercules-light-primary-button hover:shadow-lg hover:scale-105"
                  }  text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400`}
                  type="submit"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-20 bg-black"></div>
      </form>
      {isVisibleCcaf && (
        <NuevoModalEditarCAFF
          idEmpresa={modalData.id}
          ccafData={ccafData}
          razonSocialEmpresa={modalData.razonSocial}
          nombreCCAFActual={(nombre) => {
            setValue("nombreCCAFActual", nombre);
          }}
          cerrarModal={() => setIsVisibleCcaf(false)}
        />
      )}
      {isVisibleMutual && (
        <NuevoModalEditarMutual
          idEmpresa={modalData.id}
          mutualData={mutualData}
          razonSocialEmpresa={modalData.razonSocial}
          nombreMutualActual={(nombre) => {
            setValue("nombreMutualActual", nombre);
          }}
          cerrarModal={() => setIsVisibleMutual(false)}
        />
      )}
    </>
  );
}
