import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import CargasFamiliaresTrabajador from "../CargasFamiliaresTrabajador";
import { useStateContext } from "../../../../contexts/ContextProvider";
import NuevoAFPTrabajador from "../NuevoAFPTrabajador";
import NuevoSaludTrabajador from "../NuevoSaludTrabajador";
import {
  afpTrabajador,
  datosTrabajadorCFamiliar,
  limpiarInfoMenuEdicion,
  tablaSalud,
} from "../../../../Redux/Actions";
import UtilsFunctions from "../../../../utils/UtilsFunctions";

const FichaTrabajadorPaginaDos = ({
  onClose,
  institucionesApv,
  datosTrabajador,
  changePage,
}) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      afp: "Sin Afp",
      salud: "Sin Salud",
      cargasFamiliares: "0",
      monto: "0",
    },
  });

  const [botonGuardarHabilitado, setBotonGuardarHabilitado] = useState(true)
  const { refreshTable, setRefreshTable } = useStateContext();
  const [showEditorAfp, setShowEditorAfp] = useState(false);
  const [showEditorSalud, setShowEditorSalud] = useState(false);
  const [showEditorCargas, setShowEditorCargas] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (datosTrabajador) {
      dispatch(afpTrabajador(datosTrabajador.id));
      dispatch(tablaSalud(datosTrabajador.id));
      dispatch(datosTrabajadorCFamiliar(datosTrabajador.id));
    }
  }, [datosTrabajador]);

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("afpTrabajador"));
      dispatch(limpiarInfoMenuEdicion("tablaSalud"));
      dispatch(limpiarInfoMenuEdicion("datosTrabajadorCarga"));
    };
  }, []);

  return (
    <>
      <form className="w-full">
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none">
          <div className="segundoDivModal max-w-6xl">
            {/*content*/}
            <div className="tercerDivModal shadow-lg  max-h-[85vh]">
              {/*header*/}
              <div className="divHeaderModal border-slate-200">
                <h3 className="titulo-Modal">Nuevo Trabajador</h3>
              </div>
              <div className="relative p-6 flex-auto overflow-auto dark:bg-hercules-dark-bg-200">
                
                <div className="md:flex mb-8 md:mb-3">
                  <div className="relative w-full md:w-auto md:mr-12 mb-3">
                    {datosTrabajador.apellido_Paterno ? (
                      <label className="block tracking-wide dark:text-white text-hercules-light-font-secondary font-medium mb-2">
                        Trabajador:{" "}
                        {datosTrabajador.apellido_Paterno +
                          " " +
                          datosTrabajador.apellido_Materno +
                          " " +
                          datosTrabajador.nombres}
                      </label>
                    ) : (
                      <label className="block tracking-wide dark:text-white text-hercules-light-font-secondary font-medium mb-2">
                        Trabajador:{" "}
                        {datosTrabajador.apellidoPaterno +
                          " " +
                          datosTrabajador.apellidoMaterno +
                          " " +
                          datosTrabajador.nombres}
                      </label>
                    )}
                  </div>

                  <div className="relative w-full md:w-auto">
                    <label className="block tracking-wide dark:text-white text-hercules-light-font-secondary font-medium mb-2">
                      RUT:{" "}
                      {UtilsFunctions.rutFormatter(datosTrabajador.rut, {
                        dots: true,
                      })}
                    </label>
                  </div>
                </div>

                <div className="flex flex-wrap mb-9">
                  <div className="relative w-full md:w-2/12 mr-3">
                    <label
                      className="block  tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      AFP
                    </label>
                    <input
                      {...register("afp")}
                      disabled
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      id="grid-first-name"
                      type="text"
                    />
                  </div>
                  <button
                    className="md:mt-7 mt-2 hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 ease-in-out transition-all duration-400"
                    type="button"
                    onClick={() => setShowEditorAfp(true)}
                  >
                    Asignar/Editar AFP
                  </button>
                </div>

                <div className="flex flex-wrap mb-9">
                  <div className="relative w-full md:w-2/12 mr-3">
                    <label
                      className="block  tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      Salud
                    </label>
                    <input
                      {...register("salud")}
                      disabled
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      id="grid-first-name"
                      type="text"
                    />
                  </div>
                  <button
                    className="md:mt-7 mt-2 hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 ease-in-out transition-all duration-400"
                    type="button"
                    onClick={() => setShowEditorSalud(true)}
                  >
                    Asignar/Editar Salud
                  </button>
                </div>

                {/* <div className="flex flex-wrap mb-2">
                  <div className="relative w-full md:w-2/12 mr-3">
                    <label
                      className="block  tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      Cargas familiares
                    </label>
                    <input
                      {...register("cargasFamiliares")}
                      disabled
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                      id="grid-first-name"
                      type="text"
                    />
                  </div>
                  <button
                    className="md:mt-7 mt-2 hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 ease-in-out transition-all duration-400"
                    type="button"
                    onClick={() => setShowEditorCargas(true)}
                  >
                    Asignar/Editar Cargas familiares
                  </button>
                </div> */}
              </div>

              {/*footer*/}
              <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                  type="button"
                  onClick={() => {
                    onClose();
                    setRefreshTable(!refreshTable);
                  }}
                >
                  Cerrar
                </button>
                <button
                  disabled={!botonGuardarHabilitado}
                  className={` ${!botonGuardarHabilitado? "bg-gray-500" : "bg-hercules-light-primary-button hover:shadow-lg hover:scale-105"}  text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400`}
                  type="button"
                  onClick={() => {
                    setBotonGuardarHabilitado(false)
                    try {
                      changePage(datosTrabajador, "3", institucionesApv);
                    } catch (error) {
                      console.log("Error", error);
                    }
                  }}
                >
                  APVs / Caja Dos
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-30 bg-black"></div>
      </form>

      {showEditorAfp && (
        <NuevoAFPTrabajador
          isVisibleEditorAfp={showEditorAfp}
          modalData={{
            trabajadorId: datosTrabajador.id,
            rutTrabajador: datosTrabajador.rut,
            apellidoPaterno: datosTrabajador.apellidoPaterno,
            apellidoMaterno: datosTrabajador.apellidoMaterno,
            nombres: datosTrabajador.nombres,
          }}
          onCloseEditorAfp={() => setShowEditorAfp(false)}
          updateTrabajadorAfp={(newAfp) => setValue("afp", newAfp)}
        />
      )}

      {showEditorSalud && (
        <NuevoSaludTrabajador
          isVisibleEditorSalud={showEditorSalud}
          modalData={{
            trabajadorId: datosTrabajador.id,
            rutTrabajador: datosTrabajador.rut,
            apellidoPaterno: datosTrabajador.apellidoPaterno,
            apellidoMaterno: datosTrabajador.apellidoMaterno,
            nombres: datosTrabajador.nombres,
          }}
          onCloseEditorSalud={() => setShowEditorSalud(false)}
          updateTrabajadorSalud={(newSalud) => setValue("salud", newSalud)}
        />
      )}

      {showEditorCargas && (
        <CargasFamiliaresTrabajador
          isVisibleEditorCargas={showEditorCargas}
          modalData={{
            trabajadorId: datosTrabajador.id,
            rutTrabajador: datosTrabajador.rut,
            apellidoPaterno: datosTrabajador.apellidoPaterno,
            apellidoMaterno: datosTrabajador.apellidoMaterno,
            nombres: datosTrabajador.nombres,
          }}
          onCloseEditorCargas={() => setShowEditorCargas(false)}
          updateTrabajadorCargaFamiliar={(newCargas) => {
            newCargas
              ? setValue("cargasFamiliares", newCargas)
              : setValue("cargasFamiliares", "");
          }}
        />
      )}
    </>
  );
};

export default FichaTrabajadorPaginaDos;
