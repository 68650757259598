import React, { useEffect, useState } from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import { ColumnFilter } from "../../components";
import UtilsFunctions from "../../utils/UtilsFunctions";
import {
  contratosPorEmpresa,
  hideLoader,
  obtenerFiniquitos,
  showLoader,
} from "../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import SimpleTable from "../../components/SimpleTable";
import ModalVerFiniquito from "../../components/Herramientas/FiniquitosEmpresa/Ver/ModalVerFiniquito";
import ModalFiniquito from "../../components/Herramientas/FiniquitosEmpresa/Crear/ModalFiniquito";

export default function FiniquitosEmpresa() {
  const dispatch = useDispatch();

  const {
    refreshTable,
    activeMenu,
    modalDataVerFiniquito,
    setModalDataVerFiniquito,
  } = useStateContext();
  const [modalFiniquitar, setModalFiniquitar] = useState(false);
  const { modalDataFiniquito, setModalDataFiniquito } = useStateContext();
  const [verModal, setVerModal] = useState(false);
  const empresaId = localStorage.getItem("empresaId");

  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader());
      await Promise.all([
        dispatch(contratosPorEmpresa(empresaId)),
        dispatch(obtenerFiniquitos()),
      ]);
      dispatch(hideLoader());
    };

    fetchData().catch((error) => {
      console.log(error.response.data.message);
      dispatch(hideLoader());
    });
  }, [refreshTable, dispatch]);

  const listaTrabajadoresContratoVigente = useSelector(
    (state) => state.contratosEmpresa
  );
  const listaTrabajadoresFiniquitados = useSelector(
    (state) => state.obtenerFiniquitos
  );

  const Columnas_Trabajadores_Contrato = [
    {
      Header: "RUT",
      accessor: (d) =>
        UtilsFunctions.rutFormatter(d.trabajador.rut, { dots: true }),
      Filter: ColumnFilter,
    },
    {
      Header: "Trabajador",
      accessor: (d) =>
        d.trabajador.nombres +
        " " +
        d.trabajador.apellidoPaterno +
        " " +
        d.trabajador.apellidoMaterno,
      Filter: ColumnFilter,
    },
  ];

  const Columnas_Trabajadores_Finiquitados_CEmpresa = [
    {
      Header: "RUT",
      accessor: (d) => UtilsFunctions.rutFormatter(d.rut, { dots: true }),
      Filter: ColumnFilter,
    },
    {
      Header: "Trabajador",
      accessor: (d) =>
        d.nombres + " " + d.apellidoPaterno + " " + d.apellidoMaterno,
      Filter: ColumnFilter,
    },
    {
      Header: "F. Finiquito",
      accessor: "fechaFiniquito",
      Filter: ColumnFilter,
    },
    {
      Header: "Total",
      accessor: "total",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value !== -1) {
          return "$" + UtilsFunctions.integerFormatter(value);
        } else {
          return "N/A";
        }
      },
    },
    {
      Header: "Centro Costo",
      accessor: "centroCosto",
      Filter: ColumnFilter,
    },
    {
      Header: "Artículo",
      accessor: "causal",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value) {
          return value;
        } else {
          return "N/A";
        }
      },
    },
    {
      Header: "Cargo",
      accessor: "cargo",
      Filter: ColumnFilter,
    },
  ];

  const headersCsv = [
    {
      key: "rut",
      name: "RUT",
    },
    {
      key: "nombres",
      name: "Nombre",
    },
    {
      key: "apellidoPaterno",
      name: "Apellido Paterno",
    },
    {
      key: "apellidoMaterno",
      name: "Apellido Materno",
    },
    {
      key: "fechaFiniquito",
      name: "Fecha Finiquito",
    },
    {
      key: "total",
      name: "Total",
    },
    {
      key: "centroCosto",
      name: "Centro de Costo",
    },
    {
      key: "causal",
      name: "Articulo",
    },
    {
      key: "cargo",
      name: "Cargo",
    },
  ];

  return (
    <div
      className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
        activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">Herramientas</div>
      <div className="titulo-Pagina">Finiquitos</div>

        <div className="h-full rounded-lg pt-5 md:mr-12 overflow-auto">
          <p className="text-xl text-hercules-light-font-secondary dark:text-dark-primary">
            Lista de Trabajadores:
          </p>
          <SimpleTable
            col={Columnas_Trabajadores_Contrato}
            tableData={listaTrabajadoresContratoVigente}
            secondaryButton={{
              type: "finiquitarTrabajador",
              onClickFunc: (data) => {
                setModalFiniquitar(true);
                setModalDataFiniquito(data);
              },
              active: true,
            }}
            exportCsvButton={false}
            tableHeight="h-96"
          />
        </div>

      <div className="h-full md:mr-12 rounded-lg pt-9">
      <p className="text-xl text-hercules-light-font-secondary dark:text-dark-primary">
          Trabajadores finiquitados:
        </p>
        <SimpleTable
          col={Columnas_Trabajadores_Finiquitados_CEmpresa}
          tableData={listaTrabajadoresFiniquitados}
          primaryButton={{
            type: "ver",
            onClickFunc: (data) => {
                setModalDataVerFiniquito(data);
                setVerModal(true);
            },
            active: true,
          }}
          exportCsv={() =>
            UtilsFunctions.jsonToCSV3(listaTrabajadoresFiniquitados, headersCsv, "Trabajadores Finiquitados")
          }  
        />
      </div>

    {modalFiniquitar && 
      <ModalFiniquito
      isVisible={modalFiniquitar}
      onClose={() => setModalFiniquitar(false)}
      modalData={modalDataFiniquito}
    />
    }
   {verModal && 
      <ModalVerFiniquito
      isVisible={verModal}
      onClose={() => setVerModal(false)}
      modalData={modalDataVerFiniquito}
    />
   }
    </div>
  );
}
