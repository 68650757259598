import React, { useEffect, useState } from "react";
import { axiosPrivate } from "../../../../common/axiosPrivate";
import { useForm } from "react-hook-form";
// import UtilsFunctions from "../../../utils/UtilsFunctions";
import { toast } from "react-hot-toast";
import ModalSpinner from "../../../ModalSpinner";
import { useStateContext } from "../../../../contexts/ContextProvider";
import SimpleTable from "../../../SimpleTable";
import ColumnFilter from "../../../ColumnFilter";
import {
  getEventosTrabajador,
  hideLoader,
  limpiarInfoMenuEdicion,
  showLoader,
} from "../../../../Redux/Actions";
import { useDispatch, useSelector } from "react-redux";

export default function EditarEventosTrabajador({
  isVisible,
  onClose,
  modalData,
  eventosActivos,
}) {
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: modalData,
  });

  console.log("modalData", modalData);
  console.log("eventosActivos", eventosActivos);
  const dispatch = useDispatch();

  const nombreTrabajador =
    modalData.nombres +
    " " +
    modalData.apellido_Paterno +
    " " +
    modalData.apellido_Materno;
  const [botonGuardarHabilitado, setBotonGuardarHabilitado] = useState(true);
  const { refreshTable, setRefreshTable, screenSize } = useStateContext();

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const validacionTextInput = {
    required: { value: true, message: "Campo obligatorio" },
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader());
      await Promise.all([dispatch(getEventosTrabajador(modalData.id))]);
      dispatch(hideLoader());
    };

    fetchData().catch((error) => {
      console.log(error.response.data.message);
      dispatch(hideLoader());
    });
  }, [dispatch, refreshTable]);

  const infoEventosTrabajador = useSelector(
    (state) => state.getEventosTrabajador
  );

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("getEventosTrabajador"));
    };
  }, []);

  const onSubmit = async (data) => {
    setBotonGuardarHabilitado(false);
    try {
      const payload = {
        trabajadorId: modalData.id,
        eventoId: data.eventoId,
        descripcion: data.descripcionEvento,
        fecha: data.fechaEvento,
      };
      toast.loading("Agregando Evento", estilosAlerta);
      const response = await axiosPrivate.post(
        `${api_service}/api/v1/trabajadores/eventos/nuevo`,
        payload,
        Authorization
      );
      if (response.status === 200) {
        toast.dismiss();
        toast.success("Evento agregado con éxito", estilosAlerta);
        setRefreshTable(!refreshTable);
      }
      setBotonGuardarHabilitado(true);
    } catch (error) {
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al agregar Evento",
        estilosAlerta
      );
      console.log(error);
      setBotonGuardarHabilitado(true);
    }
  };

  const loadingModal = modalData ? true : false;

  const COLUMNAS = [
    {
      Header: "Evento",
      accessor: (d) => d.evento.nombre,
      Filter: ColumnFilter,
    },
    {
      Header: "Descripción",
      accessor: "descripcion",
      Filter: ColumnFilter,
    },
    {
      Header: "Fecha de Término",
      accessor: "fecha",
      Filter: ColumnFilter,
    },
  ];

  console.log("");
  if (!isVisible) {
    return null;
  }

  return (
    <>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="primerDivModal">
          <div className="segundoDivModal max-w-3xl">
            {/*content*/}
            <div className="tercerDivModal shadow-lg  max-h-[85vh] ">
              {/*header*/}
              <div className="divHeaderModal border-slate-200">
                <h3 className="titulo-Modal">Eventos del Trabajador</h3>
              </div>
              {/*body*/}
              <div className="relative p-6 flex-auto overflow-auto dark:bg-hercules-dark-bg-200">
                {!loadingModal && <ModalSpinner />}
                <h3 className="text-xl mb-3 dark:text-white text-hercules-light-font-secondary">
                  Datos de <b>{nombreTrabajador}</b>
                </h3>

                <div className="w-full md:pr-3 mb-9">
                  <SimpleTable
                    col={COLUMNAS}
                    tableData={infoEventosTrabajador}
                    exportCsvButton={false}
                    hasPagination={false}
                    searchBar={false}
                    showFilters={false}
                    tableHeight="h-56"
                  />
                </div>

                <div className="md:flex md:flex-wrap">
                  <div className="div-Inputs-EditarHaber md:w-5/12 md:pr-3">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-nombre-fantasia"
                    >
                      Evento
                    </label>
                    <select
                      {...register("eventoId", {
                        required: "Debe seleccionar un Evento",
                        // onChange: (e) => {
                        //   const empresa =
                        //     listaEmpresas[e.target.selectedIndex - 1];
                        //   setValue("empresa", empresa);
                        // },
                      })}
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      <option hidden value="">
                        Seleccionar Evento
                      </option>
                      {eventosActivos.map((evento, i) => (
                        <option value={evento.id} key={i}>
                          {evento.nombre}
                        </option>
                      ))}
                    </select>
                    <span
                      className={`text-red-500 dark:text-red-400 text-xs italic ${
                        !errors.eventoId && "opacity-0 invisible"
                      }`}
                    >
                      {errors.eventoId ? errors.eventoId.message : "hidden"}
                    </span>
                  </div>

                  <div className="div-Inputs-EditarHaber md:w-4/12 md:px-3">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-last-name"
                    >
                      Fecha Evento
                    </label>
                    <input
                      {...register("fechaEvento", validacionTextInput)}
                      name="fechaEvento"
                      className="hover:cursor-pointer appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-last-name"
                      type="date"
                    />
                    <span
                      className={`text-red-500 dark:text-red-400 text-xs italic ${
                        !errors.fechaEvento && "opacity-0 invisible"
                      }`}
                    >
                      {errors.fechaEvento
                        ? errors.fechaEvento.message
                        : "hidden"}
                    </span>
                  </div>
                </div>

                <div className="md:flex md:flex-wrap">
                  <div className="div-Inputs-EditarHaber md:w-9/12 md:pr-3">
                    <label
                      className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
                      htmlFor="grid-nombre-fantasia"
                    >
                      Descripción
                    </label>
                    <input
                      {...register(
                        "descripcionEvento",
                        validacionTextInput
                      )}
                      className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                    />
                    <span
                      className={`text-red-500 dark:text-red-400 text-xs italic ${
                        !errors.descripcionEvento && "opacity-0 invisible"
                      }`}
                    >
                      {errors.descripcionEvento
                        ? errors.descripcionEvento.message
                        : "hidden"}
                    </span>
                  </div>

                  <div className="w-full md:pl-3 md:w-3/12 md:mt-6 mt-3">
                    <button
                      // className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 ease-in-out transition-all duration-400"
                      type="submit"
                      disabled={!botonGuardarHabilitado}
                      className={` ${!botonGuardarHabilitado? "bg-gray-500" : "bg-hercules-light-primary-button hover:shadow-lg hover:scale-105"}  text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400`}    
                    >
                      Agregar
                    </button>
                  </div>
                </div>
              </div>
              {/*footer*/}
              <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                  type="button"
                  onClick={() => {
                    onClose();
                    reset();
                  }}
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </form>
    </>
  );
}
