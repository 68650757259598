import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHsExtra, hideLoader, limpiarInfoMenuEdicion, showLoader } from "../../Redux/Actions";
import ColumnFilter from "../../components/ColumnFilter";
import EditarHorasExtra from "../../components/Edicion/Horas Extra/EditarHorasExtra";
import { useStateContext } from "../../contexts/ContextProvider";
import SimpleTable from "../../components/SimpleTable";
import NuevaHoraExtra from "../../components/Edicion/Horas Extra/NuevaHoraExtra";
import UtilsFunctions from "../../utils/UtilsFunctions";
import ColumnFilterOptions from "../../components/ColumnFilterOptions";

export default function MantenedorHsExtra() {
  const dispatch = useDispatch();
  const { refreshTable, activeMenu } = useStateContext();

  const horas = useSelector((state) => state.getHsExtra);
  const [showModalNuevaHorasExtra, setShowModalNuevaHorasExtra] =
    useState(false);
  const [showModalEditarHorasExtra, setShowModalEditarHorasExtra] =
    useState(false);
  const { rowDataHorasExtra, setRowDataHorasExtra } = useStateContext();
  const isLoading = useSelector((state) => state.loader);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(showLoader());
      await Promise.all([
        dispatch(getHsExtra()),
      ]);
      dispatch(hideLoader());
    };
    fetchData().catch((error) => {
      console.log(error.response.data.message);
      dispatch(hideLoader());
    });
  }, [refreshTable, dispatch])

  useEffect(() =>{
    return () => {
      dispatch(limpiarInfoMenuEdicion("getHsExtra"))
    }
  },[])

  const COLUMNS = [
    {
      Header: "Nombre Recargo",
      accessor: "nombre",
      Filter: ColumnFilter,
    },
    {
      Header: "Recargo",
      accessor: (d) =>
        (Math.round((d.recargo - 1) * 100) + "%").replace(".", ","),
      Filter: ColumnFilter,
    },
    {
      Header: "Habilitado",
      accessor: "activo",
      Filter: ColumnFilterOptions,
      Cell: ({ value }) => {
        if (value === true) {
          return "Si";
        } else {
          return "No";
        }
      },
    },
  ];

  const headersCsv = [
    {
      key: "nombre",
      name: "Nombre Recargo",
    },
    {
      key: "recargo",
      name: "Recargo",
    },
    {
      key: "activo",
      name: "Activo",
    },
  ];

  return (
    <div
    className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
      activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">
        Edición
      </div>
      <div className="titulo-Pagina">
        Mantenedor de Horas Extra
      </div>
      <div className="h-full md:mr-12 rounded-lg pt-5">
          <SimpleTable
            col={COLUMNS}
            tableData={horas || []}
            primaryButton={{
              type: "editar",
              onClickFunc: (data) => {
                setShowModalEditarHorasExtra(true);
                setRowDataHorasExtra(data);
              },
              active: true,
            }}
            exportCsv={() =>
              UtilsFunctions.jsonToCSV3(horas, headersCsv, "Horas Extra")
            }  
          />

        <button
          onClick={() => setShowModalNuevaHorasExtra(true)}
          disabled={isLoading}
          className={`mt-3 ${isLoading? "bg-gray-500" : "bg-hercules-light-primary-button hover:shadow-lg"} text-white dark:text-dark-primary font-medium uppercase text-normal px-4 py-3 rounded shadow  outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}  
      >
          Nueva Hora Extra
        </button>
      </div>
      {showModalNuevaHorasExtra &&
      <NuevaHoraExtra
        isVisible={showModalNuevaHorasExtra}
        onClose={() => setShowModalNuevaHorasExtra(false)}
      />
    }
    {showModalEditarHorasExtra && 
      <EditarHorasExtra
        isVisible={showModalEditarHorasExtra}
        onClose={() => setShowModalEditarHorasExtra(false)}
        modalData={rowDataHorasExtra}
      />
      }
    </div>
  );
}
