import React, { useState } from "react";
import { useStateContext } from "../../../contexts/ContextProvider";
import { axiosPrivate } from "../../../common/axiosPrivate";
import { toast } from "react-hot-toast";

export default function ModalEliminarCargasFamiliaresyCreditosCCAF({
  isVisible,
  onClose,
  modalData,
  tipoTabla,
}) {
  const { refreshTable, setRefreshTable, screenSize } = useStateContext();
  const [botonGuardarHabilitado, setBotonGuardarHabilitado] = useState(true);

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");

  async function eliminarRegistros() {
    toast.loading("Eliminando..");
    setBotonGuardarHabilitado(false);
    try {
      if (tipoTabla === "cargasFamiliares") {
        await axiosPrivate.delete(
          `${api_service}/api/v1/trabajadores/cargas-familiares`,
          {
            data: modalData,
            headers: {
              Authorization: `Bearer ${tokenUser}`,
              ClientId: `${token}`,
            },
          }
        );        
      } else if (tipoTabla === "creditosCCAF") {
        await axiosPrivate.delete(
          `${api_service}/api/v1/contratos/eliminarDescuentoAdicional/${modalData}`,
          {
            headers: {
              Authorization: `Bearer ${tokenUser}`,
              ClientId: `${token}`,
            },
          }
        );
      }
      setRefreshTable(!refreshTable);
      onClose();
      toast.dismiss();
      toast.success("Registros eliminados con éxito", estilosAlerta);
      setBotonGuardarHabilitado(true);
    } catch (error) {
      onClose();
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al eliminar registros"
      );
      setBotonGuardarHabilitado(true);
    }
  }

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <div className="primerDivModal">
        <div className="segundoDivModal max-w-lg">
          {/*content*/}
          <div className="tercerDivModal shadow-lg  max-h-[85vh] ">
            {/*header*/}
            <div className="divHeaderModal border-slate-200">
              <h3 className="titulo-Modal">Advertencia</h3>
            </div>

            {/*body*/}
            <div className="relative p-6 flex-auto overflow-auto dark:bg-hercules-dark-bg-200">
              <p className="dark:text-white text-hercules-light-font-secondary">
                ¿Está seguro de que desea eliminar los registros seleccionados?
              </p>
            </div>

            {/*footer*/}
            <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                type="button"
                onClick={() => onClose()}
              >
                No
              </button>
              <button
                disabled={!botonGuardarHabilitado}
                className={` ${
                  !botonGuardarHabilitado
                    ? "bg-gray-500"
                    : "bg-hercules-light-primary-button hover:shadow-lg hover:scale-105"
                }  text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400`}
                type="button"
                onClick={() => eliminarRegistros()}
              >
                Si
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
