import React, { useEffect, useState } from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import { ColumnFilter } from "../../components";
import SimpleTable from "../../components/SimpleTable";
import ConfigurarPlantilla from "../../components/Herramientas/AdministrarPlantillas/ConfigurarPlantilla";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { axiosPrivate } from "../../common/axiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import {
  limpiarInfoMenuEdicion,
  obtenerAtributosAnexoContrato,
  obtenerAtributosContrato,
  obtenerPlantillasEmpresa,
} from "../../Redux/Actions";
import ColumnFilterOptions from "../../components/ColumnFilterOptions";

export default function AdministrarPlantillas() {
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { activeMenu, screenSize, refreshTable, setRefreshTable } =
    useStateContext();
  const [activeTab, setActiveTab] = useState(1);
  const [plantillaSeleccionada, setPlantillaSeleccionada] = useState("");
  const [plantillaImportada, setPlantillaImportada] = useState("");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const empresaId = localStorage.getItem("empresaId");
  const dispatch = useDispatch();

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        dispatch(obtenerPlantillasEmpresa(empresaId)),
        dispatch(obtenerAtributosContrato()),
        dispatch(obtenerAtributosAnexoContrato()),
      ]);
    };
    fetchData().catch((error) => {
      console.log(error.response.data.message);
    });
  }, [dispatch, refreshTable]);

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("infoAtributosContratos"));
      dispatch(limpiarInfoMenuEdicion("infoAtributosAnexoContrato"));
    };
  }, []);

  const infoTablaPlantillasEmpresa = useSelector(
    (state) => state.infoPlantillasEmpresa
  );

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("infoPlantillasEmpresa"));
    };
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const allowedExtensions = /(\.doc|\.docx)$/i;

    if (file && !allowedExtensions.exec(file.name)) {
      toast.error("Solo se permiten archivos con la extensión .doc o .docx");
      event.target.value = "";
      return;
    }
    setPlantillaImportada(file);
  };

  const COLUMNS = [
    {
      Header: "Nombre Contrato",
      accessor: "nombre",
      Filter: ColumnFilter,
    },
    {
      Header: "Activo",
      accessor: "activo",
      Filter: ColumnFilterOptions,
      Cell: ({ value }) => {
        if (value === true) {
          return "Si";
        } else {
          return "No";
        }
      },
    },
    {
      Header: "Configurada",
      accessor: "configurada",
      Filter: ColumnFilterOptions,
      Cell: ({ value }) => {
        if (value === true) {
          return "Si";
        } else {
          return "No";
        }
      },
    },
  ];

  async function importarPlantilla() {
    if (!plantillaImportada) {
      toast.dismiss();
      toast.error("Primero debe subir una plantilla", estilosAlerta);
      return null;
    }

    try {
      toast.loading("Importando datos", estilosAlerta);

      const formData = new FormData();
      formData.append("nombre", plantillaImportada.name);
      formData.append("plantilla", plantillaImportada);
      formData.append("empresaId", empresaId);

      const response = await axiosPrivate.post(
        `${api_service}/api/v1/plantillas/nueva`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${tokenUser}`,
            ClientId: `${token}`,
          },
        }
      );
      if (response.status === 200) {
        toast.dismiss();
        toast.success("Plantilla importada con éxito");
        setRefreshTable(!refreshTable);
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Error al importar datos", estilosAlerta);
      console.log("error", error);
    }
  }

  async function eliminarPlantilla(plantillaId) {
    toast.loading("Eliminando", estilosAlerta);
    try {
      await axiosPrivate.delete(
        `${api_service}/api/v1/plantillas/${plantillaId}/borrar`,
        {
          headers: {
            Authorization: `Bearer ${tokenUser}`,
            ClientId: `${token}`,
          },
        }
      );
      setRefreshTable(!refreshTable);
      toast.dismiss();
      toast.success("Plantilla eliminada con éxito", estilosAlerta);
    } catch (error) {
      console.log("Error", error);
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al eliminar Plantilla",
        estilosAlerta
      );
    }
  }

  return (
    <div
      className={`dark:shadow-none md:mt-12 md:ml-20 mt-28 mx-7 pb-12 rounded-xl ${
        activeMenu && "md:w-[calc(100vw_-_23rem)]"
      }`}
    >
      <div className="titulo-Menu">Herramientas</div>
      <div className="titulo-Pagina">Administrar Plantillas</div>

      <div className="rounded-lg pt-5 ">
        {activeTab === 1 ? (
          <div className="flex max-w-lg">
            <button
              className={` w-6/12 rounded-t text-center py-3 font-semibold border-x border-t ${
                activeTab === 1
                  ? "border-gray-300 dark:border-dark-bg-200 bg-white dark:bg-hercules-dark-bg-100  dark:text-gray-50 text-hercules-light-font-secondary"
                  : "border-gray-600 bg-gray-600 text-white opacity-70 dark:border-dark-bg-200"
              }`}
              onClick={() => handleTabClick(1)}
            >
              Plantillas
            </button>

            {activeTab === 2 ? (
              <button
                className={` w-6/12 rounded-t text-center py-3 ml-1 font-semibold border-x border-t ${
                  activeTab === 2
                    ? "bg-white dark:bg-hercules-dark-bg-100 dark:text-gray-50 text-hercules-light-font-secondary border-t border-x border-gray-300 dark:border-dark-bg-200"
                    : "border-gray-600 bg-gray-600 text-white opacity-70 dark:border-dark-bg-200"
                }`}
                onClick={() => handleTabClick(2)}
              >
                Configurar Plantilla
              </button>
            ) : null}
          </div>
        ) : null}

        <div className={`${activeTab === 1 ? "block " : "hidden"} `}>
          <div className="h-full md:mr-12 rounded-lg">
            <SimpleTable
              col={COLUMNS}
              tableData={infoTablaPlantillasEmpresa}
              exportCsvButton={false}
              primaryButton={{
                type: "editar",
                onClickFunc: (data) => {
                  setPlantillaSeleccionada(data);
                  handleTabClick(2);
                },
                active: true,
              }}
              secondaryButton={{
                type: "eliminar",
                onClickFunc: (data) => {
                  eliminarPlantilla(data.id);
                },
                active: true,
              }}
            />
          </div>

          <form onSubmit={handleSubmit(importarPlantilla)}>
            <div className="flex pt-5">
              <div className="md:w-3/12 md:mr-3">
                <label className="form-check-label uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold">
                  Importar Plantilla
                </label>
                <input
                  type={"file"}
                  id={"docFileInput"}
                  accept={".doc,.docx"}
                  required
                  onChange={handleFileChange}
                  className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                />
              </div>

              <div className="w-full mt-6 md:w-2/12 mb-6 md:mb-0 md:ml-3">
                <button
                  type="submit"
                  className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 pt-3 pb-4 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
                >
                  Importar
                </button>
              </div>
            </div>
          </form>
        </div>

        {activeTab === 2 ? (
          <div className="block">
            <div className="h-full md:mr-12 rounded-lg">
              <ConfigurarPlantilla
                plantillaEditar={plantillaSeleccionada}
                cerrarComponente={() => {
                  setActiveTab(1);
                }}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
