import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CargasFamiliaresTrabajador from "../CargasFamiliaresTrabajador";
import {
  afpTrabajador,
  datosTrabajadorCFamiliar,
  limpiarInfoMenuEdicion,
  tablaSalud,
} from "../../../../Redux/Actions";
import NuevoAFPTrabajador from "../NuevoAFPTrabajador";
import NuevoSaludTrabajador from "../NuevoSaludTrabajador";
import ModalSpinner from "../../../ModalSpinner";
import UtilsFunctions from "../../../../utils/UtilsFunctions";

export default function PaginaDosEditarTrabajador({
  institucionesApv,
  onClose,
  datosTrabajadorEditar,
  changePage,
}) {
  const {
    register,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      afp: "",
      salud: "",
      cargasFamiliares: "",
      // monto: "0",
    },
  });

  const [showEditorAfp, setShowEditorAfp] = useState(false);
  const [showEditorSalud, setShowEditorSalud] = useState(false);
  const [showEditorCargas, setShowEditorCargas] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (datosTrabajadorEditar) {
      // dispatch(listaApvPeriodo(datosTrabajadorEditar.id));
      dispatch(afpTrabajador(datosTrabajadorEditar.id));
      dispatch(tablaSalud(datosTrabajadorEditar.id));
      dispatch(datosTrabajadorCFamiliar(datosTrabajadorEditar.id));
    }
  }, [datosTrabajadorEditar]);

  const afpTrabajadorData = useSelector((state) => state.afpTrabajador);
  const tablaSaludData = useSelector((state) => state.tablaSalud);
  const trabajadorCfamiliarData = useSelector(
    (state) => state.datosTrabajadorCarga
  );

  useEffect(() => {
    return () => {
      dispatch(limpiarInfoMenuEdicion("afpTrabajador"));
      dispatch(limpiarInfoMenuEdicion("tablaSalud"));
      dispatch(limpiarInfoMenuEdicion("datosTrabajadorCarga"));
      reset({})
    };
  }, []);

  useEffect(() => {
    if (afpTrabajadorData.length > 0) {
      setValue("afp", afpTrabajadorData[0]?.nombreAfp);
    }
  }, [afpTrabajadorData]);

  useEffect(() => {
    if (tablaSaludData.length > 0) {
      setValue("salud", tablaSaludData[0]?.institucionDeSaludNombre);
    }
  }, [tablaSaludData]);

  useEffect(() => {
    if (trabajadorCfamiliarData.length > 0) {
      setValue("cargasFamiliares", trabajadorCfamiliarData[0]?.numeroCargas);
    }
  }, [trabajadorCfamiliarData]);

  const loadingModal =
    afpTrabajadorData.length > 0 &&
    tablaSaludData.length > 0 &&
    trabajadorCfamiliarData.length > 0 &&
    watch("afp") !== "" &&
    watch("salud") !== "" &&
    watch("cargasFamiliares") !== "";

  // console.log("afpTrabajadorData", afpTrabajadorData);
  // console.log("afpTrabajadorData", tablaSaludData);
  // console.log("afpTrabajadorData", trabajadorCfamiliarData);
  // console.log("watch", watch());

  return (
    <>
      <form className="w-full">
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none">
          <div className="segundoDivModal max-w-6xl">
            {/*content*/}
            <div className="tercerDivModal shadow-lg  max-h-[85vh]">
              {/*header*/}
              <div className="divHeaderModal border-slate-200">
                <h3 className="titulo-Modal">
                  Editar Trabajador
                </h3>
              </div>
              {/* body */}
              <div
                className={`relative p-6 flex-auto dark:bg-hercules-dark-bg-200 ${
                  !loadingModal ? "overflow-hidden" : "overflow-auto"
                } `}
              >
                {!loadingModal ? (
                  <div className="h-fix">
                    <ModalSpinner />
                  </div>
                ) : (
                  <>
                <div className="md:flex mb-8 md:mb-3">
                  <div className="relative w-full md:w-auto md:mr-12 mb-3">
                        {datosTrabajadorEditar.apellido_Paterno ? (
                      <label className="block tracking-wide dark:text-white text-hercules-light-font-secondary font-medium mb-2">
                      Trabajador:{" "}
                            {datosTrabajadorEditar.apellido_Paterno +
                              " " +
                              datosTrabajadorEditar.apellido_Materno +
                              " " +
                              datosTrabajadorEditar.nombres}
                          </label>
                        ) : (
                          <label className="block tracking-wide dark:text-white text-hercules-light-font-secondary font-medium mb-2">
                          Trabajador:{" "}
                            {datosTrabajadorEditar.apellidoPaterno +
                              " " +
                              datosTrabajadorEditar.apellidoMaterno +
                              " " +
                              datosTrabajadorEditar.nombres}
                          </label>
                        )}
                      </div>

                      <div className="relative w-full md:w-auto">
                    <label className="block tracking-wide dark:text-white text-hercules-light-font-secondary font-medium mb-2">
                          RUT: {UtilsFunctions.rutFormatter(datosTrabajadorEditar.rut, { dots: true })}
                        </label>
                      </div>
                    </div>

                    {/* AFP */}
                    <div className="flex flex-wrap mb-9">
                  <div className="relative w-full md:w-2/12 mr-3">
                    <label
                      className="block  tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Afp
                        </label>
                        <input
                          {...register("afp")}
                          disabled
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                          id="grid-first-name"
                          type="text"
                        />
                      </div>
                      <button
                    className="md:mt-7 mt-2 hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 ease-in-out transition-all duration-400"
                    type="button"
                        onClick={() => setShowEditorAfp(true)}
                      >
                        Asignar/Editar AFP
                      </button>
                    </div>

                    {/* Salud */}
                    <div className="flex flex-wrap mb-9">
                  <div className="relative w-full md:w-2/12 mr-3">
                    <label
                      className="block  tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Salud
                        </label>
                        <input
                          {...register("salud")}
                          disabled
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                          id="grid-first-name"
                          type="text"
                        />
                      </div>
                      <button
                    className="md:mt-7 mt-2 hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 ease-in-out transition-all duration-400"
                    type="button"
                        onClick={() => setShowEditorSalud(true)}
                      >
                        Asignar/Editar Salud
                      </button>
                    </div>

                    {/* Cargas Familiares */}
                    {/* <div className="flex flex-wrap mb-2">
                  <div className="relative w-full md:w-2/12 mr-3">
                    <label
                      className="block  tracking-wide dark:text-white text-hercules-light-font-secondary text-sm font-bold mb-2"
                          htmlFor="grid-last-name"
                        >
                          Cargas familiares
                        </label>
                        <input
                          {...register("cargasFamiliares")}
                          disabled
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                          id="grid-first-name"
                          type="text"
                        />
                      </div>
                      <button
                    className="md:mt-7 mt-2 hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 ease-in-out transition-all duration-400"
                    type="button"
                        onClick={() => setShowEditorCargas(true)}
                      >
                        Asignar/Editar Cargas familiares
                      </button>
                    </div> */}
                  </>
                )}
              </div>

              {/*footer*/}
              <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                  type="button"
                  onClick={() => onClose()}
                >
                  Cerrar
                </button>
                <button
                  className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
                  type="button"
                  onClick={() =>
                    changePage(datosTrabajadorEditar, "3", institucionesApv)
                  }
                >
                  APVs / Caja Dos
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-30 bg-black"></div>
      </form>

      {showEditorAfp && (
        <NuevoAFPTrabajador
          isVisibleEditorAfp={showEditorAfp}
          modalData={{
            trabajadorId: datosTrabajadorEditar.id,
            rutTrabajador: datosTrabajadorEditar.rut,
            apellidoPaterno: datosTrabajadorEditar.apellido_Paterno,
            apellidoMaterno: datosTrabajadorEditar.apellido_Materno,
            nombres: datosTrabajadorEditar.nombres,
          }}
          onCloseEditorAfp={() => setShowEditorAfp(false)}
          updateTrabajadorAfp={(newAfp) => setValue("afp", newAfp)}
        />
      )}
      {showEditorSalud && (
        <NuevoSaludTrabajador
          isVisibleEditorSalud={showEditorSalud}
          modalData={{
            trabajadorId: datosTrabajadorEditar.id,
            rutTrabajador: datosTrabajadorEditar.rut,
            apellidoPaterno: datosTrabajadorEditar.apellido_Paterno,
            apellidoMaterno: datosTrabajadorEditar.apellido_Materno,
            nombres: datosTrabajadorEditar.nombres,
          }}
          onCloseEditorSalud={() => setShowEditorSalud(false)}
          updateTrabajadorSalud={(newSalud) => setValue("salud", newSalud)}
        />
      )}
      {showEditorCargas && (
        <CargasFamiliaresTrabajador
          isVisibleEditorCargas={showEditorCargas}
          modalData={{
            trabajadorId: datosTrabajadorEditar.id,
            rutTrabajador: datosTrabajadorEditar.rut,
            apellidoPaterno: datosTrabajadorEditar.apellido_Paterno,
            apellidoMaterno: datosTrabajadorEditar.apellido_Materno,
            nombres: datosTrabajadorEditar.nombres,
          }}
          onCloseEditorCargas={() => setShowEditorCargas(false)}
          updateTrabajadorCargaFamiliar={(newCargas) => {
            setValue("cargasFamiliares", newCargas);
          }}
        />
      )}
    </>
  );
}
