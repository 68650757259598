import React from 'react'
import { Outlet } from 'react-router-dom';
import { useStateContext } from '../../../contexts/ContextProvider';
import NavbarVacaciones from './NavbarVacaciones';
import SidebarVacaciones from './SidebarVacaciones';

const LayoutVacaciones = ({children}) => {
    const { activeMenu } = useStateContext();
    const currentMode = 'Light';
  return (
    <>
        {activeMenu ? (
                    <div className={` w-80 sidebar dark:border-r-1 dark:border-dark-bg-300 fixed dark:bg-hercules-dark-bg-100  bg-hercules-light-secondary ease-in-out duration-300 -translate-x-1`}>
                        <SidebarVacaciones />
                    </div>
                ) : (
                    <div className={`w-0 ease-linear duration-75`}>
                        <SidebarVacaciones />
                    </div>
                )}
                <div className={
                    `dark:bg-hercules-dark-bg-300 bg-hercules-light-primary min-h-screen w-full ease-in-out duration-300 ${activeMenu ? 'md:ml-72' : 'flex-2'}`
                    }>
                    <div className='fixed md:static dark:border-b-1 border-b-1 dark:border-dark-bg-300 bg-hercules-pure-white dark:bg-hercules-dark-bg-200  navbar w-full'>
                        <NavbarVacaciones />
                    </div>
                    <div className='overflow-auto h-min-[calc(100vh_-_4.6rem)]'>
                        <Outlet />
                    </div>
                </div>
    </>
  )
}

export default LayoutVacaciones