import React from "react";
import toast from "react-hot-toast";
import { useStateContext } from "../../../../contexts/ContextProvider";
import { axiosPrivate } from "../../../../common/axiosPrivate";
import { useForm } from "react-hook-form";

export default function ModalRechazarVacaciones({ modalData, onClose }) {

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const validacionCampoObligatorio = {
    required: { value: true, message: "Obligatorio" },
  };

  const { refreshTable, setRefreshTable, screenSize } = useStateContext();

  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;

  const userId = localStorage.getItem("userId");
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  async function onSubmit(data) {
    toast.loading("Rechazando Vacaciones", estilosAlerta);
    try {
      const requestBody = {
        registroVacacionId: modalData.id,
        usuarioId: userId,
        observacion: data.observaciones,
      };
      const response = await axiosPrivate.post(
        `${api_service}/api/v1/vacaciones/solicitudes/rechazar`,
        requestBody,
        Authorization
      );
      if (response.status === 200) {
        toast.dismiss();
        toast.success("Vacaciones rechazadas con éxito", estilosAlerta);
        onClose();
        setRefreshTable(!refreshTable);
      }
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al rechazar Vacaciones",
        estilosAlerta
      );
    }
  }

  return (
    <>
          <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <div className="primerDivModal">
        <div className="segundoDivModal max-w-2xl">
          {/*content*/}
          <div className="tercerDivModal shadow-lg  max-h-[85vh] ">
            {/*header*/}
            <div className="divHeaderModal border-slate-200">
              <h3 className="titulo-Modal">Rechazar Vacaciones</h3>
            </div>
            {/*body*/}
            <div className="relative p-6 overflow-auto flex-auto dark:bg-hercules-dark-bg-200">
              {/* {!loadingModal && <ModalSpinner />} */}
              <>
                {/* Primera fila modal */}

                <h5 className="text-lg font-normal leading-normal mb-3 dark:text-white text-hercules-light-font-secondary">
                  Rechazar vacaciones del trabajador{" "}
                  <b>
                    {modalData.nombres} {modalData.apellidoPaterno}{" "}
                    {modalData.apellidoMaterno}
                  </b>{" "}
                  que solicitó uso del feriado desde el{" "}
                  <b>{modalData.fechaInicio}</b> hasta el{" "}
                  <b>{modalData.fechaTermino}</b>, ambas fechas inclusive.
                </h5>

                <div className="div-Inputs-EditarHaber md:w-full md:pr-3">
                    <label className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2">
                      Observaciones
                    </label>
                    <textarea
                      {...register("observaciones", validacionCampoObligatorio)}
                      rows={3}
                      className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                      // type="text"
                    />
                    <span
                      className={`text-red-500 dark:text-red-400 text-xs italic ${
                        !errors.observaciones && "opacity-0 invisible"
                      }`}
                    >
                      {errors.observaciones
                        ? errors.observaciones.message
                        : "hidden"}
                    </span>
                  </div>

              </>
            </div>

            {/*footer*/}
            <div className="dark:bg-hercules-dark-bg-200 flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-sm md:text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                type="button"
                onClick={() => {
                  onClose();
                }}
              >
                Cerrar
              </button>
              <button
                className="bg-hercules-light-primary-button hover:shadow-lg hover:scale-105 text-white  font-bold uppercase text-sm md:text-base px-6 py-3 rounded-md shadow outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
                type="submit"
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
      </form>
      <div className="opacity-25 fixed inset-0 z-20 bg-black"></div>
    </>
  );
}
