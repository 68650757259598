import React, { useEffect, useState } from "react";
import ColumnFilter from "../../../ColumnFilter";
import HaberesDescuentosTable from "../HaberesDescuentosTable";
import { axiosPrivate } from "../../../../common/axiosPrivate";
import { useForm } from "react-hook-form";
import UtilsFunctions from "../../../../utils/UtilsFunctions";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import SpinnerRevisarHabDesc from "../../../SpinnerEspecificoRevHabDesc";
import { useStateContext } from "../../../../contexts/ContextProvider";

export default function HaberesVariablesTabla({ modalDataContrato }) {
  const api_service = process.env.REACT_APP_API_HERCULES_SERVICE;
  const tokenRut = localStorage.getItem("token-rut");
  const token = tokenRut.slice(0, 8);
  const tokenUser = localStorage.getItem("token-user");
  const periodoId = localStorage.getItem("periodoId");
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true)
  const { screenSize } = useStateContext();

  const isMobile = screenSize <= 768;
  const estilosAlerta = {
    style: {
      marginTop: isMobile ? "80px" : "",
    },
  };

  const Authorization = {
    headers: {
      Authorization: `Bearer ${tokenUser}`,
      ClientId: `${token}`,
    },
  };

  const [haberesVariablesTabla, setHaberesVariablesTabla] = useState([]);

  const formatter = new Intl.NumberFormat("es-CL", {
    styles: "currency",
    currency: "CLP",
    minimumFractionDigits: 0,
  });

  const {
    register,
    reset,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useForm();

  async function obtenerHaberes(modalDataContrato) {
    try {
      const contratoId = modalDataContrato.contratoId;
      const anexoContratoId = modalDataContrato.anexoContratoId
        ? modalDataContrato.anexoContratoId
        : null;

      if (anexoContratoId) {
        const infoHaberes = await axiosPrivate(
          `${api_service}/api/v1/contratos/anexos/${anexoContratoId}/haberes-variables/periodo/${periodoId}`,
          Authorization
        );
        setHaberesVariablesTabla(infoHaberes.data);
        reset();
      } else {
        const infoHaberes = await axiosPrivate(
          `${api_service}/api/v1/contratos/${contratoId}/haberes-variables/periodo/${periodoId}`,
          Authorization
        );
        setHaberesVariablesTabla(infoHaberes.data);
        reset();
      }
      
    } catch (error) {
      console.log("Error", error);
      toast.error(
        error.response.data.message
          ? error.response.data.message
          : "Error al cargar Haberes Variables",
          estilosAlerta
      );
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await Promise.all([obtenerHaberes(modalDataContrato)]);
      setIsLoading(false);
    };
    fetchData().catch((error) => {
      console.log(error.response.data.message);
      setIsLoading(false);
    });
  }, [dispatch, modalDataContrato]);

  const [editarMontoHaberVariable, setEditarMontoHaberVariable] = useState({
    estado: false,
    monto: "",
    contratoHaberId: "",
    anexoHaberId: "",
    indice: "",
  });

  async function editarMontoHaber(data) {
    toast.loading("Editando monto del Haber", estilosAlerta);
    try {
      const payload = {
        contratoHaberId: modalDataContrato.anexoContratoId
          ? null
          : editarMontoHaberVariable.contratoHaberId,
        anexoHaberId: modalDataContrato.anexoContratoId
          ? editarMontoHaberVariable.anexoHaberId
          : null,
        trabajadorId: modalDataContrato.trabajadorId,
        monto: data.monto?.replace(/\./g, "").replace("$", "") || 0,
        periodoId: periodoId,
      };
      console.log("Data enviada", payload);
      if (payload.anexoHaberId) {
        const info = await axiosPrivate.post(
          `${api_service}/api/v1/contratos/anexos/haberes-variables/`,
          payload,
          Authorization
        );
        console.log("Info actualizada con exito", info);
        if (info.status == 200) {
          obtenerHaberes(modalDataContrato);
          toast.dismiss();
          toast.success("Monto editado con éxito", estilosAlerta);
          setEditarMontoHaberVariable({
            ...editarMontoHaberVariable,
            estado: false,
          });
        }
      } else {
        const info = await axiosPrivate.post(
          `${api_service}/api/v1/contratos/haberes-variables`,
          payload,
          Authorization
        );
        console.log("Info actualizada con exito", info);
        if (info.status == 200) {
          obtenerHaberes(modalDataContrato);
          toast.dismiss();
          toast.success("Monto editado con éxito", estilosAlerta);
          setEditarMontoHaberVariable({
            ...editarMontoHaberVariable,
            estado: false,
          });
        }
      }
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error(error.response.data.message? error.response.data.message : "Error al editar monto", estilosAlerta);
    }
  }

  async function restaurarValorVariable(info) {
    console.log("info recibida", info.original);
    try {
      if (modalDataContrato.anexoContratoId) {
        await axiosPrivate.delete(
          `${api_service}/api/v1/contratos/anexos/haberes-variables`,
          {
            data: {
              anexoHaberId: info.original.id,
              periodoId: periodoId,
              trabajadorId: modalDataContrato.trabajadorId,
            },
            headers: {
              Authorization: `Bearer ${tokenUser}`,
              ClientId: `${token}`,
            },
          }
        );
      } else {
        await axiosPrivate.delete(
          `${api_service}/api/v1/contratos/haberes-variables`,
          {
            data: {
              contratoHaberId: info.original.id,
              periodoId: periodoId,
              trabajadorId: modalDataContrato.trabajadorId,
            },
            headers: {
              Authorization: `Bearer ${tokenUser}`,
              ClientId: `${token}`,
            },
          }
        );
      }
      obtenerHaberes(modalDataContrato);
      toast.dismiss();
      toast.success("Monto eliminado con éxito", estilosAlerta);
    } catch (error) {
      console.log("Error", error);
      toast.dismiss();
      toast.error(error.response.data.message ? error.response.data.message : "Error al eliminar monto", estilosAlerta);
    }
  }

  const COLUMNAS_HABERES_VARIABLES = [
    {
      Header: "Nombre",
      accessor: "haber.nombre",
      Filter: ColumnFilter,
    },
    {
      Header: "Monto",
      accessor: "valor",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value === -1) {
          return "Variable";
        } else {
          return "$" + UtilsFunctions.integerFormatter(value);
        }
      },
    },
  ];

  return (
    <>
      <form className="w-full" onSubmit={handleSubmit(editarMontoHaber)}>
        <div>
        <h3 className="mb-2 font-semibold dark:text-white text-hercules-light-font-secondary">
            Haberes Variables
          </h3>
          {isLoading ? (
            <div className="pt-6">
              <SpinnerRevisarHabDesc />
            </div>
          ) : (
            <HaberesDescuentosTable
              col={COLUMNAS_HABERES_VARIABLES}
              tableData={haberesVariablesTabla}
              botonEditar={true}
              editarMonto={(info) => {
                console.log("INFO", info);
                setEditarMontoHaberVariable({
                  ...editarMontoHaberVariable,
                  estado: true,
                  monto: info.original.valor,
                  contratoHaberId: info.original.id,
                  anexoHaberId: info.original.id,
                  nombre: info.original.haber.nombre,
                  indice: info.index,
                });
              }}
              botonEliminarMontoVariable={true}
              eliminarMonto={(info) => {
                restaurarValorVariable(info);
              }}
            />
          )}
        </div>

        {editarMontoHaberVariable.estado ? (
          <div className="w-full mt-3 ">
            <label
              className="block uppercase tracking-wide dark:text-white text-hercules-light-font-secondary text-xs font-bold mb-2"
              htmlFor="grid-first-name"
            >
              Monto
            </label>
            <div className="flex w-full">
              <div className="md:w-3/12 mr-3">
                <input
                  {...register("monto", {
                    validate: (value) => {
                      if (
                        value === "" ||
                        value === "$" ||
                        value === "$0" ||
                        parseInt(value)
                      ) {
                        return "Debe ingresar un monto mayor a cero";
                      }
                      return true;
                    },
                    onChange: (e) => {
                      setValue(
                        "monto",
                        "$" +
                          formatter.format(
                            getValues("monto")?.replace(/\D/g, "")
                          )
                      );
                    },
                  })}
                  name="monto"
                  class="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4  leading-tight focus:outline-none focus:bg-white"
                  id="grid-rut"
                  type="text"
                />
              </div>
              <div className="md:w-3/12">
                <button
                  className="hover:scale-105 bg-hercules-dark-primary-button text-white  font-bold uppercase text-base px-6 py-3 rounded-md shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-in-out transition-all duration-400"
                  type="submit"
                >
                  Guardar
                </button>
              </div>
              <div className="md:w-3/12">
                <button
                  className="hover:scale-110 dark:text-white text-hercules-light-font-secondary rounded-md background-transparent font-bold uppercase px-6 py-3 text-base outline-none focus:outline-none mr-3 mb-1 ease-in-out transition-all duration-400"
                  type="button"
                  onClick={() => {
                    setEditarMontoHaberVariable({
                      estado: false,
                      monto: "",
                      contratoHaberId: "",
                      anexoHaberId: "",
                      indice: "",
                    });
                    reset();
                  }}
                >
                  Cancelar
                </button>
              </div>
            </div>
            {errors.monto && (
              <span className="text-red-500 text-xs italic">
                {errors.monto.message}
              </span>
            )}
          </div>
        ) : null}
      </form>
    </>
  );
}
